/** 
 * GET-FILTER-POPULATIONS.MODAL
 * Show populations from a custom filter
 */

import Modal from "@/components/modal"
import { connect } from "react-redux"
import { withTranslation, WithTranslation } from "react-i18next"
import { Filter, FilterPopulation } from "@/redux/filter.types";
import { useState } from "react";
import { store } from "@/index";
import { filterActivate } from "@/redux/filter.actions";
import Chip from "@/components/chip";
import { Session } from "@/redux/_session.types";
import GetPopulationUsersModal from "./get-population-users.modal";
import SearchBar from "@/components/search-bar";
import { STATUS_LOADED } from "@/redux/_status.types";
import Space from "@/components/space";

interface StateProps{
  _session : Session
}

interface OwnProps{
  filter?: Filter
  onClose: Function
}

type Props = WithTranslation & StateProps & OwnProps

const MODAL_POPULATION_USERS: string = "MODAL_POPULATION_USERS"

function GetFilterPopulationsModal(props:Props) {
  const { t } = props

  //Search
  const [currentSearch, setCurrentSearch] = useState("")

  //Current population to display
  const [currentPopulationName, setCurrentPopulationName] = useState("")

  //Current modal
  const [currentModal, setCurrentModal] = useState<string | null>(null)


  //Get populations according search
  function getPopulations(list: FilterPopulation[]){
    return currentSearch.length ? list.filter(x => x.name.toLowerCase().indexOf(currentSearch.toLowerCase()) > -1) : list
  }

  //Search into list of populations
  function search(value: string){
    setCurrentSearch(value)
  }

  //Open user modal
  function showUsers(filter: Filter, populationName: string){
    store.dispatch(filterActivate(filter))
    setCurrentPopulationName(populationName)
    setCurrentModal(MODAL_POPULATION_USERS)
  }

  return (
    <Modal onClose={props.onClose}
      isCloseButtonVisible>

      { currentModal === MODAL_POPULATION_USERS &&
      <GetPopulationUsersModal onClose={() => setCurrentModal(null)}
        populationName={currentPopulationName}
      />
      }

      { props.filter?
      <div>

        <p><b>{t("filter_populations", {filter: props.filter.default ? t(props.filter.name) : props.filter.name})}</b></p>

        <div className="height-20" />

        <SearchBar onSearch={search} status={STATUS_LOADED} />

        <div className="height-20" />

        { getPopulations(props.filter.populations).length > 0 ?
        <div className="flex flex-wrap" style={{maxHeight: "500px", overflowY: "auto"}}>
          { getPopulations(props.filter.populations).map((population: FilterPopulation, i: number) => 
          <Chip key={i} 
            onInfo={() => showUsers(props.filter!, population.name)}
            count={population.usersCount}
            countWarning={population.usersCount < props._session.participationMin}
            isWithMargin
          >
            {population.name}
          </Chip>
          )}
        </div>
        :
        <div className="flex flex-dcol" style={{height : "100px"}}>
          <Space/>
          <div className="flex">
            <Space/>
            {t("no_population")}
            <Space/>
          </div>
          <Space/>
        </div>
        }
      </div>
      :
      <div className="flex flex-dcol" style={{height : "100px"}}>
        <Space/>
        <div className="flex">
          <Space/>
          {t("no_filter_selected")}
          <Space/>
        </div>
        <Space/>
      </div>
      }
      
    </Modal>


  )

}

const mapStateToProps = state => ({
  _session : state._session,
})

export default connect(mapStateToProps)(withTranslation()(GetFilterPopulationsModal))