/**
 * ATTRIBUTE.TYPES
 */

export class Attribute{
  id : string = ""
  name : string = ""
  selected : boolean = false
  usersCount : number = 0
  description : string = ""
  aliases : any = {}
  aid: string = ""
  filterName: string = ""

  constructor(attribute: Partial<Attribute> = {}){
    if (!attribute) attribute = new Attribute()
    Object.assign(this, attribute)
  }

}

export interface AttributeState{
  active : Attribute //Attribute selected
  list : Attribute[] //List of attributes
  compareList : Attribute[] //2nd list for dashboard Compare
  status : string //Status of attribute
}

export const ATTRIBUTE_ACTIVATE : string = "ATTRIBUTE_ACTIVATE"
export const ATTRIBUTE_ADD : string = "ATTRIBUTE_ADD"
export const ATTRIBUTE_COMPARE_GET : string = "ATTRIBUTE_COMPARE_GET"
export const ATTRIBUTE_EDIT : string = "ATTRIBUTE_EDIT"
export const ATTRIBUTE_GET : string = "ATTRIBUTE_GET"
export const ATTRIBUTE_INIT : string = "ATTRIBUTE_INIT"
export const ATTRIBUTE_REMOVE : string = "ATTRIBUTE_REMOVE"
export const ATTRIBUTE_STATUS : string = "ATTRIBUTE_STATUS"