/** 
 * LESSON.REDUCERS
 * Edit lesson state
 */

import { Lesson, LessonState, LESSON_ACTIVATE, LESSON_ADD, LESSON_EDIT, LESSON_INIT, LESSON_GET, LESSON_REMOVE, LESSON_REPLACE, LESSON_STATUS } from "./lesson.types";
import { STATUS_LOADED } from "./_status.types";
import { v4 as uuid } from "uuid";


let active:Lesson

const initialState: LessonState = {
  active : new Lesson(),
  list : [],
  status : STATUS_LOADED,
}

export default function lessonReducer(
  state = initialState,
  action: any
): LessonState {
  switch (action.type) {

    //activate lesson
    case LESSON_ACTIVATE:
      return Object.assign({}, state, {
        active : new Lesson(action.payload.lesson)
      })

    //Add new template
    case LESSON_ADD:
      active = new Lesson(action.payload.lesson)
      active.id = uuid()
      return Object.assign({}, state, {
        active,
        list : [active, ...state.list]
      })

    //editing lesson
    case LESSON_EDIT:
      active = Object.assign(state.active, { [action.payload.key] : action.payload.value })
      return Object.assign({}, state, {
        active,
        list : state.list.map(x => x.id === active.id ? active : x)
      })
    
    //Init store
    case LESSON_INIT:
      return Object.assign({}, state, {
        active : new Lesson(state.active),
        status : state.status,
      })

    //Save list of learning in the store
    case LESSON_GET:
      return {
        ...state,
        list : action.payload.lessons.map((x) => new Lesson(x))
      }

    //Remove a lesson
    case LESSON_REMOVE:
      return {
        ...state,
        list  : state.list.filter(x => x.id !== action.payload.id )
      }

    //Replace an existing lesson
    case LESSON_REPLACE:
      return Object.assign({}, state, {
        active : new Lesson(action.payload.lesson),
        list : state.list.map(x => x.id === action.payload.lesson.id ? action.payload.lesson : x)
      })

    //Set status
    case LESSON_STATUS:
      return {
        ...state,
        status : action.payload.status
      }

    default:
      return state

  }

}