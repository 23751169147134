/**
 * TASKS-ACTION-PLAN-DISPLAY.WIDGET
 */

import ListCell from "@/components/list-cell"
import ListIcon from "@/components/list-icon"
import { ActionPlan } from "@/redux/action-plan.types"
import { faPlayCircle, faUserCircle } from "@fortawesome/free-solid-svg-icons"
import { useEffect, useState } from "react"
import { withTranslation, WithTranslation } from "react-i18next"
import AdmUserPopulationsWidget from "./adm-user-populations.widget"
import Chip from "@/components/chip"
import ProgressBar from "@/components/progress-bar"
import { connect } from "react-redux"
import { Session } from "@/redux/_session.types"
import Space from "@/components/space"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"


interface OwnProps {
  actionPlan: ActionPlan
  isSelected?: boolean
  onClick?: Function
  isBlurred?: boolean
  showPlayButton?: boolean
}

interface StateProps {
  _session: Session
}

type Props = OwnProps & StateProps & WithTranslation

function TasksActionPlanDisplayWidget(props: Props){
  const { t } = props

  const [actionPlan, setActionPlan] = useState<ActionPlan>(props.actionPlan)
  const [isSelected, setIsSelected] = useState<boolean>(props.isSelected ? true : false)

  useEffect(() => {
    setActionPlan(props.actionPlan)
  }, [
    props.actionPlan
  ])

  useEffect(() => {
    setIsSelected(props.isSelected ? true : false)
  }, [
    props.isSelected
  ])

  function click(){
    if(props.onClick){
      props.onClick()
    }
  }

  return (
    <div
      className={"card flex" + (props.onClick ? " _hover" : "")}
      onClick={click}
      style={{
        padding: "20px 30px",
        border: isSelected ? "1px solid" + props._session.accountColors.active : undefined,
        opacity: props.isBlurred ? 0.7 : undefined
      }}
    >
      <div className="flex1">

        { actionPlan.UserResponsible &&
        <div className="flex">

          <ListIcon fallbackIcon={faUserCircle}
            image={actionPlan.UserResponsible.imageUrl}
          />

          <div>

            <ListCell width={170} text={actionPlan.UserResponsible.username}/>

            <AdmUserPopulationsWidget currentUser={actionPlan.UserResponsible}/>

          </div>

        </div>
        }

        <div className="height-20" />

        <div className="flex">
          { actionPlan.status === "active" ?
          <div style={{ width: 100, marginLeft: 12 }}>
            <div style={{ margin: "-6px 0px"}}>
              <ProgressBar
                items={[
                  {value: actionPlan.tasksDoneCount}
                ]}
                max={actionPlan.tasks.length}
              />
            </div>
          </div>
          :
          <div className="grey-t" style={{marginRight: 6}}>
            {t("tasks_count", { count: actionPlan.tasks.length, s: actionPlan.tasks.length > 1 ? "s" : "" })}
          </div>
          }

          <Space />

          <Chip>
            {actionPlan.statusLabel}
          </Chip>
        </div>

      </div>

      { props.showPlayButton &&
      <div className="flex flex-dcol" data-tip={t("action_plan_redirect")} style={{marginLeft: 20}}>
        <Space />
        <FontAwesomeIcon icon={faPlayCircle} color={props._session.accountColors.cta} />
        <Space />
      </div>
      }
      
    </div>
  )
}

const mapStateToProps = state => ({
  _session: state._session
})

export default connect(mapStateToProps)(withTranslation()(TasksActionPlanDisplayWidget))