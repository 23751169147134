/** 
 * LEARNING.ACTIONS
 * Actions about learning
 */
import { Learning, LEARNING_ACTIVATE, LEARNING_ADD, LEARNING_EDIT, LEARNING_EDIT_LESSON, LEARNING_GET, LEARNING_INIT, LEARNING_REMOVE, LEARNING_STATUS } from "./learning.types";
import { Lesson } from "./lesson.types"


/* REDUX */

export const learningActivate = (learning:Learning) => ({
  type : LEARNING_ACTIVATE,
  payload : {
    learning
  }
})

export const learningAdd = (language:string) => ({
  type : LEARNING_ADD,
  payload : {
    language
  }
})

export const learningEdit = (key:string, value:any) => ({
  type : LEARNING_EDIT,
  payload : {
    key,
    value
  }
})

export const learningEditLesson = (lesson:Lesson) => ({
  type : LEARNING_EDIT_LESSON,
  payload : {
    lesson
  }
})

export const learningGet = (learnings : Learning[]) => ({
  type : LEARNING_GET,
  payload : {
    learnings
  }
})

export const learningInit = () => ({
  type : LEARNING_INIT
})

export const learningRemove = (id:string) => ({
  type : LEARNING_REMOVE,
  payload : {
    id
  }
})

export const learningStatus = (status:string) => ({
  type : LEARNING_STATUS,
  payload : {
    status
  }
})


/** API */

export const learningDestroy = (id:string) => ({
  type : 'API',
  payload : {
    method : "DELETE",
    url : "/learning/" + id
  }
})

export const learningDestroy_AsSupervisor = (id:string) => ({
  type : 'API',
  payload : {
    method : "DELETE",
    url : "/supervisor/learning/" + id
  }
})

export const learningFetch = (search: string, limit: number | null, offset: number | null, fromSupervisor: boolean | null, visible: boolean | null) => ({
  type : 'API',
  payload : {
    method : "GET",
    url : "/learnings",
    data : {
      search,
      limit,
      offset,
      fromSupervisor,
      visible
    }
  }
})

export const learningFetchLessons = (learningId:string) => ({
  type : 'API',
  payload : {
    method : "GET",
    url : "/learning/lessons",
    data : {
      learningId
    }
  }
})

export const learningFetchLessons_AsSupervisor = (learningId:string) => ({
  type : 'API',
  payload : {
    method : "GET",
    url : "/supervisor/learning/lessons",
    data : {
      learningId
    }
  }
})

export const learningFetch_AsSupervisor = (search:string, limit:number | null, offset:number) => ({
  type : 'API',
  payload : {
    method : "GET",
    url : "/supervisor/learnings",
    data : {
      search,
      limit,
      offset
    }
  }
})

export const learningUpdate = (learning : Learning) => ({
  type : 'API',
  payload : {
    method : "POST",
    url : "/learning/update",
    data : {
      learning,
    }
  }
})

export const learningUpdate_AsSupervisor = (learning : Learning) => ({
  type : 'API',
  payload : {
    method : "POST",
    url : "/supervisor/learning/update",
    data : {
      learning,
    }
  }
})
