/** 
 * PROJECT-EDIT.ROUTE
 * Wizard for project : everything starts here 
 */

import NavFocus from "@/nav/nav.focus";
import { connect } from "react-redux";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { ProjectState } from "@/redux/project.types";
import ProjectEditWidget from "@/widgets/project-edit.widget";

interface StateProps{
  project : ProjectState
}

function ProjectEditRoute(props:StateProps) {
  const navigate: NavigateFunction = useNavigate()
  return(
    <NavFocus onBack={() => navigate("/project/list")}
      isCreditsDisplayed
      status={props.project.status}>
      <ProjectEditWidget/>
    </NavFocus>
  )

}

const mapStateToProps = (state) => ({
  project : state.project
})

export default connect(mapStateToProps)(ProjectEditRoute)