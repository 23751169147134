/**
 * DASHBOARD-MESSAGES
 * Comments written by the users
 */
import NavDashboard from "@/nav/nav.dashboard";
import DashboardMessagesWidget from "@/widgets/dashboard-messages.widget";

function DashboardMessagesRoute(){
  return(
    <NavDashboard title="dashboard_messages"
      hideOptions
      helpPage={19}>
      <DashboardMessagesWidget/>
    </NavDashboard>
  )
}
export default DashboardMessagesRoute