/** 
 * SURVEY
 */

import { formatDate } from "@/utils/format-date.utils"
import { Account } from "./account.types"
import { Template } from "./template.types"
import { ProjectAxisRule } from "./project.types"
import { SurveyInvitation } from "@/widgets/dashboard-participation.widget"
import { Attribute } from "./attribute.types"
import { t } from "@/translate/t"

export type SurveyStatus = "closed" | "launch"

export class SurveyRpsItem{
  stress : boolean = false
  lowAutonomy : boolean = false
  lowSocialSupport : boolean = false
  lowWorkGratitude : boolean = false
  constructor(surveyRpsItem: Partial<SurveyRpsItem> = {}){
    Object.assign(this, surveyRpsItem)
  }
}

export class SurveyRps{
  isoStrain : number = 0
  jobStrain : number = 0
  actives : number = 0
  passives : number = 0
  relaxed : number = 0
  total : number = 0
  list : SurveyRpsItem[] = []
  constructor(surveyRps: Partial<SurveyRps> = {}){
    Object.assign(this, surveyRps)
  }
}

export class Survey{
  attributesByFilter : Attribute[] = [] //List of attributes for specific filter (for select populations)
  Account : Account = new Account() //Account for the survey
  bookmarksOrder : string[] = [] //Order of bookmarks for the dashboard
  createdAt:Date = new Date() //Date of creation 
  defaultFilterIds : string[] = [] //List of default filters selected for the survey
  dateEnd : Date | null = null //Set date end
  dateStart : Date = new Date() //Set date for launch
  filterNames : string[] = [] //List of filters selected (for groupSurvey)
  id : string = "" //Id of the survey
  isInvitationsInProgress : boolean = false //Is invitations generation in progrses
  isSurveyGroup : boolean = false //If survey concat of many surveys
  isTest : boolean = true //Is survey of type test (can generate random data)
  languages : string[] = [] //List of languages for the survey (that will be display in the vote)
  name : string = "" //Name of the survey (given by the admin)
  participationSend : number = 0 //Count of invitation send
  participationStarted : number = 0 //Count of user who have only start the survey
  participationDone : number = 0 //Count of users who have finish the survey
  participationUndone : number = 0 //Count of users who have not start the survey
  ProjectId : string | null = null //Project related to the project
  randomData : boolean = false //Generate randome data for survey
  rps : SurveyRps = new SurveyRps() //RPS data to display
  segmentationByAxesRules : ProjectAxisRule[] = [] //Segmentation by population for the survey
  surveyIds : string[] = [] //List of surveys if group
  template : Template = new Template() //Template choosen for the survey
  updatedAt:Date = new Date() //Last update
  note: number = 0 //Note (score or satisfaction)
  invitations: SurveyInvitation[] = []
  
  constructor(item: Partial<Survey> = {}){
    if (!item) item = new Survey()
    item.segmentationByAxesRules = item.segmentationByAxesRules ? item.segmentationByAxesRules.map(x => new ProjectAxisRule(x)) : []
    item.Account = new Account(item.Account)
    item.rps = new SurveyRps(item.rps)
    item.template = new Template(item.template)
    item.template = new Template(item.template)
    Object.assign(this, item)
  }

  //Date of creation (label)
  get dateCreatedLabel():string{
    return formatDate(this.createdAt, true, false)
  }

  //Date start (label)
  get dateStartLabel():string{
    return t("survey_time_launch", { date : formatDate(this.dateStart, false, false) }) 
  }

  //Label date end
  get dateEndLabel():string{
    return this.dateEnd ? new Date(this.dateEnd).toLocaleDateString() : ""
  }

  get rpsNotConcerned():number{
    return this.participationSend - this.rpsNotstressed - this.rpsStressed
  }

  get rpsNotstressed():number{
    return this.rps.actives + this.rps.passives + this.rps.relaxed
  }

  get rpsStressed():number{
    return this.rps.isoStrain + this.rps.jobStrain
  }

  //Couleur du statut de l'enquête
  get statusColor():string{
    return this.status === "launch" ? "#ff9500" : "#CCCCCC"
  }

  //Statut de l'enquête
  get statusLabel():string{
    return t("survey_" + this.status)
  }

  get status():SurveyStatus{
    return this.dateEnd ? "closed" : "launch"
  }

  //Get participation
  //If hide started just get the done
  getParticipationCount(hideStartedForParticipation:boolean):number{
    return hideStartedForParticipation ? this.participationDone : this.participationDone + this.participationStarted
  }

  //Get the participation Rate
  getParticipationRate(hideStartedForParticipation:boolean):number{
    if (this.participationSend > 0){
      return Math.round(this.getParticipationCount(hideStartedForParticipation) / this.participationSend * 100)
    }else{
      return 0
    }
  }
  
}

export interface SurveyState{
  active : Survey,
  status : string,
  list : Survey[]
}

export const SURVEY_EDIT = 'SURVEY_EDIT'
export const SURVEY_EDIT_ONE = 'SURVEY_EDIT_ONE'
export const SURVEY_ACTIVATE = 'SURVEY_ACTIVATE'
export const SURVEY_GET = 'SURVEY_GET'
export const SURVEY_INIT = 'SURVEY_INIT'
export const SURVEY_REMOVE = 'SURVEY_REMOVE'
export const SURVEY_STATUS = 'SURVEY_STATUS'