import { t } from "@/translate/t"

//Get text for onboarding label
const getLabel = (dateStart: Date, dateEvents: Date[]): string => {
  const time = getTime(dateStart, dateEvents)

  if (!time) {
    return "-"
  } else {
    if (time < 120) {
      return t("utils_time_minutes", {
        value: time,
        s: time > 1 ? "s" : ""
      })
    } else if (time < 60 * 48) {
      return t("utils_time_hours", {
        value: Math.round(time / 60),
        s: Math.round(time / 60) < 2 ? "" : "s"
      })
    } else {
      return t("utils_time_days", {
        value: Math.round(time / 60 / 24),
        s: Math.round(time / 60 / 24) < 2 ? "" : "s"
      })
    }
  }
}

//Get time to do an action
const getTime = (dateStart: Date, dateEvents: Date[]): number | null => {
  if (dateEvents.length) {
    const event = dateEvents.sort((d1, d2) => new Date(d1).getTime() - new Date(d2).getTime())[0]
    const time = new Date(event).getTime() - new Date(dateStart).getTime()
    return Math.round(time / (1000 * 60)) + 1
  } else {
    return null
  }
}

const onboarding = {
  getLabel,
  getTime
}

export default onboarding