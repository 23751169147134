/***
 * HOME-CALENDAR.WIDGET
 * Manage participants for a survey
 */
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { ProjectCalendarEventItem, ProjectState } from "@/redux/project.types";
import Card from "@/components/card";
import { Reminder, ReminderState } from "@/redux/reminder.types";
import { Sending, SendingState } from "@/redux/sending.types";
import ListItem from "@/components/list-item";
import { useEffect, useState } from "react";
import { Email, EmailState } from "@/redux/email.types";
import { flatten, sortBy, uniq } from "lodash";
import Space from "@/components/space";
import Link from "@/components/link";
import ProjectEditReminderModal from "@/modals/project-edit-reminder.modal";
import ProjectEditSendingModal from "@/modals/project-edit-sending.modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from 'react-tooltip'
import PagePlaceholder from "@/components/page-placeholder";
import { STATUS_LOADED, STATUS_LOADING } from "@/redux/_status.types";
import { store } from "@/index";
import { projectActivate, projectFetchAll, projectGet } from "@/redux/project.actions";
import { reminderFetchAll, reminderGet } from "@/redux/reminder.actions";
import { emailFetchAll, emailGet } from "@/redux/email.actions";
import { sendingFetchAll, sendingGet } from "@/redux/sending.actions";
import ProjectAddModal from "@/modals/project-add.modal";
import { Session } from "@/redux/_session.types";
import { ACCOUNT_DEMO } from "@/redux/account.types";
import { NavigateFunction, useNavigate } from "react-router-dom";

interface StateProps extends WithTranslation{
  _session : Session
  email : EmailState
  project: ProjectState
  reminder: ReminderState
  sending : SendingState
}

const MODAL_ADD = "MODAL_ADD"
const MODAL_EDIT_REMINDER = "MODAL_EDIT_REMINDER"
const MODAL_EDIT_SENDING = "MODAL_EDIT_SENDING"

function HomeCalendarWidget(props:StateProps){
  const { t } = props
  const navigate: NavigateFunction = useNavigate()

  //Modal to display
  const [currentModal, setCurrentModal] = useState<null | string>(null)

  //Current reminded to edit
  const [currentReminder, setCurrentReminder] = useState(new Reminder())

  //Current reminded to edit
  const [currentSending, setCurrentSending] = useState(new Sending())

  //Are projects loading
  const [isLoading, setIsLoading] = useState(true)

  //Is demo account
  const [isDemo] = useState(props._session.accountId === ACCOUNT_DEMO.id)

  //Load projects at start
  //If one found set it as active project
  useEffect(() => {

    load()

    async function load(){
      
      const projects:any = await store.dispatch(projectFetchAll())

      if (!projects.error){
        
        //If there is only one project load other data
        if (projects.length === 1){
          store.dispatch(projectActivate(projects[0]))
        }

        let reminders:Reminder[] = []
        let emails:Email[] = []
        let sendings:Sending[] = []

        if (isDemo){

          const projectId = projects[0]

          emails = [
            new Email({
              id : "relaunch",
              name : t("project_demo_relaunch")
            }),
            new Email({
              id : "start",
              name : t("project_demo_start")
            })
          ]

          sendings = [
            new Sending({ 
              id : "start",
              date : new Date(new Date().setDate(new Date().getDate() + 1)),
              EmailId : "start",
              ProjectId : projectId
            }),
            new Sending({ 
              id : "relaunch",
              date : new Date(new Date().setDate(new Date().getDate() + 7)),
              EmailId : "relaunch",
              ProjectId : projectId
            })
          ]

          reminders = [
            new Reminder({
              id : "review",
              title : t("project_demo_review"),
              date : new Date(new Date().setDate(new Date().getDate() + 14)),
              ProjectId : projectId
            })
          ]

        }else{
          const remindersResponse: any = await store.dispatch(reminderFetchAll())
          const emailsResponse: any = await store.dispatch(emailFetchAll())
          const sendingsResponse: any = await store.dispatch(sendingFetchAll())
          
          reminders = remindersResponse.error ? [] : remindersResponse
          emails = emailsResponse.error ? [] : emailsResponse
          sendings = sendingsResponse.error ? [] : sendingsResponse
        }

        //Update store
        store.dispatch(reminderGet(reminders))
        store.dispatch(emailGet(emails, []))
        store.dispatch(sendingGet(sendings)) 

        //Get project ids
        const projectIds = uniq(reminders.map(x => x.ProjectId).concat(sendings.map(x => x.ProjectId)))

        //Update projects list
        store.dispatch(projectGet(isDemo ? projects : projects.filter(x => projectIds.indexOf(x.id) > -1)))

      }else{
        store.dispatch(projectGet([]))
      }

      setIsLoading(false)
    
    }

  }, [
    t,
    isDemo
  ])

  function getEventItems(){
    const events = props.project.active.getEvents(props.sending.list, props.reminder.list, props.email.list)
    const eventsItems = sortBy(flatten(events.map(x => x.items)), "date")
    return eventsItems.filter(x => x.date > new Date()).slice(0, 10)
  }

  function getProject(projectId:string){
    const project = props.project.list.find(x => x.id === projectId)
    return project
  }

  function getProjectName(projectId:string){
    const project = getProject(projectId)
    return project ? project.name : ""
  }

  function openProject(e, projectId:string){
    e.stopPropagation()
    const project = getProject(projectId)
    if (project){
      store.dispatch(projectActivate(project))
      navigate("/project/edit")
    }
  }

  function select(eventItem:ProjectCalendarEventItem){

    if (!isDemo){

      if (eventItem.reminder){
        setCurrentReminder(eventItem.reminder)
        setCurrentModal(MODAL_EDIT_REMINDER)
      }
  
      if (eventItem.sending){
        setCurrentSending(eventItem.sending)
        setCurrentModal(MODAL_EDIT_SENDING)
      }

    }

  }

  return (
    <Card isWithoutPadding
      isFullWidth
      status={isLoading ? STATUS_LOADING : STATUS_LOADED}>
        
      { currentModal === MODAL_ADD &&
      <ProjectAddModal isWithoutTemplate onClose={() => setCurrentModal(null)}/>
      }

      { currentModal === MODAL_EDIT_REMINDER &&
      <ProjectEditReminderModal onClose={() => setCurrentModal(null)}
        editedReminded={currentReminder}
      />
      }

      { currentModal === MODAL_EDIT_SENDING &&
      <ProjectEditSendingModal onClose={() => setCurrentModal(null)}
        editedSending={currentSending}/>
      }

      { getEventItems().length > 0 
      ?
      <div>

        <div style={{ 
          fontSize : 18,
          padding : 24,
          textAlign : "center" 
        }}>
          <b>
            {t("calendar_next_multiple")}
          </b>
        </div>

        <div style={{ height : 378, overflowY : "auto" }}>
          { getEventItems().map(eventItem => 
          <ListItem isEditable
            key={eventItem.id}
            onClick={() => select(eventItem)}>

            <div className="width-10 abs"
              style={{ 
                backgroundColor : eventItem.color,
                height : "100%",
                top : 0,
                left : 0
              }}
            />
            
            <div className="width-10"/>

            <div>

              { (props.project.active.status === "test" && eventItem.sending)
              ?
              <div className="grey-t">
                {eventItem.name}
                &nbsp;
                <FontAwesomeIcon icon={faInfoCircle}
                  className="_hover"
                  data-tip={t("project_calendar_sending_test")}
                  data-for={eventItem.id}
                />
                <ReactTooltip id={eventItem.id}/>
              </div>
              :
              <div>{eventItem.name}</div>
              }

              { props.project.list.length > 1 &&
              <div style={{ fontSize : 12, marginTop : 8 }} 
                className="grey-t _hover"
                onClick={(e) => openProject(e, eventItem.projectId)}>
                {getProjectName(eventItem.projectId)}
              </div>
              }

            </div>

            <Space/>

            <div className="width-20"/>

            <span className="grey-t"
              style={{ minWidth : 120 }}>
              {eventItem.dateLabel}
            </span>
            
          </ListItem>
          )}
        </div>

        <ListItem>
          <Space/>
          { props.project.list.length > 1 
          ?
          <Link onClick={() => navigate("/project/list")}
            isWithoutMargin>
            {t("projects_link")}
          </Link>
          :
          <Link onClick={() => navigate("/project/edit")}
            isWithoutMargin>
            {t("project_get")}
          </Link>
          }
        </ListItem>

      </div>
      :
      <div className="flex flex1 flex-dcol"
        style={{ height : 490 }}>
        <Space/>
        <PagePlaceholder image="results"
          title={t("calendar_empty")}
          onClick={() => navigate("/project/list")}
          button={t("projects_link")}
          help={t("project_calendar_help")}
        />
        <Space/>
      </div>
      }

    </Card>
  )
}

const mapStateToProps = state => ({
  _session : state._session,
  email : state.email,
  project : state.project,
  sending : state.sending,
  reminder : state.reminder
})

export default connect(mapStateToProps)(withTranslation()(HomeCalendarWidget))