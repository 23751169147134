/** 
 * DASHBOARD-NPS.MINI
 * NPS score for the survey
 */

import { withTranslation, WithTranslation } from "react-i18next"
import { connect } from "react-redux"
import { faExclamationTriangle, faFrown, faSmile } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { FilterState } from "@/redux/filter.types";
import { Session } from "@/redux/_session.types";
import { store } from "@/index";
import { SurveyState } from "@/redux/survey.types";
import Card from "@/components/card";
import DashboardNumber from "@/components/dashboard-number";
import { fetchNps } from "@/redux/_nps.actions";
import { sortBy } from "lodash";
import { Topic, TopicState } from "@/redux/topic.types";
import { NavigateFunction, useNavigate } from "react-router-dom";
 
interface StateProps extends WithTranslation{
  _session : Session
  filter : FilterState
  survey : SurveyState
  topic : TopicState
}

function DashboardNpsMiniWidget(props:StateProps) {
  const { t } = props
  const navigate: NavigateFunction = useNavigate()

  const [isLoading, setIsLoading] = useState(true)
  const [nps, setNps] = useState(0)
  const [showData, setShowData] = useState(props._session.modules.nps)
  const [topic, setTopic] = useState(new Topic())

  //Load count of message every times filters are changed
  //All level should be greater than 2
  useEffect(() => {

    async function load(){
      
      setIsLoading(true)
        
      if(!props._session.modules.nps || props.survey.active.randomData){
        setNps(0)
        setShowData(false)
      }

      else{
        const response:any = await store.dispatch(fetchNps(props.filter.dashboard))
        if (response.error){
          setShowData(false)
        }
        else{
          if (!response.length){
            setShowData(false)
          }else{

            const npsQuestion = sortBy(response, "aid")[0]
            setNps(npsQuestion.nps)
            
            const topic = props.topic.list.find(x => x.Questions.map(x => x.aid).indexOf(npsQuestion.aid) > -1)
            setTopic(new Topic(topic))

            setShowData(true)

          }
        }
      }

      setIsLoading(false)

    }

    load()
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props._session.modules.nps,
    props.survey.active.randomData,
    props.filter.dashboard,
  ])

  function getColor(){
    if (nps >= 30){
      return "#20CA7E"
    }else if (nps >= 0){
      return "#f2d600"
    }else{
      return "#eb5a46"
    }
  }

  return (
    <Card className="_hover flex1"
      isWithoutPadding
      onClick={() => navigate("/dashboard/nps")}>

      { showData
      ?
      <DashboardNumber
        color={getColor()}
        isLoading={isLoading}
        count={nps.toFixed(0)}
        icon={nps >= 0 ? faSmile : faFrown}
        header={t("nps_header")}
        title={topic.label ? topic.label : t("nps")}
      />
      :
      <DashboardNumber
        isInactive
        count={t("utils_unavailable")}
        icon={faExclamationTriangle}
        header={t("nps_header")}
        title={t("nps")}
        fontSize={12}
      />
      }
      
    </Card>
  )

}

const mapStateToProps = state => ({
  _session : state._session,
  filter : state.filter,
  survey : state.survey,
  topic : state.topic
})

export default connect(mapStateToProps)(withTranslation()(DashboardNpsMiniWidget))