/**
 * SUPERADMIN-SETTINGS
 * Options for the super-account
 */
import { Component } from "react"
import NavApp from "@/nav/nav.app"
import AdmSettingsWidget from "@/widgets/adm-settings.widget";

class SuperadminSettings extends Component{

  render () {
    return(
      <NavApp>
        <AdmSettingsWidget/>
      </NavApp>
    )
  }

}

export default SuperadminSettings