/** 
 * REPORT-DELETE-PAGES.MODAL
 * Delete report
 */

import { withTranslation, WithTranslation } from "react-i18next"
import { connect } from "react-redux"
import { store } from "@/index";
import { ReportState } from "@/redux/report.types";
import { reportDelete, reportDelete_AsSupervisor, reportRemove } from "@/redux/report.actions";
import { Session } from "@/redux/_session.types";
import Modal from "@/components/modal";
import EnterCodeWidget from "@/widgets/enter-code.widget";
 
interface StateProps extends WithTranslation{
  _session : Session
  report : ReportState
}

interface OwnProps{
  onClose : Function
}

type Props = StateProps & OwnProps

function ReportDeleteModal(props:Props) {
  const { t } = props

  function close(){
    props.onClose()
  }

  async function deleteReport(){
    const reportId = props.report.active.id
    const deleteFunction = props._session.interfaceType === "SUPERVISOR" ? reportDelete_AsSupervisor : reportDelete
    const response: any = await store.dispatch(deleteFunction(reportId))
    if(!response.error){
      store.dispatch(reportRemove(reportId))
      props.onClose()
    }
  }

  return (
    <Modal onClose={close} 
      title={t("report_delete")}>

      <p>
        <b>
          {t("report_delete_confirm", {
            report : props.report.active.name
          })}
        </b>
      </p>

      <EnterCodeWidget onCancel={close}
        onNext={deleteReport}
        status={props.report.status}
      />

    </Modal>
  )

}

const mapStateToProps = state => ({
  _session : state._session,
  report : state.report
})

export default connect(mapStateToProps)(withTranslation()(ReportDeleteModal))