import { faPen } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useState } from "react"
import { connect } from "react-redux"
import { toast } from "react-toastify"
import { Lesson } from "@/redux/lesson.types"
import { Session } from "@/redux/_session.types"
import { t } from "@/translate/t"

interface StateProps{
  _session : Session
}

interface OwnProps{
  lesson : Lesson
  index : number
  onClick? : Function
  onEdit? : Function
}

type Props = StateProps & OwnProps

function LessonItem(props:Props){

  const [hover, setHover] = useState<boolean>(false)

  function click(){
    if(props.onClick){
      if(props.lesson.visible){
        props.onClick()
      }
      else{
        toast(t("lesson_not_visible"), { type : 'error' })
      }
    }
  }

  function editLesson(e){
    e.stopPropagation()
    if(props.onEdit){
      props.onEdit(props.lesson)
    }
  }
  
  return (
    <div
      className={"flex1 lesson-item _hover " + (props.lesson.visible?" clickable":" lesson-item-locked")}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={()=> click()}
    >

      {props.index>-1 &&
      <div className="lesson-item-index" style={{backgroundColor:props._session.accountColors.active}}>
        {"#" + (props.index+1)}
      </div>
      }

      {(props.onEdit && hover) &&
      <div className="lesson-item-edit clickable"
        onClick={(e)=>editLesson(e)}>
        <FontAwesomeIcon icon ={faPen}/>
      </div>
      }

      <img
        className="lesson-item-img"
        alt={props.lesson.label}
        width={145}
        src={props.lesson.picture+""}
        style={!props.lesson.visible?
        {opacity: "0.5"}:{}}
      />

      <div className="lesson-item-label">
        {props.lesson.label}
      </div>

    </div>
  )
}

const mapStateToProps = state => ({
  _session : state._session,
})

export default connect(mapStateToProps)(LessonItem)