/**
 * PROPOSITION
 */

import { PROPOSITION_GET, PROPOSITION_INIT } from "./proposition.types";


//REDUX

export const propositionGet = propositions => ({
  type : PROPOSITION_GET,
  payload : {
    propositions
  }
})

export const propositionInit = () => ({
  type : PROPOSITION_INIT
})