/** 
 * REPORT-SELECT-FILTER-NAME.MODAL
 * Select filters for modal
 */

import { withTranslation, WithTranslation } from "react-i18next"
import { connect } from "react-redux"
import { Session } from "@/redux/_session.types";
import Modal from "@/components/modal";
import { FilterState } from "@/redux/filter.types";
import Chip from "@/components/chip";
import { useState } from "react";
 
interface StateProps extends WithTranslation{
  _session : Session
  filter : FilterState
}

interface OwnProps{
  filterName : string | null
  onClose : Function
  onNext : Function
}

type Props = StateProps & OwnProps

function ReportSelectFilterName(props:Props) {
  const { t } = props

  const [filterName, setFilterName] = useState<null | string>(props.filterName)

  return (
    <Modal onClose={() => props.onClose()}
      onNext={() => {
        props.onNext(filterName)
        props.onClose()
      }}
      isCloseButtonVisible
      title={t("page_filter_name_all")}>

      <p className="grey-t">
        <b>{t("page_filter_name_all_help")}</b>
      </p>

      <div className="flex flex-wrap">
        { props.filter.list.map(filter => 
        <Chip key={filter.id} isWithMargin
          color={filter.name === filterName ? props._session.accountColors.active : undefined}
          onClick={() => setFilterName(filter.name === filterName ? null : filter.name)}>
          {filter.name}
        </Chip>
        )
        }
      </div>

    </Modal>
  )

}

const mapStateToProps = state => ({
  _session : state._session,
  filter : state.filter
})

export default connect(mapStateToProps)(withTranslation()(ReportSelectFilterName))