
interface OwnProps{
  color?: string
  size?: number
}

type Props = OwnProps

const DEFAULT_SIZE: number = 130
const DEFAULT_COLOR: string = "#51d886"

function LogoSvgUtils(props: Props){
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={props.size} height={props.size} viewBox={`0 0 ${DEFAULT_SIZE} ${DEFAULT_SIZE}`} style={{shapeRendering: "geometricPrecision", textRendering: "geometricPrecision", imageRendering: "crisp-edges", fillRule: "evenodd", clipRule: "evenodd" }}>
      <g><path fill={props.color ? props.color : DEFAULT_COLOR} d="M 57.5,11.5 C 81.1114,11.8849 98.2781,22.5515 109,43.5C 115.648,60.8783 114.814,77.8783 106.5,94.5C 106.167,94.8333 105.833,95.1667 105.5,95.5C 101.172,93.6713 97.1718,91.338 93.5,88.5C 104.906,64.3433 99.5726,44.8433 77.5,30C 71.1636,27.0327 64.4969,25.5327 57.5,25.5C 57.5,20.8333 57.5,16.1667 57.5,11.5 Z"/></g>
      <g><path fill={props.color ? props.color : DEFAULT_COLOR} d="M 50.5,29.5 C 73.6098,27.4711 88.4432,37.4711 95,59.5C 97.3513,82.9758 87.1846,97.8091 64.5,104C 38.3789,105.115 23.3789,92.7815 19.5,67C 21.3493,47.3303 31.6826,34.8303 50.5,29.5 Z M 54.5,40.5 C 72.2231,40.2215 82.2231,48.8882 84.5,66.5C 81.0031,88.1801 68.6698,96.3467 47.5,91C 33.3684,82.9501 28.8684,71.1168 34,55.5C 38.5231,47.1719 45.3564,42.1719 54.5,40.5 Z"/></g>
      <g><path fill={props.color ? props.color : DEFAULT_COLOR} d="M 52.5,44.5 C 57.248,44.2748 61.9147,44.7748 66.5,46C 68.5547,46.6872 70.2214,47.8539 71.5,49.5C 70.2138,52.2835 68.3805,54.6169 66,56.5C 58.8877,52.3592 52.5544,53.3592 47,59.5C 43.3868,68.0409 45.5534,74.5409 53.5,79C 63.6125,80.7069 69.2792,76.5403 70.5,66.5C 73.8333,66.5 77.1667,66.5 80.5,66.5C 79.2496,80.2507 71.7496,87.9173 58,89.5C 41.6912,87.1935 34.3578,77.8601 36,61.5C 39.0191,53.3156 44.5191,47.649 52.5,44.5 Z"/></g>
      <g><path fill={props.color ? props.color : DEFAULT_COLOR} d="M 22.5,90.5 C 25.5576,93.0514 28.5576,95.8847 31.5,99C 34.8369,101.671 38.5036,103.837 42.5,105.5C 43.0923,109.922 42.0923,114.089 39.5,118C 27.8401,115.475 18.8401,108.975 12.5,98.5C 15.903,95.8141 19.2363,93.1474 22.5,90.5 Z"/></g>
    </svg>
  )
}

export default LogoSvgUtils