/** 
 * PROJECT-CHECKLIST.MODAL
 * Steps done for a project
 */
import { faCaretRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Chip from "@/components/chip"
import Space from "@/components/space"
import ProjectIntroductionModal from "@/modals/project-introduction.modal"
import { useEffect, useState } from "react"
import { withTranslation, WithTranslation } from "react-i18next"
import { connect } from "react-redux"
import { ProjectState, ProjectStatus, PROJECT_STATUS_LIST } from "@/redux/project.types"
import { Session } from "@/redux/_session.types"

interface StateProps extends WithTranslation{
  _session : Session
  project : ProjectState
}

const MODAL_INTRODUCTION = "MODAL_INTRODUCTION"

function ProjectChecklistWidget(props:StateProps) {
  const { t } = props

  const [checklistIndex, setChecklistIndex] = useState(0)
  const [currentModal, setCurrentModal] = useState<string | null>(null)

  useEffect(() => {
    setChecklistIndex(PROJECT_STATUS_LIST.findIndex(x => x === props.project.active.status))
  }, [props.project.active.status])

  return (
    <div className="flex">

      { currentModal === MODAL_INTRODUCTION &&
      <ProjectIntroductionModal
        onClose={() => setCurrentModal(null)}
        status={props.project.active.status}
      />
      }

      <div
        style={{ textAlign : "center", margin : 20 }}
        className="flex1 rel _hover"
        onClick={() => setCurrentModal(MODAL_INTRODUCTION)}>

        <img alt="status"
          src={require("@/assets/project-status/" + ( checklistIndex === 1 ? "test" : "draft" ) + ".png")}
          style={
            checklistIndex > 1 ? 
            { 
              opacity: 0.5,
              filter: "grayscale(1)"
            }
            : 
            {}
          }
          height={80} width={80}
        />

        <p className={ checklistIndex > 1 ? "grey-t" : ""}>
          <b>{t("project_status_prepare")}</b>
        </p>

        { checklistIndex < 2
        &&
        <div className="flex">
          <Space/>
          <Chip isWithMargin
            onClick={() => setCurrentModal(MODAL_INTRODUCTION)}
            isOutlineColored={checklistIndex===0}
            color={props.project.active.statusColor}>
            {t("project_status_" + props.project.active.status)}
          </Chip>
          <Space/>
        </div>
        }

      </div>

      { PROJECT_STATUS_LIST.slice(2).map((status: ProjectStatus, i: number) =>
      <div key={i}
        style={{ textAlign : "center", margin : 20 }}
        className="flex1 rel _hover"
        onClick={() => setCurrentModal(MODAL_INTRODUCTION)}>

        <FontAwesomeIcon icon={faCaretRight}
          className="abs medgrey-t"
          style={{ 
            fontSize: 48,
            top: "calc(50% - 24px)",
            left: -26
          }}
        />

        <img alt="status"
          src={require("@/assets/project-status/" + status + ".png")}
          style={
            status !== props.project.active.status ? 
            { 
              opacity: 0.5,
              filter: "grayscale(1)"
            }
            : 
            {}
          }
          height={80} width={80}
        />

        { status === props.project.active.status
        ?
        <div className="flex">
          <Space/>
          <Chip isWithMargin
            color={props.project.active.statusColor}>
            {t("project_status_" + status)}
          </Chip>
          <Space/>
        </div>
        :
        <p className="grey-t">
          <b>
            {t("project_status_" + status)}
          </b>
        </p>
        }

      </div>
      )}

    </div>
  )

}

const mapStateToProps = state => ({
  _session : state._session,
  project : state.project
})

export default connect(mapStateToProps)(withTranslation()(ProjectChecklistWidget))