/** 
 * report-params.WIDGET.TSX
 * Éditer les paramètres pour une slide
 */
import { store } from "@/index";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { ReportState } from "@/redux/report.types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { reportEdit, reportEditAndSave } from "@/redux/report.actions";
import { Session } from "@/redux/_session.types";
import Checkbox from "@/components/checkbox";
import { useState } from "react";
import LanguageSelection from "@/components/language-selection";

interface StateProps extends WithTranslation{
  _session : Session
  report : ReportState
}

function ReportParamsWidget(props:StateProps) {
  const { t } = props

  //Edit report description
  const [description, setDescription] = useState(props.report.active.options.description)

  //Edit options for the report
  function editReportOptions(key:string, value:any){
    store.dispatch(
      reportEditAndSave(
        "options", 
        Object.assign({}, props.report.active.options, { [key] : value }) 
      )
    )
  }

  return (
    <div className={"rel report-params" + (props.report.active.paramsOpen ?  "" : " report-params-closed")}>

      <div className={"report-params-arrow abs _hover flex grey-t"  + (props.report.active.paramsOpen ?  "" : " report-params-arrow-closed") }
        onClick={() => store.dispatch(reportEdit("paramsOpen", !props.report.active.paramsOpen))}>
        <FontAwesomeIcon 
          icon={props.report.active.paramsOpen ? faChevronRight : faChevronLeft} 
          className="flex-auto"
        />
      </div>

      <h2>
        {t("report_edit_params")}
      </h2>

      <div className="height-10"/>

      <p className="grey-t">
        {t("report_languages")}
      </p>

      <LanguageSelection languages={["fr", "en"]}
        activeLang={props.report.active.options.language}
        onClick={(e) => editReportOptions("language", e.code)}
      />

      <div className="height-20"/>

      <p className="grey-t">
        {t("utils_description")}
      </p>

      <textarea className="text-area"
        style={{ height : "60px" }}
        onChange={(e) => setDescription(e.target.value)}
        name="description"
        onBlur={() => editReportOptions("description", description)}
        value={description}>
      </textarea>
      
      <div className="height-20"/>

      { props._session.interfaceType === "SUPERVISOR" &&
      <Checkbox active={props.report.active.options.isForPopulations}
        onClick={() => editReportOptions("isForPopulations", !props.report.active.options.isForPopulations)}
        text="Rapport pour une équipe"
      />
      }

    </div>
  )
  
}

const mapStateToProps = state => ({
  _session : state._session,
  report : state.report
})

export default connect(mapStateToProps)(withTranslation()(ReportParamsWidget))