/**
 * EDITBAR-BUTTON
 * Button for the edit bar
 *  */
import { IconDefinition } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Space from "./space"
import ReactTooltip from "react-tooltip"
import { PageContentScreenshotType } from "@/redux/page.types"
import ReportScreenshotImageWidget from "@/widgets/report-screenshot-image.widget"
import { Session } from "@/redux/_session.types"
import { connect } from "react-redux"

interface StateProps {
  _session: Session
}

interface OwnProps {
  icon?: IconDefinition //Icon to display (if no image)
  isActive?: boolean //Selected (take active color)
  isSmall?: boolean //Small button
  onClick: Function //Function trigger on click
  screenshotType?: PageContentScreenshotType //Type for the picture
  text: string //Text to display for the button
}

type Props = StateProps & OwnProps

function EditbarButton(props: Props) {
  return (
    <div
      className={"editbar-button flex flex-dcol _hover" + (props.isSmall ? " editbar-button-sm" : "")}
      style={{
        color: props.isActive ? props._session.accountColors.active : "#CCCCCC",
        borderColor: props.isActive ? props._session.accountColors.active : "#CCCCCC"
      }}
      onClick={() => props.onClick()}>
      {!props.screenshotType && <Space />}
      {props.icon && <FontAwesomeIcon icon={props.icon} className="flex-auto" data-tip={props.text} />}
      {props.screenshotType && (
        <div style={{ width: 26, height: 26, margin: "0px -2px 0px -2px" }} data-tip={props.text}>
          <ReportScreenshotImageWidget type={props.screenshotType} />
        </div>
      )}
      <ReactTooltip />
      {!props.isSmall && props.text}
      {!props.screenshotType && <Space />}
    </div>
  )
}

const mapStateToProps = (state) => ({
  _session: state._session
})

export default connect(mapStateToProps)(EditbarButton)
