/** 
 * NAV-LOGIN
 * Container for login page
 * Background is green
 */

import { connect } from "react-redux"
import { Session } from "@/redux/_session.types"

interface StateProps{
  _session : Session
}

interface OwnProps{
  children : any //Texte à l'intérieur
}

type Props = StateProps & OwnProps

function NavLogin(props:Props) {
  return (
    <div className="flex flex-dcol page-login"
      style={{ backgroundColor : props._session.accountColors.cta }} >
      {props.children}
    </div>
  )
  
}

const mapStateToProps = state => ({
  _session : state._session
})

export default connect(mapStateToProps)(NavLogin)