/***
 * PROJECT-INVITATIONS.WIDGET
 * invitation widget
 */

import { faCompress, faExpand, faLock } from "@fortawesome/free-solid-svg-icons";
import Card from "@/components/card";
import Space from "@/components/space";
import { store } from "@/index";
import { useCallback, useEffect, useState } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { SurveyState } from "@/redux/survey.types";
import { STATUS_LOADED, STATUS_LOADING, STATUS_SAVING } from "@/redux/_status.types";
import { invitationFetchCountWithoutEmail, invitationStatus } from "@/redux/invitation.actions";
import Button from "@/components/button";
import { Invitation, InvitationState } from "@/redux/invitation.types";
import LoadingModal from "@/modals/loading.modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ProjectState } from "@/redux/project.types";
import { toast } from "react-toastify";
import env from "@/env";
import ProjectInvitationsListWidget from "./project-invitations-list.widget";
import Modal from "@/components/modal";
import { fetchPDFList, generatePDFArchive, generatePDFFolder, generatePDFUnique } from "@/redux/_qrcode.actions";
import InvitationLoadbarWidget from "./invitation-loadbar.widget";
import Checkbox from "@/components/checkbox";
import ProjectEditSendingModal from "@/modals/project-edit-sending.modal";
import PageHeader from "@/components/page-header";
import { fetchAttributes } from "@/redux/_archive.actions";
import { attributeGet, attributeStatus } from "@/redux/attribute.actions";
import PageLoader from "@/components/page-loader";
import { AttributeState } from "@/redux/attribute.types";
import Chip from "@/components/chip";
import { DashboardFilters } from "@/redux/filter.types";

interface StateProps extends WithTranslation{
  attribute : AttributeState
  project: ProjectState
  invitation: InvitationState
  survey: SurveyState
}

//List of modals
const MODAL_ADD_SENDING: string = "MODAL_ADD_SENDING" //Send email to everyone
const MODAL_LOADING: string = "MODAL_LOADING" //Loading modal
const MODAL_QRCODE_GENERATING: string = "MODAL_QRCODE_GENERATING" //Alert incomplete archive to download
const MODAL_QRCODE_REGENERATE: string = "MODAL_QRCODE_REGENERATE" //Start regenerating PDF confirm

function ProjectInvitationsWidget(props:StateProps){
  const { t } = props

  //List of attributes selected
  const [attributes, setAttributes] = useState<string[]>([])

  //Current dialog
  const [currentModal, setCurrentModal] = useState<string | null>(null)

  //Get count of users without email adresses
  const [countWithoutEmail, setCountWithoutEmail] = useState<number>(0)

  //If QRCode in download
  const [isQrcodeDownloading, setIsQrcodeDownloading] = useState<boolean>(false)

  //Is QRCode generated for everyone
  const [isQrcodeForEveryone, setIsQrcodeForEveryone] = useState<boolean>(false)

  //Is QRCode generation in process
  const [isQrcodeGenerating, setIsQrcodeGenerating] = useState<boolean>(false)

  //Is QRCode generated for everyone
  const [isQrcodeForPopulation, setIsQrcodeForPopulation] = useState<boolean>(false)

  //Is context menu open (for action on inivtations)
  const [isQrcodeMenuOpen, setIsQrcodeMenuOpen] = useState<boolean>(false)
  
  //List of invitation generated from server
  const [qrcodeInvitationList, setQrcodeInvitationList] = useState<string[]>([])

  //Load attributes on load
  useEffect(() => {

    async function loadAttributes(){
      store.dispatch(attributeStatus(STATUS_LOADING))
      const response:any = await store.dispatch(fetchAttributes(new DashboardFilters(), props.project.active.qrcodeOptions.attributeToDisplay, props.survey.active.id))
      store.dispatch(attributeGet(response.error ? [] : response))
      store.dispatch(attributeStatus(STATUS_LOADED))
    }

    if (isQrcodeForPopulation){
      loadAttributes()
    }else{
      setAttributes([])
      store.dispatch(attributeGet([]))
    }
    
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isQrcodeForPopulation])

  //Add attribute to list
  function addAttributeToList(aid:string){
    if (attributes.indexOf(aid) > -1){
      setAttributes(attributes.filter(x => x !== aid))
    }else{
      setAttributes(attributes.concat([aid]))
    }
  }

  //Function to loadQRCode invitations
  const loadQRCodeInvitations = useCallback(async () => {
    const response : any = await store.dispatch(fetchPDFList(props.survey.active.id))
    if(response.fileNames){
      setQrcodeInvitationList(response.fileNames)
    }
  }, [props.survey.active.id])

  //Get count of users without email
  //For QRCOde only
  const loadInvitationCountWithoutEmail = useCallback(async () => {
    const response:any = await store.dispatch(invitationFetchCountWithoutEmail(props.survey.active.id))
    if(response.count){
      setCountWithoutEmail(response.count)
    }
  }, [props.survey.active.id])

  //On load
  //For qrcode : load count invitation without email and invitations generated
  useEffect(() => {
    if (props.project.active.diffusionMode.qrcode){
      loadInvitationCountWithoutEmail()
      loadQRCodeInvitations()
    }
  }, [
    props.project.active.diffusionMode.qrcode,
    loadInvitationCountWithoutEmail,
    loadQRCodeInvitations
  ])

  //Replacement for <Prompt/>
  useEffect(() => {

    const handleBeforeUnload = (event: any) => {
      if (isQrcodeGenerating) {
        
        //'You have unsaved changes. Are you sure you want to leave?';
        const message: string = t("utils_ask_save_edits")
        event.returnValue = message; // Standard for most browsers
        return message; // For some older browsers
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isQrcodeGenerating]);

  //Click on the line to generation qrcode
  function clickQrcodeInvitation(invitation: Invitation){
    generateQrcodeUnique(invitation)
  }

  //Generate archive for QRCode
  async function downloadQRCodeArchive(){
    setIsQrcodeDownloading(true)
    const response: any = await store.dispatch(generatePDFArchive(props.survey.active.id, props.survey.active.name))
    if(!response.error){
      window.open(env.REACT_APP_URL_QRCODE + "/public/" + response + ".zip", "_blank")
    }
    setIsQrcodeDownloading(false)
    setCurrentModal(null)
  }

  //generate all PDF
  async function generateQrcodeMultiple(){
    store.dispatch(invitationStatus(STATUS_LOADING))
    setQrcodeInvitationList([])


    await store.dispatch(generatePDFFolder(props.survey.active.id, true))
    setIsQrcodeGenerating(true)
    if(isQrcodeMenuOpen){
      setIsQrcodeMenuOpen(false)
    }

    store.dispatch(invitationStatus(STATUS_LOADED))

  }

  //Generate one PDF
  async function generateQrcodeUnique(invitation: Invitation){

    setCurrentModal(MODAL_LOADING)
    await store.dispatch(generatePDFFolder(props.survey.active.id, false))
    const pdf:any = await store.dispatch(generatePDFUnique(props.survey.active.id, invitation.aid, props.project.active.qrcodeOptions)) 
    setCurrentModal(null)
    
    if (!pdf.error){
      loadQRCodeInvitations()
      window.open(env.REACT_APP_URL_QRCODE + "/public/" + props.survey.active.id + "/" + pdf.file, "_blank")
    }
    else{
      toast(t("utils_error"), { type : 'error' })
    }

  }

  function getInvitationsCount(): number {
    return isQrcodeForEveryone? props.invitation.count : countWithoutEmail
  }

  //Stop generation of the QRCodes
  function stopQRCodeGeneration(finished: boolean){
    setIsQrcodeGenerating(false)
    if(finished){
      toast(t("process_finished"))
      if(!isQrcodeMenuOpen){
        setIsQrcodeMenuOpen(true)
      }
    }
    else{
      toast(t("process_stopped"))
    }
    loadQRCodeInvitations()
  }

  return (
    <div>

      <PageHeader title={t("project_invitations")}
        subtitle={t("project_invitations_help")}>

        { props.survey.active.status === "launch" &&
        <div className="flex">
        
          { props.project.active.diffusionMode.email &&
          <Button onClick={() => setCurrentModal(MODAL_ADD_SENDING)}>
            {t("project_sending_now")}
          </Button>
          }

          { props.project.active.diffusionMode.qrcode &&
          <Button isWithBorder
            onClick={() => setIsQrcodeMenuOpen(true)}
            className="primary">
            {isQrcodeGenerating ? t("invitations_are_generating") : t("qrcode_download")}
          </Button>
          }

        </div>
        }

      </PageHeader>

      <Card isWithoutPadding
        className="flex1 rel">

        { currentModal === MODAL_ADD_SENDING &&
        <ProjectEditSendingModal onClose={() => setCurrentModal(null)} isNow/>
        }

        { currentModal === MODAL_LOADING &&
        <LoadingModal/>
        }

        { currentModal === MODAL_QRCODE_GENERATING &&
        <Modal isCloseButtonVisible
          status={isQrcodeDownloading ? STATUS_SAVING : undefined}
          onClose={() => setCurrentModal(null)}
          onNext={downloadQRCodeArchive}>
          <div>{t("generation_in_progress")}</div>
        </Modal>
        }

        { currentModal === MODAL_QRCODE_REGENERATE &&
        <Modal isCloseButtonVisible
          status={isQrcodeGenerating ? STATUS_SAVING : undefined}
          onClose={() => setCurrentModal(null)}
          onNext={() => { 
            generateQrcodeMultiple(); 
            setCurrentModal(null)
          }}>
          <div>{t("restart_generation")}</div>
        </Modal>
        }

        {( isQrcodeMenuOpen || isQrcodeGenerating ) &&
        <div className="flex"
          onClick={isQrcodeMenuOpen? () => setIsQrcodeMenuOpen(false) : undefined}
          style={
            isQrcodeMenuOpen ? 
            {
              //modal-container
              top: "0px",
              left: "0px",
              position: "fixed",
              zIndex: 100,
              backgroundColor: currentModal === null ? "#00000040" : undefined,
              height: "100vh",
              width: "100vw"
            } 
            : 
            {
              //card
              borderRadius: "8px",
              boxShadow: "0px 4px 24px rgba(0,0,0,0.1)",
              position: "fixed",
              padding: 10,
              right: 30,
              bottom: 20,
              zIndex: 100,
              backgroundColor : "white"
            }
          }>

          <div onClick={e => { e.stopPropagation() }}
            className={isQrcodeMenuOpen ? "modal" : "flex1"}
            style={isQrcodeMenuOpen ? { width : "100%" } : {}}>

            <div className="flex">
              <b>{(isQrcodeGenerating ? t("invitations_are_generating") : t("qrcode_download")) + "  "}</b>
              <Space />
              {isQrcodeMenuOpen?
              <FontAwesomeIcon icon={faCompress} onClick={() => setIsQrcodeMenuOpen(false)} className="_hover"/>
              :
              <FontAwesomeIcon icon={faExpand} onClick={() => setIsQrcodeMenuOpen(true)} className="_hover"/>
              }
            </div>

            { isQrcodeGenerating ?
            <InvitationLoadbarWidget
              attributes={attributes}
              surveyId={props.survey.active.id}
              count={isQrcodeForEveryone ? props.invitation.count : countWithoutEmail}
              isForEveryone={isQrcodeForEveryone}
              onStop={(finished: boolean)=>{ stopQRCodeGeneration(finished)} }
            />
            :
            <div>
              <div className="height-40" style={{width:"100%"}}/>

              <Checkbox active={!isQrcodeForEveryone} 
                onClick={() => isQrcodeGenerating ? toast(t("invitations_are_generating"), { type : 'error' }) : setIsQrcodeForEveryone(!isQrcodeForEveryone) }
                text={t("survey_letter_generate_everyone")}
              />

              <Checkbox active={isQrcodeForPopulation} 
                onClick={() => isQrcodeGenerating ? toast(t("invitations_are_generating"), { type : 'error' }) : setIsQrcodeForPopulation(!isQrcodeForPopulation) }
                text={t("survey_letter_generate_population")}
              />

              { isQrcodeForPopulation &&
              <PageLoader status={props.attribute.status}>
                <div className="flex flex-wrap">
                  { props.attribute.list.map(attribute => 
                  <Chip key={attribute.aid} isWithMargin
                    onClick={() => addAttributeToList(attribute.aid)}
                    color={attributes.indexOf(attribute.aid) > -1 ? "#ff9500" : ""}>
                    {attribute.name}
                  </Chip>
                  )
                  }
                </div>
              </PageLoader>
              }

              <div className="height-40"/>

              <div className="flex">
                <Space/>

                <Button
                  icon={getInvitationsCount() < 1 ? faLock : undefined} 
                  isWithBorder
                  isLoading={props.invitation.status === STATUS_LOADING}
                  className={(getInvitationsCount() > 0 && qrcodeInvitationList.length < props.invitation.count) ? "secondary" : "light"}
                  onClick={() => getInvitationsCount() < 1 ? toast(t("error_project_no_recipients"), {type : "error"}) : (qrcodeInvitationList.length < props.invitation.count ? generateQrcodeMultiple() : setCurrentModal(MODAL_QRCODE_REGENERATE))}>
                  {t("survey_letter_generate") + ` (${getInvitationsCount()})`}
                </Button>
              
                { qrcodeInvitationList.length > 0 &&
                <Button isLoading={isQrcodeDownloading || isQrcodeGenerating} 
                  className={isQrcodeGenerating ? "light" : "primary"}
                  onClick={isQrcodeGenerating ? () => setCurrentModal(MODAL_QRCODE_GENERATING) : downloadQRCodeArchive}>
                    {t("download_archive") + ` (${qrcodeInvitationList.length})`}
                </Button>
                }

              </div>
            </div>
            }
          </div>
        </div>
        }

        <ProjectInvitationsListWidget onClickQrcode={(invitation:Invitation) => clickQrcodeInvitation(invitation)}/>

      </Card>

    </div>
  )
}

const mapStateToProps = state => ({
  attribute : state.attribute, 
  project : state.project,
  survey : state.survey,
  invitation : state.invitation
})

export default connect(mapStateToProps)(withTranslation()(ProjectInvitationsWidget))