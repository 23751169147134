import { t } from '@/translate/t';
import { useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

interface OwnProps{
  content?: any
  onChange?: Function
  readonly?: boolean
}

const defaultContent: any = {"entityMap":{},"blocks":[{"key":"637gr","text": t("lesson_text_placeholder"),"type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}]}

type Props = OwnProps

function HtmlEditor(props:Props){

  const [editorState, setEditorState] = useState<any>(props.content ? props.content : defaultContent)

  function edit(value:any){
    setEditorState(value)
    if(props.onChange){
      props.onChange(value)
    }
  }

  return (
    <Editor
      readOnly={props.readonly}
      toolbarHidden={props.readonly}
      defaultContentState={editorState}
      wrapperClassName="demo-wrapper"
      editorClassName="demo-editor"
      onContentStateChange={props.readonly ? undefined : edit}
    />
  )
}

export default HtmlEditor