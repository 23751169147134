/** 
 * MENU-ACCOUNTS.WIDGET
 * Display menu-account on the left side of the screen
 */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft, faCaretDown, faCaretRight, faPlus } from "@fortawesome/free-solid-svg-icons"
import env from "@/env"
import { withTranslation, WithTranslation } from "react-i18next"
import { connect } from "react-redux"
import { AccountState } from "@/redux/account.types"
import { Session } from "@/redux/_session.types"
import LoginModal from "@/modals/login.modal"
import { useState } from "react"
import EditAccountModal from "@/modals/edit-account.modal"
import { getIconUrl } from "@/utils/default-icons.utils"
import { NavigateFunction, useNavigate } from "react-router-dom"

interface StateProps extends WithTranslation{
  account : AccountState
  _session : Session
}

const MODAL_ADD = "MODAL_ADD" //DIsplay dialog to create a new account

function MenuAccountsWidget(props:StateProps) {
  const { t } = props
  const navigate: NavigateFunction = useNavigate()

  //CurrentId of the account to access => trigger login modal
  const [currentAccountId, setCurrentAccountId] = useState<string | null>(null)

  //Display dialog
  const [currentModal, setCurrentModal] = useState<string | null>(null)

  //Show texts on hover on the bar
  const [isExpanded, setIsExpanded] = useState(false)

  //Loading status when going back to superadmin account
  const [isLoadingBack, setIsLoadingBack] = useState(false)

  //Show all account (by default when connected in admin account don't show other accounts for privacy)
  const [showAllAccount, setShowAllAccount] = useState(props._session.interfaceType === "SUPERADMIN")
  
  //Go back to supervisor interface
  function back(){
    setIsLoadingBack(true)
  }

  //Onclick on super account logo
  //If connected as admin go back to superadmin account
  function clickSuperAccount(){
    if (props._session.interfaceType === "SUPERADMIN"){
      navigate("/home/account")
    }else{
      back()
    }
  }

  //Classname depends of hover
  function getClassName(){
    const className = "menu-accounts medgrey-b"
    return isExpanded ? className + " menu_account-expanded" : className
  }

  return (
    props._session.connectedAsSuperadmin
    ?
    <div className="menu-accounts-container rel">
      <div className={getClassName()}
        onMouseEnter={() => setIsExpanded(true)}
        onMouseLeave={() => setIsExpanded(false)}>

        { currentAccountId &&
        <LoginModal authLevel={2}
          onClose={() => { setCurrentAccountId(null) ; setShowAllAccount(false) ; setIsExpanded(false) }}
          accountIdForSuperadmin={currentAccountId}
          email={props._session.email}
          isSupervisor={props._session.connectedAsSupervisor}
        />
        }

        { isLoadingBack &&
        <LoginModal authLevel={2}
          onClose={() => { setIsLoadingBack(false) ; setShowAllAccount(true) ; setIsExpanded(false) }}
          email={props._session.email}
          accountIdForSuperadmin={props._session.connectedAsSupervisor ? props._session.superaccountId : undefined}
          isSupervisor={props._session.connectedAsSupervisor}
        />
        }

        { currentModal === MODAL_ADD &&
        <EditAccountModal isNew={true}
          onClose={() => setCurrentModal(null)}
        />
        }

        { props._session.userRole === "ADMIN" &&
        <div className="flex menu-accounts-line">

          <img alt="account" 
            className="menu-accounts-circle menu-accounts-superadmin _hover"
            data-place="right"
            onClick={clickSuperAccount}
            style={{ backgroundColor : props._session.superaccountColor }}
            src={
              props._session.superaccountImage ?
              env.REACT_APP_URL_SPACE.concat("/Accounts/", props._session.superaccountId, ".png?" + props._session.logoLastUpdate) :
              require('@/assets/logo-sm-account.png')
            }>
          </img>

          { isExpanded &&
          <div className="menu-accounts-name grey-t _hover"
            onClick={clickSuperAccount}>
            <b>{props._session.superaccountName}</b>
          </div>
          }

        </div>
        }

        <div className="menu-account-separator medgrey-b"/>

        { props._session.interfaceType === "ADMIN" &&
        <div>

          { props._session.userRole === "ADMIN" &&
          <div className="menu-accounts-line flex">

            <div className="menu-accounts-circle _hover medgrey-b medgrey-t menu-accounts-add flex"
              onClick={back}>
              <FontAwesomeIcon icon={faArrowLeft}/>
            </div>

            { isExpanded &&
            <div className="menu-accounts-name grey-t _hover"
              onClick={back}>
              {t("utils_back")}
            </div>
            }

          </div>
          }
          
          <div className="menu-accounts-line flex">

            { showAllAccount ?
            <div className="menu-accounts-circle _hover medgrey-b medgrey-t menu-accounts-add flex"
              onClick={() => setShowAllAccount(false)}>
              <FontAwesomeIcon icon={faCaretDown}/>
            </div>
            :
            <div className="menu-accounts-circle _hover medgrey-b medgrey-t menu-accounts-add flex"
              onClick={() => setShowAllAccount(true)}>
              <FontAwesomeIcon icon={faCaretRight}/>
            </div>
            }

            { isExpanded &&
            showAllAccount ?
            <div className="menu-accounts-name grey-t _hover"
              onClick={() => setShowAllAccount(false)}>
              {t("account_show_less")}
            </div>
            :
            <div className="menu-accounts-name grey-t _hover"
              onClick={() => setShowAllAccount(true)}>
              {t("account_show_more")}
            </div>
            }

          </div>

        </div>
        }

        <div className="menu-accounts-scrollbar"
          style={{
            height : props._session.interfaceType === "ADMIN" ? "calc(100vh - 150px)" : "calc(100vh - 72px)"
          }}>

          { showAllAccount && props.account.list.map(account =>
          <div key={account.id}
            className="flex menu-accounts-line">

            <img alt="account" 
              className="menu-accounts-circle _hover"
              data-place="right"
              style={{ 
                backgroundColor : account.colors.brandPrimary,
                borderColor : account.colors.brandPrimary
              }}
              onClick={() => setCurrentAccountId(account.id)}
              src={
                account.options.image ?
                env.REACT_APP_URL_SPACE.concat("/Accounts/", account.id, ".png?" + props._session.logoLastUpdate) :
                getIconUrl(account.options.defaultIcon)
              }>
            </img>

            { isExpanded &&
            <div className="menu-accounts-name grey-t _hover"
              onClick={() => setCurrentAccountId(account.id)}>
              <b>{account.name}</b>
            </div>
            }

          </div>
          )
          }

          { props._session.connectedAsSupervisor &&
          <div className="menu-accounts-line flex">

            <div className="menu-accounts-circle _hover medgrey-b medgrey-t menu-accounts-add flex"
              onClick={() => setCurrentModal(MODAL_ADD)}>
              <FontAwesomeIcon icon={faPlus}/>
            </div>

            { isExpanded &&
            <div className="menu-accounts-name grey-t _hover"
              onClick={() => setCurrentModal(MODAL_ADD)}>
              {t("account_add")}
            </div>
            }

          </div>
          }

          <div className="height-80"/>

        </div>

      </div>
    </div>
    :
    null
    
  )
}

const mapStateToProps = state => ({
  _session : state._session,
  account : state.account
})

export default connect(mapStateToProps)(withTranslation()(MenuAccountsWidget))