/**
 * CHECK-DELETE
 * Enter code before doing a sensible operation
 */

import Button from "@/components/button"
import { useState } from "react"
import { withTranslation, WithTranslation } from "react-i18next"
import Space from "@/components/space"
import TextInput from "@/components/text-input"
import { toast } from "react-toastify"
import { STATUS_SAVING } from "@/redux/_status.types"

interface OwnProps{
  labelCta? : string
  onCancel : Function
  onNext : Function
  status : string
}

type Props = OwnProps & WithTranslation

function CheckDeleteWidget(props:Props) {
  const { t } = props
  
  //Value in text input
  const [code, setCode] = useState("")
  const [initialCode] = useState(Math.floor(Math.random() * (9999 - 1000 + 1) + 1000).toString())

  //Check if the name in textInput match with the code
  //> if ok go next
  function next(){
    if (code !== initialCode){
      toast(t("utils_delete_enter_error"), { type : 'error' })
    }else{
      props.onNext()
    }
  }

  return (
    <div>

      <p>
        {t("utils_delete_enter", { 
          name : initialCode
        })}
      </p>
      
      <TextInput title={t("utils_delete_label")}
        onChange={(e) => setCode(e.value)}
        placeholder={initialCode}
        value={code}
      />
      
      <div className="height-40"/>

      <div className="flex">
        <Space/>
        <Button onClick={() => props.onCancel()}>
          {t("utils_cancel")}
        </Button>
        <Button onClick={next}
          isLoading={props.status === STATUS_SAVING}
          className="warning">
          {props.labelCta ? props.labelCta : t("utils_delete")}
        </Button>
      </div>

    </div>
  )
  
}

export default withTranslation()(CheckDeleteWidget)