/**
 * TEXT-INPUT
 */
import { faCheckCircle, faInfoCircle, faSearch, faTimesCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useState } from "react"
import { connect } from "react-redux"
import { Session } from "@/redux/_session.types"
import { STATUS_SAVED, STATUS_SEARCHING } from "@/redux/_status.types"
import ContextMenu from "./context-menu"
import ListItem from "./list-item"
import SaveIcon from "./save-icon"
import SearchBar from "./search-bar"
import Space from "./space"

interface StateProps {
  _session: Session
}

interface OwnProps {
  autoComplete?: string //Auto completion
  autoFocus?: boolean //Is focus auto
  children?: any //Add elements inside
  disabled?: boolean //Is text input disabled
  error?: boolean //Red outline if error
  help?: string //Display help text just below
  id?: string //Id of text input
  isCheck?: boolean //Display check icon before input
  isFullWidth?: boolean //Take width of parent component
  isWhiteMode?: boolean //Display input in white
  min?: number //Min value for number type input
  max?: number //Max value for number type input
  step?: number //Step value for number type input
  onBlur?: Function //Action to trigger when i lose focus (save for exemple)
  onChange?: Function //Action to trigger when i edit text
  onHelp?: Function //Trigger action on click on info
  onKeyDown?: Function //Action when i click on specific key ("Enter" for exemple)
  placeholder?: string //Placeholder for input
  required?: boolean //If no value or empty string red outline
  status?: string //Status (for save icon)
  title?: string //Label just above input
  type?: InputTypes //Type of text input
  value: any //Text value to display
  values?: string[] //List of values you can pick for text input
  warning?: string //Orange outline if warning
  width?: number | string //Set defined width
}

type Props = StateProps & OwnProps
type InputTypes = "datetime-local" | "date" | "password" | "search" | "email" | "number" | "text"

function TextInput(props: Props) {
  //Search text (if list of values)
  const [currentSearch, setCurrentSearch] = useState("")

  //Show status after edit
  const [showStatus, setShowStatus] = useState(false)

  //Search value
  const [isSearching, setIsSearching] = useState(false)

  //Show values in order to pick one
  const [isValuesOpen, setIsValuesOpen] = useState(false)

  function clickHelp() {
    if (props.onHelp) {
      props.onHelp()
    }
  }

  function getClassName() {
    let className = "text-input"
    if (props.isFullWidth) className = className + " text-input-fullwidth"
    if (props.isWhiteMode) className = className + " text-input-white"
    if (props.type === "number") className = className + " text-input-number"
    if (props.error) className = className + " text-input-error"
    return className
  }

  //Action to trigger when i loose focus
  function handleBlur() {
    if (props.onBlur) {
      props.onBlur()
      setShowStatus(true)

      setTimeout(() => {
        setShowStatus(false)
      }, 2000)
    }
  }

  //Action onChange text
  function handleChange(event) {
    if (props.onChange) {
      props.onChange({
        id: event.target.id,
        value: event.target.value
      })
    }
  }

  //Action on keyDown
  function handleKeyDown(event) {
    if (props.onKeyDown) {
      props.onKeyDown(event.key)
    }
  }

  return (
    <div
      className={getClassName()}
      style={{
        width: typeof props.width === "string" ? props.width : props.width ? props.width + "px" : ""
      }}>
      {props.title && (
        <div className="text-input-title flex">
          <span className={props.isWhiteMode ? "white-t" : "grey-t"}>{props.title}</span>
          <Space />
          {props.onHelp && (
            <div className="dropdown-header">
              <FontAwesomeIcon
                icon={faInfoCircle}
                onClick={clickHelp}
                style={{
                  color: props.isWhiteMode ? "white" : "",
                  marginTop: "0px"
                }}
              />
            </div>
          )}
        </div>
      )}

      {props.children}

      <div className="flex rel">
        {props.error && (
          <div className="text-input-check red-t flex">
            <FontAwesomeIcon icon={faTimesCircle} />
          </div>
        )}

        {props.isCheck && (
          <div style={{ color: props._session.accountColors.active }} className="text-input-check flex">
            <FontAwesomeIcon icon={faCheckCircle} />
          </div>
        )}

        <input
          autoComplete={props.autoComplete}
          placeholder={props.placeholder}
          disabled={props.disabled}
          readOnly={props.disabled}
          required={props.required}
          id={props.id}
          value={props.value ? props.value : ""}
          onBlur={handleBlur}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          type={props.type}
          min={props.min ? props.min : undefined}
          max={props.max ? props.max : undefined}
          step={props.step ? props.step : undefined}
          autoFocus={props.autoFocus}></input>

        {props.values && (
          <div className="_hover text-input-search grey-t" onClick={() => setIsValuesOpen(true)}>
            <FontAwesomeIcon icon={faSearch} />
          </div>
        )}

        {props.status && <SaveIcon status={showStatus ? props.status : null} />}

        {isValuesOpen && (
          <ContextMenu onClose={() => setIsValuesOpen(false)}>
            <SearchBar
              onSearch={(value) => {
                setCurrentSearch(value)
                setIsSearching(false)
              }}
              status={isSearching ? STATUS_SEARCHING : STATUS_SAVED}
              onSearchStart={() => setIsSearching(true)}
            />

            <div style={{ height: "300px", overflowY: "auto" }}>
              {props.values
                ?.filter((x) =>
                  currentSearch.length ? x.toLowerCase().indexOf(currentSearch.toLowerCase()) > -1 : true
                )
                .map((value, i) => (
                  <ListItem
                    key={i}
                    isSmall
                    isEditable
                    onClick={() => {
                      setIsValuesOpen(false)
                      handleChange({ target: { id: props.id, value } })
                    }}>
                    {value}
                  </ListItem>
                ))}
            </div>
          </ContextMenu>
        )}
      </div>

      {props.help && <div className="text-input-help">{props.help}</div>}
    </div>
  )
}

const mapStateToProps = (state) => ({
  _session: state._session
})

export default connect(mapStateToProps)(TextInput)
