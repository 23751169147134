/** 
 * TOGGLE-BUTTON
 */


import { faCheck } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { connect } from "react-redux"
import { Session } from "@/redux/_session.types"

interface StateProps{
  _session : Session
}

interface OwnProps{
  color? : string
  text? : string
  isActive : boolean
  onToogle : Function
}

type Props = StateProps & OwnProps

function ToggleButton(props: Props) {

  return (
    <div className="toggle-button _hover"
      onClick={() => props.onToogle()}
      style={{ backgroundColor : props.isActive ? (props.color ? props.color : props._session.accountColors.active) : "" }}>

      { props.text &&
      <div className={"toggle-button-text flex" + (props.isActive ? " toggle-button-active" : "")}>
        {props.text}
      </div>
      }

      <div className={"flex toggle-button-circle" + (props.isActive ? " toggle-button-active" : "")}>
        { props.isActive && <FontAwesomeIcon icon={faCheck}/> }
      </div>

    </div>
  )
}

const mapStateToProps = state => ({
  _session : state._session
})

export default connect(mapStateToProps)(ToggleButton)