/** 
 * PAGE-HEADER
 * Header of page (title and subtitle)
 */
import Space from "@/components/space"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons"
import { connect } from "react-redux"
import { Session } from "@/redux/_session.types"

interface StateProps {
  _session : Session
}

interface OwnProps{
  children? : any
  subtitle? : string
  title : string
  warning? : string
}

type Props = StateProps & OwnProps

function PageHeader(props:Props) {

  /*useEffect(() => {
    document.title = "IDTree - " + props.title
  }, [props.title])*/

  return (
    <div className="flex page-header">
      <div className="flex1 page-header-titles">

        <h1>
          {props.title}
        </h1>

        <p>
          {props.subtitle}
        </p>

        {props.warning &&
        <div className="grey-t page-header-warning">
          <FontAwesomeIcon
            style={{ color : props._session.accountColors.active }}
            icon={faExclamationTriangle}/>
          {props.warning}
        </div>
        }

      </div>

      <div className="flex flex-dcol"
        style={{ marginLeft : '12px' }}>
        <Space/>
        <div className="flex">
          { props.children }
        </div>
        <div className="flex1"></div>
        <Space/>
      </div>
      
    </div>

  )

}

const mapStateToProps = state => ({
  _session : state._session
})

export default connect(mapStateToProps)(PageHeader)