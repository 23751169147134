/** 
 * DASHBOARD-MESSAGES.WIDGET
 * List of messages
 */

import { connect } from "react-redux"
import { useEffect, useState } from "react";
import { FilterState } from "@/redux/filter.types";
import PageLoader from "@/components/page-loader";
import { store } from "@/index";
import { INSUFFICIENT_PARTICIPATION, STATUS_LOADED, STATUS_LOADING } from "@/redux/_status.types";
import { questionStatus } from "@/redux/question.actions";
import { Question, QuestionState } from "@/redux/question.types";
import NoResultsWidget from "./no-results.widget";
import { fetchQuestions } from "@/redux/_archive.actions";
import DashboardQuestionOpenWidget from "./dashboard-question-open.widget";
import { Topic, TopicState } from "@/redux/topic.types";
import { SurveyState } from "@/redux/survey.types";
import { generateTopics } from "@/redux/_generate.actions";
import PagePlaceholder from "@/components/page-placeholder";
import { withTranslation, WithTranslation } from "react-i18next";
import { sortBy } from "lodash";
import { MessageState } from "@/redux/message.types";

interface StateProps extends WithTranslation{
  survey : SurveyState
  topic : TopicState
  filter : FilterState
  message : MessageState
  question : QuestionState
}

//Dashboard item to display
interface DashboardQuestion{
  question : Question
  topic : Topic
  count : number
}

function DashboardMessagesWidget(props:StateProps) {
  const { t } = props

  //Total of quesiton open (if 0 display message)
  const [dashboardQuestions, setDashboardQuestions] = useState<DashboardQuestion[]>([])

  //Load questions on every change on the dashboard filters
  useEffect(() => {

    async function loadQuestions(){

      //Set loader
      store.dispatch(questionStatus(STATUS_LOADING))

      //Load questions in order to have messages count
      //Different routes if random data or real survey data
      let responses:any = []
      if (props.survey.active.randomData){
        
        const responseOpen:any = await store.dispatch(generateTopics(
          props.survey.active.template.id, 
          props.survey.active.isTest ? props.survey.active.id : null, //SurveyId, 
          false, 
          props.filter.dashboard
        )) 

        if (!responseOpen.error) responses = responseOpen.questions

      }else{
        const response: any = await store.dispatch(fetchQuestions(props.filter.dashboard, "open"))
        if(response.length > 0 && !response.error){
          responses = response
        }
      }

      //Map questions to current topics
      let newDashboardQuestions:DashboardQuestion[] = []
      props.topic.list.forEach(topic => {
        topic.Questions.filter(x => (x.type === "choice" && x.withMessage) || x.type === "open").forEach(question => {
          
          const currentQuestion = props.survey.active.randomData ?
            responses.find(x => x.aid === question.id && x.fkTopic === topic.id) :
            responses.find(x => x.aid === question.aid && x.fkTopic === topic.aid)

          if (currentQuestion){
            question.prompt = currentQuestion.prompt
            question.isPromptCustom = currentQuestion.isPromptCustom
          }

          newDashboardQuestions.push({
            count : currentQuestion ? currentQuestion.messagesCount : 0,
            question,
            topic
          })

        })
      })

      //Remove non used question for observer with limited access
      if (props.filter.observerAttributes.length){
        newDashboardQuestions = newDashboardQuestions.filter(x => x.count > 0)
      }

      //Save dashboardQuestions
      setDashboardQuestions(sortBy(newDashboardQuestions, "count").reverse())

      //Set end of loading
      store.dispatch(questionStatus(STATUS_LOADED))

    }

    loadQuestions()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.survey.active.randomData,
    props.filter.dashboard
  ])

  return (
    props.question.status === INSUFFICIENT_PARTICIPATION
    ?
    <NoResultsWidget/>
    :
    <PageLoader status={props.question.status}>

      { dashboardQuestions.length === 0 &&
      <PagePlaceholder image="sherlock"
        title={t("dashboard_messages_empty")}
      />
      }

      { dashboardQuestions.map(item => 
      <DashboardQuestionOpenWidget key={item.question.id}
        currentQuestion={item.question}
        currentTopic={item.topic}
        count={item.count}
        isOpenByDefault={dashboardQuestions.length === 1}
        showSegmentation
      />
      )
      }

      { 
      /*
      MAGIC BACKEND LISI
      A SUPPRIMER
      props.survey.active.id === 'de52af65-7660-4b80-b273-a70c46330957' &&
      <DashboardMessageByAttributeWidget/>
      */
      }
      
    </PageLoader>
  )

}

const mapStateToProps = state => ({
  survey : state.survey,
  topic : state.topic,
  filter : state.filter,
  message : state.message,
  question : state.question
})

export default connect(mapStateToProps)(withTranslation()(DashboardMessagesWidget))