/** 
 * REGROUPING.ACTIONS
 * Actions about regrouping
 */

import { Regrouping, REGROUPING_ACTIVATE, REGROUPING_ADD, REGROUPING_COPY, REGROUPING_EDIT, REGROUPING_GET, REGROUPING_INIT, REGROUPING_REMOVE, REGROUPING_STATUS } from "./regrouping.types"


/* REDUX */

export const regroupingActivate = (regrouping: Regrouping) => ({
  type : REGROUPING_ACTIVATE,
  payload : {
    regrouping
  }
})

export const regroupingAdd = (filterName: string) => ({
  type : REGROUPING_ADD,
  payload : {
    filterName
  }
})

export const regroupingCopy = (regrouping: Regrouping) => ({
  type : REGROUPING_COPY,
  payload : {
    regrouping
  }
})

export const regroupingEdit = (key: string, value: any) => ({
  type : REGROUPING_EDIT,
  payload : {
    key,
    value
  }
})

export const regroupingGet = (regroupings :Regrouping[]) => ({
  type : REGROUPING_GET,
  payload : {
    regroupings
  }
})

export const regroupingInit = () => ({
  type : REGROUPING_INIT
})

export const regroupingRemove = (id: string) => ({
  type : REGROUPING_REMOVE,
  payload : {
    id
  }
})

export const regroupingStatus = (status: string) => ({
  type : REGROUPING_STATUS,
  payload : {
    status
  }
})



/** API */

export const regroupingDestroy = (id: string) => ({
  type : 'API',
  payload : {
    method : "DELETE",
    url : "/regrouping/" + id
  }
})

export const regroupingFetch = (filtersName: string[]) => ({
  type : 'API',
  payload : {
    method : "GET",
    url : "/regroupings",
    data : {
      filtersName
    }
  }
})

export const regroupingUpdate = (regrouping: Regrouping) => ({
  type : 'API',
  payload : {
    method : "POST",
    url : "/regrouping/update",
    data : {
      regrouping,
    }
  }
})