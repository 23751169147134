/** 
 * NAV-AVATAR
 * Picture of the user in the menu top
 */
import { useState } from "react"
import { connect } from "react-redux"
import ContextMenu from "./context-menu"
import { Session } from "@/redux/_session.types"
import env from "@/env"

interface StateProps{
  _session : Session
}

interface OwnProps{
  children : any //Elements for context
}

type Props = StateProps & OwnProps

function NavAvatar(props:Props) {

  //Is content menu open
  const [isChildrenVisible, setIsChildrenVisible] = useState(false)

  //Action on clic
  //Open context menu
  function click(){
    setIsChildrenVisible(!isChildrenVisible)
  }

  //Close context menu
  function closeContextMenu(){
    setIsChildrenVisible(false)
  }

  return (
    <div className="rel flex">

      <div className="page-navigation-text"
        onClick={click}
        style={{ 
          cursor : 'pointer', 
          color : props._session.accountColors.brandText
        }}>
        {props._session.userFirstname}
      </div>

      { props._session.connectedAsSupervisor
      ?
      <img alt="supervisor" className="page-navigation-circle-img" 
        style={{ backgroundColor : 'white' }}
        onClick={click}
        src={env.REACT_APP_URL_SPACE.concat("/Supervisors/", props._session.supervisorId, ".png")}>
      </img>
      :
      (
        props._session.userOptions.image
        ?
        <img alt="user" className="page-navigation-circle-img" 
          style={{ backgroundColor : 'white' }}
          onClick={click}
          src={env.REACT_APP_URL_SPACE.concat("/Users/", props._session.userId, ".png?")}>
        </img>
        :
        ( 
          props._session.userOptions.googlePhotoURL
          ?
          <img alt="user" 
            style={{ backgroundColor : 'white' }} 
            className="page-navigation-circle-img" 
            onClick={click}
            src={props._session.userOptions.googlePhotoURL}>
          </img>
          :
          <img alt="user" 
            style={{ backgroundColor : 'initial' }}
            className="page-navigation-circle-img" 
            onClick={click}
            src={require("@/assets/user.png")}>
          </img>
        )
      )
      }

      { isChildrenVisible &&
      <ContextMenu onClose={closeContextMenu}
        positionRight>
        <div onClick={closeContextMenu}>
          { props.children }
        </div>
      </ContextMenu>
      }

    </div>
    
  )

}

const mapStateToProps = state => ({
  _session : state._session
})

export default connect(mapStateToProps)(NavAvatar)