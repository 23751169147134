/***
 * PROJECT-LANGUAGES.WIDGET
 * Select survey languages
 */

import { connect } from "react-redux";
import { WithTranslation, withTranslation } from "react-i18next"
import { Session } from "@/redux/_session.types";
import { ProjectState } from "@/redux/project.types";
import { TemplateState } from "@/redux/template.types";
import { store } from "@/index";
import { projectCheckEmails, projectCheckLanguages, projectCheckMessage, projectCheckQrcode, projectEdit } from "@/redux/project.actions";
import SelectLanguages from "@/components/select-languages";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import getLanguagesUtil from "@/utils/get-languages.utils";
import { useState } from "react";
import UpgradeModal from "@/modals/upgrade.modal";
import ConfirmButtons from "@/components/confirm-buttons";
import { EmailState } from "@/redux/email.types";
import ListItem from "@/components/list-item";
import Link from "@/components/link";
import i18n from "@/translate/i18n";
import { toast } from "react-toastify";
import ProjectStepErrorsWidget from "./project-step-errors.widget";
import DuplicateTemplateAskModal from "@/modals/duplicate-template-ask.modal";
import { NavigateFunction, useNavigate } from "react-router-dom";

interface StateProps extends WithTranslation{
  _session : Session
  email : EmailState
  project : ProjectState
  template : TemplateState
}

interface OwnProps{
  onEdit : Function
  onNext: Function
}

type Props = StateProps & OwnProps

const MODAL_DUPLICATE_TEMPLATE:string = "MODAL_DUPLICATE_TEMPLATE" //Ask for duplicate
const MODAL_UPGRADE_LANGUAGES:string = "MODAL_UPGRADE_LANGUAGES" //Lock languages

function ProjectLanguagesWidget(props:Props){
  const { t } = props
  const navigate: NavigateFunction = useNavigate()
  
  //Current modal displayed
  const [currentModal, setCurrentModal] = useState<string | null>(null)

  //Set default value
  function cancel(){
    editLanguages([i18n.language])
    next(false, false)
  }

  function toogleLanguage(lang:string){
    const languages = props.project.active.languages.indexOf(lang) > -1 ?
      props.project.active.languages.filter((x:string)=> x !== lang) :
      [...props.project.active.languages, lang]
    editLanguages(languages)
  }

  function editLanguages(languages:string[]){
    store.dispatch(projectEdit("languages", languages))
    store.dispatch(projectCheckLanguages(languages))
    store.dispatch(projectCheckMessage(languages, props.project.active.messageOptions))
    store.dispatch(projectCheckQrcode(props.project.active.languages, props.project.active.qrcodeOptions))
    store.dispatch(projectCheckEmails(props.project.active.languages, props.email.list))
    props.onEdit()
  }

  function editTemplate(){
    if (props.template.active.fromSupervisor){
      setCurrentModal(MODAL_DUPLICATE_TEMPLATE)
    }
    else if (props.template.active.id.length){
      navigate("/survey/template")
    }
    else{
      toast(t("project_error_template_no_template"), { type : "error" })
    }
  }

  function next(isVisited, isConfirmed){
    props.onNext(isVisited, isConfirmed)
  }

  return (
    <div>

      { currentModal === MODAL_DUPLICATE_TEMPLATE &&
      <DuplicateTemplateAskModal onClose={() => setCurrentModal(null)}/>
      }

      { currentModal === MODAL_UPGRADE_LANGUAGES &&
      <UpgradeModal feature="languages"
        onClose={() => setCurrentModal(null)}
      />
      }

      <ListItem>
        <div className="setup-card-help flex1">
          <p>
            {t("project_languages_help")}
          </p>
          <div className="grey-t setup-card-help-content flex">
            {t("project_languages_help_1")}
            &nbsp;
            <Link isWithoutMargin
              onClick={() => editTemplate()}>
              {t("template_params")}
            </Link>
          </div>
        </div>
      </ListItem>

      { props._session.modules.languages
      ?
      <SelectLanguages languageIds={props.template.active.languages}
        selectedLanguageIds={props.project.active.languages}
        onSelect={(languageId: string) => (props.project.active.languages.length === 1 && props.project.active.languages[0] === languageId) ? toast(t("at_least_one_language"), {type: "error"}) : toogleLanguage(languageId)}
      />
      :
      <ListItem isEditable
        onClick={() => setCurrentModal(MODAL_UPGRADE_LANGUAGES)}>
        <FontAwesomeIcon icon={faLock}/>
        &nbsp;
        {getLanguagesUtil(props.project.active.languages)[0].fullName}
      </ListItem>
      }

      <ProjectStepErrorsWidget alert={props.project.active.alerts.languages} step="languages"/>

      <ConfirmButtons onCancel={() => cancel()}
        onConfirm={() => next(true, true)}
        onConfirmLater={() => next(true, false)}
      />

      <div className="height-20"/>

    </div>
  )
}

const mapStateToProps = state => ({
  _session : state._session,
  email : state.email,
  project : state.project,
  template : state.template
})

export default connect(mapStateToProps)(withTranslation()(ProjectLanguagesWidget))