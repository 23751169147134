/**
 * REMINDER.TYPES
 */

export type ReminderTag = "start" | "template" | "direction" | "union" | "communication" | "end" | "results" | "custom"

export class Reminder {
  date : Date = new Date()
  id : string = ""
  tag : ReminderTag = "custom"
  text : string = ""
  title : string = ""
  ProjectId : string = ""

  constructor(item: Partial<Reminder> = {}){
    if (!item) item = new Reminder()
    item.date = item.date ? new Date(item.date) : new Date()
    Object.assign(this, item)
  }

}

export interface ReminderState{
  list : Reminder[]
}

export const REMINDER_TAGS:ReminderTag[] = [
  "start",
  "template",
  "direction",
  "union",
  "communication",
  "end",
  "results"
]

export const REMINDER_ADD:string = "REMINDER_ADD"
export const REMINDER_GET:string = "REMINDER_GET"
export const REMINDER_INIT:string = "REMINDER_INIT"
export const REMINDER_REPLACE:string = "REMINDER_REPLACE"
export const REMINDER_REMOVE:string = "REMINDER_REMOVE"