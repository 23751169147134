/** 
 * TASKS-OPTIONS.MODAL
 * Edit options for the action plan
 */
import Modal from "@/components/modal"
import { WithTranslation, withTranslation } from "react-i18next"
import TasksOptionsWidget from "@/widgets/tasks-options.widget";

interface OwnProps{
  onClose : Function
}

type Props = OwnProps & WithTranslation

function TasksOptionsModal(props: Props) {
  const { t } = props

  return (
    <Modal isCloseButtonVisible
      isLarge
      title={t("utils_options")}
      onClose={() => props.onClose()}>
      <TasksOptionsWidget/>
    </Modal>
  )

}

export default withTranslation()(TasksOptionsModal)