/** 
 * DASHBOARD-INEQUALITIES.WIDGET
 * Gini
 */

import { withTranslation, WithTranslation } from "react-i18next"
import { connect } from "react-redux"
import { SurveyState } from "@/redux/survey.types";
import { Session } from "@/redux/_session.types";
import { Fragment, useEffect, useState } from "react";
import Dropdown from "@/components/dropdown";
import initFilters from "@/utils/init-filters.utils";
import { store } from "@/index";
import getFilterName from "@/utils/get-filter-name.utils";
import { fetchInequalities } from "@/redux/_archive.actions";
import { Question } from "@/redux/question.types";
import { Topic, TopicState } from "@/redux/topic.types";
import { groupBy } from "lodash";
import { FilterState } from "@/redux/filter.types";
import getHeatmapCeils from "@/utils/get-heatmap-ceils.utils";
import ReactTooltip from 'react-tooltip'
import ModalHelp from "@/components/modal-help";
import InequalitiesHelp from "@/help/inequalities.help";
import Space from "@/components/space";
import DashboardInequalitiesDisplayWidget, { InequalityData } from "@/widgets/dashboard-inequalities-display.widget"
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ListCell from "@/components/list-cell";
import ListItem from "@/components/list-item";
import Card from "@/components/card";
import { STATUS_LOADED, STATUS_LOADING } from "@/redux/_status.types";

interface StateProps{
  _session: Session
  filter: FilterState
  survey: SurveyState
  topic: TopicState
}

interface OwnProps{
  hideControls?: boolean,
  filterName?: string
}

type Props = StateProps & OwnProps & WithTranslation

const FIRST_CELL_SIZE: number = 400
const CELL_SIZE: number = 60

function DashboardInequalitiesWidget(props: Props) {
  const { t } = props

  const [data, setData] = useState<InequalityData[]>([])
  
  const [displayHelper, setDisplayHelper] = useState<boolean>(false)
  
  const [filterName, setFilterName] = useState<string>(props.filterName ? props.filterName : (props._session.accountOptions.heatmapFilterName ? props._session.accountOptions.heatmapFilterName : "gender" ))
  
  const [isDataLoading, setIsDataLoading] = useState<boolean>(true)

  useEffect(() => {
    if(props.filterName){
      setFilterName(props.filterName)
    }
  }, [
    props.filterName,
  ])

  useEffect(() => {
    if(props._session.accountOptions.heatmapFilterName){
      setFilterName(props._session.accountOptions.heatmapFilterName)
    }
  }, [
    props._session.accountOptions.heatmapFilterName
  ])

  useEffect(() => {
    async function load(){
      setIsDataLoading(true)

      const response: any = await store.dispatch(fetchInequalities(
        props.filter.dashboard,
        filterName,
        props.survey.active.id,
        props.survey.active.dateStart,
        getHeatmapCeils(filterName)
      ))

      if(!response.error){
        setData(response.map((question: any) => question.attributes ? ({...question, attributes: question.attributes.map((attribute: any) => attribute.aid ? attribute : ({...attribute, aid: "other"}))}) : question ))
      }

      setIsDataLoading(false)
    }

    load()
    
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.filter.dashboard,
    filterName,
    props.survey.active.id
  ])

  function getRowName(question: Question, topicLabel?: string, topicColor?: string): JSX.Element{
    const rowData: InequalityData | undefined = data.find((x: InequalityData) => x.fkQuestion === question.aid)

    return <ListItem
      key={question.id}
    >
      { topicLabel &&
      <>
        <ListCell width={FIRST_CELL_SIZE} >
          <div className="flex flex-dcol">
            <div
              style={{
                color: topicColor ? topicColor : "#8C8C8C",
              }}
            >
              {topicLabel}
            </div>
          
            {question.label}
          </div>
        </ListCell>

        <ListCell width={CELL_SIZE} />
      </>
      }

      { isDataLoading ?
        <ListCell width={CELL_SIZE}>
          <FontAwesomeIcon icon={faCircleNotch} spin className="grey-t" />
        </ListCell>
        :
        ((
          rowData &&
          question.responseCount > 2 &&
          !question.primaryId
        ) ?
        <DashboardInequalitiesDisplayWidget
          rowData={rowData}
          filterName={filterName}
          topicColor={topicColor}
          reverse={question.reverse}
          responseCount={question.responseCount}
          nbDisplay={rowData.fkQuestion === "_" ? 6 : undefined}
          isReverse={question.reverse}
        />
        :
        <div className="flex flex-dcol grey-t" style={{fontSize: 12}}>
          <Space/>
          {t("utils_unavailable")}
          <Space/>
        </div>
        )    
      }

    </ListItem>

  }

  return (
    <Card isWithoutPadding>

      { displayHelper &&
      <ModalHelp onClose={() => setDisplayHelper(false)} title={t("disparities_help")}>
        <InequalitiesHelp />
      </ModalHelp>
      }

      <div
        style={{
          borderCollapse: "collapse",
          tableLayout: "fixed",
          textAlign: "left",
        }}
      >

        <div
          className="flex" 
          style={{
            top: -60,
            left: 0,
            padding: "10px 30px",
            position: "sticky",
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            backgroundColor: "white",
            zIndex: 2,
            borderBottom: "1px solid #f0f0f0"
          }}
        >

          { !props.hideControls &&
          <Dropdown
            active={filterName}
            displayField="name"
            title={t("filter")}
            list={initFilters()}
            onSelect={(e: any) => isDataLoading ? {} : setFilterName(e.id)}
            status={isDataLoading ? STATUS_LOADING : STATUS_LOADED}
            data-tip={t("filter")}
            value={getFilterName(filterName)}
          />
          }

          <Space />
          
          <div className="flex flex-dcol">
            <Space />
            <u
              className="_hover grey-t"
              onClick={() => setDisplayHelper(true)}
              style={{
                marginTop: 20,
                backgroundColor: "white",
                zIndex: 3
              }}
            >
              {t("utils_help")}
            </u>
          </div>
          
        </div>

        {/** General row **/}

        <div>
          { isDataLoading ?
          <div className="flex" style={{padding: "18px 32px"}} >
            <ListCell width={FIRST_CELL_SIZE + CELL_SIZE + 12}/>
            <FontAwesomeIcon icon={faCircleNotch} spin className="grey-t" />
          </div>
          :
          <div>
            {getRowName(new Question({aid: "_"}))}
          </div>
          }
        </div>


        {/** Question rows **/}

        { Object.values(groupBy(props.topic.list.filter((topic: Topic) => topic.Questions.some((question: Question) => question.type === "scale" && question.withScore)), "AxisId")).map((topics: Topic[]) =>
        <div key={topics[0].AxisId}>
          <div
            style={{
              backgroundColor: topics[0].Axis?.color,
              color: "white",
              fontWeight : 500,
              top: 50,
              left: 0,
              position: "sticky",
              zIndex: 1,
              padding: "8px 30px"
            }}
          >
            {topics[0].getAxisLabel(props._session.language)}
          </div>

          { topics.map((topic: Topic) => 
          <Fragment key={topic.id}>
            { topic.Questions.filter((question: Question) => question.type === "scale" && question.withScore).map((question: Question) => getRowName(question, topic.label, topic.Axis?.color))}
          </Fragment>
          )}

        </div>
        )}

      </div>

      <div className="height-100"/>

      <ReactTooltip/>

    </Card>
  )

}

const mapStateToProps = state => ({
  _session: state._session,
  filter: state.filter,
  survey: state.survey,
  topic: state.topic
})

export default connect(mapStateToProps)(withTranslation()(DashboardInequalitiesWidget))