/** 
 * ONBOARDING-FAQ.MODAL
 * Informations
 */
import Link from "@/components/link"
import Modal from "@/components/modal"
import { withTranslation, WithTranslation } from "react-i18next"

interface OwnProps extends WithTranslation{
  onClose : Function
}

function OnboardingFaqModal(props:OwnProps) {
  const { t } = props
  
  return (
    <Modal isCloseButtonVisible
      onClose={() => props.onClose()}
      title={t("utils_faq")}>

      <h3>
        {"IDTree, qu'est ce que c'est ?"}
      </h3>

      <p>
        {"IDTree est un logiciel qui permet de réaliser des enquêtes QVT et RPS en entreprise."}
      </p>

      <h3>
        {"Est-ce vraiment gratuit de créer un compte ?"}
      </h3>

      <p>
        {"Oui. La création de compte est gratuite est sans engagement. Aucune carte de crédit n'est requise."}
      </p>

      <p>
        {"Avec la version de test, vous pouvez accéder à l'ensemble des modèles de questionnaire et lancer des enquêtes de test auprès de vos collaborateurs."}
      </p>

      <p>
        {"Vous pouvez inviter jusqu'à 5 personnes à participer à une enquête de test."}
      </p>

      <h3>
        {"Comment sont utilisées mes données ?"}
      </h3>

      <p>
        {"IDTree est conforme avec le RGPD. Les résultats des enquêtes sont anonymisés et vos données ne sont pas partagées à d'autres organisations. "}
      </p>

      <p>
        {"Les serveurs de IDTree sont localisés à Francfort (Allemagne)."}
      </p>

      <Link onClick={() => window.open("https://www.id-tree.com/tarifs#faq", "_blank")}
        isWithoutMargin>
        Plus de questions ? consultez notre FAQ complète
      </Link>

    </Modal>

  )

}

export default withTranslation()(OnboardingFaqModal)