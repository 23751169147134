/**
 * DASHBOARD-RPS-TEAM
 * Details of team for RPS
 */
import { useState } from "react";
import { Session } from "@/redux/_session.types";
import { connect } from "react-redux";
import UpgradeWidget from "@/widgets/upgrade.widget";
import NavFocus from "@/nav/nav.focus";
import { filterReplaceDashboard } from "@/redux/filter.actions";
import { DashboardFilters, FilterState } from "@/redux/filter.types";
import { store } from "@/index";
import DashboardHeaderWidget from "@/widgets/dashboard-header.widget";
import { withTranslation, WithTranslation } from "react-i18next";
import DashboardRpsUsersWidget from "@/widgets/dashboard-rps-users.widget";
import DashboardRpsOverviewWidget from "@/widgets/dashboard-rps-overview.widget";
import Tabs from "@/components/tabs";
import DashboardRpsQuestionsWidget from "@/widgets/dashboard-rps-questions.widget";
import { NavigateFunction, useNavigate } from "react-router-dom";

interface Props extends WithTranslation {
  _session: Session,
  filter: FilterState
}

//List of tabs
const TAB_QUESTIONS = "TAB_QUESTIONS";
const TAB_USERS = "TAB_USERS"

function DashboardRpsTeamRoute(props:Props){
  const { t } = props
  const navigate: NavigateFunction = useNavigate()

  const [currentTab, setCurrentTab] = useState(TAB_QUESTIONS)

  function goBack(){
    store.dispatch(filterReplaceDashboard(new DashboardFilters()))
    navigate("/dashboard/rps")
  }

  return (
    <NavFocus onBack={goBack}>

      {!props._session.modules.rps ?
      <UpgradeWidget feature="rps" />
      :
      <div>

        <DashboardHeaderWidget title="rps_title" />

        <DashboardRpsOverviewWidget dashboardFilters={props.filter.dashboard}/>

        <Tabs active={currentTab}
          onClick={(currentTab) => setCurrentTab(currentTab)}
          tabs={{
            TAB_QUESTIONS: t("rps_questions_title"),
            TAB_USERS: t("rps_users_title"),
          }}
        />

        { currentTab === TAB_QUESTIONS &&
        <DashboardRpsQuestionsWidget dashboardFilters={props.filter.dashboard}/>
        }

        { currentTab === TAB_USERS &&
        <DashboardRpsUsersWidget dashboardFilters={props.filter.dashboard}/>
        } 

      </div>
      }
      
    </NavFocus>
  )
}

const mapStateToProps = (state) => ({
  _session: state._session,
  filter: state.filter
})

export default connect(mapStateToProps)(withTranslation()(DashboardRpsTeamRoute))
