/***
 * DASHBOARD-FILTER-USERS-COUNT
 * Display count of users for a population in filter display
 * If showInfo when i click i can get the list of users (before survey launch)
 */

import { connect } from "react-redux";
import { Session } from "@/redux/_session.types";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import Space from "@/components/space";
import GetPopulationUsersModal from "@/modals/get-population-users.modal";

interface StateProps{
  _session : Session
}

interface OwnProps {
  defaultPopulationId? : string
  defaultFilterId? : string
  isDefaultFilter? : boolean
  filterName : string
  populationName : string
  showInfo? : boolean
  userCount : number
}

type Props = StateProps & OwnProps

const MODAL_POPULATION_USERS:string = "MODAL_POPULATION_USERS"

function DashboardFilterUsersCountWidget(props:Props){

  const [currentModal, setCurrentModal] = useState<string | null>(null)
  const [currentPopulationName, setCurrentPopulationName] = useState<string>("")

  function showUsers(e: any, populationName: string){
    e.stopPropagation()
    if (props.showInfo){
      setCurrentPopulationName(populationName)
      setCurrentModal(MODAL_POPULATION_USERS)
    }
  }

  if (props.userCount > 0){
    return (
      <div>

        { currentModal === MODAL_POPULATION_USERS &&
        <GetPopulationUsersModal onClose={() => setCurrentModal(null)}
          filterName={props.filterName}
          defaultPopulationId={props.defaultPopulationId}
          defaultFilterId={props.defaultFilterId}
          isDefaultFilter={props.isDefaultFilter}
          populationName={currentPopulationName}
        />
        }

        <div className={"flex " + (props.userCount < props._session.participationMin ? "medgrey-t" : "grey-t") + (props.showInfo ? " _hover" : "")}
          style={{ minWidth : 54 }}
          onClick={(e: any) => showUsers(e, props.populationName)}>
  
          <Space/>
          
          <FontAwesomeIcon icon={faUser} style={{ margin : 2 }}/>

          {props.userCount}
  
        </div>

      </div>
    )
  }else{
    return null
  }

}

const mapStateToProps = state => ({
  _session : state._session
})

export default connect(mapStateToProps)(DashboardFilterUsersCountWidget)
