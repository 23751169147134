import { useState } from "react"

interface OwnProps {
  color1?: string,
  color2?: string,
  color3?: string
}

type Props = OwnProps

const defaultColor1: string = "#ff4800"
const defaultColor2: string = "#da54d8"
const defaultColor3: string = "#0092f4"

function AnimatedColorSvgWidget(props: Props){

  const [animationColor1] = useState<string>(props.color1 ? props.color1 : defaultColor1)
  const [animationColor2] = useState<string>(props.color2 ? props.color2 : defaultColor2)
  const [animationColor3] = useState<string>(props.color3 ? props.color3 : defaultColor3)

  return (
    <svg width="0" height="0">
      <linearGradient id="my-gradient" gradientTransform="rotate(150)">
        <stop offset="13.4%" stopColor={animationColor1}>
          <animate attributeName="stop-color" values={`${animationColor1}; ${animationColor2}; ${animationColor3}; ${animationColor1}`} dur="2s" repeatCount="indefinite"/>
        </stop>
        <stop offset="50%" stopColor={animationColor2}>
          <animate attributeName="stop-color" values={`${animationColor2}; ${animationColor3}; ${animationColor1}; ${animationColor2}`} dur="2s" repeatCount="indefinite"/>
        </stop>
        <stop offset="86.6%" stopColor={animationColor3}>
          <animate attributeName="stop-color" values={`${animationColor3}; ${animationColor1}; ${animationColor2}; ${animationColor3}`} dur="2s" repeatCount="indefinite"/>
        </stop>
      </linearGradient>
    </svg>
  )
}

export default AnimatedColorSvgWidget