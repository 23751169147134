/**
 * REPORT-GENERATE.MODAL
 * Generate rapport PPTX
 */

import { withTranslation, WithTranslation } from "react-i18next"
import { connect } from "react-redux"
import { Session } from "@/redux/_session.types"
import LoadingModal from "./loading.modal"
import Space from "@/components/space"
import ProgressBar from "@/components/progress-bar"
import { Page, PageContent, PageState } from "@/redux/page.types"
import { useEffect, useState } from "react"
import { ReportState } from "@/redux/report.types"
import pptxgen from "pptxgenjs"
import { SurveyState } from "@/redux/survey.types"
import domtoimage from "dom-to-image"
import { toast } from "react-toastify"
import { store } from "@/index"
import Color from "color"
import { pageActivate, pageEdit, pageInitGenerate, pageReplace, pageReplaceTag, pageStatus } from "@/redux/page.actions"
import { STATUS_SAVED } from "@/redux/_status.types"
import { RawDraftInlineStyleRange } from "draft-js"
import env from "@/env"
import { TopicState } from "@/redux/topic.types"
import { isEqual } from "lodash"

interface StateProps extends WithTranslation {
  _session: Session
  page: PageState
  report: ReportState
  survey: SurveyState
  topic: TopicState
}

interface OwnProps {
  onClose: Function
}

type Props = StateProps & OwnProps

const DEFAULT_FONT_FACE: string = "Open Sans"
const SLIDE_WIDTH: number = 9 //Size in inches for slide width
const SLIDE_HEIGTH: number = 4 //Idem height
const MARGIN_RIGHT: string = "3.4%" //Default value for right aligment

function ReportGenerateModal(props: Props) {
  const { t } = props

  const [ratio] = useState<number>(9 / 16)

  const [generatingStep, setGeneratingStep] = useState<number>(0)

  const [pageCount, setPageCount] = useState(props.page.list.length)

  useEffect(() => {
    async function generate() {
      const presentationName: string = props.report.active.name

      // 1. Create a new Presentation
      const pres: pptxgen = new pptxgen()

      //2. Presentation metadata
      pres.author = props._session.userName
      pres.company = props._session.accountName
      pres.subject = props.survey.active.name
      pres.title = presentationName
      pres.theme = {
        bodyFontFace: DEFAULT_FONT_FACE,
        headFontFace: DEFAULT_FONT_FACE
      }

      //Define master slide
      pres.defineSlideMaster({
        title: "MASTER_SLIDE_BACKGROUND",
        background: { color: "FFFFFF" },
        objects: [
          {
            placeholder: {
              options: {
                name: "slide_title",
                type: "title",
                x: "10%",
                y: "6.9%",
                w: "52%",
                h: "7.5%",
                color: props._session.accountColors.brandPrimary,
                fontSize: 24,
                align: "left"
              },
              text: "Slide title"
            }
          },
          {
            rect: {
              x: "3.4%",
              y: "6%",
              w: "5.6%",
              h: "10%",
              fill: { color: props._session.accountColors.brandPrimary },
              rectRadius: 1
            }
          },
          {
            image: {
              x: "3.4%",
              y: "6%",
              path: props._session.accountImage,
              sizing: {
                type: "contain",
                w: "5.6%",
                h: "10%"
              }
            }
          }
        ],
        slideNumber: {
          fontSize: 22,
          color: "#FFFFFF",
          x: "95%",
          y: "88%"
        }
      })

      //Get pages
      const pages = await pageInitGenerate(props.page.list, false)
      setPageCount(pages.length)

      //Loop on each page of the document
      for (let i = 0; i < pages.length; i++) {
        const page = pages[i]

        //5. Create a new slide
        let slide: any = pres.addSlide({ masterName: "MASTER_SLIDE_BACKGROUND" })

        if (page.backgroundId) {
          const { image, slideImageHeight, slideImageWidth } = await domToImage("background-" + page.backgroundId, 30)
          slide.addImage({
            x: slideImageWidth * -0.02,
            y: slideImageHeight * -0.02,
            path: image,
            w: slideImageWidth * 1.8,
            h: slideImageHeight * 1.7
          })
        }

        //Title for the page
        slide.addText(pageReplaceTag(page.name), { placeholder: "slide_title", bold: true })

        //Update data according attribute
        //Add delay
        store.dispatch(pageActivate(page))
        const delay =
          isEqual(page.options.populations, pages[i + 1]?.options.populations) && page.contentLeft.type !== "screenshot"
            ? 250
            : props.survey.active.participationSend + 2500
        await new Promise((resolve) => setTimeout(resolve, delay))

        //6. Generate the content
        slide = await generatePageContent(slide, page, page.contentLeft, !page.twoColumns, false)
        if (page.contentRight) {
          slide = await generatePageContent(slide, page, page.contentRight, false, true)
        }

        setGeneratingStep((prevState) => prevState + 1)
      }

      //7. Save the Presentation
      pres
        .writeFile({
          fileName: presentationName.slice(0, 64).replace(/ /g, "_") + ".pptx"
        })
        .then((fileName) => {
          toast(t("report_created") + ` : ${fileName}`)
        })

      setGeneratingStep(0)
      store.dispatch(pageStatus(STATUS_SAVED))
      props.onClose()
    }

    generate()

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  //Generate the content for one side of the page
  async function generatePageContent(slide, page: Page, content: PageContent, isFullWidth: boolean, isRight: boolean) {
    //Type of the content == TEXT
    //Read all blocks formatted as draftJS blocks
    //Transform them into object readable by the pptx generator plugin
    if (content.type === "text") {
      const texts: any[] = []

      content.textBlocks.forEach((block, i) => {
        const textBlock = Object.create(block)

        //Remove not used style and merge styles that are commons
        textBlock.inlineStyleRanges = formatInlineStyleRanges(textBlock.inlineStyleRanges)

        //Transforms blocks and apply style
        const transformedBlocks = transformTextWithStyles(textBlock)
        transformedBlocks.forEach((block) => {
          texts.push({
            text: pageReplaceTag(block.text),
            options: mapStyleToOptions(block.styles)
          })
        })

        //Add escape beetween blocks (not for the last one !)
        if (i < content.textBlocks.length - 1) {
          texts.push({
            text: "\n\n",
            options: { fontSize: 8 }
          })
        }
      })

      //Add the text for the page
      slide.addText(texts, {
        w: isFullWidth ? "80%" : "45%",
        h: "50%",
        x: isRight ? "50%" : MARGIN_RIGHT,
        y: "30%",
        fit: "resize"
      })
    }

    //Type is image
    else if (content.type === "image" && content.imageId) {
      const imagePath = env.REACT_APP_URL_SPACE + "/ReportPages/" + content.imageId + ".png"

      slide.addImage({
        x: "55%",
        y: "20%",
        w: 3,
        h: 3,
        path: imagePath
      })
    } else if (content.type === "screenshot") {
      let scale = 2

      //Adjust scale for messages type
      if (content.screenshot.type === "messages") {
        scale = 3
      }

      //Page type topics
      if (page.Topics.length > 0) {
        scale = 3
        store.dispatch(pageReplace(page))
        await new Promise((resolve) => setTimeout(resolve, 500))
      }

      //Increase scale for heatmap
      //Update also the
      if (content.screenshot.type === "heatmap") {
        scale = 3
        store.dispatch(pageEdit("heatmapOffset", page.heatmapOffset))
        await new Promise((resolve) => setTimeout(resolve, 500))
      }

      const { image, slideImageHeight, slideImageWidth } = await domToImage(
        "report-screenshot-" + (isRight ? "right" : "left") + "-" + page.id,
        scale
      )

      const imageX: string | number = isRight ? "55%" : "5%"
      const scaleRatio = 4.2 / slideImageHeight
      let imageW = slideImageWidth * scaleRatio
      let imageH = 4.2

      if (imageW > 9.5) {
        const newRatio = 9.5 / imageW
        imageW = 9.5
        imageH = imageH * newRatio
      }

      /*if (isFullWidth){
        imageX = Math.abs(SLIDE_WIDTH - imageW) / 2 //Center image
      }*/

      //Calculate image width according image height
      slide.addImage({
        x: imageX,
        y: "20%",
        w: imageW,
        h: imageH,
        data: image
      })
    }

    return slide
  }

  //Convert HTML to image
  //Scale params is used for quality adjustement
  async function domToImage(id: string, scale: number) {
    const element: HTMLElement | null = document.getElementById("image-exportable-" + id)
    let image
    let slideImageHeight = SLIDE_HEIGTH
    let slideImageWidth = SLIDE_WIDTH

    if (element) {
      /******DOM TO IMAGE ********/
      const { offsetWidth, offsetHeight } = element

      image = await domtoimage.toPng(element, {
        width: offsetWidth * scale,
        height: offsetHeight * scale,
        style: {
          transform: "scale(" + scale + ")",
          transformOrigin: "top left"
        }
      })

      if (offsetWidth * ratio > offsetHeight) {
        slideImageHeight = (slideImageWidth * offsetHeight) / offsetWidth
      } else {
        slideImageWidth = (slideImageHeight * offsetWidth) / offsetHeight
      }
    }

    return {
      image,
      slideImageHeight,
      slideImageWidth
    }
  }

  //Function that will cut the texts into many segments with differents styles
  function transformTextWithStyles(block: any) {
    const { text, inlineStyleRanges } = block
    const result: any[] = []

    // Initialize a map to keep track of styles at each character position
    const styleMap = Array.from({ length: text.length }, () => new Set())

    // Fill the styleMap with styles from inlineStyleRanges
    inlineStyleRanges.forEach((range) => {
      for (let i = range.offset; i < range.offset + range.length; i++) {
        range.styles.forEach((style) => styleMap[i]?.add(style))
      }
    })

    // Helper function to get styles for a segment
    const getStylesForSegment = (start, end) => {
      const styles = new Set()
      for (let i = start; i < end; i++) {
        styleMap[i].forEach((style) => styles.add(style))
      }
      return Array.from(styles)
    }

    // Iterate over the text and create segments
    let start = 0
    while (start < text.length) {
      let end = start + 1
      while (
        end < text.length &&
        JSON.stringify(getStylesForSegment(start, start + 1)) === JSON.stringify(getStylesForSegment(end, end + 1))
      ) {
        end++
      }

      result.push({
        text: text.slice(start, end),
        styles: getStylesForSegment(start, end)
      })

      start = end
    }

    return result
  }

  //Remove not used style property and merge styles
  function formatInlineStyleRanges(inlineStyleRanges: RawDraftInlineStyleRange[]) {
    inlineStyleRanges = inlineStyleRanges.filter(
      (x) => !x.style?.includes("fontfamily") && !x.style?.includes("bgcolor")
    )
    return mergeInlineStyles(inlineStyleRanges)
  }

  //Merge the styles that are used in many text fragments
  function mergeInlineStyles(inlineStyleRanges: RawDraftInlineStyleRange[]) {
    // First, we need to sort the ranges by offset
    inlineStyleRanges.sort((a, b) => a.offset - b.offset || a.length - b.length)

    // Result array to store merged ranges
    const mergedRanges: any[] = []

    // Helper function to add or merge styles to the existing range in the result array
    const addOrMergeStyles = (range) => {
      const lastRange: any = mergedRanges[mergedRanges.length - 1]

      if (lastRange && lastRange.offset === range.offset && lastRange.length === range.length) {
        // Merge styles if the offset and length are the same
        lastRange.styles.push(range.style)
      } else {
        // Otherwise, add a new range with the initial style in an array
        mergedRanges.push({
          styles: [range.style],
          length: range.length,
          offset: range.offset
        })
      }
    }

    // Iterate through the input ranges and merge styles
    for (const range of inlineStyleRanges) {
      addOrMergeStyles(range)
    }

    // Sort styles within each range for consistency
    for (const range of mergedRanges) {
      range.styles.sort()
    }

    return mergedRanges
  }

  // Helper function to map draftJs styles to the required format
  function mapStyleToOptions(styles: string[]) {
    const options: any = {}

    styles.forEach((style) => {
      if (style) {
        if (style.includes("fontsize-")) {
          options.fontSize = style.split("-")[1]
        }

        if (style.includes("color-")) {
          const color = Color(style.split("-")[1])
          options.color = color.hex()
        }

        if (style === "BOLD") {
          options.bold = true
        }
      }
    })

    return options
  }

  return (
    <LoadingModal>
      <div className="height-20" />

      <div className="flex">
        <Space />

        <div style={{ width: 200 }}>
          <ProgressBar items={[{ value: generatingStep }]} max={pageCount} />
        </div>

        <Space />
      </div>

      <div className="height-10" />

      <div className="grey-t" style={{ fontSize: 12, textAlign: "center" }}>
        <b>{t("page")}</b> : {pageReplaceTag(props.page.active.name)}
      </div>
    </LoadingModal>
  )
}

const mapStateToProps = (state) => ({
  _session: state._session,
  page: state.page,
  report: state.report,
  survey: state.survey,
  topic: state.topic
})

export default connect(mapStateToProps)(withTranslation()(ReportGenerateModal))
