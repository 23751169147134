/** 
 * DUPLICATE-TEMPLATE-ASK.MODAL
 * Ask the user if he want to duplicate the template
 */
import { useState } from "react"
import { connect } from "react-redux"
import { withTranslation, WithTranslation } from "react-i18next"
import ModalConfirm from "@/components/modal-confirm"
import { TemplateState } from "@/redux/template.types";
import { Session } from "@/redux/_session.types";
import DuplicateTemplateModal from "./duplicate-template.modal";

interface StateProps extends WithTranslation{
  _session : Session,
  template : TemplateState
}

interface OwnProps{
  onClose : Function
}

type Props = StateProps & OwnProps

const MODAL_DUPLICATE = 'MODAL_DUPLICATE' //Modal for duplicate

function DuplicateTemplateAskModal(props:Props) {
  const { t } = props

  const [currentModal, setCurrentModal] = useState<string | null>(null)

  function close(){
    props.onClose()
  }

  return (
    <div>
      
      <ModalConfirm
        onNo={close}
        onYes={() => setCurrentModal(MODAL_DUPLICATE)}
        text={t("template_duplicate_ask")}
        textBold={t("utils_next_ask")}
      />

      { currentModal === MODAL_DUPLICATE &&
      <DuplicateTemplateModal
        onClose={close}
      />
      }

    </div>
  )

}

const mapStateToProps = state => ({
  _session : state._session,
  template : state.template
})

export default connect(mapStateToProps)(withTranslation()(DuplicateTemplateAskModal))