/**
 * SORT-METHODS.WIDGET
 * selector buttons for sorting methods and order
 * 
 */

import ListButton from "@/components/list-button"
import Space from "@/components/space"
import { Session } from "@/redux/_session.types"
import { faLongArrowAltDown, faLongArrowAltUp, faSortAlphaDown, faSortAlphaUpAlt, faSortAmountDownAlt, faSortAmountUp, faSortNumericDown, faSortNumericUpAlt, IconDefinition } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"
import { WithTranslation, withTranslation } from "react-i18next"
import { connect } from "react-redux"

interface StateProps{
  _session: Session
}

interface OwnProps {
  sortMethod?: SortMethod
  order?: "asc" | "desc"
  onSortClic?: Function
  onOrderClic?: Function
  hideItems?: SortMethod[]
}

type Props = StateProps & OwnProps & WithTranslation

export type SortMethod = "alphabetical" | "numerical" | "participation" | "ratio" | "total"

export type SortItem = {
  id: SortMethod,
  description: string,
  iconAsc: IconDefinition,
  iconDesc: IconDefinition,
}

const sortItemsAll: SortItem[] = [
  { id: "ratio", description: "survey_participation", iconAsc: faSortAmountDownAlt, iconDesc: faSortAmountUp },
  { id: "alphabetical", description: "utils_alphabet", iconAsc: faSortAlphaDown, iconDesc: faSortAlphaUpAlt },
  { id: "numerical", description: "utils_numeric", iconAsc: faSortNumericDown, iconDesc: faSortNumericUpAlt },
]

function SortMethodsWidget(props: Props){
  const { t } = props

  const sortItems: SortItem[] = props.hideItems ? sortItemsAll.filter((sortItem: SortItem) => !props.hideItems?.includes(sortItem.id)) : sortItemsAll

  const [sort, setSort] = useState<SortItem | undefined>(getSortItem(props.sortMethod))

  const [order, setOrder] = useState<"asc" | "desc">(props.order ? props.order : "asc")

  useEffect(() => {
    setSort(getSortItem(props.sortMethod))
  }, [
    props.sortMethod
  ])

  useEffect(() => {
    setOrder(props.order ? props.order : "asc")
  }, [
    props.order
  ])

  function getSortItem(sortMethod: any): SortItem | undefined{
    if(sortMethod){
      const sortItem: SortItem | undefined = sortItems.find((x: SortItem) => x.id === sortMethod)
      if(sortItem){
        return sortItem
      }
      else{
        return sortItems[0]
      }
    }
    else{
      return undefined
    }
  }

  function sortClic(sortMethod: SortMethod){
    if(props.onSortClic){
      props.onSortClic(sortMethod)
    }
  }

  function orderClic(orderItem: "asc" | "desc"){
    if(props.onOrderClic){
      props.onOrderClic(orderItem)
    }
  }

  return (
    <div className="flex">
      { sortItems.map((x: SortItem) =>
      <div
        key={x.id}
        className={"_hover flex"}
        style={{
          minWidth: 40,
          height: 40,
          margin: 5,
          border: "1px solid #b8b8b8",
          borderRadius: 8
        }}
        onClick={(sort && sort.id === x.id) ? undefined : () => sortClic(x.id)} 
      >
        <Space/>
        <div className="flex flex-dcol" style={{height: 40}}>
          <Space />
          <FontAwesomeIcon
            icon={order === "asc" ? x.iconAsc : x.iconDesc}
            data-tip={t(x.description) + " (" + t("utils_order_" + order) + ")"}
            size={"xl"}
            color={(sort && sort.id === x.id) ? props._session.accountColors.active : "#8C8C8C"}
          />
          <Space />
        </div>
        <Space />
      </div>
      )}

      <div style={{ marginTop : 16, marginRight : 4 }}>
        <ListButton icon={order === "asc" ? faLongArrowAltUp : faLongArrowAltDown}
          onClick={() => orderClic(order === "asc" ? "desc" : "asc")}
        />
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  _session : state._session
})

export default connect(mapStateToProps)(withTranslation()(SortMethodsWidget))