/** 
 * HOME-ACCOUNTS.WIDGET
 * Load all account for consulting
 */
import { withTranslation, WithTranslation } from "react-i18next"
import { connect } from "react-redux"
import Card from "@/components/card";
import ListCell from "@/components/list-cell";
import ListItem from "@/components/list-item";
import Space from "@/components/space";
import { useState } from "react";
import { store } from "@/index";
import { Session } from "@/redux/_session.types";
import { faPlayCircle, faShieldAlt } from "@fortawesome/free-solid-svg-icons";
import { Account, AccountState } from "@/redux/account.types";
import ListIcon from "@/components/list-icon";
import env from "@/env";
import { getIconUrl } from "@/utils/default-icons.utils";
import ListButton from "@/components/list-button";
import { accountActivate } from "@/redux/account.actions";
import Modal from "@/components/modal";
import LoginModal from "@/modals/login.modal";

interface StateProps extends WithTranslation{
  _session : Session
  account : AccountState
}

const MODAL_AUTH_CONFIRM = "MODAL_AUTH_CONFIRM"

function HomeAccountsWidget(props:StateProps) {
  const { t } = props

  const [currentAccountId, setCurrentAccountId] = useState<string | null>(null)

  const [currentModal, setCurrentModal] = useState<string | null>(null)

  function clickAccount(account:Account){
    store.dispatch(accountActivate(account))
    setCurrentModal(MODAL_AUTH_CONFIRM)
  }

  return (
    <Card isWithoutPadding
      className="flex1">

      { currentAccountId &&
      <LoginModal authLevel={2}
        onClose={() => setCurrentAccountId(null)}
        accountIdForSuperadmin={currentAccountId}
        email={props._session.email}
        isSupervisor={props._session.connectedAsSupervisor}
      />
      }

      { currentModal === MODAL_AUTH_CONFIRM &&
      <Modal isCloseButtonVisible
        onClose={() => setCurrentModal(null)}
        onNext={() => { 
          setCurrentModal(null)
          setCurrentAccountId(props.account.active.id) 
        }}>
        <p>{t("account_connect")} : <b>{props.account.active.name}</b></p>
      </Modal>
      }
      
      <ListItem>
        <b>
          {t("accounts")}
        </b>
      </ListItem>

      <div style={{
          maxHeight : 452,
          overflowY : "auto"
        }}>

        { props.account.list.map(account =>
        <ListItem onClick={() => clickAccount(account)}
          key={account.id}
          isEditable>

          <ListIcon fallbackIcon={faShieldAlt}
            iconColor={account.colors.brandPrimary}
            image={account.options.image ? env.REACT_APP_URL_SPACE.concat("/Accounts/", account.id, ".png?" + Date.now()) : getIconUrl(account.options.defaultIcon)}
          />

          <ListCell width={200} text={account.name}/>

          <Space/>

          <ListButton icon={faPlayCircle} 
            onClick={() => clickAccount(account)}
            isPrimary 
            text={t("utils_connect")}
          />

        </ListItem>
        )
        }

      </div>

    </Card>
  )

}

const mapStateToProps = state => ({
  _session : state._session,
  account : state.account
})

export default connect(mapStateToProps)(withTranslation()(HomeAccountsWidget))