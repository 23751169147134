/** 
 * SURVEY-TEMPLATES
 * List of templates for idtree
 */
import { Component } from "react";
import { withTranslation, WithTranslation } from 'react-i18next';
import NavApp from "@/nav/nav.app";
import PageHeader from "@/components/page-header";
import TemplatesWidget from "@/widgets/templates.widget";

type Props = WithTranslation

class SurveyTemplates extends Component<Props>{

  render () {
    const { t } = this.props

    return(
      <NavApp>
        <PageHeader title={t("templates")}/>
        <TemplatesWidget loadSupervisorTemplates/>
      </NavApp>

    )
  }
}

export default withTranslation()(SurveyTemplates)