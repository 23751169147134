/**
 * LESSON-TYPES.MODAL
 * List of Media types available
 */
import Modal from "@/components/modal";
import { WithTranslation, withTranslation } from 'react-i18next';
import CardContainer from "@/components/card-container";
import CardButton from "@/components/card-button"
import Space from "@/components/space";
import { faFile, faHandPointer, faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { t } from "@/translate/t";

interface OwnProps{
  onClose : Function
  onSelect : Function
}
  
type Props = OwnProps & WithTranslation

//Liste des cartes disponibles
const CARD_ITEMS = [
  {
    id : "file",
    title : t("lesson_type_file"),
    icon : faFile
  },
  {
    id : "link",
    title : t("lesson_type_link"),
    icon : faHandPointer
  },
  {
    id : "text",
    title : t("lesson_type_text"),
    icon : faPencilAlt
  }

]

function LessonTypesModal(props:Props){
  const { t } = props

  function select(item : string){
    props.onSelect(item)
  }

  return (

    <Modal isCloseButtonVisible
      onClose={()=>props.onClose() }
      title={t("lesson_types")}>

      <CardContainer>
        
        <Space/>

        {CARD_ITEMS.map(choice =>
        <CardButton
          key={choice.id}
          icon={choice.icon}
          title={choice.title}
          onClick={()=>select(choice.id)}>
        </CardButton>
        )}

        <Space/>

      </CardContainer>

    </Modal>

  )

}

export default withTranslation()(LessonTypesModal)