/**
 * TEST-QUICKVOTE
 * Open link to test quickvote
 */

import env from "@/env"
import { store } from "@/index"

const testQuickvote = (language) => {

  //Get current redux store
  const state = store.getState()

  //Open link quickvote
  window.open(env.REACT_APP_URL_QUICKVOTE + '/test?templateId=' + state.template.active.id + 
    (state.topic.active.questionPrimary ? "&questionId=" + state.topic.active.questionPrimary.id : "") +
    "&language=" + language, "_blank")

}

export default testQuickvote