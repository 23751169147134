/**
 * FILTER.ACTIONS
 */

import { DashboardFilters, Filter, FILTER_ACTIVATE, FILTER_ADD, FILTER_EDIT, FILTER_EDIT_DASHBOARD, FILTER_EDIT_DASHBOARD_COMPARE, FILTER_GET, FILTER_GET_IMPORT_RESPONSE, FILTER_GET_OBSERVER_ATTRIBUTES, FILTER_INIT, FILTER_REMOVE, FILTER_REPLACE_DASHBOARD, FILTER_REPLACE_DASHBOARD_COMPARE, FILTER_STATUS } from "./filter.types";
import { Population } from "./population.types";
import { STATUS_LOADED, STATUS_LOADING, STATUS_LOAD_ERROR, STATUS_SAVED, STATUS_SAVE_ERROR, STATUS_SAVING } from "./_status.types";


// REDUX

export const filterActivate = (filter:Filter) => ({
  type : FILTER_ACTIVATE,
  payload : {
    filter
  }
})

export const filterAdd = (filter: Partial<Filter>) => ({
  type : FILTER_ADD,
  payload : {
    filter
  }
})

export const filterEdit = (key:string, value:any) => ({
  type : FILTER_EDIT,
  payload : {
    key,
    value
  }
})

export const filterEditDashboard = (key:string, value:any) => ({
  type : FILTER_EDIT_DASHBOARD,
  payload : {
    key,
    value
  }
})

export const filterEditDashboardCompare = (key:string, value:any) => ({
  type : FILTER_EDIT_DASHBOARD_COMPARE,
  payload : {
    key,
    value
  }
})

export const filterGet = (filters:Filter[]) => ({
  type : FILTER_GET,
  payload : {
    filters
  }
})

export const filterGetImportResponse = (response:object) => ({
  type : FILTER_GET_IMPORT_RESPONSE,
  payload : {
    response
  }
})

export const filterGetObserverAttributes = (observerAttributes:Population[]) => ({
  type : FILTER_GET_OBSERVER_ATTRIBUTES,
  payload : {
    observerAttributes
  }
})

export const filterInit = () => ({
  type : FILTER_INIT
})

export const filterRemove = (id:string) => ({
  type : FILTER_REMOVE,
  payload : {
    id
  }
})

export const filterReplaceDashboard = (dashboardFilters:DashboardFilters) => ({
  type : FILTER_REPLACE_DASHBOARD,
  payload : {
    dashboardFilters
  }
})

export const filterReplaceDashboardCompare = (dashboardFilters:DashboardFilters) => ({
  type : FILTER_REPLACE_DASHBOARD_COMPARE,
  payload : {
    dashboardFilters
  }
})

export const filterStatus = (status:string) => ({
  type : FILTER_STATUS,
  payload : {
    status
  }
})


// API

export const filterDestroy = (id:string) => ({
  type : 'API',
  payload : {
    method : "DELETE",
    url : "/filter/" + id
  }
})

export const filterDestroyAll = () => ({
  type : 'API',
  payload : {
    method : "DELETE",
    url : "/filters"
  }
})

export const filterDestroyList = (filterIds:string[]) => ({
  type : 'API',
  payload : {
    method : "POST",
    url : "/filters/destroy",
    data : {
      filterIds
    }
  }
})

export const filterFetch = (withPopulations:boolean, withDefaultFilters:boolean) => ({
  type : 'API',
  payload : {
    method : "GET",
    url : "/filters",
    data : {
      withPopulations,
      withDefaultFilters
    }
  }
})

export const filterCountDefaultPopulations = () => ({
  type : 'API',
  payload : {
    method : "GET",
    url : "/filters/populations",
  }
})

export const filterFetchCheck = data => ({
  type : 'API',
  payload : {
    method : "POST",
    url : "/filter/check",
    data : {
      data
    }
  }
})

export const filterUpdate = (filter:Filter) => ({
  type : 'API',
  payload : {
    method : "POST",
    url : "/filter",
    data : {
      filter
    }
  }
})

export const filterUpdateList = (filters:Partial<Filter>[]) => ({
  type : 'API',
  payload : {
    method : "POST",
    url : "/filters",
    data : {
      filters
    }
  }
})

export const filterUpdateArchive_AsSupervisor = (surveyId:string) => ({
  type : 'API',
  payload : {
    method : "POST",
    url : "/filter/archive",
    data : {
      surveyId
    }
  }
})


// THUNK

export const filterActivateAndEdit:any = (filter: Filter, key: string, value: any) => async (dispatch, getState) => {
  dispatch(filterActivate(filter))
  dispatch(filterStatus(STATUS_SAVING))
  dispatch(filterEdit(key, value))
  const response = await dispatch(filterUpdate(getState().filter.active))
  dispatch(filterStatus(response.error ? STATUS_SAVE_ERROR : STATUS_SAVED))
}

export const filterAddAndUpdate: any = (filter: Filter) => async (dispatch, getState) => {
  dispatch(filterAdd(filter))
  dispatch(filterStatus(STATUS_SAVING))
  const response = await dispatch(filterUpdate(getState().filter.active))
  dispatch(filterStatus(response.error ? STATUS_SAVE_ERROR : STATUS_SAVED))
}

export const filterEditAndUpdate:any = (key:string, value:any) => async (dispatch, getState) => {
  dispatch(filterStatus(STATUS_SAVING))
  dispatch(filterEdit(key, value))
  const response = await dispatch(filterUpdate(getState().filter.active))
  dispatch(filterStatus(response.error ? STATUS_SAVE_ERROR : STATUS_SAVED))
}

export const filterFetchAndGet:any = () => async (dispatch, getState) => {
  dispatch(filterGet([]))
  if (getState()._session.authLevel > 0){
    dispatch(filterStatus(STATUS_LOADING))
    const response = await dispatch(filterFetch(false, false))
    dispatch(filterStatus(response.error ? STATUS_LOAD_ERROR : STATUS_LOADED))
    dispatch(filterGet(response.error ? [] : response))
  }
}