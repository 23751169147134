/** 
 * DASHBOARD-POPULATIONS.WIDGET
 * "Flute de pan" to display results by population
 */

import { connect } from "react-redux"
import { useEffect, useState } from "react";
import { FilterState } from "@/redux/filter.types";
import { Session } from "@/redux/_session.types";
import PageLoader from "@/components/page-loader";
import { STATUS_LOADED, STATUS_LOADING } from "@/redux/_status.types";
import Card from "@/components/card";
import { store } from "..";
import { fetchHeatmap } from "@/redux/_archive.actions";
import getHeatmapCeils from "@/utils/get-heatmap-ceils.utils";
import { Population } from "@/redux/population.types";
import { sortBy } from "lodash";
import ProgressBar from "@/components/progress-bar";
import ListDropdown from "@/components/list-dropdown";
 
interface StateProps{
  _session : Session
  filter : FilterState
}

function DashboardPopulationsWidget(props:StateProps) {

  const [isLoading, setIsLoading] = useState(true)
  const [filterName, setFilterName] = useState(props._session.accountOptions.heatmapFilterName)
  const [populations, setPopulations] = useState<Population[]>([])

  //Colors for heatmap
  const [color1] = useState(
    props._session.dashboardDisplaySettings.dashboardNightmareModeHeatmap
      ? props._session.dashboardDisplaySettings.dashboardNightmareColor1
      : "#eb5a46"
  )

  const [color2] = useState(
    props._session.dashboardDisplaySettings.dashboardNightmareModeHeatmap
      ? props._session.dashboardDisplaySettings.dashboardNightmareColor2
      : "#cccccc"
  )

  const [color3] = useState(
    props._session.dashboardDisplaySettings.dashboardNightmareModeHeatmap
      ? props._session.dashboardDisplaySettings.dashboardNightmareColor3
      : "#20CA7E"
  )

  //Load data at start and when filters are changed
  useEffect(() => {

    async function load(){

      if (filterName){

        setIsLoading(true)
      
        const response:any = await store.dispatch(fetchHeatmap(
          filterName,
          "survey",
          props._session.userInitialLanguage,
          props._session.accountOptions.heatmapDisplayNoScore,
          props._session.dashboardDisplaySettings.dashboardDisplayMode === "satisfaction",
          props.filter.dashboard,
          getHeatmapCeils(filterName),
          props._session.dashboardDisplaySettings.hideStartedForParticipation,
          false,
          false,
          false,
          null,
          0,
          0
        ))
        
        if (!response.error){

          const newPopulations : Population[] = []
          const data = response.data[2] ? response.data[2] : []

          response.columns.slice(1).forEach((column, i) => {
            const name = column[1]
            const note = data[i + 1]
            if (note){
              newPopulations.push(new Population({
                name,
                note
              }))
            }
          })

          setPopulations(sortBy(newPopulations, "note"))

        }
        
      }

      setIsLoading(false)

    }

    load()
    
  }, [props.filter.dashboard, filterName])

  function getNote(note:number){
    return parseFloat(props._session.accountOptions.dashboardDisplayMode === "note" ? (note / 10).toFixed(1) : note.toFixed(0))
  }

  function getColor(note:number){
    if (note < props._session.dashboardDisplaySettings.dashboardNightmareModeMin){
      return color1
    }else if (note >= props._session.dashboardDisplaySettings.dashboardNightmareModeMax){
      return color3
    }else{
      return color2
    }
  }

  return (
    <PageLoader status={isLoading ? STATUS_LOADING : STATUS_LOADED}>
      <Card isWithoutPadding
        exportName="populations">

        <div style={{ padding : "10px 20px" }}>
          <ListDropdown
            isBold
            isCaretAlwaysVisible
            onSelect={(e) => setFilterName(e.name)}
            value={filterName}
            values={props.filter.list}
          />
        </div>

        { populations.map((population, i) =>
        <div key={i} className="flex"
          style={{ padding : "0px 20px" }}>

          <div style={{ width : 260 }} className="flex flex-auto">
            {population.name}
          </div>

          <div className="flex1">
            { population.note &&
            <ProgressBar
              items={[{ 
                value : getNote(population.note), 
                color : getColor(population.note) }
              ]}
              max={props._session.accountOptions.dashboardDisplayMode === "note" ? 10 : 100}
            />
            }
          </div>

        </div>
        )
        }

        <div className="height-10"/>

      </Card>


    </PageLoader>
  )

}

const mapStateToProps = state => ({
  _session : state._session,
  filter : state.filter
})

export default connect(mapStateToProps)(DashboardPopulationsWidget)