import Space from "@/components/space"
import { t } from "i18next"
import { useSelector } from "react-redux"
import DashboardHeatmapWidget from "./dashboard-heatmap.widget"
import { selectSession } from "@/core/slices/session/session-selector"
import { useState } from "react"
import GollacInfoHelp from "@/help/gollac-info.help"
import Button from "@/components/button"
import Card from "@/components/card"
import { useNavigate } from "react-router-dom"

export const DashboardRpsGollacWidget = () => {
  const session = useSelector(selectSession)
  const [showHelp, setShowHelp] = useState<boolean>(false)

  const handleModalOpen = () => {
    setShowHelp(true)
  }

  const handleModalClose = () => {
    setShowHelp(false)
  }

  const navigate = useNavigate()

  const handleAdminLinkClick = () => {
    navigate("/adm/filters")
  }

  return (
    <>
      <Card>
        {session.accountOptions.gollacFilterName && session.accountOptions.gollacManagerAttributeName ? (
          <div>
            <div className="p-1">
              <p className="text-idt-grey-darker text-base leading-relaxed">
                {t("golac_heatmap_info")}
                <span className="link text-idt-grey-dark inline cursor-pointer" onClick={handleModalOpen}>
                  {t("faq_calculation")}...
                </span>
              </p>
            </div>

            <DashboardHeatmapWidget isGollac={true} />
          </div>
        ) : (
          <div className="m-2 flex items-center">
            <b>
              {t("filter_reference_manager_gollac")} : {t("utils_undefined")}{" "}
            </b>
            <Space />
            {session.userRole === "ADMIN" ? (
              <Button onClick={handleAdminLinkClick}>{t("adm_filter_route")}</Button>
            ) : (
              <div>{t("contact_admin")}</div>
            )}
          </div>
        )}
      </Card>
      {showHelp && <GollacInfoHelp onClose={() => handleModalClose()} />}
    </>
  )
}
