/*
BACKEND.API.TS

*/

import env from "@/env"
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import cookie from "react-cookies"

// Define a service using a base URL and expected endpoints
export const backendApi = createApi({
  reducerPath: "backendApi",
  baseQuery: fetchBaseQuery(),
  tagTypes: ["Supervisor"],
  endpoints: () => ({})
})

//Define actions to trigger when fetch with api is called (backend middleware)
export const fetchBackend = async (url: string, options: RequestInit = {}) => {
  const token = cookie.load("idtreeToken")
  const baseUrl = env.REACT_APP_URL_BACKEND

  // Configurer les en-têtes de la requête
  const headers = new Headers(options.headers || {})
  headers.set("Content-Type", "application/json")

  if (token) {
    headers.set("idtreeToken", token)
  }

  // Envoyer la requête et capturer la réponse
  const response = await fetch(`${baseUrl}${url}`, {
    ...options,
    headers
  })

  // Vérifier si la réponse est correcte
  if (!response.ok) {
    throw new Error("Erreur lors de la requête API")
  }

  // Lire le contenu de la réponse JSON une seule fois
  const data = await response.json()

  return data
}
