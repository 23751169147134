/** 
 * PROJECT-DUPLICATE.MODAL
 * Duplicate project as supervisor
 */
import Checkbox from "@/components/checkbox";
import Modal from "@/components/modal"
import TextInput from "@/components/text-input";
import { store } from "@/index";
import { useEffect, useState } from "react";
import { connect } from "react-redux"
import { projectActivate, projectDuplicate_AsSupervisor, projectEdit } from "@/redux/project.actions";
import { Project, ProjectState } from "@/redux/project.types";

interface StateProps{
  project : ProjectState
}

interface OwnProps{
  onClose : Function
}

type Props = StateProps & OwnProps

function ProjectDuplicateModal(props:Props) {
  
  const [languages, setLanguages] = useState("fr,ar,cs,de,en,es,pl,tr,zh")
  const [isSpecific, setIsSpecific] = useState(true)
  const [specificAccounts, setSpecificAccounts] = useState("")

  useEffect(() => {
    store.dispatch(projectActivate(new Project()))
  }, [])

  function next(){
    store.dispatch(projectDuplicate_AsSupervisor(props.project.active.id, languages.split(","), isSpecific, specificAccounts.split(",")))
    props.onClose()
  }

  return (
    <Modal onClose={() => props.onClose()}
      isCloseButtonVisible
      onNext={() => next()}
      title="Dupliquer le projet">

      <TextInput onChange={(e) => store.dispatch(projectEdit("id", e.value))}
        title="Identifiant du projet"
        value={props.project.active.id}
      />

      <TextInput onChange={(e) => setLanguages(e.value)}
        title="Langues du projet (séparées par des virgules)"
        value={languages}
      />

      <Checkbox onClick={() => setIsSpecific(!isSpecific)}
        text="Copier le projet uniquement pour certains comptes enfants"
        active={isSpecific}
      />

      { isSpecific &&
      <TextInput onChange={(e) => setSpecificAccounts(e.value)}
        title="Identifiants des comptes concernés"
        value={specificAccounts}
      />
      }
      
    </Modal>
  )

}

const mapStateToProps = state => ({
  project : state.project
})

export default connect(mapStateToProps)(ProjectDuplicateModal)