import Space from "@/components/space"
import { connect } from "react-redux"
import { NavigateFunction, useNavigate } from "react-router-dom"
import { Session } from "@/redux/_session.types"
import { Topic } from "@/redux/topic.types"

interface StateProps{
  _session : Session
}

interface OwnProps{
  isPreview? : boolean
  topic: Topic
}

type Props = StateProps & OwnProps

function DashboardTopicOverviewWidget(props: Props){

  const navigate: NavigateFunction = useNavigate()

  function clickTopic(){
    if (!props.isPreview){
      navigate("/dashboard/questions?topic_id=" + props.topic.id)
    }
  }

  return (
    <div className="flex">
      <div
        className="podium-pastille"
        style={{backgroundColor: props.topic.axisColor}}
      />

      <div className="flex1 flex podium-text-label"
        onClick={clickTopic}>
        <div className="width-10" />
        {props.topic.label}
        <div className="width-10" />
      </div>

      <div
        className="podium-note flex flex-dcol"
        style={{ 
          color : props.topic.axisColor,
          width: 42
        }}
      >
        <Space />
        <b>
          {props._session.dashboardDisplaySettings.dashboardDisplayMode === "note" ?
          props.topic.noteLabel
          :
          props.topic.satisfaction.toFixed(0)+"%"
          }
        </b>
        <Space />
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  _session : state._session
})

export default connect(mapStateToProps)(DashboardTopicOverviewWidget)