/** 
 * SAVE-ICON
 * Loader showing if save is successful
 */
import { faCheck, faCircleNotch, faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"
import { WithTranslation, withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { Session } from "@/redux/_session.types"
import { STATUS_SAVED, STATUS_SAVE_ERROR, STATUS_SAVING } from "@/redux/_status.types"
import { FadeOut } from "@/utils/animations.utils"

interface StateProps extends WithTranslation{
  _session : Session
}

interface OwnProps{
  isStatusDisplayed? : boolean //Show confirmation texte
  status : string | null //Status to display
}

type Props = StateProps & OwnProps

function SavedIcon(props:Props) {
  const { t } = props

  //Show status (trigger when i lose focus)
  const [showStatus, setShowStatus] = useState(false)
  const [showEndStatus, setShowEndStatus] = useState(false)

  //After 200 ms set status to show loading spinner
  //After 1s of fadeOut hide the status
  useEffect(() => {
    if (props.status === STATUS_SAVING){
      setShowStatus(true)

      setTimeout(() => {
        setShowEndStatus(true)

        setTimeout(() => {
          setShowEndStatus(false)
          setShowStatus(false)
        }, 1000)

      }, 200)

    }
  }, [props.status])

  return (
    <div className="text-input-blur-status flex">

      { showStatus &&
      <div className="flex flex-auto">

        { !showEndStatus 
        ?
        <FontAwesomeIcon icon={faCircleNotch} spin/>
        :
        <FadeOut className="flex">

          { props.status === STATUS_SAVING &&
          <FontAwesomeIcon icon={faCircleNotch} spin/>
          }
          
          { props.status === STATUS_SAVED &&
          <FontAwesomeIcon icon={faCheck} style={{
            color : props._session.accountColors.active
          }}
          />
          }

          { props.status === STATUS_SAVE_ERROR &&
          <FontAwesomeIcon icon={faTimes}/>
          }

          { (props.status === STATUS_SAVED && props.isStatusDisplayed) &&
          <div style={{
              color : props._session.accountColors.active,
              fontSize : 12,
              marginLeft : -8,
              marginRight : 20
            }}>
            {t("utils_saved")}
          </div>
          }

        </FadeOut>
        }

      </div>
      }
    </div>

  )
}

const mapStateToProps = state => ({
  _session : state._session
})

export default connect(mapStateToProps)(withTranslation()(SavedIcon))