/** 
 * QUESTION-SPECIAL.HELP
 * Info about rps
 */
import HelpModal from "@/components/modal-help"
import { withTranslation, WithTranslation } from "react-i18next"

interface OwnProps extends WithTranslation{
  onClose : Function
}

function QuestionSpecialHelp(props:OwnProps) {
  const { t } = props

  return (
    <HelpModal onClose={props.onClose}
      title={t("question_rps")}>
        
      <p>{t("question_rps_help")}</p>

      <ul>
        <li>
          <b>{t("rps_decision_latitude")}</b> : &nbsp;
          {t("rps_decision_latitude_help")}
        </li>
        <li>
          <b>{t("rps_psychological_job_demands")}</b> : &nbsp;
          {t("rps_psychological_job_demands_help")}
        </li>
        <li>
          <b>{t("rps_skill_use")}</b> : &nbsp;
          {t("rps_skill_use_help")}
        </li>
        <li>
          <b>{t("rps_manager_support")}</b> : &nbsp;
          {t("rps_manager_support_help")}
        </li>
        <li>
          <b>{t("rps_team_support")}</b> : &nbsp;
          {t("rps_team_support_help")}
        </li>
        <li>
          <b>{t("rps_work_gratitude")}</b> : &nbsp;
          {t("rps_work_gratitude_help")}
        </li>
      </ul>

    </HelpModal>
  )
}

export default withTranslation()(QuestionSpecialHelp)