/**
 * AXIS
 */

import env from "@/env"
import getLabelShort from "@/utils/get-label-short.utils"
import getLabel from "@/utils/get-label.utils"
import { ProjectAxisRule } from "./project.types"
import { Population } from "./population.types"
import { t } from "@/translate/t"

export class Axis{
  color: string = "#c1c1c1"
  id: string = ""
  image: string | null = null
  defaultImage: boolean = false
  name: any = {}
  note: number = 0
  open: boolean = false
  usersCount: number = 0

  constructor(axis: Partial<Axis> = {}){
    Object.assign(this, axis)
  }

  //Get label from langague
  get label():string{
    return getLabel(this.name)
  }

  //Cut label
  get labelShort():string{
    return getLabelShort(this.label)
  }

  get picture():string{
    return env.REACT_APP_URL_SPACE.concat((this.defaultImage ? "/_default/" : "/Axes/"), this.image + ".png?" + (new Date().getTime()))
  }

  //Check if an axis should be displayed according the populationsObserver settings for the connected user
  isDisplayed(segmentationByAxesRules:ProjectAxisRule[], populationsObserver:Population[]):boolean{
    const axisRule = segmentationByAxesRules.find(x => x.axisId === this.id)
    if (axisRule && populationsObserver.length > 0){

      let isDisplayed = false
      for (let i = 0; i < populationsObserver.length; i++) {
        const item = populationsObserver[i]
        const population = axisRule.populations.find(x => x.filterName === item.filterName && x.name === item.name)
        if (population){
          isDisplayed = true
          break
        }
      }

      return isDisplayed

    }else{
      return true
    }
  }

  getAttributesLabel(axesInvited):string{
    const axisInvited = axesInvited.find(x => x.id === this.id)
    if (axisInvited){
      return axisInvited.attributesLabel.map(x => t(x)).join(", ")
    }else{
      return ""
    }
  }

  getAttributesLabelShort(axesInvited):string{
    const label = this.getAttributesLabel(axesInvited)
    return getLabelShort(label)
  }

  getNoteLabel(dashboardDisplayMode:string):string{
    if (!this.note){
      return ""
    }else if (dashboardDisplayMode === "note"){
      return (this.note / 10).toFixed(1)
    }else{
      return this.note.toFixed(0) + "%" 
    }
  }

}

export interface AxisState{
  active : Axis
  list : Axis[]
  status : string
}

export const CONFIG_AXIS_COLORS = [
  "#0079BF",
  "#61BD4F",
  "#FFAB4A",
  "#EB5A46",
  "#C377E0",
  "#FF80CE",
  "#00C2E0",
  "#42548E"
]

export const AXIS_ACTIVATE = 'AXIS_ACTIVATE'
export const AXIS_ADD = 'AXIS_ADD'
export const AXIS_COPY = 'AXIS_COPY'
export const AXIS_EDIT = 'AXIS_EDIT'
export const AXIS_EDIT_NAME = 'AXIS_EDIT_NAME'
export const AXIS_GET = 'AXIS_GET'
export const AXIS_INIT = 'AXIS_INIT'
export const AXIS_OPEN = 'AXIS_OPEN'
export const AXIS_REMOVE = 'AXIS_REMOVE'
export const AXIS_STATUS = 'AXIS_STATUS'
export const AXIS_SWAP = 'AXIS_SWAP'