import { sum } from "lodash";

//Calculate ratio of person in a repartition class
//Repartition can include noAnswers also
export function getRepartitionRatio(count: number, repartition: number[]): number{
  if(repartition.length < 1){
    return 0
  }
  else{
    const sum: any = repartition.reduce((partialSum: number, a: number) => partialSum + a, 0)
    return sum > 0 ? count / sum * 100 : 0
  }
}


//calculate satisfaction rate from repartition array 
export function getSatisfaction(repartition: number[], noAnswers: number): number{
  if(repartition.length > 0){
    const denominator: number = repartition.reduce((partialSum: number, a: number) => partialSum + a, 0) + (noAnswers > 0 ? noAnswers : 0)
    const lengthPositive : number = Math.ceil(repartition.length / 2) 
    
    return denominator > 0 ? sum(repartition.slice(-lengthPositive)) / denominator * 100 : -1
  }
  else{
    return -1
  }
}