/**
 * Regrouping
 */

import { sum } from "lodash"
import { Attribute } from "./attribute.types"
 
export class Regrouping{
  id : string = ""
  name : string = ""
  filterName : string = ""
  attributes : string[] = []
  isExpanded : boolean = false
  selected : boolean = false
  Attributes : Attribute[] = []
  AccountId : string | null = null

  constructor(regrouping: Partial<Regrouping> = {}){
    if (!regrouping) regrouping = new Regrouping()
    regrouping.Attributes = regrouping.Attributes ? regrouping.Attributes.map(x => new Attribute(x)) : []
    Object.assign(this, regrouping)
  }

  get usersCount():number{
    return sum(this.Attributes.map((x: Attribute) => x.usersCount))
  }


}

export interface RegroupingState{
  active : Regrouping
  list : Regrouping[]
  status : string
}

export const REGROUPING_ACTIVATE : string = "REGROUPING_ACTIVATE"
export const REGROUPING_ADD : string = "REGROUPING_ADD"
export const REGROUPING_COPY : string = "REGROUPING_COPY"
export const REGROUPING_EDIT : string = "REGROUPING_EDIT"
export const REGROUPING_EDIT_LESSON : string = "REGROUPING_EDIT_LESSON"
export const REGROUPING_GET : string = "REGROUPING_GET"
export const REGROUPING_INIT : string = "REGROUPING_INIT"
export const REGROUPING_REMOVE : string = "REGROUPING_REMOVE"
export const REGROUPING_STATUS : string = "REGROUPING_STATUS"