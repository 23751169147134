/** 
 * DASHBOARD-REPARTITION.WIDGET
 * Repartition of users for a survey
 */
import { withTranslation, WithTranslation } from "react-i18next"
import Card from "@/components/card";
import DashboardRepartitionChartWidget from "./dashboard-repartition-chart.widget";
//import { NavigateFunction, useNavigate } from "react-router-dom";

type Props = WithTranslation

function DashboardRepartitionWidget(props: Props) {
  const { t } = props

  return (
    <Card className="_hover flex1"
      exportName={t("survey_repartition")}
      title={t("survey_repartition")}
      //onClick={() => navigate("/dashboard/repartition")}
      >
      <DashboardRepartitionChartWidget/>
    </Card>
  )

}

export default withTranslation()(DashboardRepartitionWidget)