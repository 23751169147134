/** 
 * LIST-INPUT
 * Text input editable in list
 */
import { useEffect, useRef, useState } from "react"
import { connect } from "react-redux"
import Style from 'style-it';
import { WithTranslation, withTranslation } from 'react-i18next'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { validateEmail } from "@/utils/validate-email.utils";
import { toast } from "react-toastify";
import { Session } from "@/redux/_session.types";

interface StateProps extends WithTranslation{
  _session : Session
}

interface OwnProps{
  isDisabled? : boolean //Set input as inactive
  isEdited? : boolean //Is text value edited by default
  isEditButtonVisible? : boolean //Always display the pencil
  onEdit? : Function //Action when text edition start
  onEditEnd : Function //Action when text is edited
  value : any //Value to display
  type? : string //Type of input
}

type Props = StateProps & OwnProps

function ListInput(props:Props) {
  const { t } = props

  //Style onHover : call to action color
  const style = ".list-input-text:hover{ color: " + props._session.accountColors.cta + "; }"

  //Detect text input
  //Allow to detect blur when keydown = "Enter"
  const refInput = useRef<HTMLInputElement>(null)

  //Si element edited
  //If yes display textinput instead of text value
  const [isEdited, setIsEdited] = useState(props.isEdited)

  //Text value to edit
  const [textEdited, setTextEdited] = useState(props.value)

  //Detect action on keyboard
  //> Enter => save
  //> Escape => cancel
  useEffect(() => {
  
    const onKeyDown = (e) => {
      if (e.key === 'Enter'){
        refInput.current?.blur?.()
      }
      else if (e.key === 'Escape'){
        setIsEdited(false)
        setTextEdited(props.value)
      }
    }

    if (isEdited){
      window.addEventListener('keydown', onKeyDown)
    }else{
      window.removeEventListener('keydown', onKeyDown)
    }

  })

  useEffect(() => {
    setTextEdited(props.value)
  }, [props.value])

  //Start editing
  //Send info to parent component
  function edit(e){
    e.stopPropagation()
    setIsEdited(true)
    if (props.onEdit){
      props.onEdit()
    }
  }

  //End editing
  //Return edited value with validation if email
  function endEdit(){
    let next = true

    if (props.type === "email"){
      if (!validateEmail(textEdited)){
        toast(t("utils_email_invalid"), { type : 'error' })
        next = false
      }
    }

    if (next){
      setIsEdited(false) 
      props.onEditEnd(textEdited)
    }

  }

  return (
    <div className={"list-input " + (isEdited ? "flex1" : "")}
      style={{
        marginTop : (props.type === "date" && isEdited) ? "-6px" : ""
      }}>

      <Style>{style}</Style>

      { ( !isEdited && !props.isDisabled)
      ?
      <div className={"list-input-text" + (!props.isEditButtonVisible ? " list-input-text-button-not-visible" : "")}
        onClick={(e) => edit(e)}>

        { props.value?.length > 0
        ?
        props.value
        :
        <i>
          {t("utils_undefined")}
        </i>
        }

        <FontAwesomeIcon icon={faPencilAlt}/>

      </div>
      :
      <input autoFocus
        onBlur={endEdit}
        onClick={(e) => e.stopPropagation()}
        onChange={(e) => setTextEdited(e.target.value)}
        ref={refInput}
        value={textEdited}
        disabled={props.isDisabled}
        type={props.type}>
      </input>
      }

    </div>
  )

}

const mapStateToProps = state => ({
  _session : state._session
})

export default connect(mapStateToProps)(withTranslation()(ListInput))