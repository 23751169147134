/** 
 * CHECKBOX
 */

import { useState } from "react"
import { faCheck } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import SaveIcon from "./save-icon"

interface OwnProps{
  active? : boolean //Is checkbox active
  id? : string //Id of the checkbox
  onClick : Function | null //Function to trigger onclick
  status? : string //Show save status
  text? : string //Text to display
}

function Checkbox(props:OwnProps) {

  //Show status after editing
  const [showStatus, setShowStatus] = useState(false)

  //Trigger click on checkbox
  function click(event:any){
    event.stopPropagation()

    setShowStatus(true)

    if (props.onClick){
      props.onClick({ id : props.id, value : !props.active })
    }

    setTimeout(() => {
      setShowStatus(false)
    }, 2000)
  }

  function getClassName(){
    let className = "checkbox-container flex"

    if (!props.onClick){
      className += " checkbox-inactive"
    }

    return className
  }

  return (
    <div className={getClassName()}>

      <div className="flex">

        <div onClick={click} 
          className={props.active ? "checkbox checkbox-active flex" : "checkbox"}>
          { props.active &&
          <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
          }
        </div>
        
        { props.text &&
        <div className="checkbox-label">
          {props.text}
        </div>
        }

        { props.status &&
        <SaveIcon status={showStatus ? props.status : null}/>
        }

      </div>

    </div>

  )
}

export default Checkbox