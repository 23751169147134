/** 
 * PAGE-PLACEHOLDER
 * Big CTA on page (if no date for exemple)
 */
import { faLightbulb } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Space from "./space"
import Button from "./button"

interface OwnProps{
  image? : string
  button? : string
  onClick? : Function
  help? : string
  title? : string
  url? : string
}

function PagePlaceholder(props:OwnProps) {

  function click(){
    if (props.onClick){
      props.onClick()
    }
  }

  return (
    <div className="page-empty">
      
      <h2>{props.title}</h2>

      { props.image &&
      <img src={require("@/assets/" + props.image + ".png")}
        alt="empty">
      </img> 
      }

      { props.onClick &&
      <div className="flex page-empty-button">
        <Space/>
        <a href={props.url ? props.url : "#"}>
          <Button className="primary" 
            isLarge
            onClick={click}>
            {props.button}
          </Button>
        </a>
        <Space/>
      </div>
      }

      { props.help &&
      <div className="flex page-empty-help grey-t">
        <Space/>
        <FontAwesomeIcon icon={faLightbulb}></FontAwesomeIcon>
        <div>{props.help}</div>
        <Space/>
      </div>
      }

    </div>

  )

}

export default PagePlaceholder