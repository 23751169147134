/**
 * ADM-IMPORT-FILE.ROUTE
 * Upload a file (csv or excel) in order to import users
 */
import NavFocus from "@/nav/nav.focus";
import { WithTranslation, withTranslation } from 'react-i18next';
import Card from "@/components/card";
import PageHeader from "@/components/page-header";
import AdmImportFileWidget from "@/widgets/adm-import-file.widget";
import { NavigateFunction, useNavigate } from "react-router-dom";

type Props = WithTranslation

function AdmImportFileRoute(props:Props){
  const { t } = props;
  const navigate: NavigateFunction = useNavigate()
  return(
    <NavFocus onBack={() => navigate('/adm/users')}>
      <PageHeader title={t("adm_upload_title")} subtitle={t("adm_upload_subtitle")}/>
      <Card>
        <AdmImportFileWidget onNext={() => navigate('/adm/import-mapping')}/>
      </Card>
    </NavFocus>
  )
}

export default withTranslation()(AdmImportFileRoute)