/** 
 * IMAGE-COLORS-PICKER
 * Pick a color from account logo
 */

import { faCheckCircle, faPaintBrush } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@/components/button';
import Space from '@/components/space';
import { useEffect, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

interface OwnProps{
  url: string,
  onSave?: Function
}

type Props = OwnProps & WithTranslation

//Default color white
const CANVAS_HEIGHT: number = 100
const CANVAS_WIDTH: number = 100

function ImageColorsPickerWidget(props:Props) {
  const { t } = props

  const [imageData, setImageData] = useState<any>(null)
  const [pickedColor, setPickedColor] = useState<number[]>([0,0,0,255])
  const [medianColor, setMedianColor] = useState<number[]>([0,0,0,255])

  useEffect(()=>{
    const drawImage = () =>{

      const image: any = new Image()
      image.crossOrigin = "http://localhost:3000";

      const canvas: any = document.getElementById("myCanvas");
      const ctx: any = canvas.getContext("2d", {
        willReadFrequently: true
      });

      //image.src = require("@/assets/default_icons/flags/france.png")
      image.src = props.url ? props.url : require("@/assets/logo-sm-account.png")

      image.onload = () => {
        canvas.width = CANVAS_WIDTH
        canvas.height = CANVAS_HEIGHT
        ctx.drawImage(image, 0, 0, image.width, image.height, 0, 0, canvas.width, canvas.height);
        setImageData(ctx)

        const median: number[] = findMedianColorRange(ctx.getImageData(0, 0, canvas.width, canvas.height).data)

        setMedianColor(median)
        setPickedColor(median)
      }
    }

    drawImage()

  }, [props.url])

  function findMedianColorRange(imageData: number[]){

    const reds: number[] = []
    const greens: number[] = []
    const blues: number[] = []

    for (let i: number = 0; i < imageData.length; i += 4) {
      reds.push(imageData[i])
      greens.push(imageData[i + 1])
      blues.push(imageData[i + 2])
    }

    return [
      reds.sort()[Math.floor(reds.length/2)],
      greens.sort()[Math.floor(greens.length/2)],
      blues.sort()[Math.floor(blues.length/2)],
      255
    ]
  }

  function getData(e: any){
    const data: any = imageData.getImageData(e.nativeEvent.offsetX, e.nativeEvent.offsetY, 1, 1)
    setPickedColor(data.data)
  }

  function save(){
    if(props.onSave){
      props.onSave(pickedColor)
    }
  }

  return (
    <div>

      <div className="flex">

        <canvas
          id="myCanvas"
          style={{cursor: "crosshair"}}
          onClick={(e: any)=>getData(e)}
        />

        <div className="width-40"/>

        <div className="flex1 flex flex-dcol">

          <Space/>
          <div className="flex">

            <div style={{backgroundColor:"rgba("+pickedColor.join(",")+")", height:50, width:50, borderRadius:50}} />

            <div className="width-20"/>

            <div>
              <div>{`rgba(${pickedColor.join(", ")})`}</div>
              <div className="_hover grey-t flex" onClick={()=>setPickedColor(medianColor)}>

                <div className="flex flex-dcol">

                  <Space/>
                  {medianColor !== pickedColor ?
                  <FontAwesomeIcon icon={faPaintBrush} />
                  :
                  <FontAwesomeIcon icon={faCheckCircle} />
                  }
                  <Space/>

                </div>

                <div className="width-10" />

                <u>
                  {t("use_median_color")}
                </u>
              </div>
            </div>

          </div>
          <Space/>
    
        </div>

      </div>

      <div className="height-20"/>

      <div className="flex">
        <Space />
        <Button className="primary" onClick={save} >{t("utils_save")}</Button>
      </div>

    </div>
  )
}

export default withTranslation()(ImageColorsPickerWidget)