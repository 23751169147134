/** 
 * REPORT-Page-BACKGROUND.WIDGET.TSX
 * Report slide background
 */
import { connect } from "react-redux";
import { Session } from "@/redux/_session.types";

interface StateProps{
  _session : Session
}

interface OwnState{
  backgroundId:number
}

type Props = StateProps & OwnState

function ReportPageBackgroundWidget(props:Props) {

  function getBackground(){
    switch(props.backgroundId){
      case 1:
        return (
          <svg viewBox="0 0 200 120">
            <polygon points="200,0 200,120 80,200 105,0"
              style={{
                fill : props._session.accountColors.brandPrimary + "66"
              }}>
            </polygon>
          </svg>
        )
      case 2:
        return (
          <svg viewBox="0 0 200 120">
            <circle cx="75%" cy="0%" r="35" fill="transparent" strokeWidth="15"
              style={{
                stroke : props._session.accountColors.brandPrimary + "66"
              }}
            />
            <circle cx="90%" cy="90%" r="45" fill="transparent" strokeWidth="15"
              style={{
                stroke : props._session.accountColors.brandPrimary + "66"
              }}
            />
          </svg>
        )
      case 3:
        return (
          <svg viewBox="0 0 200 120">
            <circle cx="115%" cy="0%" r="100"
              style={{
                fill : props._session.accountColors.brandPrimary + "4D"
              }}
            />
            <circle cx="-10%" cy="100%" r="60"
              style={{
                fill : props._session.accountColors.brandPrimary + "33"
              }}
            />
          </svg>
        )
      default:
        return <div/>

    }
  }

  return getBackground()
  
}

const mapStateToProps = state => ({
  _session : state._session
})

export default connect(mapStateToProps)(ReportPageBackgroundWidget)