/** 
 * EDITOR-AXES
 * List of axes in the editor in list mode
 */
import { withTranslation, WithTranslation } from "react-i18next"
import { connect } from "react-redux"
import Space from "@/components/space";
import { AxisState } from "@/redux/axis.types";
import Card from "@/components/card";
import EditorTopicsWidget from "./editor-topics.widget";
import env from "@/env";
import EditorAxesFieldsWidget from "./editor-axes-fields.widget";
import { TopicState } from "@/redux/topic.types";
import { FadeIn } from "@/utils/animations.utils";
import { TemplateState } from "@/redux/template.types";
import { faChevronDown, faChevronRight, faPlus } from "@fortawesome/free-solid-svg-icons";
import ListButton from "@/components/list-button";
import { store } from "@/index";
import { axisOpen } from "@/redux/axis.actions";
import { useState } from "react";
import EditorAxesOptionsWidget from "./editor-axes-options.widget";
import AddAxisModal from "@/modals/add-axis.modal";

interface StateProps extends WithTranslation{
  axis : AxisState
  template : TemplateState
  topic : TopicState
}

interface OwnProps{
  onHelp : Function
  onOpenAdvanced : Function
}

type Props = StateProps & OwnProps

const MODAL_AXIS_ADD = "MODAL_AXIS_ADD"

function EditorAxes(props:Props) {
  const { t } = props

  //Modal to display
  const [currentModal, setCurrentModal] = useState<string | null>(null)

  //If without axes questions open
  const [withoutAxesOpen, setWithoutAxesOpen] = useState(true)

  //Detect if there are questions without axes
  //Create specific bloc
  function questionWithoutAxes(){
    return props.topic.list.filter(x => x.Axis === null).length > 0
  }

  return (
    <FadeIn className="editor-axes">

      { currentModal === MODAL_AXIS_ADD &&
      <AddAxisModal onClose={() => setCurrentModal(null)}/>
      }

      { props.axis.list.map((axis, i) => 
      <Card key={axis.id}
        isWithoutPadding>

        <div className="flex editor-axes-header"
          id={"axis-" + axis.id}>

          <div className="editor-axes-header-open">
            <ListButton icon={axis.open ? faChevronDown : faChevronRight} 
              onClick={() => store.dispatch(axisOpen(axis.id))}
            />
          </div>

          <h2 style={{ color : axis.color }}>
            {t("axis_with_number", {
              number : i + 1,
              name : axis.name[props.template.active.language]
            })}
          </h2>

          <Space/>

          { axis.open
          ?
          <div>
            { axis.image &&
            <img alt={axis.id}
              src={env.REACT_APP_URL_SPACE.concat("/Axes/", axis.image, ".png") }>
            </img>
            }
          </div>
          :
          <div style={{ margin : '20px 30px' }}>
            <EditorAxesOptionsWidget currentAxis={axis}/>
          </div>
          }

        </div>

        { axis.open &&
        <div>
          <EditorAxesFieldsWidget currentAxis={axis} onHelp={props.onHelp}/>
          <EditorTopicsWidget axisId={axis.id}
            onOpenAdvanced={() => props.onOpenAdvanced()}
            axisNumber={(i + 1).toString()}
          />
        </div>
        }

      </Card>
      )
      }

      <Card isWithoutPadding>
        <div className="flex editor-axes-header">

          <div className="editor-axes-header-open">
            <ListButton icon={faPlus} 
              onClick={() => setCurrentModal(MODAL_AXIS_ADD)}
            />
          </div>

          <h2>{t("axis_add")}</h2>

          <Space/>

        </div>
      </Card>
      
      { questionWithoutAxes() &&
      <Card isWithoutPadding>

        <div className="flex editor-axes-header">

          <div className="editor-axes-header-open">
            <ListButton icon={withoutAxesOpen ? faChevronDown : faChevronRight} 
              onClick={() => setWithoutAxesOpen(!withoutAxesOpen)}
            />
          </div>

          <h2>{t("questions_without_axis")}</h2>

          <Space/>

        </div>

        { withoutAxesOpen &&
        <div>

          <EditorAxesFieldsWidget currentAxis={null} onHelp={props.onHelp}/>

          <EditorTopicsWidget axisId={null}
            onOpenAdvanced={() => props.onOpenAdvanced()}
            axisNumber={questionWithoutAxes() ? "x" : null}
          />
        
        </div>
        }

      </Card>
      }

      <div style={{ height : '460px' }}/>

    </FadeIn>
    
  )

}

const mapStateToProps = state => ({
  axis : state.axis,
  template : state.template,
  topic : state.topic
})

export default connect(mapStateToProps)(withTranslation()(EditorAxes))