/** 
 * SUPERVISOR-TEMPLATES.ROUTE
 * Global admin template and questions
 */
import { useState, useEffect } from "react"
import { connect } from "react-redux";
import { WithTranslation, withTranslation } from 'react-i18next'
import PageHeader from "@/components/page-header";
import { store } from "@/index";
import { STATUS_LOADED, STATUS_LOADING, STATUS_LOAD_ERROR, STATUS_SEARCHING } from "@/redux/_status.types";
import { templateAdd, templateActivate, templateGet, templateFetch_AsSupervisor, templateStatus, templateGetCountSearch, templateGetCount, templateFetchCount_AsSupervisor } from "@/redux/template.actions";
import Card from "@/components/card";
import ListContainer from "@/components/list-container";
import { Template, TemplateState } from "@/redux/template.types";
import ListItem from "@/components/list-item";
import ListFields from "@/components/list-fields";
import ListCell from "@/components/list-cell";
import Space from "@/components/space";
import ListIcon from "@/components/list-icon";
import { faClipboardList, faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import env from "@/env";
import Chip from "@/components/chip";
import ListButton from "@/components/list-button";
import AddTemplateModal from "@/modals/add-template.modal";
import LoadingModal from "@/modals/loading.modal";
import { Session } from "@/redux/_session.types";
import { TopicState } from "@/redux/topic.types";
import { topicFetchAndGet } from "@/redux/topic.actions";
import { QuestionState } from "@/redux/question.types";
import { Config } from "@/redux/_config.types";
import Button from "@/components/button";
import NavSupervisor from "@/nav/nav.supervisor";
import Link from "@/components/link";
import ProjectDuplicateModal from "@/modals/project-duplicate.modal";
import { NavigateFunction, useNavigate } from "react-router-dom";

interface Props extends WithTranslation{
  _config : Config,
  _session : Session,
  question : QuestionState,
  template : TemplateState,
  topic : TopicState
}

//Dialog
const MODAL_ADD_TEMPLATE = 'MODAL_ADD_TEMPLATE' //add new template
const MODAL_PROJECT_DUPLICATE = 'MODAL_PROJECT_DUPLICATE' //Duplicate project (for multi-account)

//Limit of items displayed by page
const LIMIT = 8

function SupervisorTemplatesRoute(props: Props){
  const { t } = props
  const navigate: NavigateFunction = useNavigate()

  const [currentModal, setCurrentModal] = useState<string | null>(null)
  const [currentOffset, setCurrentOffset] = useState<number>(0)
  const [currentSearch, setCurrentSearch] = useState<string>("")


  //Add template
  function addTemplate(){
    store.dispatch(templateAdd(props._session.language))
    setCurrentModal(MODAL_ADD_TEMPLATE)
  }

  //Load page on start
  useEffect(() => {

    //Load page data
    //Data depends of the tab (question or templates)
    async function loadPage(){

      store.dispatch(templateStatus(STATUS_SEARCHING))
      const response:any = await store.dispatch(templateFetch_AsSupervisor(currentSearch, props._session.language, LIMIT, currentOffset))
      const count:any = await store.dispatch(templateFetchCount_AsSupervisor(currentSearch, props._session.language))
      
      store.dispatch(templateGet(response.error ? [] : response))
      store.dispatch(templateGetCountSearch(count.count))
      if (!currentSearch.length) store.dispatch(templateGetCount(count.count))

      store.dispatch(templateStatus(response.error ? STATUS_LOAD_ERROR : STATUS_LOADED))

    }

    loadPage()

  }, [
    props._session.language,
    currentSearch,
    currentOffset
  ])

  //Clic on template
  function getTemplate(template: Template){
    store.dispatch(templateActivate(template))
    store.dispatch(topicFetchAndGet(null))
    navigate("/template/editor")
  }

  //Search
  function search(search:string){
    setCurrentSearch(search)
    setCurrentOffset(0)
  }
  

  //Change page (for results search)
  async function setPage(offset: number){
    setCurrentOffset(offset)
  }
  

  return(
    <NavSupervisor>

      { props.topic.status === STATUS_LOADING &&
      <LoadingModal/>
      }

      { currentModal === MODAL_ADD_TEMPLATE &&
      <AddTemplateModal onClose={() => setCurrentModal(null)}/>
      }

      {currentModal === MODAL_PROJECT_DUPLICATE &&
      <ProjectDuplicateModal onClose={() => setCurrentModal(null)}/>
      }
      
      <PageHeader title={t("utils_content")}>
        <Button className="primary"
          onClick={addTemplate}>
          {t("template_create")}
        </Button>
      </PageHeader>

      <Card isWithoutPadding>

        <ListContainer countLimit={LIMIT}
          countSearch={props.template.countSearch}
          isFooterActive
          onSearch={search}
          onSetPage={setPage}
          status={props.template.status}>

          <ListFields>
            <ListCell/>
            <ListCell width={300} text={t("user_lastname")}/>
            <Space/>
            <ListCell width={140} text={t("utils_status")}/>
            <ListCell/>
          </ListFields>

          { props.template.list.map(template =>
          <ListItem key={template.id}>

            <ListIcon fallbackIcon={faClipboardList}
              image={template.image ? env.REACT_APP_URL_SPACE.concat("/Templates/", template.image, ".png") : null}
            />

            <ListCell width={300} 
              text={template.name[props._session.language]}
            />

            <Space/>

            <ListCell width={140}>
              { template.draft &&
              <Chip color="#EB5A46">
                {t("survey_draft")}
              </Chip>
              }
            </ListCell>

            <ListButton icon={faPencilAlt}
              onClick={() => getTemplate(template)}
              text={t("utils_edit")}
            />

          </ListItem>
          )
          }

        </ListContainer>

      </Card>
      
      <p className="grey-t">
        <b>Autres</b>
      </p>
      
      <Link isWithoutMargin
        onClick={() => setCurrentModal(MODAL_PROJECT_DUPLICATE)}>
        Dupliquer projet
      </Link>

      <div className="height-100"/>

    </NavSupervisor>
  )

}

const mapStateToProps = (state:any) => ({
  _config : state._config,
  _session : state._session,
  question : state.question,
  template : state.template,
  topic : state.topic
})

export default connect(mapStateToProps)(withTranslation()(SupervisorTemplatesRoute))