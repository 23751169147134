/** 
 * LIST-ICON
 * Little round image in list
 */
import { IconDefinition } from "@fortawesome/fontawesome-common-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Space from "./space"
import { useEffect, useState } from "react"
import AnimatedColorSvgWidget from "@/widgets/animated-color-svg.widget"

interface OwnProps{
  image? : string | null
  fallbackIcon : IconDefinition
  iconColor? : string,
  onClick? : Function
  spin?: boolean
  isAnimated?: boolean
}

function ListIcon(props:OwnProps) {

  const [isAnimated, setIsAnimated] = useState<boolean>(props.isAnimated === true)

  useEffect(() => {
    setIsAnimated(props.isAnimated === true)
  }, [props.isAnimated])

  function click(e: any){
    e.stopPropagation()
    if (props.onClick){
      props.onClick()
    }
  }

  function getClassName(){
    let className = "list-icon flex grey-t"

    if (props.onClick){
      className += " list-icon-editable"
    }

    return className
  }

  return (
    <div className={getClassName()}
      onClick={click}>

      <AnimatedColorSvgWidget />

      { props.image
      ?
      <img src={props.image}
        alt="list-icon"
        style={{ backgroundColor : props.iconColor }}
      />
      :
      <div className="list-icon-fallback flex">
        <Space/>

        <FontAwesomeIcon icon={props.fallbackIcon}
          className={isAnimated ? "list-icon-animation" : undefined}
          style={{ 
            color: props.iconColor,
          }}
          spin={props.spin}
        />
        <Space/>
      </div>
      }

      <Space/>

    </div>
  )
}

export default ListIcon