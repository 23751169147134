/** 
 * REPORT-SCREENSHOT-QUESTIONS.WIDGET.TSX
 * Preview of the slide
 */
import { connect } from "react-redux";
import { WithTranslation, withTranslation } from "react-i18next";
import { Session } from "@/redux/_session.types";
import { Topic, TopicState } from "@/redux/topic.types";
import { pageInitDashboardFilters } from "@/redux/page.actions";
import { useEffect, useState } from "react"; 
import { store } from "@/index";
import { fetchMessages } from "@/redux/_archive.actions";
import { flatMap } from "lodash";
import { Question } from "@/redux/question.types";
import { MESSAGE_CEIL_COUNT_SUMMARY, Message } from "@/redux/message.types";
import { Page } from "@/redux/page.types";
import DashboardSummarizeWidget from "./dashboard-summarize.widget";

interface StateProps{
  _session : Session
  topic : TopicState
}

interface OwnProps{
  isFullWidth : boolean
  currentPage : Page
  currentTopic : Topic
  summarize : boolean
}

type Props = StateProps & OwnProps & WithTranslation

function ReportScreenshotMessagesWidget(props: Props) {
  const { t } = props

  const [question] = useState<Question>(initQuestions())
  const [messages, setMessages] = useState<Message[]>([])

  //Load questions on every change on the dashboard filters
  useEffect(() => {

    async function loadMessages(){
      const dashboardFilters = pageInitDashboardFilters(props.currentPage.options.populations)
      const response:any = await store.dispatch(fetchMessages(dashboardFilters, question.aid, question.fk_topic))
      setMessages(response.error ? [] : response)
    }

    loadMessages()

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.currentPage.options.populations])


  function initQuestions(){
    let questions = props.currentTopic.Questions.length > 0 ? props.currentTopic.Questions : flatMap(props.topic.list, x => x.Questions)
    questions = questions.filter(x => x.type === "open")
    return questions.length > 0 ? questions[0] : new Question()
  }

  return (
    props.summarize
    ?
    <div style={{
        transform : "scale(0.38)",
        transformOrigin : "top left",
        width : props.isFullWidth ? 1675 : 800
      }}>

      <div style={{
          fontSize : 24,
          marginBottom : 24
        }}>
        <b>{t("page_type_screenshot_messages_summarize_help", { question : question.label })}</b>
      </div>

      { messages.length >= MESSAGE_CEIL_COUNT_SUMMARY &&
      <DashboardSummarizeWidget
        comments={messages.map(x => x.text)}
        currentQuestion={question}
      />
      }

    </div>
    :
    <div style={{
      transform : "scale(0.38)",
      transformOrigin : "top left",
      width : props.isFullWidth ? 1675 : 800
     }}>
      
      <div style={{
          fontSize : 24,
          marginBottom : 24
        }}>
        <b>{t("page_type_screenshot_messages_help", { question : question.label })}</b>
      </div>

      { messages.map(message => 
      <div key={message.aid}
        className="medgrey-b"
        style={{
          display : "inline-block",
          padding : "10px 20px",
          margin : 10,
          border : "1px solid",
          borderRadius : 8
        }}>
        {message.text}
      </div>
      )
      }
    
    </div>
  )
  
}

const mapStateToProps = state => ({
  _session: state._session,
  topic : state.topic
})

export default connect(mapStateToProps)(withTranslation()(ReportScreenshotMessagesWidget))

