/** 
 * DASHBOARD-COMPARE.WIDGET
 * Compare two results
 */

import { connect } from "react-redux"
import { DashboardFilters, Filter, FilterState } from "@/redux/filter.types";
import { Topic, TopicState } from "@/redux/topic.types";
import { Survey, SurveyState } from "@/redux/survey.types";
import { withTranslation, WithTranslation } from "react-i18next";
import DashboardResultsWidget from "./dashboard-results.widget";
import Space from "@/components/space";
import { useEffect, useState } from "react";
import Dropdown from "@/components/dropdown";
import { BookmarkState } from "@/redux/bookmark.types";
import DashboardFiltersWidget from "./dashboard-filters.widget";
import { store } from "@/index";
import { filterReplaceDashboardCompare } from "@/redux/filter.actions";
import { surveyFetchOne, surveyStatus } from "@/redux/survey.actions";
import { STATUS_LOADED, STATUS_LOADING, STATUS_LOAD_ERROR } from "@/redux/_status.types";
import PageLoader from "@/components/page-loader";
import fetchArchiveFilters from "@/utils/fetch-archive-filters.utils";
import { Axis, AxisState } from "@/redux/axis.types";
import { compact, uniqBy } from "lodash";
import { attributeCompareGet } from "@/redux/attribute.actions";
import { Attribute, AttributeState } from "@/redux/attribute.types";
import fetchArchiveAttributes from "@/utils/fetch-archive-attributes.utils";

interface StateProps extends WithTranslation{
  attribute : AttributeState
  axis : AxisState
  bookmark : BookmarkState
  survey : SurveyState
  topic : TopicState
  filter : FilterState
}

function DashboardCompareWidget(props:StateProps) {
  const { t } = props

  //State
  //> axis list for the compare
  //> survey for the compare
  //> topics for the compare
  const [currentAxesCompare, setCurrentAxesCompare] = useState<Axis[]>(props.axis.list)
  const [currentFiltersCompare, setCurrentFiltersCompare] = useState<Filter[]>(props.filter.list.map((filter: Filter) => new Filter(filter)))
  const [currentSurveyCompare, setCurrentSurveyCompare] = useState<Survey>(props.survey.active)
  const [currentTopicsCompare, setCurrentTopicsCompare] = useState<Topic[]>(props.topic.list)

  //On load reset filters compare
  useEffect(() => {
    resetDashboardFilters()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  //Classname depend if bookmark are defined
  function getClassName(){
    let className = "flex flex1"

    if (props.bookmark.list.length > 0){
      className += " dashboard-compare-bookmark"
    }

    return className

  }

  //Reset dashboard filters
  function resetDashboardFilters(){
    store.dispatch(filterReplaceDashboardCompare(new DashboardFilters()))
  }

  //Change survey : load new topics and filters
  async function switchSurvey(survey: Survey){

    store.dispatch(surveyStatus(STATUS_LOADING))

    const newSurvey:any = await store.dispatch(surveyFetchOne(survey.id, survey.isSurveyGroup))
    const newFilters: any = await fetchArchiveFilters(new DashboardFilters(), survey.id)
    const attributes: Attribute[] = await fetchArchiveAttributes(new DashboardFilters(), survey.id, null)
    
    if (!newSurvey.error && !newFilters.error){
      resetDashboardFilters()
      setCurrentSurveyCompare(survey)
      setCurrentTopicsCompare(newSurvey.template.Topics.map((x: Topic) => new Topic(x)))
      setCurrentAxesCompare(uniqBy(compact(newSurvey.template.Topics.map((x: Topic) => x.Axis)), "id"))
      setCurrentFiltersCompare(newFilters.map((filter: Filter) => new Filter(filter)))
      store.dispatch(attributeCompareGet(attributes))
      store.dispatch(surveyStatus(STATUS_LOADED))
    }
    else{
      store.dispatch(surveyStatus(STATUS_LOAD_ERROR))
    }

  }

  return (
    <div className={getClassName()}
      style={{ overflow : 'hidden' }}>

      <Space/>

      <div className="dashboard-compare">
        
        <div className="flex">

          <div className="flex1">

            <h3>
              {t("survey")}
            </h3>

            <p>
              {props.survey.active.name}
            </p>

          </div>

          <div className="dashboard-compare-separator medgrey-b"/>
          
          <div className="flex1 dashboard-compare-survey"
            id={currentSurveyCompare.id}>

            <h3>
              {t("survey")}
            </h3>
          
            <Dropdown list={props.survey.list}
              onSelect={(survey) => switchSurvey(survey)}
              displayField="name"
              value={currentSurveyCompare.name}
              active={currentSurveyCompare.id}
            />

          </div>

        </div>

        <div className="flex">

          <div className="flex1">

            <h3>
              {t("filters")}
            </h3>

            <DashboardFiltersWidget currentFilters={props.filter.list}
              currentSurvey={props.survey.active}
            />

            <div className="height-20"/>

          </div>

          <div className="dashboard-compare-separator medgrey-b"/>
          
          <div className="flex1">

            <h3>
              {t("filters")}
            </h3>

            { props.survey.status === STATUS_LOADED &&
            <DashboardFiltersWidget currentFilters={currentFiltersCompare}
              currentSurvey={currentSurveyCompare}
              isCompare
            />
            }

            <div className="height-20"/>
            
          </div>
          
        </div>

        <div className="flex">

          <div className="flex1">
            <DashboardResultsWidget
              exportName={t("dashboard_results")}
              currentAxes={props.axis.list}
              currentSurvey={props.survey.active}
              currentTopics={props.topic.list}
              dashboardFilters={props.filter.dashboard}
              isSortByName
            />
          </div>

          <div className="dashboard-compare-separator medgrey-b"/>

          <div className="flex1">
            <PageLoader status={props.survey.status}>
              <DashboardResultsWidget 
                exportName={t("dashboard_compare")}
                currentAxes={currentAxesCompare}
                currentSurvey={currentSurveyCompare}
                currentTopics={currentTopicsCompare}
                dashboardFilters={props.filter.dashboardCompare}
                isSortByName
              />
            </PageLoader>
          </div>

        </div>

      </div>

      <Space/>

    </div>
  )

}

const mapStateToProps = state => ({
  attribute : state.attribute,
  axis : state.axis,
  bookmark : state.bookmark,
  survey : state.survey,
  topic : state.topic,
  filter : state.filter,
})

export default connect(mapStateToProps)(withTranslation()(DashboardCompareWidget))