import { createAsyncThunk } from "@reduxjs/toolkit"
import { Supervisor } from "./supervisor.types"
import { fetchBackend } from "@/core/apis/backend/backend.api"

// interface ApiError {
//   message: string
//   code?: number
// }

//DISPATCHS A ENCHAINER
//> enchainer des backend call et des slices
//> e

// Thunk to add a new supervisor
export const getSuperviors = createAsyncThunk<Supervisor[], string>(
  "supervisor/supervisorsGet",
  async (search, { rejectWithValue }) => {
    try {
      const data: Supervisor[] = await fetchBackend(
        `/supervisor/supervisors?search=${search}`
      )
      return data
    } catch (error) {
      console.error("Erreur lors de la récupération des superviseurs: ", error)
      return rejectWithValue("Erreur lors de la récupération des superviseurs")
    }
  }
)

export const addSupervisor = createAsyncThunk<Supervisor, Partial<Supervisor>>(
  "supervisor/addSupervisor",
  async (newSupervisor, { rejectWithValue }) => {
    try {
      const data: Supervisor = await fetchBackend("/supervisor/supervisor", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ supervisor: newSupervisor })
      })
      return data
    } catch (error) {
      console.error("Erreur lors de l'ajout du superviseur: ", error)
      return rejectWithValue("Erreur lors de l'ajout du superviseur")
    }
  }
)
