/**
 * AXIS.REDUCERS
 */
import { STATUS_LOADED } from './_status.types';
import { v4 as uuid } from "uuid";
import { Axis, AxisState, AXIS_ACTIVATE, AXIS_ADD, AXIS_COPY, AXIS_EDIT, AXIS_EDIT_NAME, AXIS_GET, AXIS_INIT, AXIS_OPEN, AXIS_REMOVE, AXIS_STATUS, AXIS_SWAP, CONFIG_AXIS_COLORS } from './axis.types';
import { shuffle, sortBy } from 'lodash';

let currentIndex:number
let active:Axis = new Axis()
let colors:string[]
let list:Axis[]

const initialState: AxisState = {
  active : new Axis(),
  list : [],
  status : STATUS_LOADED
}

export default function axisReducer(
  state = initialState,
  action: any
): AxisState {
  switch (action.type) {

    //Set active axis
    case AXIS_ACTIVATE:
      return Object.assign({}, state, {
        active : new Axis(action.payload.axis)
      })

    //Add new customized axis
    //Find color not already used
    case AXIS_ADD:
      active = new Axis()
      active.id = uuid()
      active.name[action.payload.language] = ""
      colors = CONFIG_AXIS_COLORS.filter(color => state.list.map(x => x.color).indexOf(color) === -1)
      active.color = colors.length ? shuffle(colors)[0] : shuffle(CONFIG_AXIS_COLORS)[0]
      return Object.assign({}, state, {
        active
      })

    //Copy active axis to list
    case AXIS_COPY:
      return Object.assign({}, state, {
        list : state.list.concat([state.active])
      })

    //Edit axis
    case AXIS_EDIT:
      active = Object.assign(state.active, { [action.payload.key] : action.payload.value })
      return Object.assign({}, state, {
        active,
        list : state.list.map(x => x.id === active.id ? active : x)
      })

    //Edit axis name (with language param)
    case AXIS_EDIT_NAME:
      active = new Axis(state.active)
      active.name[action.payload.language] = action.payload.value
      return Object.assign({}, state, { active })

    //Save axis list in the store
    //Set first axis as open
    case AXIS_GET:
      list = sortBy(action.payload.axes.map((x) => new Axis(x)), axis => { return action.payload.axesOrder.indexOf(axis.id) })
      return {
        ...state,
        active : new Axis(),
        list
      }

    //Init store
    case AXIS_INIT:
      return Object.assign({}, state, {
        active : new Axis(state.active),
        list : state.list.map((x:any) => new Axis(x)),
        status : initialState.status
      })

    //Open axis
    case AXIS_OPEN:
      active = new Axis(state.list.find(x => x.id === action.payload.id))
      active = Object.assign(active, { open : !active.open })
      return Object.assign({}, state, {
        list : state.list.map(x => x.id === active.id ? active : x)
      })
      
    //Remove an axis
    case AXIS_REMOVE:
      return {
        ...state,
        list  : state.list.filter(i => { return i.id !== action.payload.id })
      }

    //Update status
    case AXIS_STATUS:
      return {
        ...state,
        status : action.payload.status
      }

    //Change order of axis
    case AXIS_SWAP:
      active = new Axis(state.list.find(x => x.id === action.payload.sourceId))
      currentIndex = state.list.findIndex(x => x.id === action.payload.destinationId)
      list = state.list.filter(x => x.id !== active.id)
      list = list.slice(0, currentIndex).concat([active]).concat(list.slice(currentIndex))
      return Object.assign({}, state, { 
        list
      })

    default:
      return state

  }
  
}