/** 
 * CONTACT.THOMAS
 * Infos to contact Thomas best boy !
 */

import Contact from "@/components/contact"
import Modal from "@/components/modal"
import { withTranslation, WithTranslation } from "react-i18next"

interface OwnProps extends WithTranslation{
  onClose : Function
}

function ContactModal(props:OwnProps) {
  const { t } = props
  
  return (

    <Modal onClose={() => props.onClose()}
      isCloseButtonVisible
      title={t("onboarding_end_questions")}>

      <Contact/>

    </Modal>

  )

}

export default withTranslation()(ContactModal)