/** 
 * UNPAID-BILLS
 * Modal showing account's unpaid bills with details
 */

import Modal from "@/components/modal";
import Space from "@/components/space";
import { WithTranslation, withTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import ReactMarkdown from "react-markdown";
import { sum } from "lodash";
import { Subscription } from "@/redux/subscription.types";
import Contact from "@/components/contact";
import BillingsWidget from "@/widgets/billings.widget";

interface OwnProps{
  unpaidBills: Subscription[]
  onClose?: Function
}

type Props = OwnProps & WithTranslation

function UnpaidBillsModal(props: Props){
  const { t } = props

  //Show unpaid purchases in modal
  const [showUnpaid, setShowUnpaid] = useState<boolean>(false)
  const [unpaidBills, setUnpaidBills] = useState<Subscription[]>(props.unpaidBills)

  useEffect(() => {
    setUnpaidBills(props.unpaidBills)
  }, [
    props.unpaidBills
  ])

  function close(){
    if(props.onClose){
      props.onClose()
    }
  }

  return(
    <Modal
      isCloseButtonVisible
      title={t("unpaid_bills")}
      onClose={close}
    >
      <div className="flex">
        <div className="flex1">
          <ReactMarkdown>
            {t("unpaid_bills_details",  {amount : sum(unpaidBills.map((x: Subscription)=>x.price)) })}
          </ReactMarkdown>
        </div>

        <div style={{
          margin:"0 20px",
          border:"1px solid",
          height:100
        }}/>

        <Contact/>
      </div>

      <div className="height-40"/>

      <div className="flex">

        <h3>
          {t("unpaid_bills_list")}
        </h3>

        <Space/>

        <div className="flex flex-dcol">
          <Space/>
          <u className="_hover grey-t"
            onClick={()=>setShowUnpaid(!showUnpaid)}>
            {t(showUnpaid ? "utils_hide" : "utils_show")}
          </u>
          <Space/>
        </div>

      </div>

      { showUnpaid ?
      <BillingsWidget
        billings={unpaidBills}
      />
      :
      <div className="height-20"/>
      }

      <div className="height-20"/>
      
    </Modal>
  )
}

const mapStateToProps = state => ({
  survey : state.survey
})

export default connect(mapStateToProps)(withTranslation()(UnpaidBillsModal))
