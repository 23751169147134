/** PACK.REDUCERS
 * Modifier le state pour les packs d'invitations
 */

import { Pack, PackState, PACK_ADD, PACK_EDIT, PACK_GET, PACK_INIT, PACK_REMOVE } from "./pack.types";

let active:Pack

const initialState: PackState = {
  list : []
}

export default function packReducer(
  state = initialState,
  action: any
): PackState {
  switch (action.type) {

    //Add new pack
    case PACK_ADD:
      active = action.payload.pack
      return Object.assign({}, state, { 
        list : [active].concat(state.list)
      })

    //Edit pack
    case PACK_EDIT:
      active = new Pack(state.list.find(x => x.id === action.payload.id))
      active[action.payload.key] = action.payload.value
      return Object.assign({}, state, { 
        list : state.list.map(x => x.id === action.payload.id ? active : x) 
      })

    //Save list of pack in the store
    case PACK_GET:
      return Object.assign({}, state, {
        list : action.payload.packs.map((x:any) => new Pack(x))
      })

    //Init store
    case PACK_INIT:
      return Object.assign({}, state, {
        list : state.list.map((x:any) => new Pack(x))
      })

    //Remove pack
    case PACK_REMOVE:
      return Object.assign({}, state, { 
        list : state.list.filter(x => x.id !== action.payload.id)
      })

    default:
      return state

  }

}