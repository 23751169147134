/** 
 * DASHBOARD-NPS-TRENDS
 * NPS score for the survey
 */

import Space from "@/components/space"
import { orderBy } from "lodash"
import { withTranslation, WithTranslation } from "react-i18next"
 
interface OwnProps{
  ceilBad: number
  ceilGood: number
}

type Props = OwnProps & WithTranslation

const enps_notes: any = [
  {id: 2, name: "sector_manufacturing", score: 13},
  {id: 3, name: "sector_it", score: 26},
  {id: 4, name: "sector_healthcare", score: -6.5}
]

function DashboardNpsTrendsWidget(props: Props) {
  const { t } = props

  function getColor(score: number){
    if (score >= props.ceilGood){
      return "#20CA7E"
    }else if (score >= props.ceilBad){
      return "#f2d600"
    }else{
      return "#eb5a46"
    }
  }

  return (
    <div className="grey-t">

      <p style={{textAlign: "center"}}>
        <b>
          {t("average_enps_sector")}
        </b>
      </p>

      <div className="height-20" />

      <div className="flex flex-dcol">
        { orderBy(enps_notes, ["score"], ["desc"]).map((x: any) =>
        <div key={x.id} className="flex flex1" style={{margin: "3px 0"}}>

          <div className="flex flex-dcol">
            <Space />
            <div style={{textAlign: "center"}}>
              {t(x.name).replaceAll("_", " ")}
            </div>
            <Space />
          </div>

          <Space />

          <div style={{
            color: getColor(x.score),
            fontSize: 18
          }}>
            {x.score}
          </div>
        </div>
        )}

      </div>

      <div className="height-20" />

      <p>
        {t("nps_help")}
      </p>

    </div>
  )

}


export default withTranslation()(DashboardNpsTrendsWidget)