/** 
 * ACTION-MENU
 * Context-menu with list of choices
 */
import { faChevronLeft, faChevronRight, IconDefinition } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import ContextMenu from "./context-menu";
import { toast } from "react-toastify"
import ListItem from "./list-item";
import { WithTranslation, withTranslation } from "react-i18next";
import { useState } from "react";
import Space from "./space";

interface ActionState{
  icon : IconDefinition
  id : string
  isAdvanced? : boolean
  isInactive? : boolean
  label : string,
}

interface OwnProps extends WithTranslation{
  actions : ActionState[] //Actions possibles (menu contextuel)
  positionBottom? : boolean
  positionRight? : boolean
  onSelect : Function //Clic sur bouton d'action
  onClose : Function //Action au clic
}

function ActionsMenu(props:OwnProps) {
  const { t } = props

  const [showAdvanced, setShowAdvanced] = useState(false)
  const [actions, setActions] = useState(props.actions?.filter(x => !x.isAdvanced))

  function select(e, action){
    e.stopPropagation()
    if (!action.isInactive){
      props.onSelect(action.id)
      close()
    }else{
      toast(t("action_unauthorized"), { type : 'error' })
    }
  }

  function close(){
    props.onClose()
  }

  //Show advanced options
  function toogleAdvanced(e){
    e.stopPropagation()

    if (showAdvanced){
      setActions(props.actions?.filter(x => !x.isAdvanced))
    }else{
      setActions(props.actions?.filter(x => x.isAdvanced))
    }

    setShowAdvanced(!showAdvanced)

  }
  
  return (
    <ContextMenu onClose={close}
      positionBottom={props.positionBottom}
      positionRight={props.positionRight}>
       
      { actions.map(action => 
      <ListItem key={action.id}
        isEditable 
        isSmall
        onClick={(e) => select(e, action)}>

        <span className={action.isInactive ? "grey-t" : ""}>
          <FontAwesomeIcon icon={action.icon} style={{ marginRight : '8px', width : '18px' }}/>
          {action.label}
        </span>

      </ListItem>
      )
      }

      { /** button show more or less */
      props.actions?.filter(x => x.isAdvanced).length > 1 && 
      <div>
        { showAdvanced
        ?
        <ListItem  isEditable
          onClick={toogleAdvanced}>
          {t("utils_back")}
          <Space/>
          <FontAwesomeIcon icon={faChevronLeft} style={{ margin : 'auto -12px auto 0px' }}/>
        </ListItem>
        :
        <ListItem  isEditable
          onClick={toogleAdvanced}>
          {t("utils_show_more_options")}
          <Space/>
          <FontAwesomeIcon icon={faChevronRight} style={{ margin : 'auto -12px auto 0px' }}/>
        </ListItem>
        }
      </div>
      }

    </ContextMenu>
  )

}

export default withTranslation()(ActionsMenu)