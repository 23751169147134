/**
 * ONBOARDING-STEP.WIDGET
 * Display tutorial for a frame
 */

import { faArrowLeft, faArrowRight, faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Button from "@/components/button"
import Card from "@/components/card"
import Space from "@/components/space"
import { withTranslation, WithTranslation } from "react-i18next"
import { NavigateFunction, useNavigate } from "react-router-dom"
import i18n from "@/translate/i18n"
import { FadeIn } from "@/utils/animations.utils"

interface OwnProps{
  height : number
  width : number
  arrowLeft : number
  arrowRotate : number
  arrowScaleX : number
  arrowTop : number
  cardLeft : number
  cardTop : number
  ctaText : string
  image : string,
  onBack : Function
  onNext : Function
  children : React.ReactNode
}

type Props = OwnProps & WithTranslation

//Widget
function OnboardingStepWidget(props:Props) {
  const { t } = props
  const navigate: NavigateFunction = useNavigate()

  function back(e){
    e.stopPropagation()
    props.onBack()
  }

  function skip(e){
    e.stopPropagation()
    navigate("/onboarding/signup")
  }

  return (
    <div className="flex flex1"
      onClick={() => props.onNext()}>
      <div className="onboarding-step"
        style={{
          height : props.height + "px",
          width : props.width + "px"
        }}>

        <div className="onboarding-step-close">
          <Button icon={faTimes}
            onClick={(e) => skip(e)}
            className="light">
            {t("onboarding_start_skip")}
          </Button>
        </div>

        <FadeIn className="abs onboarding-step-card"
          style={{
            left : props.cardLeft + "px",
            top : props.cardTop + "px"
          }}>

          <img alt="arrow"
            src={require("@/assets/onboarding/arrow.png")}
            style={{
              left : props.arrowLeft + "px",
              top : props.arrowTop + "px",
              transform : "rotate(" + props.arrowRotate + "deg) scaleX(" + props.arrowScaleX + ")"
            }}
          />

          <Card>
            <div className="onboarding-step-card-body">
              {props.children}
            </div>
            <div className="height-20"/>
            <div className="flex">
              <div className="onboarding-step-back">
                <Button className="light"
                  onClick={(e) => back(e)}
                  icon={faArrowLeft}>
                  {t("utils_back")}
                </Button>
              </div>
              <Space/>
              <Button className="primary">
                {props.ctaText}
                <FontAwesomeIcon icon={faArrowRight}
                  className="onboarding-step-arrow"
                />
              </Button>
            </div>
          </Card>

        </FadeIn>

        <img src={require("@/assets/onboarding/" + i18n.language + "-" + props.image + ".png")} alt={props.image}/>

      </div>
    </div>
  )
  
}

export default withTranslation()(OnboardingStepWidget)