/**
 * GET-AXIS-FROM-TOPIC
 * Format list of axis from list of topics
 */

import { mean } from "lodash"
import { Axis } from "@/redux/axis.types";
import { Topic } from "@/redux/topic.types";

const formatAxisResults = (axis:Axis, newTopics:Topic[], axes:any, accountId:string, isRandomData:boolean) => {
  
  if (axes.error){
    return new Axis()
  }else{

    //Magic backend IHS
    if (["ae0289c8-0bd8-48f4-9079-593d0491589a", "6fec5604-588c-463f-8800-e252de6e647c"].indexOf(accountId) > -1 || isRandomData){
      axis.note = mean(newTopics.filter(x => !x.hide && x.AxisId === axis.id && x.withScore).map(x => x.note)) 
    }
    //Get results from axes fetch
    else{
      const topicsAids = newTopics.filter(x => x.AxisId === axis.id).map(x => x.aid)
      const topicAxis = axes.find(x => topicsAids.indexOf(x.aid) > -1)
      axis.note = topicAxis ? topicAxis.note : null
    }
  
    return axis
    
  }

}

export default formatAxisResults
