/**
 * SURVEY.REDUCERS
 */
import { Survey, SurveyState, SURVEY_ACTIVATE, SURVEY_EDIT, SURVEY_GET, SURVEY_INIT, SURVEY_REMOVE, SURVEY_STATUS, SURVEY_EDIT_ONE } from "./survey.types";
import { STATUS_LOADED } from "./_status.types";

let active:Survey
let list:Survey[] 

const initialState: SurveyState = {
  active : new Survey(),
  list : [],
  status : STATUS_LOADED
}

export default function surveyReducer(
  state = initialState,
  action: any
): SurveyState {
  switch (action.type) {

    //Select current survey
    case SURVEY_ACTIVATE:
      return Object.assign({}, state, {
        active : new Survey(action.payload.survey)
      })
      
    case SURVEY_EDIT:
      active = Object.assign(state.active, { [action.payload.key] : action.payload.value })
      return Object.assign({}, state, { active })

    case SURVEY_EDIT_ONE:
      list = state.list.map(x => x.id === action.payload.surveyId ? Object.assign(x, { [action.payload.key] : action.payload.value }) : x)
      return Object.assign({}, state, { list })

    //Save surveys in the store
    //Match survey with surveys in archive database in order to get participation data
    case SURVEY_GET:
      list = action.payload.surveys.map((survey: any) => {
        const matchingSurvey: any = action.payload.surveysArchive.find((x: any) => x.id === survey.id)
        if(matchingSurvey){
          return new Survey({
            ...survey,
            participationDone: matchingSurvey.participationDone,
            participationSend: matchingSurvey.participationSend,
            participationStarted: matchingSurvey.participationStarted,
            participationUndone: matchingSurvey.participationUndone,
          })
        }
        else{
          return new Survey(survey)
        }
      })

      //Hide surveys without participants
      //> for observers that are not concerned
      if (action.payload.hideEmpty){
        list = list.filter(x => x.participationSend > 0)
      }
      
      return {
        ...state,
        active : list.length > 0 ? list[0] : new Survey(),
        list
      }
    
    case SURVEY_INIT:
      return Object.assign({}, state, {
        active : new Survey(state.active),
        list : state.list.map((x:any) => new Survey(x)),
        status : initialState.status
      })

    case SURVEY_REMOVE:
      return Object.assign({}, state, { 
        list : state.list.filter(x => x.id !== action.payload.id)
      })

    case SURVEY_STATUS:
      return {
        ...state,
        status : action.payload.status
      }

    default:
      return state

  }
  
}