/** 
 * QUESTION-TYPE.HELP
 * Info about type of question
 */
import HelpModal from "@/components/modal-help"
import { withTranslation, WithTranslation } from "react-i18next"

interface OwnProps extends WithTranslation{
  onClose : Function
}

function QuestionTypeHelp(props:OwnProps) {
  const { t } = props

  return (
    <HelpModal onClose={props.onClose}
      title={t("question_type")}>

      <h2>{t("question_type_scale")}</h2>
      <p>{t("question_type_scale_help")}</p>
      <p><i>{t("question_type_scale_help_sample")}</i></p>

      <h2>{t("question_type_choice")}</h2>
      <p>{t("question_type_choice_help")}</p>
      <p><i>{t("question_type_choice_help_sample")}</i></p>

      <h2>{t("question_type_open")}</h2>
      <p>{t("question_type_open_help")}</p>

    </HelpModal>
  )
}

export default withTranslation()(QuestionTypeHelp)