/** 
 * SEARCH-BAR
 * Search bar with loader
 */
import TextInput from "./text-input"
import { useCallback, useState } from "react"
import { withTranslation, WithTranslation } from "react-i18next"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Button from "./button"
import { debounce } from 'lodash';
import { faCircleNotch, faTimes } from "@fortawesome/free-solid-svg-icons"
import { STATUS_SEARCHING } from "@/redux/_status.types"

interface OwnProps extends WithTranslation{
  status : string
  onSearch : Function
  onSearchStart? : Function
}

function SearchBar(props:OwnProps) {
  const { t } = props

  //Text to enter
  const [searchText, setSearchText] = useState("")

  //Set up 500ms delay after search to trigger search
  const delayedSearch = useCallback(debounce(search, 500), []) // eslint-disable-line react-hooks/exhaustive-deps

  //Cancel search
  function cancelSearch(){
    const search = ""
    setSearchText(search)
    props.onSearch(search)
  }

  //Trigger search function
  function search(value){
    props.onSearch(value)
  }

  //Start the search
  function startSearch(e){

    const search = e.value
    setSearchText(search)
    delayedSearch(search)
    
    if (props.onSearchStart){
      props.onSearchStart()
    }

  }
  
  return (
    <div className="flex search-bar">

      <TextInput onChange={startSearch}
        autoFocus
        placeholder={t("Rechercher")}
        value={searchText}
      />

      { /** show loader */
      props.status === STATUS_SEARCHING &&
      <Button onClick={cancelSearch}>
        <FontAwesomeIcon icon={faCircleNotch} spin/>
      </Button>
      }

      { /** if loading is done show cancel button */
      (
        searchText &&
        props.status !== STATUS_SEARCHING
      )&&
      <Button onClick={cancelSearch}>
        <FontAwesomeIcon icon={faTimes}/>
      </Button>
      }

    </div>
  )

}

export default withTranslation()(SearchBar)