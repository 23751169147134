/**
 * DASHBOARD-PARTICIPATION
 * Participation stats for a given survey
 */

import NavDashboard from "@/nav/nav.dashboard";
import DashboardParticipationWidget from "@/widgets/dashboard-participation.widget";


function DashboardParticipationRoute(){
  return(
    <NavDashboard title={"survey_participation"}
      hideOptions>
      <DashboardParticipationWidget/>
    </NavDashboard>
  )
}


export default DashboardParticipationRoute