/** 
 * NUMIND.ACTIONS
 * Predictions
 */

export const predict = (text: string) => ({
  type : "NUMIND",
  payload : {
    method : "GET",
    url : "/predict",
    data : {
      text
    }
  }
})

export const predictionSave = (expectedValue: string, probabilities: any, text: string, ceil: number) => ({
  type : "API",
  payload : {
    method : "POST",
    url : "/predict",
    data : {
      expectedValue,
      probabilities,
      text,
      ceil
    }
  }
})