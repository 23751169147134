/**
 * DASHBOARD-HISTORY.WIDGET
 * Compare surveys in the time
 */

import { IconDefinition, faArrowDown, faArrowUp, faChartBar, faCircleNotch, faMinus, faPlus, faTh } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "@/components/button";
import Chip from "@/components/chip";
import Dropdown from "@/components/dropdown";
import Link from "@/components/link";
import ListButton from "@/components/list-button";
import ListItem from "@/components/list-item";
import Modal from "@/components/modal";
import PageLoader from "@/components/page-loader";
import PagePlaceholder from "@/components/page-placeholder";
import Scroller from "@/components/scroller";
import Space from "@/components/space";
import { store } from "@/index";
import { sortBy } from "lodash";
import LoadingModal from "@/modals/loading.modal";
import { useEffect, useState } from "react";
import { withTranslation, WithTranslation } from "react-i18next"
import { connect } from "react-redux"
import { fetchHistory } from "@/redux/_archive.actions";
import { Session } from "@/redux/_session.types";
import { STATUS_LOADED, STATUS_LOADING, STATUS_SAVED, STATUS_SAVING } from "@/redux/_status.types";
import { Axis } from "@/redux/axis.types";
import { FilterState } from "@/redux/filter.types";
import { surveyEditOne, surveyStatus, surveyUpdateProject } from "@/redux/survey.actions";
import { Survey, SurveyState } from "@/redux/survey.types";
import { Topic, TopicState } from "@/redux/topic.types";

interface StateProps extends WithTranslation{
  _session : Session
  filter : FilterState
  survey : SurveyState
  topic : TopicState
}

type DisplayMode = "chart" | "evolution"

interface DisplayModeIcon{
  id : DisplayMode,
  value : IconDefinition
}

type Props = StateProps

const MODAL_LOADING = "MODAL_LOADING"
const MODAL_SURVEY_ADD = "MODAL_SURVEY_ADD"

function DashboardHistoryWidget(props: Props){
  const { t } = props

  //Display the button to edit the columns (only for the admins and if the survey is related to a project)
  const [isEditButtonVisible] = useState(props._session.userRole === "ADMIN" && props.survey.active.ProjectId)

  //Status loading
  const [isLoading, setIsLoading] = useState(true)

  //Results are acecssibles (many surveys configured)
  const [isVisible, setIsVisible] = useState(false)

  //Modal to be displayed
  const [currentModal, setCurrentModal] = useState<null | string>(null)

  //List of column (surveys to be compared)
  const [columns, setColumns] = useState<Survey[]>([])

  //Current display mode
  const [displayMode, setDisplayMode] = useState<DisplayMode>("evolution")

  //List of modes
  const [displayModeIcons] = useState<DisplayModeIcon[]>([
    { id : "evolution", value : faChartBar },
    { id : "chart", value : faTh }
  ])

  //List of rows for the results
  const [rows, setRows] = useState<any[]>([])

  //Type of results returned (questions, topics, axis or company)
  const [resultsType, setResultsType] = useState("company")

  //List of results types
  const [resultsTypeList] = useState([
    { name : t("user_company_alt"), id : "company" },
    { name : t("axes"), id : "axes" },
    { name : t("topics"), id : "topics" },
    { name : t("questions"), id : "questions" }
  ])

  //Load data
  useEffect(() => {
    loadHistory()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.filter.dashboard, resultsType])

  async function editSurvey(surveyId:string, add:boolean){
    const projectId = add ? props.survey.active.ProjectId : null
    store.dispatch(surveyEditOne(surveyId, "ProjectId", projectId))
    store.dispatch(surveyStatus(STATUS_SAVING))
    await store.dispatch(surveyUpdateProject(surveyId, projectId))
    store.dispatch(surveyStatus(STATUS_SAVED))
  }

  async function loadHistory(){
    if (props.survey.active.ProjectId){

      setIsLoading(true)
      store.dispatch(surveyStatus(STATUS_LOADED))

      //Get surveys associated for the project
      const surveys = sortBy(props.survey.list.filter(x => x.ProjectId === props.survey.active.ProjectId), "dateStart")
      setColumns(surveys)

      //Load data if there is other surveys
      if (surveys.length > 1){
        setIsVisible(true)
        const response:any = await store.dispatch(fetchHistory(props.filter.dashboard, props._session.language, props.survey.active.ProjectId, resultsType, props._session.dashboardDisplaySettings.dashboardDisplayMode === "satisfaction"))
        if (!response.error){
          formatData(response.rows, surveys)
        }
      }else{
        setIsVisible(false)
      }

      setIsLoading(false)

    }else{
      setIsLoading(false)
    }
  }

  async function closeAddSurveyModal(){
    setCurrentModal(null)
    loadHistory()
  }

  function getColumnWidth(){
    return displayMode === "evolution" ? 64 : 110
  }

  function updateRows(rows, response, surveys:Survey[], axis:Axis | null, topic:Topic | null, label:string){
    const row = response.find(x => x.name === label)
    if (row){
      const values:any[] = []
      for (let i = 0; i < surveys.length; i++) {
        const survey = surveys[i]
        const note = row[survey.id] ? Math.round(row[survey.id]) / 10 : null
        
        let evolution:number | null = null
        let evolutionColor:string | null = null
        let noteHeight:number | null = null
        let evolutionHeight:number | null = null

        if (note && values.length > 0){
          const previousValue = values[values.length - 1]
          if (previousValue.note){
            evolution = note - previousValue.note
            evolutionHeight = Math.abs(evolution * 10)
  
            if (evolution >= 1){
              evolutionColor = "#51e898"
            }else if (evolution >= 0.5){
              evolutionColor = "#99efc2"
            }else if (evolution <= -1){
              evolutionColor = "#f05146"
            }else if (evolution <= -0.5){
              evolutionColor = "#f1a8a3"
            }
  
          }

        }

        //Max 9, min 4 for the height bar
        let noteLabel = ""
        let evolutionLabel = ""
        if (note){

          noteHeight = ((note - 4) * 10)
          if (noteHeight < 0) noteHeight = 0

          //Display score as satisfaction
          if (props._session.dashboardDisplaySettings.dashboardDisplayMode === "satisfaction"){
            noteLabel = (note * 10).toFixed(0)
            evolutionLabel = evolution ? (evolution * 10).toFixed(1) : ""
          }else{
            noteLabel = note.toFixed(1)
            evolutionLabel = evolution ? evolution.toFixed(1) : ""
          }

        }

  
        values.push({
          note,
          noteLabel : noteLabel,
          noteHeight : noteHeight,
          evolution,
          evolutionLabel : evolutionLabel,
          evolutionHeight,
          evolutionColor
        })
  
      }

      if (label === "company"){
        label = props._session.accountName
      }
  
      return rows.concat([{
        label,
        Axis : axis,
        Topic : topic,
        values
      }])
  
    }else{
      return rows
    }

  }

  function formatData(response, surveys:Survey[]){
    let dataRows:any = []

    if (resultsType === "company"){
      dataRows = updateRows(dataRows, response, surveys, null, null, "company")
    }else{

      props.topic.list.forEach(topic => {

        if (resultsType === "axes"){
          if (topic.Axis){
            if (!dataRows.find(x => x.label === topic.Axis?.label)){
              dataRows = updateRows(dataRows, response, surveys, null, null, topic.Axis?.label)
            }
          }
        }

        if (resultsType === "topics"){
          dataRows = updateRows(dataRows, response, surveys, topic.Axis, null, topic.label)
        }

        if (resultsType === "questions"){
          topic.Questions.filter(x => x.type === "scale").forEach(question => {
            dataRows = updateRows(dataRows, response, surveys, topic.Axis, topic, question.label)
          })
        }
        
      })

    }

    setRows(dataRows)

  }

  return(
    <PageLoader status={isLoading ? STATUS_LOADING : STATUS_LOADED}>

      { currentModal === MODAL_LOADING &&
      <LoadingModal/>
      }

      { currentModal === MODAL_SURVEY_ADD &&
      <Modal disableClickOutside
        title={t("dashboard_history_empty_cta")}
        isLarge
        status={props.survey.status}>
        <Scroller isModalScroller
          maxHeight={300}>

          { props.survey.list.filter(x => x.id !== props.survey.active.id).map(survey =>
          <ListItem key={survey.id}>
            <div>
              <div>
                {survey.name}
              </div>
              <div className="grey-t">
                {survey.dateStartLabel}
              </div>
            </div>
            <Space/>

            { props.survey.active.ProjectId === survey.ProjectId
            ?
            <ListButton icon={faMinus}
              isPrimary
              isFat
              text={t("dashboard_history_survey_remove")}
              onClick={() => editSurvey(survey.id, false)}
            />
            :
            <ListButton icon={faPlus}
              text={t("dashboard_history_survey_add")}
              onClick={() => editSurvey(survey.id, true)}
            />
            }
          </ListItem>
          )
          }

        </Scroller>

        <div className="height-20"/>

        <div className="flex">
          <Space/>
          { props.survey.status === STATUS_SAVING
          ?
          <Button className="light">
            <FontAwesomeIcon spin icon={faCircleNotch}/>
          </Button>
          :
          <Button isWithBorder
            className="secondary"
            onClick={() => closeAddSurveyModal()}>
            {t("utils_confirm")}
          </Button>
          }

        </div>

      </Modal>
      }

      { isVisible
      ?
      <div className="card-exportable"
        style={{ backgroundColor : "white" }}
        id={t("dashboard_history")}>

        <div className="flex heatmap-header lightwhite rel "
          style={{ display : "flex" }}>

          { isEditButtonVisible &&
          <div className="abs"
            style={{ right : 0 }}>
            <Link onClick={() => setCurrentModal(MODAL_SURVEY_ADD)}>
              {t("dashboard_history_empty_cta")}
            </Link>
          </div>
          }

          <div className="flex flex-dcol"
            style={{
              width : 336,
              paddingLeft : 32,
              paddingBottom : 16
            }}>

            <Space/>

            <div className="flex">
              { displayModeIcons.map((icon, i) =>
              <div key={i}
                style={{ paddingTop : 18 }}>
                <ListButton icon={icon.value}
                  isPrimary={icon.id === displayMode}
                  isColorActive={icon.id === displayMode}
                  onClick={() => setDisplayMode(icon.id)}
                />
              </div>
              )
              }
            </div>

            <div className="height-20"/>

            <Dropdown displayField="name"
              onSelect={(item) => setResultsType(item.id)}
              value={resultsTypeList.find(x => x.id === resultsType)?.name}
              list={resultsTypeList}
            />

          </div>

          { columns.map(column => 
          <div key={column.id} className="heatmap-column heatmap-cell"
            style={{ width : getColumnWidth() }}>

            <div className="heatmap-column-text flex">
              { new Date(column.dateStart).toLocaleDateString(props._session.language, { year : "numeric", month : "long" }) }
            </div>

          </div>
          )
          }

        </div>

        { rows.map((row, i) =>
        <ListItem key={i}>

          <div style={{ width : 326 }}>

            { row.Axis &&
            <div style={{
                color : row.Axis.color,
                fontSize : 12 
              }}>
              <b>{t("axis")}</b> : {row.Axis.label}
            </div>
            }

            { row.Topic &&
            <div style={{ 
                fontSize : 12, 
                marginBottom : 6 
              }}
              className="grey-t">
              <b>{t("topic")}</b> : {row.Topic.label}
            </div>
            }

            <div>
              {row.label}
            </div>

          </div>

          <div className="width-20"/>

          { row.values.map((value, j) =>
          displayMode === "evolution"
          ?
          <div key={j} 
            className="flex flex-dcol medgrey-b"
            style={{
              width : getColumnWidth(),
              marginBottom : -18,
              marginTop : -18,
              borderBottom : "1px solid"
            }}>
            <Space/>

            <div className="height-20"/>

            { value.note &&
            <div className="rel"
              style={{ 
                backgroundColor : "#e0e0e0",
                height : value.noteHeight,
                width : 30
              }}>

              <div className="grey-t abs"
                style={{
                  textAlign : "center",
                  bottom : -28,
                  zIndex : 1,
                  width : "100%"
                }}>
                {value.noteLabel}
              </div>

              { value.evolution !== 0 &&
              <div className="rel">

                <div className="abs"
                  style={{
                    width : "100%",
                    height : value.evolutionHeight,
                    backgroundColor : value.evolution > 0 ? "#51e898" : "#f1a8a3",
                    top : value.evolution < 0 ? -value.evolutionHeight : undefined
                  }}>
                </div>

                { value.evolutionColor &&
                <div className="abs"
                  style={{
                    color : value.evolutionColor,
                    left: 32,
                    fontSize : 12,
                    width: 60,
                    fontWeight : "bold"
                  }}>

                    {value.evolution > 0 &&
                    <span>
                      +
                    </span>
                    }

                    {value.evolutionLabel}

                </div>
                }

              </div>
              }

            </div>
            }

            { value.note &&
            <div style={{
                width : 30,
                height : 30,
                backgroundColor : "#e0e0e0"
              }}>
            </div>
            }

          </div>
          :
          <div key={j}
            className="flex"
            style={{ width : getColumnWidth() }}>

            <span className="grey-t"
              style={{ marginRight : 8 }}>
              {value.noteLabel}
            </span>

            { value.evolutionColor &&
            <Chip icon={value.evolution > 0 ? faArrowUp : faArrowDown}
              color={value.evolutionColor}>

              {value.evolution > 0 &&
              <span>
                +
              </span>
              }

              {value.evolutionLabel}

            </Chip>
            }

          </div>
          )
          }
        </ListItem>
        )
        }

      </div>
      :
      <PagePlaceholder title={t("dashboard_history_empty")}
        image="sherlock"
        onClick={isEditButtonVisible ? () => setCurrentModal(MODAL_SURVEY_ADD) : undefined}
        button={t("dashboard_history_empty_cta")}
      />
      }

    </PageLoader>
  )
}

const mapStateToProps = state => ({
  _session : state._session,
  filter : state.filter,
  survey : state.survey,
  topic : state.topic
})

export default connect(mapStateToProps)(withTranslation()(DashboardHistoryWidget))