/**
 * DASHBOARD-COMPARE
 * Compare two results
 */

import NavDashboard from "@/nav/nav.dashboard";
import DashboardCompareWidget from "@/widgets/dashboard-compare.widget";
import { WithTranslation, withTranslation } from "react-i18next"
import { useState } from "react";
import Space from "@/components/space";
import { connect } from "react-redux";
import { Session } from "@/redux/_session.types";
import UpgradeWidget from "@/widgets/upgrade.widget";
import AccountOptionsHelp from "@/help/account-options.help";
import DashboardComparePopulations2Widget from "@/widgets/dashboard-compare-populations2.widget";
import Tabs from "@/components/tabs";
import PagePlaceholder from "@/components/page-placeholder";

interface StateProps extends WithTranslation{
  _session: Session
}

type Props = StateProps

type ComparatorTab = "TAB_COMPARATOR" | "TAB_QUESTIONS" | "TAB_POPULATIONS"

const MODAL_HELP_SETTINGS: string = "MODAL_HELP_SETTINGS"

function DashboardCompareRoute(props: Props){
  const { t } = props

  const [currentModal, setCurrentModal] = useState<string | null>(null)
  const [currentTab, setCurrentTab] = useState<ComparatorTab>("TAB_COMPARATOR")

  function changeTab(tab: ComparatorTab){
    setCurrentTab(tab)
  }

  function getWidget(){
    switch(currentTab){

      case "TAB_COMPARATOR":
        return <DashboardCompareWidget/>

      case "TAB_POPULATIONS":
        return <DashboardComparePopulations2Widget />

      default :
        return <div>{t("widget not found")}</div>

    }
  }

  return(
    <NavDashboard title="dashboard_compare"
      hideOptions
      hideFilters
      helpPage={16}>

      { /**
       * edit settings
       * change ceil to display red values
       */
      currentModal === MODAL_HELP_SETTINGS &&
        <AccountOptionsHelp onClose={()=>setCurrentModal(null)}/>
      }

      { props._session.accountLevel === "free"
      ?
      <UpgradeWidget feature="comparator" isAppLevel/>
      :      
      <div className="flex" style={{width:"100%"}}>
        <Space/>

        { props._session.userRole !== "ADMIN" ?
        <PagePlaceholder
          title={t("dashboard_empty_observer")}
          image="sherlock"
        />
        :
        <div style={{width:1000}}>

          <div className="flex">
            
            <Tabs
              onClick={changeTab}
              active={currentTab}
              tabs={{
                TAB_COMPARATOR : t("dashboard_compare_simple"),
                TAB_POPULATIONS : t("dashboard_compare_advanced")
              }}
            />

            <Space/>

          </div>

          {getWidget()}

          <div className="height-60" />
          
        </div>
        }
        
        <Space/>

      </div>
      }

    </NavDashboard>
  )
}

const mapStateToProps = state => ({
  _session : state._session
})

export default connect(mapStateToProps)(withTranslation()(DashboardCompareRoute))