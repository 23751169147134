/**
 * DASHBOARD-RPS
 * Burnout indicators
 */

import { useState } from "react"
import NavDashboard from "@/nav/nav.dashboard"
import UpgradeWidget from "@/widgets/upgrade.widget"
import Tabs from "@/components/tabs"
import { useTranslation } from "react-i18next"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChartColumn, faList, faPeopleGroup } from "@fortawesome/free-solid-svg-icons"
import ListItem from "@/components/list-item"
import DashboardRpsOverviewWidget from "@/widgets/dashboard-rps-overview.widget"
import DashboardRpsTeamsWidget from "@/widgets/dashboard-rps-teams.widget"
import DashboardRpsQuestionsWidget from "@/widgets/dashboard-rps-questions.widget"
import DashboardRpsUsersWidget from "@/widgets/dashboard-rps-users.widget"
import { DashboardRpsGollacWidget } from "@/widgets/dashboard-rps-gollac.widget"
import { useSelector } from "react-redux"
import { selectSession } from "@/core/slices/session/session-selector"
import { selectFilter } from "@/core/slices/filter/filter.selector"
import { selectSurvey } from "@/core/slices/survey/survey.selector"

const TAB_VIEW_KARASEK = "TAB_VIEW_KARASEK"
const TAB_VIEW_GOLLAC = "TAB_VIEW_GOLLAC"
const TAB_QUESTIONS = "TAB_QUESTIONS"
const TAB_TEAMS = "TAB_TEAMS"
const TAB_USERS = "TAB_USERS"

const DashboardRps = () => {
  const { t } = useTranslation()

  // Redux selectors
  const _session = useSelector(selectSession)
  const filter = useSelector(selectFilter)
  const survey = useSelector(selectSurvey)

  // State management with hooks
  const [currentViewTab, setCurrentViewTab] = useState(TAB_VIEW_KARASEK)
  const [currentTab, setCurrentTab] = useState(TAB_TEAMS)

  const tabsList = [
    {
      id: "item-1",
      icon: faPeopleGroup,
      name: t("rps_teams_title"),
      currentTab: TAB_TEAMS
    },
    {
      id: "item-2",
      icon: faList,
      name: t("rps_questions_title"),
      currentTab: TAB_QUESTIONS
    },
    {
      id: "item-3",
      icon: faChartColumn,
      name: t("rps_users_title"),
      currentTab: TAB_USERS
    }
  ]

  return (
    <NavDashboard
      title="rps_title"
      hideFilters={!_session.modules.rps}
      hideOptions={!_session.modules.rps}
      helpPage={23}>
      {!_session.modules.rps ? (
        <UpgradeWidget feature="rps" />
      ) : (
        <div>
          <Tabs
            active={currentViewTab}
            onClick={setCurrentViewTab}
            tabs={{
              TAB_VIEW_KARASEK: t("rps_tab_karasek"),
              TAB_VIEW_GOLLAC: t("rps_tab_gollac")
            }}
          />
          {currentViewTab === TAB_VIEW_KARASEK && (
            <>
              <DashboardRpsOverviewWidget dashboardFilters={filter.dashboard} />
              {survey.active.rps.total > 0 && _session.accountLevel !== "free" && (
                <div className="flex">
                  <div
                    className="flex flex-dcol"
                    style={{
                      marginRight: "1rem",
                      height: "50%"
                    }}>
                    {tabsList.map((item) => {
                      const isActive = currentTab === item.currentTab
                      return (
                        <div
                          style={{
                            cursor: "pointer"
                          }}
                          key={item.id}
                          onClick={() => setCurrentTab(item.currentTab)}
                          onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "#F0F0F0")}
                          onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "transparent")}>
                          <ListItem>
                            <div
                              className="width-10 abs"
                              style={{
                                backgroundColor: isActive ? _session.accountColors.active : "transparent",
                                height: "100%",
                                top: 0,
                                left: 0
                              }}
                            />
                            <div className={`${isActive ? "dark-t" : "grey-t"} flex flex-col justify-center w-full`}>
                              <FontAwesomeIcon icon={item.icon} className="p-1" />
                              <div className="text-center" style={{ fontSize: 12 }}>
                                {item.name}
                              </div>
                            </div>
                          </ListItem>
                        </div>
                      )
                    })}
                  </div>

                  {currentTab === TAB_TEAMS && <DashboardRpsTeamsWidget />}

                  {currentTab === TAB_QUESTIONS && <DashboardRpsQuestionsWidget dashboardFilters={filter.dashboard} />}

                  {currentTab === TAB_USERS && <DashboardRpsUsersWidget dashboardFilters={filter.dashboard} />}
                </div>
              )}
            </>
          )}
          {currentViewTab === TAB_VIEW_GOLLAC && <DashboardRpsGollacWidget />}
        </div>
      )}
    </NavDashboard>
  )
}

export default DashboardRps
