/**
 * PROJECT-REPEAT.MODAL
 * Open existing project with new survey
 */
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { ProjectState } from "@/redux/project.types";
import ModalConfirm from "@/components/modal-confirm";
import { store } from "@/index";
import { projectEdit, projectStatus, projectUpdate } from "@/redux/project.actions";
import { STATUS_SAVED, STATUS_SAVING } from "@/redux/_status.types";
  
interface StateProps extends WithTranslation{
  project : ProjectState
}

interface OwnProps{
  onClose : Function
}

type Props = StateProps & OwnProps

function ProjectRepeatModal(props:Props) {
  const { t } = props

  async function repeat(){

    store.dispatch(projectStatus(STATUS_SAVING))
    const response:any = await store.dispatch(projectUpdate(Object.assign({}, props.project.active, { isClosed : false })))
    store.dispatch(projectStatus(STATUS_SAVED))

    if (!response.error){
      store.dispatch(projectEdit("isClosed", false))
      props.onClose()
    }

  }

  return (
    <ModalConfirm status={props.project.status}
      onNo={() => props.onClose()}
      onYes={() => repeat()}
      textBold={t("utils_next_ask")}
      text={t("project_repeat_text")}
    />
  )
  
}

const mapStateToProps = state => ({
  project : state.project
})

export default connect(mapStateToProps)(withTranslation()(ProjectRepeatModal))