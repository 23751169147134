
/** 
 * COLORS-PREVIEW
 * Render account
 */

import { faCaretDown, faClipboardList, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Account } from '@/redux/account.types';
import env from '@/env';
import { getIconUrl } from '@/utils/default-icons.utils';

interface OwnProps{
  account:Account
}

type Props = OwnProps & WithTranslation

function ColorsPreviewWidget(props:Props){

  const { t } = props

  return (
    <div style={{ backgroundColor:"#F9FBFC", borderRadius : '8px', boxShadow : "2px 2px 10px #c5c5c5" }} className="flex1">
      <div className="flex grey-bor"
        style={{
          height:"48px",
          borderRadius : "8px 8px 0px 0px",
          background : 'linear-gradient(90deg, ' + props.account.colors.brandPrimary + ' 0%, ' + props.account.colors.brandSecondary + ' 100%)',
          color : props.account.colors.brandText
      }}>

        <img alt="account" className="page-navigation-circle-img"
          src={
            props.account.options.image ?
            env.REACT_APP_URL_SPACE.concat("/Accounts/", props.account.id, ".png?") :
            getIconUrl(props.account.options.defaultIcon)
          }>
        </img>

        <div className="rel menutop-item flex">
          <div className="menutop-item-content flex">
            <FontAwesomeIcon icon={faClipboardList}/>
            <div className="menutop-item-dropdown">
              {"  " + t("templates")}
              <FontAwesomeIcon icon={faCaretDown} />
            </div>
          </div>
        </div>

        <div className="rel menutop-item menutop-item-opacity flex">
          <div className="menutop-item-content flex">
            <FontAwesomeIcon icon={faPaperPlane}/>
            <div className="menutop-item-dropdown">
              {"  " + t("projects")}
            </div>
          </div>
        </div>

      </div>

      <div style={{ padding : "20px" }}>

        <h2 style={{margin : "10px"}}>{props.account.name}</h2>

        <div className="flex selector-tabs">


          <div className="selector-tabs-tab flex grey-t">

            <div style={{ margin : 'auto' }}>
              {t("preview_tab")} 
            </div>

          </div>

          <div className="selector-tabs-tab flex selector-tabs-tab-active">

            <div style={{ margin : 'auto' }}>
              {t("preview_tab_active")}
            </div>
            
            <div style={{ 
                backgroundColor : props.account.colors.active 
              }} 
              className="selector-tabs-tab-active-bottom">
            </div>

          </div>
        
        </div>

        <button className="button grey-t"
          style={{
            backgroundColor : props.account.colors.cta,
            color: 'white'
          }}>
          {t("preview_button_primary")}
        </button>

        <button className="button grey-t"
          style={{
            color: props.account.colors.cta
          }}>
          {t("preview_button_secondary")}
        </button>

      </div>

    </div>
  )
}

export default withTranslation()(ColorsPreviewWidget)