/** 
 * INVITE-USERS.MODAL
 * Invite users to a survey in progress
 */
import Button from "@/components/button"
import Checkbox from "@/components/checkbox"
import Modal from "@/components/modal"
import Space from "@/components/space"
import { store } from "@/index"
import { useEffect, useState } from "react"
import { withTranslation, WithTranslation } from "react-i18next"
import { connect } from "react-redux"
import { invitationAddNew } from "@/redux/invitation.actions"
import { InvitationState } from "@/redux/invitation.types"
import { SurveyState } from "@/redux/survey.types"
import { userFetchNotInvited, userFetchNotInvitedCount, userGet, userGetCount, userGetCountSearch, userStatus } from "@/redux/user.actions"
import { UserState } from "@/redux/user.types"
import AdmEditUserModal from "./adm-edit-user.modal";
import { accountFetchInvitations } from "@/redux/account.actions";
import ListContainer from "@/components/list-container";
import { STATUS_LOADED, STATUS_LOADING, STATUS_LOAD_ERROR, STATUS_SEARCHING } from "@/redux/_status.types";
import PageLoader from "@/components/page-loader";
import ListFields from "@/components/list-fields";
import ListCell from "@/components/list-cell";
import ListItem from "@/components/list-item";
import ListIcon from "@/components/list-icon";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { ProjectState } from "@/redux/project.types";
import LoadingModal from "./loading.modal";
import { surveyEdit } from "@/redux/survey.actions";
import { sessionEdit } from "@/redux/_session.actions";
import { Session } from "@/redux/_session.types";

interface StateProps extends WithTranslation{
  _session : Session
  invitation : InvitationState
  project : ProjectState
  survey : SurveyState
  user : UserState
}

interface OwnProps{
  onClose : Function
}

type Props = StateProps & OwnProps

const LIMIT = 5

const MODAL_ADD_USER = "MODAL_ADD_USER" //Add new user
const MODAL_CONFIRM = "MODAL_CONFIRM" //Confirmation box
const MODAL_DONE = "MODAL_DONE" //Confirmation operation is done (multiple)
const MODAL_ERROR_INVITATIONS = "MODAL_ERROR_INVITATIONS" //Error not enough credits
const MODAL_ERROR_PARTICIPANTS = "MODAL_ERROR_PARTICIPANTS" //Error too much participants (test only)
const MODAL_SAVING = "MODAL_SAVING" //Save in progress

function ProjectAddInvitation(props:Props) {
  const { t } = props

  //Modal to display
  const [currentModal, setCurrentModal] = useState<string | null>(null)

  //Current offset for search (when page is changed)
  const [currentOffset, setCurrentOffset] = useState<number>(0)

  //Content for searchbar
  const [currentSearch, setCurrentSearch] = useState<string>("")

  //Count of remaining invitations
  const [invitationsRemaining, setInvitationsRemaining] = useState(0)

  //Loading in progress
  const [isLoading, setIsLoading] = useState(true)

  //Users selected for invitation
  const [selectedUsersIds, setSelectedUsersIds] = useState<string[]>([])

  //Load users
  useEffect(() => {
    loadUsers()

    //Fetch users from backend and get count
    //If there is a search update count
    async function loadUsers(){

      //Fetch
      store.dispatch(userStatus(STATUS_SEARCHING))
      const users:any = await store.dispatch(userFetchNotInvited(props.survey.active.id, currentSearch, LIMIT, currentOffset))
      const usersCount:any = await store.dispatch(userFetchNotInvitedCount(props.survey.active.id, currentSearch))

      if (users.error || usersCount.error){
        store.dispatch(userStatus(STATUS_LOAD_ERROR))
      }else{

        //Update store (list and count)
        store.dispatch(userGet(users))
        store.dispatch(userGetCountSearch(usersCount.count))
        if (currentSearch.length < 1) store.dispatch(userGetCount(usersCount.count))
        store.dispatch(userStatus(STATUS_LOADED))

      }

      setIsLoading(true)

    }

  }, [
    props.survey.active.id,
    currentOffset,
    currentSearch
  ])

  //Load remaining invitations
  useEffect(() => {

    loadInvitationsRemaining()

    async function loadInvitationsRemaining(){
      const invitations:any = await store.dispatch(accountFetchInvitations())
      setInvitationsRemaining(invitations.error ? 0 : invitations.remaining)
    }

  }, [])

  //Get text when add multiple users
  function getAddConfirmText(){

    let confirmText : string = ""
    
    if(selectedUsersIds.length > props._session.invitationsRemaining && !props._session.accountOptions.isUnlimited){
      confirmText += t("survey_error_invitations")
    }
    else if (!props.survey.active.isTest){
      confirmText += t("account_invitations_remaining_after", {
        count : (props._session.invitationsRemaining - selectedUsersIds.length),
        s : (props._session.invitationsRemaining - selectedUsersIds.length) > 1 ? "s" : ""
      })
    }

    return confirmText
  }

  //Send invitations
  async function addConfirm(){
    setCurrentModal(MODAL_SAVING)
    const response:any = await store.dispatch(invitationAddNew(props.survey.active.id, selectedUsersIds))
    if(!props.survey.active.isTest){
      store.dispatch(sessionEdit("invitationsRemaining", props._session.invitationsRemaining - selectedUsersIds.length))
    }
    setCurrentModal(response.error ? null : MODAL_DONE)
  }

  //If test survey and max reach OR not enough invitations remaning
  //> error message
  function isInvitationError(invitedCount){

    let error = false
    if (props.survey.active.isTest && props.invitation.count + invitedCount > props._session.accountOptions.ceilsTesters){
      setCurrentModal(MODAL_ERROR_PARTICIPANTS)
      error = true
    }else if (!props.survey.active.isTest && invitationsRemaining - invitedCount <= 0){
      setCurrentModal(MODAL_ERROR_INVITATIONS)
      error = true
    }

    return error
  }

  function next(){
    if (!isInvitationError(selectedUsersIds.length)){
      setCurrentModal(MODAL_CONFIRM)
    }
  }

  //Reload list of invitation after operation is done
  //Close modal
  function reload(){
    store.dispatch(surveyEdit("isInvitationsInProgress", true))
    props.onClose()
  }

  function search(search){
    setCurrentSearch(search)
  }

  function select(userId:string){
    if (selectedUsersIds.indexOf(userId) > -1){
      setSelectedUsersIds(selectedUsersIds.filter(x => x !== userId))
    }else{
      setSelectedUsersIds(selectedUsersIds.concat([userId]))
    }
  }

  function setPage(offset){
    setCurrentOffset(offset)
  }

  return (
    <Modal onClose={() => props.onClose()}
      isCloseButtonVisible
      title={t("project_add_user")}
      isLarge
      onNext={selectedUsersIds.length > 0 ? () => next() : undefined}>

      { currentModal === MODAL_ADD_USER &&
      <AdmEditUserModal onClose={() => setCurrentModal(null)}
        onNext={(user) => {
          store.dispatch(userGetCountSearch(props.user.countSearch + 1))
          select(user.id);
          setCurrentModal(null)
        }}
      />
      }

      { currentModal === MODAL_CONFIRM &&
      <Modal isCloseButtonVisible
        onNext={() => selectedUsersIds.length > props._session.invitationsRemaining ? setCurrentModal(MODAL_ERROR_INVITATIONS) : addConfirm()}
        onClose={() => setCurrentModal(null)}>
       
        <p>
          <b>
            {t("invitation_add_confirm_multiple", {
              count : selectedUsersIds.length
            })}
          </b>
        </p>

        <div className="flex">
          <div className="flex1">
            <p className="grey-t">
              {getAddConfirmText()}
            </p>
            { props.project.active.diffusionMode.email &&
            <p>
              {t("invitation_add_confirm_multiple_help_email")}
            </p>
            }
          </div>
          { props.project.active.diffusionMode.email &&
          <div className="flex1"
            style={{ marginLeft : 20 }}>
            <img alt="add-invitation"
              className="medgrey-b"
              style={{
                border: "1px solid",
                borderRadius: 8
              }}
              width="100%"
              src={require("@/assets/add-invitation.png")}>
            </img>
          </div>
          }
        </div>

        <div className="height-20"/>

      </Modal>
      }

      { currentModal === MODAL_DONE &&
      <Modal isCloseButtonVisible
        onClose={reload}
        title={t("utils_congratulations")}>
        <p>
          {t("invitation_add_done_1")}
        </p>
        { props.project.active.diffusionMode.email &&
        <p>
          <b>
            {t("invitation_add_done_2")}
          </b>
        </p>
        }
      </Modal>
      }
      
      { currentModal === MODAL_ERROR_INVITATIONS &&
      <Modal onClose={() => setCurrentModal(null)}>
        <p>
          {t("survey_error_invitations")}
        </p>
        <div className="height-20"/>
        <div className="flex">
          <Space/>
          <Button onClick={() => setCurrentModal(null)}>
            {t("utils_close")}
          </Button>
          <Button className="primary"
            onClick={() => window.open("https://www.id-tree.com/lp-contact", "_blank")}>
            {t("utils_contact")}
          </Button>
        </div>
      </Modal>
      }

      { currentModal === MODAL_ERROR_PARTICIPANTS &&
      <Modal isCloseButtonVisible
        onClose={() => setCurrentModal(null)}>
        <p>
          {t("project_test_error_participants", { count : props._session.accountOptions.ceilsTesters })}
        </p>
      </Modal>
      }

      { currentModal === MODAL_SAVING &&
      <LoadingModal/>
      }
      
      <ListContainer countLimit={LIMIT}
        countSearch={props.user.countSearch}
        isFooterActive
        onAddTitle={t("user_add")}
        onAdd={() => setCurrentModal(MODAL_ADD_USER)}
        onSearch={search}
        onSearchStart={() => store.dispatch(userStatus(STATUS_SEARCHING))}
        onSetPage={setPage}
        status={props.user.status}>

        <PageLoader status={isLoading ? STATUS_LOADED : STATUS_LOADING}>

          <ListFields>
            <ListCell width={48}/>
            <ListCell/>
            <ListCell width={180} text={t("user_lastname")}/>
            <ListCell width={250} text={t("user_email")}/>
            <Space/>
            <ListCell/>
            <ListCell/>
          </ListFields>

          { props.user.list.map((user) =>
          <ListItem key={user.id}
            isEditable
            onClick={() => select(user.id)}>

            <ListCell width={48}>
              <Checkbox
                active={selectedUsersIds.indexOf(user.id) > -1}
                onClick={() => select(user.id)}
              />
              <Space/>
            </ListCell>

            <ListIcon fallbackIcon={faUserCircle}
              image={user.imageUrl}
            />

            <ListCell width={180} text={user.username}/>
            <ListCell width={250} text={user.email ? user.email : ""}/>
            
            <Space/>
            
          </ListItem>
          )
          }

        </PageLoader>

      </ListContainer>

      <div className="flex">
        <Space />
        <div className="grey-t">
          {t("selected_users", {count: selectedUsersIds.length, s: selectedUsersIds.length > 1 ? "s" : ""})}
        </div>
        <div className="width-10" />
      </div>

    </Modal>
  )

}

const mapStateToProps = state => ({
  _session : state._session,
  invitation : state.invitation,
  project : state.project,
  survey : state.survey,
  user : state.user
})

export default connect(mapStateToProps)(withTranslation()(ProjectAddInvitation))