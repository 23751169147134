/** 
 * TASKS.HOME
 * Home page for actions plans
 */
import PageHeader from "@/components/page-header";
import NavApp from "@/nav/nav.app";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Session } from "@/redux/_session.types";
import TasksEditWidget from "@/widgets/tasks-edit.widget";
import TasksTeamsWidget from "@/widgets/tasks-teams.widget";

interface StateProps extends WithTranslation{
  _session : Session
}

function TasksHomeRoute(props:StateProps){
  const { t } = props
  
  return(
    props._session.userRole === "ADMIN"
    ?
    <NavApp>

      <PageHeader title={t("tasks")}
        subtitle={t("tasks_help")}>
      </PageHeader>

      <TasksTeamsWidget/>

    </NavApp>
    :
    <NavApp>
      <TasksEditWidget/>
    </NavApp>
  )
}


const mapStateToProps = (state) => ({
  _session : state._session
})

export default connect(mapStateToProps)(withTranslation()(TasksHomeRoute))