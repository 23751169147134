import { Reminder, REMINDER_ADD, REMINDER_GET, REMINDER_INIT, REMINDER_REMOVE, REMINDER_REPLACE } from "./reminder.types";

// REDUX

export const reminderAdd = (reminder:Reminder) => ({
  type : REMINDER_ADD,
  payload : {
    reminder
  }
})

export const reminderGet = (reminders:Reminder[]) => ({
  type : REMINDER_GET,
  payload : {
    reminders
  }
})

export const reminderInit = () => ({
  type : REMINDER_INIT
})

export const reminderReplace = (reminder:Reminder) => ({
  type : REMINDER_REPLACE,
  payload : {
    reminder
  }
})

export const reminderRemove = (id:string) => ({
  type : REMINDER_REMOVE,
  payload : {
    id
  }
})


// API

export const reminderDestroy = (id:string) => ({
  type : 'API',
  payload : {
    method : "DELETE",
    url : "/reminder/" + id
  }
})

export const reminderFetch = (projectId:string) => ({
  type : 'API',
  payload : {
    method : "GET",
    url : "/reminders",
    data : {
      projectId
    }
  }
})

export const reminderFetchAll = () => ({
  type : 'API',
  payload : {
    method : "GET",
    url : "/reminders/all",
  }
})

export const reminderUpdate = (reminder:Reminder, projectId:string) => ({
  type : 'API',
  payload : {
    method : "POST",
    url : "/reminder",
    data : {
      reminder,
      projectId
    }
  }
})