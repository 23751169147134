/**
 * GET-EMOJI
 * Return emoji from language code
 */

import localeEmoji from 'locale-emoji';

const getEmoji = (language: string | null) => {
  if (language){
    const emoji =  localeEmoji(language)
    return emoji ? (emoji === "🇺🇸" ? "🇬🇧" : (emoji === "🇧🇷" ? "🇵🇹" : emoji)) : language.toUpperCase()
  }else{
    return ""
  }
}

export default getEmoji