/**
 * LOGIN.MAIL
 * Connexion from a link
 */

import { Component } from "react"
import LoginModal from "@/modals/login.modal"

class LoginMail extends Component{
  
  render () {
    return(
      <div>
        <LoginModal authLevel={1}/>
      </div>
    )

  }

}

export default LoginMail