/** 
 * LOGIN-SSO
 * Login to application with SSO provider
 */

import { WithTranslation, withTranslation } from 'react-i18next'
import LoadingModal from '@/modals/loading.modal'
import { useEffect, useState } from 'react'
import { store } from '@/index'
import { _authSsoChallenge } from '@/redux/_auth.actions'
import PagePlaceholder from '@/components/page-placeholder'
import { useParams } from 'react-router-dom';

type Props = WithTranslation

function LoginSsoAuthRoute(props: Props){
  const { t } = props

  //Get url param
  const { org } = useParams()

  //Error loading the page
  const [isError, setIsError] = useState(false)

  //Fetch server on loard
  useEffect(() => {

    challengeSso()

    async function challengeSso(){

      if (!org){
        setIsError(true)
      }else{

        //Challenge the SSO
        const response:any = await store.dispatch(_authSsoChallenge(org))
  
        //If no error proceed to redirection
        if (response.error){
          setIsError(true)
        }else{
          window.location.href = response.authorization_url
        }

      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="flex flex-auto">

      { isError
      ?
      <PagePlaceholder image="sherlock" help={t("survey_letter_faq_5")}/>
      :
      <LoadingModal/>
      }

    </div>
  )
}

export default withTranslation()(LoginSsoAuthRoute)