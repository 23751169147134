/**
 * 
 * AI-ASSISTANT-BUTTON
 * 
 */

import { useEffect, useState } from "react"
import ListIcon from "./list-icon"
import { WithTranslation, withTranslation } from "react-i18next"
import { faCheck, faGraduationCap } from "@fortawesome/free-solid-svg-icons"
import { BounceIn } from "@/utils/animations.utils"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Space from "./space"

interface OwnProps{
  isAnimated?: boolean
  isChecked?: boolean
  onClick?: Function
}

type Props = OwnProps & WithTranslation

function AiAssistantButton(props: Props){
  const { t } = props

  const [isAnimated, setIsAnimated] = useState<boolean>(props.isAnimated ? true : false)
  const [isChecked, setIsChecked] = useState<boolean>(props.isChecked ? true : false)

  useEffect(() => {
   setIsAnimated(props.isAnimated ? true : false)  
  }, [
    props.isAnimated
  ])

  useEffect(() => {
    setIsChecked(props.isChecked ? true : false)  
  }, [
    props.isChecked
  ])

  function click(){
    if(props.onClick){
      props.onClick()
    }
  }

  return (
    <div
      className={"_hover" + (isAnimated ? " _animated" : " grey-t")}
      style={{
        border: `1px solid ${isAnimated ? "transparent" : "#8c8c8c"}`,
        borderRadius: 20,
        marginTop: -3,
      }}
      onClick={click}
    >
      <div
        className="flex"
        style={{
          backgroundColor: "#ffffff",
          borderRadius: 20,
          padding: "2px 6px",
        }}
      >
                
        <BounceIn>
          <ListIcon
            onClick={click}
            isAnimated={isAnimated}
            fallbackIcon={faGraduationCap}
          />
        </BounceIn>

        {isAnimated ?
        <div
          className="_animated"
          style={{
            marginLeft: -23,
            backgroundClip: "text",
            color: "transparent"
          }}
        >
          {t("module_assistant")}
        </div>
        :
        <div style={{marginLeft: -23}} />
        }

        {isChecked &&
        <div className="flex flex-dcol" style={{paddingLeft: 5}}>
          <Space />
          <FontAwesomeIcon icon={faCheck} className="green-t" />
          <Space />
        </div>
        }

      </div>

    </div>
  )

}

export default withTranslation()(AiAssistantButton)