/**
 * FETCH-ARCHIVE-FILTERS
 * Get filters from archive database and format them
 */

import { store } from "@/index"
import { Attribute } from "@/redux/attribute.types";
import { fetchAttributes } from "@/redux/_archive.actions";
import { DashboardFilters } from "@/redux/filter.types";

const fetchArchiveAttributes = async (dashboardFilters: DashboardFilters, surveyId:string, filterName: string | null) => {

  const attributesResponse: any = await store.dispatch(fetchAttributes(dashboardFilters, filterName, surveyId))
  
  if (attributesResponse.error){
    return []
  }else{

    const newAttributes:Attribute[] = []
    attributesResponse.forEach(attribute => {
      attribute.id = attribute.aid.toString()
      const newAttribute : Attribute = new Attribute(attribute)
      newAttributes.push(newAttribute)
    })

    return newAttributes

  }

}

export default fetchArchiveAttributes