/** 
 * TASKS-LIST.WIDGET
 * Get list of actions to do for a user
 */

import Space from "@/components/space"
import { store } from "@/index"
import { useState } from "react"
import { withTranslation, WithTranslation } from "react-i18next"
import { connect } from "react-redux"
import { ActionPlanState, ActionPlanTask, ActionPlanTaskStatus, TASK_STATUS_LIST } from "@/redux/action-plan.types"
import TasksCardWidget from "./tasks-card.widget"
import { Droppable, DragDropContext } from 'react-beautiful-dnd';
import TasksDescriptionWidget from "./tasks-description.widget"
import { actionPlanEditTask } from "@/redux/action-plan.actions"
import { Session } from "@/redux/_session.types"
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons"
import ListButton from "@/components/list-button"
import TasksCreateChoiceWidget from "./tasks-create-choice.widget"
import Modal from "@/components/modal"

interface StateProps extends WithTranslation{
  _session : Session
  actionPlan : ActionPlanState
}

//Add new task modal
const MODAL_TASK_ADD: string = "MODAL_TASK_ADD"

function TasksListWidget(props:StateProps) {
  const { t } = props

  //Display modal
  const [currentModal, setCurrentModal] = useState<string | null>(null)

  //Change status of a tasks by dragging drop
  function changeStatus(e){
    let task = props.actionPlan.active.tasks.find(x => x.id === e.draggableId)
    if (e.destination && task){
      task = new ActionPlanTask(task)
      task.status = e.destination.droppableId
      store.dispatch(actionPlanEditTask(task))
    }
  }

  return (
    <div className="flex1">

      { currentModal === MODAL_TASK_ADD &&
      <Modal
        title={t("tasks_list_add")}
        onClose={() => setCurrentModal(null)}
      >
        <TasksCreateChoiceWidget
          onClose={() => setCurrentModal(null)}
          isInModal
        />
      </Modal>
      }

      { props.actionPlan.active.status === "active" &&
      <TasksDescriptionWidget/>
      }

      { (props.actionPlan.active.tasks.length === 0 && props.actionPlan.active.status === "draft") &&
      <div className="upload-button medgrey-b flex flex-dcol"
        style={{
          maxHeight: 340,
          padding: 40
        }}>

        <Space/>

        <div className="height-20"/>

        <div className="grey-t">
          <b>
            {t("tasks_list_empty")}
          </b>
        </div>

        <div className="height-20"/>

        <div className="grey-t" style={{ fontSize : 12 }}>
          {t("tasks_list_empty_help")}
        </div>

        <div className="height-20"/>

        <div className="flex">
          <Space/>
          <TasksCreateChoiceWidget />
          <Space/>
        </div>

        <Space/>

      </div>
      }

      { props.actionPlan.active.status !== "active"
      ?
      <div>


        { props.actionPlan.active.tasks.length > 0 &&
        <>
          <div className="height-40" />

          <ListButton
            isPrimary
            isFat
            text={t("tasks_list_add")}
            onClick={() => setCurrentModal(MODAL_TASK_ADD)}
            icon={faCirclePlus}
          />
        </>
        }


        <div className="height-40" />
        
        { props.actionPlan.active.tasks.map((task: ActionPlanTask, i: number) =>
        <TasksCardWidget
          key={task.id}
          draggableIndex={i}
          currentTask={task}
        />
        )}

      </div>
      :
      <DragDropContext onDragEnd={changeStatus}>
        <div className="flex tasks-column-container">

          { TASK_STATUS_LIST.map((status: ActionPlanTaskStatus) => 
          <div key={status} className="flex1 tasks-column medgrey-b">
            <Droppable droppableId={status} type="TASKS_STATUS">
              { provided => (
              <div ref={provided.innerRef}
                {...provided.droppableProps}
                style={{ height : "100%" }}>
                  
                <div className="flex tasks-column-title medgrey white-t">
                  {t("tasks_status_" + status)}

                  {( status === "todo" &&
                    (props._session.userRole === "ADMIN" || 
                    props.actionPlan.active.allowAddTask
                  )) &&
                  <>
                    <Space />
                    <div style={{marginRight: -8}}>
                      <ListButton
                        isPrimary
                        isFat
                        text={t("tasks_list_add")}
                        onClick={() => setCurrentModal(MODAL_TASK_ADD)}
                        icon={faCirclePlus}
                      />
                    </div>
                  </>
                  }
                </div>

                <div className="tasks-column-card-container">

                  { props.actionPlan.active.tasks.filter(x => x.status === status).map((task: ActionPlanTask, i: number) =>
                  <TasksCardWidget draggableIndex={i}
                    key={task.id} 
                    isDraggable 
                    currentTask={task}
                  />
                  )
                  }

                  { status === "todo" &&
                  <div style={{
                      padding : 16,
                      textAlign : "center",
                      marginBottom : 16
                    }}>
                    
                  </div>
                  }

                  {provided.placeholder}

                </div>

              </div>
              )
              }
            </Droppable>
          </div>
          )
          }
        
        </div>
      </DragDropContext>
      }

    </div>
  )

}

const mapStateToProps = state => ({
  _session : state._session,
  actionPlan : state.actionPlan
})

export default connect(mapStateToProps)(withTranslation()(TasksListWidget))