/** 
 * CARD-MAGIC
 * Display like a card game
 */
import { connect } from "react-redux"
import { Session } from "@/redux/_session.types";
import Space from './space';

interface StateProps{
  _session : Session
}

interface OwnProps{
  description? : string
  image : string
  isActive? : boolean
  title : string
}

type Props = StateProps & OwnProps

function CardMagic(props:Props) {

  return (
    <div style={props.isActive ? { color : props._session.accountColors.active } : {}} className="card-magic medgrey-b flex flex-dcol">

      <div className="card-magic-title medgrey-b">
        <b>{props.title}</b>
      </div>

      <div>
        <img src={props.image} alt={props.title}/>
      </div>

      <Space/>

      { props.description &&
      <div className="card-magic-description grey-t">
        {props.description}
      </div>
      }
      
      <Space/>

    </div>
  )

}

const mapStateToProps = state => ({
  _session : state._session
})

export default connect(mapStateToProps)(CardMagic)