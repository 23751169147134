/** 
 * SET-TOPIC.MODAL
 * Change the topic of one question
 */
import Modal from "@/components/modal"
import { connect } from "react-redux"
import { withTranslation, WithTranslation } from "react-i18next"
import CardButton from "@/components/card-button"
import CardContainer from "@/components/card-container"
import { faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import { Session } from "@/redux/_session.types";
import { TopicState } from "@/redux/topic.types";
import { useState } from "react";
import { Question } from "@/redux/question.types"
import { store } from "@/index"
import { topicAddAndCopyQuestion, topicCopyQuestion, topicRemoveQuestion } from "@/redux/topic.actions"
import Dropdown from "@/components/dropdown"
import { sortBy } from "lodash"

interface StateProps extends WithTranslation{
  _session : Session
  topic : TopicState,
}

interface OwnProps{
  currentQuestion : Question
  onClose : Function
}

type Props = StateProps & OwnProps

function SetTopicModal(props:Props) {
  const { t } = props

  const [newTopicId, setNewTopicId] = useState<string | null>(null)

  function close(){
    props.onClose()
  }

  function getTopicValue(){
    const topic = getTopics().find(x => x.id === newTopicId)
    return topic ? topic.name : null
  }

  function getTopics(){

    const topics:any = []
    props.topic.list.forEach(topic => {
      const name = topic.name[props._session.language]
      if (name && topic.id !== props.topic.active.id){
        topics.push({ 
          id : topic.id,
          name
        })
      }
    })

    return sortBy(topics, "name")

  }

  function next(){
    store.dispatch(topicRemoveQuestion(props.currentQuestion.id, props.topic.active.id))

    if (newTopicId){
      store.dispatch(topicCopyQuestion(newTopicId, props.currentQuestion))
    }else{
      store.dispatch(topicAddAndCopyQuestion(props._session.interfaceType === "SUPERVISOR", props.topic.active.Axis, props.currentQuestion))
    }

    close()

  }

  function selectTopic(topic){
    setNewTopicId(topic.id)
  }

  return (
    <Modal isCloseButtonVisible
      onNext={next}
      onClose={close}
      title={t("question_change_topic")}>

      <p>
        {t("question_change_topic_question", {
          question : props.currentQuestion.name[props._session.language]
        })}
      </p>

      <CardContainer>

        <CardButton icon={faPlus}
          isCta={!newTopicId}
          title={t("topic_add_new")}
          onClick={() => setNewTopicId(null)}>
        </CardButton>

        { getTopics().length > 0 &&
        <CardButton icon={faSearch}
          isCta={newTopicId !== null}
          title={t("topic_add_existing")}
          onClick={!newTopicId ? () => setNewTopicId(getTopics()[0].id) : () => {}}>
        </CardButton>
        }

      </CardContainer>

      { newTopicId !== null &&
      <Dropdown active={newTopicId}
        displayField="name"
        isContextMenuTop
        list={getTopics()}
        title={t("topic_select")}
        onSelect={selectTopic}
        value={getTopicValue()}
      />
      }

    </Modal>
  )

}

const mapStateToProps = state => ({
  _session : state._session,
  topic : state.topic
})

export default connect(mapStateToProps)(withTranslation()(SetTopicModal))