/** 
 * REPORT-ADD-PAGE-BUTTON.WIDGET.TSX
 * Add new page
 */

import { WithTranslation, withTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import ReactTooltip from 'react-tooltip'
import { store } from "@/index";
import { pageAdd, pageCreateNew } from "@/redux/page.actions";
import { Page, PageState } from "@/redux/page.types";
import { connect } from "react-redux";

interface StateProps{
  report: PageState
}

interface OwnProps{
  pageIndex : number
}

type Props = OwnProps & StateProps & WithTranslation

function ReportAddNewPageButtonWidget(props:Props) {
  const { t } = props

  const [tooltipId] = useState("report-add-tooltip-" + props.pageIndex)

  //Add new page at specific index
  function addPage(){
    const page:Page = pageCreateNew({ name: t("page_new") }, props.report.active.id)
    store.dispatch(pageAdd(page, props.pageIndex))
  }

  return (
    <div className="flex-auto">

      <FontAwesomeIcon
        onClick={() => addPage()}
        className="_hover"
        icon={faPlusCircle} 
        data-for={tooltipId}
        data-tip={t("page_add")}
      />

      <ReactTooltip id={tooltipId}/>

    </div>

  )
  
}

const mapStateToProps = state => ({
  report : state.report
})

export default connect(mapStateToProps)(withTranslation()(ReportAddNewPageButtonWidget))