/**
 * CUSTOMER FEEDBACK ACTION
 */

export const feedbackFetchAll = () => ({
  type : 'API',
  payload : {
    method : "GET",
    url : "/supervisor/feedbacks"
  }
})

export const feedbackSend = (userId:string) => ({
  type : 'API',
  payload : {
    method : "POST",
    url : "/feedback/send",
    data : {
      userId
    }
  }
})