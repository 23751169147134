/** 
 * CONTEXT-MENU
 * Dropdown menu
 */
import { useEffect } from "react"
import { FadeIn } from "@/utils/animations.utils"
import { useClickOutside } from 'react-click-outside-hook'

interface OwnProps{
  children : any, //Content inside
  footer? : string | null, //Display text on bottom
  isLarge? : boolean //Large context menu
  maxHeight? : number //Set max height (procude overflow)
  onClose? : Function, //Trigger action
  positionRight? : boolean //Align context at right
  positionBottom? : boolean //Align context at bottom
}

function ContextMenu(props:OwnProps) {

  //Detect click outiside the component => close context
  const [ref, hasClickedOutside] = useClickOutside()

  //Trigger close function when clic outside
  useEffect(() => {
    close()
  })

  //Close component
  function close(){
    if (hasClickedOutside && props.onClose){
      props.onClose()
    }
  }

  //Style
  function getStyle(){
    let top
    let bottom
    let left
    let right
    let maxHeight
    let overflowY
    let width

    if (props.isLarge){
      width = 320
    }

    if (props.positionRight){
      right = '0px'
    }else{
      left = '0px'
    }

    if (props.positionBottom){
      bottom = "100%"
    }else{
      top = "100%"
    }

    if (props.maxHeight){
      maxHeight = props.maxHeight + "px"
      overflowY = "auto"
    }

    return {
      top,
      bottom,
      left,
      right,
      maxHeight,
      overflowY,
      width
    }
    
  }

  return (
    <FadeIn ref={ref} className="context-menu abs"
      style={getStyle()}>

      { props.children }

      { props.footer &&
      <div className="context-menu-footer grey-t">
        {props.footer}
      </div>
      }

    </FadeIn>
  )

}

export default ContextMenu