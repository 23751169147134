/**
 * REPORT-EDIT.ROUTE
 * Create or update existing report and pages
 */

import { NavigateFunction, useNavigate } from "react-router-dom";
import NavFocus from "@/nav/nav.focus";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { faChevronLeft, faChevronRight, faFilePowerpoint, faFilter, faPenAlt, faSave, faTimes, faTrash, faUndo, faUserFriends, faUsers } from "@fortawesome/free-solid-svg-icons";
import { Page, PageState } from "@/redux/page.types";
import { store } from "@/index";
import { reportEditAndSave, reportSave } from "@/redux/report.actions";
import { ReportState } from "@/redux/report.types";
import { useEffect, useState } from "react";
import ListInput from "@/components/list-input";
import ReportNavWidget from "@/widgets/report-nav.widget";
import { pageChange, pageStatus } from "@/redux/page.actions";
import { STATUS_SAVING } from "@/redux/_status.types";
import Modal from "@/components/modal";
import CardContainer from "@/components/card-container";
import CardButton from "@/components/card-button";
import ReportPagePreviewWidget from "@/widgets/report-page-preview.widget";
import Space from "@/components/space";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Lottie from "react-lottie-player";
import ReportGenerateModal from "@/modals/report-generate.modal";
import ReportDeleteModal from "@/modals/report-delete.modal";
import ReportGetDataModal from "@/modals/report-get-data.modal";
import { Session } from "@/redux/_session.types";
import ReportParamsWidget from "@/widgets/report-params.widget";
import Link from "@/components/link";
import Checkbox from "@/components/checkbox";
import SelectPopulationsModal from "@/modals/select-populations.modal";
import { Population } from "@/redux/population.types";
import { populationsUpdateWithAid } from "@/redux/population.actions";

interface StateProps{
  _session : Session
  page : PageState
  report : ReportState
}

type Props = StateProps & WithTranslation

//List of action for the nav bar
const ACTION_DOWNLOAD = "ACTION_DOWNLOAD"
const ACTION_DELETE = "ACTION_DELETE"
const ACTION_SAVE = "ACTION_SAVE"
const ACTION_UNDO = "ACTION_UNDO"

//List of modals
const MODAL_DELETE = "MODAL_DELETE"
const MODAL_DOWNLOAD = "MODAL_DOWNLOAD"
const MODAL_GENERATE = "MODAL_GENERATE"
const MODAL_GET_DATA = "MODAL_GET_DATA"
const MODAL_POPULATIONS = "MODAL_POPULATIONS"
const MODAL_SELECT_POPULATIONS = "MODAL_SELECT_POPULATIONS"

function ReportEditRoute(props: Props){
  const { t } = props
  const navigate: NavigateFunction = useNavigate()

  //Current modal
  const [currentModal, setCurrentModal] = useState<string | null>(null)

  //Detect action on keyboard
  //> Enter => save
  //> Escape => cancel
  useEffect(() => {
  
    const onKeyDown = (e) => {
      if (props.report.active.keyDownAllowed){
        if (e.key === "ArrowLeft"){
          store.dispatch(pageChange(false))
        }else if (e.key === "ArrowRight"){
          store.dispatch(pageChange(true))
        }
      }
    }

    window.addEventListener('keydown', onKeyDown);
    return () => {
        window.removeEventListener('keydown', onKeyDown);
    }

  }, [
    props.report.active.keyDownAllowed
  ])

  //On load if the report is set as autogenerate => propose to download
  useEffect(() => {

    if (props.report.active.isAutoGenerated){
      setCurrentModal(MODAL_DOWNLOAD)
    }

  }, [props.report.active.isAutoGenerated])

  //On click on the nav button (save or download)
  function clickAction(action:string){
    switch(action){
      case ACTION_DELETE:
        return setCurrentModal(MODAL_DELETE)
      case ACTION_DOWNLOAD:
        return download()
      case ACTION_SAVE:
        return store.dispatch(reportSave())
      case ACTION_UNDO:
        return setCurrentModal(MODAL_GET_DATA)
      default:
        return null
    }
  }

  //Click on the download button
  //Open the generate Modal
  function download(){
    store.dispatch(pageStatus(STATUS_SAVING))
    setCurrentModal(MODAL_GENERATE)
  }

  //Edit population
  //Update the archiveId in order to filter the data for the report
  function editPopulations(populations:Population[]){
    editReportOptions("populations", populationsUpdateWithAid(populations))
  }

  //Update report
  function editReport(key: string, value: any){
    store.dispatch(reportEditAndSave(key, value))
  }

  //Edit options for the report
  function editReportOptions(key:string, value:any){
    store.dispatch(
      reportEditAndSave(
        "options", 
        Object.assign({}, props.report.active.options, { [key] : value }) 
      )
    )
  }

  //Detect if all pages are saved
  function isNotSaved(){
    return props.page.list.some((x:Page) => x.status !== "saved")
  }

  return(
    <NavFocus onBack={() => navigate(props._session.interfaceType === "SUPERVISOR" ? '/supervisor/reports' : '/report/list')}
      status={props.report.status}
      customNavItems={
        <div className="flex">
          <div className="width-20"/>
          <ListInput
            isEditButtonVisible
            value={props.report.active.name}
            onEditEnd={(name: string) => editReport("name", name)}
          />
          <div className="width-40"/>
          <FontAwesomeIcon icon={faFilter} className="grey-t"/>
          &nbsp;
          <Link isWithoutMargin
            onClick={() => setCurrentModal(MODAL_POPULATIONS)}>
            {props.report.active.options.populations.length > 0 
            ?
            props.report.active.options.populations.map(x => x.name).join(", ")
            :
            t("report_population")
            }
          </Link>
        </div>
      }
      onAction={clickAction}
      actions={[
        { id : ACTION_DELETE, icon : faTrash, label : t("report_delete") },
        { id : ACTION_UNDO, icon : faUndo, label : t("utils_cancel"), className : isNotSaved() ? "" : "light" },
        { id : ACTION_SAVE, icon : faSave, label : t("utils_save"), isLarge : true, className : isNotSaved() ? "primary" : "" },
        { id : ACTION_DOWNLOAD, icon : faFilePowerpoint, label : t("page_generate"), isLarge : true, className : isNotSaved() ? "" : "primary" }
      ]}>

      { currentModal === MODAL_GENERATE &&
      <ReportGenerateModal onClose={() => setCurrentModal(props._session.modules.report ? null : MODAL_DOWNLOAD)}/>
      }

      { currentModal === MODAL_DELETE &&
      <ReportDeleteModal onClose={() => navigate(props._session.interfaceType === "SUPERVISOR" ? '/supervisor/reports' : '/report/list')}/>
      }

      { currentModal === MODAL_GET_DATA &&
      <ReportGetDataModal onClose={() => setCurrentModal(null)}/>
      }

      { currentModal === MODAL_SELECT_POPULATIONS &&
      <SelectPopulationsModal onClose={() => setCurrentModal(MODAL_POPULATIONS)}
        isWithoutDefaultFilters
        isSurveyActiveSelected
        selectedPopulations={props.report.active.options.populations}
        onNext={(populations:Population[]) => editPopulations(populations)}
      />
      }

      { currentModal === MODAL_POPULATIONS &&
      <Modal isCloseButtonVisible
        title={t("report_population")}
        onClose={() => setCurrentModal(null)}>
        
        <CardContainer>

          <CardButton icon={faUsers}
            isSmall
            isActive={props.report.active.options.populations.length === 0}
            title={t("report_population_filters_false")}
            onClick={() => editPopulations([])}>
          </CardButton>

          <CardButton icon={faUserFriends}
            isSmall
            isActive={props.report.active.options.populations.length > 0}
            title={t("report_population_filters_true")}
            onClick={() => setCurrentModal(MODAL_SELECT_POPULATIONS)}>
          </CardButton>

        </CardContainer>

        { (props._session.userRole === "ADMIN" && props.report.active.options.populations.length > 0) &&
        <Checkbox active={props.report.active.options.allowObserversToCopy}
          onClick={() => editReportOptions("allowObserversToCopy", !props.report.active.options.allowObserversToCopy)}
          text={t("report_population_allow_copy")}
        />
        }

      </Modal>
      }

      { currentModal === MODAL_DOWNLOAD &&
      <Modal>

        <Lottie
          animationData={require("@/assets/fireworks.json")}
          loop={false}
          play
          style={{
            width: 412,
            height: 412,
            marginTop: -50,
            position: "absolute",
            marginLeft: -40
          }}
        />

        <div style={{
            textAlign : "center",
            fontSize : 18 
          }}>
          {t("report_generated_title")}
        </div>

        <div className="height-20"/>

        <CardContainer>
          
          <CardButton isCta
            title={t("report_generated_download")}
            icon={faFilePowerpoint}
            onClick={() => clickAction(ACTION_DOWNLOAD)}
          />

          <CardButton title={t("report_generated_edit")}
            icon={faPenAlt}
            isLock={!props._session.modules.report}
            onClick={props._session.modules.report ? () => setCurrentModal(null) : () => {}}
          />

          <CardButton title={t("report_generated_exit")}
            icon={faTimes}
            onClick={() => navigate("/home/account")}
          />

        </CardContainer>

        <div className="height-10"/>

        <div className="flex">
          <Space/>
          <p className="grey-t"
            style={{
              textAlign : "center",
              width : 460
            }}>
            {t("report_generated_subtitle")}
          </p>
          <Space/>
        </div>

      </Modal>
      }

      <ReportNavWidget/>
      
      { props._session.userRole !== "OBSERVER" &&
      <ReportParamsWidget/>
      }

      { props.page.list.length > 0 &&
      <div className="report-edit-container flex lightgrey">

        <div className="flex1 flex">

          <Space/>

          <FontAwesomeIcon icon={faChevronLeft} 
            className={"flex-auto medgrey-t report-edit-container-arrow " + (props.page.list.findIndex(x => props.page.active.id === x.id) > 0 ? "_hover" : "lightwhite-t")}
            onClick={() => store.dispatch(pageChange(false))}
          />

          <div className="report-edit-page grey-b flex-auto">
            <ReportPagePreviewWidget currentPage={props.page.active}/>
          </div>

          <FontAwesomeIcon icon={faChevronRight}
            className={"flex-auto medgrey-t report-edit-container-arrow " + (props.page.list.findIndex(x => props.page.active.id === x.id) < props.page.list.length - 1 ? "_hover" : "lightwhite-t")}
            onClick={() => store.dispatch(pageChange(true))}
          />

          <Space/>

        </div>

        <div className={"report-edit-page-right" + (props.report.active.paramsOpen ? "" : " report-edit-page-right-params-closed")}/>

      </div>
      }

    </NavFocus>
  )
}

const mapStateToProps = (state) => ({
  _session : state._session, 
  page : state.page,
  report : state.report
})

export default connect(mapStateToProps)(withTranslation()(ReportEditRoute))