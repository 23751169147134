/**
 * FILTER-LIST.WIDGET
 * Enter code before doing a sensible operation
 */

import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Button from "@/components/button"
import Dropdown from "@/components/dropdown"
import ListButton from "@/components/list-button";
import Modal from "@/components/modal";
import ModalConfirm from "@/components/modal-confirm";
import Space from "@/components/space";
import TextInput from "@/components/text-input";
import { store } from "@/index";
import { sortBy } from "lodash";
import { useState } from "react";
import { withTranslation, WithTranslation } from "react-i18next"
import { connect } from "react-redux"
import { toast } from "react-toastify";
import { filterActivate, filterAddAndUpdate, filterDestroy, filterEdit, filterRemove, filterStatus } from "@/redux/filter.actions";
import { Filter, FilterPopulation, FilterState } from "@/redux/filter.types"
import { Population } from "@/redux/population.types"
import { STATUS_SAVED, STATUS_SAVE_ERROR, STATUS_SAVING } from "@/redux/_status.types";
import UserFilterPictureWidget from "./user-filter-picture.widget";
import { NavigateFunction, useNavigate } from "react-router-dom";


interface StateProps{
  filter : FilterState
}

interface OwnProps{
  attributes: any
  username: string
  onEdit: Function
  isEditable? : boolean
  withInfo? : boolean //display filter info
}

type Props = StateProps & OwnProps & WithTranslation

const MODAL_ADD_ATTRIBUTE: string = "MODAL_ADD_ATTRIBUTE"
const MODAL_ADD_FILTER: string = "MODAL_ADD_FILTER"
const MODAL_DELETE_FILTER: string = "MODAL_DELETE_FILTER"

function UserFilterInfoWidget(props:Props) {
  const { t } = props
  const navigate: NavigateFunction = useNavigate()

  //Attribute name to change
  const [attributeName, setAttributeName] = useState<string>("")

  //Filter name to change
  const [filterName, setFilterName] = useState<string>("")

  //Current modal
  const [currentModal, setCurrentModal] = useState<string | null>(null)

  function addAttribute(){
    if(props.filter.active.populations.map((x:FilterPopulation)=>x.name).indexOf(attributeName) > -1){
      toast(t("attribute_add_error_duplicate", {name: attributeName}), {type: "error"})
    }
    else{
      const newPopulations: FilterPopulation[] = [...props.filter.active.populations, new FilterPopulation({name: attributeName, usersCount: 1})]
      store.dispatch(filterEdit("populations", sortBy(newPopulations, "name")))
      props.onEdit(props.filter.active.name, attributeName)
      setCurrentModal(null)
      setAttributeName("")
    }
  }

  function addFilter(){
    if(filterName.length < 1 || props.filter.list.some((x: Filter)=>x.name === filterName || x.importName === filterName)){
      toast(t("filter_error_add_duplicate", {filter: filterName}), {type: "error"})
    }
    else{
      store.dispatch(filterAddAndUpdate(new Filter({name : filterName})))
      setCurrentModal(null)
      setFilterName("")
    }
  }


  //Delete > open confirm dialog
  function deleteFilter(filter: Filter){
    store.dispatch(filterActivate(filter))
    setCurrentModal(MODAL_DELETE_FILTER)
  }

  //Confirm filter delete
  async function deleteFilterConfirm(){
    store.dispatch(filterStatus(STATUS_SAVING))
    const response:any = await store.dispatch(filterDestroy(props.filter.active.id))
    if (response.error){
      store.dispatch(filterStatus(STATUS_SAVE_ERROR))
    }else{
      store.dispatch(filterStatus(STATUS_SAVED))
      store.dispatch(filterRemove(props.filter.active.id))
      setCurrentModal(null)
    }
  }

  return (
    <div>

      { currentModal === MODAL_ADD_ATTRIBUTE &&
      <Modal isCloseButtonVisible
        isLarge
        title={t("attribute_add")}
        onClose={() => setCurrentModal(null)}
        onNext={attributeName.length > 0 ? addAttribute : undefined}>
        <TextInput onChange={(e) => setAttributeName(e.value)}
          title={t("attribute_value", {
            filter : props.filter.active.name,
            user : props.username
          })}
          value={attributeName}
        />

      </Modal>
      }

      { currentModal === MODAL_ADD_FILTER &&
      <Modal isCloseButtonVisible
        isLarge
        onClose={() => setCurrentModal(null)}
        title={t("filter_add")}
        onNext={filterName.length > 0 ? addFilter : undefined}>
        <div className="flex">

          <div className="flex1">

            <p>
              {t("filters_help")}
            </p>

            <TextInput onChange={(e) => setFilterName(e.value)}
              title={t("filter_name")}
              help={t("filters_help_2")}
              value={filterName}
            />

          </div>
          
          <div className="width-20"/>

          <div className="flex1 rel">

            <div className="text-input-title flex">
              <Space/>
              <b>{t("import_filter_example")}</b>
              <Space/>
            </div>

            <div className="height-10"/>

            <UserFilterPictureWidget/>

          </div>

        </div>

      </Modal>
      }

      { /** dialog to confirm delete */
      currentModal === MODAL_DELETE_FILTER &&
      <ModalConfirm
        onNo={() => setCurrentModal(null)}
        onYes={deleteFilterConfirm}
        status={props.filter.status}
        text={t("filter_ask_delete", {
          filter : props.filter.active.name
        })}
        textBold={t("utils_next_ask")}>
      </ModalConfirm>
      }
      
      <p><b>{t("filters_custom")}</b></p>

      { props.withInfo &&
      <div className= "grey-t">{t("filters_help")}</div>
      }

      { props.filter.list.length > 0 ?
      <div className="flex flex-wrap">
  
        { props.filter.list.filter((filter: Filter) => !filter.isTemplateFilter).map((x: Filter)=>
        <div key={x.id} className="flex">
          <Dropdown
            active={props.attributes[x.name] ? props.attributes[x.name] : undefined}
            customButtonLabel="attribute_add"
            displayField="name"
            isNullAllowed
            list={x.populations.map((x:Population | FilterPopulation)=>{return{id: x.name, name: x.name}})}
            onAdd={props.isEditable ? () => {
              store.dispatch(filterActivate(x));
              setCurrentModal(MODAL_ADD_ATTRIBUTE)
            } : undefined}
            onSelect={(item: any)=>props.onEdit(x.name, item.name)}
            title={x.name}
            value={props.attributes[x.name] ? props.attributes[x.name] : undefined}
          />

          <div className="flex flex-dcol">
            <div style={{height:"54px"}}/>
            <ListButton text={t("filter_delete")}
              icon={faTimes}
              onClick={() => deleteFilter(x)}
            />
          </div>

          <div className="width-10"/>
        </div>
        )}

      </div>
      :null
      }

      <div className="height-20"/>

      <div>
        {props.isEditable ?
        <Button isWithBorder
          isFullWidth
          className="secondary"
          onClick={()=>setCurrentModal(MODAL_ADD_FILTER)}
        >
          {t("filter_add")}
        </Button>
        :
        <div>{t("filter_edit_name") + " "}<u className="_hover grey-t" onClick={()=>navigate("/adm/filters")}>{t("utils_here")}</u></div>
        }
      </div>
    </div>
  )
  
}

const mapStateToProps = state => ({
  filter : state.filter
})

export default connect(mapStateToProps)(withTranslation()(UserFilterInfoWidget))