
/** 
 * DATABASE-HOST
 * Check which database is used for data (in both backend and reporting)
 */

import { store } from "@/index";
import Card from "@/components/card";
import { useEffect, useState } from "react";
import { supervisorGetHost } from "@/redux/supervisor.actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { getHost_Archive } from "@/redux/_archive.actions";


function DatabaseHostWidget() {

  const [backendHost, setBackendHost] = useState<string | undefined>(undefined)
  const [reportingHost, setReportingHost] = useState<string | undefined>(undefined)
  const [isBackendLoading, setIsBackendLoading] = useState<boolean>(true)
  const [isReportingLoading, setIsReportingLoading] = useState<boolean>(true)

  useEffect(() => {
    async function loadBackendHost(){
      const response: any = await store.dispatch(supervisorGetHost())

      if(!response.error){
        setBackendHost(response.host)
      }

      setIsBackendLoading(false)
    }

    async function loadReportingHost(){
      const response: any = await store.dispatch(getHost_Archive())

      if(!response.error){
        setReportingHost(response.host)
      }

      setIsReportingLoading(false)
    }

    loadBackendHost()
    loadReportingHost()
  }, [])

  return (
    <Card>
      <div className="flex">
        <div>{"Backend : "}</div>
        { isBackendLoading ?
        <FontAwesomeIcon icon={faCircleNotch} spin />
        :
        <div>{backendHost}</div>
        }
      </div>

      <div className="flex">
        <div>{"Reporting : "}</div>
        { isReportingLoading ?
        <FontAwesomeIcon icon={faCircleNotch} spin />
        :
        <div>{reportingHost}</div>
        }
      </div>
    </Card>
  )

}

export default DatabaseHostWidget