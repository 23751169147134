/**
 * Lesson
 */

import { faFile, faHandPointer, faPencilAlt, faQuestionCircle, IconDefinition } from "@fortawesome/free-solid-svg-icons"
import env from "@/env"
import i18n from "@/translate/i18n"
import videoCorrespondance from "@/utils/video-correspondance"

export type LessonTypes = "file" | "link" | "text"

export class Lesson{
  id : string = ""
  name : any = {}
  description : any = {}
  duration : number = 0
  image : boolean = false
  new : boolean = false
  visible : boolean = false
  fromSupervisor : boolean = false
  type : LessonTypes = "text"                    //file or link or text
  file : any = null                         //blob
  link : string | null = null               //varchar
  text : any = {}                           //jsonb
  languages : string[] = [i18n.language]
  done : number = 0
  createdAt : string = ""
  updatedAt : string | null = null
  AccountId : string | null = null
  CoachId : string | null = null
  tags : string[] = []


  constructor(lesson: Partial<Lesson> = {}){
    if (!lesson) lesson = new Lesson()
    Object.assign(this, lesson)
  }

  get defaultLanguage():string{
    return this.isLangSupported(i18n.language)?i18n.language:this.languages[0]
  }

  get label():string{
    return this.name[this.defaultLanguage]
  }

  get icon():IconDefinition{
    switch(this.type){
      case "file" : 
      return faFile
      case "link" :
      return faHandPointer
      case "text" : 
      return faPencilAlt
      default : 
      return faQuestionCircle
    }
  }

  get picture():string{
    return this.image ? env.REACT_APP_URL_SPACE.concat("/Lessons/Thumbnails/", this.id, ".png?" + new Date().getTime()) : require("@/assets/trombone.png")
  }

  get filePath():string{
    return this.file ? env.REACT_APP_URL_SPACE.concat("/Lessons/Files/", this.id, this.file.extension+ "?" + new Date().getTime()) : require("@/assets/sherlock.png")
  }

  //temp getter for system videos only
  //return link from digitalocean prod
  get videoLinkProd(){
    return videoCorrespondance.find((x:any)=>x.id === this.id)?.video
  }

  isLangSupported(lang:string):boolean{
    return this.languages.indexOf(lang)>-1
  }

  //return value of field in corresponding language (for multilang inputs only)
  //if replace, return text in default language else return empty string
  getValue(field : string, lang : string):string{
    if(this[field][lang]! && this[field][lang].length>0){
      return this[field][lang]
    }
    else{
      return ""
    }
  }

  //if text
  getContent(lang):any{
    if(this.text[lang]!){
      return this.text[lang]
    }
    else{
      return {"entityMap":{},"blocks":[{"key":"637gr","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}]};
    }
  }

}


export interface LessonState{
  active : Lesson
  list : Lesson[]
  status : string
}

export const LESSON_ACTIVATE : string = "LESSON_ACTIVATE"
export const LESSON_ADD : string = "LESSON_ADD"
export const LESSON_EDIT : string = "LESSON_EDIT"
export const LESSON_INIT : string = "LESSON_INIT"
export const LESSON_GET : string = "LESSON_GET"
export const LESSON_REMOVE : string = "LESSON_REMOVE"
export const LESSON_REPLACE : string = "LESSON_REPLACE"
export const LESSON_STATUS : string = "LESSON_STATUS"