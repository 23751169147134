/** 
 * LESSON.ACTIONS
 * Actions about lesson
 */


import { Lesson, LESSON_ACTIVATE, LESSON_ADD, LESSON_EDIT, LESSON_INIT, LESSON_GET, LESSON_REMOVE, LESSON_REPLACE, LESSON_STATUS } from "./lesson.types"
import { STATUS_SAVED, STATUS_SAVE_ERROR, STATUS_SAVING } from "./_status.types"


/* REDUX */

export const lessonActivate = (lesson: Lesson) => ({
  type : LESSON_ACTIVATE,
  payload : {
    lesson
  }
})

export const lessonAdd = (lesson : Lesson) => ({
  type : LESSON_ADD,
  payload : {
    lesson
  }
})

export const lessonEdit = (key: string, value: any) => ({
  type : LESSON_EDIT,
  payload : {
    key,
    value
  }
})

export const lessonInit = () => ({
  type : LESSON_INIT
})

export const lessonGet = (lessons: Lesson[]) => ({
  type : LESSON_GET,
  payload : {
    lessons
  }
})

export const lessonRemove = (id: string) => ({
  type : LESSON_REMOVE,
  payload : {
    id
  }
})

export const lessonReplace = (lesson: Lesson) => ({
  type : LESSON_REPLACE,
  payload : {
    lesson
  }
})

export const lessonStatus = (status: string) => ({
  type : LESSON_STATUS,
  payload : {
    status
  }
})


/** API */

export const lessonDestroy = (id:string) => ({
  type : 'API',
  payload : {
    method : "DELETE",
    url : "/lesson/" + id
  }
})

export const lessonDestroy_AsSupervisor = (id:string) => ({
  type : 'API',
  payload : {
    method : "DELETE",
    url : "/supervisor/lesson/" + id
  }
})

export const lessonFetch = (search:string, limit:number, offset:number, fromSupervisor:boolean, visible:boolean | null) => ({
  type : 'API',
  payload : {
    method : "GET",
    url : "/lessons",
    data : {
      search,
      limit,
      offset,
      fromSupervisor,
      visible
    }
  }
})

export const lessonFetchLearnings = (lessonId: string) => ({
  type : 'API',
  payload : {
    method : "GET",
    url : "/lesson/learnings",
    data : {
      lessonId
    }
  }
})

export const lessonFetchLearnings_AsSupervisor = (lessonId: string) => ({
  type : 'API',
  payload : {
    method : "GET",
    url : "/supervisor/lesson/learnings",
    data : {
      lessonId
    }
  }
})


export const lessonFetch_AsSupervisor = (search:string, limit:number | null, offset:number, visible:boolean | null) => ({
  type : 'API',
  payload : {
    method : "GET",
    url : "/supervisor/lessons",
    data : {
      search,
      limit,
      offset,
      visible
    }
  }
})

export const lessonUpdate = (lesson: Lesson) => ({
  type : 'API',
  payload : {
    method : "POST",
    url : "/lesson/update",
    data : {
      lesson,
    }
  }
})

export const lessonUpdate_AsSupervisor = (lesson : Lesson) => ({
  type : 'API',
  payload : {
    method : "POST",
    url : "/supervisor/lesson/update",
    data : {
      lesson,
    }
  }
})

/* REACT-THUNK */

export const lessonAddOrReplaceAndInsertOrUpdate:any = (lesson: Lesson, asSupervisor: boolean, copy: boolean) => async (dispatch, getState) => {

  dispatch(lessonStatus(STATUS_SAVING))
  lesson.new = false
  if(copy){
    dispatch(lessonAdd(lesson))
  }
  else{
    dispatch(lessonReplace(lesson))
  }
  
  const response = asSupervisor ? await dispatch(lessonUpdate_AsSupervisor(getState().lesson.active)) : await dispatch(lessonUpdate(getState().lesson.active))
  dispatch(lessonStatus(response === getState().lesson.active.id? STATUS_SAVED : STATUS_SAVE_ERROR))

}