/** 
 * CONVERT-TO-SUPERACCOUNT.MODAL
 * Switch account from classic one to account for consultants
 */
import Modal from "@/components/modal"
import { connect } from "react-redux"
import { withTranslation, WithTranslation } from "react-i18next"
import { AccountState } from "@/redux/account.types";
import EnterCodeWidget from "@/widgets/enter-code.widget";
import { store } from "@/index";
import { accountConvertToSuperaccount, accountStatus } from "@/redux/account.actions";
import { STATUS_SAVING } from "@/redux/_status.types";
import { NavigateFunction, useNavigate } from "react-router-dom";

interface StateProps extends WithTranslation{
  account : AccountState
}

interface OwnProps{
  onClose : Function
}

type Props = StateProps & OwnProps

function ConvertToSuperAccount(props:Props) {
  const { t } = props
  const navigate: NavigateFunction = useNavigate()

  async function next(){
    store.dispatch(accountStatus(STATUS_SAVING))
    await store.dispatch(accountConvertToSuperaccount(props.account.active.id))
    navigate("/supervisor/accounts")
  }

  return (
    <Modal onClose={props.onClose}
      title={t("account_convert")}>

      <EnterCodeWidget onCancel={props.onClose}
        labelCta={t("utils_next")}
        onNext={next}
        status={props.account.status}
      />

    </Modal>

  )

}

const mapStateToProps = state => ({
  account : state.account
})

export default connect(mapStateToProps)(withTranslation()(ConvertToSuperAccount))