/**
 * FORMAT-DATE.UTILS
 * Replace occurence for email
 */

import { store } from "@/index"
import { DEFAULT_FILTERS, Filter } from "@/redux/filter.types"
import { t } from "@/translate/t"

const initFilters = () => {

  //Get current redux store
  const state = store.getState()

  //Get default filters
  let filters = DEFAULT_FILTERS.map(x => new Filter(x))

  if (state.survey.active.randomData){
    filters = filters.concat(new Filter({ name : t("filter_random_title") }))
  }else{
    filters = [
      new Filter({ 
        id : "company", 
        name : t("user_company_alt"),
        default : true
      })
    ].concat(filters.concat(state.filter.list))
  }

  if (state._session.userExcludedFilters){
    filters = filters.filter(x => state._session.userExcludedFilters.indexOf(x.name) === -1)
  }

  return filters

}

export default initFilters