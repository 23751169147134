/**
 * ADM-SETTINGS
 * Settings for customer account
 */
import NavApp from "@/nav/nav.app"
import AdmSettingsWidget from "@/widgets/adm-settings.widget";

function AdmSettingsRoute(){
  return(
    <NavApp>
      <AdmSettingsWidget/>
    </NavApp>
  )
}

export default AdmSettingsRoute
