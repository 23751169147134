const videoCorrespondance: {id: string, video: string}[] = [
  {
      "id": "6dcbb0fd-1fa6-45bb-ad19-ef956928ec5a",
      "video": "COD-2017-002-PL"
  },
  {
      "id": "25334beb-ad42-43ed-b8dd-41c5feb40ddd",
      "video": "FEE-2016-001-PL"
  },
  {
      "id": "ef8bddc3-8e5d-4bc6-87df-7ba2e497db72",
      "video": "ENC-2016-001-PL"
  },
  {
      "id": "548404e2-8d28-4418-ae4f-fd4a9a2d4327",
      "video": "MOT-2016-002-PL"
  },
  {
      "id": "701a40f0-6e57-40d4-a558-126e9509a633",
      "video": "DRA-2016-001-YC"
  },
  {
      "id": "ca7933e9-7bee-499e-868f-a9449f29a570",
      "video": "FIE-2016-001-PL"
  },
  {
      "id": "7f41a24e-5571-4e4c-b12d-b6cd4badfdae",
      "video": "MOD-2016-001-YC"
  },
  {
      "id": "a70c3dec-0f3a-4619-a936-a8c719171535",
      "video": "REC-2016-001-PL"
  },
  {
      "id": "8a335285-fd50-4085-add0-8da8c065ab0a",
      "video": "UTI-2016-001-PL"
  },
  {
      "id": "4343218f-cc18-4906-89bd-d6910d8501dd",
      "video": "RES-2017-003-EM"
  },
  {
      "id": "65fac6e8-ded4-4372-b122-c429bcbc8f75",
      "video": "AUT-2016-001-MR"
  },
  {
      "id": "6e7e22dd-7af9-4e63-ae42-4bfc27a4acbb",
      "video": "LIB-2016-001-PL"
  },
  {
      "id": "ff535acb-ec1a-45a8-970c-634f8e5ab361",
      "video": "ENG-2016-004-RC"
  },
  {
      "id": "4acb2b6e-a274-49e5-9ab0-f4c19d62fff4",
      "video": "AUT-2016-006-MR"
  },
  {
      "id": "b74dfafa-3cdd-4845-b306-3c93e68c3f0a",
      "video": "ENG-2016-003-RC"
  },
  {
      "id": "6f8bb811-183f-4199-8819-bc0b86c18fdd",
      "video": "PRO-2016-001-PL"
  },
  {
      "id": "8d7e7781-a645-4b40-a1d4-2b30b996b49e",
      "video": "HEU-2016-002-PL"
  },
  {
      "id": "be5f9406-9ce3-4398-8875-4c4f3c80d0e4",
      "video": "AUT-2016-002-MR"
  },
  {
      "id": "44cfe5df-dc15-489a-b6bf-c26318f5c06b",
      "video": "AUT-2016-005-MR"
  },
  {
      "id": "8bd7a43c-6fc4-48c2-a09c-d91a069125df",
      "video": "EVA-2016-001-PL"
  },
  {
      "id": "58321e16-b056-4a14-a618-1eee270b2215",
      "video": "SEN-2016-002-PL"
  },
  {
      "id": "d3f9a686-fcc4-4de2-b494-60866ff95cdd",
      "video": "OBJ-2016-004-EM"
  },
  {
      "id": "24dd1559-36bb-4e9e-9980-41e773c92227",
      "video": "ORG-2016-001-EM"
  },
  {
      "id": "07f4d8a6-0faa-4965-9af3-7470d7b2e875",
      "video": "SEN-2016-004-RC"
  },
  {
      "id": "456a41d5-819c-4c72-af1f-0228307dd449",
      "video": "RES-2017-002-EM"
  },
  {
      "id": "caaa6de1-9bd3-4469-9ada-e2dc7f7fb965",
      "video": "VIS-2016-001-PL"
  },
  {
      "id": "645fd600-2d18-4ac6-b820-ed7f3c86b5fb",
      "video": "CFC-2017-001-PL"
  },
  {
      "id": "7849298d-45f5-4271-9f33-734d6802410d",
      "video": "OBJ-2016-002-EM"
  },
  {
      "id": "138faf9f-97c8-4b9f-a22a-735cde101461",
      "video": "AUT-2016-007-MR"
  },
  {
      "id": "1c0de9a4-5b40-4e8b-b1a6-fc72168d9d51",
      "video": "AUT-2016-004-MR"
  },
  {
      "id": "9cd47168-c325-4c37-9521-0ef353bfb8e3",
      "video": "ORG-2016-002-EM"
  },
  {
      "id": "fc3e1db2-1c25-4f07-9629-fd2814dd1404",
      "video": "BIE-2016-001-PL"
  },
  {
      "id": "576713ec-f521-4bfe-9ae5-8d7ddc5da126",
      "video": "ORG-2016-003-EM"
  },
  {
      "id": "ac7b23b4-a623-4d24-93bc-bfe2f045fb48",
      "video": "VIS-2016-001-RC"
  },
  {
      "id": "83b40fa9-dfd5-4cb6-8a98-9ce100217b43",
      "video": "PRX-2016-002-RC"
  },
  {
      "id": "01263bd9-fec8-48a2-a117-6dcaac42af86",
      "video": "PRX-2016-001-RC"
  },
  {
      "id": "3d5005a6-07c5-40f0-bfad-582f23ecc4ac",
      "video": "EFO-2017-001-PL"
  },
  {
      "id": "d669ead6-e3a7-44eb-a7a4-704de5d4f61b",
      "video": "PRX-2016-001-PL"
  },
  {
      "id": "52687020-cd30-4692-9e78-9e2ffcc0d103",
      "video": "VIS-2016-006-RC"
  },
  {
      "id": "0c68e31d-5eea-409e-88b5-faa7b8eaf035",
      "video": "SEN-2016-002-RC"
  },
  {
      "id": "822dd1ee-abee-4d6a-b786-431e61ab06aa",
      "video": "SEN-2016-003-RC"
  },
  {
      "id": "40d62ae5-36a2-4124-843c-8995abd3592e",
      "video": "MIS-2016-001-PL"
  },
  {
      "id": "24db47e5-3bbc-4ba3-92f6-6fbeae4557f9",
      "video": "STR-2016-002-EM"
  },
  {
      "id": "5ef469ac-68b0-47b9-b75a-b533bb4b1913",
      "video": "STR-2016-003-EM"
  },
  {
      "id": "3f7b371f-1181-47b2-bd09-fa21071aa57c",
      "video": "STR-2016-004-EM"
  },
  {
      "id": "ad169d46-d285-478a-ab9f-d44384332bea",
      "video": "SNC-2016-001-PL"
  },
  {
      "id": "f44c7d84-d460-46a4-9bf2-d938d705955d",
      "video": "VIS-2016-002-RC"
  },
  {
      "id": "1c5c50f8-273b-4845-8a10-7074397c8c6d",
      "video": "VIS-2016-003-RC"
  },
  {
      "id": "805a3da7-660d-4d2b-9fd6-3ef208cd8231",
      "video": "PRX-2016-003-RC"
  },
  {
      "id": "611be353-d438-4c5f-99f1-70ced2ddf715",
      "video": "BIE-2016-002-PL"
  },
  {
      "id": "9f83e7ac-f833-4c09-8cb9-e82529ce6120",
      "video": "OBJ-2016-005-EM"
  },
  {
      "id": "04e89a3e-c20b-42dc-84b7-563f99454b89",
      "video": "STR-2016-005-EM"
  },
  {
      "id": "c90e820e-1abc-414e-bdcb-89b45be86474",
      "video": "STR-2016-006-EM"
  },
  {
      "id": "f0ef507f-cb98-49b3-9b5d-4b848f6b6227",
      "video": "STR-2016-007-EM"
  },
  {
      "id": "db4c3533-526a-4216-b872-035659cb52b1",
      "video": "STR-2016-008-EM"
  },
  {
      "id": "0b2e02cc-83a1-4c50-a90b-6d8653cde8b0",
      "video": "APA-2016-001-PL"
  },
  {
      "id": "27443598-84da-4200-8ea3-d2b344059c47",
      "video": "CHA-2016-001-PL"
  },
  {
      "id": "2f5bdbf2-e23f-4a2d-8002-b83ceef74954",
      "video": "COM-2016-001-PL"
  },
  {
      "id": "94988fab-8214-452a-95e4-ff463f123efb",
      "video": "ENG-2016-002-RC"
  },
  {
      "id": "16b9ca66-c9f0-49b8-8b41-96df4004dbf1",
      "video": "ENG-2016-002-PL"
  },
  {
      "id": "f8820cd9-e6f2-442f-866c-cb3b06d1943f",
      "video": "HEU-2016-001-PL"
  },
  {
      "id": "f11ceea1-77f4-4468-93fc-d6fe5f231d2b",
      "video": "COA-2016-001-PL"
  },
  {
      "id": "349b35c9-698c-47fc-b746-330ee5ace137",
      "video": "OBS-2017-001-PL"
  },
  {
      "id": "16a81e80-3dcd-4f39-8cfd-812b4b3d78c2",
      "video": "SEN-2016-001-PL"
  },
  {
      "id": "5d304204-8937-4c89-bff1-66bbb9dfd35c",
      "video": "COO-2016-001-PL"
  },
  {
      "id": "ecbb9f4d-c421-4667-95fa-c5f56c1a7bb8",
      "video": "SER-2016-001-AF"
  },
  {
      "id": "a76a3308-2607-491a-b751-f703d7e77034",
      "video": "INT-2016-001-PL"
  },
  {
      "id": "67448c27-fdab-4d6c-b23c-68b09493c80a",
      "video": "OBJ-2016-003-EM"
  },
  {
      "id": "2efad86a-8071-4f63-ae35-5af78ed1f900",
      "video": "RES-2016-001-PL"
  },
  {
      "id": "715490d9-e13e-4961-b46d-d4dcd5659a65",
      "video": "CPT-2016-001-PL"
  },
  {
      "id": "fbce4637-e5f9-47c5-a4a9-4d244fe332f8",
      "video": "OUV-2017-001-PL"
  },
  {
      "id": "8f7a7259-a307-46a8-b541-bb822b7e1085",
      "video": "AMB-2017-001-PL"
  },
  {
      "id": "2da4d907-3b86-4775-b012-ef193803c4a9",
      "video": "RES-2017-001-EM"
  },
  {
      "id": "7bc57ef1-54d1-4810-ad08-22a13ebc883d",
      "video": "ENG-2016-001-RC"
  },
  {
      "id": "e0121d3e-caea-4aca-a53e-667948cb2179",
      "video": "AUT-2016-003-MR"
  },
  {
      "id": "abe7e6d4-59b5-4d86-9317-e1a84262dba3",
      "video": "CON-2016-001-PL"
  },
  {
      "id": "1f80ea88-1323-4a25-94f4-dbcbf0142913",
      "video": "OBJ-2016-001-EM"
  },
  {
      "id": "02aa3937-78fc-4548-9e8c-3ff11cd8349e",
      "video": "SEN-2016-001-RC"
  },
  {
      "id": "c5ec3ba3-1c19-48ce-a0b3-23a07f4176ef",
      "video": "OBJ-2016-006-EM"
  },
  {
      "id": "efee2069-c4e6-43a0-b4f0-f610ec5f3c17",
      "video": "PLA-2016-001-PL"
  },
  {
      "id": "25e6f365-9b5f-449a-bfd6-0f35cb8c30fa",
      "video": "ORG-2016-004-EM"
  },
  {
      "id": "c40f1e4f-dc5c-447c-8fc3-f0f664edfa82",
      "video": "EXI-2016-005-EM"
  },
  {
      "id": "d986e9a5-77e3-427f-9d56-eb63537501e9",
      "video": "EXI-2016-006-EM"
  },
  {
      "id": "85bc46cf-65e5-4c57-96c5-243c0fa13141",
      "video": "EXI-2016-003-EM"
  },
  {
      "id": "c24cfdf8-c6f2-4ea7-a1f8-f33e594ab770",
      "video": "EXI-2016-004-EM"
  },
  {
      "id": "a8d03638-0ae0-41e1-82d0-069cb0b06093",
      "video": "EXI-2016-001-PL"
  },
  {
      "id": "f6d8ae8e-6060-4845-b359-b3f51b68a1ec",
      "video": "EXI-2016-002-EM"
  },
  {
      "id": "99a3d768-b6d6-43eb-84f8-d039f63ea5e2",
      "video": "EXI-2016-001-EM"
  },
  {
      "id": "9d79cef8-c981-449e-b9dd-7fcbf9096243",
      "video": "CDT-2016-001-PL"
  },
  {
      "id": "c1b74c3a-373b-4a9c-8a96-62cd48e2e398",
      "video": "EXE-2016-001-PL"
  },
  {
      "id": "5ebc7df2-186b-4316-8270-586d5daf1b59",
      "video": "OBJ-2016-001-PL"
  },
  {
      "id": "5389e6dd-b382-4bb4-a2c2-e16d5401b4d8",
      "video": "RSL-2016-001-PL"
  },
  {
      "id": "e752a06c-16b8-4766-ba78-20c335cd3a3b",
      "video": "DEL-2016-003-RC"
  },
  {
      "id": "167e0938-90ae-4367-b1d4-34bbe7d0b357",
      "video": "DEL-2016-004-RC"
  },
  {
      "id": "dffd62a2-65b0-49b9-ae65-660aabc57b5a",
      "video": "DEL-2016-005-RC"
  },
  {
      "id": "92af2f65-7879-42ec-8d67-7d2c131b7014",
      "video": "DEL-2016-001-RC"
  },
  {
      "id": "3b9ec12d-3dab-4113-9cd7-62e9457fb653",
      "video": "DEL-2016-002-RC"
  },
  {
      "id": "2d14337b-359e-467a-b208-b222a9db5b11",
      "video": "VIS-2016-005-RC"
  },
  {
      "id": "6a102422-c493-4dc9-95d1-fc32cd376a8e",
      "video": "DVT-2017-001-PL"
  },
  {
      "id": "2b1f01bd-a3d5-458f-ac7a-654f28d6ec1d",
      "video": "DVT-2017-002-PL"
  },
  {
      "id": "a5a59db4-3d27-409e-817c-4d35ce08c49b",
      "video": "ESP-2016-001-PL"
  },
  {
      "id": "ddcf8522-13b5-4deb-ba1a-1dadaa1a8079",
      "video": "AUT-2016-001-PL"
  },
  {
      "id": "d63d482d-cfce-4e1b-ae3c-443cbe7d3ac1",
      "video": "SOL-2016-001-PL"
  },
  {
      "id": "37987676-d5f8-4878-b0a6-ec23f030d932",
      "video": "STR-2016-001-EM"
  },
  {
      "id": "be97bba0-fc84-4e8d-a091-5a9e3b258b07",
      "video": "COD-2017-001-PL"
  },
  {
      "id": "2145636c-1b55-4cc6-a1be-c87ec59817f3",
      "video": "EMO-2016-001-PL"
  },
  {
      "id": "f1a1bc17-4126-4207-af45-d6ca8df82e79",
      "video": "ECT-2016-001-PL"
  },
  {
      "id": "e5e0bbcd-a9b5-4198-adb0-4992ed7819c7",
      "video": "FCO-2016-001-PL"
  },
  {
      "id": "426f3abd-343a-4d43-a6a6-f7876e15c3d8",
      "video": "ENG-2016-001-PL"
  },
  {
      "id": "c4607ec6-d593-44d6-a441-1f6b66ab41a5",
      "video": "ACC-2018-002-PG"
  },
  {
      "id": "dd8335c7-2d0e-4093-9020-c1b682161944",
      "video": "ACC-2018-001-PG"
  },
  {
      "id": "483507bd-642d-406e-83e0-8175ec8cb86c",
      "video": "ACC-2018-003-PG"
  },
  {
      "id": "742774a9-1217-4829-8247-1afa82d5e633",
      "video": "ACC-2018-004-PG"
  },
  {
      "id": "45357278-5a15-44a4-a9bd-ac0f0268e1e5",
      "video": "SER-2016-001-PL"
  }
]

export default videoCorrespondance;