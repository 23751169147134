/** 
 * IMAGE-SELECTOR
 * Select an image
 */

import { faCheck } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { connect } from "react-redux"
import { Session } from "@/redux/_session.types"

interface ImageSelectorText{
  value : string
  top : number
  left : number
}

interface StateProps {
  _session : Session
}

interface OwnProps{
  height : number
  imageName : string //Should be in assets folder
  isActive : boolean //Is the image selected (display border and check)
  onClick : Function //Action on click
  width : number
  texts? : ImageSelectorText[] //List of text to display
}

type Props = StateProps & OwnProps

function ImageSelector(props:Props) {
  return (
    <div style={{ width : props.width, height : props.height }}
      onClick={() => props.onClick()}
      className="_hover image-selector rel">

      <img src={require("@/assets/" + props.imageName + ".png")}
        alt="layout1" width="100%"
        style={{ 
          borderColor : props.isActive ? props._session.accountColors.active : "white"
        }}>
      </img>

      { props.isActive &&
      <div className="image-selector-check white-t abs flex"
        style={{ backgroundColor : props._session.accountColors.active }}>
        <FontAwesomeIcon className="flex-auto" icon={faCheck}/>
      </div>
      }

      { props.texts &&
      props.texts.map((text: ImageSelectorText, i: number) =>
      <div
        key={i}
        className="abs image-selector-text grey-t"
        style={{
          top : text.top,
          left : text.left
        }}>
        {text.value}
      </div> 
      )
      }

    </div>
  )
}

const mapStateToProps = state => ({
  _session : state._session
})

export default connect(mapStateToProps)(ImageSelector)