/** 
 * TABS
 */
import { connect } from "react-redux"
import { Session } from "@/redux/_session.types"
import Chip from "./chip"

interface StateProps {
  _session : Session
}

interface OwnProps{
  onBlur? : Function
  onChange? : Function
  min : number
  max : number
  step? : number
  value : number
  hideValue? : boolean
}

type Props = StateProps & OwnProps

function Range(props:Props) {

  function blur(e: any){
    if(props.onBlur){
      props.onBlur(e.target.value)
    }
  }

  function change(e: any){
    if(props.onChange){
      props.onChange(e.target.value)
    }
  }

  return (
    <div className="flex">
      <input type="range"
        step={props.step}
        style={{ 
          width : 300,
          marginRight : 10
        }}
        onBlur={blur}
        onChange={change}
        min={props.min}
        max={props.max}
        value={props.value}>
      </input>
      { !props.hideValue &&
      <Chip>
        {props.value}
      </Chip>
      }
    </div>
    
  )

}

const mapStateToProps = state => ({
  _session : state._session
})

export default connect(mapStateToProps)(Range)