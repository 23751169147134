/** 
 * ENTER-CODE
 * Input to enter a code
 */
import ReactCodeInput from 'react-code-input';
import Space from './space';

interface OwnProps{
  onSetCode : Function
}

const CODE_LENGTH = 4

function EnterCode(props:OwnProps) {

  function changeCode(code){
    if (code.length === CODE_LENGTH){
      props.onSetCode(code)
    }
  }

  return (
    <div className="enter-code flex">
      <Space/>
      <ReactCodeInput fields={CODE_LENGTH} 
        name="" 
        inputMode="verbatim"
        onChange={(e) => changeCode(e)}
        />
      <Space/>
    </div>
  )

}

export default EnterCode