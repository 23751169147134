/**
 * MESSAGE.ACTIONS
 */
import { Message, MESSAGE_GET, MESSAGE_GET_COUNT, MESSAGE_STATUS } from "./message.types";


//REDUX

export const messageGet = (messages:Message[]) => ({
  type : MESSAGE_GET,
  payload : {
    messages
  }
})

export const messageGetCount = (count:number) => ({
  type : MESSAGE_GET_COUNT,
  payload : {
    count
  }
})

export const messageStatus = (status:string) => ({
  type : MESSAGE_STATUS,
  payload : {
    status
  }
})


//API

export const messageUpdateColor = (aid, color) => ({
  type : 'API',
  payload : {
    method : "POST",
    url : "/message/color",
    data : {
      aid,
      color
    }
  }
})

export const messageDestroy = aid => ({
  type : 'API',
  payload : {
    method : "DELETE",
    url : "/message/" + aid
  }
})