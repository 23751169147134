/***
 * PROJECT-SEGMENT.WIDGET
 * Send axes by populations
 */

import { connect } from "react-redux";
import { WithTranslation, withTranslation } from "react-i18next"
import { useEffect, useState } from "react";
import ReactTooltip from 'react-tooltip'
import { Session } from "@/redux/_session.types";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { store } from "@/index";
import UpgradeModal from "@/modals/upgrade.modal";
import { projectEdit, projectEditAxisRule } from "@/redux/project.actions";
import { ProjectAxisRule, ProjectState } from "@/redux/project.types";
import Space from "@/components/space";
import SelectPopulationsModal from "@/modals/select-populations.modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Checkbox from "@/components/checkbox";
import { axisFetch, axisGet } from "@/redux/axis.actions";
import { TemplateState } from "@/redux/template.types";
import { AxisState } from "@/redux/axis.types";
import ListItem from "@/components/list-item";
import ToggleButtons from "@/components/toggle-buttons";
import PageLoader from "@/components/page-loader";
import { STATUS_LOADED, STATUS_LOADING } from "@/redux/_status.types";
import { Population } from "@/redux/population.types";
import ConfirmButtons from "@/components/confirm-buttons";
import ProjectStepErrorsWidget from "./project-step-errors.widget";

interface StateProps extends WithTranslation{
  _session : Session
  axis : AxisState
  project : ProjectState
  template : TemplateState
}

interface OwnProps {
  onSelect : Function
  onNext : Function
}

type Props = StateProps & OwnProps

//Modals
const MODAL_POPULATIONS:string = "MODAL_POPULATIONS" //Display list of populations to select
const MODAL_UPGRADE_SEGMENTATION:string = "MODAL_UPGRADE_SEGMENTATION" //Lock segmentation

function ProjectSegmentWidget(props:Props){
  const { t } = props

  //Current axisRules to edit
  const [currentAxisRule, setCurrentAxisRule] = useState(new ProjectAxisRule())

  //Current modal displayed
  const [currentModal, setCurrentModal] = useState<string | null>(null)

  //Loading templates
  const [isLoading, setIsLoading] = useState(true)

    //Load topics when segmentation is active
    useEffect(() => {
    
    if (props.project.active.isSegmentationActive){
      load()
    }

    async function load(){

      if (props.template.active.id.length){
        setIsLoading(true)
        const axes:any = await store.dispatch(axisFetch(props.template.active.id, null))
        store.dispatch(axisGet(axes.error ? [] : axes, props.template.active.axesOrder))
      }else{
        store.dispatch(axisGet([], []))
      }
      
      setIsLoading(false)

    }

  }, [
    props.template.active.id,
    props.project.active.isSegmentationActive,
    props.template.active.axesOrder
  ])

  //Reset values
  function cancel(){
    store.dispatch(projectEdit("segmentationByAxesRules", []))
    store.dispatch(projectEdit("isSegmentationActive", false))
    select()
    next(false, false)
  }


  function next(isVisited, isConfirmed){
    props.onNext(isVisited, isConfirmed)
  }

  function select(){
    props.onSelect()
  }

  //Click on axis in order to select population or reset
  function selectAxis(buttonChoice: string, axisId: string){
    if (buttonChoice === "A"){
      store.dispatch(projectEdit("segmentationByAxesRules", props.project.active.segmentationByAxesRules.filter(x => x.axisId !== axisId)))
      select()
    }else if (buttonChoice === "B"){
      setCurrentAxisRule(new ProjectAxisRule({ axisId, populations : props.project.active.getAxisRulePopulations(axisId)}))
      setCurrentModal(MODAL_POPULATIONS)
    }
  }

  //Select populations
  function selectPopulations(populations:Population[]){
    store.dispatch(projectEditAxisRule(currentAxisRule.axisId, populations))
    select()
  }

  //Activate segmentation
  function selectSegmentation(activateSegmentation:boolean){
    if (!activateSegmentation){
      store.dispatch(projectEdit("isSegmentationActive", false))
      select()
    }else{
      store.dispatch(projectEdit("isSegmentationActive", true))
      select()
    }
  }

  return (
    <div>

      { currentModal === MODAL_POPULATIONS &&
      <SelectPopulationsModal isTemplateFilterLoaded
        isWithoutDefaultFilters
        onClose={() => setCurrentModal(null)}
        onNext={(populations:Population[]) => selectPopulations(populations)}
        selectedPopulations={currentAxisRule.populations}
      />
      }

      { currentModal === MODAL_UPGRADE_SEGMENTATION &&
      <UpgradeModal feature="segment"
        onClose={() => setCurrentModal(null)}
      />
      }

      <ListItem>
        <div className="setup-card-help">
          <p>
            {t("project_segment_help")}
          </p>
          <p className="grey-t setup-card-help-content">
            {t("project_segment_help_1")}
          </p>
          <p className="grey-t setup-card-help-content">
            {t("project_segment_help_2")}
          </p>
        </div>
      </ListItem>

      <ListItem>
        { props._session.modules.segment 
        ?
        <Checkbox text={t("project_segmentation_active")}
          active={props.project.active.isSegmentationActive}
          onClick={() => selectSegmentation(!props.project.active.isSegmentationActive)}
        />
        :
        <div className="grey-t _hover"
          onClick={() => setCurrentModal(MODAL_UPGRADE_SEGMENTATION)}
          style={{ margin : "12px 0px 12px 38px" }}>
          <FontAwesomeIcon icon={faLock}/>
          &nbsp;
          {t("project_segmentation_inactive")}
        </div>
        }
        <Space/>
      </ListItem>

      { props.project.active.isSegmentationActive && 
      <PageLoader status={isLoading ? STATUS_LOADING : STATUS_LOADED}>

        { props.axis.list.map(axis => 
        <ListItem key={axis.id}>

          <span style={{ color : axis.color, margin : 'auto' }}>
            <b>{axis.label}</b>
          </span>

          <Space/>

          <ToggleButtons activeToogle={props.project.active.getAxisRule(axis.id) ? "B" : "A"}
            onToogle={(buttonChoice: string) => selectAxis(buttonChoice, axis.id)}
            textA={t("project_segmentation_a")}
            textB={t("project_segmentation_b")}
            childrenB={
              props.project.active.getAxisRule(axis.id)
              ?
              <ul className="dark-t" style={{ fontSize : "12px", marginBottom : "0px" }}>
                { props.project.active.getAxisRulePopulations(axis.id).map(population =>
                <li key={population.id}>
                  {population.filterName} : <b>{population.name + "  "}</b>
                </li>
                )}
              </ul>
              :
              undefined
            }
          />

          <ReactTooltip/>

        </ListItem>
        )
        }

      </PageLoader>
      }
      
      <ProjectStepErrorsWidget alert={props.project.active.alerts.segment} step="segment"/>

      <ConfirmButtons onCancel={() => cancel()}
        onConfirm={() => next(true, true)}
        onConfirmLater={() => next(true, false)}
      />

    </div>
  )
}

const mapStateToProps = state => ({
  _session : state._session,
  axis : state.axis,
  project : state.project,
  template : state.template
})

export default connect(mapStateToProps)(withTranslation()(ProjectSegmentWidget))
