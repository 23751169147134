/** 
 * TEMPLATES.WIDGET
 * Display template card
 */

import { withTranslation, WithTranslation } from "react-i18next"
import { connect } from "react-redux"
import CardButton from "@/components/card-button";
import { faClipboard, faCopy, faEdit, faEye, faList, faPen, faPlay, faPlusCircle, faTrash } from "@fortawesome/free-solid-svg-icons";
import env from "@/env";
import DuplicateTemplateModal from "@/modals/duplicate-template.modal";
import { Session } from "@/redux/_session.types";
import DeleteTemplateModal from "@/modals/delete-template.modal";
import { topicFetchAndGet, topicFetchPublic, topicGet } from "@/redux/topic.actions";
import TemplatePreviewHelp from "@/help/template-preview.help";
import RenameTemplateModal from "@/modals/rename-template.modal";
import { useState } from "react";
import { store } from "@/index";
import { templateActivate } from "@/redux/template.actions";
import { Template } from "@/redux/template.types";
import testQuickvote from "@/utils/test-quickvote.utils";
import LoadingModal from "@/modals/loading.modal";
import { projectEdit } from "@/redux/project.actions";
import ProjectAddModal from "@/modals/project-add.modal";
import Modal from "@/components/modal"
import TemplateQuestionsWidget from "./template-questions.widget";
import getEmoji from "@/utils/get-emoji.utils";
import { Language } from "@/redux/language.types";
import getLanguagesUtil from "@/utils/get-languages.utils";
import { NavigateFunction, useNavigate } from "react-router-dom";
 
interface StateProps extends WithTranslation{
  _session : Session
}

interface OwnProps{
  currentTemplate : Template
  isActive?: boolean
  switchForProject? : boolean
  onSelect? : Function
  showLanguageFlags?: boolean
}

type Props = StateProps & OwnProps

//Actions
const ACTION_CREATE_PROJECT: string = 'ACTION_CREATE_PROJECT' //Create new project from template
const ACTION_DELETE: string = 'ACTION_DELETE' //Delete a template (prompt confirm modal)
const ACTION_DUPLICATE: string = 'ACTION_DUPLICATE' //Duplicate a template 
const ACTION_QUESTIONS: string = 'ACTION_QUESTIONS' //Go to editor
const ACTION_RENAME: string = 'ACTION_RENAME' //Rename a template
const ACTION_PREVIEW: string = 'ACTION_PREVIEW' //Open preview in side menu
const ACTION_SHOW: string = 'ACTION_SHOW' //Show every template questions in a modal
const ACTION_TEST: string = 'ACTION_TEST' //Open quickvote in other browser tab

//Tutoriel
const HELP_PREVIEW: string = 'HELP_PREVIEW' //Show preview

//Modals
const MODAL_ADD: string = 'MODAL_ADD' //Add new questionnaire
const MODAL_DELETE: string = 'MODAL_DELETE' //Modal of confirmation before delete
const MODAL_DUPLICATE: string = 'MODAL_DUPLICATE' //Enter name to create a copy
const MODAL_LOADING: string = 'MODAL_LOADING' //Load dashboard demo
const MODAL_QUESTIONS: string = 'MODAL_QUESTIONS' //Modal questions
const MODAL_RENAME: string = 'MODAL_RENAME' //Modify name

function TemplateWidget(props:Props) {
  const { t } = props
  const navigate: NavigateFunction = useNavigate()

  const [currentHelp, setCurrentHelp] = useState<string | null>(null)
  const [currentModal, setCurrentModal] = useState<string | null>(null)

  //Clic on action of context menu
  //Set template active and execute action
  function clickAction (actionId){
    store.dispatch(templateActivate(props.currentTemplate))

    switch (actionId){
      case ACTION_CREATE_PROJECT:
        if (props._session.interfaceType === "SUPERADMIN"){
          return navigate("/project/list")
        }else{
          return setCurrentModal(MODAL_ADD)
        }
      case ACTION_DELETE:
        return setCurrentModal(MODAL_DELETE)
      case ACTION_DUPLICATE:
        return setCurrentModal(MODAL_DUPLICATE)
      case ACTION_PREVIEW:
        return loadPreview(props.currentTemplate.id)
      case ACTION_QUESTIONS:
        return getTemplate()
      case ACTION_RENAME:
        return setCurrentModal(MODAL_RENAME)
      case ACTION_SHOW:
        return setCurrentModal(MODAL_QUESTIONS)
      case ACTION_TEST:
        return testQuickvote(props._session.language)
      default:
        return alert(actionId + " : à venir")
    }

  }

  //Clic on template
  //If project defined create project from template
  //If switch defined change template for the active project
  //If not go to editor
  function clickTemplate(){
    store.dispatch(templateActivate(props.currentTemplate))
    if (props.switchForProject){
      switchProjectTemplate()
    } else{
      getTemplate()
    }
  }

  //Return list of actions possible for a template
  //If template if from the account, possible to rename and delete
  function getActions(){

    const actions = [
      { id : ACTION_CREATE_PROJECT, label : t("project_add"), icon : faPlusCircle },
      { id : ACTION_SHOW, label : t("questions_get"), icon : faList },
      { id : ACTION_QUESTIONS, label : t("onboarding_tag_editor"), icon : faPen },
      { id : ACTION_DUPLICATE, label : t("utils_duplicate"), icon : faCopy },
      { id : ACTION_TEST, label : t("template_test"), icon : faPlay },
      { id : ACTION_PREVIEW, label : t("dashboard_preview"), icon : faEye }
    ]

    if (props.currentTemplate.fromSupervisor){
      return actions
    }else{
      return actions.concat([
        { id : ACTION_RENAME, label : t("utils_rename"), icon : faEdit },
        { id : ACTION_DELETE, label : t("utils_delete"), icon : faTrash }
      ])
    }

  }

  //Clic on template or action "see questions"
  //Go to editor page
  function getTemplate(){
    store.dispatch(topicFetchAndGet(null))
    navigate("/template/editor")
  }

  //Load preview for dashboard
  async function loadPreview(templateId){

    setCurrentModal(MODAL_LOADING)
    const topics:any = await store.dispatch(topicFetchPublic(templateId))
    store.dispatch(topicGet(topics.error ? [] : topics))

    setTimeout(() => {
      setCurrentHelp(HELP_PREVIEW)
      setCurrentModal(null)
    }, 200)

  }

  //Change template for project
  function switchProjectTemplate(){
    store.dispatch(projectEdit("TemplateId", props.currentTemplate.id))
    if (props.onSelect){
      props.onSelect(props.currentTemplate.id)
    }
  }

  return (
    <div>

      { currentHelp === HELP_PREVIEW &&
      <TemplatePreviewHelp onClose={() => setCurrentHelp(null)}/>
      }
      
      { currentModal === MODAL_ADD &&
      <ProjectAddModal onClose={() => setCurrentModal(null)}/>
      }

      { currentModal === MODAL_DELETE &&
      <DeleteTemplateModal onClose={() => setCurrentModal(null)}/>
      }
      
      { currentModal === MODAL_DUPLICATE &&
      <DuplicateTemplateModal
        onClose={() => setCurrentModal(null)}
      />
      }

      { currentModal === MODAL_LOADING &&
      <LoadingModal/>
      }

      { currentModal === MODAL_QUESTIONS &&
      <Modal onClose={()=>setCurrentModal(null)}
        title={props.currentTemplate.label}
        isCloseButtonVisible
        isLarge>
        <TemplateQuestionsWidget
          currentTemplateId={props.currentTemplate.id}
          language={props.currentTemplate.language}
        />
      </Modal>
      }

      { currentModal === MODAL_RENAME &&
      <RenameTemplateModal onClose={() => setCurrentModal(null)}/>
      }

      <CardButton key={props.currentTemplate.id}
        actions={getActions()}
        icon={faClipboard}
        image={props.currentTemplate.image ? env.REACT_APP_URL_SPACE.concat("/Templates/", props.currentTemplate.image, ".png") : null}
        isActive={props.isActive}
        title={props.currentTemplate.label}
        onAction={(actionId) => clickAction(actionId)}
        subtitle={props.currentTemplate.fromSupervisor ? undefined : t("utils_updated_at", { date : props.currentTemplate.updatedAtLabel })}
        onClick={clickTemplate}>
      </CardButton>

    
      { props.showLanguageFlags &&
      <div className="rel flex flex-wrap template-lang-icons" data-tip={t("available_languages", {
        languages : getLanguagesUtil(props.currentTemplate.languages).map((x: Language)=>x.name).join(", "),
        s : props.currentTemplate.languages.length > 1 ? "s" : ""
      })}>
        {props.currentTemplate.languages.map((x: string)=>
        <div key={x}>
          {getEmoji(x)}
        </div>
        )}
      </div>
      }
    

    </div>
  )

}

const mapStateToProps = state => ({
  _session : state._session
})

export default connect(mapStateToProps)(withTranslation()(TemplateWidget))