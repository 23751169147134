/**
 * CUSTOMER FEEDBACK ACTION
 */

import { User } from "./user.types"

export class Feedback{
  createdAt:Date = new Date()
  id:string = ""
  isAnswered:boolean = false
  message:string|null = null
  noteProduct:number|null = null
  noteService:number|null = null
  User:User = new User()

  constructor(item: Partial<Feedback> = {}){
    if (!item) item = new Feedback()
    item.User = item.User ? new User(item.User) : new User()
    item.createdAt = item.createdAt ? new Date(item.createdAt) : new Date()
    Object.assign(this, item)
  }

}