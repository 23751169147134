/** 
 * SURVEY.TEMPLATE
 * Edit params for a template
 */

import { useState } from "react"
import { connect } from "react-redux"
import { withTranslation, WithTranslation } from "react-i18next"
import { Template, TemplateState, TEMPLATES_TAGS } from "@/redux/template.types";
import DuplicateTemplateAskModal from "@/modals/duplicate-template-ask.modal";
import Checkbox from "@/components/checkbox";
import Card from "@/components/card";
import { store } from "@/index";
import { templateEdit, templateEditAndUpdate, templateEditDescription, templateEditLanguageAndUpdate, templateEditName, templateStatus, templateUpdate, templateUpdate_AsSupervisor } from "@/redux/template.actions";
import { STATUS_SAVED, STATUS_SAVE_ERROR, STATUS_SAVING } from "@/redux/_status.types";
import TextInput from "@/components/text-input";
import ImagePicker from "@/components/image-picker";
import { Session } from "@/redux/_session.types";
import NavFocus from "@/nav/nav.focus";
import PageHeader from "@/components/page-header";
import Space from "@/components/space";
import Tabs from "@/components/tabs";
import EditDisplayWidget from "@/widgets/edit-display.widget";
import LanguageSelection from "@/components/language-selection";
import Chip from "@/components/chip";
import { Language } from "@/redux/language.types";
import { Topic, TopicState } from "@/redux/topic.types";
import { Axis, AxisState } from "@/redux/axis.types";
import { Question } from "@/redux/question.types";
import { Proposition } from "@/redux/proposition.types";
import { axisUpdate } from "@/redux/axis.actions";
import { topicUpdate } from "@/redux/topic.actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import TranslatorWidget from "@/widgets/translator.widget";
import ContextMenu from "@/components/context-menu";
import TemplateOptionsWidget from "@/widgets/template-options.widget";
import EditorOptionsWidget from "@/widgets/editor-options.widget";

interface StateProps extends WithTranslation{
  _session : Session
  axis : AxisState
  template : TemplateState
  topic : TopicState
}

const TOPICS_CHUNKS_SIZE: number = 10

const CONTEXT_AUTO_TRANSLATION: string = "CONTEXT_AUTO_TRANSLATION"
const MODAL_DUPLICATE_ASK: string = "MODAL_DUPLICATE_ASK" //Modal for duplicate

function SurveyTemplate(props:StateProps) {
  const { t } = props

  const [currentModal, setCurrentModal] = useState<string | null>(null)
  const [currentTab, setCurrentTab] = useState(props.template.active.language)
  const [translateStatus, setTranslateStatus] = useState<string | null>(null)

  //Edit template and save just after
  function editTemplate(key, value){
    store.dispatch(templateEditAndUpdate(key, value))
  }

  //Get tabs
  function getTabs(){
    const tab = {}
    props.template.active.languages.forEach(lg => {
      tab[lg] = lg.toUpperCase()
    })
    return tab
  }
  
  //Check if template is editable
  //If template of idtree and connected as customer, ask for duplicate
  function isTemplateLock(){
    return props._session.interfaceType !== "SUPERVISOR" && props.template.active.fromSupervisor
  }

  function addLanguage(language: string){
    store.dispatch(templateEditLanguageAndUpdate(language))
    setCurrentTab(language)
  }

  function editOptions(key, value){
    store.dispatch(templateEditAndUpdate("options", Object.assign({}, props.template.active.options, { [key] : value })))
  }

  function selectLanguage(item : string){
    store.dispatch(templateEdit("language", item))
    setCurrentTab(item)
  }

  function getTextsToTranslate(): string[]{
    const texts: string[] = []

    //Topics (includes questions and propositions)
    props.topic.list.forEach((topic: Topic)=>{
      texts.push(topic.name)
      topic.Questions.forEach((question: Question)=>{
        texts.push(question.name)
        question.Propositions!.forEach((proposition: Proposition)=>{
          texts.push(proposition.name)
        })
      })
    })

    //Axes
    props.axis.list.forEach((axis: Axis)=>{
      texts.push(axis.name)
    })

    //Template
    texts.push(props.template.active.name)
    texts.push(props.template.active.description)

    return texts
  }

  async function translateTemplateTexts(texts: string[], language: Language){
    let index : number = 0

    setTranslateStatus(t("starting_translation"))

    //Topics (includes questions and propositions)
    const topicsTranslated: Topic[] = props.topic.list
    topicsTranslated.forEach((topic: Topic)=>{
      //edit topic
      topic.name[language.code] = texts[index]
      index++
      topic.Questions.forEach((question: Question)=>{
        //edit question
        question.name[language.code] = texts[index]
        question.updated = true
        index++
        question.Propositions!.forEach((proposition: Proposition)=>{
          //edit proposition
          proposition.name[language.code] = texts[index]
          proposition.updated = true
          index++
        })
      })
    })


    for(let j: number = 0; j < topicsTranslated.length; j += TOPICS_CHUNKS_SIZE){
      setTranslateStatus(t("translating_topics") + " " + j + "/" + topicsTranslated.length )
      await store.dispatch(topicUpdate(props.template.active, topicsTranslated.slice(j, j + TOPICS_CHUNKS_SIZE), []))
    }

    //Axes
    const axesTranslated: Axis[] = props.axis.list
    axesTranslated.map(async(axis: Axis)=>{
      axis.name[language.code] = texts[index]
      index++
    })

    for(let k: number = 0; k < axesTranslated.length; k++){
      setTranslateStatus(t("translating_axes") + " " + k + "/" + axesTranslated.length )
      await store.dispatch(axisUpdate(axesTranslated[k], props.template.active.id))
    }


    //Template
    const templateTranslated: Template = props.template.active
    templateTranslated.name[language.code] = texts[index]
    index++
    templateTranslated.description[language.code] = texts[index]
    setTranslateStatus(t("translating_template"))
    await store.dispatch(templateUpdate(templateTranslated))
    setTranslateStatus(null)
  }

  function removeLanguage(language:string){
    store.dispatch(templateEditLanguageAndUpdate(language))
    resetTab()
  }

  //Reset tabs
  //Set first tab as default
  function resetTab(){
    setCurrentTab(props.template.active.languages[0])
  }

  //Save data
  //Function different if supervisor or not
  async function save(){
    if (isTemplateLock()){
      setCurrentModal(MODAL_DUPLICATE_ASK)
    }else{
      const updateFunction = props._session.interfaceType === "SUPERVISOR" ? templateUpdate_AsSupervisor : templateUpdate
      store.dispatch(templateStatus(STATUS_SAVING))
      const response:any = await store.dispatch(updateFunction(props.template.active))
      store.dispatch(templateStatus(response.error ? STATUS_SAVE_ERROR : STATUS_SAVED))
    }
  }
  
  return (
    <NavFocus>

      { currentModal === MODAL_DUPLICATE_ASK &&
      <DuplicateTemplateAskModal onClose={() => setCurrentModal(null)}/>
      }

      <PageHeader title={t("template_params")}/>

      { props._session.interfaceType === "SUPERVISOR"
      ?
      <Card title={t("supervisor_params")}>

        { /** si plusieurs langues alors onglets */
        props.template.active.languages.length > 1
        &&
        <Tabs active={currentTab}
          tabs={getTabs()}
          onClick={(tab:string) => selectLanguage(tab)}
        />
        }

        <TextInput onBlur={save}
          onChange={(e) => store.dispatch(templateEditName(currentTab, e.value))}
          status={props.template.status}
          title={t("template_name")}
          value={props.template.active.name[currentTab]}
        />
        
        <TextInput onBlur={save}
          isFullWidth
          onChange={(e) => store.dispatch(templateEditDescription(currentTab, e.value))}
          status={props.template.status}
          title={t("utils_description")}
          value={props.template.active.description[currentTab]}
        />

        <Checkbox active={props.template.active.draft}
          status={props.template.status}
          text={t("template_supervisor_draft")}
          onClick={() => editTemplate("draft", !props.template.active.draft)}
        />

        <Checkbox active={props.template.active.free}
          status={props.template.status}
          text={t("template_supervisor_free")}
          onClick={() => editTemplate("free", !props.template.active.free)}
        />

        <p className="grey-t">
          {t("template_tag")}
        </p>

        <div className="flex">
          {TEMPLATES_TAGS.map((tag, i) =>
          <Chip key={i}
            onClick={() => editOptions("tag", tag)}
            color={props.template.active.options.tag === tag ? props._session.accountColors.active : ""}
            isWithMargin>
            {t("template_tag_" + tag)}
          </Chip>
          )}
        </div>

      </Card>
      :
      <Card title={t("utils_informations")}>
        
        { /** si plusieurs langues alors onglets */
        props.template.active.languages.length > 1
        &&
        <Tabs active={currentTab}
          tabs={getTabs()}
          onClick={(tab:string) => selectLanguage(tab)}
        />
        }

        <TextInput onBlur={save}
          onChange={(e: any) => store.dispatch(templateEditName(currentTab, e.value))}
          status={props.template.status}
          title={t("template_name")}
          value={props.template.active.name[currentTab]}
        />

      </Card>
      }

      <TemplateOptionsWidget/>

      <EditorOptionsWidget/>

      <Card title={t("utils_edit_display")}>
        <p className="grey-t">
          {t("utils_edit_display_help")}
        </p>
        <EditDisplayWidget
          checkboxes={[
            { id : "editorDisplayTopic", label : t("topic") },
            { id : "editorDisplayPrimary", label : t("question_priority") },
            { id : "editorDisplayQuestion", label : t("question") },
            { id : "editorDisplayTrigger", label : t("question_trigger") },
            { id : "editorDisplayType", label : t("question_type") },
            { id : "editorDisplayWithScore", label : t("question_score") },
            { id : "editorDisplayReverse", label : t("question_reverse") },
            { id : "editorDisplaySpecial", label : t("question_rps") },
            { id : "editorDisplayFullScreen", label : t("question_fullscreen") },
            { id : "editorDisplayAxis", label : t("axis") }
          ]}  
        />
      </Card>

      <Card className="flex1"
        title={t("utils_lg_params")}>

        <div className="flex">

          <div>
          
            <p className="grey-t">
              {t("template_lg_available")}
            </p>

            <LanguageSelection
              onAdd={(language: Language)=>addLanguage(language.code)}
              onDelete={(language: Language)=>removeLanguage(language.code)}
              languages={props.template.active.languages}
              activeLang={currentTab}
              onClick={(language: Language) => selectLanguage(language.code)}
            />

            <div className="height-20" />
            <i>{t("template_lg_current")}</i>

          </div>
          
          <Space/>

          <div>
            {translateStatus ?
            <div className="flex">
              <FontAwesomeIcon icon={faCircleNotch} spin />
              {"  " + translateStatus}
            </div>
            :
            (props.template.active.languages.length > 1 ?
              <u className="_hover grey-t" onClick={()=>setCurrentModal(CONTEXT_AUTO_TRANSLATION)}>{t("auto_translate")}</u>
              :
              <div/>
            )}
            <div className="rel">
              {currentModal === CONTEXT_AUTO_TRANSLATION &&
              <ContextMenu
                positionRight
                onClose={()=>setCurrentModal(null)}
              >
                <div style={{padding:"10px 20px"}}>
                  <p>{t("auto_translate")}</p>
                  <TranslatorWidget
                    targetLang={currentTab}
                    onTranslate={(texts: string[], language: Language) => {
                      translateTemplateTexts(texts, language)
                      setCurrentModal(null)
                    }}
                    languages={props.template.active.languages}
                    sourceTexts={getTextsToTranslate()}
                    onTargetChange={(lang: Language)=>setCurrentTab(lang.code)}
                  />
                </div>
              </ContextMenu>
              }
            </div>
          </div>
        </div>

      </Card>

      <Card title={t("Image")}>
        <div className="flex">
          <ImagePicker id={props.template.active.id}
            square
            imageId={props.template.active.image ? props.template.active.image : null}
            model="Templates"
            onDelete={() => editTemplate("image", null)}
            onSave={() => editTemplate("image", props.template.active.id)}
          />
          <Space/>
        </div>
      </Card>

    </NavFocus>
  )

}

const mapStateToProps = state => ({
  _session : state._session,
  axis : state.axis,
  template : state.template,
  topic : state.topic
})

export default connect(mapStateToProps)(withTranslation()(SurveyTemplate))