/**
 * ADM-USERS.ROUTE
 * List of users for one account
 */
import { useState } from "react"
import { WithTranslation, withTranslation } from 'react-i18next';
import PageHeader from "@/components/page-header";
import NavApp from "@/nav/nav.app"
import Button from "@/components/button";
import DeleteUsersModal from "@/modals/delete-users.modal";
import ImportUsersModal from "@/modals/import-users.modal";
import AdmUsersWidget from "@/widgets/adm-users.widget";
import Card from "@/components/card";

type Props = WithTranslation

const MODAL_DELETE_ALL = "MODAL_DELETE_ALL" //Display modal to confirm to delete all user
const MODAL_IMPORT = "MODAL_IMPORT" //Options to import users

function AdmUsersRoute(props: Props){
  const { t } = props

  const [currentModal, setCurrentModal] = useState<string | null>(null)

  return(
    <NavApp>

      { currentModal === MODAL_DELETE_ALL &&
      <DeleteUsersModal
        onClose={() => setCurrentModal(null)}
        onDeleteSuccess={() => window.location.reload()}  
      />
      }

      { currentModal === MODAL_IMPORT &&
      <ImportUsersModal
        onClose={() => setCurrentModal(null)}
        onNext={() => {window.location.reload() ; setCurrentModal(null)}}
      />
      }

      <PageHeader title={t("users")}
        subtitle={t("users_help")}>

        <Button onClick={() => setCurrentModal(MODAL_DELETE_ALL)}>
          {t("user_delete_all")}
        </Button>

        <Button className="primary"
          onClick={() => setCurrentModal(MODAL_IMPORT)}>
          {t("user_import")}
        </Button>

      </PageHeader>

      <Card isWithoutPadding>
        <AdmUsersWidget/>
      </Card>

    </NavApp>

  )

}

export default withTranslation()(AdmUsersRoute)
