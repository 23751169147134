/** 
 * EDIT-TAGS.MODAL
 * Edit tags
 */

import Modal from "@/components/modal"
import { WithTranslation, withTranslation } from "react-i18next"
import TextInput from "@/components/text-input"
import { Tag } from "@/redux/account.types"
import { useState } from "react"
import { STATUS_SAVING } from "@/redux/_status.types"

interface OwnProps{
  tags: Tag[]
  onClose: Function
  onSave: Function
}

type Props = OwnProps & WithTranslation

function EditTagsModal(props:Props) {
  const { t } = props

  const [tags, setTags] = useState<Tag[]>(props.tags)

  const [isEdited, setIsEdited] = useState<boolean>(false)

  const [isLoading, setIsLoading] = useState<boolean>(false)

  function change(event: {id: string, value: string}){
    if(!isEdited){
      setIsEdited(true)
    }

    setTags(tags.map((tag: Tag) => 
      tag.color === event.id ? { color: event.id, name: event.value } : tag
    ))

  }

  async function close(){
    if(isEdited){
      setIsLoading(true)
      await props.onSave(tags)
    }
    props.onClose()
  }

  return (
    <Modal
      title={t("tags_edit")}
      status={ isLoading ? STATUS_SAVING : undefined}
      onClose={close}
      onNext={close}
    >
      {tags.map((tag: Tag) =>
      <div key={tag.color}
        className="flex"
      >
        <div
          className="messages-colors _hover"
          style={{ backgroundColor : tag.color }}
        />

        <div className="width-20" />

        <TextInput value={tag.name}
          id={tag.color}
          onChange={(e: any) => change(e)}       
        />

      </div> 
      )}


    </Modal>
  )

}

export default withTranslation()(EditTagsModal)