/** 
 * LOGIN-REGISTER
 * Create a password for user with account
 */

import { useEffect, useState } from "react"
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from "react-redux";
import LegalModal from "@/modals/legal.modal"
import TextInput from "@/components/text-input";
import Button from "@/components/button";
import Card from "@/components/card";
import { STATUS_SAVING } from "@/redux/_status.types";
import { AccountState } from "@/redux/account.types";
import NavLogin from "@/nav/nav.login";
import Space from "@/components/space";
import Link from "@/components/link";
import LoginIconWidget from "@/widgets/login-icon.widget";
import { validateEmail } from "@/utils/validate-email.utils";
import RegisterModal from "@/modals/register.modal";
import LoginModal from "@/modals/login.modal";
import validatePassword from "@/utils/validate-password.utils";
import { NavigateFunction, useNavigate } from "react-router-dom";
 
interface Props extends WithTranslation{
  account : AccountState
}

const MODAL_LEGAL = "MODAL_LEGAL"
const MODAL_LOGIN = "MODAL_LOGIN"
const MODAL_REGISTER = "MODAL_REGISTER"

function LoginRegister(props: Props){
  const { t } = props
  const navigate: NavigateFunction = useNavigate()

  const [currentModal, setCurrentModal] = useState<string | null>(null)
  const [email, setEmail] = useState<string>("")
  const [isEmailDisabled, setIsEmailDisabled] = useState<boolean>(false)
  const [password, setPassword] = useState<string>("")
  const [passwordRepeat, setPasswordRepeat] = useState<string>("")

  //On load
  //If email valid in param, set email field
  //If no email redirection to the app
  useEffect(() => {
    const email = new URLSearchParams(window.location.search).get("email")
    if (email && validateEmail(email)){
      setEmail(email)
      setIsEmailDisabled(true)
    }
  }, [

  ])

  //Submit form
  function handleSubmit(event: any){
    event.preventDefault()
    if (validatePassword(6, password, passwordRepeat)){
      setCurrentModal(MODAL_REGISTER)
    }
  }


  return(
    <NavLogin>
      
      { currentModal === MODAL_LEGAL &&
      <LegalModal onClose={() => setCurrentModal(null)}/>
      }
      
      { currentModal === MODAL_LOGIN &&
      <LoginModal authLevel={2}
        email={email}
        password={password}
        onClose={() => setCurrentModal(null)}
      />
      }

      { currentModal === MODAL_REGISTER &&
      <RegisterModal email={email}
        password={password}
        onClose={() => setCurrentModal(MODAL_LOGIN)}
      />
      }

      <LoginIconWidget/>

      <div className="flex">
        <Space/>
        <div>

          <Card title={t("self_password_create")}>
            
            <form className="flex flex-dcol"
              onSubmit={ handleSubmit }>

              <TextInput
                disabled={isEmailDisabled}
                title={t("user_email")}
                id="email" 
                isFullWidth
                value={ email }
                onChange={(event: any) => setEmail(event.value)}
                type="email"
                autoFocus
              />
                
              <TextInput 
                title={t("utils_password")}
                id="password" 
                isFullWidth
                value={ password }
                onChange={(event: any) => setPassword(event.value)}
                type="password"
                autoFocus
              />

              <TextInput 
                title={t("utils_password_repeat")}
                id="passwordRepeat" 
                isFullWidth
                value={ passwordRepeat }
                onChange={(event: any) => setPasswordRepeat(event.value)}
                type="password"
                autoFocus
              />

              <div className="height-20"/>
            
              <Button type="submit" 
                isLarge
                isLoading={props.account.status === STATUS_SAVING} 
                className="primary" 
                isFullWidth>
                {t("utils_next")}
              </Button>
              
            </form>

          </Card>

          {/** link at the bottom of the page */}
          <div className="flex">
            <Space/>
            <Link isWhite
              onClick={() => navigate("/login/app")}>
              {t("account_connect_instead")}
            </Link>
            <Space/>
          </div>

        </div>
        
        <Space/>

      </div>

      <Space/>
      
    </NavLogin>

  )
}

const mapStateToProps = state => ({
  account : state.account
})

export default connect(mapStateToProps)(withTranslation()(LoginRegister))