/** 
 * DASHBOARD-QUESTIONS-NOTE.WIDGET
 * Display note for a topic
 */

import { connect } from "react-redux"
import { Topic } from "@/redux/topic.types";
import { Session } from "@/redux/_session.types";

interface StateProps{
  _session : Session
}

interface OwnProps{
  currentTopic : Topic
}

type Props = StateProps & OwnProps

function DashboardQuestionsNoteWidget(props:Props) {
  return (
    <div style={{ color : props.currentTopic.Axis?.color }} 
      className="details-topic-note">
      <b>
        { props.currentTopic.withScore 
        ?
        (
          props._session.dashboardDisplaySettings.dashboardDisplayMode === "note" 
          ?
          props.currentTopic.noteLabel 
          :
          props.currentTopic.satisfaction?.toFixed(0) + "%"
        )
        :
        null
        }
      </b>
    </div>
  )

}

const mapStateToProps = state => ({
  _session : state._session
})

export default connect(mapStateToProps)(DashboardQuestionsNoteWidget)