/**
 * EXPORT-TEMPLATE.UTILS
 * Fonction to generate doc from template
 */
import { store } from "@/index"
import { Document, HeadingLevel, Paragraph, AlignmentType, Packer, TextRun } from "docx"
import { saveAs } from "file-saver"
import * as XLSX from "xlsx"
import { Question } from "@/redux/question.types"
import { Axis } from "@/redux/axis.types"
import { t } from "@/translate/t"

//Generate doc from template
async function exportTemplateUtils(format){
  
  //Get current redux store
  const state = store.getState()

  //Title of the doc
  const title: string = t("template_title", {
    name : state.template.active.name[state.template.active.language]
  })

  //Init Doc
  let file:any = null
  let excelWs: XLSX.WorkSheet = {}
  
  if (format === "word"){
    file = new Document({
      creator : 'IDTree',
      description : title,
      title : title,
      styles : {
        default : {
          heading1: {
            run : {
              size : 48,
              bold : true,
              font : "Calibri",
              color : "111C2B"
            },
            paragraph: {
              spacing: {
                after: 220,
              }
            }
          },
          heading2: {
            run: {
              size: 38,
              bold: true,
              font: "Calibri"
            },
            paragraph: {
              spacing: {
                before: 440,
                after: 220
              },
            },
          },
          heading3: {
            run: {
              size: 28,
              bold: true,
              font: "Calibri"
            },
            paragraph: {
              spacing: {
                before: 340,
                after: 120
              },
            },
          },
          heading4: {
            run: {
              size: 24,
              font: "Calibri",
              color : "8C8C8C"
            },
            paragraph: {
              spacing: {
                before: 180,
                after: 80
              },
            },
          },
          heading5: {
            run: {
              size: 20,
              font: "Calibri",
              color : "8C8C8C"
            },
            paragraph: {
              spacing: {
                before: 100,
                after: 60
              },
            },
          }
        }
      }
    })
  }

  if (format === "excel"){

    excelWs = XLSX.utils.aoa_to_sheet([[
      t("axis"), 
      t("topic"), 
      t("question_priority"), 
      t("question"), 
      t("question_trigger"), 
      t("question_type"), 
      t("question_score"), 
      t("question_reverse"), 
      t("question_rps")
    ]])
  
    const colWidths = [
      { wch: 25 }, // axis
      { wch: 20 }, // topic
      { wch: 22 }, // question_priority
      { wch: 70 }, // question
      { wch: 40 }, // question_trigger
      { wch: 16 }, // question_type
      { wch: 20 }, // question_score
      { wch: 20 }, // question_reverse
      { wch: 30 }, // question_rps
    ]
    excelWs['!cols'] = colWidths
  }

  //Init contents for word
  const wordChildren:any[] = []

  //Add title
  wordChildren.push(new Paragraph({
    text : title,
    heading : HeadingLevel.HEADING_1,
    alignment : AlignmentType.CENTER
  }))

  
  const excelArray:string[] = new Array(9)

  //Init axis array
  const axes:any[] = state.axis.list.filter(x => new Axis(x))
  const withoutAxis = {
    color : "#111C2B",
    name : {
      [state.template.active.language] : t("questions_without_axis")
    }
  }

  //Loop on axis
  axes.concat([withoutAxis]).forEach(axis => {
    excelArray[0] = axis.name[state.template.active.language]

    //add axis title
    wordChildren.push(new Paragraph({
      heading : HeadingLevel.HEADING_2,
      children : [
        new TextRun({ 
          text : axis.name[state.template.active.language],
          color : axis.color.replace("#", "")
        })
      ]
    }))

    //Loop on topics
    state.topic.list.filter(x => x.Axis?.id === axis.id).forEach(topic => {

      excelArray[1] = topic.name[state.template.active.language]

      wordChildren.push(new Paragraph({
        heading : HeadingLevel.HEADING_3,
        children : [
          new TextRun({ 
            text : topic.name[state.template.active.language],
            color : axis.color.replace("#", "")
          })
        ]
      }))

      //Loop on questions
      topic.Questions.forEach((question, i) => {

        const primaryQuestion = new Question(topic.Questions[0])

        excelArray[2] = question.getPrimaryLabel(i === 0)
        excelArray[3] = question.name[state.template.active.language]
        excelArray[4] = question.getTriggerLabel(state.template.active, primaryQuestion, state.template.active.language)
        excelArray[5] = question.typeLabel
        excelArray[6] = question.numericLabel
        excelArray[7] = question.reverseLabel
        excelArray[8] = question.specialLabel

        wordChildren.push(new Paragraph({
          heading : HeadingLevel.HEADING_4,
          children : [
            new TextRun({ 
              text : question.name[state.template.active.language],
              bold : true,
              color : "111C2B"
            }),
            new TextRun({
              text : " (" + question.typeLabel + ")"
            }),
            new TextRun({
              text : " " + question.specialLabel,
              bold : true
            })
          ]
        }))

        if (i > 0 && question.getTrigger(primaryQuestion)){
          wordChildren.push(new Paragraph({
            heading : HeadingLevel.HEADING_5,
            children : [
              new TextRun({ 
                italics: true,
                text : t("question_answer_at") + " " + 
                  question.getTriggerLabel(state.template.active, primaryQuestion, state.template.active.language) + " " +
                  t("question_answer_at_question_name", { question : primaryQuestion.name[state.template.active.language] })
              })
            ]
          }))
        }
        
        if (format === "excel"){
          XLSX.utils.sheet_add_aoa(excelWs, [excelArray], { origin: -1 })
        }

        //Loop on choice items for a question
        if (question.type === 'choice'){
          question.Propositions.forEach(proposition => {

            if (format === "excel"){
              excelArray[2] = ""
              excelArray[3] = proposition.name[state.template.active.language]
              excelArray[4] = ""
              excelArray[5] = ""
              excelArray[6] = ""
              excelArray[7] = ""
              excelArray[8] = proposition.specialLabel
              XLSX.utils.sheet_add_aoa(excelWs, [excelArray], { origin: -1 })
            }

            wordChildren.push(new Paragraph({
              heading : HeadingLevel.HEADING_4,
              bullet : {
                level : 0
              },
              children : [
                new TextRun({ 
                  text : proposition.name[state.template.active.language]
                }),
                new TextRun({ 
                  text : proposition.special ? " " + proposition.specialLabel : "",
                  bold : true
                })
              ]
            }))

          })

        }
        
      })

    })

  })

  if (format === "word"){

    //Add section
    file.addSection({
      children : wordChildren
    })

    //Save doc
    Packer.toBlob(file).then(blob => {
      saveAs(blob, title + ".docx")
    })
    
  }

  else if (format === "excel"){
    // Create a workbook and add the worksheet
    const workbook: any = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, excelWs, title.slice(0, 31));

    // Generate a binary Excel file and save it
    const wbout: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    saveAs(new Blob([wbout]), title + ".xlsx");
  }

}

export default exportTemplateUtils