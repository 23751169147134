/** 
 * ADD-LEARNING.MODAL
 * add new learning
 */

import Modal from "@/components/modal"
import { connect } from "react-redux"

import { withTranslation, WithTranslation } from "react-i18next"
import TextInput from "@/components/text-input"
import { store } from "@/index"
import { learningEdit } from "@/redux/learning.actions";
import { LearningState } from "@/redux/learning.types";
import i18n from "@/translate/i18n";
import { NavigateFunction, useNavigate } from "react-router-dom"

interface StateProps extends WithTranslation{
  learning : LearningState
}

interface OwnProps{
  onClose : Function
}

type Props = StateProps & OwnProps

function AddLearningModal(props:Props) {
  const { t } = props
  const navigate: NavigateFunction = useNavigate()

  //Reset url params on close
  function close(){
    window.history.replaceState({}, '', window.location.pathname)
    props.onClose()
  }

  function editName(value){
    const item : any = props.learning.active.name
    item[i18n.language] = value 
    store.dispatch(learningEdit("name", item))
  }

  async function next(){
    navigate("/learning/editor")
  }

  return (
    <Modal isCloseButtonVisible
      onClose={close}
      onNext={next}
      title={t("learning_create")}>
        
      <TextInput autoFocus
        isFullWidth
        onChange={(e) => editName(e.value)}
        title={t("learning_name")}
        value={props.learning.active.label}
      />

      <div className="height-20"/>

    </Modal>
  )

}

const mapStateToProps = state => ({
  learning : state.learning
})

export default connect(mapStateToProps)(withTranslation()(AddLearningModal))