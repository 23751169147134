/**
 * DASHBOARD-QUESTIONS
 * Results question by question
 */
import NavDashboard from "@/nav/nav.dashboard"
import DashboardQuestionsWidget from "@/widgets/dashboard-questions.widget"

function DashboardQuestionsRoute(){
  return(
    <NavDashboard title="questions"
      helpPage={9}>
      <DashboardQuestionsWidget/>
    </NavDashboard>
  )
}

export default DashboardQuestionsRoute