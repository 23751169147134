/** 
 * LEARNING.REDUCERS
 * Edit learning state
 */

import { Learning, LearningState, LEARNING_ACTIVATE, LEARNING_ADD, LEARNING_EDIT, LEARNING_EDIT_LESSON, LEARNING_GET, LEARNING_INIT, LEARNING_REMOVE, LEARNING_STATUS } from "./learning.types";
import { Lesson } from "./lesson.types"
import { STATUS_LOADED } from "./_status.types"
import { v4 as uuid } from "uuid";
import { t } from "@/translate/t";


let active:Learning

const initialState: LearningState = {
  active : new Learning(),
  list : [],
  status : STATUS_LOADED
}

export default function learningReducer(
  state = initialState,
  action: any
): LearningState {
  switch (action.type) {

    //activate learning
    case LEARNING_ACTIVATE:
      return Object.assign({}, state, {
        active : new Learning(action.payload.learning)
      })

    
    //Add new learning
    case LEARNING_ADD:
      active = new Learning()
      active.id = uuid()
      active.name[action.payload.language] = t("learning_new")
      active.languages = [action.payload.language]
      active.new = true
      return Object.assign({}, state, { active })

    //editing learning
    case LEARNING_EDIT:
      active = Object.assign(state.active, { [action.payload.key] : action.payload.value })
      return Object.assign({}, state, {
        active,
        list : state.list.map(x => x.id === active.id ? active : x)
      })

    //Update one attribute of user
    case LEARNING_EDIT_LESSON:
      active = new Learning(state.active)

      active.Lessons = active.Lessons.map((x:Lesson)=>x.id === action.payload.lesson.id? action.payload.lesson : x)

      return Object.assign({}, state, {
        active,
        list : state.list.map(x => x.id === active.id ? active : x)
      })
    

    //Save list of learning in the store
    case LEARNING_GET:
      return {
        ...state,
        list : action.payload.learnings.map((x) => new Learning(x))
      }

    //Init store
    case LEARNING_INIT:
      return Object.assign({}, state, {
        active : new Learning(state.active),
        list : state.list.map((x:any) => new Learning(x)),
        status : state.status
      })

    //Remove a learning
    case LEARNING_REMOVE:
      return {
        ...state,
        list  : state.list.filter(x => x.id !== action.payload.id )
      }

    //Set status
    case LEARNING_STATUS:
      return {
        ...state,
        status : action.payload.status
      }

    default:
      return state

  }

}