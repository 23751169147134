/** 
 * UPLOAD.ACTIONS
 * Upload file to space
 */

export const upload = (id: string, file: any, item: any, type: string) => ({
  type : "UPLOAD",
  payload : {
    app : "upload",
    method : "POST",
    url : "/upload",
    data : {
      id,
      file,
      item,
      type
    }
  }
})

export const remove = (folder: string, id: string, extension: string) => ({
  type : "UPLOAD",
  payload : {
    app : "upload",
    method : "POST",
    url : "/upload/delete",
    data : {
      folder,
      id,
      extension
    }
  }
})