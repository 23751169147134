/** 
 * BUTTON-FLOATING
 * Floating button for question card
 */
import { IconDefinition } from "@fortawesome/fontawesome-common-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { connect } from "react-redux"
import ReactTooltip from 'react-tooltip'
import { Session } from "@/redux/_session.types"

interface StateProps{
  _session : Session
}

interface OwnProps{
  bottom? : string
  isPrimary? : boolean
  icon : IconDefinition
  left? : string
  onClick : Function
  right? : string
  text : string
  top? : string
}

type Props = StateProps & OwnProps

function ButtonFloating(props:Props) {

  function click(){
    props.onClick()
  }

  function getClassName(){
    let className = "question-action question-action-border"

    if (!props.isPrimary){
      className += " grey-t"
    }else{
      className += " white-t"
    }

    return className

  }

  function getStyle(){
    let backgroundColor

    if (props.isPrimary){
      backgroundColor = props._session.accountColors.cta
    }

    return {
      backgroundColor,
      bottom : props.bottom, 
      left : props.left, 
      right : props.right, 
      top : props.top
    }

  }

  return (
    <div>
      <div style={getStyle()}
        data-tip={props.text}
        onClick={click}
        className={getClassName()}>
        <FontAwesomeIcon icon={props.icon}/>
      </div>
      <ReactTooltip/>
    </div>
  )
}

const mapStateToProps = state => ({
  _session : state._session
})

export default connect(mapStateToProps)(ButtonFloating)