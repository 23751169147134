/** 
 * LANGUAGE.TYPE
 * Language to be displayed
 */

export class Language{
  code : string  = "" //Code
  name : string = "" //Full name to be displayed
  nativeName : string = "" //Native name
  isFavorite : boolean = false //Most popular for selection
  isTranslatable : boolean = false //Can be translate by deepL
  deepL : string | undefined = undefined //Specific code for deepL
  flag : string | undefined = undefined //Flag to display

  constructor(item: Partial<Language> = {}){
    Object.assign(this, item)
  }

  //Get code for deepL translate
  get deepLCode():string | null{
    if (this.deepL){
      return this.deepL
    }else if (this.isTranslatable){
      return this.code
    }else {
      return null
    }
  }

  //Show full name
  get fullName():string{
    return this.name + " (" + this.code.toUpperCase() + ")"
  }

}