/** 
 * EDITOR-TOPICS
 * List of topics that can be edited in list mode
 */
import { withTranslation, WithTranslation } from "react-i18next"
import { connect } from "react-redux"
import { store } from "@/index"
import { TopicState } from "@/redux/topic.types"
import { topicActivate, topicAdd, topicAddProposition, topicAddQuestion, topicEdit, topicEditName, topicEditProposition, topicEditQuestion, topicEditTrigger, topicRemoveProposition, topicSwap, topicSwapProposition } from "@/redux/topic.actions"
import ListCell from "@/components/list-cell";
import Space from "@/components/space";
import ListItem from "@/components/list-item";
import { faArrowRight, faCheckCircle, faChevronDown, faChevronUp, faClone, faCommentDots, faEllipsisV, faLock, faPenNib, faPlay, faPlus, faPlusCircle, faStar, faTrash, faUnlock } from "@fortawesome/free-solid-svg-icons";
import ListInput from "@/components/list-input";
import { Session } from "@/redux/_session.types";
import ListButton from "@/components/list-button";
import ModalConfirm from "@/components/modal-confirm";
import { useState } from "react";
import ListDropdown from "@/components/list-dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DuplicateTemplateAskModal from "@/modals/duplicate-template-ask.modal";
import DeleteQuestionModal from "@/modals/delete-question.modal";
import AnnotationsWidget from "./annotations.widget";
import { Question, QUESTION_LIST_FULLSCREEN, QUESTION_LIST_REVERSE, QUESTION_LIST_SPECIAL, QUESTION_LIST_TYPE, QUESTION_LIST_WITH_SCORE } from "@/redux/question.types";
import AxesDropdown from "@/dropdowns/axes.dropdown"
import Chip from "@/components/chip"
import { TemplateState } from "@/redux/template.types"
import { Proposition } from "@/redux/proposition.types"
import UpgradeModal from "@/modals/upgrade.modal"
import ActionsMenu from "@/components/actions-menu"
import SetTopicModal from "@/modals/set-topic.modal"

interface StateProps extends WithTranslation{
  _session : Session
  template : TemplateState
  topic : TopicState
}

interface OwnProps{
  axisNumber  : string | null
  axisId : string | null
  onOpenAdvanced : Function
}

type Props = StateProps & OwnProps

const ACTION_SET_DRAFT = 'ACTION_SET_DRAFT'
const ACTION_ADD_ANNOTATION = 'ACTION_ADD_ANNOTATION'
const ACTION_ADD_QUESTION_PRIMARY = 'ACTION_ADD_QUESTION_PRIMARY'
const ACTION_ADD_QUESTION_SECONDARY = 'ACTION_ADD_QUESTION_SECONDARY'
const ACTION_ADD_PROPOSITION = 'ACTION_ADD_PROPOSITION'
const ACTION_CHANGE_TOPIC = 'ACTION_CHANGE_TOPIC'
const ACTION_DELETE_QUESTION = 'ACTION_DELETE_QUESTION'
const ACTION_DELETE_PROPOSITION = 'ACTION_DELETE_PROPOSITION'
const ACTION_MODE_ADVANCED = 'ACTION_MODE_ADVANCED'
const ACTION_ORDER_DOWN = 'ACTION_ORDER_DOWN'
const ACTION_ORDER_UP = 'ACTION_ORDER_UP'
const ACTION_PROPOSITION_ORDER_DOWN = 'ACTION_PROPOSITION_ORDER_DOWN'
const ACTION_PROPOSITION_ORDER_UP = 'ACTION_PROPOSITION_ORDER_UP'
const ACTION_SET_PRIMARY = 'ACTION_SET_PRIMARY'
const ACTION_UNLOCK = 'ACTION_UNLOCK'
const MODAL_UPGRADE = 'MODAL_UPGRADE'
const MODAL_DELETE_QUESTION = 'MODAL_DELETE_QUESTION'
const MODAL_DUPLICATE_ASK = 'MODAL_DUPLICATE_ASK'
const MODAL_EDIT_QUESTION = 'MODAL_EDIT_QUESTION'
const MODAL_SET_TOPIC = "MODAL_SET_TOPIC"

//Do not trigger action context menu when you clic on these elements
const EDITABLE_CLASSNAMES = ["list-dropdown-text", "list-input-text"]

function EditorTopics(props:Props) {
  const { t } = props

  const [allowOpenActions, setAllowOpenActions] = useState(true) //Do not display context menu when click outside current context menu (reset after one second)
  const [currentActions, setCurrentActions] = useState([]) //Actions to display in context menu (depends of question primary, choice...)
  const [currentModal, setCurrentModal] = useState<string | null>(null) //Dialog displayed
  const [currentQuestion, setCurrentQuestion] = useState(new Question()) //Current question selected
  const [currentProposition, setCurrentProposition] = useState<Proposition | null>(null) //Current proposition selected
  const [currentAnnotationsOpen, setCurrentAnnotationOpen] = useState("") //id for the question for annotation open
  const [openActions, setOpenActions] = useState(false) //Show context menu for actions
  const [actionPosition, setActionPosition] = useState([0, 0]) //Position top and left for context menu of actions

  //Set question and topic as active
  function activateQuestion(topic, question){
    store.dispatch(topicActivate(topic))
    setCurrentQuestion(question)
  }

  //Clic on a option for a proposition
  function clickActionForProposition(action, topic, question, proposition){
    const propositionIndex = question.Propositions.findIndex(x => x.id === proposition.id)
    closeActions()
    store.dispatch(topicActivate(topic))
    
    if (isTemplateLock()){
      setCurrentModal(MODAL_DUPLICATE_ASK)
    }else{
      switch(action){
        case ACTION_ADD_PROPOSITION:
          return store.dispatch(topicAddProposition(question.id))
        case ACTION_DELETE_PROPOSITION:
          return store.dispatch(topicRemoveProposition(question.id, proposition.id))
        case ACTION_PROPOSITION_ORDER_UP:
          return store.dispatch(topicSwapProposition(question.id, proposition.id, question.Propositions[propositionIndex - 1].id))
        case ACTION_PROPOSITION_ORDER_DOWN:
          return store.dispatch(topicSwapProposition(question.id, proposition.id, question.Propositions[propositionIndex + 1].id))
        case ACTION_UNLOCK:
          return unlockQuestion(topic, question)
      }
    }

  }

  //Clic on a option for a question
  function clickActionForQuestion(action, topic, question){
    const topicIndex = props.topic.list.findIndex(x => x.id === topic.id)
    store.dispatch(topicActivate(topic))

    setCurrentQuestion(question)
    closeActions()

    if (isTemplateLock()){
      setCurrentModal(MODAL_DUPLICATE_ASK)
    }else{
      switch(action){
        case ACTION_ADD_ANNOTATION:
          return openAnnotations(topic, question)
        case ACTION_ADD_PROPOSITION:
          return store.dispatch(topicAddProposition(question.id))
        case ACTION_ADD_QUESTION_PRIMARY:
          return store.dispatch(topicAdd(props._session.interfaceType === "SUPERVISOR", topic.Axis, false, false, !props._session.userOptions.activateQuestions))
        case ACTION_ADD_QUESTION_SECONDARY:
          return store.dispatch(topicAddQuestion(props._session.interfaceType === "SUPERVISOR", false, null))
        case ACTION_DELETE_QUESTION:
          return setCurrentModal(MODAL_DELETE_QUESTION)
        case ACTION_CHANGE_TOPIC:
          return setCurrentModal(MODAL_SET_TOPIC)
        case ACTION_ORDER_DOWN:
          return store.dispatch(topicSwap(topic.id, props.topic.list[topicIndex + 1].id))
        case ACTION_ORDER_UP:
          return store.dispatch(topicSwap(topic.id, props.topic.list[topicIndex - 1].id))
        case ACTION_MODE_ADVANCED:
          return props.onOpenAdvanced()
        case ACTION_SET_DRAFT:
          return setQuestionDraft(topic, question)
        case ACTION_SET_PRIMARY:
          return store.dispatch(topicEdit("questionPrimaryId", question.id))
        case ACTION_UNLOCK:
          return unlockQuestion(topic, question)
      }

    }

  }

  //Click on the list item
  //If not an editable field open context menu on click
  function clickQuestion(question, e, actions, topic){
    activateQuestion(topic, question)
    setCurrentProposition(null)
    openContextMenu(actions, e)
  }

  //Click on the list item
  //If not an editable field open context menu on click
  function clickProposition(question, e, actions, propositions, topic){
    activateQuestion(topic, question)
    setCurrentProposition(propositions)
    openContextMenu(actions, e)
  }

  //Set timeout before close actions
  //This allow to not retrigger context menu when click outside
  function closeActions(){
    setOpenActions(false)
    setTimeout(() => {
      setAllowOpenActions(true)
    }, 500)
  }

  //Confirm edition of the question
  function editQuestionConfirm(){
    store.dispatch(topicEditQuestion(currentQuestion.id, 'super', false))
    setCurrentModal(null)
  }

  //Edit text for choice items
  function editPropositionName(question, proposition, value){
    const name = proposition.name
    name[props.template.active.language] = value
    store.dispatch(topicEditProposition(proposition.id, question.id, "name", name))
  }

  //Edit tag RPS proposition
  function editPropositionSpecial(propositionId, questionId, value){
    if (!isFeatureLock()){
      store.dispatch(topicEditProposition(propositionId, questionId, "special", value))
    }
  }

  //Edit question
  function editQuestion(question, key, value){
    store.dispatch(topicEditQuestion(question.id, key, value))
  }

  //Edit question name
  function editQuestionName(question, value){
    const name = question.name
    name[props.template.active.language] = value
    store.dispatch(topicEditQuestion(question.id, "name", name))
  }

  //Edit tag for question
  function editQuestionSpecial(question, item){
    if (!isFeatureLock()){
      editQuestion(question, "special", item.id)
    }
  }

  //Edit topic name
  function editTopicName(name){
    if (name !== props.topic.active.name[props.template.active.language]){
      store.dispatch(topicEditName(props.template.active.language, name))
    }
  }

  //Get all actions avaiable for context menu
  //If primary question => possibility to add secondary question and change order in the list
  //If secondary => possibilité to set the question as primary
  function getActionsForQuestion(question, isPrimary, index){
    return [

      //Actions primary
      { id : ACTION_UNLOCK, label : t("utils_unlock"), icon : faUnlock, isInactive : !isQuestionLock(question) },
      { id : ACTION_MODE_ADVANCED, label : t("question_set_display_card"), icon : faClone },
      { id : ACTION_DELETE_QUESTION, label : t("question_delete"), icon : faTrash },
      { id : ACTION_ADD_QUESTION_SECONDARY, label : t("question_add_secondary"), icon : faPlus, isInactive : !isPrimary },
      { id : ACTION_ADD_PROPOSITION, label : t("proposition_add"), icon : faCheckCircle, isInactive : question.type !== "choice" || isQuestionLock(question) },
      
      //Actions advanced
      { isAdvanced : true, id : ACTION_ADD_QUESTION_PRIMARY, label : t("question_add"), icon : faPlusCircle },
      { isAdvanced : true, id : ACTION_CHANGE_TOPIC, label : t("question_change_topic"), icon : faArrowRight, isInactive : isPrimary },
      { isAdvanced : true, id : ACTION_ORDER_UP, label : t("question_order_up"), icon : faChevronUp, isInactive : !isPrimary || index === 0 },
      { isAdvanced : true, id : ACTION_ORDER_DOWN, label : t("question_order_down"), icon : faChevronDown, isInactive : !isPrimary || index === props.topic.list.length - 1 },
      { isAdvanced : true, id : ACTION_SET_PRIMARY, label : t("question_set_primary"), icon : faStar, isInactive : isPrimary },
      { isAdvanced : true, id : ACTION_ADD_ANNOTATION, label : t("annotation_add"), icon : faCommentDots, isInactive : question.super },
      { isAdvanced : true, id : ACTION_SET_DRAFT, label : question.draft ? t("question_activate") : t("utils_draft"), icon : question.draft ? faPlay : faPenNib, isInactive : isQuestionLock(question) }

    ]
  }

  //Actions for choice item
  function getActionsForProposition(question, k){
    return [
      { id : ACTION_UNLOCK, label : t("utils_unlock"), icon : faUnlock, isInactive : !isQuestionLock(question) },
      { id : ACTION_ADD_PROPOSITION, label : t("proposition_add"), icon : faCheckCircle, isInactive : isQuestionLock(question) },
      { id : ACTION_PROPOSITION_ORDER_UP, label : t("question_order_up"), icon : faChevronUp, isInactive : k === 0 || isQuestionLock(question) },
      { id : ACTION_PROPOSITION_ORDER_DOWN, label : t("question_order_down"), icon : faChevronDown, isInactive : k === question.Propositions.length - 1 || isQuestionLock(question) },
      { id : ACTION_DELETE_PROPOSITION, label : t("utils_delete"), icon : faTrash, isInactive : isQuestionLock(question) },
    ]
  }

  //Get topics depending of the axis ID
  function getTopics(){
    if (props.axisId){
      return props.topic.list.filter(x => x.Axis?.id === props.axisId)
    }else{
      return props.topic.list.filter(x => x.Axis === null)
    }
  }

  //Check if feature is available according the level of the account
  //If not display dialog
  function isFeatureLock(){
    if (props._session.interfaceType !== "SUPERVISOR" && !props._session.modules.rps){
      setCurrentModal(MODAL_UPGRADE)
      return true
    }else{
      return false
    }
  }

  //La question est-elle éditable
  //Si compte client et question de IDtree confirmation nécessaire
  function isQuestionLock(question){
    return props._session.interfaceType !== "SUPERVISOR" && question.super
  }

  //Check if template is editable
  //If template of idtree and connected as customer, ask for duplicate
  function isTemplateLock(){
    return props._session.interfaceType !== "SUPERVISOR" && props.template.active.fromSupervisor
  }

  //Open annotation widget
  function openAnnotations(topic, question){
    store.dispatch(topicActivate(topic))
    setCurrentAnnotationOpen(question.id)
  }

  //Open content menu on click on the list item
  function openContextMenu(actions, e){
    if (!allowOpenActions){
      setOpenActions(false)
    }else{
      if (EDITABLE_CLASSNAMES.indexOf(e.target.className) === -1){
        setOpenActions(true)
        setAllowOpenActions(false)
        setCurrentActions(actions)
        setActionPosition([e.clientY, e.clientX])
      }
    }
  }

  //Switch question draft or not
  function setQuestionDraft(topic, question){
    activateQuestion(topic, question)
    editQuestion(question, "draft", !question.draft)
  }

  //Unlock question of idtree
  //If template super admin as duplicate
  function unlockQuestion(topic, question){
    if (isQuestionLock(question)){
      activateQuestion(topic, question)
      setCurrentModal(MODAL_EDIT_QUESTION)
    }
  }

  return (
    <div style={{ 
        display : 'inline-block',
        minWidth : 'calc(100vw - 80px)'
      }}>
      
      { openActions &&
      <div className="editor-topics-actions"
        style={{
          top : actionPosition[0],
          left : actionPosition[1]
        }}>
        <ActionsMenu actions={currentActions}
          positionBottom={actionPosition[0] > 500}
          onClose={closeActions}
          onSelect={currentProposition ?
            (actionId) => clickActionForProposition(actionId, props.topic.active, currentQuestion, currentProposition) :
            (actionId) => clickActionForQuestion(actionId, props.topic.active, currentQuestion)
          }
        />
      </div>
      }

      { currentModal === MODAL_UPGRADE &&
      <UpgradeModal feature="rps"
        onClose={() => setCurrentModal(null)}
      />
      }

      { currentModal === MODAL_DELETE_QUESTION &&
      <DeleteQuestionModal onClose={() => setCurrentModal(null)}
        currentQuestion={currentQuestion}
      />
      }

      { currentModal === MODAL_DUPLICATE_ASK &&
      <DuplicateTemplateAskModal onClose={() => setCurrentModal(null)}/>
      }

      { currentModal === MODAL_EDIT_QUESTION &&
      <ModalConfirm onNo={() => setCurrentModal(null)}
        onYes={editQuestionConfirm}
        text={t("question_is_idtree")}
        textBold={t("question_ask_edit")}
      />
      }

      { currentModal === MODAL_SET_TOPIC &&
      <SetTopicModal currentQuestion={currentQuestion} onClose={() => setCurrentModal(null)}/>
      }
      
      { getTopics().map((topic, j)  => 
      <div key={topic.id}
        id={"topic-" + topic.id}>

        <div className="medgrey"
          style={{ height : "2px" }}/>

        { topic.Questions.map((question, i) =>
        <div key={question.id}>

          { currentAnnotationsOpen === question.id &&
          <AnnotationsWidget onClose={() => setCurrentAnnotationOpen("")}
          />
          }

          <ListItem bgColor={question.draft ? "#f2d600" : (i === 0 ? "#e1e1e1" : null)}
            onClick={(e) => clickQuestion(question, e, getActionsForQuestion(question, i === 0, j), topic)}
            isEditable>
            
            { i === 0
            ?
            <ListCell>
              #{props.axisNumber}{props.axisNumber ? "." : ""}{j + 1}
            </ListCell>
            :
            <ListCell>
              <div className="rel editor-topics-secondary">
                <div className="editor-topics-secondary-dot medgrey"/>
                <div className="editor-topics-secondary-top medgrey-b"/>
                { (i < topic.Questions.length - 1 || question.type === "choice") &&
                <div className="editor-topics-secondary-bottom medgrey-b"/>
                }
              </div>
            </ListCell>
            }

            <ListButton icon={isQuestionLock(question) ? faLock : faUnlock}
              isInactive={!isQuestionLock(question) && !question.draft}
              isPrimary={question.draft}
              text={isQuestionLock(question) ? t("utils_unlock") : t("question_unlocked")}
              onClick={() => unlockQuestion(topic, question)}
            />

            <ListCell width={60}>

              {( 
                i === 0 && 
                !question.fromSupervisor
              ) &&
              <div className="flex" 
                onMouseEnter={() => openAnnotations(topic, question)}>
                <ListButton icon={faCommentDots}
                  isInactive={topic.Annotations.length === 0 && !question.draft}
                  isPrimary={question.draft}
                  dot={topic.newAnnotations}
                  text={t("annotation_get")}
                />
              </div>
              }
              
            </ListCell>

            { props._session.userOptions.editorDisplayTopic &&
            <ListCell width={200}>
              { i === 0 &&
              <ListInput
                onEdit={() => activateQuestion(topic, question)}
                onEditEnd={(value) => editTopicName(value)}
                value={topic.name[props.template.active.language]} 
              />
              }
            </ListCell>
            }

            { props._session.userOptions.editorDisplayPrimary &&
            <ListCell width={200}>
              <Chip color={i === 0 ? topic.Axis?.color : null}>
                {question.getPrimaryLabel(i === 0)}
              </Chip>
            </ListCell>
            }

            { props._session.userOptions.editorDisplayQuestion &&
            <ListCell width={500}>

              { isQuestionLock(question) 
              ?
              <span>
                {question.name[props.template.active.language]}
              </span>
              :
              <ListInput isEdited={!question.name[props.template.active.language]}
                onEdit={() => activateQuestion(topic, question)}
                onEditEnd={(value) => editQuestionName(question, value)}
                value={question.name[props.template.active.language]} 
              />
              }

            </ListCell>
            }

            <Space/>
            
            { props._session.userOptions.editorDisplayTrigger &&
            <ListCell width={200}>
              { (i === 0 || topic.questionPrimary?.type === "open")
              ?
              <span className="grey-t">
                {t("question_always_trigger")}
              </span>
              :
              (
                isQuestionLock(question)
                ?
                <span>
                  {question.getTriggerValue(topic.questionPrimary, props.template.active.language)}
                </span>
                :
                <ListDropdown active={question.getTrigger(topic.questionPrimary)}
                  color={question.getTriggerValue(topic.questionPrimary, props.template.active.language) === "" ? "#eb5a46" : ""}
                  onClick={() => activateQuestion(topic, question)}
                  onSelect={(item) => store.dispatch(topicEditTrigger(question.id, topic.questionPrimary?.type === "scale" ? "triggerValue" : "triggerChoice" , item.id))}
                  value={question.getTriggerValue(topic.questionPrimary, props.template.active.language)}
                  values={question.getTriggerList(topic.questionPrimary, props.template.active.language)}
                />
              )
              }
            </ListCell>
            }

            { props._session.userOptions.editorDisplayType &&
            <ListCell width={140}>
              { isQuestionLock(question)
              ?
              <span>
                {question.typeLabel}
              </span>
              :
              <ListDropdown active={question.type}
                onClick={() => activateQuestion(topic, question)}
                onSelect={(item) => editQuestion(question, "type", item.id)}
                value={question.typeLabel}
                values={QUESTION_LIST_TYPE}
              />
              }
            </ListCell>
            }

            { props._session.userOptions.editorDisplayWithScore &&
            <ListCell width={140}>
              { question.isNumericAllowed
              ?
              <div className={!question.withScore ? "editor-topic-without-score" : "" }>
                { isQuestionLock(question)
                ?
                <span>
                  {question.numericLabel}
                </span>
                :
                <ListDropdown active={question.type}
                  onClick={() => activateQuestion(topic, question)}
                  onSelect={(item) => editQuestion(question, "withScore", item.id)}
                  value={question.numericLabel}
                  values={QUESTION_LIST_WITH_SCORE}
                />
                }
              </div>
              :
              <span className="grey-t">
                {question.numericLabel}
              </span>
              }
            </ListCell>
            }
            
            { props._session.userOptions.editorDisplayReverse &&
            <ListCell width={180}>
              { question.isNumeric &&
              <div className={question.reverse ? "editor-topic-reverse" : "" }>
                { isQuestionLock(question)
                ?
                <span>
                  {question.reverseLabel}
                </span>
                :
                <ListDropdown active={question.reverse}
                  onClick={() => activateQuestion(topic, question)}
                  onSelect={(item) => editQuestion(question, "reverse", item.id)}
                  value={question.reverseLabel}
                  values={QUESTION_LIST_REVERSE.filter(x => x.responseCount.indexOf(question.responseCount) > -1)}
                />
                }
              </div>
              }
            </ListCell>
            }

            { props._session.userOptions.editorDisplaySpecial &&
            <ListCell width={220}>
              { question.type === "scale" &&
              <div className={!question.special ? "editor-topic-without-score" : "" }>
                { isQuestionLock(question)
                ?
                <span>
                  {question.specialLabel}
                </span>
                :
                <ListDropdown active={question.special}
                  onClick={() => activateQuestion(topic, question)}
                  onSelect={(item) => editQuestionSpecial(question, item)}
                  value={question.specialLabel}
                  values={QUESTION_LIST_SPECIAL}
                />
                }
              </div>
              }
            </ListCell>
            }

            { props._session.userOptions.editorDisplayFullScreen &&
            <ListCell width={120}>
              { i === 0 &&
              (
                isQuestionLock(question)
                ?
                <span>
                  {topic.fullScreen ? t("utils_yes") : t("utils_no")}
                </span>
                :
                <ListDropdown active={topic.fullScreen}
                  onClick={() => activateQuestion(topic, question)}
                  onSelect={(item) => store.dispatch(topicEdit("fullScreen", item.id))}
                  value={topic.fullScreen ? t("utils_yes") : t("utils_no")}
                  values={QUESTION_LIST_FULLSCREEN}
                />
              )
              }
            </ListCell>
            }

            { props._session.userOptions.editorDisplayAxis &&
            <ListCell width={160}>
              { i === 0 &&
              <AxesDropdown currentTopic={topic}/>
              }
            </ListCell>
            }
            
            <ListCell>
              { !isQuestionLock(question) &&
              <ListButton icon={question.draft ? faPlay : faPenNib}
                onClick={() => setQuestionDraft(topic, question)}
                text={question.draft ? 
                  t("question_activate") :
                  t("utils_draft")
                }
              />
              }
            </ListCell>
            
            <ListButton icon={faEllipsisV}
              onClick={() => activateQuestion(topic, question)}
              actions={getActionsForQuestion(question, i === 0, j)}
              onAction={(actionId) => clickActionForQuestion(actionId, topic, question)}
              text={t("utils_options")}
            />
            
          </ListItem>

          { question.type === "choice" &&
          question.Propositions.map((proposition, k) =>
          <ListItem key={proposition.id} 
            isEditable
            onClick={(e) => clickProposition(question, e, getActionsForProposition(question, k), proposition, topic)}
            bgColor={question.draft ? "#f2d600" : null}>

            <ListCell>
              { /** afficher une ligne pour questions secondaires */
              topic.Questions.length > 1 &&
              <div className="rel editor-topics-secondary">
                <div className="editor-topics-secondary-top medgrey-b"/>
                { (
                  i < topic.Questions.length - 1 ||
                  k < question.Propositions.length - 1
                ) &&
                <div className="editor-topics-secondary-bottom medgrey-b"/>
                }
              </div>
              }
            </ListCell>

            <ListCell/>
            <ListCell width={60}/>

            { props._session.userOptions.editorDisplayTopic &&
            <ListCell width={200}/>
            }

            {props._session.userOptions.editorDisplayPrimary &&
            <ListCell width={200}/>
            }

            { props._session.userOptions.editorDisplayQuestion &&
            <ListCell width={500}>

              <span className="grey-t">
                <FontAwesomeIcon icon={faCheckCircle} style={{ marginRight : '12px' }}/>
              </span>

              { isQuestionLock(question) 
              ?
              <span>
                <i>{proposition.name[props.template.active.language]}</i>
              </span>
              :
              <span className="flex1">
                <i>
                  <ListInput isEdited={!proposition.name[props.template.active.language]}
                    onEdit={() => activateQuestion(topic, question)}
                    onEditEnd={(value) => editPropositionName(question, proposition, value)}
                    value={proposition.name[props.template.active.language]} 
                  />
                </i>
              </span>
              }

            </ListCell>
            }

            <Space/>

            { props._session.userOptions.editorDisplayTrigger &&
            <ListCell width={200}/>
            }

            { props._session.userOptions.editorDisplayType &&
            <ListCell width={140}/>
            }
            
            { props._session.userOptions.editorDisplayWithScore &&
            <ListCell width={140}/>
            }

            { props._session.userOptions.editorDisplayReverse &&
            <ListCell width={180}/>
            }

            { props._session.userOptions.editorDisplaySpecial &&
            <ListCell width={220}>
              <div className={!proposition.special ? "editor-topic-without-score" : "" }>
                { isQuestionLock(question)
                ?
                <span>
                  {proposition.specialLabel}
                </span>
                :
                <ListDropdown active={proposition.special}
                  onClick={() => activateQuestion(topic, question)}
                  onSelect={(item) => editPropositionSpecial(proposition.id, question.id, item.id)}
                  value={proposition.specialLabel}
                  values={QUESTION_LIST_SPECIAL}
                />
                }
              </div>
            </ListCell>
            }

            { props._session.userOptions.editorDisplayFullScreen &&
            <ListCell width={120}/>
            }

            { props._session.userOptions.editorDisplayAxis &&
            <ListCell width={160}/>
            }
            
            <ListCell/>

            <ListButton icon={faEllipsisV}
              onClick={() => activateQuestion(topic, question)}
              actions={getActionsForProposition(question, k)}
              onAction={(actionId) => clickActionForProposition(actionId, topic, question, proposition)}
              text={t("utils_options")}
            />
            
          </ListItem> 
          )
          }

        </div>
        )
        }

      </div>
      )
      }

    </div>
    
  )

}

const mapStateToProps = state => ({
  _session : state._session,
  template : state.template,
  topic : state.topic
})

export default connect(mapStateToProps)(withTranslation()(EditorTopics))