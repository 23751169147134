/**
 * SUPERADMIN-USERS
 * Manage super-admins for the super-account
 */
import { WithTranslation, withTranslation } from 'react-i18next';
import PageHeader from "@/components/page-header";
import NavApp from "@/nav/nav.app"
import AdmRolesWidget from "@/widgets/adm-roles.widget";
import AdmAddRoleWidget from '@/widgets/adm-add-role.widget';

type Props = WithTranslation

function SuperadminRolesRoute(props: Props){
  const { t } = props
  return(
    <NavApp>

      <PageHeader title={t("superadmin_users")}
        subtitle={t("superadmin_users_help")}>
        <AdmAddRoleWidget/>
      </PageHeader>

      <AdmRolesWidget/>

    </NavApp>
  )
}

export default withTranslation()(SuperadminRolesRoute)