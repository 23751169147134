/**
 * PROJECT-MODES.WIDGET
 * Select invitation modes
 */

import { useState } from "react";
import { withTranslation, WithTranslation } from 'react-i18next';
import CardContainer from "@/components/card-container";
import CardButton from "@/components/card-button";
import { faEnvelope, faMobileAlt, faQrcode, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import { Session } from "@/redux/_session.types";
import { store } from "@/index";
import UpgradeModal from "@/modals/upgrade.modal";
import { Project, ProjectState } from "@/redux/project.types";
import { projectCheckEmails, projectCheckModes, projectEdit, projectEditQrcodeOptions } from "@/redux/project.actions";
import ConfirmButtons from "@/components/confirm-buttons";
import ListItem from "@/components/list-item";
import { EmailState } from "@/redux/email.types";
import ProjectStepErrorsWidget from "./project-step-errors.widget";

interface StateProps extends WithTranslation{
  _session : Session
  email : EmailState
  project : ProjectState
}

interface OwnProps{
  onNext? : Function
  onEdit? : Function
}

type Props = StateProps & OwnProps

interface ModuleCard{
  id : string
  icon : IconDefinition
  withModule? : boolean
}

//Modals
const MODAL_UPGRADE_QRCODE: string = "modal_upgrade_qrcode"
const MODAL_UPGRADE_SMS: string = "modal_upgrade_sms"

//List of cards to display
const CARDS:ModuleCard[] = [
  { id : "email", icon : faEnvelope },
  { id : "qrcode", icon : faQrcode, withModule : true },
  { id : "sms", icon : faMobileAlt, withModule : true }
]

function ProjectModesWidget(props:Props) {
  const { t } = props

  //Display modal
  const [currentModal, setCurrentModal] = useState<string | null>(null)

  //Detect button is locked (according modules)
  function isLocked(card:ModuleCard){
    return card.withModule && !props._session.modules[card.id]
  }

  //Reset values
  function cancel(){
    store.dispatch(projectEdit("diffusionMode", new Project().diffusionMode))
    select()
    next(false, false)
  }

  //Click on card
  function clickCard(cardId:string){

    if (cardId === "qrcode" && Object.keys(props.project.active.qrcodeOptions.content).length === 0){
      store.dispatch(projectEditQrcodeOptions("senderName", props._session.accountName))
      store.dispatch(projectEditQrcodeOptions("content", props.project.active.messageOptions.content))
    }

    store.dispatch(projectEdit("diffusionMode", Object.assign({}, props.project.active.diffusionMode, { [cardId] : !props.project.active.diffusionMode[cardId] })))
    
    select()

  }

  //Click on next
  function next(isVisited:boolean, isConfirmed:boolean){
    if (props.onNext){
      props.onNext(isVisited, isConfirmed)
    }
  }

  function select(){
    store.dispatch(projectCheckModes(props.project.active.isDiffusionModeDefined))
    store.dispatch(projectCheckEmails(props.project.active.languages, props.email.list))
    if (props.onEdit){
      props.onEdit()
    }
  }

  return (
    <div>

      { currentModal === MODAL_UPGRADE_QRCODE &&
      <UpgradeModal feature="qrcode"
        onClose={() => setCurrentModal(null)}
      />
      }

      { currentModal === MODAL_UPGRADE_SMS &&
      <UpgradeModal feature="sms"
        onClose={() => setCurrentModal(null)}
      />
      }

      <ListItem>
        <div className="setup-card-help">
          <p>
            {t("project_modes_help")}
          </p>
          <ul className="grey-t setup-card-help-content">
            <li>{t("project_modes_help_1")}</li>
            <li>{t("project_modes_help_2")}</li>
            <li>{t("project_modes_help_3")}</li>
          </ul>
        </div>
      </ListItem>

      <CardContainer>
        { CARDS.map(card =>
        <CardButton icon={card.icon}
          key={card.id}
          isLock={isLocked(card)}
          isActive={props.project.active.diffusionMode[card.id]}
          onClick={() => isLocked(card) ? setCurrentModal("modal_upgrade_" + card.id) : clickCard(card.id)}
          title={t("project_modes_" + card.id)}>
        </CardButton>
        )}
      </CardContainer>

      <ProjectStepErrorsWidget alert={props.project.active.alerts.modes} step="modes"/>

      { props.onNext &&
      <ConfirmButtons isLocked={props.project.active.alerts.modes.errors.length > 0}
        onCancel={() => cancel()}
        onConfirm={() => next(true, true)}
        onConfirmLater={() => next(true, false)}
      />
      }

    </div>
  )
}

const mapStateToProps = state => ({
  _session : state._session,
  email : state.email,
  project : state.project
})

export default connect(mapStateToProps)(withTranslation()(ProjectModesWidget))