/** 
 * SURVEY.PERMISSIONS
 * Define rôles for each template as superadmin
 */
import NavApp from "@/nav/nav.app";
import AccountsListWidget from "@/widgets/accounts-list.widget";
import PageHeader from "@/components/page-header";
import { withTranslation, WithTranslation } from "react-i18next";

type Props = WithTranslation

function SuperadminTemplatesRoute(props: Props){
  const { t } = props
  return(
    <NavApp>

      <PageHeader title={t("superadmin_templates")}
        subtitle={t("template_permissions_help")}>
      </PageHeader>

      <AccountsListWidget editTemplateRulesOnly/>

    </NavApp>
  )
}

export default withTranslation()(SuperadminTemplatesRoute)