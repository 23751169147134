/** 
 * LEGAL.MODAL
 * Informations RGPD
 */
import Link from "@/components/link"
import Modal from "@/components/modal"
import { withTranslation, WithTranslation } from "react-i18next"
import { connect } from "react-redux"
import { Session } from "@/redux/_session.types"

interface StateProps extends WithTranslation{
  _session : Session
}

interface OwnProps{
  onClose : Function
}

type Props = StateProps & OwnProps

function LegalModal(props:Props) {
  const { t } = props
  
  return (
    <Modal isCloseButtonVisible
      onClose={() => props.onClose()}
      title={t("legal")}>

      <p>
        {t("legal_cookies")}
      </p>

      <p>
        {t("legal_email")}
      </p>

      <p>
        {t("legal_unsubscribe")}
      </p>

      { props._session.language === "fr" &&
      <Link isWithoutMargin
        onClick={() => window.open("https://www.id-tree.com/cgs", "_blank")}>
        Voir les conditions générales détaillées
      </Link>
      }

    </Modal>

  )

}

const mapStateToProps = state => ({
  _session : state._session
})

export default connect(mapStateToProps)(withTranslation()(LegalModal))