/**
 * ANNOTATION
 * Comment about a question
 */

export class Annotation{
  id : string = ""
  text : string = ""
  author : string = ""
  authorId : string = ""
  createdAt : Date = new Date()
  updated : boolean = false

  constructor(annotation: Partial<Annotation> = {}){
    Object.assign(this, annotation)
  }

  get isNew():boolean{
    return new Date(this.createdAt).getTime() + 86400000 > new Date().getTime()
  }

  get createdAtLabel():string{
    return new Date(this.createdAt).toLocaleDateString(navigator.language, {
      hour : '2-digit',
      minute :'2-digit'
    })
  }
  
}