/**
 * DASHBOARD-POPULATIONS.ROUTE
 * "Flute de pan" to display results by population
 */

import NavDashboard from "@/nav/nav.dashboard";
import DashboardPopulationsWidget from "@/widgets/dashboard-populations.widget";

function DashboardPopulationsRoute(){
  return(
    <NavDashboard title="attributes"
      hideOptions>
      <div className="flex">
        
        <div className="flex1">
          <DashboardPopulationsWidget/>
        </div>

        <div className="width-40"/>

        <div className="flex1">

        </div>

      </div>
    </NavDashboard>
  )
}

export default DashboardPopulationsRoute