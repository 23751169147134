/** 
 * MOBILE-WARNING.MODAL
 * Text to display if the user try idtree in mobile
 */
import Modal from "@/components/modal"
import { withTranslation, WithTranslation } from "react-i18next"

type Props = WithTranslation

function MobileWarningModal(props: Props) {
  const { t } = props
  
  return (
    <Modal>
      <div style={{ width : '300px' }}>
        <p>
          <b>{t("utils_no_smartphone")}</b> 
          {t("utils_no_smartphone_help")}
        </p>
        <p>
          {t("utils_apologize")}
        </p>
      </div>
    </Modal>

  )

}

export default withTranslation()(MobileWarningModal)