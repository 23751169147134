/** 
 * DASHBOARD-RESULTS-AXES.WIDGET
 * radar chart with axes scores
 */

import { connect } from "react-redux"
import { Session } from "@/redux/_session.types";
import { Topic } from "@/redux/topic.types";
import { orderBy } from "lodash";
import { useEffect, useState } from "react";
import DashboardTopicOverviewWidget from "./dashboard-topic-overview.widget";

const NB_TOPICS_DISPLAY: number = 3

interface StateProps{
  _session : Session
}

interface OwnProps{
  currentTopics: Topic[]
  displayQuestions? : boolean
  selectedAxisId?: string
  reverse?: boolean
  nbToDisplay?: number
  isPreview? : boolean
}

type Props = StateProps & OwnProps

function DashboardTopicsPodiumWidget(props: Props) {

  const [topics, setTopics] = useState<Topic[]>([])

  const [reverse, setReverse] = useState<boolean>(props.reverse!)

  useEffect(() => {
    setReverse(props.reverse!)
  }, [
    props.reverse
  ])

  useEffect(() => {

    function getTopics(selectedAxisId: string | undefined){
      return selectedAxisId ?
        orderBy(
          props.currentTopics.filter((x: Topic) => x.note && x.AxisId === selectedAxisId),
          props._session.dashboardDisplaySettings.dashboardDisplayMode,
          "desc"
        )
      :
        orderBy(
          props.currentTopics.filter((x: Topic) => x.note),
          props._session.dashboardDisplaySettings.dashboardDisplayMode,
          "desc"
        )
    }

    setTopics(getTopics(props.selectedAxisId))

  }, [
    props.currentTopics,
    props.selectedAxisId,
    props._session.dashboardDisplaySettings.dashboardDisplayMode
  ])


  return(
    <div className="flex flex-dcol">
      {(reverse ? topics.slice().reverse() : topics).slice(0, props.nbToDisplay ? props.nbToDisplay : NB_TOPICS_DISPLAY).map((topic: Topic) =>
      <div key={topic.id}
        className="flex1"
        style={{fontSize: 12, minWidth: 300, maxWidth: 300, marginLeft: 10}}>
        <DashboardTopicOverviewWidget topic={topic} isPreview={props.isPreview}/>
        { props.displayQuestions &&
        topic.Questions.map(question => 
        <div key={question.id} 
          className="grey-t"
          style={{
            fontSize : 12,
            paddingBottom : 6
          }}>
          {question.label}
        </div>
        )
        }
      </div>
      )}
    </div>
  )

}

const mapStateToProps = state => ({
  _session : state._session
})

export default connect(mapStateToProps)(DashboardTopicsPodiumWidget)