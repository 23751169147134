
import { Session } from '@/redux/_session.types';
import { connect } from 'react-redux';
import { CSS } from '@dnd-kit/utilities';
import { useSortable } from '@dnd-kit/sortable';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripVertical } from '@fortawesome/free-solid-svg-icons';
import Space from '@/components/space';

interface StateProps{
  _session : Session
}

interface OwnProps{
  children: any
  id: any
  withHandle?: boolean
  isDisabled?: boolean
}

type Props = StateProps & OwnProps

function SortableWidget(props: Props) {

  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition
  } = useSortable({
    id: props.id,
    disabled: props.isDisabled
  });

  const [isHovered, setIsHovered] = useState<boolean>(false)

  const style = {
    // Outputs `translate3d(x, y, 0)`
    transform: CSS.Translate.toString(transform),
    transition
  };


  return (
    <div
      id={props.id}
      ref={setNodeRef}
      style={style}
      {...attributes}
      className="flex rel"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {props.children}

      {( !props.isDisabled && props.withHandle && isHovered ) &&
      <div
        ref={setActivatorNodeRef}
        {...listeners}
        className="abs flex flex-dcol grey-t"
        style={{
          height: "100%",
          padding: "0 12px",
          backgroundColor: "#FFFFFF88",
          borderRadius: 18,
          cursor: "grab",
        }}
      >
        <Space/>
        <FontAwesomeIcon icon={faGripVertical} size={"lg"}/>
        <Space/>
      </div>
      }
    </div>

  );
}

const mapStateToProps = state => ({
  _session : state._session
})

export default connect(mapStateToProps)(SortableWidget)