/**
 * TASKS-LINKER.WIDGET
 * Display new created tasks from /dashboard/questions page
 */

import { Session } from "@/redux/_session.types"
import { ActionPlan, ActionPlanState } from "@/redux/action-plan.types"
import { faListCheck } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"
import { withTranslation, WithTranslation } from "react-i18next"
import { connect } from "react-redux"
import { store } from ".."
import { actionPlanActivate, actionPlanFetch, actionPlanFetchSelf, actionPlanGet } from "@/redux/action-plan.actions"
import { NavigateFunction, useNavigate } from "react-router-dom"
import { FadeIn } from "@/utils/animations.utils"
import ReactTooltip from "react-tooltip"
import AnimatedColorSvgWidget from "./animated-color-svg.widget"
import ModalHelp from "@/components/modal-help"
import TasksActionPlanDisplayWidget from "./tasks-action-plan-display.widget"
import Space from "@/components/space"

interface StateProps{
  _session: Session
  actionPlan: ActionPlanState
}

interface OwnProps{
  surveyId?: string
  isExpanded?: boolean
}

type Props = StateProps & OwnProps & WithTranslation

function TasksLinkerWidget(props: Props){
  const { t } = props
  const navigate: NavigateFunction = useNavigate()

  const [surveyActionPlans, setSurveyActionPlans] = useState<ActionPlan[]>([])
  const [isExpanded, setIsExpanded] = useState<boolean>(props.isExpanded ? true : false)
  const [isAnimated] = useState<boolean>(true)
  const [isAnimatedCounter, setIsAnimatedCounter] = useState<boolean>(false)
  const [tasksCount, setTasksCount] = useState<number>(0)

  useEffect(() => {
    setIsExpanded(props.isExpanded ? true : false)
  }, [
    props.isExpanded
  ])

  useEffect(() => {
    async function loadActionPlans(){
      const response: any = await store.dispatch(props._session.userRole === "ADMIN" ? actionPlanFetch() : actionPlanFetchSelf())

      if(!response.error){
        store.dispatch(actionPlanGet(response))
      }
    }

    loadActionPlans()
  }, [
    props.actionPlan.active.tasks
  ])
  
  useEffect(() => {
    const actionPlans: ActionPlan[] = props.surveyId ? props.actionPlan.list.filter((ap: ActionPlan) => ap.referenceSurveyId === props.surveyId) : props.actionPlan.list
    setSurveyActionPlans(actionPlans)
    setTasksCount(actionPlans.reduce((sum: number, ap: ActionPlan) => sum + ap.tasks.length, 0))
  }, [
    props.surveyId,
    props.actionPlan.list
  ])

  useEffect(() => {
    if(tasksCount > 0){
      setIsAnimatedCounter(true)
    }
    
    const timeout: NodeJS.Timeout = setTimeout(() => {
      setIsAnimatedCounter(false)
    }, 2000)

    return () => {
      clearTimeout(timeout)
    }
  }, [
    tasksCount
  ])

  function click(actionPlan: ActionPlan){
    store.dispatch(actionPlanActivate(actionPlan))
    navigate("/tasks/edit?ap=" + actionPlan.id)
  }

  return (
    ( props._session.modules.task && surveyActionPlans.length > 0 ) ?
    <>
      <AnimatedColorSvgWidget />
      
      { isExpanded ?
      <ModalHelp
        title={t("tasks")}
        onClose={() => setIsExpanded(false)}
      >
        <FadeIn>
          <div
            className="flex flex-wrap"
            style={{ margin: 20 }}
          >
            { surveyActionPlans.map((ap: ActionPlan) => 
            <TasksActionPlanDisplayWidget
              key={ap.id}
              actionPlan={ap}
              onClick={() => click(ap)}
              showPlayButton
            />
            )}
          </div>
        </FadeIn>
      </ModalHelp>
      :
      <div
        className={"_hover" + ( isAnimated ? " _animated" : undefined )}
        onClick={() => setIsExpanded(true)}
        title={t("tasks")}
        style={{
          position: "fixed",
          borderTop: "1px solid transparent",
          borderBottom: "1px solid transparent",
          borderLeft: "1px solid transparent",
          right: 0,
          top: "40%",
          width: 50,
          zIndex: 1,
          borderTopLeftRadius: 8,
          borderBottomLeftRadius: 8
        }}
      >
        <div
          style={{
            backgroundColor: "white",
            padding: "10px",
            borderTopLeftRadius: 8,
            borderBottomLeftRadius: 8
          }}
        >
          <FontAwesomeIcon
            icon={faListCheck}
            className={isAnimated ? "list-icon-animation" : undefined}
          />
          
          <div
            className="abs red flex flex-dcol"
            style={{
              top: -10,
              paddingTop: 1,
              right: 12,
              height: 20,
              width: 21,
              borderRadius: "50%",
              textAlign: "center",
              color: "white",
              fontSize: 12,
              zIndex: 1
            }}
          >
            <Space />
            <div className={isAnimatedCounter ? "_vertical-shake" : ""}>
            {tasksCount}
            </div>
            <Space />
          </div>
        </div>
      </div>
      }

      <ReactTooltip />
    </>
    :
    <></>  
  )
}

const mapStateToProps = state => ({
  _session: state._session,
  actionPlan: state.actionPlan,
})

export default connect(mapStateToProps)(withTranslation()(TasksLinkerWidget))