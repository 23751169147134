/** 
 * ADD-LANGUAGE.MODAL
 * add language for template
 */

import { connect } from "react-redux"

import { withTranslation, WithTranslation } from "react-i18next"
import { Session } from "@/redux/_session.types";
import Chip from "@/components/chip";
import { useState } from "react";
import SearchBar from "@/components/search-bar";
import { STATUS_LOADED } from "@/redux/_status.types"
import languages from '@/translate/lang.json'
import Space from "@/components/space";
import Checkbox from "@/components/checkbox";
import { Language } from "@/redux/language.types";
import CardButton from "@/components/card-button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleDown, faChevronCircleRight } from "@fortawesome/free-solid-svg-icons";

interface StateProps extends WithTranslation{
  _session : Session
}

interface OwnProps{
  activeLanguages : string[]
  availableLanguages? : string[]
  hideFavorites?: boolean
  displaySearch? : boolean
  onToogle : Function
}

type Props = StateProps & OwnProps

function AddLanguageWidget(props:Props) {
  const { t } = props

  const displayLanguages : any[] = props.availableLanguages ? languages.filter((lg: any)=>props.availableLanguages!.indexOf(lg.code)>-1) : languages

  const [displayAll, setDisplayAll] = useState<boolean>(props.hideFavorites? true : false)
  const [displayFavorites, setDisplayFavorites] = useState<boolean>(true)
  const [showOnlyTranslatable, setShowOnlyTranslatable] = useState<boolean>(props.hideFavorites? true : false)
  const [currentSearch, setCurrentSearch] = useState<string>("")

  function isCorresponding(language: Language){
    const correspondance: boolean = language.code.includes(currentSearch.toLowerCase()) ||
      language.name.toLowerCase().includes(currentSearch.toLowerCase()) ||
      language.nativeName.toLowerCase().includes(currentSearch.toLowerCase())

    return correspondance
  }

  function getAllLanguages(){
    const allLanguages: Language[] = showOnlyTranslatable ? displayLanguages.filter((x:any)=>x.isTranslatable) : displayLanguages
    return currentSearch.length>0 ? allLanguages.filter((lg: Language)=>isCorresponding(lg)) : allLanguages
  }

  function getFavoriteLanguages(){
    return displayLanguages.filter((x:any)=>x.isFavorite).map((x:any)=>new Language(x))
  }

  return (
    <div>
      {!props.hideFavorites &&
      <div>

        <div className="_hover flex" onClick={()=>{setDisplayFavorites(!displayFavorites)}}>
          <b>{t("favorite_languages")}</b>
          <Space />
          <div>
            {getFavoriteLanguages().length + " " + t("lang_availables") + " "}
            <FontAwesomeIcon icon={displayFavorites ? faChevronCircleDown : faChevronCircleRight} />
          </div>
        </div>

        <div className="height-20"/>

        { displayFavorites &&
        <div className="flex flex-wrap">
          {getFavoriteLanguages().map((lg:Language)=>
          <CardButton
            key={lg.code}
            onClick={() => props.onToogle(lg)}
            isActive={props.activeLanguages.indexOf(lg.code) > -1}
            title={lg.name.split(";")[0].split(",")[0] + " (" + lg.code.toUpperCase() + ")"}
            image={require("@/assets/default_icons/flags/" + lg.flag + ".png")}
          />
          )}
        </div>
        }
      </div>
      }

      <div className="height-40"/>


      <div className="flex _hover" onClick={()=>{setCurrentSearch(""); setDisplayAll(!displayAll)}}>
        <b>{t("all_languages")}</b>
        <Space />
        <div>
          {getAllLanguages().length + " " + t("lang_availables") + " "}
          <FontAwesomeIcon icon={displayAll ? faChevronCircleDown : faChevronCircleRight} />
        </div>
      </div>

      <div className="height-20"/>

      {displayAll&&
      <div>
        
        <div>
          { props.displaySearch &&
          <SearchBar onSearch={setCurrentSearch} status={STATUS_LOADED}/>
          }
          <Space/>
          <Checkbox active={showOnlyTranslatable} onClick={()=>setShowOnlyTranslatable(!showOnlyTranslatable)} text={t("show_only_translatable")}/>
        </div>
        
        { getAllLanguages().length > 0 ?
        <div className="flex flex-wrap search-question-container"
          style={{
            //maxHeight: "400px",
            overflowY: "auto",
            padding: "0px 40px"
          }}>

          {getAllLanguages().map((lg: Language) => 
          <Chip key={lg.code} isWithMargin
            onClick={() => props.onToogle(lg)}
            color={props.activeLanguages.indexOf(lg.code) > -1 ? props._session.accountColors.active : ""}>
            {lg.name.split(";")[0].split(",")[0] + " (" + lg.code.toUpperCase() + ")"}
          </Chip>
          )}

        </div>
        :
        <div>
          {t("no_language")}
        </div>
        }
        
      </div>
      }
    </div>
  )

}

const mapStateToProps = state => ({
  _session : state._session
})

export default connect(mapStateToProps)(withTranslation()(AddLanguageWidget))