/** 
 * REGROUPING-VIEWER
 */

import { sum } from "lodash"
import { useState } from "react"
import { withTranslation, WithTranslation } from "react-i18next"
import { Attribute } from "@/redux/attribute.types"
import { Regrouping } from "@/redux/regrouping.types"
import Space from "./space"

interface OwnProps extends WithTranslation{
  regrouping : Regrouping       //Regrouping to create/update
  attributes : Attribute[]      //new attributes to add (can be empty)
  name : string
}

function RegroupingViewer(props:OwnProps) {
  const { t } = props

  const [expanded, setExpanded] = useState<boolean>(false)

  return (
    <div  onClick={()=>setExpanded(!expanded)}
      className="regrouping">

      <div className="flex regrouping-attributes">

        <div className={(props.regrouping.name !== props.name || props.regrouping.usersCount === 0) ? "orange-t" : ""}>
          <b>{props.name}</b>
        </div>

        <Space/>

        <span className={sum(props.attributes.map((x:Attribute)=>x.usersCount))>1 ? "orange-t" : "grey-t" }>
          { props.regrouping.usersCount + sum(props.attributes.map((x:Attribute)=>x.usersCount))}
        </span>

      </div>


      {props.attributes.map((x:Attribute)=>
      <div key={x.id} className="orange-t flex regrouping-attributes">

        {t(x.name)}
            
        <Space/>

        { x.usersCount > 0 &&
        x.usersCount
        }

      </div>
      )}


      {props.regrouping.Attributes.length>0&&
        (expanded?
        <div>
          {props.regrouping.Attributes.map((x:Attribute)=>
          <div key={x.id} className="grey-t flex regrouping-attributes" >

            {t(x.name)}

            <Space/>

            { x.usersCount > 0 &&
            x.usersCount
            }

          </div>
          )}
        </div>
        :
        <div className="grey-t flex height-40 " >
          <Space/>

            <u className="_hover">
              {t("utils_show_others", {
                count : props.regrouping.Attributes.length,
                s : props.regrouping.Attributes.length > 1 ? "s" : ""
              })} 
            </u>
      
          <Space/>
        </div>
        )
      }
    </div>
  )
}

export default (withTranslation()(RegroupingViewer))