/**
 * RPS.HELP
 * Tutoriel about RPS
 */
import { useEffect, useRef } from "react"
import HelpModal from "@/components/modal-help"
import { WithTranslation, withTranslation } from "react-i18next"
import ReactMarkdown from "react-markdown"

export type Rps = "iso-strain" | "job-strain" | "actives" | "passives" | "relaxed" | "nc"

interface OwnProps {
  currentHelp: Rps | string | null
  onClose: Function
}

type Props = OwnProps & WithTranslation

function RpsHelp(props: Props) {
  const { t } = props

  //Références aux différentes sections de l'aide
  const isoStrain: any = useRef(null)
  const jobStrain: any = useRef(null)
  const actives: any = useRef(null)
  const passives: any = useRef(null)
  const relaxed: any = useRef(null)
  const nc: any = useRef(null)

  //Scroll to section
  useEffect(() => {
    if (props.currentHelp === "iso-strain") isoStrain.current.scrollIntoView()
    else if (props.currentHelp === "job-strain") jobStrain.current.scrollIntoView()
    else if (props.currentHelp === "actives") actives.current.scrollIntoView()
    else if (props.currentHelp === "passives") passives.current.scrollIntoView()
    else if (props.currentHelp === "relaxed") relaxed.current.scrollIntoView()
    else if (props.currentHelp === "nc") jobStrain.current.scrollIntoView()
  }, [props.currentHelp])

  return (
    <HelpModal onClose={props.onClose} title={t("rps_title")}>
      <div>
        <h2>{t("rps_help_text_1")}</h2>

        <p>{t("rps_help_text_1")}</p>

        <p>{t("rps_help_text_2")}</p>

        <ul>
          <li>
            <ReactMarkdown>{t("rps_help_ul_1")}</ReactMarkdown>
          </li>
          <li>
            <ReactMarkdown>{t("rps_help_ul_2")}</ReactMarkdown>
          </li>
          <li>
            <ReactMarkdown>{t("rps_help_ul_3")}</ReactMarkdown>
          </li>
        </ul>

        <h2>{t("rps_help_text_2")}</h2>

        <h3 ref={isoStrain}>{t("rps_stressed")}</h3>

        <p>
          <ReactMarkdown>{t("rps_help_text_3")}</ReactMarkdown>
        </p>

        <h3 ref={jobStrain}>{t("rps_job_strain")}</h3>

        <p>
          <ReactMarkdown>{t("rps_help_text_4")}</ReactMarkdown>
        </p>

        <h3 ref={actives}>{t("rps_active")}</h3>

        <p>
          <ReactMarkdown>{t("rps_help_text_5")}</ReactMarkdown>
        </p>

        <h3 ref={passives}>{t("rps_passive")}</h3>

        <p>
          <ReactMarkdown>{t("rps_help_text_6")}</ReactMarkdown>
        </p>

        <h3 ref={relaxed}>{t("rps_relaxed")}</h3>

        <p>
          <ReactMarkdown>{t("rps_help_text_7")}</ReactMarkdown>
        </p>

        <h3 ref={nc}>{t("rps") + " " + t("rps_nc")}</h3>

        <p>{t("rps_help_text_8")}</p>
      </div>
    </HelpModal>
  )
}

export default withTranslation()(RpsHelp)
