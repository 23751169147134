/** 
 * TABS
 */
import { map } from "lodash"
import { connect } from "react-redux"
import { Session } from "@/redux/_session.types"

interface StateProps {
  _session : Session
}

interface OwnProps{
  active : string
  onClick : Function
  tabs : any
}

type Props = StateProps & OwnProps

function Tabs(props:Props) {

  //Get class
  //Depends of the active tab
  function getClassName(tabId){
    let className = "selector-tabs-tab flex"

    if (tabId === props.active){
      className = className + " selector-tabs-tab-active"
    }else{
      className = className + " grey-t"
    }
    
    return className

  }

  //Click on tab
  function clickTab(tab){
    props.onClick(tab)
  }

  //Build tab array from the object in props
  function getTabs(){
    return map(props.tabs, (label, id) => ({ label, id }))
  }

  return (
    <div className="flex selector-tabs">

      { getTabs().map(tab =>
      <div className={getClassName(tab.id)}
        key={tab.id}
        onClick={() => clickTab(tab.id)}>

        <div style={{ margin : 'auto' }}>
          { tab.label }
        </div>

        { tab.id === props.active &&
        <div style={{ 
            backgroundColor : props._session.accountColors.active 
          }} 
          className="selector-tabs-tab-active-bottom">
        </div>
        }

      </div>
      )
      }
      
    </div>
  )

}


const mapStateToProps = state => ({
  _session : state._session
})

export default connect(mapStateToProps)(Tabs)