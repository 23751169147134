/**
 * REPORT-LIST.WIDGET
 * Display saved reports for an account
 */

import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Survey, SurveyState } from "@/redux/survey.types";
import { useEffect, useState } from "react";
import { store } from "@/index";
import { reportActivate, reportFetch, reportGet } from "@/redux/report.actions";
import { Report, ReportState } from "@/redux/report.types";
import { groupBy } from "lodash";
import CardButton from "@/components/card-button";
import { faClipboard, faCopy, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { surveyActivate, surveyFetch, surveyGet } from "@/redux/survey.actions";
import { STATUS_LOADED, STATUS_LOADING } from "@/redux/_status.types";
import PageLoader from "@/components/page-loader";
import PagePlaceholder from "@/components/page-placeholder";
import ReportAddModal from "@/modals/report-add.modal";
import ReportDeleteModal from "@/modals/report-delete.modal";
import PageHeader from "@/components/page-header";
import Button from "@/components/button";
import GetSurveyResultsModal from "@/modals/get-survey-results.modal";
import ReportGetDataModal from "@/modals/report-get-data.modal";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { surveyFetch_Archive } from "@/redux/_archive.actions";
import ReportDuplicateModal from "@/modals/report-duplicate.modal";

interface StateProps{
  report: ReportState
  survey: SurveyState
}

type Props = StateProps & WithTranslation

const MODAL_ADD:string = "MODAL_ADD"
const MODAL_DELETE:string = "MODAL_DELETE"
const MODAL_DUPLICATE:string = "MODAL_DUPLICATE"
const MODAL_GET_SURVEY_RESULTS:string = "MODAL_GET_SURVEY_RESULTS"
const MODAL_GET_REPORT_DATA:string = "MODAL_GET_REPORT_DATA"

function ReportListWidget(props:Props){
  const { t } = props
  const navigate:NavigateFunction = useNavigate()

  const [currentModal, setCurrentModal] = useState<string | null>(null)

  const [isLoading, setIsLoading] = useState(true)

  //Load all reports for the account at init
  useEffect(() => {

    async function loadData(){

      //Fetch surveys and reports
      const surveys:any = await store.dispatch(surveyFetch())
      const surveysArchive:any = await store.dispatch(surveyFetch_Archive())
      const reports: any = await store.dispatch(reportFetch())

      //Update store
      store.dispatch(surveyGet(surveys.error ? [] : surveys, surveysArchive.error ? [] : surveysArchive, false))
      store.dispatch(reportGet(reports.error ? [] : reports))

      //End of loading
      setIsLoading(false)
      
    }

    loadData()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  //Click on report to access to the edit report page
  //Open modal that fetch all the data
  function click(report: Report){

    store.dispatch(reportActivate(report))
    const survey: Survey | undefined = props.survey.list.find((x: Survey) => x.id === report.SurveyId)
    
    if(survey){
      store.dispatch(surveyActivate(survey))
      setCurrentModal(MODAL_GET_SURVEY_RESULTS)
    }else{
      setCurrentModal(MODAL_GET_REPORT_DATA)
    }

  }

  function getSurveyName(surveyId: string | null){
    const survey: Survey | undefined = props.survey.list.find((x: Survey) => x.id === surveyId)
    return survey ? survey.name : t("no_survey_active")
  }

  //Click on the survey
  function clickAction(report: Report, action: string){
    store.dispatch(reportActivate(report))
    switch(action){
      case "ACTION_DUPLICATE":
        return setCurrentModal(MODAL_DUPLICATE)
      case "ACTION_DELETE":
        return setCurrentModal(MODAL_DELETE)
      case "ACTION_EDIT":
        return click(report)
      default:
        console.log("action_not_found")
    }
  }


  return(
    <PageLoader status={isLoading ? STATUS_LOADING : STATUS_LOADED}>
      
      { currentModal === MODAL_ADD &&
      <ReportAddModal onClose={() => setCurrentModal(null)} onNext={() => setCurrentModal(MODAL_GET_SURVEY_RESULTS)}/>
      }

      { currentModal === MODAL_DELETE &&
      <ReportDeleteModal onClose={() => setCurrentModal(null)}/>
      }

      { currentModal === MODAL_DUPLICATE &&
      <ReportDuplicateModal onClose={() => setCurrentModal(null)}
        onNext={(report:Report) => click(report)}
      />
      }

      { currentModal === MODAL_GET_SURVEY_RESULTS &&
      <GetSurveyResultsModal onClose={() => setCurrentModal(MODAL_GET_REPORT_DATA)}/>
      }

      { currentModal === MODAL_GET_REPORT_DATA &&
      <ReportGetDataModal onClose={() => navigate("/report/edit")}/>
      }

      <PageHeader title={t("report_list")}
        subtitle={t("report_help")}>
          
        { props.report.list.length > 0 &&
        <Button onClick={() => setCurrentModal(MODAL_ADD)}
          className="primary">
          {t("report_edit")}
        </Button>
        }

      </PageHeader>

      { props.report.list.length > 0 ?
      <div>

        { Object.entries(groupBy(props.report.list, "SurveyId")).map(([key, values]) =>
        <div key={key}>

          <h3>{getSurveyName(key)}</h3>

          <div className="flex flex-wrap">
            {values.map((x: Report) =>
            <CardButton
              key={x.id}
              icon={faClipboard}
              isActive={true}
              title={x.name}
              subtitle={t("report_created_at", { date : x.date })}
              onClick={() => click(x)}
              actions={[
                { id : "ACTION_EDIT", label : t("utils_edit"), icon : faEdit },
                { id : "ACTION_DUPLICATE", label : t("utils_duplicate"), icon : faCopy },
                { id : "ACTION_DELETE", label : t("utils_delete"), icon : faTrash }
              ]}
              onAction={(actionId: string) => clickAction(x, actionId)}
            />
            )}
          </div>

          <div className="height-40" />
        </div>
        )}
      </div>
      :
      <PagePlaceholder title={props.survey.list.length > 0 ? t("report_empty") : t("report_survey_empty")}
        onClick={props.survey.list.length > 0 ? () => setCurrentModal(MODAL_ADD) : undefined}
        image="start"
        button={props.survey.list.length > 0 ? t("report_edit") : undefined}
      />
      }

    </PageLoader>
  )
}

const mapStateToProps = (state) => ({
  report: state.report,
  survey: state.survey
})

export default connect(mapStateToProps)(withTranslation()(ReportListWidget))