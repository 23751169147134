/**
 * TEMPLATE.REDUCERS
 * Operations on questionnaires
 */
import { STATUS_LOADED } from "./_status.types";
import { Template, TemplateState, TEMPLATE_ADD, TEMPLATE_REMOVE, TEMPLATE_EDIT, TEMPLATE_EDIT_DESCRIPTION, TEMPLATE_EDIT_NAME, TEMPLATE_ACTIVATE, TEMPLATE_GET, TEMPLATE_INIT, TEMPLATE_STATUS, TEMPLATE_EDIT_LANGUAGE, TEMPLATE_GET_COUNT, TEMPLATE_GET_COUNT_SEARCH } from "./template.types";
import { v4 as uuid } from "uuid";
import i18n from "@/translate/i18n";
import { t } from "@/translate/t";

let active:Template

const initialState: TemplateState = {
  active : new Template(),
  count : 0,
  countSearch : 0,
  list : [],
  status : STATUS_LOADED
}

export default function templateReducer(
  state = initialState,
  action: any
): TemplateState {
  switch (action.type) {

    //Set active template and active language
    case TEMPLATE_ACTIVATE:
      active = new Template(action.payload.template)
      if (active.languages.indexOf(i18n.language) > -1){
        active.language = i18n.language
      }else{
        active.language = active.languages[0]
      }
      return Object.assign({}, state, {
        active
      })

    //Add new template
    case TEMPLATE_ADD:
      active = new Template()
      active.id = uuid()
      active.name[action.payload.language] = t("template_new")
      active.languages = [action.payload.language]
      return Object.assign({}, state, { active })

    //Edit template
    case TEMPLATE_EDIT:
      active = Object.assign(state.active, { [action.payload.key] : action.payload.value })
      return Object.assign({}, state, { active })

    //Edit description (with language)
    case TEMPLATE_EDIT_DESCRIPTION:
      active = new Template(state.active)
      active.description[action.payload.description] = action.payload.value
      return Object.assign({}, state, { active })

    //Add or remove language for the template
    case TEMPLATE_EDIT_LANGUAGE:
      active = new Template(state.active)
      if (active.languages.indexOf(action.payload.language) > -1){
        active.languages = active.languages.filter(x => x !== action.payload.language)
      }else{
        active.languages.push(action.payload.language)
        if (!active.name[action.payload.language]){
          active.name[action.payload.language] = ""
        }
        if (!active.name[action.payload.language]){
          active.description[action.payload.language] = ""
        }
      }
      return Object.assign({}, state, { active })

    //Edit template name (with language)
    case TEMPLATE_EDIT_NAME:
      active = new Template(state.active)
      active.name[action.payload.language] = action.payload.value
      return Object.assign({}, state, { active })

    //Save list of template in store
    case TEMPLATE_GET:
      return Object.assign({}, state, {
        list : action.payload.templates.map((x:any) => new Template(x))
      })

    //Save count total of templates
    case TEMPLATE_GET_COUNT:
      return Object.assign({}, state, {
        count : action.payload.count
      })

    //Save count of templates searched
    case TEMPLATE_GET_COUNT_SEARCH:
      return Object.assign({}, state, {
        countSearch : action.payload.countSearch
      })

    //Init store
    case TEMPLATE_INIT:
      return Object.assign({}, state, {
        active : new Template(state.active),
        list : state.list.map((x:any) => new Template(x)),
        status : initialState.status
      })
    
    //Remove questionnaire from list
    case TEMPLATE_REMOVE:
      return Object.assign({}, state, { 
        list : state.list.filter(x => x.id !== action.payload.id)
      })

    //Update status
    case TEMPLATE_STATUS:
      return Object.assign({}, state, {
        status : action.payload.status
      })

    default:
      return state

  }
}