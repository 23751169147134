/** 
 * TOPIC.REDUCERS
 * Edit card of questionnaire
 */

import { Question, QuestionsTopic } from "./question.types";
import { Topic, TopicState, TOPIC_EDIT, TOPIC_EDIT_NAME, TOPIC_EDIT_QUESTION, TOPIC_ACTIVATE, TOPIC_GET, TOPIC_INIT, TOPIC_STATUS, TOPIC_ADD, TOPIC_EDIT_AXES, TOPIC_REMOVE_AXES, TOPIC_EDIT_PROPOSITION, TOPIC_REMOVE_PROPOSITION, TOPIC_ADD_PROPOSITION, TOPIC_REPLACE_QUESTION, TOPIC_EDIT_TRIGGER, TOPIC_REMOVE_QUESTION, TOPIC_SWAP, TOPIC_SAVE, TOPIC_SWAP_PROPOSITION, TOPIC_COPY, TOPIC_COPY_QUESTION, TOPIC_REMOVE, TOPIC_ADD_QUESTION, TOPIC_EDIT_ANNOTATIONS, TOPIC_EDIT_DEFAULT_SCALE_COUNT, TOPIC_EDIT_DEFAULT_SCALE_NO_OPINION } from "./topic.types";
import { STATUS_LOADED } from "./_status.types";
import { v4 as uuid } from "uuid";
import { Axis } from "./axis.types";
import { Proposition } from "./proposition.types";
import { flatten, sortBy } from "lodash";

let active:Topic
let activeQuestion:Question | undefined
let activeProposition: Proposition | undefined
let currentIndex:number
let list:Topic[]

const initialState: TopicState = {
  active : new Topic(),
  isEdited : false,
  list : [],
  listDeleted : [],
  status : STATUS_LOADED
}

export default function topicReducer(
  state = initialState,
  action: any
): TopicState {
  switch (action.type) {

    //Add a new card
    //Set axis from payload and add a new empty question
    //Set this question as question primary
    //Add the topic to the list of topics
    case TOPIC_ADD: {
      active = new Topic()
      active.id = uuid()

      const questions: Question[] = flatten(state.list.map((x: Topic) => x.Questions))
      
      active.isAtStart = action.payload.isAtStart

      active.Axis = action.payload.axis
      active.Questions = [
        new Question({ 
          id : uuid(),
          draft: action.payload.isDraft,
          fromSupervisor : action.payload.fromSupervisor,
          updated : true,
          choiceCount : active.isAtStart ? 1 : null,
          type : active.isAtStart ? "choice" : "scale",
          withScore : active.isAtStart ? false : true,
          responseCount: questions.filter((x: Question) => x.type === "scale" && x.responseCount === 4).length > questions.filter((x: Question) => x.type === "scale" && x.responseCount === 5).length ? 4 : 5
        })
      ]

      active.questionPrimaryId = active.Questions[0].id

      if (action.payload.addAtTheEnd){
        list = state.list.concat([active])
      } else if (active.isAtStart){
        list = [active].concat(state.list)
      } else{
        currentIndex = state.list.findIndex(x => x.id === state.active.id) + 1
        list = state.list.slice(0, currentIndex).concat([active]).concat(state.list.slice(currentIndex))
      }
       
      return Object.assign({}, state, {
        active,
        list
      })
    }

    //Add a new multiple choice item
    //Update list of proposition for the active question
    //Update topic
    case TOPIC_ADD_PROPOSITION:
      active = new Topic(state.active)
      activeQuestion = new Question(state.active.Questions.find(x => x.id === action.payload.questionId))
      
      if (activeQuestion){

        activeQuestion.Propositions = activeQuestion.Propositions.concat([new Proposition({ id : uuid() })])
        activeQuestion.updated = true

        active.Questions = state.active.Questions.map(x => x.id === action.payload.questionId ? new Question(activeQuestion) : x)
        active.updated = true
        
      }

      return Object.assign({}, state, { 
        active,
        list : state.list.map(x => x.id === active.id ? new Topic(active) : x),
        isEdited : true
      })

    //Add new question for a topic
    //If primary question insert before first element and set primary question
    //Else add at the end of the array of questions
    //Set default trigger value as '<'
    case TOPIC_ADD_QUESTION:
      active = new Topic(state.active)
      
      if (action.payload.isPrimary){
        active.questionPrimaryId = active.Questions[0].id
        active.Questions = [
          new Question({ 
            id : uuid(),
            fromSupervisor : action.payload.fromSupervisor,
            updated : true
          })
        ].concat(active.Questions)
      }else{

        activeQuestion = new Question({ 
          id : uuid(),
          fromSupervisor : action.payload.fromSupervisor,
          updated : true,
          type : "choice",
          QuestionsTopic : new QuestionsTopic({
            triggerValue : action.payload.triggerValue ? action.payload.triggerValue : '<'
          })
        })

        active.Questions = active.Questions.concat(activeQuestion)
        active.secondaryQuestionsOrder = active.secondaryQuestionsOrder.concat(activeQuestion.id)

      }

      active.updated = true

      return Object.assign({}, state, {
        active,
        list : state.list.map(x => x.id === active.id ? new Topic(active) : x),
        isEdited : true
      })

    //Set active card
    case TOPIC_ACTIVATE:
      return Object.assign({}, state, {
        active : new Topic(action.payload.topic)
      })
    
    //Copy existing card
    //Set axis at the same time
    case TOPIC_COPY:
      active = new Topic(action.payload.topic)
      active.Axis = action.payload.axis
      active.updated = true
      return Object.assign({}, state, {
        active,
        list : state.list.concat([active]),
        isEdited : true
      })

    //Copy an existing to another topic
    //If there are no primary question defined, set this question as primary
    case TOPIC_COPY_QUESTION:
      active = new Topic(state.list.find(x => x.id === action.payload.topicId))
      activeQuestion = new Question(action.payload.question)
      activeQuestion.updated = true

      if (!active.questionPrimaryId){
        active.questionPrimaryId = activeQuestion.id
      }

      active.Questions = active.Questions.concat([activeQuestion])
      active.updated = true

      return Object.assign({}, state, {
        active,
        list : state.list.map(x => x.id === active.id ? new Topic(active) : x),
        isEdited : true
      })
  
    //Edit card informations
    //If the edited key is questionPrimaryId > change order
    case TOPIC_EDIT:
      active = new Topic(state.active)
      active[action.payload.key] = action.payload.value
      active.updated = true

      if (action.payload.key === "questionPrimaryId"){
        active.Questions = sortBy(active.Questions, q => q.id === action.payload.value ? 0 : 1)
      }

      return Object.assign({}, state, { 
        active,
        isEdited : true,
        list : state.list.map(x => x.id === active.id ? new Topic(active) : x)
      })

    //Edit annotations for a topic
    case TOPIC_EDIT_ANNOTATIONS:
      active = new Topic(state.active)
      active.Annotations = action.payload.annotations
      active.updated = true

      return Object.assign({}, state, { 
        active,
        list : state.list.map(x => x.id === active.id ? new Topic(active) : x)
      })

    //Edit axes for a topic
    case TOPIC_EDIT_AXES:
      list = state.list.filter(x => x.Axis?.id === action.payload.axis.id ? x.Axis = new Axis(action.payload.axis) : x)

      active = new Topic(state.active)
      if (state.active.Axis?.id === action.payload.axis.id){
        active.Axis = new Axis(action.payload.axis)
        active.updated = true
      }

      return Object.assign({}, state, { 
        active,
        isEdited : true,
        list : state.list.map(x => x.id === active.id ? new Topic(active) : x)
      })

    case TOPIC_EDIT_DEFAULT_SCALE_COUNT:
      list = []

      state.list.forEach(topic => {
        let edit = false

        topic.Questions.forEach(question => {

          if (question.isLikert && question.responseCount !== action.payload.defaultScaleCount){
            question.responseCount = action.payload.defaultScaleCount
            question.updated = true
            edit = true
          }

          if (edit){
            topic.updated = true
          }

        })

        list.push(topic)

      })


      return Object.assign({}, state, {
        active : new Topic(list.find(x => x.id === state.active.id)),
        list,
        isEdited : true
      })

    case TOPIC_EDIT_DEFAULT_SCALE_NO_OPINION:
      list = []

      state.list.forEach((topic: Topic) => {
        let edit: boolean = false

        topic.Questions.forEach((question: Question) => {

          if (question.type === "scale"){
            question.QuestionsTopic.disableNoOpinion = action.payload.disableNoOpinion
            question.updated = true
            edit = true
          }

          if (edit){
            topic.updated = true
          }

        })

        list.push(topic)

      })


      return Object.assign({}, state, {
        active : new Topic(list.find(x => x.id === state.active.id)),
        list,
        isEdited : true
      })

    //Edit name of the topic
    case TOPIC_EDIT_NAME:
      active = new Topic(state.active)
      active.name[action.payload.language] = action.payload.value
      active.updated = true
      return Object.assign({}, state, { 
        active,
        isEdited : true,
        list : state.list.map(x => x.id === active.id ? new Topic(active) : x)
      })

    //Edit proposition
    //Update list of propositions for the active question
    //Update topic
    case TOPIC_EDIT_PROPOSITION:
      active = new Topic(state.active)
      activeQuestion = new Question(state.active.Questions.find(x => x.id === action.payload.questionId))
      
      if (activeQuestion){

        activeProposition = new Proposition(activeQuestion.Propositions.find(x => x.id === action.payload.propositionId))
        activeProposition[action.payload.key] = action.payload.value
        activeProposition.updated = true
        activeQuestion.Propositions = activeQuestion.Propositions.map(x => x.id === action.payload.propositionId ? new Proposition(activeProposition) : x)
        activeQuestion.updated = true

        active.Questions = state.active.Questions.map(x => x.id === action.payload.questionId ? new Question(activeQuestion) : x)
        active.updated = true
        
      }

      return Object.assign({}, state, { 
        active,
        list : state.list.map(x => x.id === active.id ? new Topic(active) : x),
        isEdited : true
      })

    //Edit a question
    //Edit the topic after quesiton is edited
    case TOPIC_EDIT_QUESTION:
      active = new Topic(state.active)
      activeQuestion = new Question(state.active.Questions.find(x => x.id === action.payload.questionId))
      
      if (activeQuestion){
        
        //If a question of idtree is updated
        //> create a new id
        if (activeQuestion.fromSupervisor && action.payload.key === 'fromSupervisor' && action.payload.value === false){
          active.questionsDeleted.push(activeQuestion.id)
          activeQuestion.id = uuid()
          activeQuestion.Propositions.forEach(proposition => {
            activeQuestion?.propositionsDeleted.push(activeQuestion.id)
            proposition.id = uuid()
            proposition.updated = true
          })
        }

        //Do edits
        activeQuestion[action.payload.key] = action.payload.value
        activeQuestion.updated = true
        
        //If quesiton without score
        //> reset tag and reverse
        if (action.payload.key === 'withScore' && action.payload.value === false){
          activeQuestion.reverse = false
          activeQuestion.special = null
        }

        //Si type !== scale > reset score / reverse /special
        if (action.payload.key === 'type'){
          if (action.payload.value !== 'scale'){
            activeQuestion.withScore = false
            activeQuestion.reverse = false
            activeQuestion.special = null
          }else{
            activeQuestion.withScore = true
          }
        }

        //Update topic
        active.Questions = state.active.Questions.map(x => x.id === action.payload.questionId ? new Question(activeQuestion) : x)
        active.updated = true

        //If not question primary > set this one as primary
        if (!active.questionPrimary){
          active.questionPrimaryId = activeQuestion.id
        }

      }

      return Object.assign({}, state, { 
        active,
        list : state.list.map(x => x.id === active.id ? new Topic(active) : x),
        isEdited : true
      })

    //Edit trigger for the question (relationship between question and topic)
    case TOPIC_EDIT_TRIGGER:
      active = new Topic(state.active)
      activeQuestion = new Question(state.active.Questions.find(x => x.id === action.payload.questionId))

      if (activeQuestion){
        activeQuestion.QuestionsTopic[action.payload.key] = action.payload.value
        activeQuestion.updated = true
        active.Questions = state.active.Questions.map(x => x.id === action.payload.questionId ? new Question(activeQuestion) : x)
        active.updated = true
      }

      return Object.assign({}, state, { 
        active,
        list : state.list.map(x => x.id === active.id ? new Topic(active) : x),
        isEdited : true
      })

    //Save topics into the score
    //Si there are many topics, set the first one as active
    case TOPIC_GET:
      list = action.payload.topics.map((x:any) => new Topic(x))
      return Object.assign({}, state, {
        active : list.length ? new Topic(list[0]) : new Topic(),
        isEdited : false,
        list,
        listDeleted : []
      })

    //Init store
    case TOPIC_INIT:
      return Object.assign({}, state, {
        active : new Topic(state.active),
        list : state.list.map((x:any) => new Topic(x)),
        listDeleted : state.listDeleted,
        status : STATUS_LOADED
      })

    //Remove topic
    case TOPIC_REMOVE:
      return Object.assign({}, state, {
        active : action.payload.id === state.active.id ? new Topic(state.list[0]) : new Topic(state.active),
        list : state.list.filter(x => x.id !== action.payload.id),
        listDeleted : state.listDeleted.concat([action.payload.id]),
        isEdited : true
      })

    //Remove axis
    //Remove all questions
    case TOPIC_REMOVE_AXES:
      list = state.list.filter(x => x.Axis?.id !== action.payload.axisId)
      
      active = new Topic(state.active)
      if (state.active.Axis?.id === action.payload.axisId){
        active = new Topic(list[0])
      }

      return Object.assign({}, state, {
        listDeleted : state.listDeleted.concat(state.list.filter(x => x.Axis?.id === action.payload.axisId).map(x => x.id)),
        active,
        isEdited : true,
        list
      })

    //Remove item choice
    //Update question and topic after that
    case TOPIC_REMOVE_PROPOSITION:
      active = new Topic(state.active)
      activeQuestion = new Question(state.active.Questions.find(x => x.id === action.payload.questionId))
    
      if (activeQuestion){

        activeQuestion.Propositions = activeQuestion.Propositions.filter(x => x.id !== action.payload.propositionId)
        activeQuestion.propositionsDeleted.push(action.payload.propositionId)
        activeQuestion.updated = true
        
        active.Questions = state.active.Questions.map(x => x.id === action.payload.questionId ? new Question(activeQuestion) : x)
        active.updated = true
      }

      return Object.assign({}, state, { 
        active,
        list : state.list.map(x => x.id === active.id ? new Topic(active) : x),
        isEdited : true
      })

    //Remove quesiton
    //If the question is a primary question remove all card
    //Else remove only the question
    case TOPIC_REMOVE_QUESTION:
      active = new Topic(state.active)
      
      if (active.questionPrimaryId === action.payload.questionId){
        active = new Topic(state.list.find(x => x.id !== action.payload.topicId))
        return Object.assign({}, state, { 
          active,
          list : state.list.filter(x => x.id !== action.payload.topicId),
          listDeleted : state.listDeleted.concat([action.payload.topicId]),
          isEdited : true
        })
      }else{
        active.Questions = active.Questions.filter(x => x.id !== action.payload.questionId)
        active.questionsDeleted.push(action.payload.questionId)
        active.updated = true
        active.secondaryQuestionsOrder = active.secondaryQuestionsOrder.filter(x => x !== action.payload.questionId)
        return Object.assign({}, state, { 
          active,
          list : state.list.map(x => x.id === active.id ? new Topic(active) : x),
          isEdited : true
        })
      }

    //Replace a question by a new
    //Update topic name and questionPrimaryId (if it is a primary question)
    case TOPIC_REPLACE_QUESTION:
      active = new Topic(state.active)
      active.updated = true
      active.Questions = [new Question(action.payload.question)]
      
      if (action.payload.question.Topics.length){
        active.name = active.Questions[0].Topics[0].name
      }

      active.questionPrimaryId = action.payload.question.id

      return Object.assign({}, state, { 
        active,
        list : state.list.map(x => x.id === active.id ? new Topic(active) : x),
        isEdited : true
      })

    //Set the topic as updated
    //Remove all "updated" values
    case TOPIC_SAVE:
      list = state.list.filter(x => new Topic(x))

      list.forEach(topic => {
        topic.updated = false

        topic.Annotations.forEach(annotation => {
          annotation.updated = false
        })

        topic.Questions.forEach(question => {
          question.updated = false

          question.Propositions.forEach(proposition => {
            proposition.updated = false
          })

        })

      })

      return Object.assign({}, state, {
        list : list.map(x => new Topic(x)),
        isEdited : false
      })

    //Update status
    case TOPIC_STATUS:
      return Object.assign({}, state, {
        status : action.payload.status
      })

    //Change order of a card
    case TOPIC_SWAP:
      active = new Topic(state.list.find(x => x.id === action.payload.sourceId))
      currentIndex = state.list.findIndex(x => x.id === action.payload.destinationId)
      list = state.list.filter(x => x.id !== active.id)
      list = list.slice(0, currentIndex).concat([active]).concat(list.slice(currentIndex))
      return Object.assign({}, state, { 
        list,
        isEdited : true
      })

    //Change order for a multiple choice item
    //Edit question and topic after that
    case TOPIC_SWAP_PROPOSITION:
      active = new Topic(state.active)
      activeQuestion = new Question(state.active.Questions.find(x => x.id === action.payload.questionId))

      if (activeQuestion){
        activeProposition = new Proposition(activeQuestion.Propositions.find(x => x.id === action.payload.sourceId))
        currentIndex = activeQuestion.Propositions.findIndex(x => x.id === action.payload.destinationId)
        
        activeQuestion.Propositions = activeQuestion.Propositions.filter(x => x.id !== action.payload.sourceId)
        activeQuestion.Propositions = activeQuestion.Propositions.slice(0, currentIndex).concat([activeProposition]).concat(activeQuestion.Propositions.slice(currentIndex))
        activeQuestion.propositionsOrder = activeQuestion.Propositions.map(x => x.id)
        activeQuestion.updated = true
        
        active.Questions = state.active.Questions.map(x => x.id === action.payload.questionId ? new Question(activeQuestion) : x)
        active.updated = true

      }
      
      return Object.assign({}, state, { 
        active,
        list : state.list.map(x => x.id === active.id ? new Topic(active) : x),
        isEdited : true
      })

    default:
      return state

  }

}