/**
 * PROJECT-RENAME.MODAL
 * Change name of the project
 */
import { useState } from "react";
import Modal from "@/components/modal";
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { ProjectState } from "@/redux/project.types";
import TextInput from "@/components/text-input";
  
interface StateProps extends WithTranslation{
  project : ProjectState
}

interface OwnProps{
  onClose : Function
}

type Props = StateProps & OwnProps

function ProjectRenameModal(props:Props) {
  const { t } = props

  const [projectName, setProjectName] = useState(props.project.active.name)

  function close(isSaved:boolean){
    props.onClose(isSaved, projectName)
  }

  return (
    <Modal isCloseButtonVisible
      onNext={() => close(true)}
      onClose={() => close(false)}
      title={t("project_rename")}>

      <TextInput autoFocus
        onChange={(e) => setProjectName(e.value)}
        title={t("project_name")}
        value={projectName}
      />

      <div className="height-20"/>

    </Modal>

  )
  
}

const mapStateToProps = state => ({
  project : state.project
})

export default connect(mapStateToProps)(withTranslation()(ProjectRenameModal))