/***
 * COMISSIONS
 * List of commissions
 */

import Dropdown from "@/components/dropdown"
import { useEffect, useState } from "react"
import { store } from "@/index"
import { commissionGet } from "@/redux/_integration.actions"
import LoadingModal from "@/modals/loading.modal"
import ListItem from "@/components/list-item"
import Card from "@/components/card"
import Space from "@/components/space"
import ListCell from "@/components/list-cell"
import Chip from "@/components/chip"

type IdtreeUserType = "CS" | "AE"

interface IdtreeUser {
  id: string
  type: IdtreeUserType
}

interface Period {
  id: boolean
  value: string
}

function CommissionsRoute() {
  const [queryParams] = useState(new URLSearchParams(window.location.search))

  const [idtreeUsers] = useState<IdtreeUser[]>([
    { id: "Cris", type: "AE" },
    { id: "Nicolas", type: "CS" },
    { id: "Samy", type: "AE" },
    { id: "Thomas", type: "AE" }
  ])

  const [periods] = useState<Period[]>([
    { id: true, value: "Le mois précédent" },
    { id: false, value: "Ce mois-ci" }
  ])

  const [hideFilters] = useState(queryParams.get("hideFilters") === "true")

  const [activeIdtreeUser, setActiveIdtreeUser] = useState<null | IdtreeUser>(
    initIdtreeUser()
  )
  const [activePeriod, setActivePeriod] = useState<Period>(initPeriod())
  const [commission, setCommission] = useState(0)
  const [details, setDetails] = useState<any[]>([])
  const [amountNew, setAmountNew] = useState(0)
  const [amountResell1, setAmountResell1] = useState(0)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    async function load() {
      if (activeIdtreeUser) {
        setIsLoading(true)

        const response: any = await store.dispatch(
          commissionGet(
            activeIdtreeUser.id,
            activePeriod.id,
            activeIdtreeUser.type
          )
        )

        if (!response.error) {
          setCommission(response.commission)
          setAmountNew(response.amountNew)
          setAmountResell1(response.amountResell1)
          setDetails(response.details)
        }

        setIsLoading(false)
      }
    }

    load()
  }, [activeIdtreeUser, activePeriod])

  function initIdtreeUser() {
    const user = queryParams.get("user")
    if (user) {
      const searchedUser = idtreeUsers.find((x) => x.id === user)
      return searchedUser ? searchedUser : null
    } else {
      return null
    }
  }

  function initPeriod() {
    const period = queryParams.get("period")
    return period === "current" ? periods[1] : periods[0]
  }

  return (
    <div style={{ padding: "20px 40px" }} className="flex1">
      {isLoading && <LoadingModal />}

      {!hideFilters && (
        <div className="flex" style={{ marginBottom: 40 }}>
          <Dropdown
            active={activeIdtreeUser?.id}
            displayField="id"
            list={idtreeUsers}
            title="Collaborateur"
            onSelect={(e) => setActiveIdtreeUser(e)}
            value={activeIdtreeUser?.id}
          />

          <div className="width-20" />

          <Dropdown
            active={activePeriod.id}
            displayField="value"
            list={periods}
            title="Période"
            onSelect={(e) => setActivePeriod(e)}
            value={activePeriod.value}
          />
        </div>
      )}

      {activeIdtreeUser && (
        <div>
          {activeIdtreeUser?.type !== "CS" && (
            <p className="grey-t">
              <span style={{ marginRight: 26 }}>
                Signé New : <b>{amountNew}€</b>
              </span>

              <span style={{ marginRight: 26 }}>
                Signé N+1 : <b>{amountResell1}€</b>
              </span>
            </p>
          )}

          <div className="green-t" style={{ fontSize: 32 }}>
            Commission : <b>{commission}€</b>
          </div>

          <h3 style={{ marginTop: 40 }}>Détail</h3>

          <Card isWithoutPadding>
            {details.map((item, i) => (
              <ListItem key={i}>
                {item.name}

                <Space />

                <ListCell width={100}>
                  <Chip>{item.persona}</Chip>
                </ListCell>

                <ListCell width={100}>
                  <span className="grey-t">{item.resell}</span>
                </ListCell>

                <ListCell width={60}>{item.amount + "€"}</ListCell>
              </ListItem>
            ))}
          </Card>
        </div>
      )}
    </div>
  )
}

export default CommissionsRoute
