/** 
 * GET-TEMPLATES.MODAL
 * Get all templates in order to pick one for my survey
 */
import Modal from "@/components/modal"
import { withTranslation, WithTranslation } from "react-i18next"
import { useState } from "react";
import CardContainer from "@/components/card-container";
import CardButton from "@/components/card-button";
import { faClipboardList, faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import AddTemplateModal from "./add-template.modal";
import { NavigateFunction, useNavigate } from "react-router-dom";


interface OwnProps{
  onClose : Function
}

type Props = WithTranslation & OwnProps

const MODAL_ADD = 'MODAL_ADD' //Add new template

function GetTemplatesModal(props:Props) {
  const { t } = props
  const navigate: NavigateFunction = useNavigate()
  
  const [currentModal, setCurrentModal] = useState<string | null>(null)

  return (
    <Modal isCloseButtonVisible
      onClose={props.onClose}>

      { currentModal === MODAL_ADD &&
      <AddTemplateModal onClose={() => setCurrentModal(null)}/>
      }

      <h3>
        {t("editor_onboarding_help_0")}
      </h3>

      <CardContainer>
        <CardButton title={t("editor_onboarding_template")}
          icon={faClipboardList}
          isCta
          onClick={() => navigate("/survey/templates")}
        />
        <CardButton title={t("editor_onboarding_self")}
          icon={faPencilAlt}
          onClick={() => setCurrentModal(MODAL_ADD)}
        />
      </CardContainer>

    </Modal>
  )
}

export default withTranslation()(GetTemplatesModal)