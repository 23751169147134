import { sortBy } from "lodash";
import { Email, EmailState, EMAIL_ACTIVATE, EMAIL_ADD, EMAIL_EDIT, EMAIL_GET, EMAIL_INIT, EMAIL_REMOVE } from "./email.types";

let active:Email
let list:Email[]

const initialState: EmailState = {
  active : new Email(), 
  list : []
}

export default function emailReducer(
  state = initialState,
  action: any
): EmailState {
  switch (action.type) {

    case EMAIL_ACTIVATE:
      return Object.assign({}, state, {
        active : new Email(action.payload.email)
      })

    case EMAIL_ADD:
      active = new Email(action.payload.email)
      return Object.assign({}, state, {
        active,
        list : [active, ...state.list]
      })

    //Edit invitation model
    case EMAIL_EDIT:
      active = Object.assign(state.active, { [action.payload.key] : action.payload.value })
      list = state.list.map(x => x.id === active.id ? active : x)
      return Object.assign({}, state, {
        active,
        list
      })

    //Save emails in list
    case EMAIL_GET:
      list = action.payload.emails.map((x) => new Email(x))
      list = sortBy(list, [ email => {
        return action.payload.emailsOrder.indexOf(email.id)
      }])

      return {
        ...state,
        active : list.length ? new Email(list[0]) : new Email(),
        list,
      }
      
    //Init store
    case EMAIL_INIT:
      return Object.assign({}, state, {
        active : new Email(state.active),
        list : state.list.map((x:any) => new Email(x))
      })

    //Remove invitation model
    case EMAIL_REMOVE:
      list = state.list.filter(i => { return i.id !== action.payload.id })
      return {
        ...state,
        active : list.length > 0 ? new Email(list[0]) : new Email(),
        list
      }

    default:
      return state

  }
  
}