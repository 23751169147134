/** 
 * EDITOR-AXES-FIELDS
 * Header for an axis in the editor
 */
import { withTranslation, WithTranslation } from "react-i18next"
import ListFields from "@/components/list-fields";
import ListCell from "@/components/list-cell";
import Space from "@/components/space";
import { Axis, AxisState } from "@/redux/axis.types";
import { connect } from "react-redux";
import { Session } from "@/redux/_session.types";
import EditorAxesOptionsWidget from "./editor-axes-options.widget";

interface StateProps extends WithTranslation{
  _session : Session
  axis : AxisState
}

interface OwnProps extends WithTranslation{
  currentAxis : Axis | null
  onHelp : Function
}

type Props = StateProps & OwnProps

//Tutorials
const HELP_AXIS = 'HELP_AXIS'
const HELP_FULLSCREEN = 'HELP_FULLSCREEN'
const HELP_PRIMARY = 'HELP_PRIMARY'
const HELP_TOPIC = 'HELP_TOPIC'
const HELP_QUESTION = 'HELP_QUESTION'
const HELP_TYPE = 'HELP_TYPE'
const HELP_REVERSE = 'HELP_REVERSE'
const HELP_SCORE = 'HELP_SCORE'
const HELP_SPECIAL = 'HELP_RPS'
const HELP_TRIGGER = 'HELP_TRIGGER'

function EditorAxesFields(props:Props) {
  const { t } = props

  //Tutorial displayed
  function clickHelp(help){
    props.onHelp(help)
  }

  return (
    <div className="flex">
      
      <ListFields>
        
        <ListCell/>
        <ListCell/>
        <ListCell width={60}/>

        { props._session.userOptions.editorDisplayTopic &&
        <ListCell width={200} text={t("topic")} onHelp={() => clickHelp(HELP_TOPIC)}/>
        }
        
        { props._session.userOptions.editorDisplayPrimary &&
        <ListCell width={200} text={t("question_priority")} onHelp={() => clickHelp(HELP_PRIMARY)}/>
        }

        { props._session.userOptions.editorDisplayQuestion &&
        <ListCell width={500} text={t("question")} onHelp={() => clickHelp(HELP_QUESTION)}/>
        }

        <Space/>

        { props._session.userOptions.editorDisplayTrigger &&
        <ListCell width={200} text={t("question_trigger")} onHelp={() => clickHelp(HELP_TRIGGER)}/>
        }

        { props._session.userOptions.editorDisplayType &&
        <ListCell width={140} text={t("question_type")} onHelp={() => clickHelp(HELP_TYPE)}/>
        }
        
        { props._session.userOptions.editorDisplayWithScore &&
        <ListCell width={140} text={t("question_score")} onHelp={() => clickHelp(HELP_SCORE)}/>
        }

        { props._session.userOptions.editorDisplayReverse &&
        <ListCell width={180} text={t("question_reverse")} onHelp={() => clickHelp(HELP_REVERSE)}/>
        }

        { props._session.userOptions.editorDisplaySpecial &&
        <ListCell width={220} text={t("question_rps")} onHelp={() => clickHelp(HELP_SPECIAL)}/>
        }
        
        { props._session.userOptions.editorDisplayFullScreen &&
        <ListCell width={120} text={t("question_fullscreen")} onHelp={() => clickHelp(HELP_FULLSCREEN)}/>
        }

        { props._session.userOptions.editorDisplayAxis &&
        <ListCell width={160} text={t("axis")} onHelp={() => clickHelp(HELP_AXIS)}/>
        }
        
        <ListCell/>
        <ListCell/>

      </ListFields>

      <div className="editor-axes-fields-options">
        { props.currentAxis &&
        <EditorAxesOptionsWidget currentAxis={props.currentAxis}/>
        }
      </div>
        
    </div>
  )

}
const mapStateToProps = state => ({
  _session : state._session,
  axis : state.axis
})

export default connect(mapStateToProps)(withTranslation()(EditorAxesFields))