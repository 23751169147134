/** 
 * QUESTION-TIPS.WIDGET
 * Get ai advises to improve score in question
 */

import { withTranslation, WithTranslation } from "react-i18next"
import { connect } from "react-redux"
import { Session } from "@/redux/_session.types"
import { Question } from "@/redux/question.types"
import GptDisplayerWidget from "./gpt-displayer.widget"
import ConsentOpenaiWidget from "./consent-openai.widget"

interface StateProps{
  _session : Session
}

interface OwnProps{
  question: Question
}

type Props = StateProps & OwnProps & WithTranslation

function QuestionTipsWidget(props: Props){
  const { t } = props

  return props._session.modules.assistant ?
    <ConsentOpenaiWidget>
      <div className="flex">
        <div className="flex flex-dcol">
          <div className="height-20" />
          
          <img src={require("@/assets/assistant/owl_female.png")}
            alt={"owl assistant"}
            width={60}
            style={{ objectFit: "contain" }}
          />
        </div>

        <div className="width-20"/>

        <GptDisplayerWidget
          destination="advice"
          params={{
            questionLabel: props.question.label
          }}
          language={props._session.language}
        />
        
      </div>
    </ConsentOpenaiWidget>
    :
    <div>
      {t("upgrade_title_module", { feature: t("module_assistant") })}
    </div>
}

const mapStateToProps = state => ({
  _session : state._session
})

export default connect(mapStateToProps)(withTranslation()(QuestionTipsWidget))