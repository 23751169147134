/**
 * GET-ARCHIVE-QUESTION
 * Match question from template with archive database 
 */

import { store } from "@/index"
import { Question } from "@/redux/question.types"
import { Topic } from "@/redux/topic.types"

const getArchiveQuestion = (topic:Topic, question:Question, archiveQuestions:any[]) => {
  let hide = false

  const archiveQuestion = archiveQuestions.find(x => 
    x.aid === (store.getState().survey.active.randomData ? question.id : question.aid) &&
    x.fkTopic === (store.getState().survey.active.randomData ? topic.id : topic.aid)
  )

  if (archiveQuestion){

    question.repartition = archiveQuestion.repartition ? archiveQuestion.repartition : []
    question.messagesCount = archiveQuestion.messagesCount
    question.noAnswers = archiveQuestion.noAnswers
    question.usersCount = archiveQuestion.usersCount
    if (question.usersCount === 0){
      hide = true
    }
    
  }else{
    hide = true
  }

  if (hide){
    question.repartition = []
    question.messagesCount = 0
    question.noAnswers = 0
    question.usersCount = 0

  }

  return question

}

export default getArchiveQuestion