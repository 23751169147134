/** 
 * CONGRATULATION.MODAL
 * Display rocket animation after action
 */
import Button from "@/components/button"
import Modal from "@/components/modal"
import Space from "@/components/space"
import { withTranslation, WithTranslation } from "react-i18next"
import Lottie from "react-lottie-player"

interface OwnProps extends WithTranslation{
  onClose : Function,
  onCloseText? : string,
  onNext? : Function,
  onNextText? : string
  text : string
}

function LegalModal(props:OwnProps) {
  const { t } = props
  
  return (
    <Modal>

      <div style={{ textAlign : 'center' }}>
        <h1>
          {t("utils_congratulations")}
        </h1>
        <p>
          {props.text}
        </p>
      </div>

      <div className="flex">
        <Space/>
        <Lottie
          loop
          animationData={require("@/assets/rocket.json")}
          play
          style={{ 
            width: 300, 
            height: 250,
            marginRight : 20
          }}
        />
        <Space/>
      </div>

      <div className="height-10"/>

      { props.onNext &&
      <div className="flex"
        style={{ zIndex : 1, marginBottom : 12 }}>
        <Space/>
        <Button isWithBorder
          isLarge
          className="primary"
          onClick={() => props.onClose()}>
          {props.onNextText ? props.onNextText : t("utils_next")}
        </Button>
        <Space/>
      </div>
      }
      
      <div className="flex"
        style={{ zIndex : 1 }}>
        <Space/>
        <Button isWithBorder
          isLarge
          className="secondary"
          onClick={() => props.onClose()}>
          {props.onCloseText ? props.onCloseText : t("utils_next")}
        </Button>
        <Space/>
      </div>

    </Modal>

  )

}

export default withTranslation()(LegalModal)