/** 
 * MODAL
 * Dialog
 */
import { useEffect, useState } from "react"
import { WithTranslation, withTranslation } from "react-i18next"
import Space from "./space"
import Button from "./button"
import { STATUS_LOADED, STATUS_SAVING, STATUS_SEARCHING } from "@/redux/_status.types"
import SearchBar from "./search-bar"

interface OwnProps{
  children? : any
  disableKeyEvents? : boolean
  disableClickOutside? : boolean
  disableOverflow?: boolean
  isCloseButtonVisible? : boolean
  isLarge? : boolean
  onClose? : Function
  onDelete? : Function
  onSearch? : Function
  onNext? : Function
  status? : string
  title? : string
}

type Props = OwnProps & WithTranslation

function Modal(props:Props) {
  const { t } = props

  //Searching
  const [isSearching, setIsSearching] = useState(false)

  //Detect action
  //> Enter => go next
  //> Escape => close modal
  useEffect(() => {
    if(!props.disableKeyEvents){
      const onKeyDown = (e) => {
        if (e.key === 'Enter'){
          next()
        }
        else if (e.key === 'Escape'){
          close()
        }
      }

      window.addEventListener('keydown', onKeyDown);
      return () => {
          window.removeEventListener('keydown', onKeyDown);
      }
    }

  })

  //Close dialog
  function close(){
    if (props.onClose){
      props.onClose()
    }
  }

  //Clic outside trigger close
  function clickOutside(){
    if (!props.disableClickOutside){
      close()
    }
  }

  //Click on delete
  function deleteItem(){
    if (props.onDelete){
      props.onDelete()
    }
  }
  
  //Function next
  function next(){
    if (props.onNext){
      props.onNext()
    }
  }

  //Search
  function search(value){
    setIsSearching(false)
    if (props.onSearch) props.onSearch(value)
  }

  return (
    <div className="modal-container flex" 
      onClick={() => clickOutside()}>

      <div className="modal"
        onClick={e => { e.stopPropagation() }}
        style={{
          minWidth : props.isLarge ? '780px' : '',
          maxWidth : props.isLarge ? '780px' : ''
        }}>

        <div className="flex">

          { props.title &&
          <h1>{ props.title }</h1>
          }

          <Space/>

          { props.onSearch &&
          <div style={{ width : '300px', marginLeft : '80px' }}>
            <SearchBar onSearch={search}
              status={isSearching ? STATUS_SEARCHING : STATUS_LOADED}
              onSearchStart={() => setIsSearching(true)}
            />
          </div>
          }

        </div>

        <div
          className="modal-content"
          style={{ overflowY: props.disableOverflow ? "initial" : "auto"}}
        >
          { props.children }
        </div>

        <div className="flex">

          { props.onDelete &&
          <Button onClick={deleteItem}>
            {t("utils_delete")}
          </Button>
          }

          <Space/>

          { props.isCloseButtonVisible &&
          <Button onClick={close}>
            {t("utils_close")}
          </Button>
          }

          { props.onNext &&
          <Button className="primary"
            isLoading={props.status === STATUS_SAVING}
            onClick={next}>
            {t("utils_next")}
          </Button>
          }

        </div>

      </div>

    </div>
  )
}

export default withTranslation()(Modal)