/** UPLOAD BUTTON
 * + Dropzone
 */

import { useCallback, useState } from 'react'
import Dropzone from 'react-dropzone'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons'
import Button from "@/components/button";
import { STATUS_LOADED, STATUS_LOADING } from '@/redux/_status.types'
import { withTranslation, WithTranslation } from 'react-i18next'
import Space from '@/components/space';
import { toast } from 'react-toastify';

interface OwnProps{
  onUpload : Function
  acceptedFiles? : string
  maxSize? : number
}

type Props = OwnProps & WithTranslation

function UploadButtonWidget(props:Props){

  const { t } = props;
  const [status, setStatus] = useState<string>(STATUS_LOADED)
  const [acceptedFilesExt] = useState<string>(props.acceptedFiles ? props.acceptedFiles : ".csv,.xls,.xlsx")
  const [maxSize] = useState<number>(props.maxSize ? props.maxSize : 1000000)


  const onDrop = useCallback(acceptedFiles => {
    uploadFile(acceptedFiles)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onDropRejected = useCallback(rejectedFiles => {
    toast(t(rejectedFiles[0].errors[0].message), { type : 'error' })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  async function uploadFile(event){

    setStatus(STATUS_LOADING)
    await props.onUpload(event)
    setStatus(STATUS_LOADED)
  }


  return(
    <Dropzone
      onDrop={onDrop}
      onDropRejected={onDropRejected}
      accept = {acceptedFilesExt}
      maxSize = {maxSize}
    >
    {({getRootProps, getInputProps, isDragActive, isDragReject}) => (
    <div
      className={"flex1 flex grey-b upload-button"+ (isDragActive ?" upload-button-drop":"")}
      {...getRootProps()}
    >

      <input {...getInputProps()}/>

      <div className="flex1"></div>

      <div className="flex1">

        <div className="adm-upload-icon flex">
          <FontAwesomeIcon icon={faCloudUploadAlt}></FontAwesomeIcon>
        </div>

        { isDragActive ?
        <div style={{ textAlign : 'center' }}>
          {isDragReject?
          <p className ="red-t">{t("file_not_accepted")}</p>
          :
          <p>{t("file_drop_zone") + ""}</p>
          }
        </div>
        :
        <div style={{ textAlign : 'center' }}>
          <p className="grey-t">{t("click_or_drop")}</p>
          <div className="height-20"/>
          <div className="flex">
            <Space/>
            <Button className="primary"
              isLarge
              isLoading={status === STATUS_LOADING}>
              <input 
                type="file"
                id="myFile"
                name="file"
                multiple={false}
                onChange={isDragReject?()=>{}:uploadFile}
                accept={acceptedFilesExt}
              />
              {t("adm_upload_btn_action")}
            </Button>
            <Space/>
          </div>
        </div>
        }
        
      </div>
      <div className="flex1"></div>
    </div>
  )}
    </Dropzone>

  )
}

export default withTranslation()(UploadButtonWidget)
