/** 
 * INTEGRATION.ACTIONS
 * Integration with other API
 */

export const commissionGet = (idtreeUser:string, lastMonth:boolean, type:string) => ({
  type : "API",
  payload : {
    method : "GET",
    url : "/public/integration/monday/commission",
    data : {
      group_title : idtreeUser,
      last_month : lastMonth,
      type
    }
  }
})