/** 
 * PROJECT-LIST.ROUTE
 * Prepare diffusion of a template
 */
import { WithTranslation, withTranslation } from 'react-i18next'
import NavApp from "@/nav/nav.app";
import { connect } from "react-redux";
import { Session } from "@/redux/_session.types";
import PagePlaceholder from "@/components/page-placeholder";
import PageHeader from "@/components/page-header";
import ProjectListWidget from "@/widgets/project-list.widget";
import { NavigateFunction, useNavigate } from 'react-router-dom';

interface Props extends WithTranslation{
  _session : Session
}

function ProjectListRoute(props:Props){
  const { t } = props
  const navigate: NavigateFunction = useNavigate()
  return(
    <NavApp>

      { props._session.interfaceType === "ADMIN"
      ?
      <ProjectListWidget/>
      :
      <div>

        <PageHeader title={t("projects")}
          subtitle={t("projects_help")}>
        </PageHeader>

        <PagePlaceholder image="trial"
          button={t("survey_draft_superadmin_button")}
          help={t("survey_draft_superadmin_help")}
          onClick={() => navigate("/superadmin/accounts")}
          title={t("survey_draft_superadmin_title")}
        />

      </div>
      }

    </NavApp>
  )
}

const mapStateToProps = (state) => ({
  _session : state._session
})

export default connect(mapStateToProps)(withTranslation()(ProjectListRoute))