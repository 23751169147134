/** 
 * TASKS-TOPIC-LABEL.WIDGET
 * Display name of the topic for one task
 */
import { connect } from "react-redux"
import { ActionPlanTask } from "@/redux/action-plan.types"
import { Topic, TopicState } from "@/redux/topic.types"
import { useState } from "react"
import { Session } from "@/redux/_session.types"

interface StateProps{
  _session: Session
  topic: TopicState
}

interface OwnProps{
  currentTask : ActionPlanTask
}

type Props = StateProps & OwnProps

function TasksTopicLabelWidget(props:Props) {
  
  const [topic] = useState<Topic>(props.currentTask.getTopic(props.topic.list))

  return (
    <div
      className="flex"
      style={{ 
        fontSize : 12,
        color : topic.Axis?.color,
        marginBottom: 6
      }}
    >

      <div className="flex1">

        <b>
          {topic.label}
        </b>

        { topic.Axis &&
        <div>
          {topic.Axis?.label}
        </div>
        }
      
      </div>

      <div style={{fontSize: 16, fontWeight: 600, marginLeft: 6}}>
        {props._session.accountOptions.dashboardDisplayMode === "note" ?
        <>
          {topic.noteLabel}
        </>
        :
        <>
          { topic.satisfaction > 0 ? topic.satisfaction.toFixed(0) + "%" : "-" }
        </>
        }
      </div>


    </div>
  )
}

const mapStateToProps = state => ({
  _session: state._session,
  topic: state.topic
})

export default connect(mapStateToProps)(TasksTopicLabelWidget)