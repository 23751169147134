/** 
 * EDIT-DISPLAY
 * Edit display and save user preferences
 */
import { connect } from "react-redux"
import { Session } from "@/redux/_session.types";
import Checkbox from "@/components/checkbox";
import { store } from "@/index";
import { sessionEditUserOptions } from "@/redux/_session.actions";
import { userUpdateOptions } from "@/redux/user.actions";

interface StateProps{
  _session : Session
}

interface OwnProps{
  checkboxes : EditDisplayCheckboxes[]
}

interface EditDisplayCheckboxes{
  id : string
  label : string
}

type Props = StateProps & OwnProps

function EditDisplay(props:Props) {

  //Edit user options when checkbox is edited
  function editCheckbox(checkboxId){
    store.dispatch(sessionEditUserOptions(checkboxId, !props._session.userOptions[checkboxId]))

    setTimeout(() => {
      if (props._session.interfaceType !== "SUPERVISOR"){
        store.dispatch(userUpdateOptions(props._session.userId, props._session.userOptions))
      }
    }, 200);

  }

  return (
    <div>

      { props.checkboxes.map(checkbox => 
      <Checkbox key={checkbox.id}
        active={props._session.userOptions[checkbox.id]}
        text={checkbox.label}
        onClick={() => editCheckbox(checkbox.id)}
      />
      )
      }

    </div>
  )

}

const mapStateToProps = state => ({
  _session : state._session
})

export default connect(mapStateToProps)(EditDisplay)