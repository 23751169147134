/** 
 * FILTER-QUESTION.HELP
 * Info about carte in big screen
 */

import ModalHelp from "@/components/modal-help"
import Space from "@/components/space"
import { useState } from "react"
import { withTranslation, WithTranslation } from "react-i18next"
import ReactMarkdown from "react-markdown"

interface OwnProps extends WithTranslation{
  onClose : Function
  page?: number
}

const summary: string[] = ["question_introduction_page_0_0", "why_it_is_important", "how_it_works"]

function FilterQuestionsHelp(props: OwnProps) {
  const { t } = props

  const [page, setPage] = useState<number>(props.page ? props.page : 0)

  function getPageContent(){
    switch (page) {
      case 0:
      return (
        <div>
          <p>
            {t("question_introduction_page_0_1")}
          </p>

          <p>
            {t("question_introduction_page_0_2")}
          </p>
        
          <div className="red-t">
            <b>
              {t("our_recommendation", {
                s: "",
                tre: "tre"
              })}
            </b>

            <p>{t("question_introduction_page_0_3")}</p>
          </div>

          {summary.slice(1).map((x: string, i: number)=>
          <p key={i}>
            <u className="_hover grey-t" onClick={()=>setPage(i+1)}>
              {t(x)}
            </u>
          </p>
          )}
          
          <img alt="axis_introduction"
            style={{width: "100%"}}
            src={require("@/assets/axis-introduction.png")}
          />

        </div>
      )
      case 1:
      return (
        <div>
          <p><b>{t("question_introduction_page_1_0")}</b></p>
        
          <p>{t("question_introduction_page_1_1")}</p>
        
          <p>{t("question_introduction_page_1_2")}</p>

          <p>{t("question_introduction_page_1_3")}</p>

          <p>{t("question_introduction_page_1_4")}</p>

          <p>{t("question_introduction_page_1_5")}</p>

          <ReactMarkdown linkTarget="_blank">
            {t("question_introduction_page_1_6")}
          </ReactMarkdown>

          {t("question_introduction_page_1_7")}

          <ul>
            <li>{t("question_introduction_page_1_7_1")}</li>

            <li>{t("question_introduction_page_1_7_2")}</li>
          </ul>

          <p>{t("question_introduction_page_1_8")}</p>

          <div className="red-t">
            <b>{t("utils_summary")}</b>

            <p>{t("question_introduction_page_1_9")}</p>

            <p>{t("question_introduction_page_1_10")}</p>
          </div>
        </div>
      )
      case 2:
      return (
        <div>
          <p>{"1. " + t("question_introduction_page_2_1")}</p>

          <p>{"2. " + t("question_introduction_page_2_2")}</p>
          
          <p>{"3. " + t("question_introduction_page_2_3")}</p>
          
          <p>{"4. " + t("question_introduction_page_2_4")}</p>
          
          <p>{"5. " + t("question_introduction_page_2_5")}</p>
        </div>
      )
      default:
      return(
        <div>
          <p>{t("utils_error")}</p>
        </div>
      )
    }
  }

  return (
    <ModalHelp
      title={t("axis_introduction")}
      onClose={props.onClose}
    >
      <div>

        <div className="flex">
          <Space />
          <p>
            <u>
              {(page > 0 ? page + ". " : "") + t(summary[page])}
            </u>
          </p>
          <Space />
        </div>

        <div style={{
          maxHeight: "293px",
          overflowY: "auto",
          paddingRight: "20px",
          marginRight: "-20px"
        }}>
          {getPageContent()}
        </div>

        <div className="height-20" />

        {page !== 0 &&
        <div className="flex" style={{height: "40px"}}>

          <div className="flex1 flex flex-dcol">
            <Space />
            {page > 0 ?
            <u className="_hover grey-t" onClick={()=>setPage(page-1)}>
              {t(page === 1 ? "question_introduction" : summary[page-1])}
            </u>
            :
            <div/>
            }
            <Space />
          </div>

          <div className="width-10" />

          <div className="flex flex-dcol">
            <Space />
            {t("page_number", {
              number : page,
              total : summary.length-1
            })}
            <Space />
          </div>

          <div className="width-10" />

          <div className="flex1 flex flex-dcol">
            <Space />
            <div className="flex">
              <Space />

              {page < summary.length-1 ?
              <u className="_hover grey-t" onClick={()=>setPage(page+1)}>
                {t(summary[page+1])}
              </u>
              :
              <div/>
              }
            </div>
            <Space />
          </div>

        </div>
        }

      </div>
    </ModalHelp>
  )
}

export default withTranslation()(FilterQuestionsHelp)