/** 
 * QUESTION-TRIGGER.HELP
 * Info about trigger on a question
 */
import HelpModal from "@/components/modal-help"
import { withTranslation, WithTranslation } from "react-i18next"

interface OwnProps extends WithTranslation{
  onClose : Function
}

function QuestionTriggerHelp(props:OwnProps) {
  const { t } = props

  return (
    <HelpModal onClose={props.onClose}
      title={t("question_trigger")}>
      <p>{t("question_trigger_help")}</p>
      <p>{t("question_trigger_help_scale")}</p>
      <p>{t("question_trigger_help_choice")}</p>
    </HelpModal>
  )
}

export default withTranslation()(QuestionTriggerHelp)