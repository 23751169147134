/** 
 * PACK.ACTIONS
 */

import { Pack, PACK_ADD, PACK_EDIT, PACK_GET, PACK_INIT, PACK_REMOVE } from "./pack.types"


//REDUX

export const packAdd = (pack: Pack) => ({
  type : PACK_ADD,
  payload : {
    pack
  }
})

export const packEdit = (id:string, key:string, value:any) => ({
  type : PACK_EDIT,
  payload : {
    id,
    key,
    value
  }
})
 
export const packGet = (packs:Pack[]) => ({
  type : PACK_GET,
  payload : {
    packs
  }
})

export const packInit = () => ({
  type : PACK_INIT
})

export const packRemove = (id:string) => ({
  type : PACK_REMOVE,
  payload : {
    id
  }
})

//API

export const packDestroy = (id:string) => ({
  type : 'API',
  payload : {
    method : "DELETE",
    url : "/supervisor/pack/" + id
  }
})

export const packFetch = () => ({
  type : 'API',
  payload : {
    method : "GET",
    url : "/packs"
  }
})

export const packUpdate = (accountId:string, pack:Pack) => ({
  type : 'API',
  payload : {
    method : "POST",
    url : "/supervisor/pack",
    data : {
      accountId,
      pack
    }
  }
})