import { Attribute, ATTRIBUTE_COMPARE_GET , ATTRIBUTE_GET, ATTRIBUTE_INIT, ATTRIBUTE_STATUS } from "./attribute.types"


//REDUX


export const attributeCompareGet = (attributes : Attribute[]) => ({
  type : ATTRIBUTE_COMPARE_GET,
  payload : {
    attributes
  }
})

export const attributeGet = (attributes : Attribute[]) => ({
  type : ATTRIBUTE_GET,
  payload : {
    attributes
  }
})

export const attributeInit = () => ({
  type : ATTRIBUTE_INIT
})

export const attributeStatus = (status:string) => ({
  type : ATTRIBUTE_STATUS,
  payload : {
    status
  }
})