/** 
 * DROPDOWN
 * Dropdown component
 */

import { faCaretDown, faCheck, faChevronRight, faInfoCircle, faPencilAlt, faPlusCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { WithTranslation, withTranslation } from "react-i18next"
import { useEffect, useState } from "react"
import { connect } from "react-redux"
import Space from "./space"
import ContextMenu from "./context-menu"
import ListItem from "./list-item"
import SaveIcon from "./save-icon"
import usePrevious from "../utils/use-previous.utils"
import ListButton from "./list-button"
import { Session } from "@/redux/_session.types"
import { compact, groupBy } from "lodash"
import Chip from "./chip"

interface StateProps extends WithTranslation{
  _session : Session
}

interface OwnProps{
  active? : any | null //Id of active item
  activeValue? : any | null //Id of active item (for second level)
  children? : any //Add elements on top
  customButtonLabel? : string //Button add custom label
  displayField : string //Key of attribute to display
  error? : boolean //Show outline in red
  groupList?: any[] //name of each group
  groupValue?: string //items grouping value
  isContextMenuTop? : boolean //Align top
  isDisabled? : boolean //Context menu is disabled
  isNullAllowed? : boolean //Is null value allowed (display "not defined")
  isWhiteMode? : boolean //Show the component in white mode
  list : any[], //List of values
  title? : string //Show label
  onAdd? : Function //Function on add (show "add" button)
  onEdit? : Function //Function on edit (show "pencil" icon on line)
  onHelp? : Function //Clic on help button
  onSave? : Function //Function on save (show status icon)
  onSelect : Function //Function on select
  status? : string //Status (for save icon)
  tag? : string //Display tag next to the title
  tagColor? : string //Color for tag (default is red)
  value : any | null //Value of the list
  warning? : boolean //Show outline in orange
}

type Props = StateProps & OwnProps

function Dropdown(props:Props) {
  const { t } = props

  const [isOpen, setIsOpen] = useState(false) //Is context menu open
  const [showStatus, setShowStatus] = useState(false) //Show save status
  const [withValues] = useState(compact(props.list.map(x => x.values)).length > 0) 

  //Get previous value
  const prevCount = usePrevious(props.active)

  //Detect change of values
  //Trigger function on save if defined
  useEffect(() => {
    if (prevCount !== props.active && props.onSave){
      props.onSave()
    }
  })

  //Clic on add button
  function add(){
    if (props.onAdd){
      props.onAdd()
    }
  }

  //On clic open context menu
  function click(){
    if (!props.isDisabled){
      setIsOpen(true)
    }
  }

  //Action on click on info button
  function clickHelp(){
    if (props.onHelp){
      props.onHelp()
    }
  }

  //Close dropdown
  function close(){
    setIsOpen(false)
  }

  //Clic on pencil icon
  function edit(item){
    if (props.onEdit){
      props.onEdit(item)
    }
  }

  //Set className
  //Depends of white mode and is disabled
  function getClassName(){
    let className = "dropdown flex"

    if (props.isWhiteMode){
      className = className + " dropdown-white"
    }

    if (props.isDisabled){
      className = className + " dropdown-disabled"
    }

    if (props.error){
      className = className + " red-t red-b"
    }

    if (props.warning){
      className = className + " orange-t orange-b"
    }

    return className

  }

  //Class for the title
  function getClassNameTitle(){
    let className = "text-input-title"

    if (props.isWhiteMode){
      className = className + " white-t"
    }else{
      className = className + " grey-t"
    }

    if (props.isDisabled){
      className = className + " dropdown-disabled"
    }

    return className
  }

  //Get list of values
  //Si null allowed add "undefined" as first value
  function getList(){
    if (props.isNullAllowed){
      return [{
        id : null,
        [props.displayField] : t("utils_undefined")
      }].concat(props.list)
    }else{
      return props.list
    }
  }

  function getGroupName(groupId: string){
    if(props.groupList){
      const group: any = props.groupList.find((x: any) => x.id === groupId)
      if(group){
        return group[props.displayField]
      }
      else{
        return t("utils_undefined")
      }
    }
    else{
      return groupId
    }
  }

  //Detect if value is active
  function isValueActive(item, value){
    return props.activeValue === value.id && props.active === item.id
  }

  function isValueGroupActive(groupId: string){
    const activeItem: any = props.list.find((x: any) => x.id === props.active)
    if(activeItem && props.groupValue && activeItem[props.groupValue] === groupId){
      return true
    }
    else{
      return false
    }
  }

  //Select value in list
  function select(e, item, value){
    e.stopPropagation()

    close()
    setShowStatus(true)
    props.onSelect(item, value)

    setTimeout(() => {
      setShowStatus(false)
    }, 1000)

  }

  return (
    <div>

      { props.title &&
      <div className="flex dropdown-header">

        <div className={getClassNameTitle()}>
          { props.title }
        </div>

        <Space/>

        { props.tag &&
        <Chip color={props.tagColor ? props.tagColor : "#eb5a46"}>
          {props.tag}
        </Chip>
        }

        { props.onHelp &&
        <FontAwesomeIcon icon={faInfoCircle}
          onClick={clickHelp}
          style={{ color : props.isWhiteMode ? "white" : "#8c8c8c" }}
        />
        }

      </div>
      }

      <div className="flex rel">

        <div className={getClassName()}
          onClick={click}>

          { props.value
          ?
          props.value
          :
          <span>{t("utils_undefined")}</span>
          }

          <Space/>

          <FontAwesomeIcon icon={faCaretDown}/>

        </div>

        { props.status &&
        <SaveIcon status={showStatus ? props.status : null}/>
        }

        { isOpen &&
        <ContextMenu onClose={close}
          positionBottom={props.isContextMenuTop}>

          { props.children }

          {props.isNullAllowed &&
          <ListItem 
            isEditable
            isSmall
            onClick={(e) => select(e, {
              id : null,
              [props.displayField] : t("utils_undefined")
            }, null)}>

            <div style={{
                fontWeight : withValues ? "bold" : "initial"
              }}>
              { props.active === null
              ?
              <div className="dropdown-item-active flex"
                style={{
                  color : props._session.accountColors.active
                }}>
                
                <FontAwesomeIcon icon={faCheck}/>

                {t("utils_undefined")}
              </div>
              :
              t("utils_undefined")
              }
            </div>
        
            

          </ListItem>
          }
          
          <div style={{ maxHeight : '290px', overflowY : 'auto' }}>

            { props.groupValue ?
            Object.entries(groupBy(props.list, props.groupValue)).map(([key, values]) =>
            <div key={key} style={{ borderTop: "1px solid black" }}>
              <b
                style={{
                  paddingLeft: 24,
                  borderTop: "1px solid black",
                  color: isValueGroupActive(key) ? props._session.accountColors.active : undefined
                }}
              >
                {getGroupName(key)}
              </b>
              {values.map((item, i) => 
              <div key={i}>

                <ListItem
                  isEditable
                  isSmall
                  onClick={(e) => select(e, item, null)}>

                  <div style={{
                      fontWeight : withValues ? "bold" : "initial"
                    }}>
                    {( props.active === item.id )
                    ?
                    <div className="dropdown-item-active flex"
                      style={{
                        color : props._session.accountColors.active
                      }}>
                      
                      <FontAwesomeIcon icon={faCheck}/>

                      {t(item[props.displayField])}
                    </div>
                    :
                    t(item[props.displayField])
                    }
                  </div>
              
                  <Space/>

                  { (props.onEdit && item.id) &&
                  <ListButton icon={faPencilAlt}
                    onClick={() => edit(item)}
                  />
                  }

                </ListItem>

              </div>
              )}

            </div>
            )
            : props.list.map((item, i) => 
            <div key={i}>

              <ListItem 
                isEditable
                isSmall
                onClick={(e) => select(e, item, null)}>

                <div style={{
                    fontWeight : withValues ? "bold" : "initial"
                  }}>
                  {( props.active === item.id )
                  ?
                  <div className="dropdown-item-active flex"
                    style={{
                      color : props._session.accountColors.active
                    }}>
                    
                    <FontAwesomeIcon icon={faCheck}/>

                    {t(item[props.displayField])}
                  </div>
                  :
                  t(item[props.displayField])
                  }
                </div>
            
                <Space/>

                { (props.onEdit && item.id) &&
                <ListButton icon={faPencilAlt}
                  onClick={() => edit(item)}
                />
                }

              </ListItem>

              { item.values &&
              item.values.map(value => 
              <ListItem key={value.id}
                isActive={isValueActive(item, value)}
                isEditable
                isSmall
                onClick={(e) => select(e, item, value)}>
                <span className={!isValueActive(item, value) ? "grey-t" : ""}
                  style={{ fontSize : '12px' }}>
                  <FontAwesomeIcon icon={faChevronRight}/>
                  &nbsp;

                  {value.prefix &&
                  t(value.prefix) + " : "
                  }

                  {t(value.name)}

                  {value.suffix &&
                  " (" + t(value.suffix) + ")"
                  }

                </span>
              </ListItem>
              )
              }

            </div>
            )
            }

            { /** si aucune valeur */
            getList().length === 0 &&
            <ListItem>
              <span className="grey-t">
                {t("utils_no_values")}
              </span>
            </ListItem>
            }
          </div>

          {props.onAdd &&
          <div className="flex" onClick={add}>
            <ListItem isSmall isEditable>
              <span>
                <FontAwesomeIcon icon={faPlusCircle} color={props._session.accountColors.cta}/>
                <i>{"   " + t(props.customButtonLabel ? props.customButtonLabel : "utils_add")}</i>
              </span>

            </ListItem>
          </div>
          }
          
        </ContextMenu>
      }

      </div>

    </div>
  )

}

const mapStateToProps = state => ({
  _session : state._session
})

export default connect(mapStateToProps)(withTranslation()(Dropdown))