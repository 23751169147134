/**
 * GET-LANGUAGE.UTILS
 * Get list of languages from string
 */

import { Language } from "@/redux/language.types"
import languages from "@/translate/lang.json"

const getLanguagesUtil = (languageIds:string[]):Language[] => {
  const list:Language[] = []

  languageIds.forEach((id: string) => {
    const language: any = languages.find((x:any) => x.code === id)
    list.push(new Language(language ? language : { code: id, name: id.toUpperCase(), isTranslatable : false }))
  })

  return list

}

export default getLanguagesUtil