/** 
 * PROJECT-EDIT-REMINDER.MODAL
 * Add new reminder for the project
 */

import { withTranslation, WithTranslation } from "react-i18next"
import { connect } from "react-redux"
import Modal from "@/components/modal";
import { ProjectState } from "@/redux/project.types";
import { useState } from "react";
import TextInput from "@/components/text-input";
import { v4 as uuid } from "uuid";
import { formatDate, updateDate } from "@/utils/format-date.utils";
import { Reminder, ReminderTag, REMINDER_TAGS } from "@/redux/reminder.types";
import Dropdown from "@/components/dropdown";
import { store } from "@/index";
import { reminderAdd, reminderDestroy, reminderRemove, reminderReplace, reminderUpdate } from "@/redux/reminder.actions";
import { projectStatus } from "@/redux/project.actions";
import { STATUS_SAVED, STATUS_SAVING } from "@/redux/_status.types";
import ModalConfirm from "@/components/modal-confirm";
import TimePicker from 'react-time-picker';
 
interface StateProps extends WithTranslation{
  project : ProjectState
}

interface OwnProps{
  date? : Date
  editedReminded? : Reminder
  onClose : Function
  tag? : ReminderTag
}

type Props = StateProps & OwnProps

const MODAL_ASK_DELETE = "MODAL_ASK_DELETE"

function ProjectEditReminderModal(props:Props) {
  const { t } = props

  const [currentModal, setCurrentModal] = useState<string | null>(null)
  const [date, setDate] = useState<Date>(props.editedReminded ? new Date(props.editedReminded.date) : initDate(props.date ? props.date : new Date()))
  const [tag, setTag] = useState<ReminderTag>(props.editedReminded ? props.editedReminded.tag : (props.tag ? props.tag : "custom"))
  const [text, setText] = useState(props.editedReminded ? props.editedReminded.text : "")
  const [title, setTitle] = useState<string>(props.editedReminded ? props.editedReminded.title : t("reminder_tag_" + props.tag))

  //Init date : set hour at 9:30
  function initDate(date:Date){
    date.setHours(9)
    date.setMinutes(30)
    return date
  }

  //Proceed on next
  async function next(){

    const reminder = new Reminder({
      id : props.editedReminded ? props.editedReminded.id : uuid(),
      date,
      tag,
      text,
      title
    })

    //Save data
    store.dispatch(projectStatus(STATUS_SAVING))
    await store.dispatch(reminderUpdate(reminder, props.project.active.id))
    store.dispatch(projectStatus(STATUS_SAVED))

    //Add or edit reminder
    if (props.editedReminded){
      store.dispatch(reminderReplace(reminder))
    }else{
      store.dispatch(reminderAdd(reminder))
    }

    //Close modal
    props.onClose()

  }

  //Delete the reminder
  async function deleteReminder(){
    if (props.editedReminded){
      store.dispatch(projectStatus(STATUS_SAVING))
      await store.dispatch(reminderDestroy(props.editedReminded.id))
      store.dispatch(reminderRemove(props.editedReminded.id))
      store.dispatch(projectStatus(STATUS_SAVED))
      props.onClose()
    }
  }

  return (
    <Modal title={props.editedReminded ? t("reminder_edit") : t("reminder_add")}
      isCloseButtonVisible
      disableKeyEvents
      disableClickOutside
      status={props.project.status}
      onClose={() => props.onClose()}
      onDelete={props.editedReminded ? () => setCurrentModal(MODAL_ASK_DELETE) : undefined}
      onNext={next}>

      { currentModal === MODAL_ASK_DELETE &&
      <ModalConfirm onNo={() => setCurrentModal(null)}
        onYes={() => deleteReminder()}
        text={t("reminder_ask_remove", {
          title
        })}
        textBold={t("utils_next_ask")}
      />
      }

      <div className="flex">

        <TextInput autoFocus
          onChange={(e) => setTitle(e.value)}
          title={t("reminder_name")}
          value={title}
        />

        <div className="width-20"/>

        <Dropdown value={t("reminder_tag_" + tag)}
          displayField="name"
          active={tag}
          list={REMINDER_TAGS.concat(["custom"]).map(x => { return { id : x, name : t("reminder_tag_" + x) }})}
          title={t("reminder_tag")}
          onSelect={(e) => {
            setTag(e.id);
            setTitle(t("reminder_tag_" + e.id))
          }}
        />

        <div className="width-40"/>

      </div>

      <div className="height-10"/>
      
      <p className="grey-t">
        {t("reminder_description_" + tag)}
      </p>

      <p className="red-t">
        {t("reminder_help")}
      </p>

      <div className="height-10"/>

      <div className="flex">

        <TextInput type="date"
          isFullWidth
          onChange={(e) => setDate(updateDate(date, new Date(e.value), true))}
          title={t("reminder_date")}
          value={formatDate(date, false, true)}
        />

        <div className="width-20"/>

        <div>

          <div className="text-input-title grey-t">
            {t("reminder_hour")}
          </div>

          <TimePicker 
            onChange={(time) => {
              if (time){
                setDate(new Date(new Date(date).toDateString() + " " + time))
              }
            }}
            value={date.toTimeString().split(' ')[0]}
            disableClock={true}
          />

        </div>

        <div className="width-20"/>

      </div>

      <div className="text-input-title grey-t">
        {t("reminder_text")}
      </div>

      <textarea style={{ 
          resize: "none",
          height : 180,
          width : "calc(100% - 20px)",
          border: "none",
          padding: "6px 10px",
          backgroundColor: "#eaeaea",
          borderRadius: 8,
          margin : "6px 0px"
        }}
        onChange={(e) => setText(e.target.value)}
        value={text}>
      </textarea>
      
      <div className="height-20"/>
      
    </Modal>
  )

}

const mapStateToProps = state => ({
  project : state.project
})

export default connect(mapStateToProps)(withTranslation()(ProjectEditReminderModal))