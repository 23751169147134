/** 
 * REGROUPING-DISSOCIATE.MODAL
 *
 */

import Modal from "@/components/modal"
import { connect } from "react-redux"

import { withTranslation, WithTranslation } from "react-i18next"
import { Attribute } from "@/redux/attribute.types";
import Space from "@/components/space";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RegroupingState } from "@/redux/regrouping.types";
import { store } from "@/index";
import { regroupingDestroy, regroupingRemove, regroupingStatus } from "@/redux/regrouping.actions";
import { toast } from "react-toastify";
import RegroupingViewer from "@/components/regrouping-viewer";
import { STATUS_SAVED, STATUS_SAVE_ERROR, STATUS_SAVING } from "@/redux/_status.types";

interface StateProps extends WithTranslation{
  regrouping : RegroupingState
}

interface OwnProps{
  onClose : Function
  onNext : Function
}

type Props = StateProps & OwnProps

function RegroupingDissociateModal(props:Props) {
  const { t } = props

  function close(){
    props.onClose()
  }

  //When dissociation is confirmed
  async function next(){

    store.dispatch(regroupingStatus(STATUS_SAVING))
    const response : any = await store.dispatch(regroupingDestroy(props.regrouping.active.id))
    
    if(response.id === props.regrouping.active.id){
      store.dispatch(regroupingRemove(props.regrouping.active.id))
      store.dispatch(regroupingStatus(STATUS_SAVED))
      props.onNext()
    }
    else{
      store.dispatch(regroupingStatus(STATUS_SAVE_ERROR))
      if(response.error){
        toast(t(response.error), { type : 'error' })
      }
      else{
        toast(t("no_response"), { type : 'error' })
      }
    }
  }


  return (
    <Modal
      title={t("utils_dissociate")}
      disableClickOutside
      disableKeyEvents
      isCloseButtonVisible
      isLarge
      status={props.regrouping.status}
      onNext={next}
      onClose={close}>
      
      <div>{t("regroup_cancel")}</div>

      <div className="height-20"></div>
      
      {props.regrouping.active?
      <div className="flex">
        
        <RegroupingViewer
          regrouping={props.regrouping.active}
          attributes={[]}
          name={props.regrouping.active.name}/>

        <div className="flex" style={{width : "100px"}}>

          <Space/>

          <div style={{margin : "auto"}}>
            <FontAwesomeIcon
              icon={faArrowRight}
              size="2x" 
              color="#111C2B"/>
          </div>

          <Space/>
          
        </div>


        <div className="flex1">
          {props.regrouping.active.Attributes.map((item:Attribute)=>
          <div key={item.id} className="flex regrouping-attributes" >

            <div>{item.name}</div>

            <Space/>

            { item.usersCount > 0 &&
            <span className="grey-t">
              {item.usersCount}
            </span>
            }

          </div>

          )}
        </div>
      </div>
      :
      <div className="flex1">
        {t("Error")}
      </div>
      }

    </Modal>
  )

}

const mapStateToProps = state => ({
  regrouping : state.regrouping
})

export default connect(mapStateToProps)(withTranslation()(RegroupingDissociateModal))