/** 
 * QUESTION-NAME.HELP
 * Info about question
 */
import HelpModal from "@/components/modal-help"
import { withTranslation, WithTranslation } from "react-i18next"

interface OwnProps extends WithTranslation{
  onClose : Function
}

function QuestionNameHelp(props:OwnProps) {
  const { t } = props

  return (
    <HelpModal onClose={props.onClose}
      title={t("question")}>

      <p>{t("question_name_help")}</p>
      <p>{t("question_name_style_help")}</p>

      <ul>
        <li>
          {t("question_name_style_bold")} : <b>{t("question_name_style_bold_sample")}</b>
        </li>
        <li>
          {t("question_name_style_italic")} : <i>{t("question_name_style_italic_sample")}</i>
        </li>
        <li>
          {t("question_name_style_link")} : <u>{t("question_name_style_link_sample")}</u>
        </li>
      </ul>

    </HelpModal>
  )
}

export default withTranslation()(QuestionNameHelp)