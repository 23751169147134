/** 
 * FILE-THUMBNAIL
 * 
 */

import Space from "./space";

interface OwnProps{
  onClick?: Function
  color?: string
  extension?: string
}

function FileThumbnail(props: OwnProps) {

  function click(){
    if(props.onClick){
      props.onClick()
    }
  }
  
  return (
    <div
      onClick={click}
      style={{
        border : "1px solid",
        borderRadius: "8px",
        height : 108,
        width : 67
      }}
      className="_hover flex flex-dcol"
    >
      <Space />
      <div className="flex white-t" style={{backgroundColor: props.color ? props.color : undefined}}>
        <Space />
        {props.extension?.toUpperCase()}
        <Space />
      </div>
      <Space />
    </div>
  )

}

export default FileThumbnail