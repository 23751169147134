/**
 * LOGIN.PUBLIC
 * Connexion for new user
 */

import { Component } from "react";
import LoginModal from "@/modals/login.modal";

class LoginPublic extends Component{

  render () {
    return(
      <div>
        <LoginModal authLevel={0}/>
      </div>
    )

  }

}

export default LoginPublic