//List of routes for API call
import { backendApi } from "../backend.api"
import { Supervisor } from "@/core/slices/supervisor/supervisor.types"
import {
  addSupervisor,
  getSuperviors
} from "@/core/slices/supervisor/supervisor.thunk"

export const supervisorEndpoint = backendApi.injectEndpoints({
  endpoints: (builder) => ({
    //Fetch all supervisors
    supervisorsGet: builder.query<Supervisor[], string>({
      queryFn: async (search: string, { dispatch }) => {
        const data = await dispatch(getSuperviors(search)).unwrap()
        return { data }
      },
      providesTags: (result: Supervisor[] = []) => [
        { type: "Supervisor", id: "LIST" },
        ...(result.length
          ? result.map(({ id }) => ({ type: "Supervisor", id } as const))
          : [])
      ]
    }),

    // Add a new supervisor
    supervisorAdd: builder.mutation<Supervisor, Partial<Supervisor>>({
      queryFn: async (newSupervisor, { dispatch }) => {
        const data = await dispatch(addSupervisor(newSupervisor)).unwrap()
        return { data }
      },
      invalidatesTags: [{ type: "Supervisor", id: "LIST" }]
    })
  }),
  overrideExisting: false
})

export const { useSupervisorsGetQuery, useSupervisorAddMutation } =
  supervisorEndpoint
