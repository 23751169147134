/** 
 * TOOGLE-BUTTONS
 */
import { connect } from "react-redux"
import { Session } from "@/redux/_session.types"

interface StateProps{
  _session : Session
}

type ToogleButtonChoices = "A" | "B"

interface OwnProps{
  activeToogle : ToogleButtonChoices
  childrenA? : React.ReactNode
  childrenB? : React.ReactNode
  onToogle : Function
  textA : string
  textB : string
}

type Props = StateProps & OwnProps

function ToggleButtons(props:Props) {

  //Render button
  function renderToogleButton(buttonChoice:ToogleButtonChoices, text:string, children:React.ReactNode){
    const isActive = props.activeToogle === buttonChoice
    return (
      <div className="toogle-button medgrey-t _hover flex flex-dcol"
        onClick={() => props.onToogle(buttonChoice)}
        style={{
          borderColor : isActive ? props._session.accountColors.active : "",
        }}>

        <div className="toogle-button-text"
          style={{
            color : isActive ? props._session.accountColors.active : "",
            fontWeight : isActive ? "bold" : "initial"
          }}>
          {text}
        </div>

        <div>
          {children}
        </div>

      </div>
    )
  }

  return (
    <div className="flex">
      {renderToogleButton("A", props.textA, props.childrenA)}
      <div className="width-10"/>
      {renderToogleButton("B", props.textB, props.childrenB)}
    </div>
  )
}

const mapStateToProps = state => ({
  _session : state._session
})

export default connect(mapStateToProps)(ToggleButtons)