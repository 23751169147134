/** 
 * SUPERVISOR
 * Edit supervisor (idtree admin)
 */

import { t } from "@/translate/t"

export class Supervisor{
  id : string  = ""
  image : boolean = false
  email : string = ""
  firstname : string = ""
  language : string = "fr"
  lastname : string = ""
  name : string = ""
  level : number = 0
  code : string | null = null

  constructor(supervisor: Partial<Supervisor> = {}){
    supervisor.name = supervisor.firstname + " " + supervisor.lastname
    Object.assign(this, supervisor)
  }

  get levelLabel():string{
    return t("supervisor_level", { level : this.level })
  }
  
}

export interface SupervisorState{
  active : Supervisor,
  status : string,
  list : Supervisor[]
}

export const SUPERVISOR_ACTIVATE = 'SUPERVISOR_ACTIVATE'
export const SUPERVISOR_ADD = 'SUPERVISOR_ADD'
export const SUPERVISOR_EDIT = 'SUPERVISOR_EDIT'
export const SUPERVISOR_GET = 'SUPERVISOR_GET'
export const SUPERVISOR_INIT = 'SUPERVISOR_INIT'
export const SUPERVISOR_STATUS = 'SUPERVISOR_STATUS'
export const SUPERVISOR_REMOVE = 'SUPERVISOR_REMOVE'