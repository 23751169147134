/**
 * PROJECT.INVITATIONS.ROUTES
 * manage relaunches when survey is launched
 */

import NavFocus from "@/nav/nav.focus";
import { ProjectState } from "@/redux/project.types";
import { connect } from "react-redux";
import ProjectInvitationsWidget from "@/widgets/project-invitations.widget";

interface StateProps{
  project : ProjectState
}

function ProjectInvitationsRoute(props:StateProps){
  return (
    <NavFocus status={props.project.status} isCreditsDisplayed>
      <ProjectInvitationsWidget/>
    </NavFocus>
  )
}

const mapStateToProps = (state) => ({
  project : state.project
})

export default connect(mapStateToProps)(ProjectInvitationsRoute)