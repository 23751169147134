/** 
 * TEMPLATE-DESCRIPTION.HELP
 * Description of the template and link to tutoriel videos
 */
import ModalHelp from "@/components/modal-help"
import ModalVideo from "@/components/modal-video"
import env from "@/env"
import { useState } from "react"
import { connect } from "react-redux"
import { TemplateState } from "@/redux/template.types"
import { Session } from "@/redux/_session.types"

interface StateProps{
  _session : Session
  template : TemplateState
}

interface OwnProps{
  onClose : Function
}

type Props = StateProps & OwnProps

function TemplateDescriptionHelp(props:Props) {

  const [currentVideo, setCurrentVideo] = useState<any | null>(null)

  return (
    <ModalHelp onClose={props.onClose}
      title={props.template.active.label}>

      { currentVideo &&
      <ModalVideo 
        link={currentVideo.link}
        onClose={() => setCurrentVideo(null)}
        title={currentVideo.title}
      />
      }

      <div className="flex">

        <div className="flex1">
          {props.template.active.description[props._session.language]}
        </div>

        <div className="width-20"/>

        { props.template.active.image &&
        <div>
          <img src={env.REACT_APP_URL_SPACE.concat("/Templates/", props.template.active.image, ".png")}
            style={{ height : 120, width : 120 }}
            alt="template"
        />
        </div>
        }

      </div>

      { props._session.language === "fr" &&
      <div>

        <h3 className="green-t">
          Pour bien commencer
        </h3>

        <img className="_hover"
          onClick={() => window.open("https://id-tree.com/aide", "_blank")}
          src={require("@/assets/videos/qWd9QDK-lIk.png")} alt="theorique"
          style={{ borderRadius : '8px', width : '80%' }}
        />

        <div className="height-10"/>

        <img className="_hover"
          onClick={() => window.open("https://id-tree.com/aide", "_blank")}
          src={require("@/assets/videos/lAXsOck6Bvk.png")} alt="questionnaire"
          style={{ borderRadius : '8px', width : '80%' }}
        />
        
        <div className="height-10"/>

        <img className="_hover"
          onClick={() => window.open("https://id-tree.com/aide", "_blank")}
          src={require("@/assets/videos/QES_D04hapU.png")} alt="practical"
          style={{ borderRadius : '8px', width : '80%' }}
        />

      </div>
      }

      
    </ModalHelp>
  )
}

const mapStateToProps = state => ({
  _session : state._session,
  template : state.template
})

export default connect(mapStateToProps)(TemplateDescriptionHelp)