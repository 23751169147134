/** 
 * ACCOUNT-LOGO
 * Logo of the connected account
 */
import { connect } from "react-redux"
import { AccountState } from "@/redux/account.types"
import { Session } from "@/redux/_session.types"
import env from "@/env"
import { getIconUrl } from "@/utils/default-icons.utils"
import { NavigateFunction, useNavigate } from "react-router-dom"

interface StateProps{
  _session : Session
  superadmin : AccountState
}

function AccountLogoWidget(props:StateProps) {
  const navigate: NavigateFunction = useNavigate()
  return (
    <div className="flex"
      style={{ 
        backgroundColor : window.location.pathname.indexOf("/home/account") > -1 ? "#0000001a" : "initial"
      }}>
      <img alt="account" className="page-navigation-circle-img"
        onClick={() => navigate("/home/account")}
        src={
          props._session.accountOptions.image ?
          env.REACT_APP_URL_SPACE.concat("/Accounts/", props._session.accountId, ".png?" + props._session.logoLastUpdate) :
          getIconUrl(props._session.accountOptions.defaultIcon)
        }>
      </img>
    </div>
  )
}

const mapStateToProps = state => ({
  _session : state._session,
  superadmin : state.superadmin
})

export default connect(mapStateToProps)(AccountLogoWidget)