/** 
 * ACCOUNT-EXPIRED
 * Modal blocking for expired accounts
 */

import Modal from "@/components/modal";
import ExportDashboardModal from "./export-dashboard.modal";
import Space from "@/components/space";
import Button from "@/components/button";
import logout from "@/utils/logout.utils";
import { WithTranslation, withTranslation } from "react-i18next";
import { useState } from "react";
import { SurveyState } from "@/redux/survey.types";
import { connect } from "react-redux";
import Link from "@/components/link"
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

interface StateProps{
  survey : SurveyState
}

interface OwnProps{
  expirationDate?: number | null
  onClose?: Function
}

type Props = StateProps & OwnProps & WithTranslation

const CONTACT_EMAIL: string = "contact@id-tree.com"
const MODAL_DOWNLOAD_DATA: string = "MODAL_DOWNLOAD_DATA"

function AccountExpiredModal(props: Props){
  const { t } = props

  const [currentModal, setCurrentModal] = useState<string | null>(null)

  function close(){
    if(props.onClose){
      props.onClose()
    }
  }

  return(
    <Modal
      title={t("account_subscription_expired")}
      onClose={close}
    >

      { currentModal === MODAL_DOWNLOAD_DATA &&
      <ExportDashboardModal
        onClose={() => setCurrentModal(null)}
        withSelector
        hideTabs={["image"]}
      />
      }

      <div className="flex">
        <img
          src={require("@/assets/lock-user.png")}
          alt={t("account_subscription_expired")}
          width={120}
        />

        <div className="flex1 flex flex-dcol">
          <Space/>
          <b>{t("account_subscription_expired_on", { date: props.expirationDate ? new Date(props.expirationDate).toLocaleDateString() : " " })}</b>
          <p>{t("account_subscription_expired_info")}</p>
          <Space/>
        </div>

      </div>

      <div className="height-10"/>

      <div className="flex" style={{fontSize: 18}}>
        <Space/>
        <Link isWithoutMargin
          icon={faEnvelope}
          onClick={() => window.open("mailto:" + CONTACT_EMAIL, "_blank")}>
          {CONTACT_EMAIL}
        </Link>
        <Space/>
      </div>

      <div className="height-40"/>

      <div className="flex">

        { props.survey.list.length > 0 &&
        <div className="flex flex-dcol">
          <Space/>
          <p>{t("download_your_data") + " "}<u className="_hover grey-t" onClick={() => setCurrentModal(MODAL_DOWNLOAD_DATA) }>{t("utils_here")}</u></p>
        </div>
        }

        <Space/>

        <Button className="primary" onClick={() => logout(true)}>{t("utils_logout")}</Button>

      </div>
    </Modal>
  )
}

const mapStateToProps = state => ({
  survey : state.survey
})

export default connect(mapStateToProps)(withTranslation()(AccountExpiredModal))
