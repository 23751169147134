/** 
 * LOGIN-ICON
 * Login image at the top of the page
 */
import Space from "@/components/space"
//import LogoWhite from "@/assets/logo-white.png"

function LoginIcon() {
  return (
    <div className="flex1 flex-dcol flex">
      <Space/>
      <div className="flex">
        <Space/>
        <div>
          <img width="200px" 
            src={require('@/assets/logo-white.png')} alt="logo">
          </img>
        </div>
        <Space/>
      </div>
      <div className="height-20"/>
    </div>
  )
}

export default LoginIcon