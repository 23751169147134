/**
 * ONBOARDING.WIDGET
 * Quick tour to discover the app
 */

import { Trans, withTranslation, WithTranslation } from "react-i18next"
import OnboardingStepWidget from "./onboarding-step.widget"
import { connect } from "react-redux"
import { Session } from "@/redux/_session.types"
import { useState } from "react"
import Modal from "@/components/modal"
import ContactModal from "@/modals/contact.modal";
import Space from "@/components/space"
import { faArrowLeft, faPhone, faPlayCircle } from "@fortawesome/free-solid-svg-icons"
import CardButton from "@/components/card-button"
import { NavigateFunction, useNavigate } from "react-router-dom"

interface StateProps extends WithTranslation{
  _session : Session
}

//Dialog boxes
const MODAL_CONTACT = "MODAL_CONTACT"
const MODAL_END = "MODAL_END"

//Pathname of the route
const ROOT_PATHNAME = "/onboarding/"

//Init tabs
const TAB_TEMPLATES = "templates"
const TAB_EDITOR = "editor"
const TAB_PROGRAM = "program"
const TAB_RESULTS = "results"
const TAB_SEND = "send"

//Order of steps for the wizard
const WIZARD_TABS = [
  TAB_TEMPLATES,
  TAB_EDITOR,
  TAB_PROGRAM,
  TAB_RESULTS
]

//Widget
function OnboardingWidget(props:StateProps) {
  const { t } = props
  const navigate: NavigateFunction = useNavigate()

  //Current dialox box
  const [currentModal, setCurrentModal] = useState<string | null>(null)

  //Card end items
  const [cardEndItems] = useState([
    {
      id : "trial",
      title : t("onboarding_trial"),
      icon : faPlayCircle,
      isCta : true
    },
    {
      id : "contact",
      title : t("onboarding_contact"),
      icon : faPhone
    },
    {
      id : "back",
      title : t("onboarding_login_back"),
      icon : faArrowLeft
    }
  ])

  //Change step in the onboarding
  function changeStep(tab:string, step:number){
    navigate(getRoute(tab, step))
  }

  //Get route for a specific step
  function getRoute(tab, step){
    return ROOT_PATHNAME + tab + "/" + step
  }

  //Get formatted text for the onboarding card
  function getText(tag: string){
    return (
      <Trans components={[<span key={tag} className="green-t"/>]}>
        {t(tag)}
      </Trans>
    )
  }

  //Detect is the step is the current step (according page and index)
  function isStepActive(tab:string, step:number){
    return window.location.pathname === getRoute(tab, step)
  }

  //Detect is the step is the current step
  function isTabActive(tab:string){
    return window.location.pathname.indexOf(ROOT_PATHNAME + tab + "/") > -1
  }

  //Select choice
  function selectCard(cardId:string){
    switch(cardId){
      case "trial":
        return navigate("/onboarding/signup")
      case "contact":
        return setCurrentModal(MODAL_CONTACT)
      case "back":
        return window.open("https://id-tree.com", "_blank")
      default:
        return null
    }
  }

  return (
    <div className="flex1 flex flex-dcol">

      { currentModal === MODAL_CONTACT &&
      <ContactModal onClose={() => setCurrentModal(null)}/>
      }

      { currentModal === MODAL_END &&
      <Modal onClose={() => setCurrentModal(null)}
        isCloseButtonVisible
        disableClickOutside>

        <div style={{ textAlign : "center" }}>
          <h1>
            {t("onboarding_end_title")}
          </h1>
          <p>
            {t("onboarding_end_subtitle")}
          </p>
        </div>

        <div className="flex">

          <Space/>

          {cardEndItems.map(card =>
          <CardButton
            isCta={ card.isCta}
            key={card.id}
            icon={card.icon}
            title={card.title}
            onClick={() => selectCard(card.id)}>
          </CardButton>
          )}

          <Space/>

        </div>

      </Modal>
      }

      <div className="flex">
        { WIZARD_TABS.map((tab, i) => 
        <div key={i} 
          style={{ borderLeft : i > 0 ? "1px solid" : "none" }}
          className={"_hover flex1 medgrey-b onboarding-tab" + (isTabActive(tab) ? "" : " onboarding-tab-inactive grey-t")}
          onClick={() => changeStep(tab, 0)}>
          <div className="onboarding-tab-title">
            {t("onboarding_step", { count : i + 1 })}
          </div>
          <div>
            {t("onboarding_tag_" + tab)}
          </div>
        </div>
        )
        }
      </div>

      <div className="height-20"/>

      { isStepActive(TAB_TEMPLATES, 0) &&
      <OnboardingStepWidget 
        height={569}
        width={969}
        arrowLeft={338}
        arrowTop={104}
        arrowRotate={52}
        arrowScaleX={-1}
        cardLeft={140}
        cardTop={30}
        ctaText={t("onboarding_tag_templates_cta")}
        image="onboarding-templates"
        onBack={() => navigate("/onboarding/intro/0")}
        onNext={() => changeStep(TAB_EDITOR, 0)}>
        {getText("onboarding_tag_templates_text")}
      </OnboardingStepWidget>
      }
      
      { isStepActive(TAB_EDITOR, 0) &&
      <OnboardingStepWidget 
        height={643}
        width={1072}
        arrowLeft={336}
        arrowTop={100}
        arrowRotate={60}
        arrowScaleX={-1}
        cardLeft={52}
        cardTop={160}
        ctaText={t("onboarding_tag_editor_1_cta")}
        image="onboarding-editor-1"
        onBack={() => changeStep(TAB_TEMPLATES, 0)}
        onNext={() => changeStep(TAB_PROGRAM, 0)}>
        {getText("onboarding_tag_editor_1_text")}
      </OnboardingStepWidget>
      }

      { isStepActive(TAB_SEND, 0) &&
      <OnboardingStepWidget
        height={643}
        width={1072}
        arrowLeft={-60}
        arrowTop={20}
        arrowRotate={280}
        arrowScaleX={1}
        cardLeft={700}
        cardTop={120}
        ctaText={t("onboarding_tag_results_2_cta")}
        image="onboarding-send"
        onBack={() => changeStep(TAB_EDITOR, 0)}
        onNext={() => changeStep(TAB_PROGRAM, 0)}>
        {getText("onboarding_tag_send_1_text")}
      </OnboardingStepWidget>
      }

      { isStepActive(TAB_PROGRAM, 0) &&
      <OnboardingStepWidget
        height={545}
        width={1000}
        arrowLeft={338}
        arrowTop={104}
        arrowRotate={52}
        arrowScaleX={-1}
        cardLeft={180}
        cardTop={160}
        ctaText={t("onboarding_tag_program_1_cta")}
        image="onboarding-program-1"
        onBack={() => changeStep(TAB_EDITOR, 1)}
        onNext={() => changeStep(TAB_PROGRAM, 1)}>
        {getText("onboarding_tag_program_1_text")}
      </OnboardingStepWidget>
      }
      
      { isStepActive(TAB_PROGRAM, 1) &&
      <OnboardingStepWidget
        height={600}
        width={1000}
        arrowLeft={-90}
        arrowTop={20}
        arrowRotate={300}
        arrowScaleX={1}
        cardLeft={554}
        cardTop={190}
        ctaText={t("onboarding_tag_program_2_cta")}
        image="onboarding-program-2"
        onBack={() => changeStep(TAB_PROGRAM, 0)}
        onNext={() => changeStep(TAB_RESULTS, 0)}>
        {getText("onboarding_tag_program_2_text")}
      </OnboardingStepWidget>
      }

      { isStepActive(TAB_RESULTS, 0) &&
      <OnboardingStepWidget 
        height={600}
        width={1000}
        arrowLeft={330}
        arrowTop={160}
        arrowRotate={60}
        arrowScaleX={-1}
        cardLeft={-20}
        cardTop={111}
        ctaText={t("onboarding_tag_results_1_cta")}
        image="onboarding-results-1"
        onBack={() => changeStep(TAB_PROGRAM, 1)}
        onNext={() => changeStep(TAB_RESULTS, 1)}>
        {getText("onboarding_tag_results_1_text")}
      </OnboardingStepWidget>
      }

      { isStepActive(TAB_RESULTS, 1) &&
      <OnboardingStepWidget 
        height={600}
        width={1000}
        arrowLeft={310}
        arrowTop={-60}
        arrowRotate={100}
        arrowScaleX={1}
        cardLeft={20}
        cardTop={160}
        ctaText={t("onboarding_tag_results_2_cta")}
        image="onboarding-results-2"
        onBack={() => changeStep(TAB_RESULTS, 0)}
        onNext={() => setCurrentModal(MODAL_END)}>
        {getText("onboarding_tag_results_2_text")}
      </OnboardingStepWidget>
      }

      <div className="height-60"/>

    </div>

  )
  
}


const mapStateToProps = state => ({
  _session : state._session
})

export default connect(mapStateToProps)(withTranslation()(OnboardingWidget))