/**
 * DASHBOARD-OVERVIEW
 * Homepage of the dashboard
 */

import NavDashboard from "@/nav/nav.dashboard";
import DashboardOverviewWidget from "@/widgets/dashboard-overview.widget";


function DashboardOverviewRoute(){
  return(
    <NavDashboard>
      <DashboardOverviewWidget/>
    </NavDashboard>
  )
}


export default DashboardOverviewRoute