/**
 * PROPOSITION
 */

import { snakeCase } from "lodash"
import getLabel from "@/utils/get-label.utils"
import { t } from "@/translate/t"

export class Proposition{
  aid : string | null = null //Archive id
  count : number = 0 //Count of responses
  countAnswered : number | null = null //Count of responses (for people that have answered)
  fkQuestion : number = 0 //Related question
  id : string = "" //Id
  name : any = {} //Name in differents languages
  special : string | null = null //RPS tag
  updated : boolean = false //Is updated in editor

  constructor(proposition: Partial<Proposition> = {}){
    Object.assign(this, proposition)
  }
  
  //Get label from langague
  get label():string{
    return getLabel(this.name)
  }
  
  get specialLabel():string{
    if (this.special){
      return t("rps_" + snakeCase(this.special))
    }else{
      return t("utils_no")
    }
  }

  getRatio(participationCount: number): string{
    return participationCount > 0 ? (this.count / participationCount * 100).toFixed(1) : "-"
  }

  isEmpty(language: string): boolean{
    return !this.name[language] || this.name[language].length < 1
  }

}

export interface PropositionState{
  list : Proposition[]
}

export const PROPOSITION_GET = "PROPOSITION_GET"
export const PROPOSITION_INIT = "PROPOSITION_INIT"