/** 
 * LOGIN-CODE
 * Enter code to login as supervisor (multifactor-auth)
 */

import { Component } from "react";
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from "react-redux";
import TextInput from "@/components/text-input";
import Button from "@/components/button";
import Card from "@/components/card";
import NavLogin from "@/nav/nav.login";
import Space from "@/components/space";
import LoginIconWidget from "@/widgets/login-icon.widget";
import cookie from 'react-cookies';
import { Session } from "@/redux/_session.types";
import { instanceBackend } from "@/App";
import LoginModal from "@/modals/login.modal";
 
interface Props extends WithTranslation{
  _session : Session
}

interface State {
  code : string,
  currentModal : string | null
}

const MODAL_LOGIN = "MODAL_LOGIN"

class LoginCode extends Component<Props>{
  public readonly state: Readonly<State> = {
    code : "",
    currentModal : null
  }

  //Update the state when i make changes on the text input
  handleChange = event => {
    this.setState({ code : event.value })
  }

  //Submit form
  handleSubmit = async(event) => {
    event.preventDefault()

    //Update firebase token
    instanceBackend.defaults.headers.common['firebaseToken'] = cookie.load("firebaseToken")
    
    //Launch login
    this.setState({ currentModal : MODAL_LOGIN })
      
  }

  render () {
    const { t } = this.props

    return(
      <NavLogin>

        { this.state.currentModal === MODAL_LOGIN &&
        <LoginModal authLevel={2}
          email={this.props._session.email}
          code={this.state.code}
          isSupervisor
          toSupervisor
          onClose={() => this.setState({ currentModal : null})}
        />
        }

        <LoginIconWidget/>

        <div className="flex">
          <Space/>
          <div>

            <Card>
              
              <form className="flex flex-dcol"
                onSubmit={ this.handleSubmit }>

                <div style={{ width : '300px' }}>
                  <p>
                    {t("utils_code_email")} : <br></br>
                    <b> {this.props._session.email}</b>
                  </p>
                </div>

                <TextInput
                  id="email" 
                  isFullWidth
                  value={ this.state.code }
                  onChange={ this.handleChange }
                  autoFocus
                />
                  
                <div className="height-20"/>
              
                <Button type="submit" 
                  isLarge
                  className="primary" 
                  isFullWidth>
                  {t("utils_connect")}
                </Button>
                
              </form>

            </Card>

          </div>
          
          <Space/>

        </div>

        <Space/>
        
      </NavLogin>

    )
  }
}

const mapStateToProps = state => ({
  _session : state._session
})

export default connect(mapStateToProps)(withTranslation()(LoginCode))