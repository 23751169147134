import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { SupervisorState } from "./supervisor.types"
import { addSupervisor, getSuperviors } from "./supervisor.thunk"
import { StatusType } from "@/core/utils.types"

const initialState: SupervisorState = {
  list: [],
  status: "STATUS_LOADED",
  loading: false,
  error: null
}

const supervisorSlice = createSlice({
  name: "supervisor",
  initialState,
  reducers: {
    setStatus: (state, action: PayloadAction<StatusType>) => {
      state.status = action.payload // Modifier le statut
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload // Modifier l'état de chargement
    },
    setError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload // Modifier l'état de l'erreur
    }
    // setSupervisor: (state, action) => {}
    // setSupervisors: ()
  },
  extraReducers: (builder) => {
    builder
      //Supervisors get
      .addCase(getSuperviors.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(getSuperviors.fulfilled, (state, action) => {
        state.loading = false
        state.list = action.payload
      })
      .addCase(getSuperviors.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message || "Erreur inconnue"
      })
      // Supervisor add
      .addCase(addSupervisor.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(addSupervisor.fulfilled, (state, action) => {
        state.loading = false
        state.list.push(action.payload)
      })
      .addCase(addSupervisor.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message || "Erreur inconnue"
      })
  }
})

// Export des actions
export const { setStatus, setLoading, setError } = supervisorSlice.actions

// Export du reducer
export default supervisorSlice.reducer
