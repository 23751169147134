/** 
 * LOGIN-APP
 * Login to application
 */

import { useEffect, useState } from "react"
import { withTranslation, WithTranslation } from 'react-i18next';
import { NavigateFunction, useNavigate } from "react-router-dom";
import { firebaseAppAuth } from "@/App";
import { toast } from 'react-toastify';
import TextInput from "@/components/text-input";
import Button from "@/components/button";
import Card from "@/components/card";
import NavLogin from "@/nav/nav.login";
import Space from "@/components/space";
import Link from "@/components/link";
import LoginIconWidget from "@/widgets/login-icon.widget";
import LoginModal from "@/modals/login.modal";
import { sendPasswordResetEmail } from "firebase/auth";
 
type Props = WithTranslation

const MODAL_LOGIN = "MODAL_LOGIN"

function LoginApp(props: Props){
  const { t } = props
  const navigate: NavigateFunction = useNavigate()

  const emailUrl: string | null = new URLSearchParams(window.location.search).get("email")
  const message: string | null = new URLSearchParams(window.location.search).get("message")

  const [currentModal, setCurrentModal] = useState<string | null>(null)
  const [email, setEmail] = useState<string>(emailUrl ? emailUrl : "")
  const [password, setPassword] = useState<string>("")
  const [withGoogle, setWithGoogle] = useState<boolean>(false)


  //On load
  //If message display on bottom
  useEffect(() => {
    if (message){
      toast(t(message), { type : 'error' })
    }
  }, [
    message
  ])

  useEffect(() => {
    if (email){
      setEmail(email)
    }
  }, [
    email
  ])



  //Submit form
  //> check email before
  async function handleSubmit(event: any){
    event.preventDefault()
    if (validateForm()){
      setCurrentModal(MODAL_LOGIN)
      setWithGoogle(false)
    }
  }

  //Reset password
  function resetPassword(){
    sendPasswordResetEmail(firebaseAppAuth, email).then(() => {
      toast(t("utils_email_send", { email : email }))
    }).catch(error => {
      toast(t("firebase_" + error.code ), { type : 'error' })
    })
  }

  //Login google
  function signInWithGoogle(){
    setCurrentModal(MODAL_LOGIN)
    setWithGoogle(true)
  }

  //Confirm if form can be send
  function validateForm(){
    return email.length > 0 && password.length > 0
  }

  return(
    <NavLogin>

      { currentModal === MODAL_LOGIN &&
      <LoginModal authLevel={2}
        email={email}
        password={password}
        onClose={() => setCurrentModal(null)}
        withGoogle={withGoogle}
      />
      }

      <LoginIconWidget/>

      <div className="flex">

        <Space/>

        <div>

          <Card title={t("utils_connect_idtree")}>
            
            <form className="flex flex-dcol"
              onSubmit={ handleSubmit }>

              <TextInput
                title={t("user_email")}
                id="email" 
                isFullWidth
                value={ email }
                onChange={(event: any) => setEmail(event.value)}
                type="email"
                autoFocus
              />
                
              <TextInput
                autoComplete="current-password"
                title={t("utils_password")}
                id="password"
                isFullWidth
                value={ password }
                onChange={(event: any) => setPassword(event.value)}
                type="password"
                autoFocus
              />

              <div className="flex">
                <Space/>
                <Link onClick={resetPassword}>
                  {t("self_password_forget")}
                </Link>
              </div>

              <div className="height-20"/>
              
              <Button type="submit" 
                isLarge
                className="primary" 
                isFullWidth>
                {t("utils_connect")}
              </Button>

              <div className="height-10"/>
          
              <Card onClick={signInWithGoogle}
                isWithoutPadding
                className="_hover">
                <div className="flex" style={{padding:"20px"}}>

                  <Space/>

                  <img src={require("@/assets/google.png")}
                    alt="google"
                    style={{
                      width : '20px',
                      height : '20px',
                      marginRight : '20px'
                    }}
                  />
                  
                  <div className="grey-t">
                    <b>{"GOOGLE"}</b>
                  </div>
                  
                  <Space/>

                </div>
              </Card>
    
            </form>

          </Card>

        </div>
        
        <Space/>

      </div>

      {/** link at the bottom of the page */}
      <div className="flex">
        <Space/>
        <Link isWhite
          onClick={() => navigate("/login/register")}>
          {t("self_password_create")}
        </Link>
        <Space/>
      </div>

      <Space/>
      
    </NavLogin>

  )
}

export default withTranslation()(LoginApp)