import { faCheck, faShieldAlt, faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import ListCell from "@/components/list-cell"
import ListContainer from "@/components/list-container"
import ListIcon from "@/components/list-icon"
import ListItem from "@/components/list-item"
import ProgressBar from "@/components/progress-bar"
import Space from "@/components/space"
import env from "@/env"
import { store } from "@/index"
import { flatten, uniq } from "lodash"
import { useEffect, useState } from "react"
import { WithTranslation, withTranslation } from "react-i18next"
import { Account } from "@/redux/account.types"
import { sendingFetch_AsSupervisor } from "@/redux/sending.actions"
import { surveyFetch_AsSupervisor } from "@/redux/survey.actions"
import { formatDate } from "@/utils/format-date.utils"

interface OwnProps{
  onClick : Function
}

type Props = OwnProps & WithTranslation

function SendingsAllWidget(props: Props){
  const { t } = props

  const [sendings, setSendings] = useState<any[]>([])

  useEffect(() => {
    async function loadSendings(){

      const sendingResponse: any = await store.dispatch(sendingFetch_AsSupervisor())

      if(!sendingResponse.error && sendingResponse.length > 0){

        const surveyIds: string[] = uniq(flatten(sendingResponse.map((x: any) => x.Project.Surveys.map((y: any) => y.id))))
        const surveyResponse: any = await store.dispatch(surveyFetch_AsSupervisor(surveyIds))

        if(!surveyResponse.error){

          sendingResponse.forEach((x: any, i: number) => {
            const survey: any = surveyResponse.find((y: any) => x.Project.id === y.project_id)

            if(survey){
              sendingResponse[i]["Survey"] = survey
            }
          })

          setSendings(sendingResponse)
        
        }

      }

    }

    loadSendings()
  }, [])



  return (
    <ListContainer>

      <ListItem>
        <ListCell width={200}>{t("utils_date")}</ListCell>
        <ListCell/>
        <ListCell width={150}>{t("account")}</ListCell>
        <ListCell width={200}>{t("project_name")}</ListCell>
        <ListCell width={200}>{t("survey_participation")}</ListCell>
        <ListCell width={150}>{t("email_title")}</ListCell>
        <Space />
        <ListCell width={70}>{t("utils_status")}</ListCell>
      </ListItem>

      {sendings.map((x: any) =>
      <ListItem key={x.id} bgColor={new Date(x.date).getTime() < Date.now() ? "#e9e9e9" : "#ffffff"}>
        <ListCell width={200}>{formatDate(x.date, true, false)}</ListCell>
        <ListIcon fallbackIcon={faShieldAlt}
          iconColor={"#20CA7E"}
          image={x.Project.Account.options === "true" ? env.REACT_APP_URL_SPACE.concat("/Accounts/", x.Project.Account.id, ".png?" + Date.now()) : null}
        />

        <div  
          className="_hover"
          onClick={() => props.onClick(new Account(x.Project.Account))}
        >
          <ListCell width={150}>{x.Project.Account.name}</ListCell>
        </div>

        <ListCell width={200}>{x.Project.name}</ListCell>
        <ListCell width={200}>

          {x.Survey ?
          <div style={{width: 160}}>

            <div>{`${(x.Survey.nb_done / x.Survey.nb_created * 100).toFixed(0)}%`}</div>

            <ProgressBar
              items={[
                {value: x.Survey.nb_done, text: t("survey_done")},
                {value: x.Survey.nb_started, text: t("survey_started")},
                {value: x.Survey.nb_clicked, text: t("survey_clicked")},
              ]}
              max={x.Survey.nb_created}
            />
          </div>
          :
          <div>
            {t("utils_unavailable")}
          </div>
          }

        </ListCell>

        <ListCell width={150}>{x.Email.name}</ListCell>

        <Space/>
        <ListCell width={70}>
          {new Date(x.date).getTime() < Date.now() ?
          <FontAwesomeIcon icon={x.isDone ? faCheck : faTimes} color={x.isDone ? "#00ff00" : "#ff0000"} />
          :
          <div>...</div>
          }
        </ListCell>
      </ListItem>
      )}
    </ListContainer>
  )
}

export default withTranslation()(SendingsAllWidget)