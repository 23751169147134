/**
 * SUPERADMIN-ACCOUNTS
 * List of organizations administered by multiple-account
 */

import { Component } from "react"
import { WithTranslation, withTranslation } from 'react-i18next';
import PageHeader from "@/components/page-header";
import NavApp from "@/nav/nav.app"
import AccountListWidget from "@/widgets/accounts-list.widget";

type Props = WithTranslation

class SuperadminAccounts extends Component<Props>{
  render () {
    const { t } = this.props
    return(
      <NavApp>

        <PageHeader title={t("accounts")}
          subtitle={t("superadmin_accounts_help")}>
        </PageHeader>

        <AccountListWidget />

      </NavApp>
    )
  }
}

export default withTranslation()(SuperadminAccounts)
