/** 
 * DEFAULT-IMAGES.MODAL
 * default images library used for axes
 */

import { connect } from "react-redux"
import { Session } from "@/redux/_session.types";
import env from "@/env";

interface StateProps {
  _session : Session
}

interface OwnProps{
  selected?: string | null
  onClick? : Function
}

type Props = StateProps & OwnProps

const defaultAxisImages: string[] = [
  "d60dbbbb-a39e-4171-b880-1703721f8723",
  "c5eea81e-c59e-4745-9faf-2a2d7311801e",
  "e13a045e-6402-4577-b4a3-2fc6ea4b9f62",
  "f3aa87c6-16da-4e98-bc40-e742f1c3cadc",
  "4c10514d-3597-46e9-9079-8c80b71cbae8",
  "4c2cff22-ad87-45e0-8f5e-bd826274c2c2",
  "b07a0721-20bd-441b-aad4-49ed4b3710ad",
  "387a5815-489b-4129-968f-ad98d3600c8f",
  "d000abfd-fc1c-4fe6-920d-b0e50c95501d",
  "4fdbd8a4-b513-4467-931f-c47dc706bab8",
  "917fbaf3-5049-4dab-828f-398a4586835e",
  "96e0b820-bdd0-49bb-a11b-8a92b11dfcd4",
  "0282006b-0807-4874-a233-f996fae4b5b3",
  "0f5bc68c-3642-42ef-9625-bcaa86343f1a",
  "a2289e3f-b095-461e-b1dc-674e1a6c93dd",
  "826392b1-d43f-4dd0-b1b9-eaa60ccac0cd",
  "1148d368-91c7-4932-9fb0-3a2240b2e6e8",
  "eb7b6407-5a4e-41d2-bcdd-fa16ef81403a",
  "2e4603a7-9222-4e63-a4bf-7a0123c6d842",
  "e820434e-9591-4c4c-8abf-63b2a75873a4",
  "09038d4f-401e-4a66-a5fe-1c6e4de3c9a7",
  "ba3e8388-4fda-43b1-9458-619ae33f9474",
  "8c919376-eea3-425b-8edd-0642c6cb18a9",
  "1982bd1a-8dc5-459d-89dc-d892dc32ea67",
  "325c7685-c7dd-4571-afc0-a8a770b45a5e",
  "5d7a4d82-cf7f-4d81-aaf2-3db36b8d68ff",
  "1c9389fe-e5bd-4712-9b86-84a2f96cc6ec",
  "fe8d7c55-4ccf-4ade-8b6e-a2ad02dd32e7",
  "169379b0-8e8c-404b-9e12-a56216dfb661",
  "195271c7-42fa-486a-bf35-27747fac25d2",
  "05a27377-c37b-4ad1-b212-80c8d35104b1",
  "604b6c7b-f75e-4601-8c92-3b9171579178",
  "23de212f-700b-4897-9b37-3fc816cc7ca3",
  "e3d3f8cb-f301-4095-b9c8-e7722def1668",
  "c982c9b5-11ab-4a4f-9a95-091c93d45d2d",
  "07ad706a-448a-4de2-a6d6-ffdb6c862e68",
  "81873180-9cf1-43a1-bf29-1f2134f6e326",
  "7f3f85e7-9f4b-4427-ae77-e5a724ddea66",
  "155ec79a-20d4-4bef-8e35-bb5e39f87fc3",
  "4de221ea-61e0-426e-89f7-9bc51a9b3256"
]


function DefaultImages(props: Props){

  function click(image: string){
    if(props.onClick){
      props.onClick(image)
    }
  }

  return(
    <div className="flex flex-wrap">
      {defaultAxisImages.map((image: string, i: number)=>
      <img
        key={image}
        className={(props.onClick && props.selected !== image) ? "_hover" : ""}
        style={{
          objectFit:"cover",
          border: props.selected === image ? ("2px solid " + props._session.accountColors.active) : "", 
          borderRadius: 8, 
          margin: props.selected !== image ? 2 : 0, 
          padding:10
        }}
        alt={"default_image_"+ i}
        height={80}
        width={80}
        onClick={() => click(image)}
        src={env.REACT_APP_URL_SPACE.concat("/_default/", image + ".png?" + (new Date().getTime()))}
      />
      )}
    </div>
  )
}

const mapStateToProps = state => ({
  _session : state._session,
})

export default connect(mapStateToProps)(DefaultImages)