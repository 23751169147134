/** 
 * ADM-ROLES.WIDGET
 * Set permissions for account
 */

import { store } from "@/index";
import { withTranslation, WithTranslation } from "react-i18next"; 
import { useEffect, useState } from "react";
import { userActivate, userDestroy, userEditAndUpdateRole, userFetch, userFetch_AsSuperadmin, userFetchCount, userGet, userRemove, userStatus, userUpdatePasswordProvided_AsAdmin } from "@/redux/user.actions";
import { User, UserState, USER_ROLES, USER_ROLE } from "@/redux/user.types";
import ListFields from "@/components/list-fields";
import { connect } from "react-redux";
import ListItem from "@/components/list-item";
import ListButton from "@/components/list-button";
import ListCell from "@/components/list-cell";
import { faCrown, faEye, faPencilAlt, faUserCircle, faUserMinus, faCopy, faEllipsisV, faEnvelope, faCaretRight, faEnvelopeOpen, faEyeSlash, faComments } from "@fortawesome/free-solid-svg-icons";
import SaveIcon from "@/components/save-icon";
import { Session } from "@/redux/_session.types";
import ListIcon from "@/components/list-icon";
import ModalConfirm from "@/components/modal-confirm";
import Card from "@/components/card";
import { STATUS_LOADED, STATUS_LOADING, STATUS_SEARCHING } from "@/redux/_status.types";
import PageLoader from "@/components/page-loader";
import { toast } from "react-toastify";
import Chip from "@/components/chip";
import { sortBy } from "lodash";
import SelectPopulationsModal from "@/modals/select-populations.modal";
import { Population } from "@/redux/population.types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AdmEditUserModal from "@/modals/adm-edit-user.modal";
import ListContainer from "@/components/list-container";
import ReactMarkdown from "react-markdown";
import { AccountState } from "@/redux/account.types";
import AdmEditChildrenModal from "@/modals/adm-edit-children.modal";
import { feedbackSend } from "@/redux/feedback.actions";
import AdmSelectRoleModal from "@/modals/adm-select-role.modal";
import AdmSendEmailModal from "@/modals/adm-send-email.modal";
import LoadingModal from "@/modals/loading.modal";

interface StateProps extends WithTranslation{
  _session : Session
  account : AccountState
  user : UserState
}

interface OwnProps{
  editChildren? : boolean
}

type Props = StateProps & OwnProps

//Actions for button
const ACTION_COPY_LINK = "ACTION_COPY_LINK" //Copy invitation link
const ACTION_EDIT = "ACTION_EDIT" //Edit user
const ACTION_FEEDBACK = "ACTION_FEEDBACK" //Get user feedback
const ACTION_PREVIEW = "ACTION_PREVIEW" //Preview dashboard use
const ACTION_REMOVE = "ACTION_REMOVE" //Remove from roles list

//Modales
const MODAL_CONFIRM_REMOVE_ROLE = "MODAL_CONFIRM_REMOVE_ROLE" //Confirm removing rol
const MODAL_EDIT_CHILDREN = "MODAL_EDIT_CHILDREN" //Edit the superadmin
const MODAL_EDIT_USER = "MODAL_EDIT_USER" //Edit user informations
const MODAL_LOADING = "MODAL_LOADING" //Loading modal
const MODAL_SELECT_POPULATIONS = "MODAL_SELECT_POPULATIONS" //Select populations for dashboard
const MODAL_SELECT_ROLE = "MODAL_SELECT_ROLE" //Set role for new user
const MODAL_SEND_EMAIL = "MODAL_SEND_EMAIL" //Confirmation before send email
const MODAL_SEND_FEEDBACK = "MODAL_SEND_FEEDBACK" //Send feedback to admins

//Limit of users to display by page
const LIMIT : number = 10

function AdmRolesWidget(props:Props) {
  const { t } = props

  //List of actions available
  const [actions] = useState(initActions())

  //Current modal to display
  const [currentModal, setCurrentModal] = useState<string | null>(null)

  //Current offset for search (when page is changed)
  const [currentOffset, setCurrentOffset] = useState<number>(0)

  //Content for searchbar
  const [currentSearch, setCurrentSearch] = useState<string>("")

  //User to edit (for populations)
  const [currentUser, setCurrentUser] = useState(new User())

  //Data is loading
  const [isLoading, setIsLoading] = useState<boolean>(true)

  //Get search count of admins
  const [searchCount, setSearchCount] = useState<number>(0)

  //Load data
  //Fetch all admin for account
  useEffect(() => {

    load()

    async function load(){

      const admins:any = (props._session.interfaceType === "SUPERADMIN" && props.editChildren)
        ?
        await store.dispatch(userFetch_AsSuperadmin(props.account.active.id))
        :
        await store.dispatch(userFetch(currentSearch, LIMIT, currentOffset, true, "id,email,firstname,lastname,role"))

      store.dispatch(userGet(admins.error ? [] : admins))
      store.dispatch(userStatus(STATUS_LOADED))
      setIsLoading(false)
    }

  }, [
    props.account.active.id,
    props.editChildren,
    props._session.interfaceType,
    currentOffset,
    currentSearch
  ])

  useEffect(() => {

    updateAdminCount()

    async function updateAdminCount(){
      const response: any = await store.dispatch(userFetchCount(currentSearch, true))
      setSearchCount(response.error ? 0 : response.count)
    }

  }, [currentSearch])

  //Copy specific link
  function copyLink(user:User){
    navigator.clipboard.writeText(user.getInvitationLink(props._session))
    return toast(t("utils_copy_link_done"))
  }

  //Check if user is connected
  //Can not change role for connected user
  function isConnectedUser(userId:string){
    return userId === props._session.userId
  }

  //Open select population modal
  function editPopulationsObserver(user:User){
    setCurrentUser(user)
    setCurrentModal(MODAL_SELECT_POPULATIONS)
  }

  //Change role of the user
  function editRole(user){
    setCurrentUser(user)
    setCurrentModal(MODAL_SELECT_ROLE)
  }

  //Get color for email dot
  function getSendDotColor(user:User){
    if (user.connectedAt){
      return "green"
    }else if (user.invitedAt){
      return "orange"
    }else{
      return "red"
    }
  }

  //Display text for tooltip
  function getSendText(user: User){
    let text = hasEmail(user) ? t("utils_email_send_action") : t("invitation_no_email") + " !" 
    
    if (user.connectedAt){
      text = text + " (" + t("user_connected_at", {
        date :  new Date(user.connectedAt).toLocaleDateString(navigator.language, {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit'
        })
      }) + ")"
    }else if (user.invitedAt){
      text = text + " (" + t("user_invited_at", {
        date :  new Date(user.invitedAt).toLocaleDateString(navigator.language, {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit'
        })
      }) + ")"
    }

    return text

  }

  //Get component for the user
  function getUserRole(user:User){
    const roleAdm = USER_ROLES.find(x => x.id === user.role)
    return (
      <Chip color={user.role === "ADMIN" ? props._session.accountColors.active : null}
        onClick={() => editRole(user)}
        icon={roleAdm?.icon}>
        {t("roles_" + roleAdm?.id.toLowerCase())}
      </Chip>
    )
  }

  //Check if email was provided for user
  function hasEmail(user: User): boolean{
    return (user.email && user.email.length>0) ? true : false
  }

  //Init actions
  //Specic lines for supervisors
  function initActions(){

    const list = [
      { id : ACTION_PREVIEW, label : t("roles_preview"), icon : faEye },
      { id : ACTION_COPY_LINK, label : t("utils_copy_link"), icon : faCopy },
      { id : ACTION_EDIT, label : t("user_edit"), icon : faPencilAlt },
      { id : ACTION_REMOVE, label : t("roles_remove"), icon : faUserMinus }
    ]

    if (props._session.connectedAsSupervisor){
      list.push({ id : ACTION_FEEDBACK, label : t("roles_feedback"), icon : faComments })
    }

    return list

  }

  //Open dashboard
  async function preview(user:User){
    let error = false

    //Reset the password for the user (temp effect in order to access the dashboard)    
    if (user.passwordProvided){
      setCurrentModal(MODAL_LOADING)
      const response:any = await store.dispatch(userUpdatePasswordProvided_AsAdmin(user.id))
      error = response.error ? true : false
      setCurrentModal(null)
    }

    if (!error){
      window.open(user.getInvitationLink(props._session) + "&isAdmin=true", "_blank")
    }

  }

  //Select an action from context menu
  function selectAction(user:User, action:string){
    setCurrentUser(user)
    store.dispatch(userActivate(user))
    switch(action){
      case ACTION_COPY_LINK:
        return copyLink(user)
      case ACTION_FEEDBACK:
        return setCurrentModal(MODAL_SEND_FEEDBACK)
      case ACTION_REMOVE:
        return setCurrentModal(MODAL_CONFIRM_REMOVE_ROLE)
      case ACTION_PREVIEW:
        return preview(user)
      case ACTION_EDIT:
        if (props.editChildren){
          return setCurrentModal(MODAL_EDIT_CHILDREN)
        }else{
          return setCurrentModal(MODAL_EDIT_USER)
        }
    }
  }

  //Click on sendEmail button (open dialog)
  function sendEmail(user:User){
    setCurrentUser(user)
    setCurrentModal(MODAL_SEND_EMAIL)
  }

  //Send feedback to the user
  async function sendFeedback(){
    setCurrentModal(null)
    const response:any = await store.dispatch(feedbackSend(currentUser.id))
    if (!response.error){
      toast(t("utils_email_send", { email : currentUser.email }))
    }
  }

  //Define role
  function setRole(user: User, role: USER_ROLE | null, populations: Population[], excludedFilters:string[]){

    //If superadmin interface remove also user in the database
    if (props._session.interfaceType === "SUPERADMIN" && !role){
      store.dispatch(userDestroy(user.id))
    }else{
      store.dispatch(userEditAndUpdateRole(user, role, populations, excludedFilters))
    }

    if (currentModal) setCurrentModal(null)

    //If no role is defined remove user
    if (!role){
      store.dispatch(userRemove(user.id))
    }

  }

  return (
    <Card isWithoutPadding>

      { currentModal === MODAL_LOADING &&
      <LoadingModal/>
      }
        
      { /** confirm removing permissions */
      currentModal === MODAL_CONFIRM_REMOVE_ROLE &&
      <ModalConfirm onNo={() => setCurrentModal(null)}
        onYes={() => setRole(currentUser, null, [], [])}
        text={t("user_admin_remove_confirm", { name : currentUser.username })}
        textBold={t("utils_next_ask")}
      />
      }

      { currentModal === MODAL_SELECT_ROLE &&
      <AdmSelectRoleModal currentUser={currentUser} onClose={() => setCurrentModal(null)}/>
      }
      
      { currentModal === MODAL_EDIT_USER &&
      <AdmEditUserModal userToUpdate={currentUser} 
        onClose={() => setCurrentModal(null)}
        onNext={() => setCurrentModal(null)}/>
      }

      { currentModal === MODAL_EDIT_CHILDREN &&
      <AdmEditChildrenModal onClose={() => setCurrentModal(null)} isNew={false}/>
      }

      { currentModal === MODAL_SELECT_POPULATIONS &&
      <SelectPopulationsModal
        excludedFilters={currentUser.excludedFilters}
        isWithoutDefaultFilters
        isSurveyFiltersIncluded
        isAccountSelectable
        onClose={() => setCurrentModal(null)}
        selectedPopulations={currentUser.populationsObserver}
        onNext={(populations:Population[], excludedFilters:string[]) => setRole(currentUser, "OBSERVER", populations, excludedFilters)}
        title={t("roles_select_populations", {
          name : currentUser.username
        })}
      />
      }

      { currentModal === MODAL_SEND_EMAIL &&
      <AdmSendEmailModal onClose={() => setCurrentModal(null)}
        currentUser={currentUser}
        textBold={t("utils_next_ask")}
        text={t("utils_email_ask_confirm", { 
          email : currentUser.email,
          name : currentUser.username
        })}
      />
      }

      { currentModal === MODAL_SEND_FEEDBACK &&
      <ModalConfirm onNo={() => setCurrentModal(null)}
        onYes={sendFeedback}
        text={t("utils_feedback_ask_confirm", { 
          email : currentUser.email,
          name : currentUser.username
        })}
        textBold={t("utils_next_ask")}
      />
      }

      <ListContainer countLimit={LIMIT}
        countSearch={searchCount}
        isFooterActive
        onAddTitle={t("user_add")}
        onSearch={setCurrentSearch}
        onSearchStart={() => store.dispatch(userStatus(STATUS_SEARCHING))}
        onSetPage={setCurrentOffset}
        status={props.user.status}>

        <PageLoader status={isLoading ? STATUS_LOADING : STATUS_LOADED}>

          <ListFields>
            <ListCell/>
            <ListCell width={174} text={t("user_lastname")}/>
            <ListCell width={155} text={t("utils_permissions")}/> 
          </ListFields>

          { props.user.list.length === 0 &&
          <ListItem>
            <ListCell/>
            <ReactMarkdown>
              {currentSearch.length > 0 ? t("search_no_result", {search : currentSearch}) : t("roles_empty")}
            </ReactMarkdown>
          </ListItem>
          }

          { props.user.list.map((user: User)=>
          <ListItem key={user.id}>  
            
            <ListIcon fallbackIcon={faUserCircle}
              image={user.imageUrl}
            />

            <ListCell width={170} text={user.username}/>

            { isConnectedUser(user.id)
            ?
            <Chip color={props._session.accountColors.active}
              icon={faCrown}>
              {t("roles_admin")}
            </Chip>
            :
            <div className="flex flex1">

              {getUserRole(user)}

              <div className="width-10"/>

              <div className="flex1">

                { user.role === "OBSERVER" && user.populationsObserver.length === 0 &&
                <div className="_hover grey-t"  
                  onClick={() => editPopulationsObserver(user)}>
                  {props._session.accountName}
                </div>
                }

                { sortBy(user.populationsObserver, "name").map((population, i) =>
                <div key={i}
                  className="_hover grey-t"
                  onClick={() => editPopulationsObserver(user)}>
                  <FontAwesomeIcon icon={faCaretRight}/>
                  &nbsp;
                  {population.filterName} : <b>{population.name}</b>
                </div>
                )
                }

                { user.excludedFilters.length > 0 &&
                <div className="grey-t _hover"
                  onClick={() => editPopulationsObserver(user)}
                  style={{ fontSize : 12, marginTop : 8 }}>
                  <div>
                    <FontAwesomeIcon icon={faEyeSlash}/>
                    &nbsp;
                    <b>Filtres masqués</b>
                  </div>
                  { user.excludedFilters.map((filterName, i) => 
                  <div key={i}>
                    <FontAwesomeIcon icon={faCaretRight}/>
                    &nbsp;
                    {filterName}
                  </div>
                  )
                  }
                </div>
                }

              </div>
              
              <div className="width-10"/>

              <SaveIcon status={currentUser.id === user.id ?
                props.user.status : null }
              />
              
              <ListButton icon={user.connectedAt ? faEnvelopeOpen : faEnvelope}
                dot={hasEmail(user)}
                dotColor={getSendDotColor(user)}
                isInactive={user.invitedAt || user.connectedAt || !hasEmail(user) ? true : false}
                onClick={() => hasEmail(user) ? sendEmail(user) : toast(t("invitation_no_email"), {type: "error"} )}
                text={getSendText(user)}
              />

              <ListButton
                onAction={(action:string) => selectAction(user, action)}
                icon={faEllipsisV}
                actions={actions}
              />

            </div>
            }

          </ListItem>
          )}
        </PageLoader>
      </ListContainer>
    </Card>

  )
}

const mapStateToProps = state => ({
  _session : state._session,
  account : state.account,
  user : state.user
})

export default connect(mapStateToProps)(withTranslation()(AdmRolesWidget))
