/**
 * TEMPLATE.ACTIONS
 */
import { TEMPLATE_ACTIVATE, TEMPLATE_ADD, TEMPLATE_CANCEL, TEMPLATE_EDIT, TEMPLATE_EDIT_DESCRIPTION, TEMPLATE_EDIT_IMAGE, TEMPLATE_EDIT_LANGUAGE, TEMPLATE_EDIT_NAME, TEMPLATE_GET, TEMPLATE_GET_COUNT, TEMPLATE_GET_COUNT_SEARCH, TEMPLATE_INIT, TEMPLATE_REMOVE, TEMPLATE_STATUS } from "./template.types";
import { Session } from "./_session.types";
import { STATUS_LOADED, STATUS_LOADING, STATUS_LOAD_ERROR, STATUS_SAVED, STATUS_SAVE_ERROR, STATUS_SAVING } from "./_status.types";

export const templateActivate = template => ({
  type : TEMPLATE_ACTIVATE,
  payload : {
    template
  }
})

export const templateAdd = (language) => ({
  type : TEMPLATE_ADD,
  payload : {
    language
  }
})

export const templateCancel = () => ({
  type : TEMPLATE_CANCEL
})

export const templateEdit = (key, value) => ({
  type : TEMPLATE_EDIT,
  payload : {
    key,
    value
  }
})

export const templateEditDescription = (description, value) => ({
  type : TEMPLATE_EDIT_DESCRIPTION,
  payload : {
    description,
    value
  }
})

export const templateEditImage = image => ({
  type : TEMPLATE_EDIT_IMAGE,
  payload : {
    image
  }
})

export const templateEditLanguage = (language) => ({
  type : TEMPLATE_EDIT_LANGUAGE,
  payload : {
    language
  }
})

export const templateEditName = (language, value) => ({
  type : TEMPLATE_EDIT_NAME,
  payload : {
    language,
    value
  }
})

export const templateGet = templates => ({
  type : TEMPLATE_GET,
  payload : {
    templates
  }
})

export const templateGetCount = (count:number) => ({
  type : TEMPLATE_GET_COUNT,
  payload : {
    count
  }
})

export const templateGetCountSearch = (countSearch:number) => ({
  type : TEMPLATE_GET_COUNT_SEARCH,
  payload : {
    countSearch
  }
})

export const templateInit = () => ({
  type : TEMPLATE_INIT
})

export const templateRemove = id => ({
  type : TEMPLATE_REMOVE,
  payload : {
    id
  }
})

export const templateStatus = status => ({
  type : TEMPLATE_STATUS,
  payload : {
    status
  }
})


//API

export const templateDestroy = id => ({
  type : 'API',
  payload : {
    method : "DELETE",
    url : "/template/" + id
  }
})

export const templateDestroy_AsSupervisor = id => ({
  type : 'API',
  payload : {
    method : "DELETE",
    url : "/supervisor/template/" + id
  }
})

export const templateDuplicate = (id, name) => ({
  type : 'API',
  payload : {
    method : "POST",
    url : "/template/duplicate",
    data : {
      id,
      name
    }
  }
})

export const templateDuplicate_AsSupervisor = (id, name) => ({
  type : 'API',
  payload : {
    method : "POST",
    url : "/supervisor/template/duplicate",
    data : {
      id,
      name
    }
  }
})

export const templateFetch = () => ({
  type : 'API',
  payload : {
    method : "GET",
    url : "/templates"
  }
})

export const templateFetch_FromSuperadmin = () => ({
  type : 'API',
  payload : {
    method : "GET",
    url : "/superadmin/templates"
  }
})

export const templateFetchPublic = language => ({
  type : 'API',
  payload : {
    method : "GET",
    url : "/public/templates",
    data : {
      language
    }
  }
})

export const templateFetchOne = (templateId:string) => ({
  type : 'API',
  payload : {
    method : "GET",
    url : "/public/template",
    data : {
      templateId
    }
  }
})

export const templateFetch_AsSupervisor = (search, language, limit, offset) => ({
  type : 'API',
  payload : {
    method : "GET",
    url : "/supervisor/templates",
    data : {
      search,
      language,
      limit,
      offset
    }
  }
})

export const templateFetchCount_AsSupervisor = (search, language) => ({
  type : 'API',
  payload : {
    method : "GET",
    url : "/supervisor/templates/count",
    data : {
      search,
      language
    }
  }
})

export const templateInsert = (id, name) => ({
  type : 'API',
  payload : {
    method : "POST",
    url : "/template/insert",
    data : {
      id,
      name
    }
  }
})

export const templateInsert_AsSupervisor = (id, name) => ({
  type : 'API',
  payload : {
    method : "POST",
    url : "/supervisor/template/insert",
    data : {
      id,
      name
    }
  }
})

export const templateUpdate = template => ({
  type : 'API',
  payload : {
    method : "POST",
    url : "/template/update",
    data : {
      template
    }
  }
})

export const templateUpdate_AsSupervisor = template => ({
  type : 'API',
  payload : {
    method : "POST",
    url : "/supervisor/template/update",
    data : {
      template
    }
  }
})


//THUNK

export const templateEditAndUpdate:any = (key:string, value:any) => async (dispatch, getState) => {
  const updateFunction = getState()._session.interfaceType === "SUPERVISOR" ? templateUpdate_AsSupervisor : templateUpdate
  dispatch(templateStatus(STATUS_SAVING))
  dispatch(templateEdit(key, value))
  const response = await dispatch(updateFunction(getState().template.active))
  dispatch(templateStatus(response.error ? STATUS_SAVE_ERROR : STATUS_SAVED))
}

export const templateEditLanguageAndUpdate:any = (language:string) => async (dispatch, getState) => {
  const updateFunction = getState()._session.interfaceType === "SUPERVISOR" ? templateUpdate_AsSupervisor : templateUpdate
  dispatch(templateStatus(STATUS_SAVING))
  dispatch(templateEditLanguage(language))
  const response = await dispatch(updateFunction(getState().template.active))
  dispatch(templateStatus(response.error ? STATUS_SAVE_ERROR : STATUS_SAVED))
}

export const templateFetchAndGet:any = (loadSupervisorTemplates:boolean, loadSuperadminTemplates:boolean, loadAccountTemplates:boolean, activateTemplate:boolean) => async (dispatch, getState) => {
  const session:Session = getState()._session

  //Init loading
  dispatch(templateGet([]))
  dispatch(templateStatus(STATUS_LOADING))

  //Load from account
  const accountTemplates:any = loadAccountTemplates ? await dispatch(templateFetch()) : []

  //Load from superadminAccount
  let superadminTemplates:any = loadSuperadminTemplates ? await dispatch(templateFetch_FromSuperadmin()) : []

  //Load from idtree
  let supervisorTemplates:any = loadSupervisorTemplates ? await dispatch(templateFetchPublic(session.language)) : []

  //Handle errors
  if (supervisorTemplates.error || superadminTemplates.error || accountTemplates.error){
    dispatch(templateStatus(STATUS_LOAD_ERROR))
  }
  //Handle responses
  else{

    //Set superadminTemplate As Supervisor
    superadminTemplates.forEach(template => {
      template.fromSupervisor = true
      template.free = false
      template.options.tag = "superadmin"
    })

    //Filter template according permissions
    if (session.accountIsChildren){
      if (session.accountTemplateRule === "public"){
        superadminTemplates = []
      }else if (session.accountTemplateRule === "blocked"){
        superadminTemplates = []
        supervisorTemplates = []
      }else if (session.accountTemplateRule === "account"){
        supervisorTemplates = []
      }else if (session.accountTemplateRule === "list"){
        superadminTemplates = superadminTemplates.filter(x => session.accountAllowedTemplates.indexOf(x.id) > -1)
        supervisorTemplates = supervisorTemplates.filter(x => session.accountAllowedTemplates.indexOf(x.id) > -1)
      }
    }

    //Save templates
    const templates = accountTemplates.concat(superadminTemplates).concat(supervisorTemplates)

    if (templates.length && activateTemplate){
      dispatch(templateActivate(templates[0]))
    }
    
    dispatch(templateGet(templates))
    dispatch(templateStatus(STATUS_LOADED))

  }

}