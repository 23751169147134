/**
 * YEARS-TO-RANGE.UTILS.TS
 * Get a date range from a year
 */

export function dateFromYears(refDate: Date | null, years: number): Date{
  const date: Date = refDate ? new Date(refDate) : new Date()
  date.setFullYear(date.getFullYear() - years)
  return date
}

export const dateYearsToRange = (start: Date | null, ceils: number[], index: string): string => {
  
  const numIndex = parseInt(index)
  const yearsMin = ceils[numIndex] ? ceils[numIndex] : 200
  const yearsMax = ceils[numIndex - 1] ? ceils[numIndex - 1] : 0

  return dateFromYears(start, yearsMin).toISOString().split('T')[0] + ',' + dateFromYears(start, yearsMax).toISOString().split('T')[0]

}
