export function getAssociatedLearning(topicId: string): string | null{
  const correspondance: {learningId: string, topicId: string} | undefined = learningTopicCorrespondance.find((x: {learningId: string, topicId: string}) => x.topicId === topicId)
  return correspondance ? correspondance.learningId : null
}

const learningTopicCorrespondance: {learningId: string, topicId: string}[] = [
  {
    learningId: "7905d763-e989-460f-ad36-beaf95306584",
    topicId: "0a8d4224-31fa-481a-ac1e-58cdc8ceb4f5",
  },
  {
    learningId: "7905d763-e989-460f-ad36-beaf95306584",
    topicId: "827439e8-3b3c-4fc7-84a1-94d07962eefb",
  }
]
