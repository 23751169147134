/**
 * DASHBOARD-REPARTION.ROUTE
 * Results by inequalities
 */

import NavDashboard from "@/nav/nav.dashboard";
import DashboardInequalitiesWidget from "@/widgets/dashboard-inequalities.widget";

function DashboardRepartitionRoute(){

  return(
    <NavDashboard title="survey_repartition"
      hideOptions
      //hideFilters
    >
      <DashboardInequalitiesWidget/>
    </NavDashboard>
  )
}

export default DashboardRepartitionRoute