/**
 * DASHBOARD-NPS
 * Recommandation indicators
 */
import { Component } from "react";
import NavDashboard from "@/nav/nav.dashboard";
import { Session } from "@/redux/_session.types";
import { connect } from "react-redux";
import UpgradeWidget from "@/widgets/upgrade.widget";
import DashboardNpsWidget from "@/widgets/dashboard-nps.widget";

interface Props{
  _session: Session
}

class DashboardNps extends Component<Props> {
  render() {
    return (
      <NavDashboard title="nps_title"
        helpPage={21}>
        {!this.props._session.modules.nps 
        ?
        <UpgradeWidget feature="nps"/>
        :
        <DashboardNpsWidget/>
        }
      </NavDashboard>
    );
  }
}

const mapStateToProps = (state) => ({
  _session : state._session
});

export default connect(mapStateToProps)(DashboardNps)
