/** 
 * PACK
 * Pack of invitations
 */

 export class Pack{
  id : string = ""
  date : Date = new Date()
  count : number = 50
  price : number = 200

  constructor(pack: Partial<Pack> = {}){
    Object.assign(this, pack)
  }

  //Get date in string (for input)
  get dateInput():string{
    return new Date(this.date).toISOString().split('T')[0]
  }

  //Get date in string
  get dateLabel():string{
    return new Date(this.date).toLocaleDateString()
  }
  
}

export interface PackState{
  list : Pack[]
}

export const PACK_LIST = [
  { count : 50, unitPrice : 4 },
  { count : 100, unitPrice : 3 },
  { count : 200, unitPrice : 2, default : true },
  { count : 400, unitPrice : 1.8 },
  { count : 600, unitPrice : 1.6 },
  { count : 1000, unitPrice : 1.4 },
  { count : 2000, unitPrice : 1.2 }
]

export const PACK_ADD = 'PACK_ADD'
export const PACK_EDIT = 'PACK_EDIT'
export const PACK_GET = 'PACK_GET'
export const PACK_INIT = 'PACK_INIT'
export const PACK_REMOVE = 'PACK_REMOVE'