/** 
 * GET-POPULATION-USERS
 * Get all users for one population
 */
import Modal from "@/components/modal"
import { connect } from "react-redux"
import { withTranslation, WithTranslation } from "react-i18next"
import { User } from "@/redux/user.types";
import { store } from "@/index";
import { userFetchByPopulation } from "@/redux/user.actions";
import { STATUS_LOADED, STATUS_LOADING, STATUS_SEARCHING } from "@/redux/_status.types";
import { useEffect, useState } from "react";
import PageLoader from "@/components/page-loader";
import { FilterState } from "@/redux/filter.types";
import ListContainer from "@/components/list-container";
import ListItem from "@/components/list-item";
import ListIcon from "@/components/list-icon";
import { faExclamationTriangle, faUserCircle } from "@fortawesome/free-solid-svg-icons";
import ListCell from "@/components/list-cell";
import { Session } from "@/redux/_session.types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface StateProps extends WithTranslation{
  _session : Session
  filter : FilterState
}

interface OwnProps{
  isDefaultFilter? : boolean
  defaultFilterId? : string
  defaultPopulationId? : string
  filterName? : string
  onClose : Function
  populationName : string
}

type Props = StateProps & OwnProps

const LIMIT = 5

function GetPopulationUsersModal(props:Props) {
  const { t } = props

  const [currentSearch, setCurrentSearch] = useState("")
  const [isLoading, setIsLoading] = useState(true)
  const [isSearching, setIsSearching] = useState(false)
  const [currentOffset, setCurrentOffset] = useState(0)
  const [users, setUsers] = useState<User[]>([])
  const [usersCount, setUsersCount] = useState(0)
  const [currentFilterName] = useState<string>(props.filterName ? props.filterName : props.filter.active.name)

  //On load fetch users
  useEffect(() => {

    load()

    async function load(){

      const users: any = await store.dispatch(userFetchByPopulation(
        props.defaultFilterId ? props.defaultFilterId : currentFilterName,
        props.populationName,
        currentSearch,
        props.isDefaultFilter ? true : false,
        props.defaultFilterId ? props.defaultFilterId : "",
        props.defaultPopulationId ? props.defaultPopulationId : ""
      ))

      setUsers(users.error ? [] : users.map(x => new User(x)))

      if (isLoading){
        setUsersCount(users.length)
      }

      setIsLoading(false)

    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentSearch,
    currentFilterName,
    props.populationName
  ])

  //Search user
  function search(value){
    setCurrentSearch(value)
    setIsSearching(false)
  }

  return (
    <Modal isCloseButtonVisible
      title={props.populationName}
      onClose={() => props.onClose()}>

      <PageLoader status={isLoading ? STATUS_LOADING : STATUS_LOADED}>

        <div style={{ 
            width : "600px", 
            margin : "0px -40px" 
          }}>
          <ListContainer countLimit={LIMIT}
            title={t("users")}
            countSearch={users.length}
            isFooterActive={currentSearch === ""}
            onSearch={search}
            onSearchStart={() => setIsSearching(true)}
            onSetPage={setCurrentOffset}
            status={isSearching ? STATUS_SEARCHING : STATUS_LOADED}>

            { users
              .filter(x => currentSearch.length ? x.username.toLowerCase().indexOf(currentSearch.toLowerCase()) > -1 : x)
              .slice(currentOffset, currentOffset + LIMIT)
              .map((user) =>
            <ListItem key={user.id}>
              
              <ListIcon fallbackIcon={faUserCircle}
                image={user.imageUrl}
              /> 

              <ListCell text={user.username} width={200}/>

            </ListItem>
            )
            }

          </ListContainer>

          { usersCount < props._session.participationMin &&
          <ListItem>
            <b className="orange-t">
              <FontAwesomeIcon icon={faExclamationTriangle}/>
              &nbsp;
              {t("survey_no_results_help", {
                count : props._session.participationMin
              })}
            </b>
          </ListItem>
          }

        </div>

      </PageLoader>

    </Modal>
  )
}

const mapStateToProps = state => ({
  _session : state._session,
  filter : state.filter
})

export default connect(mapStateToProps)(withTranslation()(GetPopulationUsersModal))