/** 
 * BUTTON-SCROLLTOP
 * Click to scroll on top of the page
 */
import { faChevronUp } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"
import { withTranslation, WithTranslation } from "react-i18next"
import ReactTooltip from 'react-tooltip'
import { FadeIn } from "@/utils/animations.utils"

type Props = WithTranslation

function ButtonScrolltop(props: Props) {
  const { t } = props

  const [showButton, setShowButton] = useState(false)
  const [scrollDiv, setScrollDiv] = useState<any>(null)

  useEffect(() => {
    let scrollDiv:any

    //Detect scroll button visible or not
    function handleScroll(){
      if (scrollDiv){
        setShowButton(scrollDiv.scrollTop > 0)
      }
    }

    //Get button element
    let element:any = document.getElementById("scrolltop-button")

    //Find scrollable parent
    while (element){
      if (element.scrollHeight > element.clientHeight){
        break
      }
      
      element = element.parentNode

    }

    //Affect event to scrollable parent
    //Unmount event on return
    if (element){
      scrollDiv = element
      scrollDiv.addEventListener('scroll', handleScroll)
      setScrollDiv(scrollDiv)
      return () => scrollDiv.removeEventListener('scroll', handleScroll)
    }

  }, [])

  //Click on scrollTop
  function scrollTop(){
    if (scrollDiv){
      scrollDiv.scrollTop = 0
    }
  }

  return (
    <div id="scrolltop-button"
      className="scrolltop">

      { showButton &&
      <FadeIn>
        <div data-tip={t("utils_scrolltop")}
          data-place="left"
          className="page-navigation-contact flex" 
          onClick={scrollTop}>
          <FontAwesomeIcon icon={faChevronUp}></FontAwesomeIcon>
        </div>
        <ReactTooltip/>
      </FadeIn>
      }

    </div>
  )
}

export default withTranslation()(ButtonScrolltop)