/** 
 * USER.ACTIONS
 * Actions about user
 */
import { User, USER_ACTIVATE, USER_ADD, USER_COPY, USER_EDIT, USER_EDIT_ATTRIBUTE, USER_GET, USER_GET_COUNT, USER_GET_COUNT_SEARCH, USER_GET_EXCLUDED, USER_INIT, USER_REMOVE, USER_REMOVE_ATTRIBUTE_MANAGER, USER_ROLE, USER_STATUS } from "@/redux/user.types";
import { Attribute } from "./attribute.types";
import { Population } from "./population.types";
import { STATUS_LOADED, STATUS_LOADING, STATUS_LOAD_ERROR, STATUS_SAVED, STATUS_SAVE_ERROR, STATUS_SAVING } from "./_status.types";


/* REDUX */

export const userActivate = (user: User) => ({
  type : USER_ACTIVATE,
  payload : {
    user
  }
})

export const userAdd = (user: Partial<User>) => ({
  type : USER_ADD,
  payload : {
    user
  }
})

export const userCopy = (user: User) => ({
  type : USER_COPY,
  payload : {
    user
  }
})

export const userEdit = (key: string, value: any) => ({
  type : USER_EDIT,
  payload : {
    key,
    value
  }
})

export const userEditAttribute = (attribute: Attribute) => ({
  type : USER_EDIT_ATTRIBUTE,
  payload : {
    attribute
  }
})

export const userGet = (users: User[]) => ({
  type : USER_GET,
  payload : {
    users
  }
})

export const userGetCount = (count: number) => ({
  type : USER_GET_COUNT,
  payload : {
    count
  }
})

export const userGetCountSearch = (countSearch: number) => ({
  type : USER_GET_COUNT_SEARCH,
  payload : {
    countSearch
  }
})

export const userGetExcluded = (users: User[]) => ({
  type : USER_GET_EXCLUDED,
  payload : {
    users
  }
})

export const userInit = () => ({
  type : USER_INIT
})

export const userRemove = (id: string) => ({
  type : USER_REMOVE,
  payload : {
    id
  }
})

export const userStatus = (status: string) => ({
  type : USER_STATUS,
  payload : {
    status
  }
})

export const userRemoveAttributeManager = (attributeId: string) => ({
  type : USER_REMOVE_ATTRIBUTE_MANAGER,
  payload : {
    attributeId
  }
})


/** API */

//return true if email is available
export const userCheckEmail = (email: string, id: string) => ({
  type : 'API',
  payload : {
    method : "GET",
    url : "/public/user/email-available",
    data : {
      email,
      id
    }
  }
})

export const userDestroy = (id: string) => ({
  type : 'API',
  payload : {
    method : "DELETE",
    url : "/user/" + id
  }
})

export const userDestroy_AsSupervisor = (id: string) => ({
  type : 'API',
  payload : {
    method : "DELETE",
    url : "/supervisor/user/" + id
  }
})

export const userDestroyAll = (except: string) => ({
  type : 'API',
  payload : {
    method : "DELETE",
    url : "/users/" + except
  }
})

export const userDestroyAttributes = (userId: string, attributeId: string) => ({
  type : 'API',
  payload : {
    method : "POST",
    url : "/user/destroy/attribute",
    data : {
      userId,
      attributeId
    }
  }
})

export const userDestroyList = (userIds: string[]) => ({
  type : 'API',
  payload : {
    method : "POST",
    url : "/users/destroy",
    data : {
      userIds
    }
  }
})

export const userFetch = (search: string, limit: number | null, offset: number | null, isAdmin: boolean | null, attributes: string) => ({
  type : 'API',
  payload : {
    method : "GET",
    url : "/users",
    data : {
      search,
      limit,
      offset,
      isAdmin,
      attributes
    }
  }
})

export const userFetchObservers = (filterName:string) => ({
  type : 'API',
  payload : {
    method : "GET",
    url : "/users/observers",
    data : {
      filterName
    }
  }
})

export const userFetchCount = (search: string, isAdmin: boolean | null) => ({
  type : 'API',
  payload : {
    method : "GET",
    url : "/users/count",
    data : {
      search,
      isAdmin
    }
  }
})

export const userFetchCountByFilter = (filter: string) => ({
  type : 'API',
  payload : {
    method : "GET",
    url : "/users/filter/count",
    data : {
      filter
    }
  }
})

export const userFetchOne = (userId: string) => ({
  type : 'API',
  payload : {
    method : "GET",
    url : "/user",
    data : {
      userId
    }
  }
})

export const userFetchList = (userIds: string) => ({
  type : 'API',
  payload : {
    method : "GET",
    url : "/list/users",
    data : {
      userIds
    }
  }
})

export const userFetchByPopulation = (filterName: string, populationName: string, search: string, isDefaultFilter: boolean, defaultFilterId: string, defaultPopulationId: string) => ({
  type : 'API',
  payload : {
    method : "GET",
    url : "/population/users",
    data : {
      filterName,
      populationName,
      search,
      isDefaultFilter,
      defaultFilterId,
      defaultPopulationId
    }
  }
})

export const userFetchNotInvited = (surveyId, search, limit, offset) => ({
  type : 'API',
  payload : {
    method : "GET",
    url : "/users/not-invited",
    data : {
      surveyId,
      search,
      limit,
      offset
    }
  }
})

export const userFetchNotInvitedCount = (surveyId, search) => ({
  type : 'API',
  payload : {
    method : "GET",
    url : "/users/not-invited/count",
    data : {
      surveyId,
      search
    }
  }
})

export const userFetchRecipients = (projectId, search: string, limit: number | null, offset: number | null) => ({
  type : 'API',
  payload : {
    method : "GET",
    url : "/users/recipients",
    data : {
      projectId,
      search,
      limit,
      offset
    }
  }
})

export const userFetch_AsSuperadmin = (accountId: string) => ({
  type : 'API',
  payload : {
    method : "GET",
    url : "/superadmin/users",
    data : {
      accountId
    }
  }
})

export const userFetch_AsSupervisor = (search: string, limit: number, offset: number, isAdmin: boolean | null) => ({
  type : 'API',
  payload : {
    method : "GET",
    url : "/supervisor/users",
    data : {
      search,
      limit,
      offset,
      isAdmin
    }
  }
})

export const userFetchCount_AsSupervisor = (search: string, isAdmin: boolean | null) => ({
  type : 'API',
  payload : {
    method : "GET",
    url : "/supervisor/users/count",
    data : {
      search,
      isAdmin
    }
  }
})

export const userInvite = (userId: string, sender: string, link: string, emailCode: string, populations: string) => ({
  type : 'API',
  payload : {
    method : "POST",
    url : "/user/invite",
    data : {
      userId,
      sender,
      link,
      emailCode,
      populations
    }
  }
})

export const userInsert = (user: User) => ({
  type : 'API',
  payload : {
    method : "POST",
    url : "/user/insert",
    data : {
      user,
    }
  }
})

export const userInsertList = (users: User[]) => ({
  type : 'API',
  payload : {
    method : "POST",
    url : "/users/insert",
    data : {
      users,
    }
  }
})

export const userInsert_AsSuperadmin = (user: User) => ({
  type : 'API',
  payload : {
    method : "POST",
    url : "/superadmin/user/insert",
    data : {
      user
    }
  }
})

export const userUpdate = (user, editArchiveAttributes: boolean) => ({
  type : 'API',
  payload : {
    method : "POST",
    url : "/user/update",
    data : {
      user,
      editArchiveAttributes
    }
  }
})

export const userUpdateObservers = (observers, filterName) => ({
  type : 'API',
  payload : {
    method : "POST",
    url : "/users/observers",
    data : {
      observers,
      filterName
    }
  }
})

export const userUpdate_AsEmployee = (user) => ({
  type : 'API',
  payload : {
    method : "POST",
    url : "/user/employee/update",
    data : {
      user
    }
  }
})

export const userUpdate_AsSuperadmin = (user: User) => ({
  type : 'API',
  payload : {
    method : "POST",
    url : "/superadmin/user/update",
    data : {
      user
    }
  }
})

export const userUpdateList = (users: User[]) => ({
  type : 'API',
  payload : {
    method : "POST",
    url : "/users/update",
    data : {
      users,
    }
  }
})

export const userUpdateLanguage = language => ({
  type : 'API',
  payload : {
    method : "POST",
    url : "/user/language",
    data : {
      language
    }
  }
})

export const userUpdateRole = (userId: string, role: USER_ROLE | null, populationsObserver: Population[], excludedFilters: string[]) => ({
  type : 'API',
  payload : {
    method : "POST",
    url : "/user/role",
    data : {
      userId,
      role,
      populationsObserver,
      excludedFilters
    }
  }
})

export const userUpdateOptions = (userId, options) => ({
  type : 'API',
  payload : {
    method : "POST",
    url : "/user/options",
    data : {
      userId,
      options
    }
  }
})

export const userUpdatePasswordProvided_AsAdmin = (userId:string) => ({
  type : 'API',
  payload : {
    method : "POST",
    url : "/user/password-required",
    data : {
      userId
    }
  }
})

export const userUpdatePasswordProvided = email => ({
  type : 'API',
  payload : {
    method : "POST",
    url : "/public/user/password-required",
    data : {
      email
    }
  }
})

/* REACT-THUNK */

export const userActivateAndEdit: any = (user: User, key: string, value: any) => (dispatch) => {
  dispatch(userActivate(user))
  dispatch(userEdit(key, value))
}

export const userCopyAndUpdateRole: any = (user: User, role: USER_ROLE | null, populations: Population[]) => (dispatch) => {
  dispatch(userActivate(user))
  dispatch(userCopy(user))
  dispatch(userEdit('populationsObserver', populations))
  dispatch(userStatusAndUpdateRole(role, populations, []))
}

export const userEditAndUpdate: any = (user: User, key: string, value: any) => (dispatch) => {
  dispatch(userActivate(user))
  dispatch(userEdit(key, value))
  dispatch(userStatusAndUpdate())
}

export const userEditAndUpdateRole: any = (user: User, role: USER_ROLE | null, populations: Population[], excludedFilters: string[]) => (dispatch) => {
  dispatch(userActivate(user))
  dispatch(userEdit('role', role))
  dispatch(userEdit('populationsObserver', populations))
  dispatch(userEdit('excludedFilters', excludedFilters))
  dispatch(userStatusAndUpdateRole(role, populations, excludedFilters))
}

export const userFetchCountAndGetCount: any = () => async (dispatch, getState) => {
  dispatch(userStatus(STATUS_LOADING))
  const response: any = getState()._session.authLevel > 0 ? await dispatch(userFetchCount("", null)) : { count : 0 }
  dispatch(userGetCount(response.error ? 0 : response.count))
  dispatch(userStatus(response.error ? STATUS_LOAD_ERROR : STATUS_LOADED))
}

export const userRemoveAndUpdateRole: any = (user: User) => (dispatch) => {
  dispatch(userActivate(user))
  dispatch(userRemove(user.id))
  dispatch(userStatusAndUpdateRole(0, [], []))
}

export const userStatusAndUpdate: any = () => async (dispatch, getState) => {
  dispatch(userStatus(STATUS_SAVING))
  const response = await dispatch(userUpdate(getState().user.active, false))
  dispatch(userStatus(response.error ? STATUS_SAVE_ERROR : STATUS_SAVED))
}

export const userStatusAndUpdateRole: any = (role: USER_ROLE | null, populations: Population[], excludedFilters: string[]) => async (dispatch, getState) => {
  dispatch(userStatus(STATUS_SAVING))
  const response = await dispatch(userUpdateRole(getState().user.active.id, role, populations, excludedFilters))
  dispatch(userStatus(response.error ? STATUS_SAVE_ERROR : STATUS_SAVED))
}