import { faCircleNotch, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from '@/components/button'
import Scroller from '@/components/scroller'
import Space from '@/components/space'
import { useState } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { FadeIn, FadeOut } from '@/utils/animations.utils'

interface OwnProps{
  fontSize?: number
  height?: number
  isLoading?: boolean
  onClose: Function
  onAccept?: Function
  onReject?: Function
  onHide?: Function
  children?: any
  title?: string
}

type Props = OwnProps & WithTranslation

const OWL_NAME: string = " "

function WizardOwlWidget(props: Props){
  const { t } = props

  const [isFadingOut, setIsFadingOut] = useState<boolean>(false)

  function accept(){
    setIsFadingOut(true)

    if(props.onAccept){
      props.onAccept()
    }

    setTimeout(()=>{
      props.onClose()
    }, 2000)
  }

  function reject(){
    setIsFadingOut(true)

    if(props.onReject){
      props.onReject()
    }

    setTimeout(()=>{
      props.onClose()
    }, 2000)
  }

  function hide(){
    setIsFadingOut(true)

    if(props.onHide){
      props.onHide()
    }

    setTimeout(()=>{
      props.onClose()
    }, 2000)
  }

  function render(){
    return (
      <div>

        <div className="flex">

          <div style={{marginLeft: 20, fontWeight: 700}}>
            {props.title ? props.title : OWL_NAME}
          </div>

          <Space/>

          { props.isLoading &&
          <FontAwesomeIcon icon={faCircleNotch} spin/>
          }

          <div className="width-10" />

          <FontAwesomeIcon icon={faTimes} className="_hover" onClick={() => props.onClose()} />
        </div>

        <div className="height-10" />

        <div className="rel flex" style={{ width: 350, marginTop: 5, paddingLeft : 16 }}>

          <img src={require("@/assets/assistant/owl_female.png")}
            alt={"owl assistant"}
            width={60}
            style={{ objectFit: "contain" }}
          />

          <div className="width-10"/>

          <div>

            <Scroller maxHeight={props.height ? props.height : 120}>
              <div
                className="owl-wizard-speech-ballon"
                style={{ fontSize : props.fontSize ? props.fontSize : undefined }}
              >
                {props.children}

              </div>
            </Scroller>

            {( props.onReject && !props.isLoading) &&
            <div
              className="_hover flex"
              style={{fontSize:12}}
              onClick={reject}
            >
              <Space/>
              <u>{t("utils_not_relevant")}</u>
            </div>
            }

            { props.onHide &&
            <div
              className="_hover flex"
              style={{fontSize:12}}
              onClick={hide}
            >
              <Space/>
              <u>{t("owl_wizard_hide")}</u>
            </div>
            }

            <div className="height-10" />

            { props.onAccept &&
            <div className="flex" style={{marginBottom:-6}}>
              <Space/>
              
              <Button
                isFullWidth
                className="primary"
                onClick={accept}
                isLoading={props.isLoading}
              >
                {t("utils_apply")}
              </Button>
            </div>
            }

          </div>

        </div>
        
      </div>
    )
  }

  return (
    isFadingOut ?
    <FadeOut className="abs owl-wizard">
      {render()}
    </FadeOut>
    :
    <FadeIn className="abs owl-wizard">
      {render()}
    </FadeIn>
  )
}


export default withTranslation()(WizardOwlWidget)