import { Sending, SendingState, SENDING_ADD, SENDING_GET, SENDING_INIT, SENDING_REMOVE, SENDING_REPLACE } from "./sending.types";
import { orderBy } from "lodash";

let active:Sending
let list:Sending[]

const initialState: SendingState = {
  list : []
}

export default function sendingReducer(
  state = initialState,
  action: any
): SendingState {
  switch (action.type) {

    //Get active invitation model
    case SENDING_ADD:
      active = new Sending(action.payload.sending)
      return Object.assign({}, state, {
        active,
        list : orderBy([...state.list, active ], ["date"], ["asc"])
      })

    //Save sending in two list (if sending is done or not)
    case SENDING_GET:
      list = action.payload.sendings.map((x) => new Sending(x))
      return {
        ...state,
        list,
      }
      
    //Init store
    case SENDING_INIT:
      return Object.assign({}, state, {
        list : state.list.map((x:any) => new Sending(x))
      })

    //Replace existing item
    case SENDING_REPLACE:
      active = new Sending(action.payload.sending)
      list = orderBy(state.list.map(x => x.id === active.id ? active : x), ["date"], ["asc"])
      return Object.assign({}, state, {
        list
      })

    //Remove invitation model
    case SENDING_REMOVE:
      return {
        ...state,
        list  : state.list.filter(i => { return i.id !== action.payload.id })
      }

    default:
      return state

  }
  
}