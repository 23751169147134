/**
 * FORMAT-DATE.UTILS
 * Replace occurence for email
 */

import { store } from "@/index"
import { bookmarkActivate } from "@/redux/bookmark.actions"
import { Bookmark } from "@/redux/bookmark.types"
import { filterReplaceDashboard, filterReplaceDashboardCompare } from "@/redux/filter.actions"
import { DashboardFilters } from "@/redux/filter.types"

const selectBookmark = (bookmark:Bookmark) => {
  const destination = bookmark.options.page + (bookmark.options.urlParams.length ? "?" + bookmark.options.urlParams : "")
  store.dispatch(filterReplaceDashboard(bookmark.options.dashboardFilters))
  store.dispatch(filterReplaceDashboardCompare(bookmark.options.dashboardFiltersCompare ? bookmark.options.dashboardFiltersCompare : new DashboardFilters()))
  store.dispatch(bookmarkActivate(bookmark))
  return destination
}

export default selectBookmark