/** 
 * QUESTION-REVERSE.HELP
 * Info about reverse (if question is positive or negative)
 */
import HelpModal from "@/components/modal-help"
import { withTranslation, WithTranslation } from "react-i18next"

interface OwnProps extends WithTranslation{
  onClose : Function
}

function QuestionReverseHelp(props:OwnProps) {
  const { t } = props

  return (
    <HelpModal onClose={props.onClose}
      title={t("question_reverse")}>
      <p>{t("question_reverse_help")}</p>
    </HelpModal>
  )
}

export default withTranslation()(QuestionReverseHelp)