/**
 * SURVEY-PROJECTS.WIDGET
 * Get list of projects
 */
import { connect } from "react-redux"
import { withTranslation, WithTranslation } from "react-i18next"
import Space from "@/components/space"
import { useEffect, useState } from "react"
import { store } from "@/index"
import PageLoader from "@/components/page-loader"
import Card from "@/components/card"
import { STATUS_LOADED, STATUS_LOADING } from "@/redux/_status.types"
import PageHeader from "@/components/page-header"
import Button from "@/components/button"
import { Project, ProjectState } from "@/redux/project.types"
import { projectActivate, projectFetchAll, projectGet } from "@/redux/project.actions"
import PagePlaceholder from "@/components/page-placeholder"
import Chip from "@/components/chip"
import { Session } from "@/redux/_session.types"
import LoadingModal from "@/modals/loading.modal"
import ProjectAddModal from "@/modals/project-add.modal"
import { NavigateFunction, useNavigate } from "react-router-dom"

interface StateProps extends WithTranslation {
  _session: Session
  project: ProjectState
}

//Modals
const MODAL_ADD = "MODAL_ADD"
const MODAL_LOADING = "MODAL_LOADING"

function ProjectsListWidget(props: StateProps) {
  const { t } = props
  const navigate: NavigateFunction = useNavigate()

  //Current modal to display
  const [currentModal, setCurrentModal] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(true)

  //Load projects at start
  useEffect(() => {
    load()

    async function load() {
      store.dispatch(projectGet([]))
      const projects: any = await store.dispatch(projectFetchAll())
      store.dispatch(projectGet(projects.error ? [] : projects))
      setIsLoading(false)
    }
  }, [])

  //Click on the project
  function clickProject(project: Project) {
    store.dispatch(projectActivate(project))
    navigate("/project/edit")
  }

  return (
    <div>
      {currentModal === MODAL_ADD && <ProjectAddModal isWithoutTemplate onClose={() => setCurrentModal(null)} />}

      {currentModal === MODAL_LOADING && <LoadingModal />}

      <PageHeader title={t("projects")} subtitle={t("projects_help")}>
        {props.project.list.length > 0 && (
          <Button className="primary" onClick={() => setCurrentModal(MODAL_ADD)}>
            {t("project_add")}
          </Button>
        )}
      </PageHeader>

      <PageLoader status={isLoading ? STATUS_LOADING : STATUS_LOADED}>
        {props.project.list.length === 0 ? (
          <PagePlaceholder
            title={t("project_empty")}
            onClick={() => setCurrentModal(MODAL_ADD)}
            image="start"
            button={t("project_add")}
          />
        ) : (
          props.project.list.map((project) => (
            <Card className="_hover flex1" key={project.id} isWithoutPadding onClick={() => clickProject(project)}>
              <div className="flex">
                <div
                  style={{
                    margin: 22,
                    height: 68,
                    width: 68
                  }}>
                  <img src={project.picture} width="100%" height="100%" alt={project.id} />
                </div>

                <div className="flex flex-dcol">
                  <Space />
                  <div style={{ fontSize: "18px" }}>{project.name}</div>
                  <div className="height-10" />
                  <div className="grey-t">{t("utils_last_update", { date: project.updatedAtLabel })}</div>
                  <Space />
                </div>

                <Space />

                <div className="flex-auto flex">
                  <Space />
                  <Chip color={project.statusColor}>{project.statusLabel}</Chip>
                </div>

                <div className="width-40" />
              </div>
            </Card>
          ))
        )}

        <div className="height-40" />
      </PageLoader>
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  _session: state._session,
  project: state.project
})

export default connect(mapStateToProps)(withTranslation()(ProjectsListWidget))
