/** 
 * LEARNING-EDITOR
 * Edit params for a learning
 * manage learning's lessons 
 */

import { useEffect, useState } from "react";
import { withTranslation, WithTranslation } from 'react-i18next';
import { NavigateFunction, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { Session } from "@/redux/_session.types";
import NavFocus from "@/nav/nav.focus";
import { store } from "@/index";
import { faArrowRight, faClipboardList, faEye, faMinus, faPen, faSave, faSearch, faTrash, faUndo } from "@fortawesome/free-solid-svg-icons";
import Space from "@/components/space";
import { Lesson, LessonState, LessonTypes } from "@/redux/lesson.types";
import { LearningState } from "@/redux/learning.types";
import PageHeader from "@/components/page-header";
import Card from "@/components/card";
import TextInput from "@/components/text-input";
import Tabs from "@/components/tabs";
import { learningActivate, learningEdit, learningFetchLessons, learningFetchLessons_AsSupervisor, learningStatus, learningUpdate, learningUpdate_AsSupervisor } from "@/redux/learning.actions";
import { STATUS_LOADED, STATUS_LOADING, STATUS_SAVED, STATUS_SAVE_ERROR, STATUS_SAVING } from "@/redux/_status.types";
import LanguageSelection from "@/components/language-selection";
import { lessonActivate, lessonFetch_AsSupervisor, lessonGet } from "@/redux/lesson.actions";
import ListItem from "@/components/list-item";
import ListIcon from "@/components/list-icon";
import ListCell from "@/components/list-cell";
import ListContainer from "@/components/list-container";
import ListFields from "@/components/list-fields";
import { findIndex } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { toast } from "react-toastify";
import EditLessonModal from "@/modals/edit-lesson.modal";
import LessonItem from "@/components/lesson-item";
import ImagePicker from "@/components/image-picker";
import LessonTypesModal from "@/modals/lesson-types.modal";
import SearchBar from "@/components/search-bar";
import Button from "@/components/button";
import Checkbox from "@/components/checkbox";
import DeleteLearningModal from "@/modals/delete-learning.modal";
import env from "@/env";
import { Language } from "@/redux/language.types";
import HtmlEditor from "@/components/html-editor";

interface StateProps extends WithTranslation{
  _session : Session
  learning : LearningState
  lesson : LessonState
}

//List of available actions for menu-top
const ACTION_SAVE : string = 'ACTION_SAVE'
const ACTION_CANCEL : string = 'ACTION_CANCEL'
const ACTION_DELETE : string = 'ACTION_DELETE'
const ACTION_SEARCH : string = 'ACTION_SEARCH'
const ACTION_PREVIEW : string = 'ACTION_PREVIEW'

//Dialog
const MODAL_EDIT_LESSON : string = "MODAL_EDIT_LESSON"
const MODAL_SELECT_LESSON_TYPE : string = "MODAL_SELECT_LESSON_TYPE"
const MODAL_DELETE_LEARNING_CONFIRM : string = "MODAL_DELETE_LEARNING_CONFIRM"


//Tabs
const TAB_PARAMETERS : string = 'TAB_PARAMETERS'
const TAB_LESSONS : string = 'TAB_LESSONS'
const TAB_ATTRIBUTES : string = 'TAB_ATTRIBUTES'
const TAB_SHARE : string = 'TAB_SHARE'


function LearningEditor(props:StateProps) {
  const { t } = props
  const navigate: NavigateFunction = useNavigate()

  const label : any = props.learning.active.label

  const [currentLanguage, setCurrentLanguage] = useState<string>(props._session.language)
  const [currentModal, setCurrentModal] = useState<string | null>(null)
  const [currentTab, setCurrentTab] = useState<string>(TAB_PARAMETERS)
  const [edited, setEdited] = useState<boolean>(false)
  const [selectedLessons, setSelectedLessons] = useState<Lesson[]>([])
  const [status, setStatus] = useState<string>(STATUS_LOADING)
  const [currentSearch, setCurrentSearch] = useState<string>("")

  /*Drag and drop*/
  const [sourceIndex, setSourceIndex] = useState<number>(-1)
  const [destinationIndex, setDestinationIndex] = useState<number>(-1)

  useEffect(()=>{
    if(props.learning.active.mediasOrder.length>0){
      getSelectedLessons(false)
    }

    //if the active learning does not support current language
    if(props.learning.active.languages.indexOf(currentLanguage)<0){
      setCurrentLanguage(props.learning.active.languages[0])
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  useEffect(()=>{
    loadLessons()
    //eslint-disable-next-line react-hooks/exhaustive-deps
  },[currentSearch])

  //Replacement for <Prompt/>
  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      if (edited) {
        
        //'You have unsaved changes. Are you sure you want to leave?';
        const message: string = t("utils_ask_save_edits")
        event.returnValue = message; // Standard for most browsers
        return message; // For some older browsers
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edited]);

  function addLanguage(language : string){
    store.dispatch(learningEdit("languages", [...props.learning.active.languages, language]))
    setCurrentLanguage(language)
  }

  function addLesson(lesson:Lesson, index:number){
    setStatus(STATUS_LOADING)
    const lessons : Lesson[] = selectedLessons.filter((x:Lesson)=>x.id !== lesson.id)
    if(index<lessons.length){
      setSelectedLessons([
        ...lessons.slice(0, index),
        lesson,
        ...lessons.slice(index)
      ])
    }
    else{
      setSelectedLessons([...lessons, lesson])
    }

    if(!edited){
      setEdited(true)
    }

    setStatus(STATUS_LOADED)
  }

  function changeLanguage(language : string){
    setCurrentLanguage(language)
  }

  function cancelChanges(){
    setEdited(false)
    if(props.learning.active.mediasOrder.length>0){
      getSelectedLessons(true)
    }
  }

  //Click on one the button at the top
  function clickAction(action : string){
    switch(action){
      case ACTION_SAVE:
        return save()
      case ACTION_CANCEL:
        return cancelChanges()
      case ACTION_DELETE:
        return setCurrentModal(MODAL_DELETE_LEARNING_CONFIRM)
      case ACTION_SEARCH:
        alert("SEARCH")
      break
      case ACTION_PREVIEW:
        return redirect()
      default:
        return alert(action + " : à venir")
    }

  }

  //Create lesson
  function createLesson(type : LessonTypes){
    store.dispatch(lessonActivate(
      new Lesson({
        name : {[props._session.language] : t("lesson_new")},
        type,
        languages : [props._session.language],
        new : true
      })
    ))
    setCurrentModal(MODAL_EDIT_LESSON)
  }

  function editLearning(key:string, value:any){
    store.dispatch(learningStatus(STATUS_SAVING))
    if(!edited){
      setEdited(true)
    }

    //check if object multi lang
    if(key === "name" || key === "description"){
      const item : any = props.learning.active[key]
      item[currentLanguage] = value 
      store.dispatch(learningEdit(key, item))
    }
    else{
      store.dispatch(learningEdit(key, value))
    }
    store.dispatch(learningStatus(STATUS_SAVED))
  }

  function editLesson(lesson:Lesson){
    store.dispatch(lessonActivate(lesson))
    setCurrentModal(MODAL_EDIT_LESSON)
  }

  function getFlagEmoji(countryCode : string) {
    if(countryCode!){
      return countryCode.replace(/./g,(char)=>String.fromCodePoint(0x1f1a5+char.toUpperCase().charCodeAt(0)))
    }
    else{
      return ""
    }
  }

  function getItemStyle(isDragging, draggableStyle){
    return {
      background: isDragging ? props._session.accountColors.active+"1a" : "",   //#ffa01a1a
      ...draggableStyle
    }
  }

  function getLessonFromId(LessonId:string){
    const lesson : Lesson = selectedLessons.find((x:Lesson)=>x.id === LessonId)!
    return lesson ? lesson : null
  }

  function getOrder(index:number){

    if(index===sourceIndex){
      return <div style={{color:props._session.accountColors.active}}><FontAwesomeIcon icon={faArrowRight}/>{" #" + (destinationIndex+1)}</div>
    }
    else if(index>sourceIndex && index<=destinationIndex){
      return <div style={{color:props._session.accountColors.active}}><FontAwesomeIcon icon={faArrowRight}/>{" #"+(index)}</div>
    }
    else if(index<sourceIndex && index>=destinationIndex){
      return <div style={{color:props._session.accountColors.active}}><FontAwesomeIcon icon={faArrowRight}/>{" #"+(index+2)}</div>
    }
    else{
      return <div/>
    }

  }

  async function getSelectedLessons(cancel:boolean){
    setSelectedLessons([])
    let response:any

    if(props._session.interfaceType === "SUPERVISOR"){
      response = await store.dispatch(learningFetchLessons_AsSupervisor(props.learning.active.id))
    }
    else{
      response = await store.dispatch(learningFetchLessons(props.learning.active.id))
    }

    if(response.id === props.learning.active.id){
      if(response!.Lessons.length>0){
        setSelectedLessons(response.Lessons.map((x:Lesson)=>new Lesson(x)))
      }
      if(cancel){
        store.dispatch(learningActivate(response))
      }
    }
    else{
      if(response.error){
        toast(t(response.error), { type : 'error' })
      }else{
        toast(t("no_response"), { type : 'error' })
      }
    }
  }

  function isNameEntered(language){
    return props.learning.active.name[language]
  }

  async function loadLessons(){
    const response : any = await store.dispatch(lessonFetch_AsSupervisor(currentSearch,50,0,null))
    if(response.error){
      toast(t(response.error), { type : 'error' })
      store.dispatch(lessonGet([]))
    }
    else{
      store.dispatch(lessonGet(response))
    }
  }

  function onBeforeDragStart(result : any){
    setSourceIndex(result.source.index)
    setDestinationIndex(result.source.index)
  }

  function onDragEnd(result : any){
    const lesson : Lesson = getLessonFromId(result.draggableId)!
    if(lesson){
      addLesson(lesson, destinationIndex)
    }
    setSourceIndex(-1)
    setDestinationIndex(-1)
  }

  function onDragUpdate(result : any){
    if(result.destination){
      setDestinationIndex(result.destination.index)
      if(!edited){
        setEdited(true)
      }
    }
  }

  function redirect(){
    const link : string = env.REACT_APP_URL_LEARNING + "/home?learningId=" + props.learning.active.id + (props._session.accountId ? "&accountId=" + props._session.accountId : "")
    window.open(link, "_blank")
  }

  function removeLanguage(language : string){
    if(currentLanguage === language){
      setCurrentLanguage(props.learning.active.languages[0])
    }
    store.dispatch(learningEdit("languages", props.learning.active.languages.filter((lang:string)=>lang !== language)))
  }

  function removeLesson(lessonId:string){
    setSelectedLessons(selectedLessons.filter((x:Lesson)=>x.id !== lessonId))

    if(!edited){
      setEdited(true)
    }
  }

  async function save(){
    store.dispatch(learningStatus(STATUS_SAVING))
    store.dispatch(learningEdit("mediasOrder", selectedLessons.map((x:Lesson)=>x.id)))
    let response:any 

    if(props._session.interfaceType === "SUPERVISOR"){
      response = await store.dispatch(learningUpdate_AsSupervisor(props.learning.active))
    }
    else{
      response = await store.dispatch(learningUpdate(props.learning.active))
    }

    if (response === props.learning.active.id){
      editLearning("new", false)
      store.dispatch(learningStatus(STATUS_SAVED))
      toast(t("utils_saved"))
      setEdited(false)
    }
    else{
      if(response.error){
        store.dispatch(learningStatus(STATUS_SAVE_ERROR))
        toast(t(response.error), { type : 'error' })
      }
      else{
        store.dispatch(learningStatus(STATUS_SAVE_ERROR))
        toast(t("learning_save_error"), { type : 'error' })
      }
    }


  }

  function toggleLesson(lesson:Lesson){
    if(findIndex(selectedLessons, ['id', lesson.id])>-1){
      removeLesson(lesson.id)
    }
    else{
      addLesson(lesson, selectedLessons.length)
    }
  }


  return (
    <NavFocus isCustomText
      onBack={() => navigate(props._session.interfaceType === "SUPERVISOR" ? "/supervisor/lms" : "/learning/home")}
      status={props.learning.status}
      onAction={clickAction}
      actions={
        /** show alternative menu if template supervisor in client interface */
        (props.learning.active.fromSupervisor && props._session.interfaceType !== "SUPERVISOR")
        ?
        []
        :
        [
          { id : ACTION_SAVE, label : t("utils_save"), icon : faSave, className : (props.learning.active.new? "secondary" : (edited ? "secondary" : "light")) },
          { id : ACTION_CANCEL, label : t("utils_cancel"), icon : faUndo, className : ((!props.learning.active.new && edited) ? "" : "light") },
          { id : ACTION_SEARCH, label : t("utils_search"), icon : faSearch },
          { id : ACTION_DELETE, label : t("utils_delete"), icon : faTrash },
          { id : ACTION_PREVIEW, label : t("learning_preview"), icon : faEye, isLarge : true, className : "secondary" }
        ]
      }>

      { currentModal === MODAL_SELECT_LESSON_TYPE &&
      <LessonTypesModal
        onClose={() => setCurrentModal(null)}
        onSelect={(item) => createLesson(item)}  
      />
      }

      { currentModal === MODAL_EDIT_LESSON &&
      <EditLessonModal
        onClose={() => setCurrentModal(null)}
        onSave={() => props.lesson.active.visible?addLesson(props.lesson.active, selectedLessons.length):null}
      />
      }

      {currentModal === MODAL_DELETE_LEARNING_CONFIRM &&
        <DeleteLearningModal
          onClose={() => setCurrentModal(null)}
          onConfirm={() => navigate(props._session.interfaceType === "SUPERVISOR" ? "/supervisor/lms" : "/learning/home")}
          learning={props.learning.active}
          fromSupervisor
        />
      }

      <PageHeader title={label}
        subtitle={t("learning_subtitle")}>
        <Button
          className="primary"
          onClick={() => setCurrentModal(MODAL_SELECT_LESSON_TYPE)}>{t("lesson_add")}
        </Button>
      </PageHeader>
      
      <Tabs active={currentTab}
        tabs={
          props._session.accountId?{
            TAB_PARAMETERS : t("parameters"),
            TAB_LESSONS : t("lessons"),
            TAB_ATTRIBUTES : t("attributes"),
            TAB_SHARE : t("share")
          }
        :
          {
            TAB_PARAMETERS : t("parameters"),
            TAB_LESSONS : t("lessons")
          }
        }
        onClick={(tab) => setCurrentTab(tab)}
      />

      {currentTab === TAB_PARAMETERS &&
      <Card>

        <div className="flex">
          <div className="flex1">

            <TextInput
              autoFocus
              required
              error={!props.learning.active.name[currentLanguage]}
              isFullWidth
              onChange={(e) => editLearning( "name", e.value )}
              title={t("learning_name") + " " + getFlagEmoji(currentLanguage)}
              value={props.learning.active.getValue("name", currentLanguage)}
            />

            <div className="text-input-title">
              <span className="grey-t">
                {t("utils_languages")}
              </span>
              <Space/>
            </div>

            <LanguageSelection
              languages={props.learning.active.languages}
              onAdd={(language: Language)=>addLanguage(language.code)}
              onDelete={(language: Language)=>removeLanguage(language.code)}
              onClick={(language: Language)=>changeLanguage(language.code)}
              activeLang={currentLanguage}
              checkError={(languageCode: string) => !isNameEntered(languageCode)}
            />

            <div className="text-input-title">
              <span className="grey-t">
                {t("utils_status")}
              </span>
              <Space/>
            </div>

            <Checkbox
              active={props.learning.active.visible}
              text={t("utils_visible")}
              onClick={() => editLearning("visible", !props.learning.active.visible)}
            />

          </div>

          <div className="width-40"/>

          <div className = "flex1">

            <ImagePicker
              id={props.learning.active.id}
              imageId={props.learning.active.image}
              model={"Learnings"}
              onDelete={()=>editLearning("image", null)}
              onSave={()=>editLearning("image", props.learning.active.id)}
              square
            />

          </div>
        </div>

        <div style={{width:"732px"}} />

        <div className="text-input-title">
          <span className="grey-t">
            {t("utils_description") + " " + getFlagEmoji(currentLanguage)}
          </span>
          <Space/>
        </div>

        <div style={{border: "1px solid black", minHeight: 200}}>
          <HtmlEditor
            content={props.learning.active.getValue("description", currentLanguage)}
            onChange={(e: any) => editLearning("description", e)}
          />
        </div>

      </Card>
      }

      {currentTab === TAB_LESSONS &&

      <div className="flex">
        <Card className="flex1 flex flex-wrap">

          <p>
            <b>{t("lesson_select")}</b>
          </p>

          <SearchBar
            status={status}
            onSearch={setCurrentSearch}
          />

          { props.lesson.list.length>0?
            props.lesson.list.map((x:Lesson)=>
            <LessonItem
              key={x.id}
              lesson={x}
              index={findIndex(selectedLessons, ['id', x.id])}
              onClick={()=>toggleLesson(x)}
              onEdit={(x:Lesson)=>editLesson(x)}
            />
            )
          :
          <div className="flex3">{t("no_lesson")}</div>
        }
        </Card>

        <div className="width-20"/>

        <Card className="flex1">

          <p>
            <b>{t("learning_lessons")}</b>
          </p>

          <div style={{ 
              margin : '0px -30px',
              marginTop : '-30px'
            }}>
            <ListContainer
              countLimit={6}
              countSearch={selectedLessons.length}
              isFooterActive
              status={status}>

              <ListFields>
                <ListCell width={40} text={t("utils_order")}/>
                <ListCell/>
                <ListCell width={200} text={t("user_lastname")}/>
                <Space/>
                <ListCell/>
              </ListFields>

              <DragDropContext
                onBeforeDragStart={onBeforeDragStart}
                onDragEnd={onDragEnd}
                onDragUpdate={onDragUpdate}>
                <Droppable droppableId="droppable">
                  {(provided) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {selectedLessons.length>0?
                      selectedLessons.map((lesson:Lesson, i:number)=>
                      <Draggable
                        key={lesson.id}
                        draggableId={lesson.id}
                        index={i}
                      >
                        {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                  
                          <ListItem>

                            <ListCell width={40}>
                              {"#"+(i+1)}{getOrder(i)}
                            </ListCell>
                            
                            <ListIcon fallbackIcon={faClipboardList}
                              image={lesson.picture}
                            />

                            <ListCell width={200}
                              text={lesson.label}
                            />

                            <Space/>

                            <ListIcon fallbackIcon={faPen} onClick={()=>editLesson(lesson)}/>
                            <ListIcon fallbackIcon={faMinus} onClick={()=>removeLesson(lesson.id)}/>

                          </ListItem>
                        </div>
                        )}
                      </Draggable>
                      )
                      :
                      <ListItem>
                        <div className="flex1"/>
                        <i>{t("no_lessons")}</i>
                        <div className="flex1"/>
                      </ListItem>
                    }
                    {provided.placeholder}
                  </div>
                  )}
                </Droppable>
              </DragDropContext>
            </ListContainer>
          </div>

        </Card>
      </div>
      }

      {currentTab === TAB_ATTRIBUTES &&
      <Card>
      </Card>
      }

      {currentTab === TAB_SHARE &&
      <Card>
      </Card>
      }

    </NavFocus>
  )

}

const mapStateToProps = state => ({
  _session : state._session,
  learning : state.learning,
  lesson : state.lesson
})

export default connect(mapStateToProps)(withTranslation()(LearningEditor))