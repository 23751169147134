/** 
 * BOOKMARK.REDUCERS
 * Edit store for bookmarks
 */

import { Bookmark, BookmarkState, BOOKMARK_ACTIVATE, BOOKMARK_ADD, BOOKMARK_GET, BOOKMARK_INIT, BOOKMARK_REMOVE, BOOKMARK_SELECT, BOOKMARK_STATUS, BOOKMARK_SWAP } from "./bookmark.types";
import { STATUS_LOADED } from "./_status.types";

let active:Bookmark
let currentIndex:number
let list:Bookmark[]

const initialState: BookmarkState = {
  active : new Bookmark(),
  list : [],
  status : STATUS_LOADED
}

export default function bookmarkReducer(
  state = initialState,
  action: any
): BookmarkState {
  switch (action.type) {

    //Get active filter
    case BOOKMARK_ACTIVATE:
      return Object.assign({}, state, {
        active : new Bookmark(action.payload.bookmark)
      })

    //Add new bookmark
    case BOOKMARK_ADD:
      return Object.assign({}, state, { 
        list : state.list.concat([new Bookmark(action.payload.bookmark)])
      })

    //Save list of bookmarks in the store
    case BOOKMARK_GET:
      return Object.assign({}, state, {
        list : action.payload.bookmarks.map((x:any) => new Bookmark(x))
      })

    //Init store
    case BOOKMARK_INIT:
      return Object.assign({}, state, {
        active : new Bookmark(state.active),
        list : state.list.map((x:any) => new Bookmark(x)),
        status : initialState.status
      })

    //Remove pack
    case BOOKMARK_REMOVE:
      return Object.assign({}, state, { 
        list : state.list.filter(x => x.id !== action.payload.id)
      })

    //Select bookmark for export
    case BOOKMARK_SELECT:
      active = new Bookmark(state.list.find(x => x.id === action.payload.bookmarkId))
      active.selected = !active.selected
      return Object.assign({}, state, { 
        list : state.list.map(x => x.id === active.id ? active : x)
      })

    //Update status
    case BOOKMARK_STATUS:
      return {
        ...state,
        status : action.payload.status
      }

    //Change order of a card
    case BOOKMARK_SWAP:
      active = new Bookmark(state.list.find(x => x.id === action.payload.sourceId))
      currentIndex = state.list.findIndex(x => x.id === action.payload.destinationId)
      list = state.list.filter(x => x.id !== active.id)
      list = list.slice(0, currentIndex).concat([active]).concat(list.slice(currentIndex))
      return Object.assign({}, state, { 
        list
      })

    default:
      return state

  }

}