import { faPaperPlane, faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Checkbox from "@/components/checkbox"
import FileThumbnail from "@/components/file-thumbnail"
import Space from "@/components/space"
import TextInput from "@/components/text-input"
import env from "@/env"
import { store } from "@/index"
import { useEffect, useState } from "react"
import { WithTranslation, withTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { remove, upload } from "@/redux/_upload.actions"
import { subscriptionEdit, subscriptionUpdate_AsSupervisor } from "@/redux/subscription.actions"
import { Subscription } from "@/redux/subscription.types"
import UploadButtonWidget from "./upload-button.widget"
import { STATUS_SAVED, STATUS_SAVE_ERROR, STATUS_SAVING } from "@/redux/_status.types"
import ListButton from "@/components/list-button"
import { AccountState } from "@/redux/account.types"
import { connect } from "react-redux"
import { supervisorSendEmail } from "@/redux/supervisor.actions"

interface StateProps extends WithTranslation {
  account: AccountState
}

interface OwnProps {
  subscription: Subscription
}

type Props = OwnProps & StateProps

function SubscriptionEditorWidget(props: Props) {
  const { t } = props

  const [subscription, setSubscription] = useState<Subscription>(props.subscription)
  const [status, setStatus] = useState<string>(STATUS_SAVED)
  const [isOpen, setIsOpen] = useState<boolean>(false)

  useEffect(() => {
    setSubscription(props.subscription)
  }, [props.subscription])

  function activateDateEnd() {
    const dateEnd: Date | null = subscription.dateEnd
      ? null
      : new Date(new Date(subscription.dateStart).getTime() + 365 * 24 * 60 * 60 * 1000)

    handleSubscriptionChanges({ id: "dateEnd", value: dateEnd })

    saveSubscription(new Subscription({ ...subscription, dateEnd: dateEnd }))
  }

  async function deleteBill(subscription: Subscription) {
    //delete file in digitalocean space
    const response: any = await store.dispatch(remove("Bills", subscription.AccountId + "/" + subscription.id, "pdf"))
    if (response.error) {
      toast(t("bill_not_deleted"), { type: "error" })
    } else {
      saveSubscription(new Subscription({ ...subscription, bill: false }))
      handleSubscriptionChanges({ id: "bill", value: false })
      toast(t("bill_deleted"))
    }
  }

  //Change subscription value
  function handleSubscriptionChanges(event: any) {
    store.dispatch(subscriptionEdit(subscription.id, event.id, event.value))
    setSubscription(
      new Subscription({
        ...subscription,
        [event.id]: event.value
      })
    )
  }

  //Save subscription
  async function saveSubscription(subscription: Subscription) {
    setStatus(STATUS_SAVING)
    const response: any = await store.dispatch(subscriptionUpdate_AsSupervisor(subscription))
    setStatus(response.error ? STATUS_SAVE_ERROR : STATUS_SAVED)
  }

  async function uploadBill(event: any, subscription: Subscription) {
    const reader = new FileReader()
    const file = event[0]
    let fileReader: any | null

    if (file) {
      const type: string = file.type

      reader.onloadend = async (event: any) => {
        fileReader = event.target!.result

        const response: any = await store.dispatch(
          upload(subscription.id, fileReader, "Bills/" + subscription.AccountId, type)
        )
        if (response.error) {
          toast(t("file_not_uploaded"), { type: "error" })
        } else {
          saveSubscription(new Subscription({ ...subscription, bill: true }))
          handleSubscriptionChanges({ id: "bill", value: true })
          toast(t("bill_uploaded"))
        }
      }

      reader.readAsDataURL(file)
    }
  }

  async function sendEmailToSales(note: string) {
    const response: any = await store.dispatch(
      supervisorSendEmail(
        props.account.active.SupervisorSales?.email,
        props.account.active.Supervisor?.name,
        props.account.active.name,
        note
      )
    )
    if (!response.error) {
      toast(t("Un email a été envoyé à l'adresse " + props.account.active.SupervisorSales?.email))
    }
  }

  return (
    <div>
      <TextInput
        id="text"
        title={t("utils_details")}
        onBlur={() => saveSubscription(subscription)}
        onChange={(e: any) => handleSubscriptionChanges(e)}
        value={subscription.text}
        isFullWidth
        status={status}
      />

      <div className="flex">
        <TextInput
          id="dateStart"
          title={t("tasks_date_start")}
          onBlur={() => saveSubscription(subscription)}
          onChange={(e: any) => handleSubscriptionChanges(e)}
          value={subscription.dateStartInput}
          status={status}
          type="date"
        />

        <div className="flex flex-dcol">
          <Space />

          <Checkbox active={!subscription.dateEnd} onClick={activateDateEnd} text={t("subscription_never_expires")} />
        </div>

        <TextInput
          id="dateEnd"
          title={t("tasks_date_end")}
          onBlur={() => saveSubscription(subscription)}
          onChange={(e: any) => handleSubscriptionChanges(e)}
          value={subscription.dateEndInput}
          status={status}
          type="date"
          disabled={!subscription.dateEnd}
        />

        <Space />

        <div className="grey-t">{`${t("account_subscription_date")} : ${new Date(
          subscription.date
        ).toLocaleDateString()}`}</div>
      </div>

      <div className="flex">
        <TextInput
          id="price"
          title={t("subscription_price")}
          onBlur={() => saveSubscription(subscription)}
          onChange={(e: any) => handleSubscriptionChanges(e)}
          value={subscription.price}
          status={status}
          type="number"
        />

        <Space />

        <div className="flex flex-dcol">
          <Space />

          <Checkbox
            id="paid"
            active={!subscription.paid}
            text={t("utils_unpaid")}
            status={status}
            onClick={() => {
              saveSubscription(new Subscription({ ...subscription, paid: !subscription.paid }))
              handleSubscriptionChanges({ id: "paid", value: !subscription.paid })
            }}
          />
        </div>
      </div>

      <p className="grey-t">Note avant resign</p>

      <div className="flex">
        <textarea
          className="text-area text-area-size"
          style={{ height: "60px", width: "80%" }}
          onChange={(e) => handleSubscriptionChanges({ id: "note", value: e.target.value })}
          onBlur={() => saveSubscription(subscription)}
          name="note"
          value={subscription.note}></textarea>
        <ListButton
          icon={faPaperPlane}
          text={"Envoyer un email à " + props.account.active.SupervisorSales?.name}
          onClick={() => sendEmailToSales("Note avant resign : " + subscription.note)}
        />
      </div>

      <div className="height-10" />

      {subscription.price <= 0 && !subscription.paid && <div className="red-t">{t("unpaid_price_0")}</div>}

      <div className="height-10" />

      <div>
        {subscription.bill ? (
          <div>
            <div
              className="flex"
              style={{
                width: 67
              }}>
              <div>{t("bill")}</div>

              <Space />

              <div>
                <FontAwesomeIcon icon={faTimes} onClick={() => deleteBill(subscription)} className="_hover" />
              </div>
            </div>

            <FileThumbnail
              onClick={() =>
                window.open(
                  `${env.REACT_APP_URL_SPACE}/Bills/${subscription.AccountId}/${
                    subscription.id
                  }.pdf?${new Date().getTime()}`,
                  "_blank"
                )
              }
              color="red"
              extension="pdf"
            />
          </div>
        ) : (
          <div>
            {isOpen ? (
              <div>
                <u className="_hover grey-t" onClick={() => setIsOpen(false)}>
                  {t("utils_cancel")}
                </u>
                <UploadButtonWidget
                  onUpload={(event: any) => {
                    uploadBill(event, subscription)
                  }}
                  acceptedFiles="application/pdf"
                />
              </div>
            ) : (
              <div>
                <u className="_hover grey-t" onClick={() => setIsOpen(true)}>
                  {t("bill_upload")}
                </u>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  account: state.account
})

export default connect(mapStateToProps)(withTranslation()(SubscriptionEditorWidget))
