/**
 * RESULTS-HOME.ROUTE
 * Dedicated page to get answers for the survey
 */

import NavApp from "@/nav/nav.app";
import HomeLastInvitationWidget from "@/widgets/home-last-invitation.widget";

function ResultsHomeRoute(){
  return(
    <NavApp>
      <HomeLastInvitationWidget/>
    </NavApp>
  )
}


export default ResultsHomeRoute