/**
 * INVITATION.TYPES
 */

export class Invitation{
  aid : number = 0
  address: string = ""
  code : string = ""
  email : string | null = null
  fk_user : string = ""
  language: string = "fr"
  username : string | null = null
  
  constructor(invitation: Partial<Invitation> = {}){
    if (!invitation) invitation = new Invitation()
    Object.assign(this, invitation)
  }
}

export interface InvitationState{
  active : Invitation
  count : number
  countSearch : number
  isQrcodeGenerating : boolean
  list : Invitation[]
  status : string
}

export const INVITATION_ACTIVATE = 'INVITATION_ACTIVATE'
export const INVITATION_EDIT_FROM_USER = 'INVITATION_EDIT_FROM_USER'
export const INVITATION_GET = 'INVITATION_GET'
export const INVITATION_GET_COUNT = 'INVITATION_GET_COUNT'
export const INVITATION_GET_COUNT_SEARCH = 'INVITATION_GET_COUNT_SEARCH'
export const INVITATION_INIT = 'INVITATION_INIT'
export const INVITATION_IS_GENERATING = "INVITATION_IS_GENERATING"
export const INVITATION_REMOVE = 'INVITATION_REMOVE'
export const INVITATION_STATUS = 'INVITATION_STATUS'