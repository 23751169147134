/**
 * NPS.ACTIONS
 * Route for net promoter score calculation
 */

import { DashboardFilters } from "./filter.types";

export const fetchNps = (
  dashboardFilters: DashboardFilters,
  surveyId?: string,
  surveyStart?: Date
) => ({
  type: "ARCHIVE",
  payload: {
    url: "/nps",
    dashboardFilters,
    surveyId,
    surveyStart
  }
})