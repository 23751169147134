/**
 * SUBSCRIPTION-PROJECT-END.WIDGET
 * Get end of projects for subscriptions > write note for AE
 */

import Card from "@/components/card"
import { useEffect, useState } from "react"
import { store } from ".."
import {
  subscriptionFetchAll_AsSupervisor,
  subscriptionSend_AsSupervisor,
  subscriptionUpdate_AsSupervisor
} from "@/redux/subscription.actions"
import { STATUS_LOADED, STATUS_LOADING, STATUS_SAVING } from "@/redux/_status.types"
import { Subscription, SubscriptionStatus } from "@/redux/subscription.types"
import ListItem from "@/components/list-item"
import ListIcon from "@/components/list-icon"
import env from "@/env"
import {
  faArrowLeft,
  faCheck,
  faHistory,
  faNoteSticky,
  faSortDown,
  faSortUp,
  faTimes,
  faUndo,
  faUserTimes
} from "@fortawesome/free-solid-svg-icons"
import Space from "@/components/space"
import ListButton from "@/components/list-button"
import ModalConfirm from "@/components/modal-confirm"
import Modal from "@/components/modal"
import LoadingModal from "@/modals/loading.modal"
import { toast } from "react-toastify"

const MODAL_ARCHIVE = "MODAL_ARCHIVE"
const MODAL_CONFIRM = "MODAL_CONFIRM"
const MODAL_NOTE = "MODAL_NOTE"
const MODAL_UNDO = "MODAL_UNDO"

interface OwnProps {
  title: string
  isEndSubscription?: boolean
}

function SubscriptionsListWidget(props: OwnProps) {
  const [activeSubscription, setActiveSubscription] = useState<Subscription | null>(null)
  const [currentModal, setCurrentModal] = useState<string | null>(null)
  const [isHistoryDisplayed, setIsHistoryDisplayed] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [isOrderDirectionDesc, setIsOrderDirectionDesc] = useState(props.isEndSubscription ? false : true)
  const [isSaving, setIsSaving] = useState(false)
  const [isSending, setIsSending] = useState(false)
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([])

  useEffect(() => {
    loadData()

    async function loadData() {
      //Init query params
      const statusTagged: SubscriptionStatus[] = ["DONE", "SKIPPED"]
      const statusPing: SubscriptionStatus | SubscriptionStatus[] = props.isEndSubscription
        ? statusTagged
        : isHistoryDisplayed
        ? statusTagged
        : []
      const statusCard = null
      const orderColumn = props.isEndSubscription ? "dateEnd" : "dateStart"
      const orderDirection = isOrderDirectionDesc ? "DESC" : "ASC"

      setIsLoading(true)
      const response: any = await store.dispatch(
        subscriptionFetchAll_AsSupervisor(statusPing, statusCard, orderColumn, orderDirection)
      )
      setSubscriptions(response.error ? [] : response.map((x) => new Subscription(x)))
      setIsLoading(false)
    }
  }, [isHistoryDisplayed, isOrderDirectionDesc])

  function archive() {
    updateStatus("SKIPPED")
  }

  async function confirm() {
    setIsSending(true)
    const response: any = props.isEndSubscription ? await sendCard() : await sendPing()
    setIsSending(false)

    if (!response.error) {
      updateStatus("DONE")
    } else {
      toast("Email envoyé à l'adresse " + activeSubscription?.Account.SupervisorSales?.email, { type: "error" })
    }
  }

  function edit(key: string, value: any) {
    return Object.assign({}, activeSubscription, { [key]: value })
  }

  function editList() {
    if (activeSubscription) {
      setSubscriptions(subscriptions.map((x) => (x.id === activeSubscription.id ? activeSubscription : x)))
    }
  }

  function getNote() {
    return (
      <textarea
        value={activeSubscription?.note}
        onBlur={() => update(activeSubscription)}
        onChange={(e) => setActiveSubscription(edit("note", e.target.value))}
        style={{
          width: 400,
          height: 120,
          resize: "none"
        }}></textarea>
    )
  }

  function openModal(modal: string, subscription: Subscription) {
    setActiveSubscription(subscription)
    setCurrentModal(modal)
  }

  async function sendCard() {
    if (activeSubscription) {
      const response: any = await fetch(env.REACT_APP_URL_INTEGRATION + "/resell", {
        method: "POST",
        body: JSON.stringify({ note: "Choupi" })
      })
      console.log(response)
      /*
      return await store.dispatch(subscriptionSend_AsSupervisor(activeSubscription, "supervisorSubscriptionSendCard"))*/
    } else {
      return { error: "no_subscription" }
    }
  }

  async function sendPing() {
    if (activeSubscription) {
      return await store.dispatch(subscriptionSend_AsSupervisor(activeSubscription, "supervisorSubscriptionSendPing"))
    } else {
      return { error: "no_subscription" }
    }
  }

  function undo() {
    updateStatus(null)
  }

  async function update(subscription: Subscription | null) {
    if (subscription) {
      setIsSaving(true)
      const response: any = await store.dispatch(subscriptionUpdate_AsSupervisor(subscription))
      setIsSaving(false)
      return response
    } else {
      return { error: "no_active_subscription" }
    }
  }

  async function updateStatus(status: SubscriptionStatus) {
    const response: any = await update(edit(props.isEndSubscription ? "statusCard" : "statusPing", status))
    if (!response.error) {
      setCurrentModal(null)
      setSubscriptions(subscriptions.filter((x) => x.id !== activeSubscription?.id))
    }
  }

  return (
    <Card className="flex1" isWithoutPadding status={isLoading ? STATUS_LOADING : STATUS_LOADED}>
      {currentModal === MODAL_ARCHIVE && (
        <ModalConfirm
          onNo={() => setCurrentModal(null)}
          onYes={() => archive()}
          status={isSaving ? STATUS_SAVING : STATUS_LOADING}
          textBold={"Souhaitez-vous ignorer le ping pour le compte " + activeSubscription?.Account.name + " ?"}
        />
      )}

      {currentModal === MODAL_NOTE && (
        <Modal
          title="Ajouter une note"
          isCloseButtonVisible
          onClose={() => {
            setCurrentModal(null)
            editList()
          }}>
          {getNote()}
        </Modal>
      )}

      {currentModal === MODAL_CONFIRM && (
        <Modal
          title="Ajouter une note"
          isCloseButtonVisible
          onClose={() => setCurrentModal(null)}
          onNext={() => confirm()}>
          {activeSubscription?.Account.SupervisorIdSales ? (
            <p>
              Une notification sera envoyée à {activeSubscription?.Account.SupervisorSales?.firstname}{" "}
              {activeSubscription?.Account.SupervisorSales?.lastname}.
            </p>
          ) : (
            <p className="red-t">
              <b>Aucun AE défini pour le client.</b>
            </p>
          )}
          {getNote()}
        </Modal>
      )}

      {currentModal === MODAL_UNDO && (
        <ModalConfirm
          onNo={() => setCurrentModal(null)}
          onYes={() => undo()}
          status={isSaving ? STATUS_SAVING : STATUS_LOADING}
          textBold={
            "Souhaitez-vous remettre l'abonnement pour le compte " +
            activeSubscription?.Account.name +
            " dans la liste des " +
            props.title +
            " ?"
          }
        />
      )}

      {isSending && <LoadingModal />}

      <ListItem>
        <b>{props.title}</b>
        <Space />
        <ListButton
          onClick={() => setIsOrderDirectionDesc(!isOrderDirectionDesc)}
          icon={isOrderDirectionDesc ? faSortDown : faSortUp}
          isInactive
          text={
            isOrderDirectionDesc ? "Afficher du plus ancien au plus récent" : "Afficher du plus récent au plus ancien"
          }></ListButton>
        <ListButton
          onClick={() => setIsHistoryDisplayed(!isHistoryDisplayed)}
          icon={isHistoryDisplayed ? faArrowLeft : faHistory}
          isInactive
          text={isHistoryDisplayed ? "Retour à la vue précédente" : "Afficher les éléments archivés"}></ListButton>
      </ListItem>

      {subscriptions.map((subscription) => (
        <ListItem key={subscription.id} isSmall>
          <div style={{ paddingTop: 8 }}>
            <ListIcon
              image={
                subscription.Account.SupervisorIdSales
                  ? env.REACT_APP_URL_SPACE.concat("/Supervisors/", subscription.Account.SupervisorIdSales, ".png")
                  : null
              }
              fallbackIcon={faUserTimes}
            />
          </div>
          <div>
            <div className="flex">{subscription.Account.name}</div>
            <div className="flex grey-t" style={{ fontSize: 12 }}>
              Signé le {subscription.dateStartLabel}
            </div>
          </div>
          <Space />
          {isHistoryDisplayed ? (
            <div style={{ paddingTop: 8 }}>
              <ListButton
                icon={faUndo}
                isInactive
                text="Remettre dans la liste"
                onClick={() => openModal(MODAL_UNDO, subscription)}
              />
            </div>
          ) : (
            <div style={{ paddingTop: 8 }} className="flex">
              <ListButton
                icon={faNoteSticky}
                isInactive={subscription.note ? false : true}
                text="Ajouter ou modifier la note"
                dot={subscription.note ? true : false}
                onClick={() => openModal(MODAL_NOTE, subscription)}
              />
              <ListButton
                icon={faTimes}
                isInactive
                text="Ignorer le ping"
                onClick={() => openModal(MODAL_ARCHIVE, subscription)}
              />
              <ListButton
                icon={faCheck}
                isPrimary
                text="Confirmer la fin du projet"
                onClick={() => openModal(MODAL_CONFIRM, subscription)}
              />
            </div>
          )}
        </ListItem>
      ))}
    </Card>
  )
}

export default SubscriptionsListWidget
