/** 
 * TOPIC
 * Card of a questionnaire
 */

import { findIndex, sum } from "lodash"
import getLabel from "@/utils/get-label.utils"
import { Annotation } from "./annotation.types"
import { Axis } from "./axis.types"
import { Proposition } from "./proposition.types"
import { Question } from "./question.types"
import { t } from "@/translate/t"

export class Topic{
  aid : string | null = null //Id in archive database
  Annotations : Annotation[] = [] //List of annotations (for editor)
  annotationsDeleted : string[] = [] //Annotation deleted (in order to be saved)
  answersRatio : number = 0 //Ratio of people that have answered
  Axis : Axis | null = null //Axis related to the topic
  AxisId : string | null = null //Id of the axis
  dateOpen : Date = new Date() //Date when topic is open
  fullScreen : boolean = false //Is the card fullScreen for the quickvote
  hide : boolean = false //The topic is not visible because not any results
  id : string = "" //Id in app database
  isAtStart : boolean = false //Is a question at start of the template
  name : any = {} //Name of the topic (in multilanguage => ex : { en : "my topic" })
  noAnswers : number = 0 //People that have no answered
  noAnswersRatio : number = 0 //People that have no answered (in %)
  note : number | null = 0 //Note in the dashboard
  open : boolean = false //Is the topic open in the dashboard
  Propositions :  Proposition[] = [] //List of proposition
  propositionsOverview : Proposition[] = [] //List of most voted propositions
  questionPrimaryId : string = "" //Primary question to trigger in the quickvote
  Questions : Question[] = [] //List of questions for the topic
  questionsDeleted : string[] = [] //List of questions to delete (before save)
  secondaryQuestionsOrder : string[] = [] //Order for secondary questions
  repartition : number[] = [] //Get repartition classes (for results)
  TemplateId : string = "" //Id of the template
  updated : boolean = false //Is the template update in the editor (to know topics to save)
  usersCount : number = 0 //Number of people who have vote for the topic
  withScore : boolean = false //Score from server

  constructor(topic: Partial<Topic> = {}){

    if (!topic) topic = new Topic()

    topic.Axis = topic.Axis ? new Axis(topic.Axis) : null
    topic.Questions = topic.Questions ? topic.Questions.map(x => new Question(x)) : []
    topic.Annotations = topic.Annotations ? topic.Annotations.map(x => new Annotation(x)) : []
    topic.Propositions = topic.Propositions ? topic.Propositions.map(x => new Proposition(x)) : []
    topic.propositionsOverview = topic.propositionsOverview ? topic.propositionsOverview.map(x => new Proposition(x)) : []
    
    Object.assign(this, topic)

  }

  //Couleur de l'axe
  get axisColor():string{
    if (this.Axis){
      return this.Axis.color
    }else{
      return "#c1c1c1"
    }
  }

  //Show pastille
  get countDisplayPastille():boolean{
    return this.Questions.filter(x => 
      x.draft || 
      (x.reverse && x.type === "scale") ||
      (x.special && x.type === "scale") ||
      (x.responseCount === 10)
    ).length > 0
  }

  //Count of draft questions
  get countDraft():number{
    return this.Questions.filter(x => x.draft).length
  }

  //Label for topic
  get countLabel():string{

    const labels: any = []
    if (this.countDraft > 0) labels.push(t("topic_count_draft"))
    if (this.countReverse > 0) labels.push(t("topic_count_reverse"))
    if (this.countRps > 0) labels.push(t("topic_count_rps"))
    if (this.countNps > 0) labels.push(t("topic_count_nps"))

    return labels.join(", ")

  }

  //Count of reverse questions
  get countReverse():number{
    return this.Questions.filter(x => x.reverse && x.type === "scale").length
  }

  //Count of nps questions
  get countNps():number{
    return this.Questions.filter((x: Question) => x.responseCount === 10 && x.type === "scale" && x.withScore ).length
  }

  //Count of rps questions
  get countRps():number{
    return this.Questions.filter(x => x.special && x.type === "scale").length
  }

  //Get label from langague
  get label():string{
    return getLabel(this.name)
  }

  //Complete label (with axis)
  get labelFull():string{
    let label = this.label
    if (this.Axis){
      label += " - " + getLabel(this.Axis.name)
    }
    return label
  }

  //Annotation nouvelles par rapport à aujourd'hui
  //Si vieilles de + de un jour
  get newAnnotations():boolean{
    return this.Annotations.filter(x => x.isNew).length > 0
  }

  get noteLabel():string{
    if (!this.note){
      return "-"
    } else if (this.note >= 100){
      return "10."
    }else{
      return (this.note / 10).toFixed(1)
    }
  }

  //Récupérer la question principale pour la carte
  get questionPrimary():Question | null{
    const question = this.Questions.find(x => x.id === this.questionPrimaryId)
    return question ? question : null
  }

  //Récupérer la liste des questions secondaires
  get questionsSecondary():Question[]{
    return this.Questions.filter(x => x.id !== this.questionPrimaryId)
  }

  //Get secondary question to display at left
  get questionsSecondaryLeft():Question[]{
    return this.questionsSecondary.filter(x => 
      (x.QuestionsTopic.triggerValue === '<' && !this.questionPrimary?.reverse) ||
      (x.QuestionsTopic.triggerValue === '>' && this.questionPrimary?.reverse)
    )
  }

  //Get secondary question to display at right
  get questionsSecondaryRight():Question[]{
    return this.questionsSecondary.filter(x => 
      (x.QuestionsTopic.triggerValue === '>' && !this.questionPrimary?.reverse) ||
      (x.QuestionsTopic.triggerValue === '<' && this.questionPrimary?.reverse)
    )
  }

  get satisfaction():number{
    return this.repartition.length > 0 ? this.repartition[1] / sum(this.repartition) * 100 : -1
  }

  //Get label for navigation bar
  //Include count if topic if present more than x time
  getLabelNav(language, list):string{
    const topicName = this.name[language]
    if (topicName){
      const topicList = list.filter(x => x.name[language] === topicName && x.AxisId === this.AxisId)
      if (topicList.length > 1){
        return topicName + " (" + (findIndex(topicList, { id : this.id }) + 1) + "/" + topicList.length + ")"
      }else{
        return topicName
      }
    }else{
      return t("topic_undefined")
    }
  }

  //Couleur de l'axe
  getAxisLabel(language: string): string{
    if (this.Axis){
      return this.Axis.name[language]
    }else{
      return t("utils_undefined")
    }
  }

  //check for untranslated propositions only if question type is choice
  getEmptyQuestions(language:string): Question[]{
    return this.Questions.filter((q:Question)=> q.type === "choice" ? q.isEmpty(language) || q.getEmptyPropostions(language).length > 0 : q.isEmpty(language))
  }

  getIsEmpty(language:string): boolean{
    return this.name[language] ? false : true
  }

  //Get name of the previous topic on the same axis
  getPrevious(list, language):null | string{
    const index = list.findIndex(x => x.id === this.id)
    if (index > 0){

      const previousTopic = list[index - 1]
      if (previousTopic.Axis?.id === this.Axis?.id){
        return previousTopic.name[language]
      }else{
        return null
      }

    }else{
      return null
    }
    
  }

}

export interface TopicState{
  active : Topic
  list : Topic[]
  isEdited : boolean,
  listDeleted : string[],
  status : string
}

export const TOPIC_ACTIVATE = 'TOPIC_ACTIVATE'
export const TOPIC_ADD = 'TOPIC_ADD'
export const TOPIC_ADD_QUESTION = 'TOPIC_ADD_QUESTION'
export const TOPIC_ADD_PROPOSITION = 'TOPIC_ADD_PROPOSITION'
export const TOPIC_COPY = 'TOPIC_COPY'
export const TOPIC_COPY_QUESTION = 'TOPIC_COPY_QUESTION'
export const TOPIC_EDIT = 'TOPIC_EDIT'
export const TOPIC_EDIT_ANNOTATIONS = 'TOPIC_EDIT_ANNOTATIONS'
export const TOPIC_EDIT_AXES = 'TOPIC_EDIT_AXES'
export const TOPIC_EDIT_DEFAULT_SCALE_COUNT = 'TOPIC_EDIT_DEFAULT_SCALE_COUNT'
export const TOPIC_EDIT_DEFAULT_SCALE_NO_OPINION = 'TOPIC_EDIT_DEFAULT_SCALE_NO_OPINION'
export const TOPIC_EDIT_NAME = 'TOPIC_EDIT_NAME'
export const TOPIC_EDIT_PROPOSITION = 'TOPIC_EDIT_PROPOSITION'
export const TOPIC_EDIT_QUESTION = 'TOPIC_EDIT_QUESTION'
export const TOPIC_EDIT_QUESTION_SUPER = 'TOPIC_EDIT_QUESTION_SUPER'
export const TOPIC_EDIT_TRIGGER= 'TOPIC_EDIT_TRIGGER'
export const TOPIC_GET = 'TOPIC_GET'
export const TOPIC_INIT = 'TOPIC_INIT'
export const TOPIC_REMOVE = 'TOPIC_REMOVE'
export const TOPIC_REMOVE_AXES = 'TOPIC_REMOVE_AXES'
export const TOPIC_REMOVE_PROPOSITION = 'TOPIC_REMOVE_PROPOSITION'
export const TOPIC_REMOVE_QUESTION = 'TOPIC_REMOVE_QUESTION'
export const TOPIC_REPLACE_QUESTION = 'TOPIC_REPLACE_QUESTION'
export const TOPIC_SAVE = 'TOPIC_SAVE'
export const TOPIC_STATUS = 'TOPIC_STATUS'
export const TOPIC_SWAP = 'TOPIC_SWAP'
export const TOPIC_SWAP_PROPOSITION = 'TOPIC_SWAP_PROPOSITION'