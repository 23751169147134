/***
 * INVITATIONS-LIST.WIDGET
 * Manage participants for a survey
 */

import { faCircleNotch, faCopy, faEllipsisV, faPaperPlane, faPencilAlt, faQrcode, faUndo, faUserMinus } from "@fortawesome/free-solid-svg-icons";
import ListButton from "@/components/list-button";
import ListCell from "@/components/list-cell";
import ListContainer from "@/components/list-container";
import ListItem from "@/components/list-item";
import Space from "@/components/space";
import { store } from "@/index";
import { useEffect, useState } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { SurveyState } from "@/redux/survey.types";
import { STATUS_LOADED, STATUS_LOADING, STATUS_LOAD_ERROR, STATUS_SAVED, STATUS_SAVE_ERROR, STATUS_SAVING, STATUS_SEARCHING } from "@/redux/_status.types";
import ModalConfirm from "@/components/modal-confirm";
import { invitationActivate, invitationDestroy, invitationEditFromUser, invitationFetch, invitationFetchCount, invitationGet, invitationGetCount, invitationGetCountSearch, invitationRemove, invitationReset, invitationStatus } from "@/redux/invitation.actions";
import { Invitation, InvitationState } from "@/redux/invitation.types";
import LoadingModal from "@/modals/loading.modal";
import { ProjectState } from "@/redux/project.types";
import ProjectDownloadQrcodesModal from "@/modals/project-download-qrcodes.modal";
import { toast } from "react-toastify";
import env from "@/env";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Chip from "@/components/chip";
import { surveyActivate, surveyFetchOne } from "@/redux/survey.actions";
import PageLoader from "@/components/page-loader";
import AdmEditUserModal from "@/modals/adm-edit-user.modal";
import { User } from "@/redux/user.types";
import { userFetchOne } from "@/redux/user.actions";
import ProjectSendEmailModal from "@/modals/project-send-email.modal";
import ProjectAddInvitationModal from "@/modals/project-add-invitation.modal";
import ListFields from "@/components/list-fields";

interface StateProps extends WithTranslation{
  project: ProjectState
  invitation: InvitationState
  survey: SurveyState
}

interface OwnProps {
  onClickQrcode: Function
}

type Props = StateProps & OwnProps
const LIMIT: number = 5

//List of action for the button at the end of the line for each user
const ACTION_SEND_EMAIL: string = "ACTION_SEND_EMAIL" //Send email to the user
const ACTION_DOWNLOAD_QRCODE: string = "ACTION_DOWNLOAD_QRCODE" //Download individual qrcode
const ACTION_COPY_LINK: string = "ACTION_COPY_LINK" //Copy link for the user
const ACTION_EDIT_USER: string = "ACTION_EDIT_USER" //Edit user properties
const ACTION_RESET: string = "ACTION_RESET" //Cancel all votes made by the user
const ACTION_REMOVE: string = "ACTION_REMOVE" //Remove user for the survey

//List of modals
const MODAL_ADD_INVITATION: string = "MODAL_ADD_INVITATION" //Add new user in the database
const MODAL_DOWNLOAD_QRCODES: string = "MODAL_DOWNLOAD_QRCODES" //Download all qrcode
const MODAL_EDIT: string = "MODAL_EDIT" //Edit user
const MODAL_LOADING: string = "MODAL_LOADING" //Loading modal
const MODAL_REMOVE: string = "MODAL_REMOVE" //Remove invitation
const MODAL_RESET: string = "MODAL_RESET" //Remove invitation but send another one
const MODAL_SEND_EMAIL: string = "MODAL_SEND_EMAIL" //Confirmation before send email

function ProjectInvitationsListWidget(props:Props){
  const { t } = props

  //List of action 
  const [actions, setActions] = useState<any>([])
  
  //If another page than the first one is displayed (ex 5 => show 5 next users)
  const [currentOffset, setCurrentOffset] = useState(0)

  //Text for manual search by a user
  const [currentSearch, setCurrentSearch] = useState("")

  //Current dialog
  const [currentModal, setCurrentModal] = useState<string | null>(null)

  //Current user to edit
  const [currentUser, setCurrentUser] = useState(new User())

  //Set status is loading
  const [isLoading, setIsLoading] = useState(true)

  //Init invitation array at start
  useEffect(() => {
    store.dispatch(invitationGet([]))
    store.dispatch(invitationGetCount(0))
    store.dispatch(invitationGetCountSearch(0))
  }, [])

  //Load invitations
  useEffect(() => {
    let timer
    
    load()

    async function load(){

      //Search data
      store.dispatch(invitationStatus(STATUS_SEARCHING))

      //Fetch data from server
      const invitationResponse:any = await store.dispatch(invitationFetch(props.survey.active.id, currentSearch, LIMIT, currentOffset))
      const invitationCountResponse:any = await store.dispatch(invitationFetchCount(props.survey.active.id, currentSearch))
      const surveyResponse:any = await store.dispatch(surveyFetchOne(props.survey.active.id, false))
      
      //If error display status
      if (
        invitationResponse.error || 
        invitationCountResponse.error ||
        surveyResponse.error
      ){
        store.dispatch(invitationStatus(STATUS_LOAD_ERROR))
      }
      //Handle response
      else{

        //Edit store
        store.dispatch(invitationStatus(STATUS_LOADED))
        store.dispatch(surveyActivate(surveyResponse))
        store.dispatch(invitationGet(invitationResponse))
        store.dispatch(invitationGetCountSearch(invitationCountResponse.count))
        if (!currentSearch.length) store.dispatch(invitationGetCount(invitationCountResponse.count))

        //If invitation in progress relaunch after 3 seconds
        //Detect if isloading (if true)
        if (surveyResponse.isInvitationsInProgress){
          timer = setTimeout(() => {
            load()
          }, 3000)
        }

      }

      setIsLoading(false)

    }

    return () => {
      clearTimeout(timer)
    }

  }, [
    props.survey.active.isInvitationsInProgress,
    props.survey.active.id,
    currentSearch,
    currentOffset
  ])

  //Update list of actions if modes are changed
  useEffect(() => {
    
    const actions:any[] = []
    if (props.project.active.diffusionMode.email) actions.push({ id : ACTION_SEND_EMAIL, icon : faPaperPlane, label : t("invitation_send_email") })
    if (props.project.active.diffusionMode.qrcode) actions.push({ id : ACTION_DOWNLOAD_QRCODE, icon : faQrcode, label : t("invitation_download_qrcode") })
    
    setActions(
      actions.concat([
        { id : ACTION_COPY_LINK, icon : faCopy, label : t("utils_copy_link") },
        { id : ACTION_RESET, icon : faUndo, label : t("invitation_reset") },
        { id : ACTION_EDIT_USER, icon : faPencilAlt, label : t("user_edit") },
        { id : ACTION_REMOVE, icon : faUserMinus, label : t("invitation_remove") }
      ])
    )
  }, [
    t,
    props.project.active.diffusionMode.email,
    props.project.active.diffusionMode.qrcode
  ])

  //Copy specific link
  function copyLink(code:string){
    navigator.clipboard.writeText(env.REACT_APP_URL_QUICKVOTE + "/" + code)
    return toast(t("utils_copy_link_done"))
  }

  //Fonction to click for qrcode generation
  function clickQrcode(invitation:Invitation){
    props.onClickQrcode(invitation)
  }
  
  //Edit user > get user from id
  async function editUser(invitation:Invitation){
    setCurrentModal(MODAL_LOADING)
    const userResponse:any = await store.dispatch(userFetchOne(invitation.fk_user))
    if (!userResponse.error){
      if (!userResponse){
        toast(t("user_is_deleted"))
        setCurrentModal(null)
      }else{
        setCurrentUser(userResponse ? new User(userResponse) : new User())
        setCurrentModal(MODAL_EDIT)
      }
    }else{
      setCurrentModal(null)
    }
  }

  //Confirm remove 
  async function removeConfirm(){
    store.dispatch(invitationStatus(STATUS_SAVING))
    const response:any = await store.dispatch(invitationDestroy(props.invitation.active.aid))
    store.dispatch(invitationStatus(response.error ? STATUS_SAVE_ERROR : STATUS_SAVED))
    if (!response.error){
      toast(t("invitation_remove_done", { name : props.invitation.active.username }))
      store.dispatch(invitationRemove(props.invitation.active.aid))
      setCurrentModal(null)
    }
  }

  //Confirm reset
  async function resetConfirm(){
    store.dispatch(invitationStatus(STATUS_SAVING))
    const response:any = await store.dispatch(invitationReset(props.invitation.active.aid))
    store.dispatch(invitationStatus(response.error ? STATUS_SAVE_ERROR : STATUS_SAVED))
    if (!response.error){
      toast(t("invitation_reset_done", { name : props.invitation.active.username }))
      setCurrentModal(null)
    }
  }

  //Click on the envelope button
  function sendEmail(invitation:Invitation){
    if (invitation.email){
      setCurrentModal(MODAL_SEND_EMAIL)
    }else{
      toast(t("invitation_send_email_error", { name : invitation.username }), { type : "error" })
    }
  }

  //Select action
  async function selectAction(action:string, invitation:Invitation){
    store.dispatch(invitationActivate(invitation))
    switch(action){
      case ACTION_COPY_LINK:
        return copyLink(invitation.code)
      case ACTION_DOWNLOAD_QRCODE:
        return clickQrcode(invitation)
      case ACTION_EDIT_USER:
        return editUser(invitation)
      case ACTION_REMOVE:
        return setCurrentModal(MODAL_REMOVE)
      case ACTION_RESET:
        return setCurrentModal(MODAL_RESET)
      case ACTION_SEND_EMAIL:
        return sendEmail(invitation)
    }
  }
  
  //Update invitation after editing user
  async function updateInvitation(user: User){
    store.dispatch(invitationEditFromUser(user))
    setCurrentModal(null)
  }

  return (
    <div className="flex1 rel">
      
      { currentModal === MODAL_ADD_INVITATION &&
      <ProjectAddInvitationModal onClose={() => setCurrentModal(null)}/>
      }

      { currentModal === MODAL_DOWNLOAD_QRCODES &&
      <ProjectDownloadQrcodesModal onClose={() => setCurrentModal(null)}/>
      }

      { currentModal === MODAL_EDIT &&
      <AdmEditUserModal userToUpdate={currentUser}
        onClose={() => setCurrentModal(null)}
        onNext={(user) => updateInvitation(user)}/>
      }

      { currentModal === MODAL_LOADING &&
      <LoadingModal/>
      }

      { currentModal === MODAL_REMOVE &&
      <ModalConfirm onNo={() => setCurrentModal(null)}
        status={props.invitation.status}
        onYes={removeConfirm}
        text={t("invitation_remove_confirm", { name : props.invitation.active.username })}
        textBold={t("utils_next_ask")}
      />
      }
      
      { currentModal === MODAL_RESET &&
      <ModalConfirm onNo={() => setCurrentModal(null)}
        status={props.invitation.status}
        onYes={resetConfirm}
        text={t("invitation_reset_confirm", { name : props.invitation.active.username })}
        textBold={t("utils_next_ask")}
      />
      }

      { currentModal === MODAL_SEND_EMAIL &&
      <ProjectSendEmailModal onClose={() => setCurrentModal(null)}/>
      }

      <PageLoader status={isLoading ? STATUS_LOADING : STATUS_LOADED}>
        <ListContainer countLimit={LIMIT}
          countSearch={props.invitation.countSearch}
          isFooterActive
          onAddPrimary={!props.project.active.diffusionMode.qrcode}
          onAddTitle={t("project_add_user")}
          onAdd={props.survey.active.status === "launch" ? () => setCurrentModal(MODAL_ADD_INVITATION) : undefined}
          onSearch={setCurrentSearch}
          onSearchStart={() => store.dispatch(invitationStatus(STATUS_SEARCHING))}
          onSetPage={setCurrentOffset}
          status={props.invitation.status}>

          { props.survey.active.isInvitationsInProgress
          ?
          <ListItem>
            <Chip>
              <FontAwesomeIcon icon={faCircleNotch} spin/>
              &nbsp;
              {t("invitations_are_generating")}
            </Chip>
          </ListItem>
          :
          <ListFields>
            <ListCell text={t("user_lastname")} width={220}/>
            <ListCell text={t("user_email")} width={220}/>
          </ListFields>
          }
            
          { props.invitation.list.map((invitation: Invitation) =>
          <ListItem key={invitation.aid}>
            
            <ListCell text={invitation.username ? invitation.username : t("utils_undefined")} width={220}/>

            <ListCell text={invitation.email ? invitation.email : t("utils_undefined")} width={220}/>

            <Space/>

            { props.survey.active.status === "launch"
            ?
            <div className="flex">

              { props.project.active.diffusionMode.email &&
              <ListButton icon={faPaperPlane}
                isInactive={invitation.email ? false : true}
                onClick={() => selectAction(ACTION_SEND_EMAIL, invitation)}
                text={t("invitation_send_email")}
              />
              }

              { props.project.active.diffusionMode.qrcode &&
              <ListButton icon={faQrcode}
                onClick={() => selectAction(ACTION_DOWNLOAD_QRCODE, invitation)}
                text={t("invitation_download_qrcode")}
              />
              }
              
              <ListButton icon={faEllipsisV}
                actions={actions}
                onAction={(actionId:string) => selectAction(actionId, invitation)}
              />

            </div>
            :
            <ListButton icon={faUserMinus}
              text={t("invitation_remove")}
              onClick={() => selectAction(ACTION_REMOVE, invitation)}
            />
            }

          </ListItem>
          )}

        </ListContainer>
      </PageLoader>

    </div>
  )
}

const mapStateToProps = state => ({
  project : state.project,
  survey : state.survey,
  invitation : state.invitation
})

export default connect(mapStateToProps)(withTranslation()(ProjectInvitationsListWidget))