/** 
 * EXPORT-DASHBOARD
 * Export data image or csv
 */

import Modal from "@/components/modal"
import { connect } from "react-redux"
import { withTranslation, WithTranslation } from "react-i18next"
import { Survey, SurveyState } from "@/redux/survey.types";
import { initBookmarkName } from "@/utils/get-export-names.utils"
import Tabs from "@/components/tabs";
import Checkbox from "@/components/checkbox";
import Dropdown from "@/components/dropdown";
import { useEffect, useState } from "react";
import { store } from "@/index";
import { surveyActivate, surveyFetchOne } from "@/redux/survey.actions";
import { Axis } from "@/redux/axis.types";
import { Topic, TopicState } from "@/redux/topic.types";
import { Question } from "@/redux/question.types";
import { Proposition } from "@/redux/proposition.types";
import { fetchHeatmap, fetchMessages, fetchPropositions, fetchQuestions, fetchTopics } from "@/redux/_archive.actions";
import { DashboardFilters, Filter, FilterState} from "@/redux/filter.types";
import domtoimage from 'dom-to-image';
import { INSUFFICIENT_PARTICIPATION, STATUS_LOADED, STATUS_LOADING } from "@/redux/_status.types";
import * as XLSX from "xlsx"
import { saveAs } from "file-saver"
import { toast } from "react-toastify";
import JSZip from "jszip";
import initFilters from "@/utils/init-filters.utils";
import LoadingModal from "./loading.modal";
import { Session } from "@/redux/_session.types";
import getHeatmapCeils from "@/utils/get-heatmap-ceils.utils";
import Chip from "@/components/chip";
import getArchiveTopic from "@/utils/get-archive-topic.utils";
import { topicGet } from "@/redux/topic.actions";
import { getSatisfaction } from "@/utils/satisfaction.utils";
import { Config } from "@/redux/_config.types";
import { difference, keys } from "lodash";
import ReportAddModal from "./report-add.modal";
import ReportGetDataModal from "./report-get-data.modal";
import { NavigateFunction, useNavigate } from "react-router-dom";

interface StateProps extends WithTranslation{
  _session : Session
  filter : FilterState
  survey : SurveyState
  topic : TopicState
}

interface OwnProps{
  onClose : Function
  withSelector?: boolean
  hideTabs?: string[]
}

type Props = StateProps & OwnProps

const availableTabs: string[] = ["powerpoint", "excel", "csv", "image"]

const MODAL_REPORT = "MODAL_REPORT"
const MODAL_GET_REPORT_DATA = "MODAL_GET_REPORT_DATA"

function ExportDashboardModal(props:Props) {
  const navigate:NavigateFunction = useNavigate()
  const { t } = props

  const TABS: any = {}
  if(props.hideTabs && props.hideTabs.length > 0){
    const hiddenTabs: string[] = props.hideTabs
    availableTabs.forEach((x: string) => { 
      if(!hiddenTabs.includes(x)) {
        TABS[x] = t(x)
      }
    })
  }
  else{
    availableTabs.forEach((x: string) => TABS[x] = t(x))
  }

  const [currentModal, setCurrentModal] = useState<null | string>(null)
  const [supportEmail] = useState(new Config().supportEmail)
  const [exportLanguage, setExportLanguage] = useState(props.survey.active.languages.length ? props.survey.active.languages[0] : props._session.language)
  const [questions, setQuestions] = useState<boolean>(true)     //checkbox questions
  const [attributes, setAttributes] = useState<boolean>(true)     //checkbox matrix
  const [messages, setMessages] = useState<boolean>(true)        //checkbox messages
  const [error, setError] = useState<string | null>(null)

  const filters: any = initFilters()                                           //get survey's default and custom filters

  //columns of matrix data
  const [filter, setFilter] = useState<Filter>(props._session.accountOptions.heatmapFilterName ? getFavoriteFilter() : filters[0])
  
  //rows of matrix data
  const [result, setResult] = useState<string>(props._session.accountOptions.heatmapResultsType ? props._session.accountOptions.heatmapResultsType : 'axes')

  const [mode, setMode] = useState<string>('powerpoint')                             //format of data

  const [isGenerating, setIsGenerating] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [state, setState] = useState<any>({                                     //data from archives
    questions : [],
    propositions : [],
    messages : [],
  })
  

  useEffect(() => {

    async function loadItems(){
      const dashboardFilters: DashboardFilters = new DashboardFilters(props.filter.dashboard)

      const questions : any = await store.dispatch(fetchQuestions(dashboardFilters, null))
      const propositions : any = await store.dispatch(fetchPropositions(dashboardFilters))
      const messages : any = await store.dispatch(fetchMessages(dashboardFilters, null, null))

      setState(prevState => {
        return {
          ...prevState,
          questions : questions.error ? [] : questions,
          propositions : propositions.error ? [] : propositions,
          messages : messages.error ? [] : messages
        }
      })

      setError(null)
    }

    async function loadData(){
      const survey: any = await store.dispatch(surveyFetchOne(props.survey.active.id, false))

      const dashboardFilters: DashboardFilters = new DashboardFilters()

      let backendTopics: any = []
      const archiveTopics: any = await store.dispatch(fetchTopics(dashboardFilters, props._session.accountOptions.topicRepartitionCeil)) 

      if (!survey.error){

        backendTopics = new Survey(survey).template.Topics

        if (archiveTopics.error === INSUFFICIENT_PARTICIPATION){
          setError("insufficient_participation")
        }else if (!archiveTopics.error){
  
          //Init new topics for survey topics
          //var newTopics = Array.from(backendTopics)
  
          const topics: Topic[] = backendTopics.map((topic: Topic) => getArchiveTopic(topic, archiveTopics))

          store.dispatch(topicGet(topics))

          await loadItems()
  
        }
        else{
          //archive topics error
          setError("middleware_error_timeout")
        }
      }
      else{
        //survey error
        setError("middleware_error_timeout")
      }

    }

    async function load(){
      setIsLoading(true)

      if(props.withSelector){
        await loadData()
      }
      else{
        await loadItems()
      }

      setIsLoading(false)
    }

    if(props.survey.active.id){
      load()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.withSelector,
    props.survey.active.id
  ])

  //Fonction de génération puis téléchargement de l'image
  async function downloadImages(){
    const images:any = []
    let cards

    switch(window.location.pathname){
      case "/dashboard/messages":
        cards = document.getElementsByClassName("message-exportable")
        break

      default:
        cards = document.getElementsByClassName("card-exportable")
        break

    }

    for (let i = 0; i < cards.length; i++) {
      const card = cards[i]
      const image = await domtoimage.toBlob(card, { width: card.scrollWidth, height: card.scrollHeight })
      images.push({
        name : removeSpecialChar(card.id),
        image
      })
    }
    
    return images

  }

  function getFavoriteFilter(){
    const filter : Filter | undefined = filters.find((x:Filter) => (x.id === props._session.accountOptions.heatmapFilterName || x.name === props._session.accountOptions.heatmapFilterName))
    return filter ? filter : filters[0]
  }

  async function next(){


    if (mode === "powerpoint"){
      setCurrentModal(MODAL_REPORT)
    } else if (questions || attributes || messages){

      setIsGenerating(true)
      // Create a workbook
      const workbook: any = XLSX.utils.book_new();
      const response : any = process()

      const zip: JSZip = new JSZip()
      const csvFiles : any = []

      if(questions){

        const questionList : any = []
        const answerList : any = []
        response.filter((x: any)=>x.type !== "open").forEach((x: any)=>{

          //Clea data
          x.question = x.question.replace(/\n/g, '')
          x.topic = x.topic.replace(/\n/g, '')
          x.axis = x.axis.replace(/\n/g, '')

          if(x.type === "scale"){
            questionList.push({
              axis : x.axis,
              topic : x.topic,
              question : x.question,
              isPrimary : x.isPrimary,
              //return question score according to account options preferences (note X.X/10 or satisfaction XX/100)
              score : x[props._session.dashboardDisplaySettings.dashboardDisplayMode] > 0 ? ( props._session.dashboardDisplaySettings.dashboardDisplayMode === "note" ? (x.note/10).toFixed(1) : x.satisfaction.toFixed(0) ) : ""
            })
          }

          x.results.forEach(y=>{
            answerList.push({
              question : x.question,
              proposition : y[0],
              count : y[1],
              axis : x.axis,
              topic : x.topic,
            })
          })
        })

        if(mode === "csv"){
          csvFiles.push([t("questions")+".csv", toCSV(questionList)], [t("propositions")+".csv", toCSV(answerList)])
        }
        else{
          generateXLS(workbook, t("questions"), questionList)
          generateXLS(workbook, t("propositions"), answerList)
        }
      }

      if(attributes){

        const matrix : any[] = []
        const heatmap : any = await store.dispatch(fetchHeatmap(
          filter.default?filter.id:filter.name, 
          result, 
          exportLanguage,
          props._session.accountOptions.heatmapDisplayNoScore,
          props._session.dashboardDisplaySettings.dashboardDisplayMode === "satisfaction",
          props.filter.dashboard,
          getHeatmapCeils(filter.id),
          props._session.dashboardDisplaySettings.hideStartedForParticipation,
          false,
          false,
          false,
          null,
          0,
          0
        ))

        if(!heatmap.error){

          //Init participation
          //Last row of the heatmap
          const participation:any = {}
          const heatmapLength = heatmap.data.length - 1

          heatmap.data.forEach((x:any,i:number)=>{

            let row : object = {}
            let aid : number = 0

            x.forEach((y:any,j:number) => {
              if (j === 0){
                aid = y
              }else{

                let note: any = ""
                if(typeof y === "number"){
                  if (props._session.dashboardDisplaySettings.dashboardDisplayMode === "note"){
                    note = (y/10).toFixed(1)
                  }else{
                    note = y.toFixed(0)
                  }
                }

                let rowItem = ""
                if (filter.id === "company" || i === 0){
                  rowItem = props._session.accountName
                }else if (filter.default){
                  if(filter.id === "gender"){
                    rowItem = t("user_" + filter.id + "_" + heatmap.columns[j][1])
                  }
                  else{
                    rowItem = t("user_" + filter.id + "_" + (parseInt(heatmap.columns[j][1], 10) + 1) + (filter.id === "company_welcome_date" ? "_month" : ""), {
                      min: props._session.accountOptions["ceils" + (filter.id === "birth_date" ? "BirthDate" : "CompanyWelcomeDate")][j-2],
                      max: props._session.accountOptions["ceils" + (filter.id === "birth_date" ? "BirthDate" : "CompanyWelcomeDate")][j-1],
                      s1: "s",
                      s2: "s"
                    })
                  }
                }else{
                  rowItem = heatmap.columns[j][1]
                }

                row[rowItem] = note

                if (i === heatmapLength){
                  participation[rowItem] = Math.round(heatmap.data[0][j] / heatmap.data[1][j] * 100) + " %"
                }

              }

            })

            let resultName = getResultName(aid, response)
            if (i === 2 && filter.id !== "company") resultName = props.survey.active.name //If company

            row = Object.assign({ [t(result)] : resultName }, row)

            if (result === "questions" || result === "topic"){
              const item = response.find(x => x.questionAid === aid)

              row = Object.assign({ [t("axis")] : item ? item.axis : "" }, row)

              if (result === "questions"){
                row = Object.assign({ [t("topic")] : item ? item.topic : "" }, row)
              }

            }

            if (resultName){
              matrix.push(row)
            }

            //Last row = participation
            if (i === heatmapLength){

              const notMatchingKeys = difference(keys(row), keys(participation))
              if (notMatchingKeys.length){
                participation[notMatchingKeys[0]] = t("survey_participation")
                matrix.push(participation)
              }

            }
            
          })

          if(mode === 'csv'){
            csvFiles.push([t("dashboard_heatmap")+".csv", toCSV(matrix)])
          }

          else{
            generateXLS(workbook, t("dashboard_heatmap"), matrix)
          }

        }
        
        else{
          toast(t("error_loading_heatmap"), { type : 'error' })
        }
        
      }

      if(messages){

        const messageList : any = []
        response.forEach(x=>
          x.messages.forEach(y=>{
            messageList.push({
              question : x.question,
              proposition : y,
              topic : x.topic
            })
          })
        )

        if(mode === 'csv'){
          csvFiles.push([t("dashboard_messages")+".csv", toCSV(messageList)])
        }
        else{
          generateXLS(workbook, t("dashboard_messages"), messageList)
        }
      }

      if(mode === 'csv'){

        if(csvFiles.length === 1){                        //if array containing only one csv file
          const csv : any = csvFiles[0][1]
          const universalBOM = "\uFEFF";

          const hiddenElement = document.createElement('a');  //creates clickable link to download csv
          hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(universalBOM+csv);  
          hiddenElement.target = '_blank';  
    
          hiddenElement.download = getFileName();  
          hiddenElement.click();
        }

        else if(csvFiles.length>1){                       //else create zip archive
          csvFiles.forEach(x=>{
            zip.file(x[0], "\uFEFF"+x[1])
          })
          zip.generateAsync({type:"blob"}).then(function(content) {
            saveAs(content, getFileName() + ".zip");
          });
        }

        else{                                             //array is empty ? -> error
          toast(t("no_csv"), { type : 'error' })
        }
      }
      else{
        // Generate a binary Excel file and save it
        const wbout: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        saveAs(new Blob([wbout]), getFileName() + '.xlsx');
      }

      setIsGenerating(false)

    }else{
      alert(t("dashboard_export_empty"))
    }

  }

  //Export as image
  async function nextImage(){
      
    //Init folder
    let folderName = removeSpecialChar(initBookmarkName(t))
    if (window.location.pathname === "/dashboard/questions" && props.topic.list.some((x: Topic) => x.open)){
      folderName = t("questions")
    }

    //init zip
    const zip = new JSZip()
    const img:any = zip.folder(folderName)

    if (window.location.pathname === "/dashboard/questions" && props.topic.list.every((x: Topic) => !x.open)){
      alert(t("dashboard_export_image_empty"))
    }else{

      //Generate images
      setIsGenerating(true)
      const images = await downloadImages()
      addToZip(images)
      
      //Save zip
      zip.generateAsync({ type : "blob" }).then(content => {
        saveAs(content, folderName)
        setIsGenerating(false)
      })

    }

    function addToZip(images){
      images.forEach(i => {
        img.file(i.name + '.png', i.image, { base64: true })
      })
    }
  
  }

  function process(){
    const data : object[] = []

    if(props.survey.active){
      props.topic.list.forEach((topic: Topic) => {
        const axis : Axis = new Axis(topic.Axis!)
        topic = new Topic(topic)
        topic.Questions.forEach((q: Question) => {
          const aQuestion : any = state.questions.find((x: any) => x.aid === q.aid && x.fkTopic === topic.aid)
          const question : Question = new Question(q)
          const results : any = getData(aQuestion, question, topic)
          const messages : any = getMessages(question, topic)
          const axisLabel = axis.name[exportLanguage] ? axis.name[exportLanguage] : axis.label
          const topicLabel = topic.name[exportLanguage] ? topic.name[exportLanguage] : topic.label
          const questionLabel = question.name[exportLanguage] ? question.name[exportLanguage] : question.label

          data.push({
            axis : axisLabel,
            topic : topicLabel,
            question : questionLabel,
            isPrimary : question.primaryId ? t('question_secondary') : t('question_primary'),
            type : question.type,
            note : (aQuestion && aQuestion.note) ? aQuestion.note : -1,
            satisfaction : (aQuestion && aQuestion.repartition) ? getSatisfaction(aQuestion.repartition, aQuestion.noAnswers ? aQuestion.noAnswers : 0) : -1,
            results,
            messages,
            topicAid : topic.aid,
            questionAid : question.aid,
          })

        })
      })
    }

    else{
      toast(t("no_survey_active"), { type : 'error' })
    }

    return data

  }

  function getData(aQuestion: any, question:Question, topic:Topic){

    switch(question.type){

      case 'choice' :
        return question.Propositions.map((p: Proposition)=>{
          const aProposition:any = state.propositions.find((x: any) => x.aid===p.aid && x.fkTopic === topic.aid)
          p.count = aProposition? aProposition.count : 0
          const proposition : Proposition = new Proposition(p)
          return [proposition.name[exportLanguage], proposition.count]
        })

      case 'scale' :
        if(aQuestion){
          if (aQuestion.repartition.length > 10) aQuestion.repartition = aQuestion.repartition.slice(1)
          const names : string[] = getScaleNames(aQuestion.repartition.length, question.reverse)
          return aQuestion.repartition.map((r,i:number)=>{
            return [names[i], r]
          })
        }
        else{
          return []
        }

      default :
        return []
    }
  }

  function getMessages(question:Question, topic:Topic){
    return state.messages.filter((message: any)=>message.fkQuestion === question.aid && message.fkTopic === topic.aid).map(message=>{
      return message.text
    })
  }

  function getScaleNames(repartitionLength:number, reverse:boolean){
    let names : string[] = []
    switch(repartitionLength){
      case 2 :
        names = [
          t("utils_no"),
          t("utils_yes")
        ]
        break;
      case 4 :
        names = [
          t("question_likert_1"),
          t("question_likert_2"),
          t("question_likert_4"),
          t("question_likert_5")
        ]
        break;
      case 5 :
        names = [
          t("question_likert_1"),
          t("question_likert_2"),
          t("question_likert_3"),
          t("question_likert_4"),
          t("question_likert_5")
        ]
        break;
      default :
        for(let i = 0; i<repartitionLength; i++){
          names[i] = (i + 1) + "/" + (repartitionLength)
        }
        break;  
    }
    return reverse?names.reverse():names
  }

  function getResultName(aid:number, response:any){
    let search : any = null
    switch (result){
      case 'axes' :
        search = response.find(x=>x.topicAid === aid)
        if(search){
          return search.axis.replace(/\n/g, '')
        }
        else{
          return null
        }

      case 'questions' :
        search = response.find(x=>x.questionAid === aid)
        if(search){
          return search.question.replace(/\n/g, '')
        }
        else{
          return null
        }

      case 'topics' :
        search = response.find(x=>x.topicAid === aid)
        if(search){
          return search.topic.replace(/\n/g, '')
        }
        else{
          return null
        }

      default :
        return null

    }
  }

  function getFileName(){
    let fileName = ""

    if(questions && attributes && messages){
      fileName = props.survey.active.name
    }
    else{
      fileName = props.survey.active.name + (questions?"_"+t("questions"):"") + (attributes?"_"+t("dashboard_heatmap"):"") + (messages?"_"+t("dashboard_messages"):"")
    }

    //Remove accents
    fileName = fileName.normalize("NFD").replace(/[\u0300-\u036f]/g, "")

    return fileName

  }

  async function generateXLS(workbook: any, sheetTitle: string, data: any){

    const keys: string[][] = [Object.keys(data[0]).map((x: string) => t(x))]
    const excelWs: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(keys)
    excelWs['!cols'] = Array(Object.keys(data[0]).length).fill({ wch: 40 })

    data.forEach((x: any) => {
      const values: string[][] = [Object.values(x)]
      XLSX.utils.sheet_add_aoa(excelWs, values, { origin: -1 });
    })

    // Add worksheet to workbook
    XLSX.utils.book_append_sheet(workbook, excelWs, sheetTitle);

  }

  function removeSpecialChar(string){
    return string.replace(/[^\w\s]/gi, ' ')
  }

  function toCSV(data: any){
    const keys = Object.keys(data[0]);
    let result = keys.map(x => t(x)).join(";") + "\n";
    data.forEach(function(obj){
      result += keys.map(k => obj[k]).join(";") + "\n";
    })
    return result
  }

  return (
    <Modal isCloseButtonVisible
      disableOverflow
      onClose={() => props.onClose()}
      onNext={( !isGenerating && !isLoading && !error ) ? 
        () => (mode === 'image' ? nextImage() : next()) : undefined
      }
      status={( isLoading || isGenerating ) ? STATUS_LOADING : STATUS_LOADED}
      title={t("dashboard_export")}>

      {( isLoading || isGenerating ) &&
      <LoadingModal/>
      }

      { currentModal === MODAL_REPORT &&
      <ReportAddModal onClose={() => setCurrentModal(null)}
        onNext={() => setCurrentModal(MODAL_GET_REPORT_DATA)}
        isSurveySelected
      />
      }

      { currentModal === MODAL_GET_REPORT_DATA &&
      <ReportGetDataModal onClose={() => navigate("/report/edit")}/>
      }

      { props.withSelector &&
      <Dropdown active={props.survey.active.id}
        displayField="name"
        list={props.survey.list}
        onSelect={(survey: Survey) => store.dispatch(surveyActivate(survey))}
        title={t("survey")}
        value={props.survey.active.name}
      />
      }

      { error ?
      <p className="red-t"><b>{t(error)}</b></p>
      :
      <>

        <Tabs
          active={mode}
          onClick={(e) => setMode(e)}
          tabs={TABS}
        />

        { (props.survey.active.languages.length > 1 && mode !== "image") && 
        <div style={{ marginBottom : '12px' }}>

          <div className="grey-t" style={{ marginBottom : '6px' }}>
            {t("survey_language_export")}
          </div>

          <div className="flex flex-wrap">
            { props.survey.active.languages.map(lg => 
            <Chip key={lg} isWithMargin
              onClick={() => setExportLanguage(lg)}
              color={exportLanguage === lg ? props._session.accountColors.active : ""}>
              {lg.toUpperCase()}
            </Chip>
            )
            }
          </div>

        </div>
        }

        { mode === "powerpoint" &&
        <div>

          <p>
            {t("report_export_module")}
          </p>

          <img src={require("@/assets/modules/report_1.png")}
            alt="powerpoint"
            width="100%"
          />

        </div>
        }

        { mode === "csv" &&
        <i>{t("warning_zip")}</i>
        }

        { mode === "image" &&
        <div style={{ padding : '20px', marginBottom : '20px' }}>
          {t("dashboard_export_image")}

          { window.location.pathname === "/dashboard/messages" &&
          <p className="grey-t">
            {t("dashboard_export_image_message")}
          </p>
          }

        </div>
        }

        { (mode === "csv" || mode === "excel") &&
        <div>
          
          <Checkbox 
            active={questions}
            onClick={() => setQuestions(!questions)}
            text={t("questions")}
          />

          <Checkbox 
            active={attributes}
            onClick={() => setAttributes(!attributes)}
            text={t("dashboard_heatmap")}
          />

          <Dropdown
            displayField="name"
            list={filters}
            status={STATUS_LOADED}
            isContextMenuTop
            onSelect={(item) => setFilter(item)}
            title={t("filter")}
            isDisabled={!attributes || isGenerating || isLoading}
            active={filter.id}
            value={t(filter.name)}
          />

          <Dropdown
            displayField="name"
            list={[{name : 'axes'}, {name : 'topics'}, {name : 'questions'}]}
            status={STATUS_LOADED}
            isContextMenuTop
            onSelect={(item) => setResult(item.name)}
            title={t("dashboard_results")}
            isDisabled={!attributes || isGenerating || isLoading}
            active={result}
            value={t(result)}
          />

          <Checkbox 
            active={messages}
            onClick={() => setMessages(!messages)}
            text={t("dashboard_messages")}
          />

        </div>
        }
      </>

      }

      { (props._session.accountLevel !== "free" && props._session.userRole !== "OBSERVER") &&
      <p className="grey-t"
        style={{ width : 400, marginTop : 12 }}>
        {t("dashboard_export_support")}
        <a className="grey-t _hover" href={"mailto:" + supportEmail}
          style={{ color : "inherit" }}>
          <u>{supportEmail}</u>
        </a>
      </p>
      }

    </Modal>
  )

}

const mapStateToProps = state => ({
  _session : state._session,
  filter : state.filter,
  survey : state.survey,
  topic : state.topic
})

export default connect(mapStateToProps)(withTranslation()(ExportDashboardModal))