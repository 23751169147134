/**
 * ARCHIVE.ACTIONS
 * Liste des actions qui déclenchent requête vers backend archive
 */

import { DashboardFilters } from "./filter.types";


export const fetchRpsOverview = (
  dashboardFilters: DashboardFilters,
  surveyId?: string,
  surveyStart?: Date
) => ({
  type: "ARCHIVE",
  payload: {
    url: "/rps/overview",
    dashboardFilters,
    surveyId,
    surveyStart
  },
})

export const fetchRpsUserQuestions = (
  userId:string,
  surveyId?: string,
  surveyStart?: Date
) => ({
  type : 'ARCHIVE',
  payload : {
    url : "/rps/user/questions",
    surveyId,
    surveyStart,
    data : {
      userId
    }
  }
})

export const fetchRpsRecommandations = (
  dashboardFilters: DashboardFilters,
  surveyId?: string,
  surveyStart?: Date
) => ({
  type: "ARCHIVE",
  payload: {
    url: "/rps/recommandations",
    dashboardFilters,
    surveyId,
    surveyStart
  },
})

export const fetchRpsUser = (
  userId:string,
  surveyId?: string,
  surveyStart?: Date
) => ({
  type: "ARCHIVE",
  payload: {
    url: "/rps/user",
    surveyId,
    surveyStart,
    data: {
      userId,
    },
  },
})

export const fetchRpsUsers = (
  dashboardFilters: DashboardFilters,
  surveyId?: string,
  surveyStart?: Date
) => ({
  type: "ARCHIVE",
  payload: {
    url: "/rps/users",
    dashboardFilters,
    surveyId,
    surveyStart
  },
})

export const fetchRpsTeams = (
  filterName: string,
  dashboardFilters: DashboardFilters,
  surveyId?: string,
  surveyStart?: Date
) => ({
  type: "ARCHIVE",
  payload: {
    url: "/rps/teams",
    dashboardFilters,
    surveyId,
    surveyStart,
    data: {
      filterName,
    },
  },
})
