/**
 * GET-LABEL-SHORT
 * Cut word with more than 30 char
 */

const getLabelShort = (label:string) => {
  if (label.length > 30){
    label = label.slice(0, 30) + " ..."
  }
  return label
}

export default getLabelShort