/**
 * TEMPLATE-QUESTION.WIDGET
 * Show every questions of template
 */


import { faCheckCircle, faChevronDown, faChevronRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Chip from "@/components/chip"
import ListButton from "@/components/list-button"
import ListCell from "@/components/list-cell"
import ListItem from "@/components/list-item"
import { store } from "@/index"
import { useEffect, useState } from "react"
import { WithTranslation, withTranslation } from "react-i18next"
import { axisFetch } from "@/redux/axis.actions"
import { Axis } from "@/redux/axis.types"
import { topicFetch } from "@/redux/topic.actions"
import { Topic } from "@/redux/topic.types"
import { StatusType, STATUS_LOADED, STATUS_LOADING, STATUS_LOAD_ERROR } from "@/redux/_status.types"
import PageLoader from "@/components/page-loader"
import { Question } from "@/redux/question.types"
import { Proposition } from "@/redux/proposition.types"
import NoResultsWidget from "./no-results.widget"

interface OwnProps {
  currentTemplateId : string
  language : string
}

type Props = WithTranslation & OwnProps

function TemplateQuestionsWidget(props: Props){
  const { t } = props

  const [axes, setAxes] = useState<Axis[]>([])
  const [topics, setTopics] = useState<Topic[]>([])
  const [status, setStatus] = useState<StatusType>(STATUS_LOADING)
  

  useEffect(() => {
    async function load(){

      setStatus(STATUS_LOADING)

      const topicResponse: any = await store.dispatch(topicFetch(props.currentTemplateId))
      const axisResponse: any = await store.dispatch(axisFetch(props.currentTemplateId, null))

      if(!topicResponse.error && !axisResponse.error){
        setTopics(topicResponse.map((topic: Topic) => new Topic(topic)))
        setAxes(axisResponse.map((axis: Axis) => new Axis(axis)))

        setStatus(STATUS_LOADED)
      }
      else{
        setStatus(STATUS_LOAD_ERROR)
      }
    }

    load()

  }, [props.currentTemplateId])
  

  //Get axis with list of topics
  function getAxis(axis:Axis, axisIndex: number){
    return (
      <div key={axis.id} >

        <ListItem isEditable
          onClick={() => openAxis(axis.id)}>
          <ListButton icon={axis.open ? faChevronDown : faChevronRight}
            onClick={() => openAxis(axis.id)}
          />
          <b style={{ color : axis.color }}>
            {axis.label.length ? axis.label : t("questions_without_axis")}
          </b>
        </ListItem>

        { axis.open &&
        <div>
          {getTopics(axis.id).length < 1 ?
          <div>
            {t("topic_undefined")}
          </div>
          :
          <div>
            {getTopics(axis.id).map((topic: Topic, j: number) => 
            <div key={topic.id}>

              <div className="medgrey" style={{ height : "2px" }}/>

              { topic.Questions.map((question: Question, i: number) =>
              <div key={question.id}>
                
                <ListItem>
              
                  { i === 0
                  ?
                  <ListCell>
                    #{axisIndex}{axisIndex ? "." : ""}{j + 1}
                  </ListCell>
                  :
                  <ListCell>
                    <div className="rel editor-topics-secondary">
                      <div className="editor-topics-secondary-dot medgrey"/>
                      <div className="editor-topics-secondary-top medgrey-b"/>
                      { (i < topic.Questions.length - 1 || question.type === "choice") &&
                      <div className="editor-topics-secondary-bottom medgrey-b"/>
                      }
                    </div>
                  </ListCell>
                  }

                  <ListCell width={100}>
                    { i === 0 && topic.label }
                  </ListCell>

                  <ListCell width={200}>
                    <Chip color={i === 0 ? topic.Axis?.color : null}>
                      {question.getPrimaryLabel(i === 0)}
                    </Chip>
                  </ListCell>

                  {question.label}
                  
                </ListItem>

                { question.type === "choice" &&
                question.Propositions.map((proposition: Proposition, k: number) =>
                <ListItem key={proposition.id}>

                  <ListCell>
                    { /** afficher une ligne pour questions secondaires */
                    topic.Questions.length > 1 &&
                    <div className="rel editor-topics-secondary">
                      <div className="editor-topics-secondary-top medgrey-b"/>
                      { (
                        i < topic.Questions.length - 1 ||
                        k < question.Propositions.length - 1
                      ) &&
                      <div className="editor-topics-secondary-bottom medgrey-b"/>
                      }
                    </div>
                    }
                  </ListCell>

                  <ListCell/>

                  <ListCell width={260}/>

                  <span className="grey-t">
                    <FontAwesomeIcon icon={faCheckCircle} style={{ marginRight : '12px' }}/>
                  </span>

                  <span>
                    <i>{proposition.name[props.language]}</i>
                  </span>

                </ListItem> 
                )}

              </div>
              )}

            </div>
            )}

          </div>
          }

        </div>
        }

      </div>
    )
  }

  //Get topics depending of the axis ID
  function getTopics(axisId: string){
    if (axisId){
      return topics.filter((x: Topic) => x.Axis?.id === axisId)
    }else{
      return topics.filter(x => x.Axis === null)
    }
  }

  function openAxis(axisId: string){
    setAxes(axes.map((axis: Axis) => axis.id === axisId ? new Axis({...axis, open : !axis.open}) : axis))
  }

  return(
    <PageLoader status={status}>
      { status === STATUS_LOAD_ERROR ?
        <div>
          <NoResultsWidget />
        </div>
      :
      <div>
      { axes.map((axis:Axis, i:number) =>
        getAxis(axis, i + 1)
      )}
      </div>
    }
    </PageLoader>
  )
}

export default withTranslation()(TemplateQuestionsWidget)