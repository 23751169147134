
//List of item to save
export const DEFAULT_ICONS:any[] = [
  { id : "building", visible:true, category:"institutions" },
  { id : "shield", visible:true, category:"institutions"},
  { id : "city", visible:true, category:"institutions" },
  { id : "hospital", visible:true, category:"institutions" },
  { id : "industry", visible:true, category:"institutions" },
  { id : "landmark", visible:true, category:"institutions" },
  { id : "school", visible:true, category:"institutions" },
  { id : "shop", visible:true, category:"institutions" },
  { id : "store", visible:true, category:"institutions" },
  { id : "warehouse", visible:true, category:"institutions" },
  { id : "australia", visible:true, category:"flags" },
  { id : "belgium", visible:true, category:"flags" },
  { id : "brazil", visible:true, category:"flags" },
  { id : "canada", visible:true, category:"flags" },
  { id : "china", visible:true, category:"flags" },
  { id : "france", visible:true, category:"flags" },
  { id : "germany", visible:true, category:"flags" },
  { id : "india", visible:true, category:"flags" },
  { id : "italy", visible:true, category:"flags" },
  { id : "japan", visible:true, category:"flags" },
  { id : "netherlands", visible:true, category:"flags" },
  { id : "poland", visible:true, category:"flags" },
  { id : "russia", visible:true, category:"flags" },
  { id : "spain", visible:true, category:"flags" },
  { id : "sweden", visible:true, category:"flags" },
  { id : "united-kingdom", visible:true, category:"flags" },
  { id : "united-states", visible:true, category:"flags" }
]

export function getRandomIcon():string{
  const randomIcon: any = DEFAULT_ICONS.filter((x:any)=>x.visible)[Math.floor(Math.random() * DEFAULT_ICONS.filter((x:any)=>x.visible).length)]
  return randomIcon.category + "/" + randomIcon.id
}

export function getIconUrl(defaultIcon:string):string{
  return require("@/assets/default_icons/" + defaultIcon + ".png")
}