/**
 * FIND-PROGRAM-DATE.UTILS.TS
 * Find the next available date in the calendar (next thueday or tursday)
 */

function findProgramDate(initialDate:Date, iteration:number){
  let count = 0
  const days = [2, 4] //Days of weeks (The or Thu)
  let nextDate : null | Date = null

  let week = 0
  while (!nextDate && count < 10){

    for (let d = 0; d < days.length; d++) {

      const date = new Date(initialDate)
      const day = date.getDay() > days[d] ? days[d] : days[d] + 7
  
      date.setDate(week * 7 + (date.getDate() + (day - date.getDay()) % 7))
      date.setHours(9)
      date.setMinutes(30)
      date.setSeconds(0)
      date.setMilliseconds(0)
  
      if (iteration === count){
        nextDate = date
        break
      }else{
        count += 1
      }
  
    }

    week += 1

  }

  return nextDate ? nextDate : new Date()

}

export default findProgramDate