/**
 * ADM-INTEGRATION-MODAL
 * List of SIRH available for synchronisation
 */

import Modal from "@/components/modal";
import { WithTranslation, withTranslation } from 'react-i18next';
import CardContainer from "@/components/card-container";
import CardButton from "@/components/card-button"
import { AccountIntegration, AccountState } from "@/redux/account.types";
import TextInput from "@/components/text-input";
import { connect } from "react-redux";
import { Session } from "@/redux/_session.types";
import { store } from "@/index";
import { sessionEditAccountOptions } from "@/redux/_session.actions";
import { accountStatus, accountUpdateOptions } from "@/redux/account.actions";
import { STATUS_SAVED, STATUS_SAVE_ERROR, STATUS_SAVING } from "@/redux/_status.types";
import { toast } from "react-toastify";

interface StateProps extends WithTranslation{
  account : AccountState
  _session : Session
}

interface OwnProps{
  onClose : Function
  onSave : Function
}
  
type Props = StateProps & OwnProps

interface CardItem{
  id : AccountIntegration
  title : string
  image : string
}

//Liste des cartes disponibles
const INTEGRATIONS:CardItem[] = [
  {
    id : "lucca",
    title : "Lucca",
    image : require("@/assets/logo-lucca.png")
  },
  {
    id : "peoplesphere",
    title : "PeopleSpheres",
    image : require("@/assets/logo-peoplespheres.png")
  }
]

function AdmIntegrationModal(props:Props){
  const { t } = props

  function changeIntegrationMethod(integrationId: AccountIntegration){
    store.dispatch(sessionEditAccountOptions("integration", integrationId))
  }

  //Edit text value
  function edit(event:any){
    store.dispatch(sessionEditAccountOptions(event.id, event.value))
  }


  //Proceed to sync with SIRH
  async function saveOptions(){
    store.dispatch(accountStatus(STATUS_SAVING))
    const response: any = await store.dispatch(accountUpdateOptions(props._session.accountOptions))
    if(!response.error){
      store.dispatch(accountStatus(STATUS_SAVED))
      props.onSave()
    }
    else{
      toast(t("error_saving"), { type : "error" })
      store.dispatch(accountStatus(STATUS_SAVE_ERROR))
    }
      
  }

  return (
    <Modal isCloseButtonVisible
      onClose={() => props.onClose() }
      onNext={props._session.accountOptions.integration ? saveOptions : undefined}
      status={props.account.status}
      title={t("sirh_import")}>

      <CardContainer isAlignedLeft>
        {INTEGRATIONS.map((integration: CardItem) =>
        <CardButton
          isActive={integration.id === props._session.accountOptions.integration}
          key={integration.id}
          image={integration.image}
          title={integration.title}
          onClick={() => changeIntegrationMethod(integration.id)}>
        </CardButton>
        )}
      </CardContainer>

      { props._session.accountOptions.integration === "lucca" &&
      <div>

        <p>
          {t("sirh_lucca_help")}
        </p>
              
        <TextInput title={t("sirh_lucca_url")}
          id="luccaApiUrl"
          onChange={ edit }
          placeholder="https://api.lucca/"
          autoFocus
          value={props._session.accountOptions["luccaApiUrl"]}
        />

        <TextInput title={t("sirh_lucca_api")}
          id="luccaApiKey"
          onChange={ edit }
          placeholder="eb1b5a84-dc51-417c-bf79-abcfc97b40e2"
          value={props._session.accountOptions["luccaApiKey"]}
        />

        <p>
          <b>{t("sirh_lucca_api_help")}</b>
        </p>

        <p>
          {t("sirh_lucca_api_details")}
        </p>

      </div>
      }

      { props._session.accountOptions.integration === "peoplesphere" &&
      <div>
        <p>
          <b>{"IDTree est désormais disponible en tant que module du SIRH PeopleSpheres."}</b>
        </p>
        <p>
          {"Veuillez nous contacter par email à l'adresse contact@id-tree.com si vous utilisez déjà le SIRH PeopleSpheres"}
        </p>
      </div>
      }

    </Modal>
    
  )

}

const mapStateToProps = state => ({
  account : state.account,
  _session : state._session
})

export default connect(mapStateToProps)(withTranslation()(AdmIntegrationModal))