/***
 * MODULES.WIDGET
 * List of modules avaiables for an account
 */

import { connect } from "react-redux";
import { WithTranslation, withTranslation } from "react-i18next"
import { Session } from "@/redux/_session.types";
import { AccountModules } from "@/redux/account.types";
import ChipsSelector from "@/components/chips-selector";
import { STATUS_SAVED } from "@/redux/_status.types";
import { toast } from "react-toastify";
import { forOwn } from "lodash";

interface StateProps extends WithTranslation{
  _session : Session
}

interface OwnProps {
  status? : string
  isEditable? : boolean
  readonly? : boolean
  availableModules? : AccountModules //superadmin case : cannot set locked modules
  modules : AccountModules
  onChange? : Function
}

type Props = StateProps & OwnProps

function ModulesWidget(props:Props){
  const { t } = props

  //Get list of modules to display
  function getModulesReadyOnly(){
    const texts:string[] = []
    
    forOwn(props.modules, (value, key) => {
      if (value) texts.push(t("module_" + key))
    })

    if (texts.length){
      return (
        <span>
          {texts.join(", ")}
        </span>
      )
    }else{
      return (
        <span className="grey-t">
          {t("utils_empty")}
        </span>
      )
    }

  }

  function isLocked(moduleId : string){
    if(props.availableModules && props.availableModules[moduleId] !== true){
      return true
    }
    else{
      return false
    }
  }

  function selectModule(moduleId:string){
    if(isLocked(moduleId)){
      toast(t("upgrade_title_module", {feature : "module_" + moduleId}), { type : 'error' })
    }
    else{
      const newModules : AccountModules = props.modules
      newModules[moduleId] = !props.modules[moduleId]
      if(props.onChange){
        props.onChange(newModules)
      }
    }
  }


  return (
    props.readonly
    ?
    <div>
      {getModulesReadyOnly()}
    </div>
    :
    <div>

      <div className="text-input-title grey-t"
        style={{ marginBottom : '12px' }}>
        {t("account_modules")}
      </div>

      <ChipsSelector colorActive={props._session.accountColors.active}
        status={props.status ? props.status : STATUS_SAVED}
        onSelect={(moduleId:string)=>selectModule(moduleId)}
        chips={ Object.entries(props.modules).map(([key, value]) => {
          return({ id : key, label : t("module_" + key), active : value, locked : isLocked(key)})
        }) }
      />

    </div>
  )
}

const mapStateToProps = state => ({
  account : state.account,
  _session : state._session
})

export default connect(mapStateToProps)(withTranslation()(ModulesWidget))
