/** 
 * CHIPS-SELECTOR
 * Select one chips from a list
 */
import { faLock } from "@fortawesome/free-solid-svg-icons"
import { useState } from "react"
import Chip from "./chip"
import SaveIcon from "./save-icon"

interface Chips{
  id : string
  label : string
  locked : boolean
  active : boolean
}

interface OwnProps{
  colorActive : string
  chips : Chips[]
  onSelect : Function
  status : string
}

function ChipsSelector(props:OwnProps) {

  const [showStatus, setShowStatus] = useState(false) //Show save status

  function select(chipId){
    setShowStatus(true)
    props.onSelect(chipId)
    setTimeout(() => {
      setShowStatus(false)
    }, 500)
  }

  function getColor(chip:Chips){
    if(chip.locked){
      return "#e9e9e9"
    }
    else if(chip.active){
      return props.colorActive
    }
    else{
      return null
    }
  }

  return (
    <div className="flex flex-wrap">

      { props.chips.map((chip:Chips) =>
      <div key={chip.id}>
        <Chip onClick={() => select(chip.id)}
          isWithMargin
          icon={chip.locked?faLock:undefined}
          color={getColor(chip)}>
          {chip.label}
        </Chip>
      </div>
      )
      }

      { showStatus &&
      <SaveIcon status={props.status}/>
      }
    
    </div>
  )

}

export default ChipsSelector