/** 
 * CARD-CONTAINER
 * Container for card-button
 */

interface OwnProps{
  children : any,
  isAlignedLeft? : boolean
}

function CardContainer(props:OwnProps) {
  return (
    <div className="card-container flex flex-wrap"
      style={{ 
        justifyContent: !props.isAlignedLeft ? "center" : ""
      }}>
      { props.children}
    </div>
  )
}

export default CardContainer