/** 
 * TASKS-EDIT.WIDGET
 * Edit action plan
 */

import { connect } from "react-redux"
import { ActionPlan, ActionPlanState } from "@/redux/action-plan.types"
import TasksListWidget from "./tasks-list.widget"
import TasksTopicsWidget from "./tasks-topics.widget"
import { useEffect, useState } from "react"
import { STATUS_LOADED, STATUS_LOADING, STATUS_SAVING } from "@/redux/_status.types"
import { store } from "@/index"
import { actionPlanActivate, actionPlanFetchOne, actionPlanUpdateAndStatus } from "@/redux/action-plan.actions"
import PageLoader from "@/components/page-loader"
import { TopicState } from "@/redux/topic.types"
import AdmUserPopulationsWidget from "./adm-user-populations.widget"
import PageHeader from "@/components/page-header"
import { WithTranslation, withTranslation } from "react-i18next"
import { loadSurveyAndTopics } from "@/redux/_archive.actions"
import { Session } from "@/redux/_session.types"
import PagePlaceholder from "@/components/page-placeholder"
import { surveyActivate, surveyFetch, surveyGet } from "@/redux/survey.actions"
import { Survey } from "@/redux/survey.types"
import { toast } from "react-toastify"
import { topicGet, topicStatus } from "@/redux/topic.actions"

interface StateProps extends WithTranslation{
  _session : Session
  actionPlan : ActionPlanState
  topic : TopicState
}

function TasksEditWidget(props:StateProps) {
  const { t } = props

  //Set is empty
  //> for an observer if there is no action plan defined
  const [isEmpty, setIsEmpty] = useState(false)

  //Set is loading
  const [isLoading, setIsLoading] = useState(true)

  //check active action plan
  useEffect(() => {

    const queryParams: URLSearchParams = new URLSearchParams(window.location.search)
    const apId: string | null = queryParams.get("ap")
    const isNew: string | null = queryParams.get("isNew")

    async function loadActionPlan(actionPlanId: string){
      const response: any = await store.dispatch(actionPlanFetchOne(actionPlanId))
      if (response.error){
        store.dispatch(actionPlanActivate(response))
      }
    }

    if(apId){
      if(apId !== props.actionPlan.active.id){
        const apToActivate: ActionPlan | undefined = props.actionPlan.list.find((ap: ActionPlan) => ap.id === apId)

        if(apToActivate){
          store.dispatch(actionPlanActivate(apToActivate))
        }
        else{
          toast(t("action_plan_not_found"), { type: "error" })
        }
      }
    }
    else{
      if(!isNew){
        loadActionPlan(props.actionPlan.active.id)
      }
    }

    //Add delay before ending loading
    setTimeout(() => {
      setIsLoading(false)
    }, 500)
    
  }, [])

  useEffect(() => {
    async function loadSurveys(){
      store.dispatch(topicStatus(STATUS_LOADING))

      const response: any = await store.dispatch(surveyFetch())

      if (response.error){
        store.dispatch(surveyGet([], [], true))
        store.dispatch(surveyActivate(new Survey()))
        store.dispatch(topicGet([]))
      }else{
        store.dispatch(surveyGet(response, [], false))

        //Detect if a survey is defined
        //If not search the last one
        const actionPlanSurvey: Survey | undefined = response.find((x: Survey) => x.id === props.actionPlan.active.referenceSurveyId)

        if (actionPlanSurvey){
          //Load topics
          store.dispatch(surveyActivate(actionPlanSurvey))
          store.dispatch(loadSurveyAndTopics(actionPlanSurvey.id))
        }
        else{
          store.dispatch(surveyActivate(new Survey()))
          store.dispatch(topicGet([]))
        }
      }

      store.dispatch(topicStatus(STATUS_LOADED))

    }

    if(!isLoading){
      loadSurveys()
    }
  }, [
    isLoading,
    props.actionPlan.active.referenceSurveyId
  ])

  //For OBSERVER
  //> if no action plan set as empty
  useEffect(() => {

    if (props._session.userRole === "OBSERVER" && props.actionPlan.active.status === "draft"){
      setIsEmpty(true)
    }

  }, [
    props.actionPlan.active.status,
    props._session.userRole
  ])
  

  //Detect changes in order to save
  useEffect(() => {

    if (props.actionPlan.status === STATUS_SAVING){
      update()
    }

    async function update(){
      store.dispatch(actionPlanUpdateAndStatus())
    }

  }, [props.actionPlan.status])

  return (
    <div>
      
      <PageHeader title={t("tasks_new_admin", { name: props.actionPlan.active.UserResponsible?.username + "" })}/>

      { props.actionPlan.active.UserResponsible &&
      <div style={{
          marginTop : -32,
          marginBottom : 28
        }}>
        <AdmUserPopulationsWidget currentUser={props.actionPlan.active.UserResponsible}/>
      </div>
      }

      <PageLoader status={isLoading || props.topic.status === STATUS_LOADING ? STATUS_LOADING : STATUS_LOADED}>

        { isEmpty
        ?
        <PagePlaceholder
          title={t("tasks_empty_manager")}
          image="sherlock"
        />
        :
        <div className="flex">

          { (props.actionPlan.active.status === "draft" && !props._session.isSuperAccount) &&
          <TasksTopicsWidget/>
          }

          <TasksListWidget/>
          
        </div>
        }

      </PageLoader>

    </div>
  )

}

const mapStateToProps = state => ({
  _session: state._session,
  actionPlan: state.actionPlan,
  topic: state.topic
})

export default connect(mapStateToProps)(withTranslation()(TasksEditWidget))