/** 
 * EMAIL-ADD-EMAIL.MODAL
 * Add email for a project
 */

import { withTranslation, WithTranslation } from "react-i18next"
import { connect } from "react-redux"
import { useEffect, useState } from "react";
import { Email, EmailState } from "@/redux/email.types";
import ProjectEmailContentWidget from "../widgets/project-email-content.widget";
import Modal from "@/components/modal";
import { Session } from "@/redux/_session.types";
import { ProjectState } from "@/redux/project.types";
import i18n from "@/translate/i18n"
import ListItem from "@/components/list-item";
import { store } from "@/index";
import { emailFetchByAccount } from "@/redux/email.actions";
import { STATUS_LOADED, STATUS_LOADING } from "@/redux/_status.types";
import PageLoader from "@/components/page-loader";
import Tabs from "@/components/tabs";
import Space from "@/components/space";
import Scroller from "@/components/scroller";
 
interface StateProps extends WithTranslation{
  _session : Session
  email : EmailState
  project : ProjectState
}

interface OwnProps{
  onClose : Function
  onNext : Function
}

const TAB_IDTREE: string = "TAB_IDTREE"
const TAB_ACCOUNT: string = "TAB_ACCOUNT"

type Props = StateProps & OwnProps

function ProjectAddEmailModal(props:Props) {
  const { t } = props

  //Chargement en cours
  const [isLoading, setIsLoading] = useState(true)

  //Init list of emails templates
  const [emails, setEmails] = useState<Email[]>([])
  
  //Email that is selected
  const [currentEmail, setCurrentEmail] = useState<Email>(new Email())

  //Tabs
  const [currentTab, setCurrentTab] = useState<string>(TAB_IDTREE)

  //Onload get list of templates
  //If template from account fetch data from server
  //If no template emails open idtree tab
  useEffect(() => {

    load()

    async function load(){

      setIsLoading(true)
  
      if (currentTab === TAB_ACCOUNT){
        const accountEmails:any = await store.dispatch(emailFetchByAccount())
        if (!accountEmails.length){
          setCurrentTab(TAB_IDTREE)
        }else{
          setEmails(accountEmails.error ? [] : accountEmails.map(x => new Email(x)))
        }
      }else{
        setEmails(
          [new Email().getFromMessage(t("project_message"), props.project.active.messageOptions, props._session.accountName)]
          .concat(new Email().getList(props._session.accountName, props.project.active.name, props.project.active.Template.label/*.length > 0 ? props.project.active.Template.label : t("template_tag_qvt")*/))
          .concat(new Email().getFromTemplate(5, props._session.accountName, props.project.active.name, props.project.active.Template.label/*.length > 0 ? props.project.active.Template.label : t("template_tag_qvt")*/))
        )
      }
  
      setIsLoading(false)

    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    t,
    currentTab,
    props.project.active.messageOptions,
    props._session.accountName,
    props.project.active.name
  ])

  //Change current email when list is updated
  useEffect(() => {
    if (emails.length){
      setCurrentEmail(emails[0])
    }
  }, [emails])

  return (
    <Modal isLarge
      status={STATUS_LOADING}
      title={t("email_add")}
      isCloseButtonVisible
      onClose={props.onClose}
      onNext={() => props.onNext(currentEmail)}>

      <Tabs active={currentTab}
        onClick={(tab: string) => setCurrentTab(tab)}
        tabs={{ 
          TAB_IDTREE : t("email_tab_idtree"),
          TAB_ACCOUNT : t("email_tab_account")
        }}
      />

      <PageLoader status={isLoading ? STATUS_LOADING : STATUS_LOADED}>
        <div className="flex"
          style={{ margin : "0px -40px" }}>

          <div style={{ 
              minWidth : 300,
              height : "calc(100vh - 390px)",
              overflowY : "auto"
            }}>
            
            { emails.map((email: Email) =>
            <ListItem key={email.id}
              isEditable
              onClick={() => setCurrentEmail(email)}
              isActive={currentEmail.id === email.id}>
              {email.name}
              <Space/>
              <div className="width-10"/>
              <span className="grey-t">
                {email.Project.name}
              </span>
            </ListItem>
            )}
          </div>

          <Scroller maxHeight={500}>
            <ProjectEmailContentWidget
              currentLanguage={i18n.language}
              emailContent={currentEmail.content}
              skin={props.project.active.emailSkin}
            />
          </Scroller>
          
        </div>
      </PageLoader>
      
      <div className="height-20"/>

    </Modal>
  )

}

const mapStateToProps = state => ({
  _session : state._session,
  email : state.email,
  project : state.project
})

export default connect(mapStateToProps)(withTranslation()(ProjectAddEmailModal))