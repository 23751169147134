/** 
 * NAV-ITEM
 * Menu top button
 */
import { faCaretDown, IconDefinition } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useState } from "react"
import { connect } from "react-redux"
import ContextMenu from "./context-menu"
import { Session } from "@/redux/_session.types"
import Space from "./space"
import { NavigateFunction, useNavigate } from "react-router-dom"

interface StateProps{
  _session : Session
}

interface OwnProps{
  children? : any //Items for context menu
  icon : IconDefinition //Icon of button,
  pathname : string //Page to navigate to
  title : string //Text to display
  tooltip? : string //Text data-tip
}

type Props = StateProps & OwnProps

function NavItem(props:Props) {
  const navigate: NavigateFunction = useNavigate()

  //Open context
  const [isChildrenVisible, setIsChildrenVisible] = useState(false)

  //Action on clic
  function click(){
    navigate(props.pathname)
  }

  //Close menu
  function closeContextMenu(){
    setIsChildrenVisible(false)
  }

  //Classname
  //If page match with current page > highlight button
  function getClassname(){
    let className = "menutop-item flex"

    if(props.pathname.split("/")[1] === window.location.pathname.split("/")[1]){
      className = className + " menutop-item-opacity"
    }

    return className

  }

  //Color of text
  function getStyle(){
    return {
      color : props._session.accountColors.brandText
    }
  }

  return (
    <div id={props.pathname.replaceAll("/", "_")} className="rel"
      onMouseLeave={props.children ? () => setIsChildrenVisible(false) : undefined}>
      
      <div className={getClassname()}
        title={props.tooltip}
        onMouseEnter={props.children ? () => setIsChildrenVisible(true) : undefined} 
        onClick={click}
        style={getStyle()}>

        <div className="menutop-item-content flex">
          <div className="flex flex-dcol">
            <Space/>
            <FontAwesomeIcon icon={props.icon}/>
            <Space/>
          </div>
          
          <div className="menutop-item-dropdown">
            
            <span style={{ marginLeft : 6 }}>
              {props.title}
            </span>

            { props.children &&
            <FontAwesomeIcon icon={faCaretDown}/>
            }

          </div>
        </div>

      </div>

      { isChildrenVisible &&
      <ContextMenu onClose={closeContextMenu}>
        { props.children }
      </ContextMenu>
      }
    </div>
    
  )

}

const mapStateToProps = state => ({
  _session : state._session
})

export default connect(mapStateToProps)(NavItem)