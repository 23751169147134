/***
 * CHAT.WIDGET
 * Display chat at the bottom of the app
 */
import { useEffect } from "react";
import Chatra from "@chatra/chatra"
import { Session } from "@/redux/_session.types";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";

interface StateProps{
  _session : Session
}

function ChatWidget(props:StateProps){

  //Get location
  const location = useLocation()

  //Init chat
  //DIsplay chat only if language is french and chat is active
  useEffect(() => {
    if (
      props._session.language === "fr" && 
      !props._session.accountOptions.disableChat && 
      props._session.userRole === "ADMIN"
    ){

      Chatra('init', {
        ID: 'CRXxXCQN6cMqRbMRw',
        setup : {
          groupId: 'QjXttMQTaakSxgz7b',
          buttonPosition : "bl"
        }
      })

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  //Update params where location is change
  useEffect(() => {
    Chatra('updateIntegrationData', {
      currentPage : location.pathname
    })
  }, [location.pathname])

  //Update session params
  useEffect(() => {
    Chatra('updateIntegrationData', {
      email : props._session.email,
      phone : props._session.phone,
      accountName : props._session.accountName
    })
  }, [
    props._session.email,
    props._session.phone,
    props._session.accountName
  ])

  return (
    <div/>
  )

}

const mapStateToProps = state => ({
  _session : state._session
})

export default connect(mapStateToProps)(ChatWidget)



