/**
 * 
 * RESPONSE-LABEL.UTILS
 * Get label for response
 * 
 */

import { store } from "@/index"
import { Template } from "@/redux/template.types"

export function responseLabel(i: number, responseCount: number){
  const template: Template = store.getState().survey.active.template
  if (responseCount === 10){
    return i
  }else if (responseCount === 2){
    if (i === 2) template.getResponseLabel(2, "yes", "utils")
    else return template.getResponseLabel(2, "no", "utils")
  }else{
    return template.getResponseLabel(responseCount, i, "question_likert")
  }

}