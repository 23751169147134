/** 
 * REPORT-DUPLICATE-TEMPLATE.MODAL
 * Duplicate the template
 */
import Modal from "@/components/modal"
import { useState } from "react"
import { connect } from "react-redux"
import { withTranslation, WithTranslation } from "react-i18next"
import TextInput from "@/components/text-input"
import { store } from "@/index"
import { STATUS_SAVE_ERROR, STATUS_SAVED, STATUS_SAVING } from "@/redux/_status.types"
import { Session } from "@/redux/_session.types";
import LoadingModal from "./loading.modal";
import { ReportState } from "@/redux/report.types"
import { reportDuplicate, reportStatus } from "@/redux/report.actions"

interface StateProps extends WithTranslation{
  _session : Session
  report : ReportState
}

interface OwnProps{
  onClose : Function
  onNext : Function
}

type Props = StateProps & OwnProps

function ReportDuplicateModal(props:Props) {
  const { t } = props

  //Name of the template
  //if from idtree add name of the account
  //else add suffix "copy"
  const [reportName, setReportName] = useState(props.report.active.name + " - " + t("utils_copy"))

  //Close modal
  function close(){
    props.onClose()
  }

  //Save template if user is connected
  async function next(){

    //Search the function (depends if the user is connected as supervisor)
    store.dispatch(reportStatus(STATUS_SAVING))
    const report:any = await store.dispatch(reportDuplicate(props.report.active.id, reportName))
    store.dispatch(reportStatus(STATUS_SAVED))
    
    if (report.error){
      store.dispatch(reportStatus(STATUS_SAVE_ERROR))
    }else {
      props.onNext(report)
    }

  }

  return (
    <Modal isCloseButtonVisible
      onClose={close}
      onNext={next}
      title={t("report_duplicate")}>

      { props.report.status === STATUS_SAVING &&
      <LoadingModal/>
      }

      <TextInput autoFocus
        isFullWidth
        onChange={(e) => setReportName(e.value)}
        title={t("report_name_new")}
        value={reportName}
      />

      <div className="height-20"/>

    </Modal>
  )

}

const mapStateToProps = state => ({
  _session : state._session,
  report : state.report
})

export default connect(mapStateToProps)(withTranslation()(ReportDuplicateModal))