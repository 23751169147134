//i18n.JS
//Init traductions dans l'application. Utiliser les fichiers json dans langugues pour mettre à jour les textes

//Importer module de traduction
import i18n from "i18next"
import HttpApi from "i18next-http-backend"
import { initReactI18next } from "react-i18next"

//Importer fichiers de traduction
import languagesDe from "@/translate/languages/de.json"
import languagesFr from "@/translate/languages/fr.json"
import languagesEn from "@/translate/languages/en.json"

//Init le module
i18n
  .use(HttpApi)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: languagesEn },
      fr: { translation: languagesFr },
      de: { translation: languagesDe }
    },
    debug: false,
    lng: "en",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false
    }
  })

export default i18n
