/** 
 * DASHBOARD-PROPOSITION.WIDGET
 * Display details for multiple choice question
 */

import { withTranslation, WithTranslation } from "react-i18next"
import ReactTooltip from 'react-tooltip'
import { connect } from "react-redux"
import { Axis } from "@/redux/axis.types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { Session } from "@/redux/_session.types";
import { Proposition } from "@/redux/proposition.types";
import { SurveyState } from "@/redux/survey.types";

interface StateProps extends WithTranslation{
  _session : Session
  survey : SurveyState
}

interface OwnProps{
  currentProposition : Proposition
  currentAxis : Axis
}

type Props = StateProps & OwnProps

function DashboardPropositionWidget(props:Props) {
  const { t } = props

  function getPercentage(): JSX.Element{
    const participationCount: number = props.survey.active.getParticipationCount(props._session.dashboardDisplaySettings.hideStartedForParticipation)
    const ratioParticipationCount: string = props.currentProposition.getRatio(participationCount)
    
    const countAnswered: number | null = props.currentProposition.countAnswered
    const ratioCountAnswered: string = props.currentProposition.getRatio((countAnswered && countAnswered > 0) ? countAnswered : participationCount)
    const ratioParticipationSend: string = props.currentProposition.getRatio(props.survey.active.participationSend)

    return (countAnswered && countAnswered > 0) ? <>
      ({ratioCountAnswered}%
      <FontAwesomeIcon style={{ margin : '3px', color : 'grey' }}
        data-for={"tooltip-" + props.currentProposition.aid}
        icon={faInfoCircle} data-tip={
        t(props.currentProposition.countAnswered ? "propositions_count_help_1" : "propositions_count_help_2", {
          text: props.currentProposition.label,
          count: props.currentProposition.count,
          countAnswered: countAnswered,
          participationCount: participationCount,
          ratioCountAnswered: ratioCountAnswered,
          ratioParticipationCount: ratioParticipationCount,
          ratioParticipationSend: ratioParticipationSend,
        })
        }
      />
      )
    </>:<></>
  }

  return (
    <div key={props.currentProposition.aid} className="flex details-proposition">

      <div className="details-proposition-item"
        style={{ borderColor : props.currentAxis.color }}>
        <div className="details-proposition-item-center"
          style={{ backgroundColor : props.currentAxis.color }}>
        </div>
      </div>

      <div className="grey-t flex1">
        { props.currentProposition.id === "others" 
        ?
        t("utils_others")
        :
        props.currentProposition.label
        }
      </div>
      &nbsp;
      {props.currentProposition.count} {props.currentProposition.count > 1 ? t("propositions") : t("proposition")}&nbsp;
      {getPercentage()}
      <ReactTooltip html id={"tooltip-" + props.currentProposition.aid}/>
    </div>
  )

}

const mapStateToProps = state => ({
  _session : state._session,
  survey : state.survey
})

export default connect(mapStateToProps)(withTranslation()(DashboardPropositionWidget))