/* eslint-disable */

import { toast } from "react-toastify";
import { t } from "@/translate/t";

//Validate password according rules
const validatePassword = (minLength: number, password: string, passwordRepeat: string): boolean => {
  const passwordRegex = /[!@#$%^&*()\-_=+{}[\]:;"'<>,.?/~]/;
  if (password.length === 0){
    toast(t("self_password_not_defined"), { type : "error"} )
    return false
  } else if (password !== passwordRepeat){
    toast(t("self_password_not_match"), { type : "error"} )
    return false
  } else if (password.length < minLength){
    toast(t("self_password_too_short", { count : minLength }), { type : "error"} )
    return false
  } else if (!passwordRegex.test(password)){
    toast(t("self_password_special_character"), { type : "error"} )
    return false
  } else{
    return true
  }
}

export default validatePassword