/**
 * GET-GENERATIVE-ASSISTANT-SUMMARY.UTILS
 * Return response from chatGPT from summarize comments
 */

import { Question } from "@/redux/question.types"
import { SocketCreate } from "./socketio.utils"
import { store } from ".."

const getGenerativeAssistantSummary = async (question:Question, comments:string[]) => {

  //Init websocket
  const socket = SocketCreate()
  socket.connect()

  //Init output summary
  let summary:string = ""

  //Create promise that will return a result once data is returned
  return new Promise((resolve, reject) => {
    
    socket.emit(
      question.isPromptCustom ? "ask" : "summarize",
      {
        comments : question.isPromptCustom && question.prompt ? [] : comments,
        prompt : question.getPromptFormatted(comments, store.getState()._session.userInitialLanguage),
        questionLabel : question.label
      }
    )

    socket.on("response", (token:string, finishReason: string | null) => {
      summary = summary + token
      if(finishReason === "length" || finishReason === "stop"){
        socket.disconnect()
        resolve(summary)
      }
    })

    socket.on("error", (error) => {
      reject(error)
      socket.disconnect()
    })

  })


}

export default getGenerativeAssistantSummary