/** 
 * PROGRESS-BAR
 * Show progression
 */

import { SESSION_COLORS, Session } from "@/redux/_session.types"
import { connect } from "react-redux";

interface StateProps{
  _session : Session
}

interface OwnProps{
  label? : string
  items : Item[]
  max : number
}

type Item = {value: number, text?: string, color?: string}

type Props = StateProps & OwnProps

function ProgressBar(props:Props) {

  return (
    <div className="flex loading-bar-container">

      { props.label &&
      <div className="flex1 loading-bar-label">
        {props.label}
      </div>
      }

      <div className="flex1 loading-bar">
        <div className="flex">
          <div className="loading-bar-inside" />

          { props.items.sort((a: Item, b: Item) => b.value - a.value).map((item: Item, index: number) =>
          <div
            key={index}
            className={"loading-bar-inside abs _hover"}
            title={(item.text ? (item.text + " : ") : "") + (index >= props.items.length - 1 ? item.value : item.value - props.items[index + 1].value)}
            style={{ 
              width : (item.value / props.max) * 98 + 2 + '%',
              backgroundColor : item.color ? item.color : ((index === props.items.length - 1) ? props._session.accountColors.active : SESSION_COLORS[index])
            }}
          />
          )}
          
        </div>
        <div className="loading-bar-count">
          <b>{Math.min(...props.items.map((x: {value: number, text?: string}) => x.value))} / {props.max}</b>
        </div>
      </div>

    </div>

  )
}

const mapStateToProps = state => ({
  _session : state._session
})

export default connect(mapStateToProps)(ProgressBar)