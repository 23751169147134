/**
 * PROJECT-LAUNCH.MODAL
 * Launch a survey from project params
 */
import Modal from "@/components/modal";
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { Session } from "@/redux/_session.types";
import { ProjectState } from "@/redux/project.types";
import TextInput from "@/components/text-input";
import { useEffect, useState } from "react";
import Space from "@/components/space";
import Button from "@/components/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight, faChartBar, faCog, faPaperPlane, faUserFriends, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import LoadingModal from "./loading.modal";
import ReactMarkdown from "react-markdown";
import { UserState } from "@/redux/user.types";
import { SendingState } from "@/redux/sending.types";
import { store } from "@/index";
import { accountFetchInvitations } from "@/redux/account.actions";
import PageLoader from "@/components/page-loader";
import { STATUS_LOADED, STATUS_LOADING } from "@/redux/_status.types";
import ProgressBar from "@/components/progress-bar";
import { projectEdit, projectLaunch } from "@/redux/project.actions";
import { Survey } from "@/redux/survey.types";
import { surveyActivate } from "@/redux/survey.actions";
import i18n from "@/translate/i18n";
import { EmailState } from "@/redux/email.types";
import { sessionEdit } from "@/redux/_session.actions";
import CongratulationModal from "./congratulation.modal";
  
interface StateProps extends WithTranslation{
  _session : Session
  email : EmailState
  project : ProjectState
  sending : SendingState
  user : UserState
}

interface OwnProps{
  isTest? : boolean
  onClose : Function
  recipientsCount: number
}

const MODAL_STARTING = "MODAL_STARTING"
const MODAL_DONE = "MODAL_DONE"

type Props = StateProps & OwnProps

function ProjectLaunchModal(props:Props) {
  const { t } = props

  //Is loading
  const [currentModal, setCurrentModal] = useState<string | null>(null)

  //Get remaining invitations
  const [invitationsRemaining, setInvitationsRemaining] = useState(0)
  const [invitationsRemainingAfter, setInvitationsRemainingAfter] = useState(0)

  //Loading in progress
  const [isLoading, setIsLoading] = useState(props.isTest ? false : true)

  //Recipients count for the suer
  const [recipientsCount] = useState(props.isTest ? props.project.active.usersForTest.length : props.recipientsCount)

  //Name of the survey
  const [surveyName, setSurveyName] = useState(props.project.active.name + (props.isTest ? " - TEST" : ""))

  //Load invitations remaining for the account
  useEffect(() => {

    if (!props.isTest){
      loadInvitationsRemaining()
    }

    async function loadInvitationsRemaining(){
      const invitations:any = await store.dispatch(accountFetchInvitations())
      setInvitationsRemaining(invitations.error ? 0 : invitations.remaining)
      if (!invitations.error) setInvitationsRemainingAfter(invitations.remaining - recipientsCount)
      setIsLoading(false)
    }

  }, [
    props.isTest,
    recipientsCount
  ])

  //End of the operation : close modal and scroll to the top
  function end(){
    const scrollDiv = document.getElementById('page-navigation-scrollable')

    if (scrollDiv){
      scrollDiv.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    }

    props.onClose()
    
  }

  //Launch
  async function launch(){
    if (!surveyName.length){
      toast(t("survey_name_empty"), { type : "error" })
    }else{

      setCurrentModal(MODAL_STARTING)
      const survey:any = await store.dispatch(projectLaunch(props.project.active.id, props.isTest ? true : false, surveyName, i18n.language, props.isTest ? props.email.active.id : null))

      if (survey.error){
        setCurrentModal(null)
      }else if (survey.message){
        setCurrentModal(null)
        toast(t(survey.message, { count : props._session.accountOptions.ceilsTesters }))
      }else{
        if(!props.isTest && !props._session.accountOptions.isUnlimited){
          store.dispatch(sessionEdit("invitationsRemaining", invitationsRemainingAfter))
        }
        store.dispatch(surveyActivate(survey))
        store.dispatch(projectEdit("Surveys", [new Survey(survey)].concat(props.project.active.Surveys)))
        setCurrentModal(MODAL_DONE)
      }

    }
  }

  return (
    currentModal === MODAL_DONE 
    ?
    <CongratulationModal text={t("survey_created")}
      onClose={() => end()}
    />
    :
    <Modal onClose={() => props.onClose()}
      title={t(props.isTest ? "project_test" : "project_launch")}>

      { currentModal === MODAL_STARTING &&
      <LoadingModal/>
      }

      <PageLoader status={isLoading ? STATUS_LOADING : STATUS_LOADED}>

        <div className="flex">

          <TextInput title={t("survey_input_name", {test : props.isTest ? t("test") : ""})}
            onChange={(e) => setSurveyName(e.value)}
            value={surveyName}
          />

          <div className="width-40"/>

          { props.isTest &&
          <div className="orange-t"
            style={{ marginTop : 32, width : 300 }}>
            <p>{t("account_invitations_test_survey")}</p>
          </div>
          }

          { !props.isTest &&
          (invitationsRemainingAfter >= 0 
          ?
          <div className="flex1">
            <div className="text-input-title grey-t">
              {props._session.accountOptions.isUnlimited ? t("invitations") : t("account_invitations_remaining")}
            </div>
            <ProgressBar
              items={[
                {value: invitationsRemainingAfter}
              ]}
              max={invitationsRemaining}
            />
            <div className="grey-t"
              style={{ fontSize : '12px' }}>
              {t("account_invitations_" + (props._session.accountOptions.isUnlimited ? "unlimited" : "remaining") + "_help", {
                count : invitationsRemainingAfter,
                s : invitationsRemainingAfter > 1 ? "s" : ""
              })}
            </div>
          </div>
          :
          <div>
            <div className="text-input-title grey-t">
              {t("account_invitations_remaining")} : {invitationsRemaining}
            </div>
            <p className="red-t">
              <b>{t("survey_error_invitations")}</b>
                
              <u className="_hover" onClick={() => window.open("https://www.id-tree.com/lp-contact", "_blank")}>
                {t("utils_contact")}
              </u>
            </p>
          </div>
          )
          }

        </div>

        <div style={{ 
            margin : '22px 0px',
            maxHeight : "calc(100vh - 510px)",
            overflowY : "auto"
          }}>

          <div className="flex">
            {ProjectLaunchIcon(faUserFriends)}
            <ReactMarkdown>
              {t("survey_launch_users" + (recipientsCount > 1 ? "" : "_uniq"), {
                count : recipientsCount,
                test : props.isTest ? t("test") : ""
              })}
            </ReactMarkdown>
          </div>

          { props.isTest
          ?
          <div className="flex">
            {ProjectLaunchIcon(faPaperPlane)}
            <ReactMarkdown>
              {t("survey_launch_date_now", {test : props.isTest ? t("test") : ""})}
            </ReactMarkdown>
          </div>
          :
          props.sending.list.length > 0 &&
          <div className="flex">
            {ProjectLaunchIcon(faPaperPlane)}
            <ReactMarkdown>
              {t("survey_launch_date", {
                date : props.sending.list[0].dateLabel
              })}
            </ReactMarkdown>
          </div>
          }

          <div className="flex">

            {ProjectLaunchIcon(faChartBar)}

            <div>

              <p>
                {t("survey_launch_admin", {test : props.isTest ? t("test") : ""})}
              </p>

              <div style={{ marginTop : '-8px', marginBottom : '16px' }}>

                {ProjectLaunchAction(t(recipientsCount < props._session.participationMin ? "survey_launch_admin_results_empty" : "survey_launch_admin_results", {
                  count : props._session.participationMin
                }))}

                {ProjectLaunchAction(t("survey_launch_admin_users"))}

                { (props.project.active.diffusionMode.email && !props.isTest) &&
                ProjectLaunchAction(t("survey_launch_admin_dates"))
                }

                { props.project.active.diffusionMode.qrcode &&
                ProjectLaunchAction(t("survey_launch_admin_qrcode"))
                }

              </div>

            </div>

          </div>

          <div className="flex">
            {ProjectLaunchIcon(faCog)}
            <p>
              {t("survey_launch_close", {test : props.isTest ? t("test") : ""})}
            </p>
          </div>

        </div>

        <div className="flex">
          <Space/>
          { (invitationsRemainingAfter >= 0 || props.isTest) &&
          <Button onClick={launch}
            isLarge
            isFullWidth
            isWithBorder
            className={props.isTest ? "secondary" : "primary"}>
            {t("project_launch_confirm", {test : props.isTest ? t("test") : ""})}
          </Button>
          }
          <Space/>
        </div>

        <div className="flex">
          <Space/>
          <Button onClick={() => props.onClose()}
            isFullWidth>
              {t("utils_cancel")}
          </Button>
          <Space/>
        </div>

      </PageLoader>

    </Modal>

  )
  
}

//Description text for the steps available after survey launch
function ProjectLaunchAction(text:string){
  return (
    <div className="grey-t" 
      style={{
        fontSize : '12px',
        marginBottom : '8px'
      }}>
      <FontAwesomeIcon icon={faCaretRight}/>
      &nbsp;
      {text}
    </div>
  )
}

//Return icon
function ProjectLaunchIcon(icon:IconDefinition){
  return (
    <FontAwesomeIcon icon={icon} className="green-t"
      style={{ marginTop : "10px", marginRight : "8px" }}
    />
  )
}


const mapStateToProps = state => ({
  _session : state._session,
  email : state.email,
  project : state.project,
  sending : state.sending,
  user : state.user
})

export default connect(mapStateToProps)(withTranslation()(ProjectLaunchModal))