/** 
 * LANGUAGE-SELECTION
 * Languages
 */

import { faExclamationTriangle, faPlus } from "@fortawesome/free-solid-svg-icons"
import { connect } from "react-redux"
import { useState } from "react"
import { Session } from "@/redux/_session.types"
import Chip from "./chip"
import { toast } from "react-toastify"
import ModalConfirm from "./modal-confirm"
import { withTranslation, WithTranslation } from "react-i18next"
import LanguageLock from "./language-lock"
import Modal from "./modal"
import AddLanguageWidget from "@/widgets/add-language.widget"
import { Language } from "@/redux/language.types"
import languages from '@/translate/lang.json'

interface StateProps extends WithTranslation{
  _session : Session
}

interface OwnProps{
  languages : string[]
  activeLang? : string
  onAdd? : Function
  onDelete? : Function
  onClick? : Function
  checkError? : Function
  removeMargin? : boolean
}

type Props = StateProps & OwnProps

const MODAL_ADD_LANGUAGE: string = "MODAL_ADD_LANGUAGE"         //Add new language
const MODAL_REMOVE_LANGUAGE: string = "MODAL_REMOVE_LANGUAGE"   //Remove language

function LanguageSelection(props:Props) {
  const { t } = props

  const langAvailables: Language[] = languages.filter((lang: any)=>props.languages.indexOf(lang.code) > -1).map((lang: any)=>new Language(lang))

  //Open context menu of actions
  const [currentModal, setCurrentModal] = useState<string | null>(null)
  const [langToDelete, setLangToDelete] = useState<Language | null>(null)

  function getColor(languageCode: string){
    if(props.activeLang && languageCode===props.activeLang){ 
      return props._session.accountColors.active
    }
    else{
      return props.onClick? "" : "#CCCCCC"
    }
  }
  
  function isLanguageActive(languageCode: string){
    return langAvailables.some((x: Language)=>x.code === languageCode)
  }

  function isError(languageCode: string){
    if(props.checkError){
      return props.checkError(languageCode)
    }
    else{
      return false
    }
  }

  //Remove language
  function toggleLanguage(language: Language){
    if (isLanguageActive(language.code)){
      if (langAvailables.length > 1){
        setLangToDelete(language)
        setCurrentModal(MODAL_REMOVE_LANGUAGE)
      }else{
        toast(t("utils_language_required"), { type : 'error' })
      }
    }else{
      if(props.onAdd!){
        props.onAdd(language)
      }
    }
  }

  //Confirm remove of the language
  function removeLanguageConfirm(){
    if(props.onDelete!){
      props.onDelete(langToDelete)
    }
    setLangToDelete(null)
    setCurrentModal(null)
  }

  function click(language: Language){
    if(props.onClick!){
      props.onClick(language)
    }
  }


  return (
    (props._session.modules.languages || props._session.interfaceType === "SUPERVISOR")
    ?
    <div>
      
      { currentModal === MODAL_ADD_LANGUAGE &&
      <Modal onClose={() => setCurrentModal(null)}
      isLarge
      isCloseButtonVisible
      title={t("utils_lg_params")}>
        <AddLanguageWidget
          activeLanguages={props.languages}
          displaySearch
          onToogle={(language: Language)=>{toggleLanguage(new Language(language))}}
        />
      </Modal>

      }

      { /** confirmer désactiver la langue */
      currentModal === MODAL_REMOVE_LANGUAGE &&
      <ModalConfirm onNo={() => setCurrentModal(null)}
        onYes={removeLanguageConfirm}
        textBold={t("template_ask_deactivate_language", {
          language : langToDelete!.fullName.toUpperCase()
        })}
      />
      }

      <div className={"flex flex-wrap" + (props.removeMargin?" margin-chip":"")}>

        {langAvailables.map((language: Language) =>
        <Chip
          key={language.code}
          onClick={()=>click(language)}
          color={getColor(language.code)}
          isWithMargin={props.removeMargin?false:true}
          onDelete={props.onDelete? () => toggleLanguage(language) : null}
          icon={isError(language.code) ? faExclamationTriangle : undefined}
          >

          { language.flag &&
          <img src={require("@/assets/default_icons/flags/" + language.flag + ".png")}
            alt={language.code}
            width="14" height="14"
            style={{ margin : '0px 6px -2px 0px' }}
          />
          }

          {language.fullName}
        
        </Chip>
        )}
   
        {props.onAdd &&
        <Chip
          onClick={() => setCurrentModal(MODAL_ADD_LANGUAGE)}
          isWithMargin
          icon={faPlus}>
          {t("template_add_language")}
        </Chip>
        }

      </div>

    </div>
    :
    <LanguageLock/>
  )

}

const mapStateToProps = state => ({
  _session : state._session
})

export default connect(mapStateToProps)(withTranslation()(LanguageSelection))