/** 
 * TEMPLATE-PREVIEW.HELP
 * Aperçu du tableau de bord
 */
import HelpModal from "@/components/modal-help"
import tipsByAxes from "@/assets/tips-dashboard.json"
import ListItem from "@/components/list-item"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown, faChevronRight } from "@fortawesome/free-solid-svg-icons"
import { useState } from "react"

interface OwnProps{
  onClose : Function
}

function DashboardTipsHelp(props:OwnProps) {

  const [selectedAxis, setSelectedAxis] = useState<null | string>(null)

  return (
    <HelpModal onClose={props.onClose}
      title="Conseils">

      <div className="height-10"/>
      
      { tipsByAxes.map(axis => 
      <ListItem key={axis.id}
        isEditable
        onClick={() => setSelectedAxis(selectedAxis === axis.id ? null : axis.id)}>     
        <div className="flex1">
          
          <div style={{ fontSize : 18 }}>
            <FontAwesomeIcon icon={selectedAxis === axis.id ? faChevronDown : faChevronRight}/>
            &nbsp;
            {axis.name}
          </div>

          { selectedAxis === axis.id &&
          <div style={{ marginTop : 40 }}>
            
            { axis.tips.map(tip =>
            <div key={tip.id}>
              <p>
                <b>
                  {tip.tip_title_fr}
                </b>
              </p>
              <p>
                {tip.text_fr}
              </p>
              <p style={{ fontSize : 12 }}>
                <i>
                  Efficacité : {tip.efficiency} / 5
                </i>
              </p>
              <p style={{ fontSize : 12 }}>
                <i>
                  Difficulté à mettre en place : {tip.difficulty} / 5
                </i>
              </p>

              <div className="height-10"/>
              
              <hr></hr>

            </div>
            )
            }

          </div>
          }
        
        </div>
      </ListItem>
      )
      }
      

    </HelpModal>
  )
}

export default DashboardTipsHelp