/** 
 * EDIT-LESSON.MODAL
 * edit lesson
 */

import Modal from "@/components/modal"
import { useEffect, useState } from "react"
import { connect } from "react-redux"
import { withTranslation, WithTranslation } from "react-i18next"
import TextInput from "@/components/text-input"
import { store } from "@/index"
import { STATUS_LOADED, STATUS_SAVED, STATUS_SAVE_ERROR } from "@/redux/_status.types"
import { Session } from "@/redux/_session.types";
import { Lesson, LessonState } from "@/redux/lesson.types";
import Dropdown from "@/components/dropdown";
import { lessonAddOrReplaceAndInsertOrUpdate, lessonStatus } from "@/redux/lesson.actions";
import { toast } from "react-toastify";
import Space from "@/components/space"
import LanguageSelection from "@/components/language-selection";
import ImagePicker from "@/components/image-picker";
import UploadButtonWidget from "@/widgets/upload-button.widget";
import HtmlEditor from "@/components/html-editor";
import { upload } from "@/redux/_upload.actions";
import Link from "@/components/link"
import Tabs from "@/components/tabs";
import Checkbox from "@/components/checkbox";
import DeleteLessonModal from "./delete-lesson.modal";
import { Language } from "@/redux/language.types";

interface StateProps extends WithTranslation{
  _session : Session
  lesson : LessonState
}

interface OwnProps{
  onClose : Function
  onSave? : Function
}

type Props = StateProps & OwnProps

const MODAL_DELETE_LESSON_CONFIRM : string = "MODAL_DELETE_LESSON_CONFIRM"


function EditLessonModal(props:Props) {
  const { t } = props

  const lesson : Lesson = Object.assign({}, props.lesson.active,
    {name : Object.assign({}, props.lesson.active.name)},
    {description : Object.assign({}, props.lesson.active.description)},
    {text : Object.assign({}, props.lesson.active.text)}
  )

  const [currentLesson, setCurrentLesson] = useState<Lesson>(new Lesson(lesson))
  const [currentLanguage, setCurrentLanguage] = useState<string>(props._session.language)
  const [currentModal, setCurrentModal] = useState<string | null>(null)

  useEffect(()=>{
    
    if(currentLesson.languages.indexOf(currentLanguage)<0){  //if the active lesson does not support current language
      setCurrentLanguage(currentLesson.languages[0])
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  useEffect(()=>{
    if(props.lesson.status===STATUS_SAVE_ERROR){
      toast(t("lesson_save_error"), { type : 'error' })
    }
    else if(props.lesson.status===STATUS_SAVED){
      toast(t("utils_saved"))
      if(props.onSave){
        props.onSave()
      }
      close()
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.lesson.status])

  //Reset url params on close
  function close(){
    store.dispatch(lessonStatus(STATUS_LOADED))
    props.onClose()
  }

  function addLanguage(language : string){
    editLesson("languages", [...currentLesson.languages, language])
    setCurrentLanguage(language)
  }

  function changeLanguage(language : string){
    setCurrentLanguage(language)
  }

  function removeLanguage(language : string){
    if(currentLanguage === language){
      setCurrentLanguage(currentLesson.languages[0] === language?currentLesson.languages[1]:currentLesson.languages[0])
    }
    editLesson("languages", currentLesson.languages.filter((lang:string)=>lang !== language))
  }

  function editLesson(key:string, value:any){
    const newLesson : Lesson = new Lesson(currentLesson)
    
    //check if object multi lang
    if(key === "name" || key === "description" || key === "text"){
      const item : any = currentLesson[key]
      item[currentLanguage] = value 
      newLesson[key] = item
    }
    else{
      newLesson[key] = value
    }

    setCurrentLesson(newLesson)

  }

  function getFlagEmoji(countryCode : string) {
    if(countryCode!){
      return countryCode.replace(/./g,(char)=>String.fromCodePoint(0x1f1a5+char.toUpperCase().charCodeAt(0)))
    }
    else{
      return ""
    }
  }

  function isNameEntered(language: string){
    return currentLesson.name[language]
  }

  function getLangTabs(){
    const tabs : any = {}
    currentLesson.languages.forEach((countryCode:string)=>tabs[countryCode] = countryCode)
    return tabs
  }

  //Save the lesson
  //The function to use depends if the user is connected as supervisor or into an account
  async function next(){

    //new lesson or save as a copy ?
    const copy : boolean = !currentLesson.id /*|| copy*/

    await store.dispatch(lessonAddOrReplaceAndInsertOrUpdate(currentLesson, props._session.interfaceType === "SUPERVISOR", copy))

  }

  async function uploadFile(event){

    const reader = new FileReader()
    const file = event[0]
    let fileReader:any | null

    if (file){
      const name : string = file.name
      const type : string = file.type

      //Get extension of the uploaded file
      const extension : string = name.substring(name.lastIndexOf("."))

      reader.onloadend = async function(event){
        fileReader = event.target!.result
        sendFile(fileReader, type, extension)
      }
  
      reader.readAsDataURL(file)

    }
  }

  async function sendFile(fileReader :any, type : string, extension : string){
    const response:any = await store.dispatch(upload(currentLesson.id, fileReader, "Lessons/files", type))
    if(response.error){
      toast(t("file_not_uploaded"), { type : 'error' })
    }
    else{
      const file : any = {type, extension}
      editLesson("file", file)
    }
  }

  return (
    <Modal
      isCloseButtonVisible
      isLarge
      disableKeyEvents
      disableClickOutside
      status={props.lesson.status}
      onDelete={()=>currentLesson.id?setCurrentModal(MODAL_DELETE_LESSON_CONFIRM):null}
      onClose={close}
      onNext={next}
      title={t("lesson_edit")}>

      { // if multilang show tabs
      currentLesson.languages.length > 1
      &&
      <Tabs active={currentLanguage}
        tabs={getLangTabs()}
        onClick={(tab) => changeLanguage(tab)}
      />
      }

      {currentModal === MODAL_DELETE_LESSON_CONFIRM &&
      <DeleteLessonModal
        onClose={() => setCurrentModal(null)}
        onConfirm={close}
        lesson={props.lesson.active}
        fromSupervisor
      />
      }

      <div className="flex">

        <div className="flex1">
          
          <TextInput
            autoFocus
            required
            placeholder = {t("lesson_new")}
            error={!currentLesson.name[currentLanguage]}
            isFullWidth
            onChange={(e) => editLesson( "name", e.value )}
            title={t("lesson_name") + " " + getFlagEmoji(currentLanguage)}
            value={currentLesson.getValue("name", currentLanguage)}
          />
          
        
          <div className="flex">

            <div className="flex1">
              
              <div className="text-input-title">
                <span className="grey-t">
                  {t("utils_languages")}
                </span>
                <Space/>
              </div>

              <LanguageSelection
                languages={currentLesson.languages}
                onAdd={(language: Language)=>addLanguage(language.code)}
                onDelete={(language: Language)=>removeLanguage(language.code)}
                onClick={(language: Language)=>changeLanguage(language.code)}
                activeLang={currentLanguage}
                checkError={(languageCode: string) => !isNameEntered(languageCode)}
              />

            </div>

            <div>
              <div className="text-input-title">
                <span className="grey-t">
                  {t("utils_status")}
                </span>
                <Space/>
              </div>
              <Checkbox
                active={currentLesson.visible}
                text={t("utils_visible")}
                onClick={()=>editLesson("visible", !currentLesson.visible)}
              />
            </div>

          </div>


          <div className="flex">

            <Dropdown
              active={currentLesson.type}
              displayField={"name"}
              list = {[
                { name : t("lesson_type_file"), id : "file" },
                { name : t("lesson_type_link"), id : "link" }, 
                { name : t("lesson_type_text"), id : "text" }
              ]}
              onSelect={(e) => editLesson( "type", e.id )}
              status={props.lesson.status}
              title={t("utils_type")}
              value={t("lesson_type_" + currentLesson.type)}
            />

            <TextInput
              onChange={(e) => editLesson("duration", e.value) }
              width={150}
              title={t("utils_duration")}
              value={currentLesson.duration}
            />

          </div>

          

        </div>

        <div>

          <ImagePicker
            id={currentLesson.id}
            imageId={currentLesson.image ? currentLesson.id : null}
            model={"Lessons"}
            onDelete={()=>editLesson("image", false)}
            onSave={()=>editLesson("image", true)}
          />

        </div>


      </div>

      <div style={{ width:"732px" }} />

      <div className="text-input-title">
        <span className="grey-t">
          {t("utils_description") + " " + getFlagEmoji(currentLanguage)}
        </span>
        <Space/>
      </div>

      <textarea
        className="text-area"
        style={{ height : "60px" }}
        onChange={(e) => editLesson("description", e.target.value )}
        name="description"
        value={currentLesson.getValue("description", currentLanguage)}>
      </textarea>

      {currentLesson.type === "file" &&
      <div>
        <div className="text-input-title">
          <span className="grey-t">
            {t("lesson_file") }
          </span>
          <Space/>
        </div>
        {currentLesson.file?
        <div>
          {currentLesson.file["type"].substring(0,5) === "video" ?
          <video /*width="240"*/ height="160" controls>
            <source
              src={currentLesson.filePath}
              type={currentLesson.file["type"]}
            />

            <track kind="captions"/>

            {t("video_not_supported")}
          </video>
          :
          <div
            className = "flex"
            onClick={() => window.open(currentLesson.filePath, "_blank")}
            style={{
              backgroundColor : "#c9c9c9",
              color : "white",
              width : "120px",
              height : "160px"
            }}
          >
            {currentLesson.file["extension"]}
          </div>
          }
          <Link onClick={() => editLesson("file", null)}>
            {t("utils_delete")}
          </Link>
        </div>
        :
        <UploadButtonWidget
          acceptedFiles = "video/*,.pdf"
          onUpload={(file) => uploadFile(file)}
          maxSize={1000000}
        />
        }
      </div>
      }

      {currentLesson.type === "link" &&
      <TextInput
        isFullWidth
        onChange={(e) => editLesson("link", e.value) }
        title={t("lesson_link")}
        placeholder = "http://exemple.com"
        value={currentLesson.link?currentLesson.link:""}
      />
      }

      {currentLesson.type === "text" &&
      <div>

        <div className="text-input-title">
          <span className="grey-t">
            {t("lesson_text") + " " + getFlagEmoji(currentLanguage)}
          </span>
          <Space/>
        </div>

        <HtmlEditor
          content={currentLesson.getContent(currentLanguage)}
          onChange={(e) => editLesson("text", e)}
        />

      </div>
      }

      <div className="height-20"/>

    </Modal>
  )

}

const mapStateToProps = state => ({
  _session : state._session,
  lesson : state.lesson
})

export default connect(mapStateToProps)(withTranslation()(EditLessonModal))