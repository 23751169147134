/** 
 * TOPIC-REPARTITON
 * component for topic repartition bar
 */

import ReactTooltip from 'react-tooltip'
import getRepartitionColor from "@/utils/get-repartition-color.utils"
import { Session } from "@/redux/_session.types"
import { connect } from "react-redux"
import { WithTranslation, withTranslation } from "react-i18next"
import { useEffect, useState } from "react"
import Space from './space'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { getRepartitionRatio } from '@/utils/satisfaction.utils'
import { AccountOptions } from '@/redux/account.types'
import { responseLabel } from '@/utils/response-label.utils'

interface StateProps{
  _session: Session
}

interface OwnProps{
  id: string
  repartition: number[]
  noAnswers: number
  reverse?: boolean
  isLarge?: boolean
  isPreview? : boolean
  displayWidth?: number
}

type Props = StateProps & OwnProps & WithTranslation

function TopicRepartition(props:Props) {
  const { t } = props

  const displayWidth: number = props.displayWidth ? props.displayWidth : 5

  const [id, setId] = useState<string>(props.id)

  const [responseCount, setResponseCount] = useState<number>(props.repartition.length)

  const [repartition, setRepartition] = useState<number[]>(props.repartition)

  const [noAnswers, setNoAnswers] = useState<number>(props.noAnswers)

  const [reverse, setReverse] = useState<boolean | undefined>(props.reverse)

  useEffect(() => {
    setId(props.id)
  }, [props.id])
  
  useEffect(() => {
    setResponseCount(props.repartition.length)
    setRepartition(props.repartition)
  }, [props.repartition])

  useEffect(() => {
    setNoAnswers(props.noAnswers)
  }, [props.noAnswers])

  useEffect(() => {
    setReverse(props.reverse)
  }, [props.reverse])

  //Get first or last item of the repartition bar for the question
  //In order to apply correct border radius rule
  //For last item detect last repartition item that is not null
  function getRepartitionQuestionItem(isFirst:boolean){
    if (isFirst){
      return repartition.findIndex(x => x > 0)
    }else{

      let index = 0
      for (let i = responseCount; i >= 0; i--) {
        const item = repartition[i]
        if (item > 0){
          index = i
          break
        }
      }

      return index

    }
  }

  return (
    <>
      { repartition.reduce((partialSum: number, a: number) => partialSum + a, 0) > 0 ?
      <div className="flex"
        style={{
          minWidth : props.isPreview ? 700 : undefined
        }}>

        { //question.repartition.map((count, j) => 
        [...Array(responseCount).keys()].map((j: number) => 
        <div key={j}
          className="flex flex-dcol details-question-item"
          style={{ width : getRepartitionRatio(repartition[j], [...repartition, noAnswers]) + '%' }}>

          <div className={"flex flex-dcol" + (props.isPreview ? " details-question-count-sm" : " details-question-count")}>
            <div className="flex2"/>

            { getRepartitionRatio(repartition[j], [...repartition, noAnswers]) > displayWidth &&
            <div>
              <div className="grey-t">
                <span>{repartition[j]}</span>
              </div>
              <div className="details-question-ratio">
                <b>{getRepartitionRatio(repartition[j], [...repartition, noAnswers]).toFixed(1)} %</b>
              </div>
            </div>
            }

            <Space/>
          </div>
          
          <div data-for={id + '-' + j}
            data-tip={t("user_questions_count", {
              count : repartition[j],
              s : repartition[j] > 1 ? "s" : "",
              ratio : getRepartitionRatio(repartition[j], [...repartition, noAnswers]).toFixed(1)
            })}
            style={{
              height : (props.isLarge ? '10px' : '5px'),
              borderRadius : (j === getRepartitionQuestionItem(true) ? '8px 0px 0px 8px' : (j === getRepartitionQuestionItem(false) ? '0px 8px 8px 0px' : '')),
              backgroundColor : getRepartitionColor(j, responseCount - 1, false, new AccountOptions(), responseCount === 10)
            }}
          />
          <ReactTooltip id={id + '-' + j}/>

          { !props.isPreview &&
          <div className="details-question-label-container flex flex-dcol">
            <Space/>
            { responseCount === 10
            ?
            getRepartitionRatio(repartition[j], [...repartition, noAnswers]) > displayWidth * 2 &&
            <div style={{ color : getRepartitionColor(j, responseCount - 1, false, new AccountOptions(), true) }}
              className="details-question-label">
              {responseLabel(reverse ? responseCount - j : j + 1, responseCount)}
            </div>
            :
            getRepartitionRatio(repartition[j], [...repartition, noAnswers]) > displayWidth &&
            <div style={{ color : getRepartitionColor(j, responseCount - 1, false, new AccountOptions(), false) }}
              className="details-question-label">
              {responseLabel(reverse ? responseCount - j : j + 1, responseCount)}
            </div>
            }
            <div className="flex2"/>
          </div>
          }

        </div>
        )}

        { !props._session.dashboardDisplaySettings.hideNoAnswers &&
        <>
          <div className="width-10" />

          <div
            className="flex flex-dcol details-question-item"
            style={{ width : getRepartitionRatio(noAnswers, [...repartition, noAnswers]) + '%' }}
          >
            <div className={"flex flex-dcol" + (props.isPreview ? " details-question-count-sm" : " details-question-count")}>
              <div className="flex2"/>
              { getRepartitionRatio(noAnswers, [...repartition, noAnswers]) > displayWidth &&
              <div>
                <div className="grey-t">
                  <span>{noAnswers}</span>
                </div>
                <div className="details-question-ratio">
                  <b>{getRepartitionRatio(noAnswers, [...repartition, noAnswers]).toFixed(1)} %</b>
                </div>
              </div>
              }
              <Space/>
            </div>

            <div  data-for={"nspp-" + id}
              data-tip={t("user_questions_count", {
                count : noAnswers,
                s : noAnswers > 1 ? "s" : "",
                ratio : getRepartitionRatio(noAnswers, [...repartition, noAnswers]).toFixed(1)
              })}
              style={{ 
                backgroundColor : "#d5d4d4",
                borderRadius : 8,
                width : "100%",//getRepartitionRatio(noAnswers, [...repartition, noAnswers]),
                height : (props.isLarge ? '10px' : '5px')
              }}
            />
            <ReactTooltip id={"nspp-" + id}/>

            { !props.isPreview &&
            <div className="details-question-label-container flex flex-dcol">
              <Space/>

              { noAnswers > 0 &&
              <div className="flex grey-t">

                <div className="details-question-label">
                  { getRepartitionRatio(noAnswers, [...repartition, noAnswers]) > displayWidth &&
                  t("question_nspp_dashboard") + " "
                  }
                </div>

                { !props.isPreview &&
                <FontAwesomeIcon icon={faInfoCircle}
                  className="_hover"
                  data-for={"nspp-" + id}
                  data-tip={t("question_nspp_dashboard_help", {
                    response : props._session.accountId === '891ec62a-f979-4f27-8668-a734d34e1d3c'
                    ?
                    t("question_nspp_believe")
                    :
                    t("question_nspp")
                  })}
                />
                }
                
              </div>
              }
              
              <div className="flex2"/>
            </div>
            }

          </div>
        </>
        }

      </div>
      :
      <div>
        <p
          style={{ margin : '22px 0px' }} 
          className="grey-t"
        >
          <b>
            {t("questions_no_results", {
              count : props._session.participationMin
            })}
          </b>
        </p>
      </div>
      }

    </>
  )
}

const mapStateToProps = state => ({
  _session: state._session
})

export default connect(mapStateToProps)(withTranslation()(TopicRepartition))