/** 
 * DELETE-USERS.MODAL
 * Delete all users for one account
 */
import Modal from "@/components/modal"
import { connect } from "react-redux"
import { withTranslation, WithTranslation } from "react-i18next"
import { UserState } from "@/redux/user.types"
import { store } from "@/index"
import { userDestroyAll, userStatus } from "@/redux/user.actions"
import { STATUS_LOADING, STATUS_LOAD_ERROR } from "@/redux/_status.types"
import EnterCodeWidget from "@/widgets/enter-code.widget"
import PageLoader from "@/components/page-loader"
import Checkbox from "@/components/checkbox"
import { useState } from "react"

interface StateProps extends WithTranslation{
  user : UserState
}

interface OwnProps{
  onClose : Function
  onDeleteSuccess : Function
}

type Props = StateProps & OwnProps

function DeleteUsersModal(props:Props) {
  const { t } = props

  const [keepAdmins, setKeepAdmins] = useState<boolean>(true)
  const [keepRoles, setKeepRoles] = useState<boolean>(true)

  //Proceed
  async function next(){
    store.dispatch(userStatus(STATUS_LOADING))
    const rolesToKeep: string = keepRoles ? "all" : ( keepAdmins ? "admin" : "none" )

    const usersResponse: any = await store.dispatch(userDestroyAll(rolesToKeep))

    if (usersResponse.error){
      store.dispatch(userStatus(STATUS_LOAD_ERROR))
    }else{
      props.onDeleteSuccess()
    }
  }

  return (
    <Modal onClose={props.onClose}
      title={t("users_delete")}>

      <PageLoader status={props.user.status}>

        <p>
          {t("users_delete_warning")}
        </p>

        <Checkbox active={keepRoles} onClick={keepRoles ? ()=>setKeepRoles(false) : ()=>{setKeepRoles(true); setKeepAdmins(true)} } text={t("keep_special_roles")}/>

        <div className={keepRoles ? "cards-inactive" : ""}>
          <Checkbox active={keepAdmins} onClick={()=> keepRoles ? {} : setKeepAdmins(!keepAdmins)} text={t("keep_admins")}/>
        </div>

        <EnterCodeWidget onCancel={props.onClose}
          onNext={next}
          status={props.user.status}
        />
      
      </PageLoader>

    </Modal>

  )

}

const mapStateToProps = state => ({
  user : state.user
})

export default connect(mapStateToProps)(withTranslation()(DeleteUsersModal))