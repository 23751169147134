/** 
 * COLOR-PICKER
 * Select a color to display
 */

import { faCaretDown, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TextInput from '@/components/text-input';
import { useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import ContextMenu from '@/components/context-menu';
import { HexColorPicker } from "react-colorful";
import Space from '@/components/space';
import Button from '@/components/button';
import ImageColorsPickerWidget from './image-colors-picker.widget';

interface OwnProps{
  colors? : string[] //List of available colors
  selectedColor? : string | null//Color selected
  isAlignedLeft? : boolean //Left align context menu
  isCustom? : boolean //Can the user define his own color (code hex)
  isNullAllowed? : boolean //Allow null value
  onSelect : Function //Trigger action when color is selected
  onOpen? : Function
  title? : string //Text to display
  imageUrl? : string
}

type Props = OwnProps & WithTranslation

//Default color white
const COLOR_WHITE : string = "#FFFFFF"

const PICK_LOGO_COLORS: string = "PICK_LOGO_COLORS"

function ColorPickerWidget(props:Props) {
  const { t } = props

  const [currentColor, setCurrentColor] = useState<string>(props.selectedColor ? props.selectedColor : (props.isNullAllowed ? "" : COLOR_WHITE))
  const [currentContext, setCurrentContext] = useState<string | null>(null)
  const [isOpen, setIsOpen] = useState(false)
  const [colors] = useState<string[]>(props.colors ? props.colors : [])

  useEffect(()=>{
    if(props.selectedColor){
      setCurrentColor(props.selectedColor)
    }
  }, [props.selectedColor])

  function close(save){
    setIsOpen(false)
    if (save){
      props.onSelect(currentColor)
    }
  }

  function getClassName(){
    let className = "color-picker"
    if (props.isCustom){
      className += " color-picker-custom"
    }
    return className
  }

  function decToHexColors(color: number[]){
    let hexColor: string = "#"

    color.forEach((x: number)=>{

      if(x < 16){
        hexColor += "0"+x.toString(16)
      }
      else{
        hexColor += x.toString(16)
      }

    })

    return hexColor.slice(0, 7)
  }
  
  function openColors(){
    setIsOpen(true)
    if (props.onOpen){
      props.onOpen()
    }
  }

  function selectColor(color:string){
    setCurrentColor(color)
    if (!props.isCustom){
      setIsOpen(false)
      props.onSelect(color)
    }
  }

  //Select color
  function confirm(){
    setIsOpen(false)
    props.onSelect(currentColor.length > 0 ? currentColor:(props.isNullAllowed? null : COLOR_WHITE))
  }

  return (
    <div className={getClassName()}>

      <div className="rel"
        style={{
          right : props.isAlignedLeft ? "230px" : ""
        }}>
        { isOpen &&
        <ContextMenu onClose={()=>close(true)}>

          <div className="color-picker-context-title">
            <b>{props.title && props.title}</b>
          </div>

          <div className="flex">

            {colors.length > 0 &&
            <div>
              <div className="flex flex-wrap color-picker-item-container">
                {colors.map((color: string) => 
                <div key={color}
                  className="color-picker-item flex"
                  onClick={() => selectColor(color)}
                  style={{ backgroundColor : color }}>
                  { currentColor === color &&
                  <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                  }
                </div>
                )}

                { props.isNullAllowed &&
                <div className="color-picker-item color-picker-item-white flex grey-b"
                  onClick={() => setCurrentColor("")}>
                  { currentColor === "" &&
                  <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>
                  }
                </div>
                }

              </div>
            </div>
            }

            { props.isCustom &&
            <div style={{ marginRight : '30px' }}>

              <HexColorPicker color={currentColor} onChange={setCurrentColor} />
              
              <div>
                <TextInput
                  onChange={(e:any) => setCurrentColor(e.value)}
                  value={currentColor}
                  title={t("color_code")}
                />
              </div>

            </div>
            }

          </div>

          { currentContext === PICK_LOGO_COLORS &&
          <div className="rel">
            <ContextMenu
              positionBottom
              onClose={()=>setCurrentContext(null)}
            >

              <div className="color-picker-context-title">
                <b>{t("get_colors_from_logo")}</b>

                <p>{t("pick_color_avatar")}</p>
              </div>

              <div style={{padding: "4px 28px"}}>
                <ImageColorsPickerWidget url={props.imageUrl!} onSave={(color: number[])=>{
                  setCurrentColor(decToHexColors(color));
                  setCurrentContext(null)}}
                />
              </div>
            </ContextMenu>
          </div>
          }

          { props.imageUrl &&
          <div style={{padding:"10px 28px"}}>
            <u className="_hover grey-t" onClick={()=>setCurrentContext(PICK_LOGO_COLORS)}>{t("get_colors_from_logo")}</u>
          </div>
          }

          { props.isCustom &&
          <div className="flex"
            style={{ margin : '12px 26px 4px 0px' }}>
            <Space/>
            <Button onClick={() => close(false)}>
              {t("utils_cancel")}
            </Button>
            <Button onClick={confirm} 
              className="primary">{t("utils_next")}
            </Button>
          </div>
          }

        </ContextMenu>
        }
      </div>

      <p className="text-input-title grey-t">
        {props.title ? props.title : t("color")}
      </p>

      <div onClick={openColors} className="flex color-picker-selector">
        <div className={ [COLOR_WHITE, null].indexOf(currentColor) > -1 ? "color-picker-item color-picker-item-white flex" : "color-picker-item flex" }
          style={{ backgroundColor : props.selectedColor ? props.selectedColor : currentColor }}>
        </div>
        <FontAwesomeIcon onClick={openColors} icon={faCaretDown}></FontAwesomeIcon>
      </div>

    </div>
  )
}

export default withTranslation()(ColorPickerWidget)