/**
 * GET-AXIS-FROM-TOPIC
 * Format list of axis from list of topics
 */

import { compact, uniqBy } from "lodash"
import { Topic } from "@/redux/topic.types";

const getAxisFromTopic = (topics:Topic[]) => {
  return uniqBy(compact(topics.map(x => x.Axis)), "id")
}

export default getAxisFromTopic