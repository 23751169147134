/** 
 * PAGE-LOADER
 * Container of page with loading status
 */
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { STATUS_LOADING } from "@/redux/_status.types"

interface OwnProps{
  children? : any
  minHeight? : number
  isFlex? : boolean
  status : string | null //Status
}

function PageLoader(props:OwnProps) {

  return (
    <div className="flex flex-dcol page-loader"
      style={{
        minHeight : props.minHeight ? props.minHeight + "px" : "80px"
      }}>

      { props.status === STATUS_LOADING
      ?
      <div className="load-container">
        <FontAwesomeIcon icon={faCircleNotch} spin/>
      </div>
      :
      <div className={props.isFlex ? "flex" : ""}>
        { props.children }
      </div>
      }

    </div>

  )

}

export default PageLoader