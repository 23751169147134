/** 
 * BOOKMARK
 * Context saved for a survey
 */

import { DashboardFilters } from "./filter.types"

export class BookmarkOptions{
  urlParams : string = ""
  dashboardFilters : DashboardFilters = new DashboardFilters()
  dashboardFiltersCompare : DashboardFilters | null = null
  heatmapFilterName : string = ""
  heatmapResultsType : string = ""
  page : string = ""

  constructor(bookmarkOptions: Partial<BookmarkOptions> = {}){
    bookmarkOptions.dashboardFilters = new DashboardFilters(bookmarkOptions.dashboardFilters)
    Object.assign(this, bookmarkOptions)
  }

}

export class Bookmark{
  id : string = ""
  name : string = ""
  options : BookmarkOptions = new BookmarkOptions()
  selected : boolean = true
  UserId: string = ""

  constructor(bookmark: Partial<Bookmark> = {}){
    bookmark.options = new BookmarkOptions(bookmark.options)
    Object.assign(this, bookmark)
  }

}

export interface BookmarkState{
  active : Bookmark
  list : Bookmark[]
  status : string
}

export const BOOKMARK_ACTIVATE = 'BOOKMARK_ACTIVATE'
export const BOOKMARK_ADD = 'BOOKMARK_ADD'
export const BOOKMARK_GET = 'BOOKMARK_GET'
export const BOOKMARK_INIT = 'BOOKMARK_INIT'
export const BOOKMARK_REMOVE = 'BOOKMARK_REMOVE'
export const BOOKMARK_SELECT = 'BOOKMARK_SELECT'
export const BOOKMARK_STATUS = 'BOOKMARK_STATUS'
export const BOOKMARK_SWAP = 'BOOKMARK_SWAP'