/**
 * Learning
 */

import env from "@/env"
import { sortBy, sum } from "lodash"
import i18n from "@/translate/i18n"
import { Lesson } from "./lesson.types"
 
export class Learning{
  id : string = ""
  name : any = {}
  description : any = {}
  image : string | null = null
  new : boolean = false
  mediasOrder : string[] = []
  visible : boolean = false
  fromSupervisor : boolean = false
  shared : boolean = false
  languages : string[] = [i18n.language]
  emailContent : any = {}
  attributesInvited : string[] = []       //uuid of concerned attributes
  invitationMode : string = "all"         //how learning will be shared ['all', 'users', 'attributes']
  usersInvitationsOptions : any = {}      //
  usersInvited : string[] = []            //uuid of concerned users
  Lessons : Lesson[] = []
  createdAt : string = ""
  updatedAt : string | null = null
  AccountId : string | null = null

  constructor(learning: Partial<Learning> = {}){
    if (!learning) learning = new Learning()
    learning.Lessons = learning.Lessons ? learning.Lessons.map(x => new Lesson(x)) : []
    Object.assign(this, learning)
  }

  get defaultLanguage():string{
    return this.isLangSupported(i18n.language)?i18n.language:this.languages[0]
  }

  get label():string{
    return this.name[this.defaultLanguage]
  }

  get lessonsSorted(): Lesson[]{
    return sortBy(this.Lessons, (item: Lesson) => {
      return this.mediasOrder.indexOf(item.id)
    })
  }

  get picture():string{
    return this.image ? env.REACT_APP_URL_SPACE.concat("/Learnings/", this.image, ".png?" + new Date().getTime()) : env.REACT_APP_URL_SPACE.concat("/Lessons/default.png?" + new Date().getTime())
  }

  get totalDuration():number{
    return sum(this.Lessons.map((x:Lesson)=>x.duration))
  }

  isLangSupported(lang:string):boolean{
    return this.languages.indexOf(lang)>-1
  }

  //return value of field in corresponding language (for multilang inputs only)
  //if replace, return text in default language else return empty string
  getValue(field : string, lang : string):string{
    if(this[field] && this[field][lang]){
      return this[field][lang]
    }
    else{
      return ""
    }
  }

  getLessonIndex(lessonId:string):number{
    return this.Lessons.findIndex((x:Lesson)=>x.id === lessonId)
  }

}

export interface LearningState{
  active : Learning
  list : Learning[]
  status : string
}

export const LEARNING_ACTIVATE : string = "LEARNING_ACTIVATE"
export const LEARNING_ADD : string = "LEARNING_ADD"
export const LEARNING_EDIT : string = "LEARNING_EDIT"
export const LEARNING_EDIT_LESSON : string = "LEARNING_EDIT_LESSON"
export const LEARNING_GET : string = "LEARNING_GET"
export const LEARNING_INIT : string = "LEARNING_INIT"
export const LEARNING_REMOVE : string = "LEARNING_REMOVE"
export const LEARNING_STATUS : string = "LEARNING_STATUS"