/**
 * GET-LABEL
 * Get label for template element according language
 */

import { store } from "@/index"
import { SESSION_DEFAULT_LANGUAGES } from "@/redux/_session.types"
import i18n from "@/translate/i18n"

const getLabel = (name:any) => {

  const initialLanguage = store.getState()._session.userInitialLanguage
  let label = SESSION_DEFAULT_LANGUAGES.indexOf(initialLanguage) === -1 ? name[initialLanguage] : name[i18n.language]
  
  if (label){
    return label
  }else{

    //Fallback english if exist
    if (name["en"]){
      return name["en"]
    }
    //Search first other language
    else{

      label = ""
      for (const [key] of Object.entries(name)) {
        label = name[key]
        break
      }
  
      return label

    }

  }
}

export default getLabel