/** 
 * DASHBOARD-RESULTS-AXES.WIDGET
 * radar chart with axes scores
 */

import { withTranslation, WithTranslation } from "react-i18next"
import { connect } from "react-redux"
import { useEffect, useState } from "react";
import { Session } from "@/redux/_session.types";
import { Axis } from "@/redux/axis.types";
import Plot from 'react-plotly.js';

interface StateProps extends WithTranslation{
  _session : Session
}

interface OwnProps{
  currentAxes: Axis[]
  onClick?: Function
}

type Props = StateProps & OwnProps

function DashboardResultsAxesWidget(props: Props) {
  const { t } = props

  const [data, setData] = useState<Plotly.Data[]>([])

  function click(axisId: string){
    if(props.onClick){
      props.onClick(axisId)
    }
  }

  useEffect(()=>{

    function updateData(newAxes: Axis[]){

      const filteredAxes: Axis[] = newAxes.filter((x: Axis) => x.note)
  
      if(filteredAxes.length > 0){

        const r: string[] = [...filteredAxes.map((x: Axis) => x.getNoteLabel(props._session.dashboardDisplaySettings.dashboardDisplayMode), (filteredAxes[0].note/10).toFixed(1))]
        const hovertext: string[] = props._session.dashboardDisplaySettings.dashboardDisplayMode === "note" ? r.map((score: string) => score+"/10") : r.map((score: string) => score)

        setData([{
          type: "scatterpolar",
          customdata: [...filteredAxes.map((x: Axis)=>x.id), filteredAxes[0].id],
          r,
          theta: [...filteredAxes.map((x: Axis, i: number) => t("axis") + (i+1)), t("axis") + "1"],
          text: [...filteredAxes.map((x: Axis) => x.label), filteredAxes[0].label],
          fill: "toself",
          hoverinfo:"text",
          hovertext,
          fillcolor: props._session.accountColors.brandPrimary,
          opacity: 0.8,
          name: t("axes"),
          line: {
            color: "#111C2B"
          },
          mode:"text+markers",
          textposition: "top center",
          marker: {
            size: 10,
            color: [...filteredAxes.map((x: Axis)=>x.color), filteredAxes[0].color],
            symbol: "circle-dot"
          },
        }])
      }
    }

    if(props.currentAxes.length > 0){
      updateData(props.currentAxes)
    }


  }, [
    t,
    props.currentAxes,
    props._session.accountColors.brandPrimary,
    props._session.dashboardDisplaySettings.dashboardDisplayMode
  ])


  return(

    data.length > 0 ?
    <Plot
      divId="plotly-radar-chart"
      useResizeHandler
      onClick={(e:any)=>click(e.points[0].customdata)}
      data={data}
      
      layout={{
        autosize: true,
        legend:{
          bgcolor: "#789654"
        },
        font: {
          color: "#111C2B",
          family: "Open Sans"
        },
        polar: {
          radialaxis: {
            visible: false,
            range: props._session.dashboardDisplaySettings.dashboardDisplayMode === "note" ? [0, 10] : [0, 100]
          }
        },
        margin:{
          //pad: 100,
          l: 30,
          r: 30,
          t: 0,
          b: 0
        },

        width: 400,
        height: 400,
        showlegend: false
      }}

      config={{
        displayModeBar: false
      }}
    />
    :
    <div style={{height: 400, width: 400}}/>

  )

}

const mapStateToProps = state => ({
  _session : state._session
})

export default connect(mapStateToProps)(withTranslation()(DashboardResultsAxesWidget))