/** 
 * UPGRADE.WIDGET
 * Text to explain feature to unlock
 */

import { useState } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import CardContainer from "@/components/card-container"
import CardButton from "@/components/card-button"
import { faInfoCircle, faPhone } from "@fortawesome/free-solid-svg-icons"
import Card from '@/components/card'
import Modal from '@/components/modal'
import Space from '@/components/space'
import ContactModal from '@/modals/contact.modal'
 
interface OwnProps extends WithTranslation{
  feature : string
  isAppLevel? : boolean
  withNoImages? : boolean
}

const MODAL_CONTACT = "MODAL_CONTACT"

function UpgradeWidget(props:OwnProps){
  const { t } = props
  
  const [currentImage, setCurrentImage] = useState<string | null>(null)
  const [currentModal, setCurrentModal] = useState<string | null>(null)

  return(
    <div className="flex upgrade-widget">

      <Space/>

      <div style={{ maxWidth : '1000px' }}>

        { currentModal === MODAL_CONTACT &&
        <ContactModal onClose={() => setCurrentModal(null)}/>
        }

        { currentImage &&
        <Modal isLarge
          isCloseButtonVisible
          onClose={() => setCurrentImage(null)}>
          <img alt={props.feature} src={require("@/assets/modules/" + props.feature + "_" + currentImage + ".png")} width="100%"/>
          <p className="grey-t">
            {t("upgrade_image_" + props.feature + "_" + currentImage)}
          </p>
        </Modal>
        }

        <div className="flex">

          <div className="flex1">

            { props.isAppLevel
            ?
            <h3>
              {t("upgrade_title_app")}
            </h3>
            :
            <h3>
              {t("upgrade_title_module", {
                feature : t("module_" + props.feature)
              })}
            </h3>
            }

            <p>
              {t("upgrade_text_" + props.feature)}
            </p>

            <CardContainer isAlignedLeft={!props.withNoImages}>
              <CardButton title={t("utils_contact")}
                isCta
                onClick={() => setCurrentModal(MODAL_CONTACT)}
                icon={faPhone}>
              </CardButton>
              <CardButton title={t("utils_find_more")}
                onClick={() => window.open("https://www.id-tree.com/tarifs")}
                icon={faInfoCircle}>
              </CardButton>
            </CardContainer>

          </div>

          { !props.withNoImages &&
          <div className="flex1 flex">
            <div style={{ margin : 'auto' }}>
              <img src={require("@/assets/" + props.feature + ".png")} width="200" alt={props.feature}/>
            </div>
          </div>
          }


        </div>

        <div className="height-20"/>

        { !props.withNoImages &&
        <div className="flex">
          
          <div className="flex1 upgrade-widget-card">
            <Card className="flex">
              <div>
                <img src={require("@/assets/modules/" + props.feature + "_1.png")}
                  alt={props.feature}
                  onClick={() => setCurrentImage("1")}>
                </img>
              </div>
              <span className="grey-t">
                {t("upgrade_image_" + props.feature + "_1")}
              </span>
            </Card>
          </div>

          <div className="width-20"/>

          <div className="flex1 upgrade-widget-card">
            <Card className="flex">
              <div>
                <img src={require("@/assets/modules/" + props.feature + "_2.png")}
                  alt={props.feature}
                  onClick={() => setCurrentImage("2")}>
                </img>
              </div>
              <span className="grey-t">
                {t("upgrade_image_" + props.feature + "_2")}
              </span>
            </Card>
          </div>

        </div>
        }

      </div>

      <Space/>

    </div>
  )
}

export default withTranslation()(UpgradeWidget)
