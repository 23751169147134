/**
 * 
 * DASHBOARD-AXES-OVERVIEW.WIDGET
 * 
 */

import { connect } from "react-redux"
import { Axis } from "../redux/axis.types"
import { Session } from "../redux/_session.types"
import { useEffect, useState } from "react"
import env from "../env";

interface StateProps{
  _session: Session
}

interface OwnProps{
  axes: Axis[]
  onClick?: Function
  isFullWidth? : boolean
  isPreview? : boolean
}

type Props = StateProps & OwnProps

function DashboardAxesOverviewWidget(props: Props){

  const [axes, setAxes] = useState<Axis[]>(props.axes)
  const [axesTextLength, setAxesTextLength] = useState<number>(0) //If text too long hide images

  useEffect(() => {
    setAxes(props.axes)
    //Set max text width
    setAxesTextLength(Math.max(...props.axes.map(x => x.label.length)))
  }, [
    props.axes
  ])

  function click(axis: Axis){
    if(props.onClick){
      props.onClick(axis)
    }
  }

  //Error image
  function onErrorImage(axis){
    axis.image = null
    setAxes(axes.map(x => x.id === axis ? axis : x))
  }
 
  return (
    <div className={!props.isFullWidth ? "" : "flex flex-wrap"}
      style={{ 
        height : props.isPreview && !props.isFullWidth ? 320 : undefined,
        width : props.isPreview ? (props.isFullWidth ? 580 : 290) : undefined
      }}>
      { // here new widget
      axes.filter(x => x.note).map((axis: Axis) => 
      <div className="flex" 
        style={{ width: props.isFullWidth ? '50%' : '100%' }}
        key={axis.id}>

        <div className="flex1 podium-text flex">

          { axesTextLength < 38 &&
          (
            axis.image
            ?
            <img alt="axis" className="results-axis-img"
              onError={() => onErrorImage(axis)}
              src={env.REACT_APP_URL_SPACE.concat("/Axes/", axis.image, ".png")}>
            </img>
            :
            <div style={{ backgroundColor : axis.color }} className="podium-pastille"></div>
          )
          }

          <div onClick={() => click(axis)}
            style={{
              marginLeft : 16
            }}
            className="podium-text-label flex1">
            {axis.label}
          </div>

        </div>

        <div className="podium-note"
          style={{ 
            color : axis.color,
            paddingRight : 12
          }}>
          <b>
            {axis.getNoteLabel(props._session.dashboardDisplaySettings.dashboardDisplayMode)}
          </b>
        </div>

      </div>
      
      )}

    </div>
  )
}

const mapStateToProps = state => ({
  _session : state._session,
})

export default connect(mapStateToProps)(DashboardAxesOverviewWidget)