/**
 * REPORT-LIST.ROUTE
 * Home page for the report module
 */

import NavApp from "@/nav/nav.app";
import ReportListWidget from "@/widgets/report-list.widget";

function ReportListRoute(){
  return(
    <NavApp>
      <ReportListWidget/>
    </NavApp>
  )
}

export default ReportListRoute