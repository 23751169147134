/**
 * MESSAGE.TYPES
 */

import { formatDate } from "@/utils/format-date.utils"

export const MESSAGE_CEIL_COUNT_SUMMARY : number = 20 //Messages minimum to display AI synthesis

export class Message{
  aid : string = ""
  color : string | null = null
  text : string = ""
  date : Date = new Date()
  fkQuestion : string | null = ""

  constructor(message: Partial<Message> = {}){
    Object.assign(this, message)
  }

  //Date (label)
  get dateLabel():string{
    return formatDate(this.date, false, false)
  }

}

export interface MessageState{
  count : number //Number of message
  list : Message[] //List of messages
  status : string //Status of messages
}

export const MESSAGE_GET = 'MESSAGE_GET'
export const MESSAGE_GET_COUNT = 'MESSAGE_GET_COUNT'
export const MESSAGE_STATUS = 'MESSAGE_STATUS'