/** 
 * ATTRIBUTE.REDUCERS
 * Edit attribute state
 */

import { v4 as uuid } from "uuid";
import { Attribute, AttributeState, ATTRIBUTE_ACTIVATE, ATTRIBUTE_ADD, ATTRIBUTE_COMPARE_GET, ATTRIBUTE_EDIT, ATTRIBUTE_GET, ATTRIBUTE_INIT, ATTRIBUTE_STATUS, ATTRIBUTE_REMOVE } from "./attribute.types";
import { STATUS_LOADED } from "./_status.types";

let active:Attribute

const initialState: AttributeState = {
  active : new Attribute(),
  list : [],
  compareList : [],
  status : STATUS_LOADED
}

export default function attributeReducer(
  state = initialState,
  action: any
): AttributeState {
  switch (action.type) {

    //activate attribute
    case ATTRIBUTE_ACTIVATE:
      return Object.assign({}, state, {
        active : new Attribute(action.payload.attribute)
      })

    //add new attribute
    case ATTRIBUTE_ADD:
      active = new Attribute(action.payload.attribute)
      active.id = uuid()
      return Object.assign({}, state, {
        active,
        list : [active, ...state.list]
      })

    //Save list of attribute in the store
    case ATTRIBUTE_COMPARE_GET:
      return {
        ...state,
        compareList : action.payload.attributes.map((x) => new Attribute(x))
      }

    //editing attribute
    case ATTRIBUTE_EDIT:
      active = Object.assign(state.active, { [action.payload.key] : action.payload.value })
      return Object.assign({}, state, {
        active,
        list : state.list.map(x => x.id === active.id ? active : x)
      })

    //Save list of attribute in the store
    case ATTRIBUTE_GET:
      return {
        ...state,
        active : new Attribute(),
        list : action.payload.attributes.map((x) => new Attribute(x))
      }

    //Init store
    case ATTRIBUTE_INIT:
      return Object.assign({}, state, {
        active : new Attribute(state.active),
        list : state.list.map((x:any) => new Attribute(x)),
        status : state.status
      })

    //Remove attribute
    case ATTRIBUTE_REMOVE:
      return {
        ...state,
        list  : state.list.filter((attribute:Attribute) => { return attribute.id !== action.payload.attributeId })
      }

    //Set status
    case ATTRIBUTE_STATUS:
      return {
        ...state,
        status : action.payload.status
      }

    default:
      return state

  }

}