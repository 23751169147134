/**
 * GET-EXPORT-NAME
 * Get page name according to route and filteres
 */

import { store } from "@/index"
import { sortBy } from "lodash"
import { DEFAULT_FILTERS } from "@/redux/filter.types"

export {
  getFiltersName,
  getHeatmap,
  getPageName,
  getTopic,
  initBookmarkName
}

//Get name of the filters active
function getFiltersName(){
  const texts:string[] = []
  const activeFilters = document.getElementsByClassName("filters-item-active")
  for (const item of activeFilters) {
    if (item.textContent){
      texts.push(item.textContent)
    }
  }
  return texts.join(", ")
}

//Get heatmap options
function getHeatmap(t){

  const params = {
    filterName : "",
    filterNameLabel : "",
    resultsType : "",
    resultsTypeLabel : ""
  }

  if (window.location.pathname === "/dashboard/attributes"){

    //Search resultsType
    const resultsType = document.getElementsByClassName("dashboard-heatmap-results-type")
    if (resultsType.length){
      params.resultsType = resultsType[0].id
      params.resultsTypeLabel = t(resultsType[0].id)
    }

    //Search filter
    const filterName = document.getElementsByClassName("dashboard-heatmap-filter-name")
    if (filterName.length){
      params.filterName = filterName[0].id
      const defaultFilter = DEFAULT_FILTERS.find(x => x.id === params.filterName)
      if (defaultFilter){
        params.filterNameLabel = t("user_" + defaultFilter.id + (defaultFilter.id === "gender" ? "" : "_alt"))
      }else{
        params.filterNameLabel = filterName[0].id
      }
    }

  }
  
  return params

}

//Get name of the page
function getPageName(t){
  switch(window.location.pathname){
    case "/dashboard/overview":
      return t("dashboard_overview")
    case "/dashboard/questions":
      return t("questions")
    case "/dashboard/heatmap":
      return t("dashboard_heatmap")
    case "/dashboard/messages":
      return t("dashboard_messages")
    case "/dashboard/rps":
      return t("rps_title")
    case "/dashboard/compare":
      return t("dashboard_compare")
    default:
      return ""
  }
}

//Get topic
function getTopic(){
  const state = store.getState()

  const topic = { 
    name : "", 
    id : "" 
  }

  if (window.location.pathname === "/dashboard/questions"){
    const openTopics = state.topic.list.filter(x => x.open)
    if (openTopics.length){
      const openTopic = sortBy(openTopics, "dateOpen").reverse()[0]
      topic.name = openTopic.label
      topic.id = openTopic.id
    }
  }

  return topic

}

//Init name from page and options
function initBookmarkName(t){

  const names:string[] = []
  const filterName = getFiltersName()
  const topicName = getTopic().name
  const heatmapName = getHeatmap(t).filterNameLabel

  if (heatmapName.length){
    names.push(heatmapName)
  }

  if (topicName.length && filterName.length){
    names.push(topicName + " (" + filterName + ")")
  }else{

    if (topicName.length){
      names.push(topicName)
    }

    if (filterName.length){
      names.push(filterName)
    }

  }

  names.push(getPageName(t))

  return names.slice(0, 1).join(" - ")
  
}