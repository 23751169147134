/** 
 * DASHBOARD-RESULTS-TOPICS.WIDGET
 * tree with topics scores
 */

import { withTranslation, WithTranslation } from "react-i18next"
import { connect } from "react-redux"
import { useEffect, useState } from "react";
import { Session } from "@/redux/_session.types";
import { Topic } from "@/redux/topic.types";
import Space from "@/components/space";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudSun } from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from 'react-tooltip'
import { NavigateFunction, useNavigate } from "react-router-dom";

interface StateProps extends WithTranslation{
  _session : Session
}

interface OwnProps{
  currentTopics : Topic[],
  note: number,
  isPreview?: boolean
}

type Props = StateProps & OwnProps

const SIZE: number = 340

//Rotate border according number of questions
const ROTATION_DIFF: any = {
  4 : -36,
  5 : -18,
  6 : -6,
  7 : 2,
  8 : 8,
  9 : 13,
  10 : 17,
  11 : 20,
  12 : 23,
  13 : 26,
  14 : 28,
  15 : 29,
  16 : 31,
  17 : 32,
  18 : 34,
  19 : 34.5,
  20 : 35.5,
  21 : 36,
  22 : 37,
  23 : 37.5,
  24 : 38,
  25 : 39,
  26 : 39.5,
  27 : 39.5,
  28 : 40.5,
  29 : 41,
  30 : 41,
  31 : 42,
  32 : 42,
  33 : 42,
  34 : 42,
  35 : 42.5,
  36 : 42.5,
  37 : 43.5,
  38 : 44,
  39 : 44,
  40 : 44.2,
  41 : 44.5,
  42 : 44.5,
  43 : 45,
  44 : 45,
  45 : 45.5,
  46 : 45.5,
  47 : 46,
  48 : 46,
  49 : 46
}

function DashboardResultsTopicsWidget(props: Props) {
  const { t } = props
  const navigate: NavigateFunction = useNavigate()

  const [topics, setTopics] = useState<Topic[]>(props.currentTopics)

  //Note to display at the center of the circle
  const [note, setNote] = useState(props.note)
  
  //Deg and tang (to calculate radius)
  const [math, setMath] = useState({
    deg : 0,
    degC : 0,
    tan : 0
  })

  useEffect(()=>{

    if(props.currentTopics.length > 0){
      setTopics(props.currentTopics)
      setNote(props.note)
    }

  }, [
    props.currentTopics,
    props.note,
  ])

  useEffect(() => {
    //Math option
    //Detect angle for every branch of the tree
    function calculateMath(){
      if(props.currentTopics.length > 1){
        const deg: number = 360 / props.currentTopics.length
        const degC: number = (90 - deg) / 2
        const rad: number = degC * Math.PI / 180
        const tanDeg: number =  Math.tan(rad)
        const tan: number = 50 * tanDeg
    
        return {
          deg : deg,
          degC : degC,
          tan : tan
        }
      }
      else{
        return {
          deg : 0,
          degC : 0,
          tan : 0
        }
      }
    }

    setMath(calculateMath())
  }, [
    props.currentTopics.length
  ])


  //Click on a topic to scroll at him in the page questions
  function clickTopic(topic){
    if (!props.isPreview){
      navigate("/dashboard/questions?topic_id=" + topic.id)
    }
  }

  //Get background for branch of tree
  //> color depend of the axis or if the nightmare mode is active
  function getTopicBack(topic, i){
    let note = props._session.dashboardDisplaySettings.dashboardDisplayMode === "note" ? topic.note : topic.satisfaction
    let color = topic.axisColor
    let opacity
    
    //Color according the nightmare mode
    if (topic.hide){
      opacity = 1
      color = "white"
      note = 100
    }else{
      if (props._session.dashboardDisplaySettings.dashboardNightmareModeDisplay){
        if(note <= props._session.dashboardDisplaySettings.dashboardNightmareModeMin){
          color = props._session.dashboardDisplaySettings.dashboardNightmareColor1
        }
        else if(note >= props._session.dashboardDisplaySettings.dashboardNightmareModeMax){
          color = props._session.dashboardDisplaySettings.dashboardNightmareColor3
        }
        else{
          color = props._session.dashboardDisplaySettings.dashboardNightmareColor2
        }
      }else{
        if (note >= 80) opacity = 0.8
        else if (note >= 70) opacity = 0.7
        else if (note >= 60) opacity = 0.6
        else if (note >= 50) opacity = 0.5
        else opacity = 0.4
      }
    }

    //Force ceil about note
    if (note < 35) note = 35
    if (note >= 90) note = 90

    //Calc size of the card
    const margin = 150 - note * 1.5

    return {
      opacity : opacity,
      height : SIZE - margin * 2 + "px",
      width : SIZE - margin * 2 + "px",
      margin : margin + "px",
      borderRadius : (SIZE - margin * 2 ) / 2 + "px",
      backgroundColor : color,
      clipPath  : "polygon(50% 50%, 50% 50%, 100% " + (50 - math.tan) + "%, 100% 0%, " + (50 + math.tan) + "% 0%)",
      transform : "rotate(" + (i * math.deg - 39) + "deg)"
    }

  }
  
  //Style of the little color dot for a topic
  function getTopicPastille(topic, i){
    let rotation = ((i * math.deg) - 75.5) + math.degC

    if (topics.length === 5){
      rotation = rotation + 27
    }

    const style:any = { backgroundColor : topic.AxisId ? topic.axisColor : null }
    if (rotation < 150 || rotation > 340){
      style.right = "-16px"
    }else{
      style.left = "-16px"
    }

    return style

  }

  //Style of the border
  function getTopicBorder(i){
    const rotation = ((i * math.deg) - 75.5) + math.degC
    return { 
      width : SIZE + "px",
      marginTop : (SIZE / 2 - 1) + "px",
      transform : "rotate(" + (rotation - ROTATION_DIFF[topics.length]) + "deg)" 
    }
  }

  //Style of the text for all topics
  function getTopicText(topic, i){
    let rotation = ((i * math.deg) - 75.5) + math.degC;

    const style:any = {
      height : SIZE + "px",
      width : SIZE + "px"
    }

    if (topic.hide){
      style.color = "#dadada"
    }

    if (topics.length < 6){
      if (topics.length === 4){
        rotation = rotation + 33.3
      }else if (topics.length === 5){
        rotation = rotation + 27
      }
    }

    if (rotation < 150 || rotation > 340){
      style.transform = "rotate(" + (rotation - 48.5) + "deg)"
      style.justifyContent = "flex-end"
    }else{
      style.transform = "rotate(" + (rotation + 133) + "deg)"
    }

    return style

  }


  return(
    <div className="results-tree" style={{
      height : SIZE + "px",
      width : SIZE + "px",
      margin: SIZE / 10
    }}>

      <div className="results-tree-center" style={{
          top : (SIZE - 76) / 2 + "px",
          left : (SIZE - 76) / 2 + "px"
        }}>
        <div className="results-tree-note">

          { note > 0
          &&
          props._session.dashboardDisplaySettings.dashboardDisplayMode === "note"
          ?
          <b>
            {(note / 10)?.toFixed(1)}
          </b>
          :
          <div className="flex">
            <Space/>
            <b>
              {note?.toFixed(0)}
            </b>
            <div style={{ 
                fontSize : '18px',
                marginTop : '4px',
                marginBottom : '-4px'
              }}>
              %
            </div>
            <Space/>
          </div>
          }

        </div>

        <div className="grey-t">
          { note > 0
          ?
          <span>{props._session.dashboardDisplaySettings.dashboardDisplayMode === "note" ? t("survey_note") : t("survey_satisfaction")}</span>
          :
          <FontAwesomeIcon icon={faCloudSun}
            style={{ color : '#d4d4d4' }}
          />
          }
        </div>

      </div>

      { topics.map((topic, i) => 
      <div key={i}>
        <div style={getTopicBack(topic, i)} className="results-tree-back"></div>

        <div style={getTopicText(topic, i)} 
          data-tip={topic.label + " : " + (props._session.dashboardDisplaySettings.dashboardDisplayMode === "note" ? topic.noteLabel : topic.satisfaction.toFixed(1) + "%")}
          data-for={topic.id}
          className="results-tree-text flex">

          <div style={getTopicPastille(topic, i)} className="results-tree-pastille"></div>

          <span onClick={() => clickTopic(topic)}
            className={!props.isPreview ? "_hover" : ""}>
            { topic.label.length > 20
            ?
            topic.label.slice(0, 20)
            :
            topic.label
            }
          </span>

        </div>
        { !props.isPreview &&
        <ReactTooltip id={topic.id}/>
        }

        <div style={getTopicBorder(i)} className="results-tree-border-container flex">
          <div className="flex1"></div>
          <div className="results-tree-border flex1"></div>
        </div>
      </div>
      )}

    </div>
  )

}

const mapStateToProps = state => ({
  _session : state._session
})

export default connect(mapStateToProps)(withTranslation()(DashboardResultsTopicsWidget))