/** 
 * QUESTION-SCORE.HELP
 * Info about score
 */
import HelpModal from "@/components/modal-help"
import { withTranslation, WithTranslation } from "react-i18next"

interface OwnProps extends WithTranslation{
  onClose : Function
}

function QuestionScoreHelp(props:OwnProps) {
  const { t } = props

  return (
    <HelpModal onClose={props.onClose}
      title={t("question_score")}>

      <p>{t("question_score_help")}</p>
      <p>{t("question_score_help_response")}</p>
      
      <ul>
        <li>{t("question_score_help_response_5")}</li>
        <li>{t("question_score_help_response_4")}</li>
        <li>{t("question_score_help_response_3")}</li>
        <li>{t("question_score_help_response_2")}</li>
        <li>{t("question_score_help_response_1")}</li>
      </ul>

      <p>{t("question_score_help_calculate_topic")}</p>
      <p>{t("question_score_help_calculate_global")}</p>

      <p>
        <b>{t("question_reverse_help")}</b>
      </p>

    </HelpModal>
  )
}

export default withTranslation()(QuestionScoreHelp)