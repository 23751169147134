/**
 * SPLIT AND SUM
 * Divide array values in array of two elements
 */

import { slice, sum } from "lodash"

const splitAndSum = (values:number[]) => {
  if (values.length > 2){

    // Find the middle index
    const midIndex = Math.floor(values.length / 2)

    // Sum the first part of the array
    const firstPartSum = sum(slice(values, 0, midIndex))

    // Sum the second part of the array
    const secondPartSum = sum(slice(values, midIndex))

    return [firstPartSum, secondPartSum]

  }else{
    return values
  }

}

export default splitAndSum