/**
 * TOPIC-TASK.WIDGET
 */


import ListItem from "@/components/list-item"
import Space from "@/components/space"
import { Topic } from "@/redux/topic.types"
import { t } from "@/translate/t"
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"
import { WithTranslation, withTranslation } from "react-i18next"
import ReactTooltip from "react-tooltip"


interface OwnProps{
  topic: Topic
  isActive?: boolean
  onClick?: Function
  onInfo?: Function
}

type Props = OwnProps & WithTranslation

function TopicTaskWidget(props: Props){

  const [topic, setTopic] = useState<Topic>(props.topic)

  useEffect(() => {
    setTopic(props.topic)
  }, [
    props.topic
  ])

  function click(){
    if(props.onClick){
      props.onClick()
    }
  }

  function info(){
    if(props.onInfo){
      props.onInfo()
    }
  }

  return (
    <ListItem key={topic.id}
      isEditable
      onClick={click}
      isActive={props.isActive}
      isSmall>
      <div className="flex flex1"
        style={{
          marginLeft : -30,
          fontSize : 12 
        }}>
        <span style={{ width : 32 }}>
          <b>
            {topic.noteLabel}
          </b>
        </span>
        {topic.label ? topic.label : t("utils_undefined")}
        <Space/>
        <div className="width-20"/>
        { props.onInfo &&
        <FontAwesomeIcon onClick={info}
          icon={faInfoCircle}
          data-for={topic.id}
          data-tip={t("utils_details")}
          style={{ fontSize : 14 }}
          className="_hover medgrey-t"
        />
        }
        <ReactTooltip id={topic.id}/>
      </div>
    </ListItem>
  )
}

export default withTranslation()(TopicTaskWidget)