/** 
 * SELECT-TEMPLATE.MODAL
 * Pick a template in order to launch a project
 */
import Modal from "@/components/modal"
import Scroller from "@/components/scroller"
import Tabs from "@/components/tabs"
import { useState } from "react"
import { withTranslation, WithTranslation } from "react-i18next"
import { connect } from "react-redux"
import { TemplateState } from "@/redux/template.types"
import { Session } from "@/redux/_session.types"
import TemplatesWidget from "@/widgets/templates.widget"
import AddTemplateModal from "./add-template.modal"

interface StateProps extends WithTranslation{
  _session : Session
  template : TemplateState
}

type Props = StateProps & OwnProps

interface OwnProps extends WithTranslation{
  onClose : Function
  onSelect? : Function
}

//Tabs for template
type TemplateTabs = "TAB_ACCOUNT" | "TAB_IDTREE"

const MODAL_ADD: string = "MODAL_ADD"

function SelectTemplateModal(props:Props) {
  const { t } = props

  const [currentModal, setCurrentModal] = useState<string | null>(null)
  const [currentTab, setCurrentTab] = useState<TemplateTabs>("TAB_ACCOUNT")

  //Select template
  function select(templateId){
    if (props.onSelect){
      props.onSelect(templateId)
    }
  }

  return (

    <Modal onClose={() => props.onClose()}
      isCloseButtonVisible
      isLarge
      title={t("template_select")}>

      { currentModal === MODAL_ADD &&
      <AddTemplateModal onClose={() => setCurrentModal(null)}/>
      }

      <Tabs active={currentTab}
        onClick={(tab: TemplateTabs) => setCurrentTab(tab)}
        tabs={{ 
          TAB_ACCOUNT : t("templates_account"),
          TAB_IDTREE : t("templates_public")
        }}
      />

      <Scroller style={{ height : "calc(100vh - 300px)" }}
        isModalScroller>

        { currentTab === "TAB_ACCOUNT" 
        ?
        <TemplatesWidget switchForProject onSelect={select}/>
        :
        <TemplatesWidget loadSupervisorTemplates switchForProject onSelect={select}/>
        }

      </Scroller>
      
    </Modal>

  )

}

const mapStateToProps = state => ({
  _session : state._session,
  template : state.template
})

export default connect(mapStateToProps)(withTranslation()(SelectTemplateModal))