/**
 * SURVEY.ACTIONS.TS
 */
import {
  SURVEY_ACTIVATE,
  SURVEY_INIT,
  SURVEY_EDIT,
  SURVEY_GET,
  SURVEY_REMOVE,
  SURVEY_STATUS,
  SURVEY_EDIT_ONE
} from "./survey.types"

//REDUX

export const surveyEdit = (key: string, value: any) => ({
  type: SURVEY_EDIT,
  payload: {
    key,
    value
  }
})

export const surveyEditOne = (surveyId: string, key: string, value: any) => ({
  type: SURVEY_EDIT_ONE,
  payload: {
    surveyId,
    key,
    value
  }
})

export const surveyActivate = (survey) => ({
  type: SURVEY_ACTIVATE,
  payload: {
    survey
  }
})

export const surveyGet = (surveys, surveysArchive, hideEmpty) => ({
  type: SURVEY_GET,
  payload: {
    surveys,
    surveysArchive,
    hideEmpty
  }
})

export const surveyInit = () => ({
  type: SURVEY_INIT
})

export const surveyRemove = (id) => ({
  type: SURVEY_REMOVE,
  payload: {
    id
  }
})

export const surveyStatus = (status) => ({
  type: SURVEY_STATUS,
  payload: {
    status
  }
})

/**
 * API
 */

export const surveyCreateDemo_AsSupervisor = (accountId: string) => ({
  type: "API",
  payload: {
    method: "POST",
    url: "/supervisor/survey/demo",
    data: {
      accountId
    }
  }
})

export const surveyDestroy = (id) => ({
  type: "API",
  payload: {
    method: "DELETE",
    url: "/survey/" + id
  }
})

export const surveyDestroyGroup = (id) => ({
  type: "API",
  payload: {
    method: "DELETE",
    url: "/survey/group/" + id
  }
})

export const surveyDestroyRelaunch = (relaunchId) => ({
  type: "API",
  payload: {
    method: "DELETE",
    url: "/survey/relaunch/" + relaunchId
  }
})

export const surveyFetch = () => ({
  type: "API",
  payload: {
    method: "GET",
    url: "/surveys"
  }
})

export const surveyFetch_AsSuperadmin = () => ({
  type: "API",
  payload: {
    method: "GET",
    url: "/superadmin/surveys"
  }
})

export const surveyFetch_AsSupervisor = (surveyIds: string[]) => ({
  type: "API",
  payload: {
    method: "GET",
    url: "supervisor/surveys",
    data: {
      surveyIds
    }
  }
})

export const surveyFetchOne = (surveyId, isSurveyGroup) => ({
  type: "API",
  payload: {
    method: "GET",
    url: "/survey",
    data: {
      surveyId,
      isSurveyGroup
    }
  }
})

export const surveyFetchOne_AsSuperadmin = (accountId: string, surveyId: string) => ({
  type: "API",
  payload: {
    method: "GET",
    url: "/superadmin/survey",
    data: {
      accountId,
      surveyId
    }
  }
})

export const surveyInsertGroup = (survey) => ({
  type: "API",
  payload: {
    method: "POST",
    url: "/survey/group/insert",
    data: {
      survey
    }
  }
})

export const surveyReopen = (surveyId) => ({
  type: "API",
  payload: {
    method: "POST",
    url: "/survey/reopen",
    data: {
      surveyId
    }
  }
})

export const surveyUpdateBookmark = (surveyId, bookmarksOrder) => ({
  type: "API",
  payload: {
    method: "POST",
    url: "/survey/bookmarks",
    data: {
      surveyId,
      bookmarksOrder
    }
  }
})

export const surveyUpdateName = (surveyId: string, name: string) => ({
  type: "API",
  payload: {
    method: "POST",
    url: "/survey/name",
    data: {
      surveyId,
      name
    }
  }
})

export const surveyUpdateProject = (surveyId: string, projectId: string | null) => ({
  type: "API",
  payload: {
    method: "POST",
    url: "/survey/project",
    data: {
      surveyId,
      projectId
    }
  }
})

export const surveyUpdateClose = (surveyId: string) => ({
  type: "API",
  payload: {
    method: "POST",
    url: "/survey/close",
    data: {
      surveyId
    }
  }
})

export const surveyUpdatePause = (surveyId) => ({
  type: "API",
  payload: {
    method: "POST",
    url: "/survey/pause",
    data: {
      surveyId
    }
  }
})

export const surveyUpdateProgram = (surveyId) => ({
  type: "API",
  payload: {
    method: "POST",
    url: "/survey/program",
    data: {
      surveyId
    }
  }
})

export const surveyUpdateRelaunch = (relaunch, surveyId) => ({
  type: "API",
  payload: {
    method: "POST",
    url: "/survey/relaunch",
    data: {
      relaunch,
      surveyId
    }
  }
})

export const surveyUpdateRepeat = (surveyId) => ({
  type: "API",
  payload: {
    method: "POST",
    url: "/survey/repeat",
    data: {
      surveyId
    }
  }
})

export const surveyUpdateRpsTags = (surveyId: string) => ({
  type: "API",
  payload: {
    method: "POST",
    url: "/survey/rps-tags",
    data: {
      surveyId
    }
  }
})

export const surveyUpdateSend = (user, surveyId) => ({
  type: "API",
  payload: {
    method: "POST",
    url: "/survey/send",
    data: {
      user,
      surveyId
    }
  }
})

export const surveyUpdateTemplate = (surveyId: string) => ({
  type: "API",
  payload: {
    method: "POST",
    url: "/survey/template",
    data: {
      surveyId
    }
  }
})

export const surveyUpdateUndo = (userId, surveyId) => ({
  type: "API",
  payload: {
    method: "POST",
    url: "/survey/undo",
    data: {
      userId,
      surveyId
    }
  }
})

/* OTHER FUNCTIONS */
export function surveyFormatNote(note: number | null, withSatisfaction: boolean) {
  if (note) {
    return withSatisfaction ? note.toFixed(0) + " %" : (note / 10).toFixed(1)
  } else {
    return ""
  }
}
