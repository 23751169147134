/** 
 * SUBSCRIPTION.REDUCERS
 * Edit state for subscription
 */

import { Subscription, SubscriptionState, SUBSCRIPTION_ADD, SUBSCRIPTION_EDIT, SUBSCRIPTION_GET, SUBSCRIPTION_INIT, SUBSCRIPTION_REMOVE } from "./subscription.types";
import { v4 as uuid } from "uuid";

let active:Subscription

const initialState: SubscriptionState = {
  list : []
}

export default function subscriptionReducer(
  state = initialState,
  action: any
): SubscriptionState {
  switch (action.type) {

    //Add new sub
    case SUBSCRIPTION_ADD:
      active = new Subscription()
      active.id = uuid()
      active.AccountId = action.payload.accountId
      return Object.assign({}, state, {
        list : [active].concat(state.list)
      })

    //Edit sub
    case SUBSCRIPTION_EDIT:
      active = new Subscription(state.list.find(x => x.id === action.payload.id))
      active[action.payload.key] = action.payload.value
      return Object.assign({}, state, { 
        list : state.list.map(x => x.id === action.payload.id ? active : x) 
      })

    //Save list of sub in the store
    case SUBSCRIPTION_GET:
      return Object.assign({}, state, {
        list : action.payload.subscriptions.map(x => new Subscription(x))
      })
    
    //Init store
    case SUBSCRIPTION_INIT:
      return Object.assign({}, state, {
        list : state.list.map(x => new Subscription(x))
      })

    //Remove sub
    case SUBSCRIPTION_REMOVE:
      return Object.assign({}, state, { 
        list : state.list.filter(x => x.id !== action.payload.id)
      })

    default:
      return state

  }

}