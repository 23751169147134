/** 
 * HOME-TEMPLATES.WIDGET
 * Overview of templates created for the account
 */
import { withTranslation, WithTranslation } from "react-i18next"
import { connect } from "react-redux"
import Card from "@/components/card";
import { TemplateState } from "@/redux/template.types";
import Space from "@/components/space";
import CardButton from "@/components/card-button";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Link from "@/components/link";
import { Session } from "@/redux/_session.types";
import { store } from "@/index";
import { topicFetchAndGet } from "@/redux/topic.actions";
import { useEffect, useState } from "react";
import { templateFetchAndGet } from "@/redux/template.actions";
import GetTemplatesModal from "@/modals/get-templates.modal";
import { NavigateFunction, useNavigate } from "react-router-dom";

interface StateProps extends WithTranslation{
  _session : Session
  template : TemplateState
}

const MODAL_CREATE = "MODAL_CREATE"

function HomeTemplatesWidget(props:StateProps) {
  const navigate: NavigateFunction = useNavigate()
  const { t } = props

  //Open modal create template
  const [currentModal, setCurrentModal] = useState<string | null>(null)

  //Load templates on load
  useEffect(() => {
    store.dispatch(templateFetchAndGet(false, false, true, true))
  }, [props._session.accountId])

  function toTemplate(){
    store.dispatch(topicFetchAndGet(null))
    navigate("/template/editor")
  }

  return (
    <Card status={props.template.status}
      isWithMinHeight>

      { currentModal === MODAL_CREATE &&
      <GetTemplatesModal onClose={() => setCurrentModal(null)}/>
      }
      
      { 
      /**
       * IF THERE IS NO TEMPLATE CREATED
       * show public template buttons
       */
      props.template.list.length === 0
      ?
      <div className="flex flex-dcol home-widget">
        <Space/>
        <CardButton icon={faPlus}
          title={t("template_create_new")}
          onClick={() => setCurrentModal(MODAL_CREATE)}
        />
        <Space/>
      </div>
      /**
       * IF THERE IS AT LEAST ONE TEMPLATE
       * Link to the next edited template
       */
      :
      <div className="flex flex-dcol home-widget"
        style={{ textAlign : "center" }}>

        <span style={{ fontSize : 12 }}>
          {t("template_last_updated")}
        </span>
        
        <p onClick={toTemplate}
          className="_hover">
          <b>{props.template.active.label}</b>
        </p>

        <Space/>

        <div className="home-widget-icon">
          <img src={require("@/assets/home-templates.png")} 
            alt="home-templates"
            className="_hover"
            onClick={toTemplate}
          />
        </div>

        <Space/>

        <div className="flex">
          <Space/>
          <Link onClick={() => navigate("/survey/account")}
            isWithoutMargin>
            {t("templates_link")}
          </Link>
        </div>

      </div>
      }

    </Card>
  )

}

const mapStateToProps = state => ({
  _session : state._session,
  template : state.template
})

export default connect(mapStateToProps)(withTranslation()(HomeTemplatesWidget))