/** 
 * DASHBOARD-RESULTS.WIDGET
 * Display tree, podium and axis
 */

import { withTranslation, WithTranslation } from "react-i18next"
import { connect } from "react-redux"
import { FilterState } from "@/redux/filter.types";
import { TopicState } from "@/redux/topic.types";
import { SurveyState } from "@/redux/survey.types";
import { Session } from "@/redux/_session.types";
import { AxisState } from "@/redux/axis.types";
import DashboardParticipationMiniWidget from "./dashboard-participation-mini.widget";
import DashboardMessagesMiniWidget from "./dashboard-messages-mini.widget";
import DashboardNpsMiniWidget from "./dashboard-nps-mini.widget";
import DashboardRpsMiniWidget from "./dashboard-rps-mini.widget";
import DashboardRepartitionWidget from "./dashboard-repartition.widget";
import DashboardResultsWidget from "./dashboard-results.widget";
import NoResultsWidget from "./no-results.widget";

interface StateProps extends WithTranslation{
  _session : Session
  axis : AxisState
  survey : SurveyState
  topic : TopicState
  filter : FilterState
}

interface OwnProps{
  isPreview? : boolean
}

type Props = StateProps & OwnProps


function DashboardOverviewWidget(props:Props) {
  const { t } = props

  return (
    ( props.survey.active.id.length > 0 || props.isPreview || props.survey.active.randomData ) ?
    <div className="flex">

      <DashboardResultsWidget
        isPreview={props.isPreview}
        exportName={t("dashboard_results")}
        currentAxes={props.axis.list}
        currentSurvey={props.survey.active}
        currentTopics={props.topic.list} 
        dashboardFilters={props.filter.dashboard} 
      />
        

      <div className="width-20"/>

      <div className="flex1">

        <DashboardParticipationMiniWidget isPreview={props.isPreview}/>

        <div className="flex">
          <DashboardMessagesMiniWidget isPreview={props.isPreview}/>
          <div className="width-20"/>
          <DashboardNpsMiniWidget/>
          <div className="width-20"/>
          <DashboardRpsMiniWidget/>
        </div>

        { (props.isPreview && props.survey.active.getParticipationCount(props._session.dashboardDisplaySettings.hideStartedForParticipation) < props._session.participationMin)
        ?
        null
        :
        <DashboardRepartitionWidget/>
        }

      </div>

    </div>
    :
    <div>
      <NoResultsWidget/>
    </div>
  )

}

const mapStateToProps = state => ({
  _session : state._session,
  axis : state.axis,
  survey : state.survey,
  topic : state.topic,
  filter : state.filter
})

export default connect(mapStateToProps)(withTranslation()(DashboardOverviewWidget))