/** 
 * USER-OPTIONAL-INFO.WIDGET
 * Edit birthdate, companywelcomedate, phone, address, gender and language for an user
 */

import { withTranslation, WithTranslation } from "react-i18next"
import { useEffect, useState } from "react";
import TextInput from "@/components/text-input";
import { USER_LIST_GENDER } from "@/redux/user.types";
import PhoneInput from "react-phone-input-2";
import Dropdown from "@/components/dropdown";
import { connect } from "react-redux";
import { Session } from "@/redux/_session.types";

interface StateProps{
  _session : Session
}

interface OwnProps{
  birthDate?: Date | null
  companyWelcomeDate?: Date | null
  phone?: string | null
  address: string | null
  language: string
  gender: string | null
  genderLabel: string | null
  onEdit: Function
}

type Props = StateProps & WithTranslation & OwnProps


function UserOptionalInfoWidget(props:Props) {
  const { t } = props

  const [birthDate, setBirthDate] = useState<Date | null>(props.birthDate ? props.birthDate : null)

  const [companyWelcomeDate, setCompanyWelcomeDate] = useState<Date | null>(props.companyWelcomeDate ? props.companyWelcomeDate : null)

  const [phone, setPhone] = useState<string>(props.phone ? props.phone : "")

  const [address, setAddress] = useState<string>(props.address ? props.address : "")

  useEffect(()=>{
    setBirthDate(props.birthDate ? props.birthDate : null)
  }, [
    props.birthDate
  ])

  useEffect(()=>{
    setCompanyWelcomeDate(props.companyWelcomeDate ? props.companyWelcomeDate : null)
  }, [
    props.companyWelcomeDate
  ])

  useEffect(()=>{
    setPhone(props.phone ? props.phone : "")
  }, [
    props.phone
  ])

  useEffect(()=>{
    setAddress(props.address ? props.address : "")
  }, [
    props.address
  ])

  return (
    <div>

      <h3>{t("utils_informations_optional")}</h3>

      <div className="flex flex-wrap">

        <TextInput
          type="date"
          onBlur={() => props.onEdit("birthDate", birthDate)}
          onChange={(e) => setBirthDate(e.value)}
          title={t("user_birth_date")}
          value={birthDate}
        />

        <div className="width-20"/>

        <TextInput
          type="date"
          onBlur={() => props.onEdit("companyWelcomeDate", companyWelcomeDate)}
          onChange={(e) => setCompanyWelcomeDate(e.value)}
          title={t("user_company_welcome_date")}
          value={companyWelcomeDate}
        />

      </div>

      <p className="grey-t"
        style={{ marginBottom : '8px' }}>
        {t("user_phone")}
      </p>

      <PhoneInput
        country={props.language === "fr" ? "fr" : "gb"}
        value={phone}
        onBlur={() => props.onEdit("phone", phone)}
        onChange={(phone) => setPhone(phone)}
      />

      <TextInput
        onBlur={() => props.onEdit("address", address)}
        onChange={(e) => setAddress(e.value)}
        title={t("user_address")}
        value={address}
      />

      <div className="flex flex-wrap">

        <Dropdown displayField="name"
          list={USER_LIST_GENDER}
          onSelect={(item: any)=>props.onEdit("gender", item.id)}
          title={t("user_gender")}
          active={props.gender}
          value={props.genderLabel}
        />

        <div className="width-20"/>
        
        <Dropdown displayField="name"
          list={props._session.languagesAvailables}
          onSelect={(item: any)=>props.onEdit("language", item.id)}
          title={t("user_language")}
          active={props.language}
          value={props.language!.toUpperCase()}
        />

      </div>
    </div>

  )

}


const mapStateToProps = state => ({
  _session : state._session
})

export default connect(mapStateToProps)(withTranslation()(UserOptionalInfoWidget))