/** 
 * SURVEY-ACCOUNT
 * List of templates for an account
 */
import { Component } from "react";
import { withTranslation, WithTranslation } from 'react-i18next';
import NavApp from "@/nav/nav.app";
import PageHeader from "@/components/page-header";
import TemplatesWidget from "@/widgets/templates.widget";

type Props = WithTranslation

class SurveyAccount extends Component<Props>{

  render () {
    const { t } = this.props

    return(
      <NavApp>

        <PageHeader title={t("templates_account")}
          subtitle={t("templates_account_help")}>
        </PageHeader>
          
        <TemplatesWidget/>

      </NavApp>

    )
  }
}

export default withTranslation()(SurveyAccount)