/** 
 * REPORT.REDUCERS
 * Edit report state
 */

import { Report, ReportState, REPORT_ACTIVATE, REPORT_ADD, REPORT_EDIT, REPORT_INIT, REPORT_GET, REPORT_REMOVE, REPORT_STATUS, REPORT_EDIT_DEFAULT, REPORT_STATUS_MESSAGE } from "./report.types";
import { STATUS_LOADED } from "./_status.types";
import { v4 as uuid } from "uuid";


let active:Report
let list:Report[]

const initialState: ReportState = {
  active : new Report(),
  list : [],
  status : STATUS_LOADED,
  statusMessage : ""
}

export default function reportReducer(
  state = initialState,
  action: any
): ReportState {
  switch (action.type) {

    //activate report
    case REPORT_ACTIVATE:
      return Object.assign({}, state, {
        active : new Report(action.payload.report)
      })

    //Add new template
    case REPORT_ADD:
      active = new Report(action.payload.report)
      if(!action.payload.report.id){
        active.id = uuid()
      }
      return Object.assign({}, state, {
        active
      })

    //editing report
    case REPORT_EDIT:
      active = Object.assign(state.active, { [action.payload.key] : action.payload.value })
      return Object.assign({}, state, {
        active
      })

    //Set as default
    //Set all other not as default
    case REPORT_EDIT_DEFAULT:
      return {
        ...state,
        list : state.list.map(x => new Report(Object.assign({}, x, { default : action.payload.id === x.id })))
      } 
    
    //Init store
    case REPORT_INIT:
      return Object.assign({}, state, {
        active : new Report(state.active),
        status : state.status,
      })

    //Save list of learning in the store
    case REPORT_GET:
      list = action.payload.reports.map((x) => new Report(x))
      return {
        ...state,
        list
      }

    //Remove a report
    case REPORT_REMOVE:
      return {
        ...state,
        list  : state.list.filter(x => x.id !== action.payload.id )
      }

    //Set status
    case REPORT_STATUS:
      return {
        ...state,
        status : action.payload.status
      }

    //Set status message
    case REPORT_STATUS_MESSAGE:
      return {
        ...state,
        statusMessage : action.payload.message
      }

    default:
      return state

  }

}