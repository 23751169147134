import Link from "@/components/link"
import HelpModal from "@/components/modal-help"
import { selectSession } from "@/core/slices/session/session-selector"
import { faCloud, faCloudBolt, faCog, faSun } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { withTranslation, WithTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

interface OwnProps extends WithTranslation {
  onClose: Function
}

function GollacInfoHelp(props: OwnProps) {
  const { t } = props
  const session = useSelector(selectSession)
  const navigate = useNavigate()

  const handleAdminLinkClick = () => {
    navigate("/adm/filters")
  }

  return (
    <HelpModal
      onClose={props.onClose}
      title={t("gollac_info_help_title")}
      option={
        session.userRole === "ADMIN" ? (
          <Link onClick={handleAdminLinkClick} icon={faCog}>
            {t("adm_filter_route")}
          </Link>
        ) : (
          <>{t("contact_admin")}</>
        )
      }>
      <div className="p-4 space-y-4 text-gray-700">
        <p>
          <strong>{t("gollac_info_help_content_1_1")}</strong> {t("gollac_info_help_content_1_2")}
        </p>
        <ul className="pl-6 list-disc space-y-2">
          <li>
            <strong>{t("gollac_info_help_content_2_1")}</strong> {t("gollac_info_help_content_2_2")}{" "}
            <strong>{session.accountOptions.ceilsGollacMinValue}</strong>{" "}
            <FontAwesomeIcon className="text-idt-green-main text-xl" icon={faSun} />
          </li>
          <li>
            <strong>{t("gollac_info_help_content_3_1")}</strong> {t("gollac_info_help_content_3_2")}{" "}
            <strong>{session.accountOptions.ceilsGollacMinValue}</strong> {t("gollac_info_help_content_3_3")}{" "}
            <strong>{session.accountOptions.ceilsGollacMaxValue}</strong>{" "}
            <FontAwesomeIcon className="text-idt-yellow-main text-xl" icon={faCloud} />
          </li>
          <li>
            <strong>{t("gollac_info_help_content_4_1")}</strong> {t("gollac_info_help_content_4_2")}{" "}
            <strong>{session.accountOptions.ceilsGollacMaxValue}</strong>{" "}
            <FontAwesomeIcon className="text-idt-red-main text-xl" icon={faCloudBolt} />
          </li>
        </ul>
        <p>
          {t("gollac_info_help_content_5_1")} <strong>{t("gollac_info_help_content_5_2")}</strong>
        </p>
        <hr className="my-4 border-t border-gray-300" />
        <p>
          <strong>{t("gollac_info_help_content_6")}</strong>
        </p>
        <ul className="pl-6 list-circle space-y-2">
          <li>
            <strong>{t("gollac_info_help_content_7")}</strong> {session.accountOptions.gollacFilterName}
          </li>
          <li>
            <strong>{t("gollac_info_help_content_8")}</strong> {session.accountOptions.gollacManagerAttributeName}
          </li>
        </ul>
      </div>
    </HelpModal>
  )
}

export default withTranslation()(GollacInfoHelp)
