/** 
 * QUESTION-PRIMARY.HELP
 * Info about question order (primary or secondary)
 */
import HelpModal from "@/components/modal-help"
import { withTranslation, WithTranslation } from "react-i18next"

interface OwnProps extends WithTranslation{
  onClose : Function
}

function QuestionPrimaryHelp(props:OwnProps) {
  const { t } = props

  return (
    <HelpModal onClose={props.onClose}
      title={t("question_help_order")}>
      <p>{t("question_help_order_intro")}</p>
      <p>{t("question_help_order_primary")}</p>
      <p>{t("question_help_order_secondary")}</p>
    </HelpModal>
  )
}

export default withTranslation()(QuestionPrimaryHelp)