/***
 * FILTER-WIDGET
 * Select attributes to select for a filter
 */

import { connect } from "react-redux";
import { Session } from "@/redux/_session.types";
import { useEffect, useState } from "react";
import Button from "@/components/button";
import ContextMenu from "@/components/context-menu";
import ListItem from "@/components/list-item";
import { intersection, uniq } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faTimes } from "@fortawesome/free-solid-svg-icons";
import Space from "@/components/space";
import { WithTranslation, withTranslation } from "react-i18next";
import { Attribute } from "@/redux/attribute.types";
import PageLoader from "@/components/page-loader";
import { STATUS_LOADED, STATUS_LOADING } from "@/redux/_status.types";
import Checkbox from "@/components/checkbox";
import ReactTooltip from 'react-tooltip'
import DashboardFilterUsersCountWidget from "./dashboard-filter-users-count.widget";

interface StateProps extends WithTranslation{
  _session : Session
}

interface OwnProps {
  filterId : string
  isUnique? : boolean
  name : string
  isLoading? : boolean
  isToogle? : boolean
  list? : Attribute[]
  selectedIds : string[]
  showInfo?: boolean
  onSelect : Function
  onOpen? : Function
  readonly? : boolean
}

type Props = StateProps & OwnProps

function DashboardFilterWidget(props:Props){
  const { t } = props

  const [isOpen, setIsOpen] = useState(false) //Is context menu open
  const [attributes, setAttributes] = useState<Attribute[]>([]) //List of items to display
  const [selectedAttributes, setSelectedAttributes] = useState<string[]>([]) //List of items to pick

  //Update selected items when receiving props
  useEffect(() => {
    setSelectedAttributes(props.selectedIds)
  }, [props.selectedIds])

  //Update list
  useEffect(() => {
    if(props.list){
      setAttributes(props.list)
    }
  }, [props.list])

  //Close context menu
  //If multiple values possible send selected values
  function close(){
    setIsOpen(false)
    props.onSelect(selectedAttributes)
  }

  //Is the filter selected
  function isActive(){

    //Get attributes and attributes ids
    const attributesIds = attributes.map(x => x.id)
    
    //Cpmpare lists
    return intersection(attributesIds, getSelectedAttributesIds()).length > 0

  }

  //Detect if attribute is active
  function isAttributeActive(itemId){
    return selectedAttributes.indexOf(itemId + (props.isUnique ? '' : '|' + props.filterId)) > -1
  }

  //Get className depending
  function getClassName(){
    let className = "filters-item flex "

    if (isActive()){
      className = className + " filters-item-active"
    }else if(!props.readonly){
      className = className + " filters-item-activable"
    }

    return className

  }

  //Return list of selected ids
  function getSelectedAttributesIds(){
    return props.selectedIds.map(x => x.split('|')[0])
  }

  //Get text displayed
  function getText(){
    if (isActive()){
      const selectedAttributes = attributes.filter(x => getSelectedAttributesIds().indexOf(x.id) > -1)
      return uniq(selectedAttributes.map(x => t(x.name))).join(", ")
    }else{
      return props.name
    }
  }

  //Open filter menu
  function open(){

    if (props.onOpen){
      props.onOpen()
    }

    if (props.isToogle){
      props.onSelect()
    }else{
      setIsOpen(!isOpen)
    }

  }

  //Reset selected filters
  //Remove only items that are present in the list
  function reset(e){
    e.stopPropagation()

    let items = getSelectedAttributesIds()
    attributes.forEach((item: Attribute) => {
      if (getSelectedAttributesIds().indexOf(item.id) > -1){
        items = items.filter(x => x !== item.id)
      }
    })

    props.onSelect(items)
  }

  //Clic on item
  //If unique value allowed select the item
  //Else add or remove from array
  function addItem(itemId){
    const attribute = itemId + '|' + props.filterId
    if (selectedAttributes.indexOf(attribute) > -1){
      setSelectedAttributes(selectedAttributes.filter(x => x !== attribute))
    }else{
      setSelectedAttributes(selectedAttributes.concat([attribute]))
    }
  }

  //Replace value for item
  function selectItem(itemId){

    if (props.isUnique){
      setIsOpen(false)
      props.onSelect([itemId])
    }else{
      const attribute = itemId + '|' + props.filterId
      if (selectedAttributes.indexOf(attribute) > -1){
        setSelectedAttributes([])
      }else{
        setSelectedAttributes([attribute])
      }
    }

  }
  
  return (
    <div id={props.filterId} className="rel">

      <div className={getClassName()}
        onClick={props.readonly ? undefined : open}
        style={{ 
          color : isActive() ? props._session.accountColors.active : ""
        }}>

        {getText()}

        { isActive() &&
        <div onClick={reset} className="filters-reset-icon grey-t">
          <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
        </div>
        }

      </div>

      { isOpen &&
      <ContextMenu onClose={close}>
        <PageLoader status={props.isLoading ? STATUS_LOADING : STATUS_LOADED}>

          <div style={{ maxHeight : "326px", overflowY : "auto" }}>
            { attributes.map((item: Attribute) => 
            <div key={item.id}>
              <ListItem 
                isEditable
                isActive={isAttributeActive(item.id)}
                isSmall
                onClick={() => selectItem(item.id)}>

                <div className="dashboard-filter-chevron _hover">
                  { !props.isUnique&&
                  <Checkbox onClick={()=>addItem(item.id)} active={selectedAttributes.indexOf(item.id + '|' + props.filterId)>-1}/>
                  }
                </div>

                {t(item.name)}

                <Space/>

                <DashboardFilterUsersCountWidget
                  defaultPopulationId={item.id}
                  defaultFilterId={props.filterId}
                  isDefaultFilter={true}
                  showInfo={props.showInfo}
                  populationName={t(item.name)}
                  filterName={item.filterName}
                  userCount={item.usersCount}
                />

                {item.description?.length > 0 &&
                <FontAwesomeIcon 
                  icon={faInfoCircle} 
                  data-tip={item.description} 
                  className="grey-t" style={{ 
                  marginTop : 3,
                  marginLeft : 6
                }} />
                }

              </ListItem>
              
            </div>
            )}

            {props.filterId !== "gender" &&
            <ListItem isSmall>
              <span className="grey-t" style={{ fontSize:"12px" }}>
                {t("age_population_from_survey_date")}
              </span>
            </ListItem>
            }

            <ReactTooltip/>
          </div>

          { !props.isUnique &&
          <div className="flex context-menu-footer">
            <Space/>
            <Button className="primary"
              onClick={close}>
              {t("utils_close")}
            </Button>
          </div>
          }

        </PageLoader>
      </ContextMenu>
      }

    </div>
  )
}

const mapStateToProps = state => ({
  _session : state._session
})


export default connect(mapStateToProps)(withTranslation()(DashboardFilterWidget))
