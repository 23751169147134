/**
 * MONTHS-TO-RANGE.UTILS.TS
 * Get a date range from a month
 */

export function dateFromMonths(refDate: Date | null, months: number): Date{
  const date: Date = refDate ? new Date(refDate) : new Date()
  date.setMonth(date.getMonth() - months)
  return date
}

export const dateMonthsToRange = (start:Date | null, ceils:number[], index:string): string => {
  
  const numIndex = parseInt(index)
  const monthsMin = ceils[numIndex] ? ceils[numIndex] : 2400
  const monthsMax = ceils[numIndex - 1] ? ceils[numIndex - 1] : 0

  return dateFromMonths(start, monthsMin).toISOString().split('T')[0] + ',' + dateFromMonths(start, monthsMax).toISOString().split('T')[0]

}
