/** 
 * EDIT-ACCOUNT.MODAL
 * edit accoount
 */

import Modal from "@/components/modal"
import { useEffect, useState } from "react"
import { connect } from "react-redux"
import { withTranslation, WithTranslation } from "react-i18next"
import TextInput from "@/components/text-input"
import { store } from "@/index"
import { STATUS_SAVED, STATUS_SAVE_ERROR, STATUS_SAVING } from "@/redux/_status.types"
import { Session } from "@/redux/_session.types";
import { toast } from "react-toastify";
import Space from "@/components/space";
import { Account, AccountColors, AccountModules, AccountOptions, AccountState } from "@/redux/account.types";
import { accountEdit, accountRemove, accountStatus, accountUpdate_AsSuperadmin, accountInsert_AsSuperadmin, accountActivate, accountGet } from "@/redux/account.actions";
import DeleteAccountModal from "./delete-account.modal";
import ModalConfirm from "@/components/modal-confirm";
import ListItem from "@/components/list-item";
import Checkbox from "@/components/checkbox";
import { v4 as uuid } from "uuid";
import { validateEmail } from "@/utils/validate-email.utils"
import { User } from "@/redux/user.types";
import { userCheckEmail, userGet, userInsert_AsSuperadmin } from "@/redux/user.actions";
import AccountColorsWidget from "@/widgets/account-colors.widget";
import EditSuperadminModal from "./adm-edit-children.modal";
import Button from "@/components/button";
import ModulesWidget from "@/widgets/modules.widget";
import Dropdown from "@/components/dropdown";
import AdmRolesWidget from "@/widgets/adm-roles.widget";

interface StateProps extends WithTranslation{
  _session : Session
  account : AccountState
}

interface OwnProps{
  isNew : boolean
  onClose? : Function
}

type Props = StateProps & OwnProps

const ZONES:any = [
  { id : "europe", name : "account_zone_europe" },
  { id : "america", name : "account_zone_america" },
  { id : "asia", name : "account_zone_asia" }
]

const MODAL_ADMIN_ADD : string = "MODAL_ADMIN_ADD"
const MODAL_DELETE_CONFIRM : string = "MODAL_DELETE_CONFIRM"
const MODAL_CANCEL_CHANGES : string = "MODAL_CANCEL_CHANGES"

function EditAccountModal(props:Props) {
  const { t } = props


  const [currentModal, setCurrentModal] = useState<string | null>(null)
  const [edited, setEdited] = useState<boolean>(false)
  const [withAdmin, setWithAdmin] = useState<boolean>(false)
  const [editColors, setEditColors] = useState<boolean>(false)
  const [advancedSettings, setAdvancedSettings] = useState<boolean>(false)
  const [emailError, setEmailError] = useState<string>("utils_email_empty")

  //admin params
  const [firstname, setFirstname] = useState<string>("")
  const [lastname, setLastname] = useState<string>("")
  const [email, setEmail] = useState<string>("")

  useEffect(()=>{
    if(props.isNew){
      store.dispatch(accountActivate(new Account({
        id : uuid(),
        name : t("account_new"),
        superAccountId : props._session.accountId,
        participationMin : props._session.participationMin,
        modules : new AccountModules(props._session.modules),
        options : new AccountOptions({ pastResults : props._session.accountOptions.pastResults })
      })))
    }
  }, [
    props._session.accountOptions.pastResults,
    props.isNew,
    props._session.accountId,
    props._session.participationMin,
    props._session.modules
  ])


  //Cancel changes
  function cancel(){
    if(!props.isNew && edited){
      setCurrentModal(MODAL_CANCEL_CHANGES)
    }
    else{
      close(false)
    }
  }

  function changeEmail(email:string){
    setEmail(email)
    checkEmailFormat(email)
  }

  async function checkEmailAvailability(){
    if(emailError === ""){
      const response:any = await store.dispatch(userCheckEmail(email, ""))
      if(response.available){
        setEmailError("OK")
      }
      else{
        setEmailError("utils_email_already_exist")
      }
    }
  }

  function checkEmailFormat(email:string){
    if(email.length>0){
      if(validateEmail(email)){
        setEmailError("")
      }
      else{
        setEmailError("utils_email_invalid")
      }
    }
    else{
      setEmailError("utils_email_empty")
    }
  }

  //close modal
  function close(withReload:boolean){
    if(props.onClose){
      props.onClose(withReload)
    }
  }
  

  function editAccount(key:string, value:any){
    store.dispatch(accountEdit(key, value))
    if(!edited){
      setEdited(true)
    }
  }
  
  //Save the account
  async function saveAccount(){

    store.dispatch(accountStatus(STATUS_SAVING))
    let response : any = null
    if(props.isNew){
      response = await store.dispatch(accountInsert_AsSuperadmin(props.account.active))
    }
    else{
      response = await store.dispatch(accountUpdate_AsSuperadmin(props.account.active))
    }

    if(response.superAccountId === props._session.accountId){

      if(props.isNew){
        store.dispatch(accountGet([props.account.active].concat(props.account.list)))

        if(withAdmin){
          const userResponse:any = await saveUser(response.accountId)
          if(userResponse.email === email){
            toast(t("admin_created", {admin : userResponse.email, account : props.account.active.name}))
          }
          else{
            if(userResponse.error){
              toast(t(userResponse.error), { type : 'error' })
            }
            else{
              toast(t("no_response"), { type : 'error' })
            }
          }
        }

      }

      store.dispatch(accountStatus(STATUS_SAVED))
      toast(t("utils_saved"))
      close(true)

    }
    else{
      store.dispatch(accountStatus(STATUS_SAVE_ERROR))
      if(response.error){
        toast(t(response.error), { type : 'error' })
      }
      else{
        toast(t("no_response"), { type : 'error' })
      }
    }

  }

  async function saveUser(accountId : string){
    const user : User = new User({
      id : uuid(),
      email,
      firstname,
      lastname,
      AccountId : accountId,
      role : "ADMIN"
    })

    return await store.dispatch(userInsert_AsSuperadmin(user))
  
  }

  function removeAccount(){
    store.dispatch(userGet([]))
    store.dispatch(accountRemove(props.account.active.id))
    close(true)
  }

  function validate(){
    if(props.account.active.name.length>0){
      if(withAdmin && props.isNew){
        if(emailError === "OK"){
          saveAccount()
        }
        else{
          toast(t(emailError), { type : 'error' })
        }
      }
      else{
        saveAccount()
      }
    }
    else{
      toast(t("account_name_invalid"), { type : 'error' })
    }
  }

  return (
    <Modal
      isCloseButtonVisible
      disableKeyEvents
      isLarge
      status={props.account.status}
      onDelete={props.isNew?undefined:()=>setCurrentModal(MODAL_DELETE_CONFIRM)}
      onClose={cancel}
      onNext={validate}
      title={props.isNew?t("account_add"):t("account_edit")}>


      { /** dialog to delete account */
      currentModal === MODAL_DELETE_CONFIRM &&
      <DeleteAccountModal onClose={() => setCurrentModal(null)}
        onDelete={removeAccount}
        isAsSuperadmin
        accountId={props.account.active.id}
        accountName={props.account.active.name}
      />
      }

      { currentModal === MODAL_ADMIN_ADD &&
      <EditSuperadminModal isNew
        onClose={() => setCurrentModal(null)} />
      }

      { /** dialog to cancel account changes */
      currentModal === MODAL_CANCEL_CHANGES &&
      <ModalConfirm onNo={()=>setCurrentModal(null)}
        onYes={()=>close(true)}
        textBold={t("utils_next_ask")}
        text={t("utils_cancel_changes")}
        status={props.account.status}
      />
      }

      <div className="flex" style={{ width:"700px", marginBottom : "22px" }}>

        <TextInput
          autoFocus
          required
          placeholder = {t("account_new")}
          error={props.account.active.name.length<1}
          onChange={(e:any) => editAccount( "name", e.value )}
          title={t("account_name")}
          value={props.account.active.name}
        />

      </div>

      <div style={{ margin: "0px -40px" }}>

        { props.isNew ?
        <ListItem>
          <div>

            <Checkbox active={withAdmin}
              text={t("user_admin_add")}
              onClick={()=>setWithAdmin(!withAdmin)}
            />

            { withAdmin &&
            <div style={{ marginTop : '22px' }}>

              <div className="flex">

                <TextInput
                  onChange={(e:any) => setFirstname(e.value)}
                  title={t("user_firstname")}
                  value={firstname}
                />

                <div className="width-20"/>

                <TextInput
                  onChange={(e:any) => setLastname(e.value)}
                  title={t("user_lastname")}
                  value={lastname}
                />

              </div>

              <div className="flex">
            
                <TextInput
                  required
                  error={emailError.length > 0 && emailError !== "OK"}
                  isCheck={emailError === "OK"}
                  onChange={(e:any) => changeEmail(e.value)}
                  onBlur={checkEmailAvailability}
                  title={t("user_email")}
                  value={email}
                />

                { emailError !== "OK" &&
                <div className="red-t" style={{padding : "60px 10px 0 10px"}}>
                  {t(emailError)}
                </div>
                }

              </div>

            </div>
            }
          </div>

        </ListItem>
        :
        <ListItem>
          <div className="flex1">
            <div onClick={()=>setWithAdmin(!withAdmin)}
              className="_hover">
              <b>{t("user_admins")}</b>
            </div>

            { withAdmin &&
            <div>

              <div className="flex">

                <Space/>
                <Button onClick={()=>setCurrentModal(MODAL_ADMIN_ADD)}>{t("user_admin_add")}</Button>

              </div>

              <div style={{ 
                  margin : "0px -30px 0px -30px",
                  width : 832
                }}>
                <AdmRolesWidget editChildren/>
              </div>

            </div>
            }
          </div>

        </ListItem>
        }

        <ListItem>
          <div className="flex1">

            { props.isNew ?
            <Checkbox active={editColors}
              text={t("account_settings_customize_skin")}
              onClick={()=>setEditColors(!editColors)}
            />
            :
            <div onClick={()=>setEditColors(!editColors)} className="_hover">
              <b>{t("account_settings_customize_skin")}</b>
            </div>
            }

            { editColors &&
            <AccountColorsWidget onEdit={(key:string, value:AccountColors | AccountOptions)=>editAccount(key, value)} />
            }

          </div>
        </ListItem>

        <ListItem>
          <div>

            { props.isNew ?
            <Checkbox active={advancedSettings}
              text={t("utils_advanced_settings")}
              onClick={()=>setAdvancedSettings(!advancedSettings)}
            />
            :
            <div onClick={()=>setAdvancedSettings(!advancedSettings)}
              className="_hover">
              <b>{t("utils_advanced_settings")}</b>
            </div>
            }
          

            { advancedSettings &&
            <div>

              <div className="height-20"/>

              <ModulesWidget 
                availableModules={props._session.modules} 
                modules={props.account.active.modules} 
                onChange={(modules:AccountModules)=>editAccount("modules", modules)}
              />
              
              <div className="height-20"/>

              <div className="flex1">
                <div className="text-input-title grey-t"
                  style={{ marginBottom : '12px' }}>
                  {t("account_participation_min")}
                </div>

                <input type="range"
                  id="participation"
                  min={props._session.participationMin}
                  max={20}
                  value={props.account.active.participationMin}
                  onChange={(event:any)=>editAccount("participationMin", event.target.value)}
                  style={{ width:"200px" }}
                  list="tickmarks"
                />
                
                <datalist id="tickmarks">
                  <option value="0" label="0"></option>
                  <option value="1"></option>
                  <option value="2"></option>
                  <option value="3"></option>
                  <option value="4"></option>
                  <option value="5" label="5"></option>
                  <option value="6"></option>
                  <option value="7"></option>
                  <option value="8"></option>
                  <option value="9"></option>
                  <option value="10" label="10"></option>
                  <option value="11"></option>
                  <option value="12"></option>
                  <option value="13"></option>
                  <option value="14"></option>
                  <option value="15" label="15"></option>
                  <option value="16"></option>
                  <option value="17"></option>
                  <option value="18"></option>
                  <option value="19"></option>
                  <option value="20" label="20"></option>
                </datalist>

                <p>{t("survey_no_results_help", {count : props.account.active.participationMin})}</p>

              </div>

              <div className="flex1">
                <div className="text-input-title grey-t"
                  style={{ marginBottom : '12px' }}>
                  {t("account_participation_test")}
                </div>

                <input type="range"
                  id="ceils-testers"
                  min={5}
                  max={props._session.accountOptions.ceilsTesters}
                  value={props.account.active.options.ceilsTesters}
                  onChange={(event:any)=>editAccount("options", Object.assign({}, props.account.active.options, { ceilsTesters : event.target.value }) )}
                  style={{ width:"200px" }}
                  list="tickmarks-ceils-testers"
                />
                
                <datalist id="tickmarks-ceils-testers">
                  <option value="0" label="0"></option>
                  <option value="1"></option>
                  <option value="2"></option>
                  <option value="3"></option>
                  <option value="4"></option>
                  <option value="5" label="5"></option>
                  <option value="6"></option>
                  <option value="7"></option>
                  <option value="8"></option>
                  <option value="9"></option>
                  <option value="10" label="10"></option>
                  <option value="11"></option>
                  <option value="12"></option>
                  <option value="13"></option>
                  <option value="14"></option>
                  <option value="15" label="15"></option>
                  <option value="16"></option>
                  <option value="17"></option>
                  <option value="18"></option>
                  <option value="19"></option>
                  <option value="20" label="20"></option>
                </datalist>

                <p>{t("survey_testers_count_help", { count : props.account.active.options.ceilsTesters })}</p>

              </div>

              <Dropdown displayField="name"
                title={t("account_zone")}
                list={ZONES}
                value={t("account_zone_" +  props.account.active.options.zone.toLowerCase() )}
                onSelect={(e) => editAccount("options", Object.assign({}, props.account.active.options, { zone : e.id}))}
              />

              <div className="height-60"/>

            </div>
            }
            
          </div>
        </ListItem>

      </div>

      <div className="height-20"/>

    </Modal>
  )

}

const mapStateToProps = state => ({
  _session : state._session,
  account : state.account
})

export default connect(mapStateToProps)(withTranslation()(EditAccountModal))