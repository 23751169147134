/** 
 * DELETE-LESSON.MODAL
 * Delete a lesson
 */

import { withTranslation, WithTranslation } from "react-i18next"
import ModalConfirm from "@/components/modal-confirm"
import { store } from "@/index"
import { Lesson } from "@/redux/lesson.types";
import { lessonDestroy, lessonDestroy_AsSupervisor, lessonRemove, lessonStatus } from "@/redux/lesson.actions";
import { toast } from "react-toastify";
import { STATUS_LOADED, STATUS_LOADING, STATUS_LOAD_ERROR } from "@/redux/_status.types";

interface OwnProps{
  onClose : Function,
  onConfirm? : Function,
  lesson : Lesson,
  fromSupervisor : boolean
}

type Props = OwnProps & WithTranslation

function DeleteLessonModal(props: Props) {
  const { t } = props

  function close(){
    props.onClose()
  }

  async function deleteConfirm(){
    store.dispatch(lessonStatus(STATUS_LOADING))
    let response:any

    if(props.fromSupervisor){
      response = await store.dispatch(lessonDestroy_AsSupervisor(props.lesson.id))
    }
    else{
      response = await store.dispatch(lessonDestroy(props.lesson.id))
    }

    if(response.id === props.lesson.id){
      store.dispatch(lessonRemove(response.id))
      store.dispatch(lessonStatus(STATUS_LOADED))
      toast(t("lesson_deleted"))

      close()

      if(props.onConfirm){
        props.onConfirm()
      }
    }
    else{
      store.dispatch(lessonStatus(STATUS_LOAD_ERROR))
      if(response.error){
        toast(t(response.error), { type : 'error' })
      }else{
        toast(t("error_occurred"), { type : 'error' })
      }
    }

    close()
  }

  return (
    <ModalConfirm
      onNo={close}
      onYes={deleteConfirm}
      text={t("lesson_delete_confirm") +" "+ props.lesson.label}
      textBold={/*lesson used in multiple learnings ? avert*/t("question_delete_from_learning")}
    />
  )

}

export default withTranslation()(DeleteLessonModal)