/**
 * ONBOARDING
 * Onboarding to discover app
 */

import { faPlayCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "@/components/button";
import Card from "@/components/card";
import Space from "@/components/space";
import { Trans, withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { Session } from "@/redux/_session.types";
import i18n from "@/translate/i18n";
import OnboardingWidget from "@/widgets/onboarding.widget";

interface Props extends WithTranslation{
  _session : Session
}

function Onboarding(props: Props){
  const { t } = props
  const navigate: NavigateFunction = useNavigate()

  return(
    <div className="flex"
      style={{
        width: "100vw",
        height: "100vh"
      }}>

      { 
      /** intro page */
      window.location.pathname === "/onboarding/intro/0"
      ?
      <div className="flex flex-dcol flex1 onboarding-intro">

        <Space/>

        <div className="flex">

          <Space/>

          <Card>

            <div className="flex">

              <div className="flex1"
                style={{ padding : '40px' }}>

                <h1>
                  <Trans components={[<span key={"trans"} className="green-t"/>]}>
                    {t("onboarding_start_text")}
                  </Trans>
                </h1>

                <div className="flex" style={{ marginTop : '36px' }}>
                  <Space/>
                  <Button className="primary" isLarge
                    isFullWidth
                    onClick={() => navigate("/onboarding/templates/0")}>
                    {t("onboarding_start_cta")}
                  </Button>
                  <Space/>
                </div>

                <div className="flex" style={{ marginTop : '8px' }}>
                  <Space/>
                  <Button onClick={() => window.open(i18n.language === "fr" ? "https://id-tree.com" : "https://id-tree.com/en")}>
                    {t("utils_back")}
                  </Button>
                  <Space/>
                </div>

              </div>

              <div className="flex1">
              </div>

            </div>

            <img src={require("@/assets/onboarding/intro1.png")}
              alt="intro-1"
              style={{
                position : 'absolute',
                height : '20px',
                bottom : '90px',
                left : '396px',
                opacity : 0.9
              }}
            />

            <img src={require("@/assets/onboarding/intro2.png")}
              alt="intro-2"
              style={{
                position : 'absolute',
                height : '20px',
                bottom : '290px',
                left : '456px',
                opacity : 0.9
              }}
            />

            <img src={require("@/assets/onboarding/arrow.png")}
              alt="arrow-1"
              style={{
                position : 'absolute',
                height : '50px',
                bottom : '110px',
                left : '344px',
                transform : 'rotate(84deg) scaleY(-1)'
              }}
            />
            
            <img src={require("@/assets/onboarding/arrow.png")}
              alt="arrow-2"
              style={{
                position : 'absolute',
                height : '50px',
                bottom : '296px',
                right : '196px',
                transform : 'rotate(185deg) scaleY(-1)'
              }}
            />

            <img src={require("@/assets/onboarding/image1.png")}
              alt="onboarding-1"
              style={{
                position : 'absolute',
                width : '360px',
                top : '60px',
                right : '60px'
              }}
            />

            <img src={require("@/assets/onboarding/image2.png")}
              alt="onboarding-2"
              style={{
                position : 'absolute',
                width : '300px',
                bottom : '40px',
                left : '40px'
              }}
            />

            <img src={require("@/assets/onboarding/background.png")}
              alt="background"
              style={{
                position : 'absolute',
                width : '400px',
                bottom : '0px',
                right : '0px',
                borderRadius : '8px'
              }}
            />

            { props._session.language === "fr" &&
            <div className="onboarding-video _hover rel"
              onClick={() => navigate("/onboarding/video/IVJJKGNxtkE")}>

              <div className="onboarding-video-icon">
                <FontAwesomeIcon icon={faPlayCircle}/>
              </div>

              <img src={require("@/assets/videos/IVJJKGNxtkE.png")}
                alt="background"
                style={{
                  width : '260px',
                  borderRadius : '8px'
                }}
              />
              
            </div>
            }

          </Card>

          <Space/>

        </div>

        <Space/>

      </div>
      : 
      /** display onboarding widget if page is not intro */
      <OnboardingWidget/>
      }

    </div>
  )
}

const mapStateToProps = state => ({
  _session : state._session
})

export default connect(mapStateToProps)(withTranslation()(Onboarding))