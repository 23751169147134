/***
 * BOOKMARKS.WIDGET
 * Manager order of view for a survey
 */
import { connect } from "react-redux";
import { WithTranslation, withTranslation } from "react-i18next"
import { Draggable, Droppable, DragDropContext } from 'react-beautiful-dnd';
import Chip from "@/components/chip"
import { useState } from "react";
import { Session } from "@/redux/_session.types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { store } from "@/index";
import { TopicState } from "@/redux/topic.types";
import { Bookmark, BookmarkState } from "@/redux/bookmark.types";
import { filterReplaceDashboard } from "@/redux/filter.actions";
import { DashboardFilters, FilterState } from "@/redux/filter.types";
import { SurveyState } from "@/redux/survey.types";
import { bookmarkActivate, bookmarkDestroy, bookmarkRemove, bookmarkStatus, bookmarkSwapAndUpdate } from "@/redux/bookmark.actions";
import { STATUS_SAVED, STATUS_SAVE_ERROR, STATUS_SAVING } from "@/redux/_status.types";
import { surveyEdit, surveyUpdateBookmark } from "@/redux/survey.actions";
import ModalConfirm from "@/components/modal-confirm";
import selectBookmark from "@/utils/select-bookmark.utils";
import { NavigateFunction, useNavigate } from "react-router-dom";

interface StateProps extends WithTranslation{
  _session : Session
  bookmark : BookmarkState
  filter : FilterState
  survey : SurveyState
  topic : TopicState
}

const MODAL_BOOKMARK_DELETE = "MODAL_BOOKMARK_DELETE"

function Bookmarks(props:StateProps){
  const { t } = props
  const navigate: NavigateFunction = useNavigate()

  //currentBookmark > store information before delete
  //currentModal > dialog to display
  const [currentBookmark, setCurrentBookmark] = useState<Bookmark>(new Bookmark())
  const [currentModal, setCurrentModal] = useState<string | null>(null)

  //Delete selected bookmark
  function deleteBookmark(bookmark: Bookmark){
    setCurrentBookmark(bookmark)
    setCurrentModal(MODAL_BOOKMARK_DELETE)
  }

  //Confirm delete
  async function deleteBookmarkConfirm(){
    const bookmarkOrders = props.survey.active.bookmarksOrder.filter(x => x !== currentBookmark.id)
    store.dispatch(bookmarkStatus(STATUS_SAVING))
    const response:any = store.dispatch(bookmarkDestroy(currentBookmark.id))
    if (response.error){
      store.dispatch(bookmarkStatus(STATUS_SAVE_ERROR))
    }else{
      store.dispatch(surveyEdit("bookmarksOrder", bookmarkOrders))
      store.dispatch(surveyUpdateBookmark(props.survey.active.id, bookmarkOrders))
      store.dispatch(bookmarkRemove(currentBookmark.id))
      store.dispatch(bookmarkStatus(STATUS_SAVED))
      setCurrentModal(null)
    }
  }
  //Reset : home page without filter
  function resetBookmark(){
    navigate("/dashboard/overview")
    store.dispatch(bookmarkActivate(new Bookmark()))
    store.dispatch(filterReplaceDashboard(new DashboardFilters()))
  }

  //Select bookmark
  function clickBookmark(bookmark:Bookmark){
    if (props.bookmark.active.id === bookmark.id){
      store.dispatch(bookmarkActivate(new Bookmark()))
    }else{
      const destination = selectBookmark(bookmark)
      navigate(destination)
    }
  }

  //Switch between two bookmarks
  function swapBookmark(e){
    if (e.destination){
      store.dispatch(bookmarkSwapAndUpdate(e.draggableId, e.destination.droppableId))
    }
  }
  
  return (
    props.bookmark.list.length > 0
    ?
    <div className="nav-dashboard-subheader flex medgrey-b flex-wrap"
      style={{ backgroundColor : "#dbdbdb" }}>

      { currentModal === MODAL_BOOKMARK_DELETE &&
      <ModalConfirm onNo={() => setCurrentModal(null)}
        onYes={deleteBookmarkConfirm}
        text={t("bookmark_ask_delete", {
          bookmark : currentBookmark.name
        })}
        status={props.bookmark.status}
        textBold={t("utils_next_ask")}
      />
      }

      <Chip isWithMargin
        onClick={resetBookmark}>
        <FontAwesomeIcon icon={faHome}/>
      </Chip>

      <DragDropContext onDragEnd={swapBookmark}>

        { props.bookmark.list.map((bookmark: Bookmark) =>
        <Droppable droppableId={bookmark.id} 
          direction="horizontal"
          type="BOOKMARK"
          key={bookmark.id}>
          { provided => (
          <div ref={provided.innerRef}
            {...provided.droppableProps}>

            <Draggable draggableId={bookmark.id} index={0}>
              {(provided) => (
              <div ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}>
      
                <Chip isWithMargin 
                  color={props.bookmark.active.id === bookmark.id ? props._session.accountColors.active : "#8C8C8C"}
                  onDelete={() => deleteBookmark(bookmark)}
                  onClick={() => clickBookmark(bookmark)}>
                  {bookmark.name}
                </Chip>

              </div>
              )}
            </Draggable>

            {provided.placeholder}

          </div>
          )
          }
        </Droppable>
        )
        }

      </DragDropContext>

    </div>
    :
    null
  )
}

const mapStateToProps = state => ({
  _session : state._session,
  bookmark : state.bookmark,
  filter : state.filter,
  survey : state.survey,
  topic : state.topic
})

export default connect(mapStateToProps)(withTranslation()(Bookmarks))
