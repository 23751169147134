/**
 * ONBOARDING-SIGNUP
 * Create an account
 */

import { Component } from "react";
import OnboardingSignupWidget from "@/widgets/onboarding-signup.widget";

class OnboardingSignup extends Component{
  render () {
    return(
      <OnboardingSignupWidget/>
    )
  }
}

export default OnboardingSignup