/**
 * INEQUALITIES.HELP
 * 
 */

import { countBy } from "lodash"
import { WithTranslation, withTranslation } from "react-i18next"
import { INEQUALITY_COLORS } from "@/widgets/dashboard-inequalities-display.widget"

type Props = WithTranslation

const SCORE_SAMPLE: number[] = [0, 0, 25, 25, 25, 25, 50, 50, 75, 75, 75, 100, 100, 100, 100, 100, 100, 100, 100]
const NB_NO_ANSWER: number = 1
const CEILS: number[] = [0, 0.2, 0.3, 0.4, 0.5]

function InequalitiesHelp(props: Props){
  const { t } = props

  function getCeilsRows(ceils: number[]): JSX.Element[]{
    return ceils.map((ceil: number, index: number) =>
      <tr key={index}>
        <td>{(index < ceils.length - 1 ? t("utils_comparison_between", { n1: ceil, n2: ceils[index + 1] }) : t("utils_comparison_more_than", { number: ceil }))}</td>
        <td>{index}</td>
      </tr>
    )
  }

  function getInequalityLevel(ceils: number[], gini: number | null): number{
    if(gini){
      let level: number = ceils.length - 1
      while(gini < ceils[level]){
        level--
      }
      return (level + 1)
    }
    else return 0
  }

  function getMean(sample: number[]): number{
    return sample.reduce((accumulator: number, a: number) => accumulator + a, 0) / sample.length
  }

  function getGini(sample: number[]): number | null{
    const sortedData: number[] = sample.sort((a: number, b: number) =>
      a - b
    )
    const sampleSize: number = sortedData.length
    
    let gini: number | null = 0

    if(sampleSize > 0){
        
      const totalNotes: number = sortedData.reduce((accumulator: number, a: number) => accumulator + a, 0)

      if(totalNotes > 0){
        let weightedSum: number = 0
        sortedData.forEach((value: number, index: number) =>
          weightedSum += (index + 1) * value
        )

        gini = (2 * weightedSum / (totalNotes > 0 ? totalNotes : 1) - (sampleSize + 1)) / sampleSize
      }
      
    }
    else{
      gini = null
    }

    return gini
  }

  return(
    <div>
      <p>{`Le module "Inégalités" vous permet de mesurer et comparer les inégalités entre les populations de votre entreprise, question par question.`}</p>
      <p>{`L'inégalité est mesurée sur une echelle de 1 à 5 et est représentée par une couleur`}</p>

      <table>
        <thead>
          <tr>
            <th>{t("utils_level")}</th>
            <th>{t("color")}</th>
            <th>{`Inégalité`}</th>
          </tr>
        </thead>
        <tbody>
          {INEQUALITY_COLORS.map((color: string, index: number) =>
          <tr key={index}>
            <td>{index}</td>
            <td>
              <div style={{
                backgroundColor: color,
                borderRadius: 30,
                height: 30,
                width: 30
              }}/>
            </td>
            <td>{t("disparity_level_" + index)}</td>
          </tr>
          )}
        </tbody>
      </table>

      <p>{`Le première colonne de résultats représente la tendence générale (toutes populations confondues), les colonnes suivantes sont spécifiques aux populations du filtre sélectionné.
        Seules les questions de type "echelle de valeurs" apparaissent ici.`}
      </p>

      <p>{`Comment est calculé le niveau d'inégalité ?`}</p>

      <p>{`Pour chaque question, un score sur 10 est calculé à partir de la réponse de chaque collaborateur. Grâce à l'échantillon ainsi obtenu nous calculons le coefficient de Gini.`}</p>

      <p>{`Le coefficient de Gini, ou indice de Gini, est une mesure statistique permettant de rendre compte de la répartition d'une variable au sein d'une population. Principalement il mesure le degré d'inégalité des revenus d'un pays. (Wikipedia)`}</p>
        
      <p>{`Le coefficient obtenu est défini par un nombre variant de 0 à 1.
        Plus le nombre se rapproche de 1, plus l'inégalité perçue sera forte.
        Un coefficient de 0 signifie une égalité parfaite (tous les collaborateurs de l'échantillon ont répondu la même chose). Un coefficient de 1 signifie une inégalité totale.
      `}</p>

      <p>{`A partir du coefficient obtenu nous avons détérminé le niveau d'inégalité comme suit:`}</p>

      <table>
        <thead>
          <tr>
            <th>{"Gini"}</th>
            <th>{t("utils_level")}</th>
          </tr>
        </thead>
        <tbody>
          {getCeilsRows(CEILS)}
        </tbody>
      </table>

      <p>{`Exemple concret:`}</p>

      <p>{t("Voici les réponses d'une population de {{count}} personnes pour une question donnée :", { count: SCORE_SAMPLE.length + NB_NO_ANSWER}) + ""}</p>

      <ul>
        {Object.values(countBy(SCORE_SAMPLE, Math.floor)).map((number: number, index: number) =>
        <li key={index}>{`${number} ${t(number > 1 ? "propositions" : "proposition")} "${t("question_likert_" + (index + 1))}" `}</li>
        )}
        <li>{`${NB_NO_ANSWER} ${t("personne à choisi de ne pas se prononcer")}`}</li>
      </ul>

      <p>{`pour une question de type "echelle de valeurs" sur 5 et non inversée les scores sont attribués comme suit:`}</p>

      <ul>
        {[...Array(5).keys()].map((index: number) =>
        <li key={index}>
          {t("question_score_help_response_" + (index + 1))}
        </li>
        )}
      </ul>

      <p>{`Ainsi une fois les réponses de nos collaborateurs converties en score nous obtenons l'échantillon suivant:`}</p>
      <div>{`[${SCORE_SAMPLE.map((x: number) => x/10).join(", ")}]`}</div>

      <p>{`Le coefficient de Gini est calculé à partir de la formule suivante:`}</p>

      <b>{`GINI`}</b>
 
      <p>{t("Pour notre échantillon le coefficient obtenu est ", { coef: getGini(SCORE_SAMPLE) }) + " " + getGini(SCORE_SAMPLE)?.toFixed(3)}</p>
      <p>{`A partir de notre tableau de correspondance nous évaluons le niveau d'inégalité à ${getInequalityLevel(CEILS, getGini(SCORE_SAMPLE)!)} soit une inégalité moderée.`}</p>

      <p>{`Que peut-t-on en conclure ?`}</p>
      <p>{t("La note moyenne obtenue sur la question est de ", { mean: ( getMean(SCORE_SAMPLE) / 10 ).toFixed(1) }) + "" + ( getMean(SCORE_SAMPLE) / 10 ).toFixed(1) }</p>
      <p>{`Mais la disparité des réponses est plutôt hétérogène ce qui signifie que le ressenti des collaborateurs varie significativement d'un individu à l'autre. Cela peut-être dû à un traitement inégalitaire.`}</p>

    </div>
  )
}

export default withTranslation()(InequalitiesHelp)