/** 
 * ACCOUNT.ACTIONS
 * Actions related to idtree accounts
 */

import { Account, AccountAdsOptions, AccountColors, AccountModules, AccountOptions, AccountTemplateRule, ACCOUNT_ACTIVATE, ACCOUNT_ADD, ACCOUNT_EDIT, ACCOUNT_GET, ACCOUNT_GET_COUNT, ACCOUNT_GET_COUNT_SEARCH, ACCOUNT_INIT, ACCOUNT_ORDER, ACCOUNT_REMOVE, ACCOUNT_STATUS } from "./account.types"
import { CONNEXION_MODE } from "./_session.types"

export const accountActivate = (account:Account) => ({
  type : ACCOUNT_ACTIVATE,
  payload : {
    account
  }
})

export const accountAdd = (supervisorId, firstname, lastname) => ({
  type : ACCOUNT_ADD,
  payload : {
    supervisorId,
    firstname,
    lastname
  }
})

export const accountEdit = (key:string, value:any) => ({
  type : ACCOUNT_EDIT,
  payload : {
    key,
    value
  }
})

export const accountGet = (accounts:Account[]) => ({
  type : ACCOUNT_GET,
  payload : {
    accounts
  }
})

export const accountGetCount = (count:number) => ({
  type : ACCOUNT_GET_COUNT,
  payload : {
    count
  }
})

export const accountGetCountSearch = (countSearch:number) => ({
  type : ACCOUNT_GET_COUNT_SEARCH,
  payload : {
    countSearch
  }
})

export const accountInit = () => ({
  type : ACCOUNT_INIT
})

export const accountOrder = (ascOrDesc:"asc" | "desc", order:string) => ({
  type : ACCOUNT_ORDER,
  payload : {
    ascOrDesc,
    order
  }
})

export const accountRemove = (id:string) => ({
  type : ACCOUNT_REMOVE,
  payload : {
    id
  }
})

export const accountStatus = (status:string) => ({
  type : ACCOUNT_STATUS,
  payload : {
    status
  }
})


//API

export const accountConnect = (accountId, email) => ({
  type : 'API',
  payload : {
    method : "POST",
    url : "/account/connect",
    data : {
      accountId,
      email
    }
  }
})

export const accountConnect_AsSupervisor = (accountId, email) => ({
  type : 'API',
  payload : {
    method : "POST",
    url : "/supervisor/account/connect",
    data : {
      accountId,
      email
    }
  }
})

export const accountConvertToSuperaccount = (accountId) => ({
  type : 'API',
  payload : {
    method : "POST",
    url : "/supervisor/account/convert-to-superaccount",
    data : {
      accountId
    }
  }
})

export const accountConfirm = (userId, code) => ({
  type : 'API',
  payload : {
    method : "POST",
    url : "/public/account/confirm",
    data : {
      userId,
      code
    }
  }
})

export const accountDestroy = (id:string) => ({
  type : 'API',
  payload : {
    method : "DELETE",
    url : "/account/" + id
  }
})

export const accountDestroy_AsSupervisor = (id:string) => ({
  type : 'API',
  payload : {
    method : "DELETE",
    url : "/supervisor/account/" + id
  }
})

//for multiple, get all sub-accounts
export const accountFetch_AsSuperadmin = () => ({
  type : 'API',
  payload : {
    method : "GET",
    url : "/superadmin/accounts",
  }
})

//same function as above, returns only acount's id, name, options and colors (used for superadmin lateral bar)
export const accountFetchLite_AsSuperadmin = () => ({
  type : 'API',
  payload : {
    method : "GET",
    url : "/superadmin/accounts/lite",
  }
})

export const accountFetch_AsSupervisor = (search:string, offset:number, limit:number, isMyAccount:boolean) => ({
  type : 'API',
  payload : {
    method : "GET",
    url : "/supervisor/accounts",
    data : {
      search, 
      offset, 
      limit,
      isMyAccount
    }
  }
})

export const accountFetchCount_AsSuperadmin = (search:string) => ({
  type : 'API',
  payload : {
    method : "GET",
    url : "/superadmin/account/count",
    data : {
      search
    }
  }
})

export const accountFetchCount_AsSupervisor = (search:string, isMyAccount:boolean) => ({
  type : 'API',
  payload : {
    method : "GET",
    url : "/supervisor/account/count",
    data : {
      search,
      isMyAccount
    }
  }
})

export const accountFetchInvitations = () => ({
  type : 'API',
  payload : {
    method : "GET",
    url : "/account/invitations",
  }
})

export const accountFetchInvitations_AsSupervisor = (accountId: string) => ({
  type : 'API',
  payload : {
    method : "GET",
    url : "/supervisor/account/invitations",
    data : {
      accountId
    }
  }
})

export const accountInsertPublic = (
  accountAdsOptions:AccountAdsOptions,
  accountColors:AccountColors,
  connexionMode:CONNEXION_MODE,
  email:string,
  id:string,
  language:string,
  name:string,
  firstname:string,
  lastname:string,
  phone:string,
  isWithImage:boolean,
  googlePhotoURL:string | null
) => ({
  type : 'API',
  payload : {
    method : "POST",
    url : "/public/account/insert",
    data : {
      accountAdsOptions,
      accountColors,
      connexionMode,
      email,
      id,
      language,
      name,
      firstname,
      lastname,
      phone,
      isWithImage,
      googlePhotoURL
    }
  }
})

export const accountInsert_AsSupervisor = account => ({
  type : 'API',
  payload : {
    method : "POST",
    url : "/supervisor/account/insert",
    data : {
      account
    }
  }
})

export const accountPay = (token, email, price, count) => ({
  type : 'API',
  payload : {
    method : "POST",
    url : "/account/payment",
    data : {
      token,
      email,
      price,
      count
    }
  }
})

export const accountQuit = (toSupervisor) => ({
  type : 'API',
  payload : {
    method : "POST",
    url : "/account/quit",
    data : {
      toSupervisor
    }
  }
})

export const accountUpdate = (account:any) => ({
  type : 'API',
  payload : {
    method : "POST",
    url : "/account",
    data : {
      account
    }
  }
})


export const accountInsert_AsSuperadmin = (account:Account) => ({
  type : 'API',
  payload : {
    method : "POST",
    url : "/superadmin/account/insert",
    data : {
      account
    }
  }
})


export const accountUpdate_AsSuperadmin = (account:Account) => ({
  type : 'API',
  payload : {
    method : "POST",
    url : "/superadmin/account/update",
    data : {
      account
    }
  }
})

export const accountUpdateModules_AsSupervisor = (accountId:string, modules:AccountModules) => ({
  type : 'API',
  payload : {
    method : "POST",
    url : "/supervisor/account/modules",
    data : {
      accountId,
      modules
    }
  }
})

export const accountUpdateColors = (colors:AccountColors) => ({
  type : 'API',
  payload : {
    method : "POST",
    url : "/account/colors",
    data : {
      colors
    }
  }
})

export const accountUpdateOptions = (options:AccountOptions) => ({
  type : 'API',
  payload : {
    method : "POST",
    url : "/account/options",
    data : {
      options
    }
  }
})

export const accountUpdateTemplateRules = (accountId:string, templateRule:AccountTemplateRule, allowedTemplates:string[]) => ({
  type : 'API',
  payload : {
    method : "POST",
    url : "/account/template/rules",
    data : {
      accountId,
      templateRule,
      allowedTemplates
    }
  }
})

export const accountUpdate_AsSupervisor = account => ({
  type : 'API',
  payload : {
    method : "POST",
    url : "/supervisor/account/update",
    data : {
      account
    }
  }
})