/** 
 * ADM-SELECT-ROLE.MODAL
 * Set role for one user
 */

import Modal from "@/components/modal"
import { connect } from "react-redux"
import { withTranslation, WithTranslation } from "react-i18next"
import { User, USER_ROLE, USER_ROLES, UserState } from "@/redux/user.types";
import { useState } from "react";
import { store } from "@/index";
import { userCopyAndUpdateRole, userEditAndUpdateRole, userRemove, userRemoveAndUpdateRole } from "@/redux/user.actions";
import { toast } from "react-toastify";
import { Population } from "@/redux/population.types";
import Space from "@/components/space";
import CardContainer from "@/components/card-container";
import CardButton from "@/components/card-button";
import ReactMarkdown from "react-markdown";
import SelectPopulationsModal from "./select-populations.modal";
import AdmSendEmailModal from "./adm-send-email.modal";
import { Session } from "@/redux/_session.types";

interface StateProps{
  _session : Session
  user : UserState
}

interface OwnProps{
  currentUser : User
  onClose : Function
}

type Props = StateProps & OwnProps & WithTranslation

const MODAL_SELECT_POPULATIONS = "MODAL_SELECT_POPULATIONS"
const MODAL_SEND_EMAIL = "MODAL_SEND_EMAIL"

function AdmSelectRoleModal(props: Props) {
  const { t } = props

  //Modal to display
  const [currentModal, setCurrentModal] = useState<string | null>(null)

  //Current role selected
  const [selectedRole, setSelectedRole] = useState<USER_ROLE>(props.currentUser.role ? props.currentUser.role : USER_ROLES[0].id)

  //Detect if the user is a new user
  const [isNew] = useState(props.user.list.findIndex(x => x.id === props.currentUser.id) === -1)
  
  //Close the modal
  function closeModal(){

    if (isNew){
      store.dispatch(userRemove(props.currentUser.id))
    }
    
    props.onClose()
    
  }

  //Confirm role
  function confirmRole(){
    switch (selectedRole){
      case "OBSERVER":
        return setCurrentModal(MODAL_SELECT_POPULATIONS)
      default:
        setRole(props.currentUser, selectedRole, [])
    }
  }

  //Change role
  //Si user is admin
  //>>> if role = null > remove from list
  //>>> else set role (admin or editor or observer)
  //Else add to list (as admin)
  function setRole(user:User, role:USER_ROLE | null, populations:Population[]){

    if(user.role){
      user.role = role
      if (!role){
        store.dispatch(userRemoveAndUpdateRole(user))
        toast(t("user_admin_remove_confirmed", { name : props.user.active.username }))
      }else{
        store.dispatch(userEditAndUpdateRole(user, role, populations, []))
      }
    }
    else{
      user.role = role
      if(props.user.list.find((x: User)=>x.id === user.id)){
        store.dispatch(userEditAndUpdateRole(user, role, populations, []))
      }
      else{
        store.dispatch(userCopyAndUpdateRole(user, role, populations))
      }
    }

    setCurrentModal(MODAL_SEND_EMAIL)
    //props.onClose()

  }

  return (
    <Modal isCloseButtonVisible
      onClose={() => closeModal()}
      onNext={() => confirmRole()}>

      { currentModal === MODAL_SEND_EMAIL &&
      <AdmSendEmailModal currentUser={props.currentUser}
        onClose={() => props.onClose()}
        text=""
        textBold={t("adm_roles_send_now", {
          name : props.currentUser.username
        })}/>
      }
      
      { currentModal === MODAL_SELECT_POPULATIONS &&
      <SelectPopulationsModal
        excludedFilters={props.currentUser.excludedFilters}
        isWithoutDefaultFilters
        isSurveyFiltersIncluded
        selectedPopulations={[]}
        onNext={(populations:Population[]) => setRole(props.currentUser, "OBSERVER", populations)}
        title={t("roles_select_populations", {
          name : props.currentUser.username
        })}
      />
      }

      <div className="flex">
        <Space/>

        <div style={{
            width : 560
          }}>

          <h2 style={{
              textAlign : "center",
              marginTop : 0
            }}>
            {t("roles_select", {
              name : props.currentUser.username
            })}
          </h2>

          <CardContainer>

            { USER_ROLES.map(role =>
            <CardButton key={role.id}
              isActive={role.id === selectedRole}
              title={t("roles_" + role.id.toLowerCase())}
              icon={role.icon}
              onClick={() => setSelectedRole(role.id)}
            />
            )
            }

          </CardContainer>

          <div style={{
              textAlign : "center",
              color : props._session.accountColors.active
            }}>
            <ReactMarkdown linkTarget="_blank">
              {t("roles_" + selectedRole.toLowerCase() + "_help")}
            </ReactMarkdown>
          </div>

        </div>

        <Space/>
      </div>
    </Modal>
  )

}

const mapStateToProps = state => ({
  _session : state._session,
  user : state.user
})

export default connect(mapStateToProps)(withTranslation()(AdmSelectRoleModal))