/** 
 * DASHBOARD-NPS-METER
 * Interactive meter for nps
 */

import Space from "@/components/space"
import { useEffect, useState } from "react"

interface OwnProps{
  ceilBad: number
  ceilGood: number
  score: number
}

type Props = OwnProps

const WIDGET_SIZE: number = 80 

function DashboardNpsMeterWidget(props:Props){

  const [angle, setAngle] = useState<number>(0)

  useEffect(() => {
    setTimeout(() => {
      setAngle(getRotation())
    }, 1000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function getRotation(){
    const unit: number = (props.ceilGood - props.ceilBad) / 4
    if(props.score + 2 * unit < props.ceilBad){
      return 30
    }
    if(props.score > props.ceilGood + 3 * unit) {
      return 165
    }
    else{
      return 165 - ((props.ceilGood + 3 * unit - props.score) / unit ) * 15
    }
  }

  return (
    <div style={{position: "relative"}}>

      <div className="flex" style={{ 
          height: 20,
          width: WIDGET_SIZE 
        }}>

        <Space/>

        <div>
          {props.ceilBad}
        </div>

        <div style={{ width: WIDGET_SIZE / 2 }}/>
        
        <div>
          {props.ceilGood}
        </div>

        <Space/>

      </div>

      <img src={require("@/assets/enps_meter.png")}
        alt={"eNPS meter"}
        style={{position: "absolute"}}
        width={WIDGET_SIZE}
      />
      
      <img src={require("@/assets/enps_needle.png")}
        alt={"eNPS needle"}
        style={{
          position: "absolute",
          rotate: 0 + "deg",
          transform: `rotate(${angle}deg)`,
          transition: 'transform 2000ms ease',
        }}
        width={WIDGET_SIZE}
      />

    </div>
  )

}

export default DashboardNpsMeterWidget