import { ChangeEvent, useState } from "react"
import { useSelector } from "react-redux"
import { t } from "i18next"
import { Filter, FilterPopulation } from "@/redux/filter.types"
import { ACCOUNT_RESULTS_TYPES, AccountOptions } from "@/redux/account.types"
import { filterStatus } from "@/redux/filter.actions"
import { sessionEditAccountOptions } from "@/redux/_session.actions"
import { store } from "@/index"
import { STATUS_SAVE_ERROR, STATUS_SAVED, STATUS_SAVING } from "@/redux/_status.types"
import { accountUpdateOptions } from "@/redux/account.actions"
import { selectFilter } from "@/core/slices/filter/filter.selector"
import { selectSession } from "@/core/slices/session/session-selector"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSun, faCloud, faCloudBolt, faRotateLeft } from "@fortawesome/free-solid-svg-icons"
import Card from "@/components/card"
import Button from "@/components/button"
import Dropdown from "@/components/dropdown"
import MultiRangeSlider from "multi-range-slider-react"
import ListButton from "@/components/list-button"
import Tooltip from "@/components/tooltip"

export const GollacFiltersWidget = () => {
  const filter = useSelector(selectFilter)
  const session = useSelector(selectSession)

  // get min and max init gollac steps
  const initAcountOptions = new AccountOptions()
  const ceilsGollacDefaultValue = {
    min: initAcountOptions.ceilsGollacMinValue,
    max: initAcountOptions.ceilsGollacMaxValue
  }

  // GollacParams state
  const [gollacParams, setGollacParams] = useState<Partial<AccountOptions>>({
    gollacFilterName: session.accountOptions.gollacFilterName,
    gollacManagerAttributeName: session.accountOptions.gollacManagerAttributeName,
    ceilsGollacMinValue: session.accountOptions.ceilsGollacMinValue,
    ceilsGollacMaxValue: session.accountOptions.ceilsGollacMaxValue,
    gollacHeatmapViewDefault: session.accountOptions.gollacHeatmapViewDefault
  })

  // Fonction de réinitialisation
  const resetGollacParams = () => {
    setGollacParams((prev) => ({
      ...prev,
      ceilsGollacMinValue: ceilsGollacDefaultValue.min,
      ceilsGollacMaxValue: ceilsGollacDefaultValue.max
    }))
  }

  // Saving the updated GollacParams
  const handleSetGollacParams = async () => {
    // Vérification des valeurs obligatoires
    const requiredFields = [
      gollacParams.gollacFilterName,
      gollacParams.gollacManagerAttributeName,
      gollacParams.ceilsGollacMinValue,
      gollacParams.ceilsGollacMaxValue,
      gollacParams.gollacHeatmapViewDefault
    ]

    const hasNullValues = requiredFields.some((field) => field === null || field === undefined)

    if (hasNullValues) {
      store.dispatch(filterStatus("error"))

      alert("Veuillez remplir tous les champs obligatoires avant de sauvegarder.")
      return
    }

    // Statut en cours de sauvegarde
    store.dispatch(filterStatus(STATUS_SAVING))

    const response: any = await store.dispatch(
      accountUpdateOptions({
        ...session.accountOptions,
        gollacFilterName: gollacParams.gollacFilterName!,
        gollacManagerAttributeName: gollacParams.gollacManagerAttributeName!,
        ceilsGollacMinValue: gollacParams.ceilsGollacMinValue!,
        ceilsGollacMaxValue: gollacParams.ceilsGollacMaxValue!,
        gollacHeatmapViewDefault: gollacParams.gollacHeatmapViewDefault!
      })
    )

    if (response.error) {
      store.dispatch(filterStatus(STATUS_SAVE_ERROR))
    } else {
      for (const key in gollacParams) {
        store.dispatch(sessionEditAccountOptions([key], gollacParams[key]))
      }

      store.dispatch(filterStatus(STATUS_SAVED))
    }
  }

  const handleChangeGollacParams = (e: React.ChangeEvent<HTMLInputElement>, type: string) => {
    const value = e.target.value

    if (value !== "" || !isNaN(Number(value))) {
      // Si gollacFilterName change, réinitialiser gollacManagerAttributeName
      const resetManagerAttribute = type === "gollacFilterName" ? { gollacManagerAttributeName: null } : {}

      setGollacParams((prevParams) => {
        return {
          ...prevParams,
          [type]:
            type === "ceilsGollacMinValue" || type === "ceilsGollacMaxValue"
              ? value === ""
                ? undefined
                : parseInt(value, 10) // gestion des valeurs vides
              : value,
          ...resetManagerAttribute
        }
      })
    }
  }

  // mapping select
  const handleSelectChange = (selected: { name: string }, type: string) => {
    const simulatedEvent = { target: { value: selected.name } }
    handleChangeGollacParams(simulatedEvent as React.ChangeEvent<HTMLInputElement>, type)
  }

  // onChangeSlider
  const handleSliderChange = (e: any) => {
    // Vérification des valeurs issues du composant MultiRangeSlider
    const { minValue, maxValue } = e

    setGollacParams((prevParams) => ({
      ...prevParams,
      ceilsGollacMinValue: parseFloat(minValue),
      ceilsGollacMaxValue: parseFloat(maxValue)
    }))
  }

  return (
    <Card>
      <div className="mb-4">
        <b>{t("golac_params_title")} </b>
      </div>

      <div className="grid grid-cols-3 grid-rows-2 items-center">
        {/* Gollac Filter Name Select */}
        <Dropdown
          active={gollacParams.gollacFilterName}
          list={filter.list.map((filter: Filter) => ({
            id: filter.name,
            name: filter.name
          }))}
          value={gollacParams.gollacFilterName}
          displayField="name"
          onSelect={(selected: { name: string }) => handleSelectChange(selected, "gollacFilterName")}
          isNullAllowed
          title={t("filter_reference_manager_gollac")}
          status={filter.status}
        />

        {/* Gollac Manager Attribute Name Select */}
        {gollacParams.gollacFilterName && (
          <Dropdown
            active={gollacParams.gollacManagerAttributeName}
            list={
              filter.list
                .find((f: Filter) => f.name === gollacParams.gollacFilterName)
                ?.populations.map((pop: FilterPopulation) => ({
                  id: pop.name,
                  name: pop.name
                })) || []
            }
            value={gollacParams.gollacManagerAttributeName}
            displayField="name"
            onSelect={(selected: { name: string }) => handleSelectChange(selected, "gollacManagerAttributeName")}
            isNullAllowed
            title={t("filter_attribute_reference_manager_gollac")}
            status={filter.status}
          />
        )}

        {/* Gollac Heatmap View Default Select */}
        {gollacParams.gollacHeatmapViewDefault && (
          <Dropdown
            active={gollacParams.gollacHeatmapViewDefault}
            list={ACCOUNT_RESULTS_TYPES.map((view) => ({
              id: view,
              name: view
            }))}
            value={t(gollacParams.gollacHeatmapViewDefault)}
            displayField="name"
            onSelect={(selected: { name: string }) => handleSelectChange(selected, "gollacHeatmapViewDefault")}
            title={t("gollac_heatmap_view_default")}
            status="active"
          />
        )}

        {/* Ceil Gollac range step Min/Max Value */}
        <div className="col-span-2 mt-2">
          <div className="flex">
            <div className="text-idt-info-dark"> {t("account_ceil_nightmare_note")}</div>

            <Tooltip
              text={[
                t("gollac_init_step"),
                `${t("gollac_info_help_content_2_1")}  ${ceilsGollacDefaultValue.min}`,
                `${t("gollac_info_help_content_4_1")} ${ceilsGollacDefaultValue.max}`
              ]}
              position="right">
              <ListButton icon={faRotateLeft} onClick={resetGollacParams} />
            </Tooltip>
          </div>
          <MultiRangeSlider
            min={0}
            max={10}
            step={0.1}
            ruler={false}
            minValue={gollacParams.ceilsGollacMinValue}
            maxValue={gollacParams.ceilsGollacMaxValue}
            onInput={(e: ChangeEvent) => handleSliderChange(e)}
          />
        </div>
      </div>

      <div className="flex">
        <ul className="pl-6 list-disc space-y-2">
          <li>
            <strong>{t("gollac_info_help_content_2_1")}</strong> {t("gollac_info_help_content_2_2")}{" "}
            <strong>{session.accountOptions.ceilsGollacMinValue}</strong>{" "}
            <FontAwesomeIcon className="text-idt-green-main text-xl text-idt-ye" icon={faSun} />
          </li>
          <li>
            <strong>{t("gollac_info_help_content_3_1")}</strong> {t("gollac_info_help_content_3_1")}{" "}
            <strong>{session.accountOptions.ceilsGollacMinValue}</strong> {t("gollac_info_help_content_3_3")}{" "}
            <strong>{session.accountOptions.ceilsGollacMaxValue}</strong>{" "}
            <FontAwesomeIcon className="text-idt-yellow-main text-xl" icon={faCloud} />
          </li>
          <li>
            <strong>{t("gollac_info_help_content_4_1")}</strong> {t("gollac_info_help_content_4_2")}{" "}
            <strong>{session.accountOptions.ceilsGollacMaxValue}</strong>{" "}
            <FontAwesomeIcon className="text-idt-red-main text-xl" icon={faCloudBolt} />
          </li>
        </ul>
        <div className="flex-1" />
        <div className="justify-end mt-auto">
          <Button onClick={handleSetGollacParams} className="primary">
            {t("utils_save")}
          </Button>
        </div>
      </div>
    </Card>
  )
}
