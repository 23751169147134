/** 
 * ACTION-PLAN.REDUCERS
 * Update action plan store
 */

import { ActionPlan, ActionPlanState, ACTION_PLAN_ACTIVATE, ACTION_PLAN_EDIT, ACTION_PLAN_GET, ACTION_PLAN_REMOVE, ACTION_PLAN_STATUS, ACTION_PLAN_ADD, ACTION_PLAN_ADD_TASK, ActionPlanTask, ACTION_PLAN_REMOVE_TASK, ACTION_PLAN_INIT, ACTION_PLAN_EDIT_TASK, ACTION_PLAN_ADD_TASK_MESSAGE, ACTION_PLAN_REMOVE_TASK_MESSAGE } from "./action-plan.types";
import { STATUS_LOADING, STATUS_SAVING } from "./_status.types";
import { v4 as uuid } from "uuid";

let active:ActionPlan
let activeTask:ActionPlanTask

const initialState: ActionPlanState = {
  active : new ActionPlan(),
  list : [],
  status : STATUS_LOADING
}

export default function actionPlanReducer(
  state = initialState,
  action: any
): ActionPlanState {
  switch (action.type) {

    //Set as active action plan
    case ACTION_PLAN_ACTIVATE:
      return Object.assign({}, state, {
        active : new ActionPlan(action.payload.actionPlan)
      })

    //Create new action plan
    case ACTION_PLAN_ADD:
      active = new ActionPlan({
        id: uuid(),
        UserIdContact: action.payload.userContact ? action.payload.userContact.id : null,
        UserIdResponsible: action.payload.userResponsible ? action.payload.userResponsible.id : null,
        UserContact: action.payload.userContact,
        UserResponsible: action.payload.userResponsible,
        referenceSurveyId : action.payload.referenceSurveyId
      })
      return Object.assign({}, state, {
        active,
        list: [active, ...state.list]
      })

    //Add new tast for action plan
    case ACTION_PLAN_ADD_TASK:

      active = new ActionPlan(state.active)
      active.tasks.push(new ActionPlanTask({
        id : uuid(),
        text : action.payload.text,
        topicAid : action.payload.topicAid,
        createdAt : new Date(),
        createdBy : action.payload.createdBy
      }))

      return Object.assign({}, state, {
        active,
        status : STATUS_SAVING
      })

    //Add message to existing task
    case ACTION_PLAN_ADD_TASK_MESSAGE:

      active = new ActionPlan(state.active)
      activeTask = new ActionPlanTask(active.tasks.find(x => x.id === action.payload.taskId))
      activeTask.messages.push({
        id : uuid(),
        text : action.payload.text,
        user : action.payload.username,
        userId : action.payload.userId,
        date : new Date()
      })

      active.tasks = state.active.tasks.map(x => x.id === activeTask.id ? activeTask : x)

      return Object.assign({}, state, {
        active,
        status : STATUS_SAVING
      })

    //Edit action plan
    case ACTION_PLAN_EDIT:
      return Object.assign({}, state, { 
        active : Object.assign(state.active, { [action.payload.key] : action.payload.value }),
        status : action.payload.saveChanges ? STATUS_SAVING : state.status
      })

    //Edit action plan
    case ACTION_PLAN_EDIT_TASK:
      activeTask = new ActionPlanTask(action.payload.task)
      return Object.assign({}, state, { 
        active : Object.assign(state.active, { tasks : state.active.tasks.map(x => x.id === activeTask.id ? activeTask : x) }),
        status : STATUS_SAVING
      })

    //Save list to the store
    case ACTION_PLAN_GET:
      return Object.assign({}, state, {
        list : action.payload.actionPlans.map((x:any) => new ActionPlan(x))
      })

    //Init store on app load
    case ACTION_PLAN_INIT:
      return Object.assign({}, state, {
        active : new ActionPlan(state.active),
        list : state.list.map((x:any) => new ActionPlan(x)),
        status : initialState.status
      })

    //Remove action plan
    case ACTION_PLAN_REMOVE:
      return {
        ...state,
        list  : state.list.filter(x => x.id !== action.payload.id )
      }

    //Remove task for an action plan
    case ACTION_PLAN_REMOVE_TASK:
      active = new ActionPlan(state.active)
      active.tasks = active.tasks.filter(x => x.id !== action.payload.taskId)
      return Object.assign({}, state, {
        status : STATUS_SAVING,
        active 
      })

    //Remove message for a task
    case ACTION_PLAN_REMOVE_TASK_MESSAGE:
      active = new ActionPlan(state.active)
      activeTask = new ActionPlanTask(active.tasks.find(x => x.id === action.payload.taskId))
    
      activeTask.messages = activeTask.messages.filter(x => x.id !== action.payload.messageId)
      active.tasks = state.active.tasks.map(x => x.id === activeTask.id ? activeTask : x)

      return Object.assign({}, state, {
        active,
        status : STATUS_SAVING
      })

    //Update status
    case ACTION_PLAN_STATUS:
      return Object.assign({}, state, {
        status : action.payload.status
      })

    default:
      return state

  }

}