import { useEffect, useRef } from "react";

/** 
 * use-previous 
 * Detect parent props for component
*/
const usePrevious = (value) => {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export default usePrevious