/** 
 * GENERATE.ACTIONS
 * Actions to randomize data for the dashboard
 */

import { DashboardFilters } from "./filter.types"

export const generateHeatmap = (templateId:string, surveyId:string | null, filterName:string, dashboardFilters:DashboardFilters) => ({
  type : 'API',
  payload : {
    method : "GET",
    url : "/public/generate/heatmap",
    data : {
      templateId,
      surveyId,
      filterName,
      dashboardFilters
    }
  }
})

export const generateMessages = (dashboardFilters:DashboardFilters, countOnly) => ({
  type : 'API',
  payload : {
    method : "GET",
    url : "/public/generate/messages",
    data : {
      dashboardFilters,
      countOnly
    }
  }
})

export const generateParticipation = (dashboardFilters:DashboardFilters) => ({
  type : 'API',
  payload : {
    method : "GET",
    url : "/public/generate/participation",
    data : {
      dashboardFilters
    }
  }
})

export const generateRepartition = (dashboardFilters:DashboardFilters) => ({
  type : 'API',
  payload : {
    method : "GET",
    url : "/public/generate/repartition",
    data : {
      dashboardFilters
    }
  }
})

export const generateTopics = (templateId:string, surveyId:string | null, withScore:boolean, dashboardFilters:DashboardFilters) => ({
  type : 'API',
  payload : {
    method : "GET",
    url : "/public/generate/topics",
    data : {
      templateId,
      surveyId,
      withScore,
      dashboardFilters
    }
  }
})

export const generateTranslation = (text: string[], inputLang: string | null, _targetLang: string | null) =>({
  type : 'API',
  payload : {
    method : "POST",
    url : "/public/translate",
    data : {
      text,
      inputLang,
      _targetLang
    }
  }
})