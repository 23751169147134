/** 
 * report-params.WIDGET.TSX
 * Éditer les paramètres pour une slide
 */
import { store } from "@/index";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { pageReplace } from "@/redux/page.actions";
import { PAGE_FILTER_MODES, Page, PageFilterMode, PageState } from "@/redux/page.types";
import { STATUS_SAVING } from "@/redux/_status.types";
import ReportPageBackgroundWidget from "./report-page-background.widget";
import { ReportState } from "@/redux/report.types";
import { faCopy, faFilter, faTimes, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { reportEdit } from "@/redux/report.actions";
import { Session } from "@/redux/_session.types";
import { useState } from "react";
import SelectPopulationsModal from "@/modals/select-populations.modal";
import { Population } from "@/redux/population.types";
import { AttributeState } from "@/redux/attribute.types";
import { populationsUpdateWithAid } from "@/redux/population.actions";
import ReportSelectFilterNameModal from "@/modals/report-select-filter-name.modal";
import Space from "@/components/space";
import Chip from "@/components/chip";
import Modal from "@/components/modal";
import CardContainer from "@/components/card-container";
import CardButton from "@/components/card-button";

interface StateProps extends WithTranslation{
  _session : Session
  attribute : AttributeState
  page : PageState
  report : ReportState
}

type SelectorType = "background" | "twoColumns"

const MODAL_FILTER = "MODAL_FILTER"
const MODAL_SELECT_FILTER_NAME = "MODAL_SELECT_FILTER_NAME"
const MODAL_SELECT_POPULATIONS = "MODAL_SELECT_POPULATIONS"

function ReportPageParamsWidget(props:StateProps) {
  const { t } = props

  //Modal
  const [currentModal, setCurrentModal] = useState<null | string>(null)

  //Set value for a page attribute
  function editPage(key:string, value:any){

    const editedPage: Page = new Page({
      ...props.page.active,
      [key] : value,
      status : props.page.active.status === "saved" ? "edited" : props.page.active.status
    })

    store.dispatch(pageReplace(editedPage))

  }

  //Edit options for the report
  function editPageOptions(key:string, value:any){
    editPage(
      "options", 
      Object.assign({}, props.page.active.options, { [key] : value }) 
    )
  }

  //Get icon
  function getFilterModeIcon(mode:PageFilterMode){
    switch(mode){
      case "all":
        return faCopy
      case "one":
        return faUserPlus
      default:
        return faTimes 
    }
  }

  //Edit filtermode
  function editFilterMode(){
    switch(props.page.active.options.filterMode){
      case "all":
        return setCurrentModal(MODAL_SELECT_FILTER_NAME)
      case "one":
        return setCurrentModal(MODAL_SELECT_POPULATIONS)
      default:
        openNavbar(true)
        return setCurrentModal(null)
    }
  }

  //Edit population
  //Show the navbar again
  //Update the archiveId in order to filter the data for the report
  function editPopulations(populations:Population[]){
    openNavbar(true)
    editPageOptions("populations", populationsUpdateWithAid(populations))
  }

  //Classname for the selectors
  function getSelectorClass(type:SelectorType, value:any){
    let className = "_hover report-nav-page flex1 medgrey-b "
    const classNameActive = "report-nav-page-active report-nav-background-active"
    
    if (props.page.status === STATUS_SAVING){
      className += "report-nav-background-generating" //Specific class if generating (to not affect pptx generation)
    }else if (props.page.active.backgroundId === value && type === "background"){
      className += classNameActive
    }else if (props.page.active.twoColumns === value && type === "twoColumns"){
      className += classNameActive
    }else{
      className += "report-nav-background-inactive"
    }

    return className 
    
  }

  //Open filter modal
  //As admin choice beetween populations one by one or create a page for each attribute of population
  //As observer choice only between populations
  function openFilters(){
    openNavbar(false)
    setCurrentModal(MODAL_FILTER)
  }

  //Open the navbar
  function openNavbar(isOpen:boolean){
    store.dispatch(reportEdit("navbarOpen", isOpen))
  }

  //If same background deactive
  function setBackground(backgroundId:number | null){
    editPage("backgroundId", backgroundId)
  }

  return (
    <div className="report-page-params flex1 flex">

      { currentModal === MODAL_FILTER &&
      <Modal onNext={() => editFilterMode()}
        onClose={() => {
          openNavbar(true)
          setCurrentModal(null)
        }}
        title={t("report_page_filter_title")}
        isCloseButtonVisible>
        <CardContainer>

          { PAGE_FILTER_MODES.map(mode => 
          <CardButton icon={getFilterModeIcon(mode)}
            key={mode}
            isActive={mode === props.page.active.options.filterMode}
            title={t("page_filter_name_" + mode)}
            onClick={() => editPageOptions("filterMode", mode)}>
          </CardButton>
          )
          }

        </CardContainer>

        <div className="height-20"/>

        <div className="flex grey-t">
          <Space/>
          <div style={{
              width : 400,
              height : 40,
              textAlign : "center"
            }}>
            <b>{t("page_filter_name_" + props.page.active.options.filterMode + "_help")}</b>
          </div>
          <Space/>
        </div>

        <div className="height-10"/>

      </Modal>
      }
      
      { currentModal === MODAL_SELECT_FILTER_NAME &&
      <ReportSelectFilterNameModal onClose={() => {
          openNavbar(true)
          setCurrentModal(null)
        }}
        onNext={(filterName) => { editPageOptions("filterName", filterName) }}
        filterName={props.page.active.options.filterName}
      />
      }

      { currentModal === MODAL_SELECT_POPULATIONS &&
      <SelectPopulationsModal onClose={() => {
          openNavbar(true)
          setCurrentModal(null)
        }}
        isWithoutDefaultFilters
        isSurveyActiveSelected
        selectedPopulations={[]}
        onNext={(populations:Population[]) => editPopulations(populations)}
      />
      }

      <Space/>

      <div>

        <div className="flex report-nav-backgrounds">

          <div onClick={() => setBackground(null)} 
            className={getSelectorClass("background", null)}>
            <ReportPageBackgroundWidget backgroundId={0}/>
          </div>

          { [...Array(3)].map((e, i) =>
          <div key={i} onClick={() => setBackground(i + 1)} 
            id={"image-exportable-background-" + (i + 1)}
            className={getSelectorClass("background", i + 1)}>
            <ReportPageBackgroundWidget backgroundId={i + 1}/>
          </div>
          )
          }

        </div>

        <div className="flex report-nav-backgrounds">

          { (props.page.active.contentLeft.type === "screenshot" || props.page.active.contentRight.type === "screenshot") &&
          <div className="flex flex-auto">
            <Chip icon={faFilter}
              onClick={() => openFilters()}
              color={props.page.active.options.filterMode === "none" ? "#CCCCCC" : props._session.accountColors.active}>
              {t("report_page_filter")}
            </Chip>
          </div>
          }

          <Space/>

          <div className={getSelectorClass("twoColumns", false) + " flex"}
            onClick={() => editPage("twoColumns", false)}>
            <div className="flex1 report-page-layout medgrey-b"/>
          </div>

          <div className={getSelectorClass("twoColumns", true) + " flex"}
            onClick={() => editPage("twoColumns", true)}>
            <div className="flex1 report-page-layout medgrey-b" style={{ marginRight : 2 }}/>
            <div className="flex1 report-page-layout medgrey-b" style={{ marginLeft : 2 }}/>
          </div>

        </div>

      </div>

    </div>
  )
  
}

const mapStateToProps = state => ({
  _session : state._session,
  attribute : state.attribute,
  page: state.page,
  report : state.report
})

export default connect(mapStateToProps)(withTranslation()(ReportPageParamsWidget))