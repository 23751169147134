/** 
 * CONFIRM-BUTTONS
 * Choice beetween confirm and confirm later
 */
import { WithTranslation, withTranslation } from "react-i18next"
import { faLock } from "@fortawesome/free-solid-svg-icons";
import Button from "./button"
import Space from "./space"
import ListItem from "./list-item";
import { toast } from "react-toastify";
import { useState } from "react";
import ModalConfirm from "./modal-confirm";

interface OwnProps{
  isLocked? : boolean
  onCancel : Function
  onConfirm : Function
  onConfirmLater : Function
}

type Props = OwnProps & WithTranslation

const MODAL_CONFIRM_CANCEL = "MODAL_CONFIRM_CANCEL"

function ConfirmButtons(props:Props) {
  const { t } = props

  const [currentModal, setCurrentModal] = useState<string | null>(null)

  function cancel(){
    setCurrentModal(null)
    props.onCancel()
  }

  function confirm(){
    if (props.isLocked){
      toast(t("utils_confirm_locked"), { type : "error" })
    }else{
      props.onConfirm()
    }
  }

  return (
    <ListItem>

      { currentModal === MODAL_CONFIRM_CANCEL &&
      <ModalConfirm onNo={() => setCurrentModal(null)}
        onYes={() => cancel()}
        textBold={t("utils_reset")}
      />
      }

      <Button onClick={() => setCurrentModal(MODAL_CONFIRM_CANCEL)}>
        {t("utils_cancel")}
      </Button>
      <Space/>
      <Button
        isWithBorder
        onClick={() => props.onConfirmLater()}>
        {t("utils_confirm_later")}
      </Button>
      <Button className={props.isLocked ? "light" : "primary"}
        icon={props.isLocked ? faLock : undefined}
        isWithBorder
        onClick={() => confirm()}>
        {t("utils_confirm")}
      </Button>

    </ListItem>
  )
}

export default withTranslation()(ConfirmButtons)