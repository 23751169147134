import { RootState } from "@/index"
import { createSelector } from "@reduxjs/toolkit"
import { SupervisorState } from "./supervisor.types"

// Selector to get the supervisors list from the supervisor slice
export const supervisorState = (state: RootState): SupervisorState =>
  state.supervisor

// Memoized selector to get the list of supervisors
export const selectSupervisors = createSelector(
  supervisorState,
  (supervisorState) => supervisorState.list
)
