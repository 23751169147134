import { Sending, SENDING_ADD, SENDING_GET, SENDING_INIT, SENDING_REMOVE, SENDING_REPLACE } from "./sending.types";

// REDUX

export const sendingAdd = (sending:Sending) => ({
  type : SENDING_ADD,
  payload : {
    sending
  }
})

export const sendingGet = (sendings:Sending[]) => ({
  type : SENDING_GET,
  payload : {
    sendings
  }
})

export const sendingInit = () => ({
  type : SENDING_INIT
})

export const sendingReplace = (sending:Sending) => ({
  type : SENDING_REPLACE,
  payload : {
    sending
  }
})

export const sendingRemove = (id:string) => ({
  type : SENDING_REMOVE,
  payload : {
    id
  }
})


// API

export const sendingDestroy = (id: string) => ({
  type : 'API',
  payload : {
    method : "DELETE",
    url : "/sending/" + id
  }
})

export const sendingFetch = (projectId: string) => ({
  type : 'API',
  payload : {
    method : "GET",
    url : "/sendings",
    data : {
      projectId
    }
  }
})

export const sendingFetchAll = () => ({
  type : 'API',
  payload : {
    method : "GET",
    url : "/sendings/all",
  }
})

export const sendingFetch_AsSupervisor = () => ({
  type : 'API',
  payload : {
    method : "GET",
    url : "/supervisor/sendings",
  }
})

export const sendingUpdate = (sending:Sending, projectId:string, isNow:boolean) => ({
  type : 'API',
  payload : {
    method : "POST",
    url : "/sending",
    data : {
      sending,
      projectId,
      isNow
    }
  }
})