/** 
 * QUESTION-TOPIC.HELP
 * Info about topic
 */
import HelpModal from "@/components/modal-help"
import { withTranslation, WithTranslation } from "react-i18next"

interface OwnProps extends WithTranslation{
  onClose : Function
}

function QuestionTopicHelp(props:OwnProps) {
  const { t } = props

  return (
    <HelpModal onClose={props.onClose}
      title={t("topic")}>
      <p>{t("topic_help")}</p>
      <p>{t("topic_help_many")}</p>
      <p>{t("topic_help_score")}</p>
    </HelpModal>
  )
}

export default withTranslation()(QuestionTopicHelp)