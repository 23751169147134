/** 
 * NOTIFICATION
 * "New item" that popped
 */
import { faCheck } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useRef, useState } from "react"
import { withTranslation, WithTranslation } from "react-i18next"

interface OwnProps extends WithTranslation{
  isHidden : boolean
  onHover? : Function
}

function Notification(props:OwnProps) {
  const { t } = props
  
  const isFirstRender = useRef(true)
  const [isHovered, setIsHovered] = useState(false)
  const [isHidden, setIsHidden] = useState(props.isHidden)

  //Detect if isHidden is change in hover to hide the notification
  //Do not trigger the trigger at first render
  useEffect(() => {

    if (isFirstRender.current) {
      isFirstRender.current = false;
      return
    }

    if (props.isHidden){
      hide()
    }

  }, [props.isHidden])

  function getClassName(){
    let className = "notification green-b green-t"

    if (isHovered){
      className += " notification-hovered"
    }

    if (isHidden){
      className += " notification-hovered notification-hidden"
    }

    return className

  }

  function hide(){
    setIsHovered(true)
    setTimeout(() => {
      setIsHidden(true)
    }, 500)
  }

  function hover(){
    if (props.onHover){
      hide()
      props.onHover()
    }
  }

  return (
    <div className={getClassName()}
      onMouseLeave={hover}
      onClick={hover}>
      <FontAwesomeIcon icon={faCheck} className="notification-icon"/>
      <span className="notification-text">
        {t("utils_new")}
      </span>
    </div>
  )

}

export default withTranslation()(Notification)