/**
 * SUPERVISOR-USERS
 * List of supervisors (only for level 3)
 */
import {
  useSupervisorAddMutation,
  useSupervisorsGetQuery
} from "@/core/apis/backend/endpoints/supervisor.endpoint"
import { selectSupervisors } from "@/core/slices/supervisor/supervisor.selector"
import { Supervisor } from "@/core/slices/supervisor/supervisor.types"
import NavSupervisor from "@/nav/nav.supervisor"
import { useSelector } from "react-redux"
import { v4 as uuidV4 } from "uuid"

const SupervisorUsersRoute = () => {
  const { error: fetchError, isLoading: isFetching } = useSupervisorsGetQuery(
    "",
    {
      refetchOnMountOrArgChange: true
    }
  )
  const [createSupervisor, { error: addError, isLoading: isAdding }] =
    useSupervisorAddMutation()

  const supervisors = useSelector(selectSupervisors)
  const handleCreate = async () => {
    try {
      const newSupervisor: Supervisor = {
        id: uuidV4(),
        firstname: "John",
        lastname: "Doe",
        email: "john.doe@example.com",
        language: "en",
        image: false,
        level: 1,
        code: null
      }

      await createSupervisor(newSupervisor).unwrap()
      console.log("Supervisor créé avec succès !")
    } catch (err: unknown) {
      console.error("Erreur lors de la création du supervisor :", err)

      // Caster err en Error pour avoir accès à message
      if (err instanceof Error) {
        // Si l'email est déjà utilisé
        if (
          err.message === "L'email est déjà utilisé par un autre superviseur."
        ) {
          alert("Cet email est déjà utilisé. Veuillez en choisir un autre.")
        } else {
          alert("Une erreur s'est produite lors de la création du superviseur.")
        }
      } else {
        // Si err n'est pas une instance de Error, on gère ce cas.
        alert("Une erreur inconnue s'est produite.")
      }
    }
  }

  return (
    <NavSupervisor>
      {isFetching ? (
        <div>Chargement...</div>
      ) : fetchError ? (
        <div>Une erreur s est produite lors de la récupération des données</div>
      ) : (
        <div>
          {supervisors && supervisors.length > 0 ? (
            supervisors.map((supervisor) => (
              <div key={supervisor.id}>{supervisor.firstname}</div>
            ))
          ) : (
            <div>Aucune donnée disponible</div>
          )}
          {isAdding && <div>Ajout en cours...</div>}
          {addError && <div>Erreur lors de l ajout du supervisor</div>}
        </div>
      )}
      <button onClick={handleCreate}>Créer un Supervisor</button>
    </NavSupervisor>
  )
}

export default SupervisorUsersRoute

/*

class SuperUsers extends Component<Props>{
  public readonly state: Readonly<State> = {
    currentModal : null,
    currentSearch : ""
  }

  //Add a new superamdin
  add = () => {
    store.dispatch(supervisorAdd())
  }

  //Load list at start
  componentDidMount = async () => {
    this.loadSupervisors()
  }

  //Dialog to confirm supression
  delete = (supervisor:Supervisor) => {
    store.dispatch(supervisorActivate(supervisor))
    this.setState({ currentModal : MODAL_DELETE })
  }

  //Delet supervisor
  deleteConfirm = () => {
    if (this.props.supervisor.active.id){
      store.dispatch(supervisorRemove(this.props.supervisor.active.id))
      store.dispatch(supervisorDestroy(this.props.supervisor.active.id))
      this.setState({ currentModal : null })
    }
  }

  //Activate current supervisor
  edit = (supervisor:Supervisor) => {
    store.dispatch(supervisorActivate(supervisor))
  }

  //End edit if email is valid
  //Save to server
  editEnd = (key:string, value:any) => {
    store.dispatch(supervisorEdit(key, value))
    if (validateEmail(this.props.supervisor.active.email)){
      this.save(this.props.supervisor.active)
    }
  }

  //Edit image
  editImage = (supervisor) => {
    this.edit(supervisor)
    this.setState({ currentModal : MODAL_IMAGE })
  }

  //Confirm upload of image
  editImageEnd = (key:string, value:any) => {
    this.editEnd(key, value)
    this.setState({ currentModal : null })
  }

  //Get color for level
  getColor = (level) => {
    if (level === 3){
      return "#eb5a46"
    }else if (level === 2){
      return "#ff9500"
    }else{
      return null
    }
  }

  //Load supervisors
  loadSupervisors = async() => {
    store.dispatch(supervisorStatus(STATUS_LOADING))
    const response:any = await store.dispatch(supervisorFetch(this.state.currentSearch))

    if (response.error){
      store.dispatch(supervisorStatus(STATUS_LOAD_ERROR))
    }else{
      store.dispatch(supervisorStatus(STATUS_LOADED))
      store.dispatch(supervisorGet(response))
    }
  }

  //Save database
  save = async (supervisor:Supervisor) => {
    store.dispatch(supervisorStatus(STATUS_SAVING))
    const response:any = await store.dispatch(supervisorUpdate(supervisor))
    store.dispatch(supervisorStatus(response.error ? STATUS_SAVE_ERROR : STATUS_SAVED))
  }

  //Search
  searchSupervisor = (search:string) => {
    this.setState({ 
      currentSearch : search,
    }, () => {
      this.loadSupervisors()
    })
  }

  //Edit level of the user
  //If level 3 return to level 1
  setLevel = (supervisor:Supervisor) => {
    store.dispatch(supervisorActivate(supervisor))

    if (supervisor.level > 2){
      store.dispatch(supervisorEdit('level', 1))
    }else{
      store.dispatch(supervisorEdit('level', supervisor.level + 1))
    }

    setTimeout(() => {
      this.save(this.props.supervisor.active)
    }, 200)

  }
  
  render () {
    const { t } = this.props
    return(
      <NavSupervisor>
        
        { this.state.currentModal === MODAL_IMAGE &&
        <Modal isCloseButtonVisible
          onClose={() => this.setState({ currentModal : null })}
          title={t("image_load")}>

          <ImagePicker id={this.props.supervisor.active.id}
            square
            imageId={this.props.supervisor.active.image ? this.props.supervisor.active.id : null}
            model="Supervisors"
            onDelete={() => this.editImageEnd("image", false)}
            onSave={() => this.editImageEnd("image", true)}
          />

        </Modal>
        }

        { this.state.currentModal === MODAL_DELETE &&
        <Modal isCloseButtonVisible
          onClose={() => this.setState({ currentModal : null })}  
          onNext={this.deleteConfirm}>
          <p>
            {t("supervisor_delete_confirm")}
          </p>
        </Modal>
        }

        <PageHeader title={t("supervisors")}>

          <Button className="primary"
            onClick={this.add}>
            {t("utils_add")}
          </Button>
        </PageHeader>

        <Card isWithoutPadding 
          isOverflowHidden>

          <ListContainer
            onSearch={this.searchSupervisor}
            onSearchStart={() => store.dispatch(supervisorStatus(STATUS_SEARCHING))}
            status={this.props.supervisor.status}>

            <PageLoader status={this.props.supervisor.status}>
              <ListFields>
                <ListCell/>
                <ListCell width={260} text={t("user_email")}/>
                <ListCell width={140} text={t("user_firstname")}/>
                <ListCell width={140} text={t("user_lastname")}/>
                <Space/>
              </ListFields>

              { this.props.supervisor.list.map((supervisor:Supervisor) => 
              <ListItem key={supervisor.id}>
                
                <ListIcon fallbackIcon={faUserCircle}
                  onClick={() => this.editImage(supervisor)}
                  image={supervisor.image ? env.REACT_APP_URL_SPACE.concat("/Supervisors/", supervisor.id, ".png") : null}
                />

                <ListCell width={260}>
                  <ListInput
                    type="email"
                    onEdit={() => this.edit(supervisor)}
                    onEditEnd={(value) => this.editEnd("email", value)}
                    value={supervisor.email} 
                  />
                </ListCell>

                <ListCell width={140}>
                  <ListInput
                    onEdit={() => this.edit(supervisor)}
                    onEditEnd={(value) => this.editEnd("firstname", value)}
                    value={supervisor.firstname} 
                  />
                </ListCell>

                <ListCell width={140}>
                  <ListInput
                    onEdit={() => this.edit(supervisor)}
                    onEditEnd={(value) => this.editEnd("lastname", value)}
                    value={supervisor.lastname} 
                  />
                </ListCell>

                <Space/>

                <ListCell width={140}>
                  <Chip color={this.getColor(supervisor.level)}>
                    {supervisor.levelLabel}
                  </Chip>
                  <ListButton icon={supervisor.level < 3 ? faArrowUp : faArrowDown}
                    text={t("utils_level_edit")}
                    onClick={() => this.setLevel(supervisor)}
                  />
                </ListCell>

                <ListCell>
                  <SaveIcon status={this.props.supervisor.active.id === supervisor.id ?
                    this.props.supervisor.status : null }
                  />
                </ListCell>

                <ListButton icon={faTimes}
                  text={t("utils_delete")}
                  onClick={() => this.delete(supervisor)}
                />

              </ListItem>
              )
              }
            </PageLoader>
          </ListContainer>
        </Card>

      </NavSupervisor>
    )

  }

}

const mapStateToProps = (state:any) => ({
  _session : state._session,
  supervisor : state.supervisor
})

export default connect(mapStateToProps)(withTranslation()(SuperUsers))*/
