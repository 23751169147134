/** 
 * LIST-FIELDS
 * Header for list container (text in grey)
 */

interface OwnProps{
  children : any
}

function ListFields(props:OwnProps) {

  return (
    <div className="list-container-fields flex flex1">
      {props.children}
    </div>
  )

}

export default ListFields