/**
 * TEMPLATE.TYPE
 * Questionnaire
 */

import env from "@/env"
import i18n from "@/translate/i18n"
import { Axis } from "./axis.types"
import { Topic } from "./topic.types"
import { t } from "@/translate/t"

export class TemplateOptions {
  defaultScaleCount: number = 5 //By default likert 5
  emojiMode: boolean = false //Emoji mode
  hideAxisPage: boolean = false //Do not show axis page between
  hideQuestionSecondaryText: boolean = false //Hide text for secondary question
  response0Label: boolean = false //Custom text for "not concerned" options
  response2Label: boolean = false //Custom text for Yes/No question type
  response5Label: boolean = false //Custom text for 4 ou 5 scale question type
  response10Label: boolean = false //Custom text for nps scale question type
  showCircle: boolean = false //Show circle aside "not concerned" option
  triggerNegativeAnswer: number = 50 //Trigger to display question (negative)
  triggerPositiveAnswer: number = 50 //Trigger to display question (positive)
  triggerNegativeAnswerNps: number = 70 //Trigger to display question (NPS negative)
  triggerPositiveAnswerNps: number = 70 //Trigger to display question (NPS positive)
  tag: string | null = null

  constructor(templateOptions: Partial<TemplateOptions> = {}) {
    if (!templateOptions) templateOptions = new TemplateOptions()
    Object.assign(this, templateOptions)
  }
}

export class Template {
  axesOrder: string[] = []
  AccountId: string | null = null
  Axes: Axis[] = []
  createdAt: Date = new Date()
  updatedAt: Date = new Date()
  description: any = {}
  draft: boolean = false
  free: boolean = true
  id: string = ""
  image: string | null = null
  language: string = i18n.language
  languages: string[] = [i18n.language]
  loading: boolean = false
  name: any = {}
  open: boolean = false
  options: TemplateOptions = new TemplateOptions()
  fromSupervisor: boolean = false
  Topics: Topic[] = []
  topicsOrder: string[] = []

  constructor(template: Partial<Template> = {}) {
    if (!template) template = new Template()
    template.options = new TemplateOptions(template.options)
    template.Axes = template.Axes ? template.Axes.map((x) => new Axis(x)) : []
    template.Topics = template.Topics
      ? template.Topics.map((x) => new Topic(x))
      : []
    Object.assign(this, template)
  }

  //Get image url
  get imageUrl(): string | null {
    return this.image
      ? env.REACT_APP_URL_SPACE.concat(
          "/Templates/",
          this.image,
          ".png?" + Date.now()
        )
      : null
  }

  get label(): string {
    return this.name[i18n.language]
      ? this.name[i18n.language]
      : this.name[i18n.languages[0]]
      ? this.name[i18n.languages[0]]
      : ""
  }

  get languageAlt(): string | null {
    if (this.languages.length > 1) {
      const language = this.languages.find((x) => x !== this.language)
      return language ? language : null
    } else {
      return null
    }
  }

  get updatedAtLabel(): string {
    return new Date(this.updatedAt).toLocaleDateString()
  }

  getDuplicateName(accountName: string, isForProject: boolean): string {
    if (isForProject && !this.fromSupervisor) {
      return this.label
    } else {
      return (
        this.label +
        " - " +
        (this.fromSupervisor ? accountName : t("utils_copy"))
      )
    }
  }

  //Get label for every response
  getResponseLabel(
    responseCount: number,
    key: string | number,
    labelPrefix: string
  ): string {
    if (responseCount === 4) {
      responseCount = 5
      if (key === 3 || key === 4) {
        key = key + 1
      }
    }

    if (this.options["response" + responseCount + "Label"]) {
      const label =
        this.options[
          "response" + responseCount + "Label_" + key + "_" + this.language
        ]
      return label ? label : t(labelPrefix + "_" + key)
    } else {
      return t(labelPrefix + "_" + key)
    }
  }
}

export const TEMPLATES_TAGS = ["qvt", "rps", "rse", "tt", "manager", null]

export interface TemplateState {
  active: Template
  count: number //Total of templates
  countSearch: number //Total of templates searched
  status: string
  list: Template[]
}

export const TEMPLATE_ACTIVATE = "TEMPLATE_ACTIVATE"
export const TEMPLATE_ADD = "TEMPLATE_ADD"
export const TEMPLATE_CANCEL = "TEMPLATE_CANCEL"
export const TEMPLATE_EDIT = "TEMPLATE_EDIT"
export const TEMPLATE_EDIT_DESCRIPTION = "TEMPLATE_EDIT_DESCRIPTION"
export const TEMPLATE_EDIT_IMAGE = "TEMPLATE_EDIT_IMAGE"
export const TEMPLATE_EDIT_LANGUAGE = "TEMPLATE_EDIT_LANGUAGE"
export const TEMPLATE_EDIT_NAME = "TEMPLATE_EDIT_NAME"
export const TEMPLATE_GET = "TEMPLATE_GET"
export const TEMPLATE_GET_COUNT = "TEMPLATE_GET_COUNT"
export const TEMPLATE_GET_COUNT_SEARCH = "TEMPLATE_GET_COUNT_SEARCH"
export const TEMPLATE_INIT = "TEMPLATE_INIT"
export const TEMPLATE_REMOVE = "TEMPLATE_REMOVE"
export const TEMPLATE_STATUS = "TEMPLATE_STATUS"
