/** 
 * EXPORT-TEMPLATE.MODAL
 * Export template (word or excel)
 */
import Modal from "@/components/modal"
import { withTranslation, WithTranslation } from "react-i18next"
import CardContainer from "@/components/card-container"
import CardButton from "@/components/card-button"
import { faFileExcel, faFileWord } from "@fortawesome/free-solid-svg-icons"
import Space from "@/components/space"
import exportTemplateUtils from "@/utils/export-template.utils"

interface OwnProps extends WithTranslation{
  onClose : Function
}

function ExportTemplate(props:OwnProps) {
  const { t } = props

  function exportExcel(){
    exportTemplateUtils("excel")
  }

  function exportWord(){
    exportTemplateUtils("word")
  }

  return (
    <Modal isCloseButtonVisible
      isLarge
      onClose={() => props.onClose()}>

      <div className="flex">
        <Space/>
        <h3>
          {t("template_export")}
        </h3>
        <Space/>
      </div>

      <CardContainer>
        <CardButton title={t("template_export_word")}
          onClick={exportWord}
          icon={faFileWord}>
        </CardButton>
        <CardButton title={t("template_export_excel")}
          onClick={exportExcel}
          icon={faFileExcel}>
        </CardButton>
      </CardContainer>

    </Modal>
  )

}

export default withTranslation()(ExportTemplate)