/** 
 * SET-PASSWORD.MODAL
 * Edit password of the connected user
 */
import Modal from "@/components/modal"
import { withTranslation, WithTranslation } from "react-i18next"
import TextInput from "@/components/text-input";
import { useState } from "react";
import { STATUS_SAVING } from "@/redux/_status.types";
import { Session } from "@/redux/_session.types";
import { connect } from "react-redux";
import Button from "@/components/button"
import { UserState } from "@/redux/user.types";
import RegisterModal from "./register.modal";
import LoginModal from "./login.modal";
import validatePassword from "@/utils/validate-password.utils";

interface StateProps extends WithTranslation{
  _session : Session,
  user : UserState
}

interface OwnProps{
  loginAfter? : boolean //Direct log avec isSet
  onClose? : Function //Action when modal is closed
  passwordMinLength? : number
}

const MODAL_LOGIN = "MODAL_LOGIN"
const MODAL_REGISTER = "MODAL_REGISTER"

type Props = StateProps & OwnProps

function SetPasswordModal(props:Props) {
  const { t } = props

  const passwordMinLength: number = props.passwordMinLength ? props.passwordMinLength : 6

  const [currentModal, setCurrentModal] = useState<string | null>(null)
  const [password, setPassword] = useState("")
  const [passwordRepeat, setPasswordRepeat] = useState("")
  
  function endRegister(){
    if (props.loginAfter){
      setCurrentModal(MODAL_LOGIN)
    }else{
      close()
    }
  }

  function close(){
    if (props.onClose){
      props.onClose()
    }
  }

  function next(){
    if (validatePassword(passwordMinLength, password, passwordRepeat)){
      register()
    }
  }

  function register(){
    setCurrentModal(MODAL_REGISTER)
  }

  return (
    <Modal isCloseButtonVisible={props.onClose ? true : false}
      onClose={close}>

      { currentModal === MODAL_LOGIN &&
      <LoginModal authLevel={2}
        isFirstConnexion
        email={props._session.email}
        password={password}
        onClose={close}/>
      }

      { currentModal === MODAL_REGISTER &&
      <RegisterModal email={props._session.email}
        password={password}
        onClose={endRegister}
      />
      }

      <div className="signup-form">

        <h3>
          {t("utils_last_step")}
        </h3>

        <p>
          {t("Veuillez créer un mot de passe pour pouvoir vous connecter à votre compte IDTree.")}
        </p>
        
        <TextInput
          isFullWidth
          onChange={(e) => setPassword(e.value)}
          value={password}
          type="password"
          title={t("utils_password")}
        />

        <TextInput
          isFullWidth
          onChange={(e) => setPasswordRepeat(e.value)}
          value={passwordRepeat}
          title={t("self_password_repeat")}
          type="password"
        />

        <p className="grey-t" style={{fontSize: 12}}>{t("self_password_too_short", { count: passwordMinLength}) + " " + t("self_password_special_character")}</p>

        <div className="height-20"/>

        <Button className="primary"
          isFullWidth
          isLarge
          isLoading={props.user.status === STATUS_SAVING}
          onClick={next}>
          {t("self_password_create")}
        </Button>

        <div className="height-20"/>

      </div>

    </Modal>

  )

}

const mapStateToProps = state => ({
  _session : state._session,
  user : state.user
})

export default connect(mapStateToProps)(withTranslation()(SetPasswordModal))