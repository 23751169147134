/***
 * BUILD-INFO.WIDGET
 * Display build info
 */

import { WithTranslation, withTranslation } from "react-i18next"
import pkg from '../../package.json';
import env from "@/env";

type Props = WithTranslation

function BuildInfoWidget(props: Props){
  const { t } = props

  const date: Date = new Date(env.REACT_APP_BUILD_DATE ? env.REACT_APP_BUILD_DATE : 1) // 01/01/1970, 01:16:40 wrong env var format

  return (
    <div>
      <div>{t("version")} : <b>{pkg.version}</b></div>
      <div>{t("build_date")} : {date.toLocaleString()}</div>
      <div>{t("build_hash")} : {env.REACT_APP_BUILD_HASH.substring(0, 7)}</div>
    </div>
  )
}

export default withTranslation()(BuildInfoWidget)