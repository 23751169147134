/**
 *
 * GPT-DISPLAYER 
 * 
 */

import { faCircleNotch } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Space from "@/components/space"
import { useEffect, useState } from "react"
import ReactMarkdown from "react-markdown"
import { Socket } from "socket.io-client"
import { SocketCreate, SocketEmit } from "@/utils/socketio.utils"

type Props = {
  destination: string,
  params: any,
  language: string
}

function GptDisplayerWidget(props: Props){

  const [answer, setAnswer] = useState<string>("")
  const [isGenerating, setIsGenerating] = useState<boolean>(true)
  const [error, setError] = useState<any | null>(null)

  useEffect(() => {

    const socket: Socket = SocketCreate()

    SocketEmit(
      socket,
      props.destination,
      props.params,
      props.language,
      undefined,
      (ans: any) => setAnswer(ans),
      (err: any) => {setError(err); setIsGenerating(false)},
      () => setIsGenerating(false)
    )

    return () => { socket.disconnect() }
  }, [])

  return <div className="flex flex-dcol">

    <ReactMarkdown>
      {answer}
    </ReactMarkdown>

    { error &&
    <p className="red-t">{error.Error}</p>
    }

    {( isGenerating || (answer.length < 1 && !error) ) &&
    <div className="flex">
      <Space />
      <FontAwesomeIcon icon={faCircleNotch} spin/>
      <Space />
    </div>
    }
  </div>

}

export default GptDisplayerWidget