/** 
 * TEMPLATE-EDITOR.ROUTE
 * Edit questionnaire
 */
import { useCallback, useEffect, useState } from "react";
import { withTranslation, WithTranslation } from 'react-i18next';

import { connect } from "react-redux";
import { TemplateState } from "@/redux/template.types";
import { Session } from "@/redux/_session.types";
import NavFocus from "@/nav/nav.focus";
import PageLoader from "@/components/page-loader";
import { TopicState } from "@/redux/topic.types";
import DuplicateTemplateAskModal from "@/modals/duplicate-template-ask.modal";
import DuplicateTemplateModal from "@/modals/duplicate-template.modal";
import { store } from "@/index";
import { topicAdd, topicFetchAndGet, topicUpdateAndSave } from "@/redux/topic.actions";
import { axisFetchAndGet, axisOpen } from "@/redux/axis.actions";
import DeleteTemplateModal from "@/modals/delete-template.modal";
import EditorAxesWidget from "@/widgets/editor-axes.widget";
import { faCheckCircle, faClone, faCog, faCopy, faDownload, faEye, faFilm, faFolderOpen, faList, faPaperPlane, faPencilAlt, faPlay, faPlus, faSave, faSearch, faTrash, faUndo } from "@fortawesome/free-solid-svg-icons";
import EditorAdvancedWidget from "@/widgets/editor-advanced.widget";
import { AxisState } from "@/redux/axis.types";
import AddTopicModal from "@/modals/add-topic.modal";
import ExportTemplateModal from "@/modals/export-template.modal";
import TemplatePreviewHelp from "@/help/template-preview.help";
import AddAxisModal from "@/modals/add-axis.modal";
import ModalConfirm from "@/components/modal-confirm";
import Modal from "@/components/modal";
import SearchQuestionModal from "@/modals/search-question.modal";
import testQuickvote from "@/utils/test-quickvote.utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import QuestionScoreHelp from "@/help/question-score.help";
import QuestionPrimaryHelp from "@/help/question-primary.help";
import QuestionAxisHelp from "@/help/question-axis.help";
import QuestionNameHelp from "@/help/question-name.help";
import QuestionReverseHelp from "@/help/question-reverse.help";
import QuestionSpecialHelp from "@/help/question-special.help";
import QuestionTopicHelp from "@/help/question-topic.help";
import QuestionTriggerHelp from "@/help/question-trigger.help";
import QuestionTypeHelp from "@/help/question-type.help";
import QuestionFullscreenHelp from "@/help/question-fullscreen.help";
import { filterReplaceDashboard } from "@/redux/filter.actions";
import { DashboardFilters } from "@/redux/filter.types";
import LoadingModal from "@/modals/loading.modal";
import { projectActivate, projectFetchAll } from "@/redux/project.actions";
import { STATUS_LOADING } from "@/redux/_status.types";
import ProjectAddModal from "@/modals/project-add.modal";
import PagePlaceholder from "@/components/page-placeholder";
import { toast } from "react-toastify";
import ActionsMenu from "@/components/actions-menu";
import TemplateDescriptionHelp from "@/help/template-description.help";
import { NavigateFunction, useNavigate } from "react-router-dom";
import Space from "@/components/space";
import TemplateGeneratorWidget from "@/widgets/template-generator.widget";

interface Props extends WithTranslation{
  _session : Session
  axis : AxisState
  template : TemplateState
  topic : TopicState
}

//Activate modal if user click on back with unsaved changes
const PREVENT_LEAVING_UNSAVED: boolean = true

//List of available actions for menu-top
const ACTION_ADD: string = "ACTION_ADD"
const ACTION_ADVANCED_MODE: string = "ACTION_ADVANCED_MODE"
const ACTION_CANCEL: string = "ACTION_CANCEL"
const ACTION_CREATE: string = "ACTION_CREATE"
const ACTION_DELETE: string = "ACTION_DELETE"
const ACTION_DUPLICATE: string = "ACTION_DUPLICATE"
const ACTION_EXPORT: string = "ACTION_EXPORT"
const ACTION_LAUNCH: string = "ACTION_LAUNCH"
const ACTION_PARAMS: string = "ACTION_PARAMS"
const ACTION_PREVIEW: string = "ACTION_PREVIEW"
const ACTION_REACH: string = "ACTION_REACH"
const ACTION_SAVE: string = "ACTION_SAVE"
const ACTION_SEARCH: string = "ACTION_SEARCH"
const ACTION_TEST: string = "ACTION_TEST"

//Tutorials
const HELP_AXIS: string = "HELP_AXIS"
const HELP_DESCRIPTION: string = "HELP_DESCRIPTION"
const HELP_FULLSCREEN: string = "HELP_FULLSCREEN"
const HELP_PREVIEW: string = "HELP_PREVIEW"
const HELP_PRIMARY: string = "HELP_PRIMARY"
const HELP_TOPIC: string = "HELP_TOPIC"
const HELP_QUESTION: string = "HELP_QUESTION"
const HELP_TYPE: string = "HELP_TYPE"
const HELP_REVERSE: string = "HELP_REVERSE"
const HELP_SCORE: string = "HELP_SCORE"
const HELP_SPECIAL: string = "HELP_RPS"
const HELP_TRIGGER: string = "HELP_TRIGGER"

//List of modals
const MODAL_ADD: string = "MODAL_ADD"
const MODAL_ADD_AXIS: string = "MODAL_ADD_AXIS"
const MODAL_ADD_PROJECT: string = "MODAL_ADD_PROJECT"
const MODAL_DELETE: string = "MODAL_DELETE"
const MODAL_DUPLICATE: string = "MODAL_DUPLICATE"
const MODAL_DUPLICATE_ASK: string = "MODAL_DUPLICATE_ASK"
const MODAL_DUPLICATE_SUCCESS: string = "MODAL_DUPLICATE_SUCCESS"
const MODAL_EXPORT: string = "MODAL_EXPORT"
const MODAL_LEAVING_UNSAVED: string = "MODAL_LEAVE_UNSAVED"
const MODAL_LOADING: string = "MODAL_LOADING"
const MODAL_SEARCH: string = "MODAL_SEARCH"
const MODAL_TEST: string = "MODAL_TEST"

function TemplateEditorRoute(props:Props){
  const { t } = props
  const navigate: NavigateFunction = useNavigate()

  //Show widget in list mode
  const [advancedMode, setAdvancedMode] = useState(true)

  //Display current modal in the screen
  const [currentModal, setCurrentModal] = useState<null | string>(null)

  //Display left panel
  const [currentHelp, setCurrentHelp] = useState<null | string>(null)

  //Detect if a project is launch
  const [isProjectLaunch, setIsProjectLaunch] = useState(false)

  //Display context menu if ProjectLaunch
  const [showLaunchMenu, setShowLaunchMenu] = useState<boolean>(false)

  //Set interface for uploading document (supervisors only)
  const [uploadDocument, setUploadDocument] = useState<boolean>(false)

  //Replacement for <Prompt/>
  useEffect(() => {

    const handleBeforeUnload = (event: any) => {
      if (props.topic.isEdited) {
        
        //'You have unsaved changes. Are you sure you want to leave?';
        const message: string = t("utils_ask_save_edits")
        event.returnValue = message; // Standard for most browsers
        return message; // For some older browsers
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    }

  }, [props.topic.isEdited]);

  //Load page
  //Replace dashboard => for the preview
  //Load axis to display on the right panel
  const loadData = useCallback(async () => {

    store.dispatch(filterReplaceDashboard(new DashboardFilters()))
    store.dispatch(axisFetchAndGet(props.template.active.axesOrder, null))

    //Get URL params to detect if template is a duplicate
    const searchParams = new URLSearchParams(window.location.search)
    const duplicate = searchParams.get("duplicate")
    window.history.pushState({}, '', window.location.pathname)

    if (duplicate){
      setCurrentModal(MODAL_DUPLICATE_SUCCESS)
    }

    //Load projects
    const projectResponse:any = props._session.interfaceType === "ADMIN" ? await store.dispatch(projectFetchAll()) : []
    if (!projectResponse.error){
      const project = projectResponse.find(x => x.TemplateId === props.template.active.id)
      if (project){
        store.dispatch(projectActivate(project))
        setIsProjectLaunch(true)
      }
    }

  }, [
    props._session.interfaceType,
    props.template.active.axesOrder,
    props.template.active.id
  ])

  //On load
  useEffect(() => {
    loadData()
  }, [loadData])

  //Add a new question
  //If advanced mode
  //> set axis as the same of the current topic displayed
  //> else show modal to pick one the axis if there are more than 2 axes
  function addTopic(){
    if (!isTemplateLock()){
      if (advancedMode || props.axis.list.length < 1){
        store.dispatch(topicAdd(props._session.interfaceType === "SUPERVISOR", props.topic.active.Axis, !advancedMode, props.topic.active.isAtStart, !props._session.userOptions.activateQuestions))
      }else if (props.axis.list.filter(x => x.open).length === 1){
        store.dispatch(topicAdd(props._session.interfaceType === "SUPERVISOR", props.axis.list.filter(x => x.open)[0], !advancedMode, false, !props._session.userOptions.activateQuestions))
      }else{
        setCurrentModal(MODAL_ADD)
      }
    }
  }

  //Previous page
  function back(){

    let destination = "/survey/account"
    if (props._session.interfaceType === "SUPERVISOR"){
      destination = "/supervisor/templates"
    }else if (props.template.active.fromSupervisor){
      destination = "/survey/templates"
    }
    
    navigate(destination)

  }

  //Click on one the button at the top
  function clickAction(action){
    switch(action){
      case ACTION_ADD:
        return addTopic()
      case ACTION_ADVANCED_MODE:
        return toogleMode()
      case ACTION_CANCEL:
        return store.dispatch(topicFetchAndGet(null))
      case ACTION_CREATE:
        return setCurrentModal(MODAL_ADD_PROJECT)
      case ACTION_DELETE:
        return setCurrentModal(MODAL_DELETE)
      case ACTION_DUPLICATE:
        return setCurrentModal(MODAL_DUPLICATE)
      case ACTION_LAUNCH:
        if (props._session.interfaceType === "SUPERADMIN"){
          return navigate("/project/list")
        }else if (props._session.interfaceType === "SUPERVISOR"){
          return toast(t("survey_draft_superadmin_title"), { type : "error" })
        }else{
          return createProject()
        }
      case ACTION_PARAMS:
        return toParameters()
      case ACTION_PREVIEW:
        return setCurrentHelp(HELP_PREVIEW)
      case ACTION_REACH:
        return navigate("/project/edit")
      case ACTION_SAVE:
        return save()
      case ACTION_SEARCH:
        return setCurrentModal(MODAL_SEARCH)
      case ACTION_TEST:
        return test()
      case ACTION_EXPORT:
        return setCurrentModal(MODAL_EXPORT)
      default:
        return alert(action + " : à venir")
    }

  }

  //Check if template is editable
  //If template of idtree and connected as customer, ask for duplicate
  function isTemplateLock(){
    if (props._session.interfaceType !== "SUPERVISOR" && props.template.active.fromSupervisor){
      setCurrentModal(MODAL_DUPLICATE_ASK)
      return true
    }else{
      return false
    }
  }


  //Launch project from template
  //Detect if a project exist with the same template id
  async function createProject(){

    if (!isProjectLaunch){
      setCurrentModal(MODAL_ADD_PROJECT)
    }else{
      setShowLaunchMenu(true)
    }

  }

  //Save edits
  function save(){
    if (!isTemplateLock()){
      store.dispatch(topicUpdateAndSave())
    }
  }

  //Open quickvote
  //Confirmation if changes not saved
  function test(){
    if (props.topic.isEdited){
      setCurrentModal(MODAL_TEST)
    }else{
      testConfirm()
    }
  }

  //Open quickvote link
  //Special link for supervisor (to get right route in qv app)
  function testConfirm(){
    testQuickvote(props.template.active.language)
  }

  //Change mode and open axes
  async function toogleMode(){

    setAdvancedMode(!advancedMode)

    if (advancedMode){
      props.axis.list.forEach(axis => {
        if (axis.id === props.topic.active.Axis?.id && !axis.open){
          store.dispatch(axisOpen(axis.id))
        }else if (axis.id !== props.topic.active.Axis?.id && axis.open){
          store.dispatch(axisOpen(axis.id))
        }
      })
  
      setTimeout(() => {
        document.querySelector("#topic-" + props.topic.active.id)?.scrollIntoView({
          behavior: 'smooth'
        })
      }, 200);
    }

  }

  //open params
  function toParameters(){
    if (!isTemplateLock()){
      navigate("/survey/template")
    }
  }

  return(
    <NavFocus isCustomText
      isNotScrollable={advancedMode}
      isFullWidth
      onBack={(props.topic.isEdited && PREVENT_LEAVING_UNSAVED) ? () => setCurrentModal(MODAL_LEAVING_UNSAVED) : back}
      text={t("template_title", {
        name : props.template.active.name[props.template.active.language]
      })}
      status={props.template.status}
      onAction={clickAction}
      actions={
        props.topic.status === STATUS_LOADING
        ?
        []
        :
        (
          /** show alternative menu if template supervisor in client interface */
          (props.template.active.fromSupervisor && props._session.interfaceType !== "SUPERVISOR")
          ?
          [
            { id : ACTION_TEST, label : t("template_test"), icon : faPlay },
            { id : ACTION_DUPLICATE, label : t("utils_edit"), icon : faPencilAlt, isLarge : true },
            { id : ACTION_ADVANCED_MODE, icon : (advancedMode ? faList : faClone ), label : (advancedMode ? t("template_set_display_list") : t("template_set_display_cards")) }, 
            { id : ACTION_PREVIEW, label : t("dashboard_preview"), icon : faEye },
            { id : ACTION_EXPORT, label : t("utils_export"), icon : faDownload },
            { id : ACTION_SEARCH, label : t("question_search"), icon : faSearch },
            { id : ACTION_LAUNCH, icon : faPaperPlane, label : t("utils_send"), className : "primary", isLarge : true }
          ]
          :
          [
            { id : ACTION_SAVE, label : t("utils_save"), icon : faSave, className : (props.topic.isEdited ? "secondary" : "light"), isLarge : true },
            { id : ACTION_TEST, label : t("template_test"), className : (!props.topic.isEdited && props.topic.list.length > 0 ? "secondary" : ""), icon : faPlay },
            { id : ACTION_ADVANCED_MODE, icon : (advancedMode ? faList : faClone ), label : (advancedMode ? t("template_set_display_list") : t("template_set_display_cards")) },
            { id : ACTION_CANCEL, label : t("utils_cancel"), icon : faUndo, className : (!props.topic.isEdited ? "light" : "")},
            { id : ACTION_PARAMS, label : t("utils_params"), icon : faCog },
            { id : ACTION_PREVIEW, label : t("dashboard_preview"), icon : faEye },
            { id : ACTION_LAUNCH, icon : faPaperPlane, label : t("utils_send") },
            { id : ACTION_DUPLICATE, label : t("utils_duplicate"), icon : faCopy },
            { id : ACTION_EXPORT, label : t("utils_export"), icon : faDownload },
            { id : ACTION_SEARCH, label : t("question_search"), icon : faSearch },
            { id : ACTION_DELETE, label : t("utils_delete"), icon : faTrash },
            { id : ACTION_ADD, icon : faPlus, label : t("question_add"), isLarge : true, className : "primary" }
          ]
        )
      }>

      {showLaunchMenu &&
      <div style={{
        right: "20px",
        zIndex: 2,
        top: "50px",
        position: "fixed"
      }}>
        <ActionsMenu positionRight onClose={()=>setShowLaunchMenu(false)} onSelect={clickAction} actions={[
          {icon : faFolderOpen,
            id : ACTION_REACH,
            label: t("project_get")
          },
          {
            icon : faPlus,
            id : ACTION_CREATE,
            label: t("project_add")
          }
        ]} />
      </div>
      }
        
      { (props._session.interfaceType !== "SUPERVISOR" && advancedMode && props._session.language === "fr") &&
      <div className="editor-contact">
        <div className="contact-phone _hover flex"
          onClick={() => setCurrentHelp(HELP_DESCRIPTION)}>
          <FontAwesomeIcon icon={faFilm}
            style={{ margin : '8px 12px', color : "white" }}
          />
          <span className="white-t">
            Revoir les tutoriels
          </span>
        </div>
      </div>
      }

      { currentModal === MODAL_LEAVING_UNSAVED &&
      <ModalConfirm onNo={() => setCurrentModal(null)}
        onYes={back}
        text={t("utils_ask_save")}
        textBold={t("utils_next_ask")}
      />
      }

      { currentModal === MODAL_LOADING &&
      <LoadingModal/>
      }

      { currentHelp === HELP_DESCRIPTION &&
      <TemplateDescriptionHelp onClose={() => setCurrentHelp(null)}/>
      }

      { currentHelp === HELP_FULLSCREEN &&
      <QuestionFullscreenHelp onClose={() => setCurrentHelp(null)}/>
      }

      { currentHelp === HELP_AXIS &&
      <QuestionAxisHelp onClose={() => setCurrentHelp(null)}/>
      }

      { currentHelp === HELP_PRIMARY &&
      <QuestionPrimaryHelp onClose={() => setCurrentHelp(null)}/>
      }

      { currentHelp === HELP_QUESTION &&
      <QuestionNameHelp onClose={() => setCurrentHelp(currentHelp)}/>
      }

      { currentHelp === HELP_REVERSE &&
      <QuestionReverseHelp onClose={() => setCurrentHelp(currentHelp)}/>
      }

      { currentHelp === HELP_SPECIAL &&
      <QuestionSpecialHelp onClose={() => setCurrentHelp(null)}/>
      }

      { currentHelp === HELP_SCORE &&
      <QuestionScoreHelp onClose={() => setCurrentHelp(null)}/>
      }

      { currentHelp === HELP_TOPIC &&
      <QuestionTopicHelp onClose={() => setCurrentHelp(null)}/>
      }

      { currentHelp === HELP_TRIGGER &&
      <QuestionTriggerHelp onClose={() => setCurrentHelp(null)}/>
      }

      { currentHelp === HELP_TYPE &&
      <QuestionTypeHelp onClose={() => setCurrentHelp(null)}/>
      }

      { currentHelp === HELP_PREVIEW &&
      <TemplatePreviewHelp onClose={() => setCurrentHelp(null)}/>
      }

      { currentModal === MODAL_ADD &&
      <AddTopicModal onAddAxis={() => setCurrentModal(MODAL_ADD_AXIS)} 
        onClose={() => setCurrentModal(null)}
      />
      }

      { currentModal === MODAL_ADD_AXIS &&
      <AddAxisModal onClose={() => setCurrentModal(null)}/>
      }

      { currentModal === MODAL_ADD_PROJECT &&
      <ProjectAddModal onClose={() => setCurrentModal(null)}/>
      }

      { currentModal === MODAL_DELETE &&
      <DeleteTemplateModal onClose={() => setCurrentModal(null)}/>
      }

      { currentModal === MODAL_DUPLICATE_ASK &&
      <DuplicateTemplateAskModal onClose={() => setCurrentModal(null)}/>
      }

      { currentModal === MODAL_DUPLICATE &&
      <DuplicateTemplateModal onClose={() => setCurrentModal(null)}/>
      }

      { currentModal === MODAL_DUPLICATE_SUCCESS &&
      <Modal onClose={() => setCurrentModal(null)}
        isCloseButtonVisible>
        <div className="flex">
          <FontAwesomeIcon style={{
              fontSize : '90px',
              color : props._session.accountColors.active,
              marginRight : '30px'
            }}
            icon={faCheckCircle}
          />
          <div>
            <p>
              <b>{t("utils_congratulations")}</b>
            </p>
            <p>
              {t("template_duplicate_success", {
                name : props.template.active.name[props._session.language]
              })}
            </p>
          </div>
        </div>
      </Modal>
      }

      { currentModal === MODAL_EXPORT &&
      <ExportTemplateModal onClose={() => setCurrentModal(null)}/>
      }

      { currentModal === MODAL_SEARCH &&
      <SearchQuestionModal language={props.template.active.language} 
        onClose={() => setCurrentModal(null)}
      />
      }

      { currentModal === MODAL_TEST &&
      <ModalConfirm onNo={() => setCurrentModal(null)}
        onYes={testConfirm}
        textBold={t("utils_ask_save_edits")}
      />
      }

      <PageLoader status={props.topic.status}>
        
        { props.topic.list.length > 0 
        ?
        <div>

          { advancedMode
          ?
          <EditorAdvancedWidget onHelp={(help) => setCurrentHelp(help)}/>
          :
          <EditorAxesWidget  onHelp={(help) => setCurrentHelp(help)}
            onOpenAdvanced={() => setAdvancedMode(true)}
          />
          }

        </div>
        :
        (( uploadDocument && props._session.connectedAsSupervisor) ?
        <>
          <u className="_hover grey-t" onClick={() => setUploadDocument(false)}>{t("utils_back")}</u>
          <TemplateGeneratorWidget currentTemplateId={props.template.active.id}/>
        </>
        :
        <>
          <PagePlaceholder
            image="edit"
            title={t("template_questions_empty")}
            button={t("question_add")}
            onClick={() => addTopic()}
          />

          { props._session.connectedAsSupervisor &&
          <div className="flex">
            <Space />
            <u className="_hover grey-t" onClick={() => setUploadDocument(true)}>
              Uploader un fichier (Gestionnaire IDTree uniquement)
            </u>
            <Space />
          </div>
          }
        </>
        )}

      </PageLoader>

    </NavFocus>
  )
}

const mapStateToProps = state => ({
  _session : state._session,
  axis : state.axis,
  template : state.template,
  topic : state.topic
})

export default connect(mapStateToProps)(withTranslation()(TemplateEditorRoute))