/**
 * SPACE 
 * Flex 1 div as separator
 */

function Space() {
  return (
    <div className="flex1"/>
  )
  
}

export default Space