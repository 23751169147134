/**
 * SIRH ACTION
 */

export const integrationFetchLuccaOptions = () => ({
  type : 'API',
  payload : {
    method : "GET",
    url : "/integration/lucca/options"
  }
})

export const integrationFetchLuccaUsers = () => ({
  type : 'API',
  payload : {
    method : "GET",
    url : "/integration/lucca/users"
  }
})