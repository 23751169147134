/**
 * ADM-MAPPING
 * Association between column of file and idtree attributes
 */
import { WithTranslation, withTranslation } from 'react-i18next';
import NavFocus from "@/nav/nav.focus";
import Card from "@/components/card";
import PageHeader from "@/components/page-header";
import AdmImportMappingWidget from '@/widgets/adm-import-mapping.widget';
import { NavigateFunction, useNavigate } from 'react-router-dom';

type Props = WithTranslation

function AdmImportMappingRoute(props:Props){
  const { t } = props
  const navigate: NavigateFunction = useNavigate()
  return(
    <NavFocus>
      <PageHeader title={t("adm_mapping_title")} subtitle={t("adm_mapping_subtitle")}/>
      <Card isWithoutPadding>
        <AdmImportMappingWidget onNext={() => navigate("/adm/users")}/>
      </Card>
    </NavFocus>
  )
}

export default withTranslation()(AdmImportMappingRoute)