/* eslint-disable */

import { store } from "@/index";
import { userCheckEmail } from "@/redux/user.actions";

//Fonction pour valider un email (regex)
export const validateEmail = (email: string): boolean => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).trim().toLowerCase()) 
}

//Check if email is valid (check db if available)
export const checkEmail = async(email: string | null, userId: string | null): Promise<string> => {
  if(email !== "" && email !== null){
    if(validateEmail(email)){

      //Search if user exist in the database
      const response:any = await store.dispatch(userCheckEmail(email, userId? userId : ""))

      //If not available
      if (response.error){
        return "utils_email_check_error"
      }else if (!response.available){
        return "utils_email_already_exist"
      }else{
        return "OK"
      }

    }
    else{
      return "utils_email_invalid"
    }
  }
  else{
    return "utils_email_empty"
  }
}