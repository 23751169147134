/** 
 * SELECT-LANGUAGE
 * Select languages from a list
 */

import { useState } from "react"
import getLanguagesUtil from "@/utils/get-languages.utils"
import Checkbox from "./checkbox"
import ListItem from "./list-item"

interface OwnProps{
  languageIds : string[]
  selectedLanguageIds : string[]
  onSelect : Function
}

function SelectLanguages(props:OwnProps) {

  const [languages] = useState(getLanguagesUtil(props.languageIds))

  function isSelected(languageId){
    return props.selectedLanguageIds.indexOf(languageId) > -1
  }

  return (
    <div>

      { languages.map(language =>
      <ListItem key={language.code}>

        <Checkbox active={isSelected(language.code)}
          onClick={() => props.onSelect(language.code)}
        />

        <div className="flex1 flex"
          style={{ margin : "auto" }}>

          <div className="width-10"/>

          { language.flag ?
          <img src={require("@/assets/default_icons/flags/" + language.flag + ".png")}
            alt={language.code}
            width="20" height="20"
          />
          :
          <div className="width-20"/>
          }

          <div className="width-10"/>
          
          { isSelected(language.code)
          ?
          <b>
            {language.fullName}
          </b>
          :
          language.fullName
          }

          &nbsp;

        </div>

      </ListItem>
      )
      }

    </div>
  )

}

export default SelectLanguages