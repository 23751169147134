/**
 * PROPOSITION.REDUCERS
 */

import { Proposition, PropositionState, PROPOSITION_GET, PROPOSITION_INIT } from './proposition.types';

const initialState: PropositionState = {
  list : []
}

export default function propositionReducer(
  state = initialState,
  action: any
): PropositionState {
  switch (action.type) {

    //Save list in store
    case PROPOSITION_GET:
      return Object.assign({}, state, {
        list : action.payload.propositions.map((x:any) => new Proposition(x))
      })

    //Init store
    case PROPOSITION_INIT:
      return Object.assign({}, state, {
        ...state,
        list : state.list.map((x:any) => new Proposition(x))
      })

    default:
      return state

  }
  
}