/**
 * 
 * GET-DISPARITY-LEVEL.UTILS.TS
 * 
 */


// Ceils for inequalities standard deviation score
export const DISPARITY_VERY_LOW: number = 30
export const DISPARITY_LOW: number = 35
export const DISPARITY_MEDIUM: number = 40
export const DISPARITY_HIGH: number = 45

export function getDisparityLevel(sd?: number): number{
  if(!sd){
    return 0
  }
  else if(sd < DISPARITY_VERY_LOW){
    return 1
  }
  else if(sd < DISPARITY_LOW){
    return 2
  }
  else if(sd < DISPARITY_MEDIUM){
    return 3
  }
  else if(sd < DISPARITY_HIGH){
    return 4
  }
  else{
    return 5
  }
}