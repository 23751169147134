/** 
 * CARD
 */

import { faCog } from "@fortawesome/free-solid-svg-icons"
import ListButton from "./list-button"
import PageLoader from "./page-loader"
import { STATUS_LOADED } from "@/redux/_status.types"

interface OwnProps{
  className? : string
  children? : any
  exportName? : string
  isFullWidth? : boolean
  isWithoutPadding? : boolean //Force no padding
  isOverflowHidden? : boolean //Overflow inside the Card
  isWithMinHeight? : boolean
  isNotCardClass? : boolean
  onClick? : Function //On click 
  onOption? : Function //Option button
  status? : string //Display loading in card
  title? : string //Title
}

function Card(props:OwnProps) {

  function click(){
    if (props.onClick){
      props.onClick()
    }
  }

  function clickOptions(){
    if (props.onOption){
      props.onOption()
    }
  }

  return (
    <div className={
        (!props.isNotCardClass ? "card " : "") +
        (props.className ? props.className : "") +
        (props.exportName ? " card-exportable" : "")
      }
      id={props.exportName}
      onClick={click}
      style={{
        padding : !props.isWithoutPadding ? '20px 30px' : '',
        overflow : props.isOverflowHidden ? 'auto' : '',
        minWidth: props.isFullWidth ? "100%" : ( props.status ? "190px" : "" ),
        minHeight: props.isWithMinHeight ? "200px" : ""
      }}>

      { props.onOption &&
      <div className="card-options">
        <ListButton icon={faCog}
          onClick={clickOptions}
        />
      </div>
      }

      { props.title &&
      <div className="card-title flex">
        {props.title}
      </div>
      }

      <PageLoader
        status={props.status ? props.status : STATUS_LOADED}
        minHeight={props.status ? 120 : 1}
      >
        { props.children }
      </PageLoader>

    </div>
  )
}

export default Card