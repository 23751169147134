/**
 * DASHBOARD-PARTICIPATION-OVERVIEW.WIDGET
 * 
 */

import { faCheckCircle, faEnvelopeOpen, faPaperPlane } from "@fortawesome/free-solid-svg-icons"
import DashboardNumber from "@/components/dashboard-number"
import { WithTranslation, withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { Session } from "@/redux/_session.types"
import { SurveyState } from "@/redux/survey.types"

interface StateProps extends WithTranslation{
  _session : Session
  survey : SurveyState
}

interface OwnProps{
  isSmall? : boolean
  column?: boolean
  width?: number
  withGauge?: boolean
}

type Props = StateProps & OwnProps


function DashboardParticipationOverviewWidget(props: Props){
  const { t } = props

  return (
    <div id="image-exportable-participation"
      className={"rel flex" + (props.column ? " flex-dcol" : "") + (props.isSmall ? " flex1" : "")}
      style={{ 
        minWidth : props.width,
        transform : props.isSmall ? "scale(0.25) translate(-80%, -150%)" : undefined,
      }}>

      { /*props.withGauge &&
      <div className="abs" style={{ top: -18, left: -80}}>
        <Gauge
          value={props.survey.active.getParticipationRate(props._session.dashboardDisplaySettings.hideStartedForParticipation)}
          minValue={0}
          maxValue={100}
          animationSpeed={32}
          options={{
            angle: 0.35,
            lineWidth: 0.1,
            radiusScale: 1,
            pointer: {
              length: 0.6,
              strokeWidth: 0.035,
              color: '#000000',
            },
            limitMax: false,
            limitMin: false,
            colorStart: props._session.accountColors.brandPrimary,
            colorStop: props._session.accountColors.brandPrimary,
            strokeColor: '#E9E9E9',
            generateGradient: true,
            highDpiSupport: true,
          }}
          textChangeHandler={()=>{}}
          donut
        />
      </div>
      */
      }

      <DashboardNumber count={props.survey.active.getParticipationRate(props._session.dashboardDisplaySettings.hideStartedForParticipation) + "%"}
        color={props._session.accountColors.brandPrimary}
        header={t("survey_participation_rate")}
        title={t("survey_participation")}
      />

      { /*props.withGauge &&
      <div className="height-40" />*/
      }
      
      <DashboardNumber 
        count={props.survey.active.participationSend}
        icon={faPaperPlane}
        header={t("users")}
        title={t("survey_invited")}
      />

      { !props._session.dashboardDisplaySettings.hideStartedForParticipation &&
      <DashboardNumber 
        count={props.survey.active.participationStarted}
        icon={faEnvelopeOpen}
        isInactive
        header={t("templates")}
        title={t("survey_started")}
      />
      }
      
      <DashboardNumber 
        count={props.survey.active.participationDone}
        icon={faCheckCircle}
        color={props._session.accountColors.brandPrimary}
        header={t("templates")}
        title={t("survey_done")}
      />

    </div>

  )
}


const mapStateToProps = state => ({
  _session : state._session,
  filter : state.filter,
  survey : state.survey
})

export default connect(mapStateToProps)(withTranslation()(DashboardParticipationOverviewWidget))

