/** 
 * USERS-LIST.WIDGET
 * Seach users in the database
 */

import { store } from "@/index";
import { withTranslation, WithTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { userDestroy, userFetch, userFetchCount, userGet, userGetCount, userGetCountSearch, userRemove, userStatus } from "@/redux/user.actions";
import { User, UserState } from "@/redux/user.types";
import { STATUS_LOADED, STATUS_LOADING, STATUS_LOAD_ERROR, STATUS_SAVED, STATUS_SAVE_ERROR, STATUS_SAVING, STATUS_SEARCHING } from "@/redux/_status.types";
import ListContainer from "@/components/list-container";
import ListFields from "@/components/list-fields";
import { connect } from "react-redux";
import ListItem from "@/components/list-item";
import ListButton from "@/components/list-button";
import ListCell from "@/components/list-cell";
import { faCrown, faPencilAlt, faTrash, faUserCircle } from "@fortawesome/free-solid-svg-icons";
import Space from "@/components/space";
import { Session } from "@/redux/_session.types";
import ListIcon from "@/components/list-icon";
import ModalConfirm from "@/components/modal-confirm";
import PageLoader from "@/components/page-loader";
import AdmEditUserModal from "@/modals/adm-edit-user.modal";
import Chip from "@/components/chip";

interface StateProps extends WithTranslation{
  _session : Session
  user : UserState
}

interface OwnProps{
  isCtaPrimary? : boolean
  cta? : string
  onClickCta? : Function
  onEditUser? : Function
}

type Props = StateProps & OwnProps

const MODAL_ADD:string = "MODAL_ADD" //Add new user
const MODAL_EDIT : string = "MODAL_EDIT" //Edit current user
const MODAL_DELETE : string = "MODAL_DELETE" //Delete user

//Limit of users to display by page
const LIMIT : number = 5

function AdmUsersWidget(props:Props) {
  const { t } = props

  //Dialog to display
  const [currentModal, setCurrentModal] = useState<string | null>(null)
  
  //Current offset for search (when page is changed)
  const [currentOffset, setCurrentOffset] = useState<number>(0)

  //Content for searchbar
  const [currentSearch, setCurrentSearch] = useState<string>("")

  //User to update
  const [currentUser, setCurrentUser] = useState(new User())

  //Page is loading
  const [isLoaded, setIsLoaded] = useState<boolean>(false)

  //Load users
  useEffect(() => {
    loadUsers()

    //Fetch users from backend and get count
    //If there is a search update count
    async function loadUsers(){

      //Fetch
      store.dispatch(userStatus(STATUS_SEARCHING))
      const users: any = await store.dispatch(userFetch(currentSearch, LIMIT, currentOffset, null, "id,email,firstname,lastname"))
      const usersCount: any = await store.dispatch(userFetchCount(currentSearch, null))

      if (users.error || usersCount.error){
        store.dispatch(userStatus(STATUS_LOAD_ERROR))
      }else{

        //Update store (list and count)
        store.dispatch(userGet(users))
        store.dispatch(userGetCountSearch(usersCount.count))
        if (currentSearch.length < 1) store.dispatch(userGetCount(usersCount.count))
        store.dispatch(userStatus(STATUS_LOADED))

      }

      setIsLoaded(true)

    }

  }, [
    currentOffset,
    currentSearch
  ])

  //add new user
  function add(){
    if (props.onClickCta){
      props.onClickCta()
    }else{
      setCurrentModal(MODAL_ADD)
    }
  }

  //On edit user
  //Dispatch save BDD at the same time
  function edit(user:User){
    setCurrentUser(user)
    setCurrentModal(MODAL_EDIT)
  }

  //End of editing user
  //Close modal and trigger props function
  function editUserEnd(){
    if (props.onEditUser){
      props.onEditUser()
    }
    setCurrentModal(null)
  }

  //Remove the user
  //Activate and open confirm modal
  function remove(user:User){
    if (props._session.userId !== user.id){
      setCurrentUser(user)
      setCurrentModal(MODAL_DELETE)
    }
  }

  //Confirm removing the active user
  async function removeConfirm(){
    store.dispatch(userStatus(STATUS_SAVING))
    const response:any = await store.dispatch(userDestroy(currentUser.id))

    if (response.error){
      store.dispatch(userStatus(STATUS_SAVE_ERROR))
    }else{
      store.dispatch(userStatus(STATUS_SAVED))
      store.dispatch(userRemove(currentUser.id))
      setCurrentModal(null)
    }
  }

  //Search user (match email, firstname and lastname)
  function search(search:string){
    setCurrentSearch(search)
  }

  //Change page and fetch users again
  function setPage(offset : number){
    setCurrentOffset(offset)
  }

  return (
    <ListContainer countLimit={LIMIT}
      countSearch={props.user.countSearch}
      isFooterActive
      onAddPrimary={props.isCtaPrimary}
      onAddTitle={props.cta ? props.cta : t("user_add")}
      onAdd={add}
      onSearch={search}
      onSearchStart={() => store.dispatch(userStatus(STATUS_SEARCHING))}
      onSetPage={setPage}
      status={props.user.status}>

      { currentModal === MODAL_ADD &&
      <AdmEditUserModal onClose={() => setCurrentModal(null)}
        onNext={editUserEnd}
      />
      }

      { currentModal === MODAL_EDIT &&
      <AdmEditUserModal userToUpdate={currentUser}
        onClose={() => setCurrentModal(null)}
        onNext={editUserEnd}
      />
      }

      { currentModal === MODAL_DELETE &&
      <ModalConfirm 
        onNo={() => setCurrentModal(null)}
        onYes={removeConfirm}
        status={props.user.status}
        text={t("user_ask_delete", {
          user : currentUser.username
        })}
        textBold={t("utils_next_ask")}
      />
      }

      <PageLoader status={isLoaded ? STATUS_LOADED : STATUS_LOADING}>

        <ListFields>
          <ListCell/>
          <ListCell width={150} text={t("user_firstname")}/>
          <ListCell width={150} text={t("user_lastname")}/>
          <ListCell width={250} text={t("user_email")}/>
          <Space/>
          <ListCell width={50}/>
          <ListCell width={50}/>
        </ListFields>

        { props.user.list.map((user) =>
        <ListItem key={user.id}
          isEditable
          onClick={() => edit(user)}>

          <ListIcon fallbackIcon={faUserCircle}
            image={user.imageUrl}
          />
          
          <ListCell width={150} text={user.firstname}/>
          <ListCell width={150} text={user.lastname}/>
          <ListCell width={250} text={user.email ? user.email : ""}/>

          { props._session.userId === user.id &&
          <Chip icon={faCrown}>
            {t("utils_self")}
          </Chip>
          }
          
          <Space/>
          
          <ListButton icon={faPencilAlt}
            text={t("user_edit")}
            onClick={() => edit(user)}
          />
          
          <ListButton icon={faTrash}
            text={props._session.userId === user.id ? t("utils_delete_self") : t("utils_delete")}
            isInactive={props._session.userId === user.id}
            onClick={() => remove(user)}
          />
          
        </ListItem>
        )
        }

      </PageLoader>

    </ListContainer>
  )
}

const mapStateToProps = state => ({
  _session : state._session,
  user : state.user
})

export default connect(mapStateToProps)(withTranslation()(AdmUsersWidget))
