/** 
 * INVITATION.REDUCERS
 * Edit invitation stare
 */

import { Invitation, InvitationState, INVITATION_ACTIVATE, INVITATION_EDIT_FROM_USER, INVITATION_GET, INVITATION_GET_COUNT, INVITATION_GET_COUNT_SEARCH, INVITATION_INIT, INVITATION_IS_GENERATING, INVITATION_REMOVE, INVITATION_STATUS } from "./invitation.types";
import { User } from "./user.types";
import { STATUS_LOADED } from "./_status.types";

let active:Invitation = new Invitation()
let user:User = new User()

const initialState: InvitationState = {
  active : new Invitation(),
  count : 0,
  countSearch : 0,
  isQrcodeGenerating : false,
  list : [],
  status : STATUS_LOADED,
}

export default function invitationReducer(
  state = initialState,
  action: any
): InvitationState {
  switch (action.type) {
  
    //Set active user
    case INVITATION_ACTIVATE:
      return Object.assign({}, state, {
        active : new Invitation(action.payload.invitation)
      })

    //Update invitation after editing user
    case INVITATION_EDIT_FROM_USER:
      active = new Invitation(state.active)
      user = new User(action.payload.user)
      active.address = user.address ? user.address : ""
      active.email = user.email
      active.language = user.language
      active.username = user.username
      return Object.assign({}, state, {
        active,
        list : state.list.map(x => x.aid === active.aid ? active : x)
      })

    //Get list of invitations
    case INVITATION_GET:
      return Object.assign({}, state, {
        list : action.payload.invitations.map((x:Invitation) => new Invitation(x))
      })

    //Get count of invitations (total)
    case INVITATION_GET_COUNT:
      return Object.assign({}, state, {
        count : action.payload.count
      })

    //Get count of invitations (with search)
    case INVITATION_GET_COUNT_SEARCH:
      return Object.assign({}, state, {
        countSearch : action.payload.countSearch
      })

    //Init store on app load
    case INVITATION_INIT:
      return Object.assign({}, state, {
        active : new Invitation(initialState.active),
        list : state.list.map((x:any) => new Invitation(x)),
        status : initialState.status,
        isQrcodeGenerating : state.isQrcodeGenerating
      })

    //Change generation status
    case INVITATION_IS_GENERATING:
      return Object.assign({}, state, {
        isQrcodeGenerating : action.payload.isQrcodeGenerating
      })

    //Remove an invitation
    //Update counts
    case INVITATION_REMOVE:
      return {
        ...state,
        count : state.count - 1,
        countSearch : state.countSearch - 1,
        list  : state.list.filter(x => x.aid !== action.payload.aid )
      }

    //Update loader status
    case INVITATION_STATUS:
      return {
        ...state,
        status : action.payload.status
      }

    default:
      return state

  }

}