/** 
 * SUPERVISORS-LIST.WIDGET
 * Seach users in the database for a supervisor
 */

import { connect } from "react-redux"
import { store } from "@/index";
import { withTranslation, WithTranslation } from "react-i18next";
import Space from "@/components/space";
import Card from "@/components/card";
import ListItem from "@/components/list-item";
import { useEffect, useState } from "react";
import ListContainer from "@/components/list-container";
import { userFetchCount_AsSupervisor, userFetch_AsSupervisor, userGet, userGetCount, userGetCountSearch, userStatus } from "@/redux/user.actions";
import { User, UserState } from "@/redux/user.types";
import { STATUS_LOADED, STATUS_LOADING, STATUS_LOAD_ERROR, STATUS_SEARCHING } from "@/redux/_status.types";
import PageLoader from "@/components/page-loader";
import ListFields from "@/components/list-fields";
import ListCell from "@/components/list-cell";
import onboarding from "@/utils/onboarding";
import ListButton from "@/components/list-button";
import { faPlayCircle, faTimes } from "@fortawesome/free-solid-svg-icons";
import Checkbox from "@/components/checkbox";
import Chip from "@/components/chip";


interface StateProps extends WithTranslation{
  user : UserState
}

interface OwnProps{
  onClick : Function
  onDelete : Function
}

type Props = StateProps & OwnProps

const LIMIT: number = 5

function SupervisorsListWidget(props:Props) {
  const { t } = props

  const [currentSearch, setCurrentSearch] = useState<string>("")
  const [currentOffset, setCurrentOffset] = useState<number>(0)
  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  const [onlyAdmins, setOnlyAdmins] = useState<boolean>(true)

  useEffect(() => {
    loadUsers()
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentOffset,
    currentSearch,
    onlyAdmins
  ])

  //Fetch users from backend
  async function loadUsers(){
    store.dispatch(userStatus(STATUS_SEARCHING))
    const users:any = await store.dispatch(userFetch_AsSupervisor(currentSearch, LIMIT, currentOffset, onlyAdmins?true:null))
    const usersCount:any = await store.dispatch(userFetchCount_AsSupervisor(currentSearch, onlyAdmins?true:null))

    setIsLoaded(true)

    if (users.error || usersCount.error){
      store.dispatch(userStatus(STATUS_LOAD_ERROR))
    }else{

      //Update list
      store.dispatch(userGet(users))

      //Update store
      store.dispatch(userGetCountSearch(usersCount.count))
      if (currentSearch.length<1) store.dispatch(userGetCount(usersCount.count))
      store.dispatch(userStatus(STATUS_LOADED))

    }
  }


  //Search user
  function searchUsers(search : string){
    setIsLoaded(false)
    if(currentOffset !==0){setCurrentOffset(0)}
    setCurrentSearch(search)
  }

  //Select only admins for the account
  function selectAdmins(event:any){
    setIsLoaded(false)
    if(currentOffset !==0){setCurrentOffset(0)}
    setOnlyAdmins(event.value)
  }

  //Change page
  function changePage(offset : number){
    setIsLoaded(false)
    setCurrentOffset(offset)
  }

  return (
    <Card isWithoutPadding 
      isOverflowHidden>

      <div className="checkbox-superadmin-filter flex">
        <Space/>
        <Checkbox onClick={selectAdmins} active={onlyAdmins} text={t("user_admins_filter")}/>
      </div>

      <ListContainer countLimit={LIMIT}
        countSearch={props.user.countSearch}
        isFooterActive
        onSearch={searchUsers}
        onSearchStart={() => store.dispatch(userStatus(STATUS_SEARCHING))}
        onSetPage={changePage}
        status={props.user.status}>

        <PageLoader status={isLoaded ? STATUS_LOADED : STATUS_LOADING}>
          <div>

            <ListFields>
              <ListCell width={260} text={t("user_lastname")}/>
              <Space/>
              <ListCell width={180} text={t("account_name")}/>
              <ListCell width={140} text={t("account_date_start")}/>
              <ListCell width={140} text={t("account_step_1")}/>
              <ListCell width={140} text={t("account_step_2")}/>
              <ListCell/>
              <ListCell/>
            </ListFields>

            { props.user.list.map((user:User) =>
            <div key={user.id}>

              <ListItem isEditable>

                <ListCell width={260}>
                  <div>
                    <div>{user.username}</div>
                    { user.email && 
                    <div className="grey-t"
                      style={{ fontSize : '12px' }}>
                      {user.email}
                    </div>
                    }
                  </div>
                </ListCell>

                <Space/>

                <ListCell width={180} text={user.Account.name}/>

                <ListCell width={140} text={user.createdAtLabel}/>

                <ListCell width={140}>
                  <Chip color={user.Account.Templates.length > 0 ? "#20CA7E" : null}>
                    {onboarding.getLabel(user.Account.dateStart, user.Account.Templates.map(x => x.createdAt))}
                  </Chip>
                </ListCell>

                <ListCell width={140}>
                  <Chip color={user.Account.Projects.length > 0 ? "#20CA7E" : null}>
                    {onboarding.getLabel(user.Account.dateStart, user.Account.Projects.map(x => x.createdAt))}
                  </Chip>
                </ListCell>

                <ListButton
                  icon={faTimes} 
                  onClick={() => props.onDelete(user)}
                  text={t("utils_delete")}
                />

                <ListButton isPrimary
                  icon={faPlayCircle} 
                  onClick={() => props.onClick(user.Account)}
                  text={t("utils_connect")}
                />

              </ListItem>

            </div>
            )}

          </div>
        </PageLoader>

      </ListContainer>

    </Card>
  )

}

const mapStateToProps = state => ({
  user : state.user
})

export default connect(mapStateToProps)(withTranslation()(SupervisorsListWidget))