/** 
 * BUTTON
 */
import { faCircleNotch, IconDefinition } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { connect } from "react-redux"
import { Session } from "@/redux/_session.types"
import ReactTooltip from 'react-tooltip'

interface StateProps {
  _session : Session
}

export type ButtonClassName = "primary" | "secondary" | "light"  | "med" | "warning" | ""

interface OwnProps{
  children? : any //Text inside
  icon? : IconDefinition //Fontawesome icon
  className? : ButtonClassName //Classname (primary, secondary, light)
  isFullWidth? : boolean //Take all width of the parent
  isLarge? : boolean //Is a big button
  isLoading? : boolean //Show loading spinner
  isWithBorder? : boolean //Show border
  onClick? : Function //Action to trigger on click
  type? : string //Type of button (exemple "submit")
  tooltip? : string
}

type Props = StateProps & OwnProps

function Button(props:Props) {

  //Action on click
  function click(e){
    if (props.onClick){
      props.onClick(e)
    }
  }

  //Set class of the button
  //différent if is large
  function getClassName(){
    let className = "button grey-t"

    if (props.isLarge){
      className = className + " button-large"
    }

    return className

  }

  //Set style
  //className == "primary"
  //> call to action
  //className === "secondary"
  //> only the text is highlight
  //className === "light"
  //> text is light grey
  //isFullWidth
  //> take all width from parent
  function getStyle(){
    let border
    let backgroundColor
    let color
    let margin
    let maxWidth

    if (props.className === 'primary'){
      backgroundColor = props._session.accountColors.cta
      color = 'white'
    }else if (props.className === 'secondary'){
      color = props._session.accountColors.cta
    }else if (props.className === 'light'){
      color = "#CCCCCC"
    }else if (props.className === 'warning'){
      backgroundColor = "#eb5a46"
      color = 'white'
    }
    
    if (props.isFullWidth){
      margin = '6px 0px'
      maxWidth = '100%'
    }
    
    if (props.isWithBorder){
      border = '1px solid'
    }

    return {
      border,
      backgroundColor,
      color,
      margin,
      maxWidth
    }

  }

  return (
    <button className={getClassName()}
      data-tip={props.tooltip}
      onClick={click}
      style={getStyle()}>
      
      { props.icon &&
      <FontAwesomeIcon icon={props.icon}/>
      }

      { props.isLoading &&
      <FontAwesomeIcon icon={faCircleNotch} spin/>
      }
      
      { props.children }

      <ReactTooltip className="tooltip"/>

    </button>
  )

}

const mapStateToProps = state => ({
  _session : state._session
})

export default connect(mapStateToProps)(Button)