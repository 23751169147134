/** 
 * DASHBOARD.HOME
 * List of surveys in progress or done
 */
import NavApp from "@/nav/nav.app";
import DashboardHomeWidget from "@/widgets/dashboard-home.widget";

function DashboardHomeRoute(){
  return(
    <NavApp>
      <DashboardHomeWidget/>
    </NavApp>
  )
}

export default DashboardHomeRoute