/** 
 * LANGUAGE-LOCK
 * Display current language with upgrade modal if module is locked
 */

import { faLock } from "@fortawesome/free-solid-svg-icons"
import { useState } from "react"
import Chip from "./chip"
import i18n from "@/translate/i18n";
import Space from "./space"
import UpgradeModal from "@/modals/upgrade.modal"

const MODAL_UPGRADE_LANGUAGES = "MODAL_UPGRADE_LANGUAGES" //Upgrade language

function LanguageLock() {

  const [currentModal, setCurrentModal] = useState<string | null>(null)

  return (
    <div className="flex">

      { currentModal === MODAL_UPGRADE_LANGUAGES &&
      <UpgradeModal feature="languages"
        onClose={() => setCurrentModal(null)}
      />
      }
      
      <Chip isWithMargin
        icon={faLock}
        onClick={() => setCurrentModal(MODAL_UPGRADE_LANGUAGES)}>
        {i18n.language.toUpperCase()}
      </Chip>

      <Space/>

    </div>
  )

}

export default LanguageLock