/**
 * FILTER.REDUCERS
 */

import { DashboardFilters, Filter, FilterState, FILTER_ACTIVATE, FILTER_ADD, FILTER_EDIT, FILTER_EDIT_DASHBOARD, FILTER_EDIT_DASHBOARD_COMPARE, FILTER_GET, FILTER_GET_IMPORT_RESPONSE, FILTER_GET_OBSERVER_ATTRIBUTES, FILTER_INIT, FILTER_REMOVE, FILTER_REPLACE_DASHBOARD, FILTER_REPLACE_DASHBOARD_COMPARE, FILTER_STATUS } from './filter.types';
import { STATUS_LOADED } from './_status.types';
import { v4 as uuid } from "uuid";

let active:Filter
let dashboard:DashboardFilters

const initialState: FilterState = {
  active : new Filter(), 
  dashboard : new DashboardFilters(),
  dashboardCompare : new DashboardFilters(),
  observerAttributes : [],
  list : [],
  status : STATUS_LOADED,
  importResponse : {}
}

export default function filterReducer(
  state = initialState,
  action: any
): FilterState {
  switch (action.type) {

    //Get active filter
    case FILTER_ACTIVATE:
      return Object.assign({}, state, {
        active : new Filter(action.payload.filter)
      })

    //Add a new filter
    case FILTER_ADD:
      active = new Filter(action.payload.filter)
      active.id = uuid()
      return Object.assign({}, state, {
        active,
        list : [...state.list, active]
      })

    //Edit filter
    case FILTER_EDIT:
      active = Object.assign(state.active, { [action.payload.key] : action.payload.value })
      return Object.assign({}, state, {
        active,
        list : state.list.map(x => x.id === active.id ? active : x)
      })

    //Edit filter for dashboard
    case FILTER_EDIT_DASHBOARD:
      dashboard = Object.assign(state.dashboard, { [action.payload.key] : action.payload.value })
      return Object.assign({}, state, {
        dashboard : new DashboardFilters(dashboard)
      })

    //Edit filter for dashboard
    case FILTER_EDIT_DASHBOARD_COMPARE:
      dashboard = Object.assign(state.dashboardCompare, { [action.payload.key] : action.payload.value })
      return Object.assign({}, state, {
        dashboardCompare : new DashboardFilters(dashboard)
      })

    //Save filter list to the store
    //Init dashboard filters
    case FILTER_GET:
      return {
        ...state,
        active : new Filter(),
        list : action.payload.filters.map((x) => new Filter(x)),
        dashboard : new DashboardFilters()
      }

    //Save proposition of filters from file in the store
    case FILTER_GET_IMPORT_RESPONSE:
      return {
        ...state,
        active : new Filter(),
        importResponse : action.payload.response
      }
    
    //Get observer attributes
    case FILTER_GET_OBSERVER_ATTRIBUTES:
      return {
        ...state,
        observerAttributes : action.payload.observerAttributes
      }

    //Init store
    case FILTER_INIT:
      return Object.assign({}, state, {
        active : new Filter(state.active),
        dashboard : new DashboardFilters(state.dashboard),
        dashboardCompare : new DashboardFilters(state.dashboardCompare),
        list : state.list.map((x:any) => new Filter(x)),
        status : initialState.status,
        observerAttributes : state.observerAttributes
      })

    //Remove filter
    case FILTER_REMOVE:
      return {
        ...state,
        list  : state.list.filter(i => { return i.id !== action.payload.id })
      }

    //Remplace dashboard filters
    case FILTER_REPLACE_DASHBOARD:
      return Object.assign({}, state, {
        dashboard : new DashboardFilters(action.payload.dashboardFilters)
      })

    //Remplace dashboard filters
    case FILTER_REPLACE_DASHBOARD_COMPARE:
      return Object.assign({}, state, {
        dashboardCompare : new DashboardFilters(action.payload.dashboardFilters)
      })

    //Update status
    case FILTER_STATUS:
      return {
        ...state,
        status : action.payload.status
      }


    default:
      return state

  }
  
}