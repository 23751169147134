/**
 * OBSERVER-TO-ATTRIBUTES
 * Convert observer data to attributes (in order to have filters for observer because they have two different input format)
 */

import { forOwn, groupBy } from "lodash";
import { FilterLite } from "@/redux/filter.types";
import { Population } from "@/redux/population.types";
import { validate as isUuidValid } from "uuid"; 

const filtersToAttributes = (customFilters:FilterLite[], observerAttributes:Population[], isObserverNotRestricted:boolean, isObserver:boolean) => {
  
  //Init output attributes query string
  let attributes = ""

  //Transform observer attributes to groups of Filters
  customFilters = populationToFilterLite(customFilters, observerAttributes)

  //Set attributes array from attributes
  attributes = filterGroupToArray(customFilters.filter(x => !x.subFilter)).join(isObserverNotRestricted && isObserver ? "," : ";")
  if (attributes.length){

    //Add query param
    attributes = "attributes=" + attributes

    //Add subfilters if required
    const subFilters = customFilters.filter(x => x.subFilter)
    if (subFilters.length){
      attributes = attributes + "&sub_filters=" + filterGroupToArray(subFilters).join(";")
    }

  }

  //Return the results
  return attributes
  
}

//Transform filter group to array of attributes aid
function filterGroupToArray(filters:FilterLite[]){
  return filters.map((x:FilterLite)=>
    x.attributesIds.join(",")
  )
}

  //Transform observer attributes to groups of Filters
export function populationToFilterLite(customFilters:FilterLite[], observerAttributes:Population[]){
  const observerAttributesGroups = groupBy(observerAttributes, "filterName")

  forOwn(observerAttributesGroups, (value, key) => {
    const attribute:FilterLite = { id : key, attributesIds : value.filter(x => !isUuidValid(x.id)).map(x => x.id), subFilter : false }
    if (attribute.attributesIds.length > 0){
      customFilters.push(attribute)
    }
  })

  return customFilters

}

export default filtersToAttributes