/** QRCODE.ACTIONS
 * Actions for qrcode backend
 */

import { ProjectQrcodeOptions } from "./project.types"

export const generatePDFCheck = (surveyId:string) => ({
  type : 'QRCODE',
  payload : {
    method : "GET",
    url : "/check",
    data : {
      surveyId
    }
  }
})

export const generatePDFArchive = (surveyId:string, customName:string) => ({
  type : 'QRCODE',
  payload : {
    method : "POST",
    url : "/generate/archive",
    data : {
      surveyId,
      customName
    }
  }
})

export const generatePDFFolder = (surveyId: string, isMultiple: boolean) => ({
  type : 'QRCODE',
  payload : {
    method : "POST",
    url : "/generate/folder",
    data : {
      surveyId,
      isMultiple
    }
  }
})

export const generatePDFMultiple = (surveyId: string, limit:number, offset:number, isForEveryone:boolean, qrcodeOptions: any, attributes:string) => ({
  type : 'QRCODE',
  payload : {
    method : "POST",
    url : "/generate/multiple",
    data : {
      surveyId,
      limit,
      offset,
      isForEveryone,
      qrcodeOptions,
      attributes
    }
  }
})

export const generatePDFTemplate = (surveyId, language) => ({
  type : 'QRCODE',
  payload : {
    method : "POST",
    url : "/generate/template",
    data : {
      surveyId,
      language
    }
  }
})

export const generatePDFUnique = (surveyId: string, invitationAid: number, qrcodeOptions: ProjectQrcodeOptions) => ({
  type : 'QRCODE',
  payload : {
    method : "POST",
    url : "/generate/unique",
    data : {
      surveyId,
      invitationAid,
      qrcodeOptions
    }
  }
})

export const fetchPDFList = (surveyId:string) => ({
  type : 'QRCODE',
  payload : {
    method : "GET",
    url : "/list",
    data : {
      surveyId,
    }
  }
})
