/** 
 * PROJECT-STEP-ERRORS
 * Display errors for one step
 */
import { faCaretRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import ListItem from "@/components/list-item"
import Space from "@/components/space"
import ToggleButton from "@/components/toggle-button"
import { store } from "@/index"
import { withTranslation, WithTranslation } from "react-i18next"
import { connect } from "react-redux"
import { projectEdit, projectStatus, projectUpdate } from "@/redux/project.actions"
import { ProjectState, ProjectStep, StepAlert } from "@/redux/project.types"
import { STATUS_SAVED, STATUS_SAVING } from "@/redux/_status.types"

interface StateProps extends WithTranslation{
  project : ProjectState
}

interface OwnProps{
  alert : StepAlert
  step : ProjectStep
}

type Props = StateProps & OwnProps

function ProjectStepErrors(props:Props) {
  const { t } = props

  //Class name depends of errors, warning and warning active
  function getClassName():string{
    let className = "setup-card-alert "

    if (props.alert.errors.length > 0){
      className += "setup-card-alert-error"
    }else if (isWarningActive()){
      className += "setup-card-alert-warning"
    }else{
      className += "grey-t"
    }
    
    return className

  }

  //Detect if warning active
  function isWarning(): boolean{
    return props.alert.warnings.length > 0 && props.project.active.showWarnings
  }

  //Detect if the warning is active
  function isWarningActive():boolean{
    return props.project.active.isStepWarningActive(props.step)
  }
  
  //Toogle warnings for the step only
  async function toogleWarning(){

    store.dispatch(projectEdit(
      "inactiveWarnings", 
      isWarningActive()
      ?
      props.project.active.inactiveWarnings.concat([props.step]) 
      :
      props.project.active.inactiveWarnings.filter(x => x !== props.step)
    ))

    //Save to the database
    setTimeout(() => {

      save()

      async function save(){
        store.dispatch(projectStatus(STATUS_SAVING))
        await store.dispatch(projectUpdate(props.project.active))
        store.dispatch(projectStatus(STATUS_SAVED))
      }
      
    }, 500)

  }

  return (
    (isWarning() || props.alert.errors.length > 0) 
    ?
    <div className={getClassName()}>
      <ListItem>
        <div>

          { props.alert.errors.length > 0 &&
          <div>

            <b>
              {props.alert.errors.length + " " + t("utils_error", { s: props.alert.errors.length > 1 ? "s" : ""})}
            </b>

            { props.alert.errors.map((error:string, i:number)=>
            <div key={i}>
              <FontAwesomeIcon icon={faCaretRight}/>
              &nbsp;
              {t(error)}
            </div>
            )}

          </div>
          }

          {( props.alert.errors.length > 0 && isWarning() ) &&
          <div style={{ height : 6 }}></div>
          }

          { isWarning() &&
          <div>

            <b>
              {props.alert.warnings.length + " " + t("utils_warning", { s: props.alert.warnings.length > 1 ? "s" : ""})}
            </b>

            { isWarningActive() &&
            props.alert.warnings.map((warning:string, i:number)=>
            <div key={i}>
              <FontAwesomeIcon icon={faCaretRight}/>
              &nbsp;
              {t(warning)}
            </div>
            )}

            <div className="flex"
              style={{ marginTop : 12 }}>
              <ToggleButton color="#F2D600" 
                isActive={isWarningActive()}
                onToogle={() => toogleWarning()}
              />
              <div className="grey-t"
                style={{ fontSize : 12, margin : "4px 8px" }}>
                {t("project_show_warnings_step")}
              </div>
              <Space/>
            </div>
            

          </div>
          }

        </div>
      
      </ListItem>

    </div>
    :
    null
  )

}

const mapStateToProps = state => ({
  project : state.project
})

export default connect(mapStateToProps)(withTranslation()(ProjectStepErrors))