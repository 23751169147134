/**
 * DASHBOARD-REPARTITION-BARS.WIDGET
 */

import { max, sum } from "lodash"
import { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Session } from "../redux/_session.types"
import ReactTooltip from 'react-tooltip'
import { withTranslation, WithTranslation } from "react-i18next"
import Space from "../components/space";
import { AccountOptions } from "@/redux/account.types"
import getRepartitionColor from "@/utils/get-repartition-color.utils"
import { getRepartitionRatio } from "@/utils/satisfaction.utils"
import { responseLabel } from "@/utils/response-label.utils"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons"

interface StateProps{
  _session: Session
}

interface OwnProps{
  values: number[]
  classicColors?: boolean
  questionNpsAid?: number
  displaySatisfaction?: boolean
  isReverse?: boolean
  showSubtext?: boolean
  showCeils?: boolean
}

type Props = StateProps & OwnProps & WithTranslation

function DashboardRepartitionBarsWidget(props: Props){
  const { t } = props

  const [ceils] = useState(props.questionNpsAid ? [6, 8] : props._session.dashboardDisplaySettings.ceilsRepartition)

  const [values, setValues] = useState<number[]>(props.values)
  const [valuesSum, setValuesSum] = useState<number>(0)
  const [valueMax, setValueMax] = useState<number>(1)

  useEffect(() => {

    if(props.values.length > 0){
      setValuesSum(sum(props.values))
      setValueMax(max(props.values)!)
      setValues(props.values)
    }
    else{
      setValueMax(1)
      setValues([])
    }
  }, [
    props.values
  ])


  function getBarHeight(value){
    let height = 0

    if (valueMax > 0){
      height = (value / valueMax) * 100
      if (height < 2 && height > 0) height = 2
    }

    return height

  }

  function getGradient(i) {
    const totalDivs = 10
    // Define gradient color stops with opacity included
    const colorStops = [
        { r: 235, g: 90, b: 70, a: 0.4, pos: 0 },     // Color 1 (0%)
        { r: 153, g: 239, b: 194, a: 0.5, pos: 70 },  // Color 2 (70%)
        { r: 32, g: 202, b: 126, a: 0.6, pos: 100 }     // Color 3 (100%)
    ];

    // Calculate positions for the start and end of this div's gradient segment
    const startPos = (i / totalDivs) * 100;
    const endPos = ((i + 1) / totalDivs) * 100;

    // Helper function to interpolate between two color stops
    function interpolateColor(start, end, pos) {
        return {
            r: Math.round(start.r + (end.r - start.r) * pos),
            g: Math.round(start.g + (end.g - start.g) * pos),
            b: Math.round(start.b + (end.b - start.b) * pos),
            a: start.a + (end.a - start.a) * pos
        };
    }

    // Find colors at startPos and endPos
    function getColorAtPosition(pos) {
        let startColor, endColor;
        for (let j = 0; j < colorStops.length - 1; j++) {
            if (pos >= colorStops[j].pos && pos <= colorStops[j + 1].pos) {
                startColor = colorStops[j];
                endColor = colorStops[j + 1];
                break;
            }
        }
        const range = endColor.pos - startColor.pos;
        const relativePos = (pos - startColor.pos) / range;
        return interpolateColor(startColor, endColor, relativePos);
    }

    // Calculate colors at startPos and endPos
    const startColor = getColorAtPosition(startPos);
    const endColor = getColorAtPosition(endPos);

    // Format as CSS linear-gradient string with RGBA
    return `linear-gradient(90deg, rgba(${startColor.r}, ${startColor.g}, ${startColor.b}, ${startColor.a}) 0%, rgba(${endColor.r}, ${endColor.g}, ${endColor.b}, ${endColor.a}) 100%)`;
  
  }

  //Style
  function getStyle(value: number, i: number){
    let backgroundColor = ""

    if(props.classicColors){
      backgroundColor = getRepartitionColor((i >= 2 && values.length === 4) ? i + 1 : i, values.length, false, new AccountOptions(), props.values.length === 10)
    }
    else if (i + 1 > (props.showCeils ? ceils[1] : props._session.dashboardDisplaySettings.ceilsRepartition[1])){
      //green
      backgroundColor = "#20CA7E"
    }else if (i + 1 <= (props.showCeils ? ceils[0] : props._session.dashboardDisplaySettings.ceilsRepartition[0])){
      //red
      backgroundColor = "#eb5a46"
    }else{
      if (props.questionNpsAid){
        //yellow
        backgroundColor = "#f2d600"
      }else{
        //lightgreen
        backgroundColor = "#99efc2"
      }
    }

    return {
      backgroundColor,
      height : getBarHeight(value)
    }

  }

  //Text for tooltip
  function getText(value: number, i: number){
    if(props.classicColors){
      return t("user_questions_count", {
        count : value,
        s : value > 1 ? "s" : "",
        ratio : getRepartitionRatio(value, values).toFixed(1)
      })
    }
    else{
      let j = 0.1

      if (props.displaySatisfaction){
        i = i * 10
        j = j * 10
      }

      return t("survey_repartition_text_" + (props.displaySatisfaction ? "satisfaction" : "note"), { 
        count : value,
        ratio : (value / valuesSum * 100).toFixed(1),
        s : value > 1 ? "s" : "",
        min : (i + j).toFixed(0),
        max : i === 9 ? 10 : (i + j * 10 - 0.1).toFixed(1),
        total : values.length
      })
    }
    
  }

  function getSubtext(index: number): string{
    const responseCount: number = values.length
    return responseLabel(props.isReverse ? responseCount - index : index + 1, responseCount) + ""
  }

  //Get sum by group of people
  function getSum(min, count){
    const total = sum(values.slice(min, count))
    return (total / valuesSum * 100).toFixed(1) + "%"
  }
  
  //Get repartition
  function getRepartitionLabel(min:number, max:number, label:string){
    label = t("survey_repartition_" + label + (props.questionNpsAid ? "_nps" : ""))
    return (
      <div style={{ width : (max - min) * 26 + (max - min - 1) * 16 }}>
        <div className="survey-rps-bottom grey-b"></div>
  
        { max - min > 1
        ?
        <div className="survey-rps-bottom-text">
          {label}
          <br></br>
          <b>{getSum(min, max)}</b>
        </div>
        :
        <div className="survey-rps-bottom-text">
          <FontAwesomeIcon icon={faInfoCircle} className="_hover" data-tip={label + " : " + getSum(min, max)}/>
        </div>
        }
  
      </div>
    )
  }

  return (
    <div>
      <div className="flex">

        { values.map((value: number, i: number) =>
        <div className="flex1"
          key={i}>

          <div className="flex"
            data-tip={getText(value, i)}>
            <div className="repartition-bar flex flex-dcol">
              <Space />
              <div className="repartition-bar rel"
                style={getStyle(value, i)}>
                <div className="repartition-bar-content abs grey-t"
                  style={{
                    color : getBarHeight(value) > 16 ? "white" : "",
                    top : getBarHeight(value) < 16 ? -18 : 2 
                  }}>
                  <b>{value}</b>
                </div>
              </div>
            </div>
          </div>

          <div className="grey-t flex"
            style={{
              fontSize : 12,
              marginTop : 4,
              height : 20
            }}>

            { i === 0 && <div className="red-t" style={{ textAlign : "center", width : 20 }}>0</div>}

            <div className="flex1 flex">

              <div className="flex-auto"
                style={{
                  height : 4,
                  width : "100%",
                  background : getGradient(i)
                }}>
              </div>

            </div>

            { i === 9 && <div className="green-t" style={{ textAlign : "center", width : 26 }}>10</div>}
            
          </div>

          { props.showSubtext &&
          <div className="flex" style={{marginTop: 4, fontSize: 10, textAlign: "center"}}>
            <Space/>
            {getSubtext(i)}
            <Space/>
          </div>
          }

        </div>
        )}

      </div>

      { props.showCeils &&
      <div className="flex">
        <div style={{ width : 8 }}/>
        {getRepartitionLabel(0, ceils[0], "unhappy")}
        <div style={{ width : 14 }}></div>
        {getRepartitionLabel(ceils[0], ceils[1], "happy")}
        <div style={{ width : 14 }}></div>
        {getRepartitionLabel(ceils[1], 10, "veryhappy")}
        <div style={{ width : 8 }}/>
      </div>
      }

      <ReactTooltip/>
    </div>
  )
}

const mapStateToProps = state => ({
  _session : state._session,
})

export default connect(mapStateToProps)(withTranslation()(DashboardRepartitionBarsWidget))
