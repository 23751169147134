/**
 * QUESTION.REDUCERS
 */

import { STATUS_LOADED } from './_status.types';
import { Question, QuestionState, QUESTION_ACTIVATE, QUESTION_EDIT, QUESTION_GET, QUESTION_GET_COUNT, QUESTION_GET_COUNT_SEARCH, QUESTION_INIT, QUESTION_REMOVE, QUESTION_STATUS } from './question.types';

let active
let list

const initialState: QuestionState = {
  active : new Question(),
  count : 0,
  countSearch : 0,
  list : [],
  status : STATUS_LOADED
}

export default function questionReducer(
  state = initialState,
  action: any
): QuestionState {
  switch (action.type) {

    //Get active question
    case QUESTION_ACTIVATE:
      return Object.assign({}, state, {
        active : new Question(action.payload.question)
      })

    //Edit question
    case QUESTION_EDIT:
      active = Object.assign(state.active, { [action.payload.key] : action.payload.value })
      return Object.assign({}, state, { 
        active,
        list : state.list.map(x => x.id === active.id ? active : x)
      })

    //Save list in the store
    case QUESTION_GET:
      list = action.payload.questions
      return {
        ...state,
        list : list.map((x) => new Question(x))
      }

    //Save count total of templates
    case QUESTION_GET_COUNT:
      return Object.assign({}, state, {
        count : action.payload.count
      })

    //Save count of templates searched
    case QUESTION_GET_COUNT_SEARCH:
      return Object.assign({}, state, {
        countSearch : action.payload.countSearch
      })

    //Init store
    case QUESTION_INIT:
      return Object.assign({}, state, {
        ...state,
        active : new Question(state.active),
        list : state.list.map((x:any) => new Question(x)),
        status : initialState.status
      })

    //Remove a question
    case QUESTION_REMOVE:
      return {
        ...state,
        list  : state.list.filter(i => { return i.id !== action.payload.id })
      }

    //Update status
    case QUESTION_STATUS:
      return {
        ...state,
        status : action.payload.status
      }

    default:
      return state

  }
  
}