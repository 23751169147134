/**
 * ADM-ROLES.ROUTE
 * Set up roles for the account (admin, observers)
 */

import { WithTranslation, withTranslation } from 'react-i18next';
import PageHeader from "@/components/page-header";
import NavApp from "@/nav/nav.app"
import AdmRolesWidget from "@/widgets/adm-roles.widget";
import AdmAddRoleWidget from '@/widgets/adm-add-role.widget';
import AdmRolesAffectWidget from '@/widgets/adm-roles-affect.widget';

type Props = WithTranslation

function AdmRolesRoute(props: Props){
  const { t } = props

  return(
    <NavApp>

      <PageHeader title={t("roles")}
        subtitle={t("roles_help")}>
        <AdmAddRoleWidget/>
      </PageHeader>

      <AdmRolesWidget/>

      <AdmRolesAffectWidget/>

    </NavApp>
  )

}

export default withTranslation()(AdmRolesRoute)