/** 
 * TASKS-TEAMS.WIDGET
 * Get actions plan by teams
 */

import { faEnvelope, faPencilAlt, faPlus, faUserCircle } from "@fortawesome/free-solid-svg-icons"
import Card from "@/components/card"
import ListButton from "@/components/list-button"
import ListCell from "@/components/list-cell"
import ListContainer from "@/components/list-container"
import ListFields from "@/components/list-fields"
import ListIcon from "@/components/list-icon"
import ListItem from "@/components/list-item"
import PageLoader from "@/components/page-loader"
import Space from "@/components/space"
import { store } from "@/index"
import { useEffect, useState } from "react"
import { withTranslation, WithTranslation } from "react-i18next"
import { connect } from "react-redux"
import { Session } from "@/redux/_session.types"
import { STATUS_LOADED, STATUS_LOADING, STATUS_SEARCHING } from "@/redux/_status.types"
import { actionPlanActivate, actionPlanAdd, actionPlanFetch, actionPlanFetchSelf, actionPlanGet, actionPlanSend } from "@/redux/action-plan.actions"
import { ActionPlan, ActionPlanState } from "@/redux/action-plan.types"
import { userActivate, userFetch, userFetchCount, userGet, userStatus } from "@/redux/user.actions"
import { User, UserState } from "@/redux/user.types"
import AdmUserPopulationsWidget from "./adm-user-populations.widget"
import Chip from "@/components/chip"
import ProgressBar from "@/components/progress-bar"
import { NavigateFunction, useNavigate } from "react-router-dom"
import { uniq } from "lodash"
import { toast } from "react-toastify"
import { formatDate } from "@/utils/format-date.utils"

interface StateProps extends WithTranslation{
  _session: Session
  actionPlan: ActionPlanState
  user: UserState
}

//Limit of users to display by page
const LIMIT : number = 10

function TasksTeamsWidget(props:StateProps) {
  const { t } = props
  const navigate: NavigateFunction = useNavigate()

  //Current offset for search (when page is changed)
  const [currentOffset, setCurrentOffset] = useState<number>(0)

  //Content for searchbar
  const [currentSearch, setCurrentSearch] = useState<string>("")

  //Data is loading
  const [isLoading, setIsLoading] = useState<boolean>(true)

  //Get search count of managers
  const [searchCount, setSearchCount] = useState<number>(0)

  //Load data
  //Fetch all observers for the account
  useEffect(() => {

    load()

    async function load(){

      const actionPlans:any = await store.dispatch(props._session.userRole === "ADMIN" ? actionPlanFetch() : actionPlanFetchSelf())
      const admins:any = await store.dispatch(userFetch(currentSearch, LIMIT, currentOffset, true, "id,email,firstname,lastname,role"))
      
      if (actionPlans.error || admins.error){
        store.dispatch(userGet([]))
        store.dispatch(actionPlanGet([]))
      }else{

        store.dispatch(actionPlanGet(actionPlans))

        const usersWithActionPlan: string[] = uniq(actionPlans.map((x: ActionPlan) => x.UserIdResponsible!))

        store.dispatch(userGet(admins.filter((x: User) => !usersWithActionPlan.includes(x.id))))

      }

      store.dispatch(userStatus(STATUS_LOADED))
      setIsLoading(false)

    }

  }, [
    currentOffset,
    currentSearch
  ])

  //Update count of users
  useEffect(() => {

    updateAdminCount()

    async function updateAdminCount(){
      const response: any = await store.dispatch(userFetchCount(currentSearch, true))
      setSearchCount(response.error ? 0 : response.count)
    }

  }, [currentSearch])

  //Create new action plan for someone
  function createActionPlan(user: User){
    store.dispatch(userActivate(user))
    store.dispatch(actionPlanAdd(
      user, 
      props._session.connectedAsSupervisor ?
        null
      :
        new User({
          id: props._session.userId,
          email: props._session.email,
          firstname: props._session.userFirstname,
          lastname: props._session.userLastname,
          role: props._session.userRole
        }),
      null
    ))
    navigate("/tasks/edit?isNew=true")
  }

  //Edit draft action plan
  function editActionPlan(actionPlan: ActionPlan){
    if (actionPlan.UserResponsible){
      store.dispatch(userActivate(actionPlan.UserResponsible))
    }
    store.dispatch(actionPlanActivate(actionPlan))
    navigate("/tasks/edit?ap=" + actionPlan.id)
  }

  function isUserResponsibleEmailProvided(user: User | null): boolean{
    if (user && user.email && user.email.length > 0){
      return true
    }
    else{
      return false
    }
  }

  //Send invitation email
  async function send(actionPlan: ActionPlan){
    const response: any = await store.dispatch(actionPlanSend(actionPlan.id, props._session.userName))
    if (!response.error){
      store.dispatch(actionPlanGet(props.actionPlan.list.map((ap: ActionPlan) => ap.id === actionPlan.id ? new ActionPlan({...ap, dateSend: new Date()}) : ap )))
      toast(t("utils_email_send", { email: actionPlan.UserResponsible?.email }), { type: "success" })
    }
  }

  return (
    <Card isWithoutPadding>

      <ListContainer countLimit={LIMIT}
        countSearch={searchCount}
        isFooterActive
        onAddTitle={t("user_add")}
        onSearch={setCurrentSearch}
        onSearchStart={() => store.dispatch(userStatus(STATUS_SEARCHING))}
        onSetPage={setCurrentOffset}
        status={props.user.status}>

        <PageLoader status={isLoading ? STATUS_LOADING : STATUS_LOADED}>

          <ListFields>
            <ListCell/>
            <ListCell width={170} text={t("tasks_teams_manager")}/>
            <ListCell width={155} text={t("tasks_teams_populations")}/>
            <Space/>
            <ListCell width={400} text={t("tasks")}/>
          </ListFields>

          {( props.actionPlan.list.length === 0 && props.user.list.length === 0 ) &&
          <ListFields>
            {t("roles_empty")}
          </ListFields>
          }

          { props.actionPlan.list.map((actionPlan: ActionPlan)=>
          <ListItem key={actionPlan.id}>  
            
            <ListIcon fallbackIcon={faUserCircle}
              image={actionPlan.UserResponsible?.imageUrl}
            />

            <ListCell width={170} text={actionPlan.UserResponsible?.username}/>

            { actionPlan.UserResponsible &&
            <AdmUserPopulationsWidget currentUser={actionPlan.UserResponsible}/>
            }

            <Space/>

            <ListCell width={400}>

              <div className="flex">

                <Chip>
                  {actionPlan.statusLabel}
                </Chip>

                { actionPlan.status === "active" &&
                <div style={{ width : 100, marginLeft : 12 }}>
                  <div style={{ margin : "-6px 0px"}}>
                    <ProgressBar
                      items={[
                        {value: actionPlan.tasksDoneCount}
                      ]}
                      max={actionPlan.tasks.length}
                    />
                  </div>
                </div>
                }
              
              </div>
              
              <Space/>

              { actionPlan.status === "active" &&
              <>
                {isUserResponsibleEmailProvided(actionPlan.UserResponsible) ? 
                <ListButton icon={faEnvelope}
                  text={actionPlan.dateSend ? t("user_invited_at", { date: formatDate(actionPlan.dateSend, false, false)}) : t("tasks_launch_email_yes", { email: actionPlan.UserResponsible?.email })}
                  dot
                  dotColor={actionPlan.dateSend ? "green" : "red"}
                  onClick={actionPlan.dateSend ? undefined : () => send(actionPlan)}
                  isInactive={actionPlan.dateSend ? true : false}
                />
                :
                <ListButton icon={faEnvelope}
                  text={t("invitation_no_email")}
                  isInactive
                />
                }
              </>
              }

              <ListButton icon={faPencilAlt}
                text={t("utils_edit")}
                onClick={() => editActionPlan(actionPlan)}
              />
              
            </ListCell>

          </ListItem>
          )}

          { props.user.list.map((user: User)=>
          <ListItem key={user.id}>  
            
            <ListIcon fallbackIcon={faUserCircle}
              image={user.imageUrl}
            />

            <ListCell width={170} text={user.username}/>

            <AdmUserPopulationsWidget currentUser={user}/>

            <Space/>

            <ListCell width={400}>

              <div className="medgrey-t flex flex-auto"
                style={{ fontSize : 12 }}>
                {t("tasks_teams_empty")}
              </div>
              
              <Space/>

              <ListButton icon={faPlus}
                onClick={() => createActionPlan(user)}
                isPrimary
                text={t("tasks_add")}
              />
              
            </ListCell>

          </ListItem>
          )}

        </PageLoader>

      </ListContainer>

    </Card>
  )

}

const mapStateToProps = state => ({
  _session: state._session,
  actionPlan: state.actionPlan,
  user: state.user
})

export default connect(mapStateToProps)(withTranslation()(TasksTeamsWidget))