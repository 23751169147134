/**
 * ONBOARDING-VIDEO
 * Video displayed in app
 */

import Button from "@/components/button";
import Card from "@/components/card";
import Space from "@/components/space";
import { connect } from "react-redux";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { Session } from "@/redux/_session.types";

interface Props{
  _session : Session
}

const VIDEOS = [
  { id : "IVJJKGNxtkE", title : "4 étapes pour mettre en place une démarche QVT" },
  { id : "qWd9QDK-lIk", title : "3 astuces pour réussir votre enquête QVT" },
  { id : "QES_D04hapU", title : "L'éditeur de questionnaires IDTree" },
  { id : "lAXsOck6Bvk", title : "Comment rédiger un bon questionnaire QVT ?" }
]

function OnboardingVideo(props: Props){
  const navigate: NavigateFunction = useNavigate()

  const video: any = getVideo()

  function getVideo(){
    const video = VIDEOS.find(x => window.location.pathname.indexOf(x.id) > -1)
    return video ? video : { id : null, title : "" }
  }

  return(
    <div className="flex"
      style={{
        width: "100vw",
        height: "100vh"
      }}>

      <div className="flex flex-dcol flex1 onboarding-intro">

        <Space/>

        <div className="flex">

          <Space/>

          <Card className="flex flex-dcol">

            <Space/>

            <h1 className="green-t"
              style={{ margin : "0px 120px" }}>
              {video.title}
            </h1>

            <Space/>

            <div className="flex">
              <Space/>
              { video.id
              ?
              <iframe width="672" height="378" 
                className="onboarding-video-iframe"
                src={"https://www.youtube.com/embed/" + video.id + "?modestbranding=1"}
                title="YouTube video player" 
                frameBorder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowFullScreen>
              </iframe>
              :
              <div>
                <b>{"La vidéo demandée n'existe pas"}</b>
              </div>
              }
              <Space/>
            </div>

            <Space/>

            { props._session.authLevel > 0
            ?
            <div className="flex">
              <Space/>
              <Button onClick={() => navigate("/survey/templates")}
                className="primary">
                Accéder aux questionnaires
              </Button>
            </div>
            :
            <div className="flex">
              <Space/>
              <Button onClick={() => navigate("/onboarding/intro/0")}>
                Retour
              </Button>
              <Button className="primary"
                onClick={() => navigate("/onboarding/signup")}>
                Accéder aux questionnaires
              </Button>
            </div>
            }
            
            <img src={require("@/assets/onboarding/background.png")}
              alt="background"
              style={{
                position : 'absolute',
                width : '400px',
                bottom : '0px',
                left : '0px',
                borderRadius : '8px',
                transform : 'scaleX(-1)'
              }}
            />

          </Card>

          <Space/>

        </div>

        <Space/>

      </div>

    </div>
  )
}

const mapStateToProps = state => ({
  _session : state._session
})

export default connect(mapStateToProps)(OnboardingVideo)