/**
 * MODAL-VIDEO
 * Open video in dialog box
 */
import Modal from "./modal"

interface OwnProps{
  link : string
  onClose : Function
  title : string
}

function ModalVideo(props:OwnProps) {
  return (
    <Modal onClose={props.onClose}
      title={props.title}
      isCloseButtonVisible
      isLarge>
      <iframe width="672" height="378" 
        className="onboarding-video-iframe"
        src={"https://www.youtube.com/embed/" + props.link + "?modestbranding=1"}
        title="YouTube video player" 
        frameBorder="0" 
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
        allowFullScreen>
      </iframe>
    </Modal>
  )
}

export default ModalVideo