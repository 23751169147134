/**
 * SUBSCRIPTION.ACTIONS
 */

import {
  Subscription,
  SUBSCRIPTION_ADD,
  SUBSCRIPTION_EDIT,
  SUBSCRIPTION_GET,
  SUBSCRIPTION_INIT,
  SUBSCRIPTION_REMOVE,
  SubscriptionStatus
} from "./subscription.types"

//REDUX

export const subscriptionAdd = (accountId: string) => ({
  type: SUBSCRIPTION_ADD,
  payload: {
    accountId
  }
})

export const subscriptionRemove = (id: string) => ({
  type: SUBSCRIPTION_REMOVE,
  payload: {
    id
  }
})

export const subscriptionGet = (subscriptions: Subscription[]) => ({
  type: SUBSCRIPTION_GET,
  payload: {
    subscriptions
  }
})

export const subscriptionInit = () => ({
  type: SUBSCRIPTION_INIT
})

export const subscriptionEdit = (id: string, key: string, value: any) => ({
  type: SUBSCRIPTION_EDIT,
  payload: {
    id,
    key,
    value
  }
})

//API

export const subscriptionDestroy = (id: string) => ({
  type: "API",
  payload: {
    method: "DELETE",
    url: "/supervisor/subscription/" + id
  }
})

export const subscriptionFetch = () => ({
  type: "API",
  payload: {
    method: "GET",
    url: "/subscriptions"
  }
})

export const subscriptionFetchAll_AsSupervisor = (
  statusPing: SubscriptionStatus[],
  statusCard: SubscriptionStatus,
  orderColumn: string,
  orderDirection: string
) => ({
  type: "API",
  payload: {
    method: "GET",
    url: "/supervisor/subscriptions",
    data: {
      statusPing,
      statusCard,
      orderColumn,
      orderDirection
    }
  }
})

export const subscriptionSend_AsSupervisor = (subscription: Subscription, emailCode: string) => ({
  type: "API",
  payload: {
    method: "POST",
    url: "/supervisor/subscription/send",
    data: {
      subscription,
      emailCode
    }
  }
})

export const subscriptionUpdate_AsSupervisor = (subscription: Subscription) => ({
  type: "API",
  payload: {
    method: "POST",
    url: "/supervisor/subscription",
    data: {
      subscription
    }
  }
})
