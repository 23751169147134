/** 
 * DUPLICATE-TEMPLATE.MODAL
 * Duplicate the template
 */
import Modal from "@/components/modal"
import { useState } from "react"
import { connect } from "react-redux"

import { withTranslation, WithTranslation } from "react-i18next"
import TextInput from "@/components/text-input"
import { store } from "@/index"
import { STATUS_SAVE_ERROR, STATUS_SAVING } from "@/redux/_status.types"
import { templateDuplicate_AsSupervisor, templateDuplicate, templateStatus } from "@/redux/template.actions";
import { TemplateState } from "@/redux/template.types";
import { Session } from "@/redux/_session.types";
import { topicFetchAndGet, topicSave } from "@/redux/topic.actions";
import LoadingModal from "./loading.modal";

interface StateProps extends WithTranslation{
  _session : Session
  template : TemplateState
}

interface OwnProps{
  onClose : Function
}

type Props = StateProps & OwnProps

function DuplicateTemplateModal(props:Props) {
  const { t } = props

  //Name of the template
  //if from idtree add name of the account
  //else add suffix "copy"
  const [templateName, setTemplateName] = useState(props.template.active.getDuplicateName(props._session.accountName, false))

  //Close modal
  function close(){
    props.onClose()
  }

  //Save template if user is connected
  async function next(){

    //Cancel current changes
    store.dispatch(topicSave())

    //Search the function (depends if the user is connected as supervisor)
    const duplicateFunction = props._session.interfaceType === "SUPERVISOR" ? templateDuplicate_AsSupervisor : templateDuplicate
    store.dispatch(templateStatus(STATUS_SAVING))
    const response:any = await store.dispatch(duplicateFunction(props.template.active.id, templateName))
    
    if (response.error){
      store.dispatch(templateStatus(STATUS_SAVE_ERROR))
    }else {
      store.dispatch(topicFetchAndGet(response))
    }

  }

  return (
    <Modal isCloseButtonVisible
      onClose={close}
      onNext={next}
      title={t("template_duplicate")}>

      { props.template.status === STATUS_SAVING &&
      <LoadingModal/>
      }

      <TextInput autoFocus
        isFullWidth
        onChange={(e) => setTemplateName(e.value)}
        title={t("template_name_new")}
        value={templateName}
      />

      <div className="height-20"/>

    </Modal>
  )

}

const mapStateToProps = state => ({
  _session : state._session,
  template : state.template
})

export default connect(mapStateToProps)(withTranslation()(DuplicateTemplateModal))