/** 
 * QUESTION-AXIS.HELP
 * Info about axis
 */
import HelpModal from "@/components/modal-help"
import { withTranslation, WithTranslation } from "react-i18next"

interface OwnProps extends WithTranslation{
  onClose : Function
}

function QuestionAxisHelp(props:OwnProps) {
  const { t } = props

  return (
    <HelpModal onClose={props.onClose}
      title={t("axis")}>
      <p>{t("axis_help")}</p>
      <p>{t("axis_help_attributes")}</p>
    </HelpModal>
  )
}

export default withTranslation()(QuestionAxisHelp)