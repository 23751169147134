/**
 * REPORT-LIST-SUPERVISOR.WIDGET
 * List of report for supervisor
 */

import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Survey } from "@/redux/survey.types";
import { useEffect, useState } from "react";
import { store } from "@/index";
import { reportActivate, reportEditAndUpdateDefault, reportFetch_AsSupervisor, reportGet } from "@/redux/report.actions";
import { Report, ReportState } from "@/redux/report.types";
import CardButton from "@/components/card-button";
import { faClipboard, faEdit, faStar, faTrash } from "@fortawesome/free-solid-svg-icons";
import { surveyActivate, surveyGet } from "@/redux/survey.actions";
import { STATUS_LOADED, STATUS_LOADING } from "@/redux/_status.types";
import PageLoader from "@/components/page-loader";
import ReportAddModal from "@/modals/report-add.modal";
import ReportDeleteModal from "@/modals/report-delete.modal";
import PageHeader from "@/components/page-header";
import Button from "@/components/button";
import ReportGetDataModal from "@/modals/report-get-data.modal";
import { NavigateFunction, useNavigate } from "react-router-dom";

interface StateProps{
  report: ReportState
}

type Props = StateProps & WithTranslation

const MODAL_ADD:string = "MODAL_ADD"
const MODAL_DELETE:string = "MODAL_DELETE"
const MODAL_GET_REPORT_DATA:string = "MODAL_GET_REPORT_DATA"

function ReportListSupervisorWidget(props:Props){
  const { t } = props
  const navigate:NavigateFunction = useNavigate()

  const [currentModal, setCurrentModal] = useState<string | null>(null)

  const [isLoading, setIsLoading] = useState(true)

  //Load all template reports
  useEffect(() => {

    //Fetch data from server
    async function loadData(){

      //Fetch reports
      setIsLoading(true)
      const reports: any = await store.dispatch(reportFetch_AsSupervisor())

      //Update store
      store.dispatch(surveyGet([], [], false))
      store.dispatch(reportGet(reports.error ? [] : reports))

      //End of loading
      setIsLoading(false)
      
    }

    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  //Click on report to access to the edit report page
  //Open modal that fetch all the data
  function click(report: Report){
    store.dispatch(reportActivate(report))
    store.dispatch(surveyActivate(new Survey()))
    setCurrentModal(MODAL_GET_REPORT_DATA)
  }

  //Click on the report
  function clickAction(report: Report, action: string){
    switch(action){
      case "ACTION_DELETE":
        store.dispatch(reportActivate(report))
        return setCurrentModal(MODAL_DELETE)
      case "ACTION_EDIT":
        return click(report)
      case "ACTION_EDIT_DEFAULT":
        return store.dispatch(reportEditAndUpdateDefault(report.id))
      default:
        console.log("action_not_found")
    }

  }

  return(
    <PageLoader status={isLoading ? STATUS_LOADING : STATUS_LOADED}>
      
      { currentModal === MODAL_ADD &&
      <ReportAddModal onClose={() => setCurrentModal(null)} onNext={() => window.location.reload()}/>
      }

      { currentModal === MODAL_DELETE &&
      <ReportDeleteModal onClose={() => setCurrentModal(null)}/>
      }

      { currentModal === MODAL_GET_REPORT_DATA &&
      <ReportGetDataModal onClose={() => navigate("/report/edit")}/>
      }

      <PageHeader title="Modèles de rapport"
        subtitle="Modifiez les modèles de rapports qui seront disponibles pour les clients.">
        <Button onClick={() => setCurrentModal(MODAL_ADD)}
          className="primary">
          {t("report_edit")}
        </Button>
      </PageHeader>

      <div className="flex flex-wrap">
        {props.report.list.map((x: Report) =>
        <CardButton
          key={x.id}
          icon={faClipboard}
          isActive={x.default}
          title={x.name}
          subtitle={x.default ? "Modèle par défaut" : undefined}
          onClick={() => click(x)}
          actions={[
            { id : "ACTION_DELETE", label : t("utils_delete"), icon : faTrash },
            { id : "ACTION_EDIT", label : t("utils_edit"), icon : faEdit },
            { id : "ACTION_EDIT_DEFAULT", label : "Modèle par défaut", icon : faStar }
          ]}
          onAction={(actionId: string) => clickAction(x, actionId)}
        />
        )}
      </div>

    </PageLoader>
  )
}

const mapStateToProps = (state) => ({
  report: state.report
})

export default connect(mapStateToProps)(withTranslation()(ReportListSupervisorWidget))