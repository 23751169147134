/** 
 * ACCOUNT-COLORS.WIDGET
 * Customize account colors as superadmin
 */

import { connect } from "react-redux"
import { withTranslation, WithTranslation } from "react-i18next";
import { SESSION_COLORS } from "@/redux/_session.types";
import ColorPickerWidget from "@/widgets/color-picker.widget";
import { useState } from "react";
import { AccountColors, AccountOptions, AccountState } from "@/redux/account.types";
import ColorsPreviewWidget from "./colors-preview.widget";
import LogoPickerWidget from "./logo-picker.widget";
import ImagePicker from "@/components/image-picker";
import Link from "@/components/link";
import Space from "@/components/space";

interface StateProps extends WithTranslation{ 
  account : AccountState
}

interface OwnProps{
  onEdit : Function
}

type Props = StateProps & OwnProps

function AccountColorsWidget(props:Props) {
  const { t } = props

  //Is icon by default
  //If true select from a list, else there is a custom image imported
  const [defaultIcon, setDefaultIcon] = useState<boolean>(props.account.active.options.image ? false : true)

  //Edit color
  function editAccountColors(key:string, value:any){
    const accountColors:AccountColors = props.account.active.colors
    accountColors[key] = value
    props.onEdit("colors", accountColors)
  }

  //Edit image options when an image is uploaded or removed
  function editAccountImage(value:any){
    const accountOptions:AccountOptions = props.account.active.options
    accountOptions.image = value
    props.onEdit("options", accountOptions)
  }


  return (
    <div>

      <div className="height-40"/>

      <div className="flex">

        <div style={{ width : "200px" }}
          className="flex flex-dcol">

          <Space/>

          { defaultIcon ?
          <div>

            <LogoPickerWidget />
            
            <div style={{ textAlign : "center" }}>
              <div className="grey-t"
                style={{ margin : "6px" }}>
                {t("utils_or")}
              </div>
              <Link isWithoutMargin
                onClick={()=> { setDefaultIcon(false) }}>
                {t("utils_import_custom_image")}
              </Link>
            </div>

          </div>
          :
          <div>

            <ImagePicker backgroundColor={props.account.active.colors.brandPrimary}
              square
              id={props.account.active.id}
              imageId={props.account.active.options.image ? props.account.active.id : null }
              model="Accounts"
              onDelete={()=>editAccountImage(false)}
              onSave={()=>editAccountImage(true)}
            />

            <div style={{ textAlign : "center" }}>
              <div className="grey-t"
                style={{ margin : "6px" }}>
                {t("utils_or")}
              </div>
              <Link isWithoutMargin onClick={() => { 
                  setDefaultIcon(true);
                  editAccountImage(false)
                }}>
                {t("utils_select_icon")}
              </Link>
            </div>
            
          </div>
          
          }

          <Space/>

        </div>

        <div className="width-40"/>

        <ColorsPreviewWidget account={props.account.active} />

      </div>

      <div className="height-40"/>

      <div className="flex">
        
        <div className="flex1">
          <ColorPickerWidget colors={SESSION_COLORS}
            isCustom
            title={t("account_color_primary")}
            selectedColor={props.account.active.colors.brandPrimary}
            onSelect={(color:string) => editAccountColors('brandPrimary', color)}
          />
        </div>

        <div className="flex1">
          <ColorPickerWidget colors={SESSION_COLORS}
            isCustom
            title={t("account_color_secondary")}
            selectedColor={props.account.active.colors.brandSecondary}
            onSelect={(color:string) => editAccountColors('brandSecondary', color)}
          />
        </div>

        <div className="flex1"/>

      </div>

      <div className="flex">
        
        <div className="flex1">
          <ColorPickerWidget colors={SESSION_COLORS}
            isCustom
            title={t("account_color_text")}
            selectedColor={props.account.active.colors.brandText}
            onSelect={(color:string) => editAccountColors('brandText', color)}
          />
        </div>

        <div className="flex1">
          <ColorPickerWidget colors={SESSION_COLORS}
            isCustom
            title={t("account_color_cta")}
            selectedColor={props.account.active.colors.cta}
            onSelect={(color:string) => editAccountColors('cta', color)}
          />
        </div>

        <div className="flex1">
          <ColorPickerWidget colors={SESSION_COLORS}
            isAlignedLeft
            isCustom
            title={t("account_color_active")}
            selectedColor={props.account.active.colors.active}
            onSelect={(color:string) => editAccountColors('active', color)}
          />
        </div>

      </div>
    
    </div>
  )

}

const mapStateToProps = state => ({
  account : state.account
})

export default connect(mapStateToProps)(withTranslation()(AccountColorsWidget))