/**
 * LOGO-PICKER.WIDGET
 * Select default icon for account
*/

import ContextMenu from "@/components/context-menu"
import { store } from "@/index"
import { useState } from "react"
import { withTranslation, WithTranslation } from "react-i18next"
import { connect } from "react-redux"
import Link from "@/components/link"
import { accountEdit } from "@/redux/account.actions"
import { AccountOptions, AccountState } from "@/redux/account.types"
import { DEFAULT_ICONS, getIconUrl } from "@/utils/default-icons.utils"
import Space from "@/components/space"
import Button from "@/components/button"

interface StateProps extends WithTranslation{
  account : AccountState
}

type Props = StateProps

function LogoPickerWidget(props:Props){
  const { t } = props

  const [isOpen, setIsOpen] = useState<boolean>(false)

  function selectIcon(icon : string){
    const options : AccountOptions = props.account.active.options
    if(options.defaultIcon !== icon){
      options.defaultIcon = icon
      store.dispatch(accountEdit("options", options))
    }
    setIsOpen(false)
  }

  return(
    <div style={{ margin: "0px 30px", textAlign: "center" }}>

      <div className="rel" style={{ zIndex : 1 }}>

        {isOpen &&
        <ContextMenu onClose={() =>setIsOpen(false)}>

          <div className="color-picker-context-title">
            <b>{t("utils_select_icon_cta")}</b>
          </div>

          <div className="flex flex-wrap"
            style={{ margin : '16px' }}>
            {DEFAULT_ICONS.filter((x:any)=>x.visible).map((x:any)=>
            <img className="flex1 page-navigation-circle-img"
              key={x.id}
              alt={x.id}
              src={getIconUrl(x.category+"/"+x.id)}
              onClick={()=>selectIcon(x.category+"/"+x.id)}
              style={{ 
                border : "4px solid " + (props.account.active.options.defaultIcon===x.category + "/"+ x.id ? props.account.active.colors.active : " white"),
                backgroundColor : props.account.active.colors.brandPrimary
              }}
            />
            )}
          </div>

          <div className="flex"
            style={{ margin : '12px 26px 4px 0px' }}>
            <Space/>
            <Button onClick={() => setIsOpen(false)}>
              {t("utils_close")}
            </Button>
          </div>

        </ContextMenu>
        }
      </div>

      <label htmlFor="avatar">
        <img alt={props.account.active.options.defaultIcon} 
          onClick={()=>setIsOpen(true)}
          style={{ backgroundColor : props.account.active.colors.brandPrimary }}
          className="import-img-circle _hover" 
          id="import-img-container"
          src={getIconUrl(props.account.active.options.defaultIcon)}>
        </img>
      </label>

      <div className="grey-t" onClick={()=>setIsOpen(true)}>
        <label htmlFor="avatar">
          <Link isWithoutMargin>
            {t("utils_select_icon")}
          </Link>
        </label>
      </div>

    </div>
  )
}

const mapStateToProps = state => ({
  account : state.account
})

export default connect(mapStateToProps)(withTranslation()(LogoPickerWidget))
