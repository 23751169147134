/**
 * REPORT-LIST.ROUTE
 * Home page for the report module
 */

import NavSupervisor from "@/nav/nav.supervisor";
import ReportListSupervisorWidget from "@/widgets/report-list-supervisor.widget";

function SupervisorReportsRoute(){
  return(
    <NavSupervisor>
      <ReportListSupervisorWidget/>
    </NavSupervisor>
  )
}
export default SupervisorReportsRoute