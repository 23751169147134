/** 
 * UPGRADE.MODAL
 * Infos for upgrade account version
 */
import Modal from "@/components/modal"
import UpgradeWidget from "@/widgets/upgrade.widget"

interface OwnProps{
  feature : string
  isAppLevel? : boolean
  onClose : Function
}

function UpgradeModal(props:OwnProps) {
  return (
    <Modal isCloseButtonVisible
      onClose={() => props.onClose()}>
      <UpgradeWidget feature={props.feature} withNoImages isAppLevel={props.isAppLevel}/>
    </Modal>
  )

}

export default UpgradeModal