/** 
 * REPORT-SCREENSHOT-GLOBAL-RESULTS.WIDGET.TSX
 * Preview of the slide
 */
import { connect } from "react-redux";
import { Session } from "@/redux/_session.types";
import { TopicState } from "@/redux/topic.types";
import { Survey } from "@/redux/survey.types";
import DashboardResultsWidget from "./dashboard-results.widget";
import { WithTranslation, withTranslation } from "react-i18next";
import { FilterState } from "@/redux/filter.types"; 
import { useEffect, useState } from "react";
import { ReportState } from "@/redux/report.types";
import { pageInitDashboardFilters } from "@/redux/page.actions";
import { Page, PageState } from "@/redux/page.types";

interface StateProps extends WithTranslation{
  _session : Session
  filter : FilterState
  report : ReportState
  topic : TopicState
  page : PageState
}

interface OwnProps{
  currentPage:Page
  currentSurvey:Survey
  customTitle:boolean
}

type Props = StateProps & OwnProps

function ReportScreenshotGlobalResultsWidget(props:Props) {
  const { t } = props

  const [dashboardFilters, setDashboardFilters] = useState(pageInitDashboardFilters(props.currentPage.options.populations))

  useEffect(() => {
    if (props.currentPage.id === props.page.active.id){
      setDashboardFilters(pageInitDashboardFilters(props.page.active.options.populations))
    }
  }, [
    props.page.active.options.populations,
    props.currentPage.id,
    props.page.active.id
  ])

  return (
    <div style={{
        transform : "scale(0.75)",
        transformOrigin : "top left"
       }}>

      { props.customTitle &&
      <div style={{
          textAlign : "center",
          width : 400,
          fontSize : 26,
          marginTop : -8
        }}>
        {t("page_type_screenshot_global_results_title", {
          name : props.currentPage.options.populations.length ? props.currentPage.options.populations.map(x => x.name) : props.report.active.tagsData.population_name
        })}
      </div>
      }

      <DashboardResultsWidget 
        exportName=""
        currentAxes={[]}
        currentSurvey={props.currentSurvey}
        currentTopics={props.topic.list}
        isPreview
        dashboardFilters={dashboardFilters}
      />

    </div>
  )
  
}
const mapStateToProps = state => ({
  _session: state._session,
  topic : state.topic,
  filter : state.filter,
  report : state.report,
  page : state.page
})

export default connect(mapStateToProps)(withTranslation()(ReportScreenshotGlobalResultsWidget))

