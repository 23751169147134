/** 
 * LIST-CELL
 * Cell component for a list item
 */

import { faCaretDown, faCaretUp, faInfoCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

interface OwnProps{
  children? : any
  text? : string
  onHelp? : Function
  width? : number
  onClick? : Function
  order? : "asc" | "desc" | null
}

function ListCell(props:OwnProps) {

  function click(){
    if(props.onClick){
      props.onClick()
    }
  }

  //Click on info info
  function clickHelp(e:any){
    e.stopPropagation()
    if (props.onHelp){
      props.onHelp()
    }
  }

  function getClassName(){
    let className = "list-cell flex"
    if (props.onClick) className += " _hover"
    return className
  }
  
  //Width
  //If not specified set min width at 38px
  function getStyle(){
    const width = props.width ? props.width : 38 
    return {
      minWidth : width + 'px',
      maxWidth : width + 'px'
    }
  }

  return (
    <div className={getClassName()}
      style={getStyle()}>

      { props.text &&
      <span className="list-cell-title flex" onClick={click}>

        <div className="list-cell-text">

          { props.text }

        </div>

        <div className="flex1">

          { props.order &&
          <FontAwesomeIcon icon={props.order==="desc"?faCaretDown:faCaretUp} />
          }

          { props.onHelp &&
          <FontAwesomeIcon icon={faInfoCircle}
            onClick={(e:any)=>clickHelp(e)}
          />
          }

        </div>

      </span>
      }

      { props.children}

    </div>
  )
}

export default ListCell