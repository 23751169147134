import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Space from "./space";
import { faCalendar, faEnvelope, faLink, faPhone } from "@fortawesome/free-solid-svg-icons";
import Link from "@/components/link"
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Session } from "@/redux/_session.types";

interface StateProps extends WithTranslation{
  _session : Session
}

const URL_REDIRECTION: string = "https://www.id-tree.com"

function Contact(props: StateProps){
  const { t } = props

  return(
    <div>
      <div className="flex">
        <img src={props._session.salesContact.imageUrl} 
          width="100" height="100" alt="contact"
        />
        <div className="width-20"/>
        <div className="flex flex-dcol">
          <Space/>
          <div className="onboarding-end-item"><b>{props._session.salesContact.name}</b></div>
          <div className="onboarding-end-item">{props._session.salesContact.description}</div>
          <Space/>
        </div>
      </div>

      <div className="height-20"/>

      <div className="grey-t">
        <div className="onboarding-end-item flex">
          <FontAwesomeIcon icon={faPhone}/>
          {props._session.salesContact.phone}
        </div>
        <div className="onboarding-end-item flex">
          <Link isWithoutMargin
            icon={faEnvelope}
            onClick={() => window.open("mailto:" + props._session.salesContact.email, "_blank")}>
            {props._session.salesContact.email}
          </Link>
        </div>
        <div className="onboarding-end-item flex">
          <Link isWithoutMargin
            icon={faCalendar}
            onClick={() => window.open(props._session.salesContact.calendly, "_blank")}>
            {t("contact_meet")}
          </Link>
        </div>
        <div className="onboarding-end-item flex">
          <Link isWithoutMargin
            icon={faLink}
            onClick={() => window.open(URL_REDIRECTION, "_blank")}>
            {URL_REDIRECTION}
          </Link>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state : any) => ({
  _session : state._session
})

export default connect(mapStateToProps)(withTranslation()(Contact))