/***
 * PROJECT-IMPORT.WIDGET
 * Import user into the project wizard
 */

import { connect } from "react-redux";
import { WithTranslation, withTranslation } from "react-i18next"
import { useState } from "react";
import { Session } from "@/redux/_session.types";
import { UserState } from "@/redux/user.types";
import ImportUsersModal from "@/modals/import-users.modal";
import Link from "@/components/link";
import AdmImportFileWidget from "./adm-import-file.widget";
import AdmImportManualWidget from "./adm-import-manual.widget";
import AdmImportMappingWidget from "./adm-import-mapping.widget";
import ConfirmButtons from "@/components/confirm-buttons";
import ListItem from "@/components/list-item";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import DeleteUsersModal from "@/modals/delete-users.modal";
import ProjectStepErrorsWidget from "./project-step-errors.widget";
import { ProjectState } from "@/redux/project.types";
import AdmUsersWidget from "./adm-users.widget";

interface StateProps extends WithTranslation{
  _session : Session
  project : ProjectState
  user : UserState
}

interface OwnProps{
  onEdit : Function
  onNext : Function
}

type Props = StateProps & OwnProps

//Modals
const MODAL_DELETE_USERS:string = "MODAL_DELETE_USERS" //Delete all users
const MODAL_IMPORT_TYPE:string = "MODAL_IMPORT_TYPE" //Options to import users

//Pages
const PAGE_INTRO:string = "PAGE_INTRO" //Current page
const PAGE_EMAIL:string = "PAGE_EMAIL" //Create user one by one
const PAGE_UPLOAD:string = "PAGE_UPLOAD" //Upload the file
const PAGE_MAPPING:string = "PAGE_MAPPING" //Access to the mapping

function ProjectImportWidget(props:Props){
  const { t } = props

  //Dialog to display
  const [currentModal, setCurrentModal] = useState<string | null>(null)

  //If there is an integration
  const [currentPage, setCurrentPage] = useState(PAGE_INTRO)

  function cancel(){
    select()
    next(false, false)
  }

  function next(isVisited, isConfirmed){
    props.onNext(isVisited, isConfirmed)
  }

  function select(){
    props.onEdit()
  }

  //Select import mode
  function selectImportMode(id:string){
    setCurrentModal(null)
    switch(id){
      case 'emails': return setCurrentPage(PAGE_EMAIL)
      case 'upload': return setCurrentPage(PAGE_UPLOAD)
      default: return null
    }
  }


  //Update value after update of the users
  function update(){
    setCurrentModal(null)
    setCurrentPage(PAGE_INTRO)
    select()
  }

  return (
    <div>

      { currentModal === MODAL_IMPORT_TYPE &&
      <ImportUsersModal onClose={() => setCurrentModal(null)}
        onNext={update}
        onSelect={(id) => selectImportMode(id)}
      />
      }
      
      { currentModal === MODAL_DELETE_USERS &&
      <DeleteUsersModal
        onClose={() => setCurrentModal(null)}
        onDeleteSuccess={() => cancel()}  
      />
      }


      <ListItem>
        <div className="setup-card-help flex1">
          { currentPage !== PAGE_INTRO &&
          <Link isWithoutMargin
            icon={faArrowLeft}
            onClick={() => setCurrentPage(currentPage === PAGE_MAPPING ? PAGE_UPLOAD : PAGE_INTRO)}>
            {t("utils_back")}
          </Link>
          }
          <p>
            {t("user_import_help")}
          </p>

          { currentPage === PAGE_INTRO &&
          <p>
            <b>
              {props.user.count <= 1 ? t("project_error_import_no_user") : t("user_imported", {
                count : props.user.count,
                s : props.user.count > 1 ? "s" : ""
              })}
            </b>
          </p>
          }

        </div>
      </ListItem>

      { currentPage === PAGE_INTRO &&
      <div>

        { //If user are already imported do not show big cta
        props.user.count > 1
        ?
        <AdmUsersWidget cta={t("user_import")}
          onClickCta={() => setCurrentModal(MODAL_IMPORT_TYPE)}
          onEditUser={update}
        />
        :
        <AdmUsersWidget isCtaPrimary
          cta={t("user_import")} 
          onClickCta={() => setCurrentModal(MODAL_IMPORT_TYPE)}
          onEditUser={update}
        />
        }

        <ProjectStepErrorsWidget alert={props.project.active.alerts.import} step="import"/>
        
        <ConfirmButtons isLocked={props.user.count <= 1}
          onCancel={() => setCurrentModal(MODAL_DELETE_USERS)}
          onConfirm={() => next(true, true)}
          onConfirmLater={() => next(true, false)}
        />

      </div>
      }

      { currentPage === PAGE_EMAIL &&
      <AdmImportManualWidget onNext={update}/>
      }

      { currentPage === PAGE_UPLOAD &&
      <AdmImportFileWidget onNext={() => setCurrentPage(PAGE_MAPPING)}/>
      }
      
      { currentPage === PAGE_MAPPING &&
      <AdmImportMappingWidget onNext={update}/>
      }
      
    </div>
  )
}

const mapStateToProps = state => ({
  _session : state._session,
  project : state.project,
  user : state.user
})

export default connect(mapStateToProps)(withTranslation()(ProjectImportWidget))
