/** 
 * SURVEY-TEMPLATES-PUBLIC
 * List of all templates for IDTree
 */

import { connect } from "react-redux";
import { WithTranslation, withTranslation } from 'react-i18next'
import { Session } from "@/redux/_session.types";
import { TEMPLATES_TAGS, Template } from "@/redux/template.types";
import { useEffect, useState } from "react";
import { store } from "@/index";
import { templateFetchPublic } from "@/redux/template.actions";
import PageLoader from "@/components/page-loader";
import { STATUS_LOADED, STATUS_LOADING } from "@/redux/_status.types";

interface Props extends WithTranslation{
  _session : Session
}

function SurveyTemplatesPublicRoute(props: Props){
  const { t } = props

  const [isLoading, setIsLoading] = useState(true)

  const [language] = useState("fr")

  const [templates, setTemplates] = useState<Template[]>([])

  useEffect(() => {
    load()

    async function load(){

      const response:any = await store.dispatch(templateFetchPublic(language))
      setTemplates(response.error ? [] : response.map(x => new Template(x)))
      setIsLoading(false)

    }

  }, [language])

  return(
    <div className="white"
      style={{ 
        width : "100%", 
        height : 3000 
      }}>
      <PageLoader status={isLoading ? STATUS_LOADING : STATUS_LOADED}>
        <div style={{
            overflowY : "auto"
          }}>

          { TEMPLATES_TAGS.map(tag => 
          <div key={tag}>

            <h3>
              {t("templates")} {t("template_tag_" + tag)}
            </h3>

            <ul>
              { templates.filter(x => x.options.tag === tag && x.fromSupervisor).map(template =>
              <li key={template.id}
                style={{ paddingBottom : 12 }}>
                <a href={window.location.origin.concat("/login/public?trial_source=guides_all_questionnaires")}
                  rel="noreferrer"
                  target="_blank">
                  <u>{template.label}</u>
                </a>
                {template.description[language] && 
                <div className="grey-t">
                  { template.description[language] }
                </div>
                }
              </li>
              )
              }
            </ul>

          </div>
          )
          }

        </div>
      </PageLoader>
    </div>
  )

}

const mapStateToProps = state => ({
  _session : state._session
})

export default connect(mapStateToProps)(withTranslation()(SurveyTemplatesPublicRoute))