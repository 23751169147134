/**
 * USER-FILTER-PICTURE-WIDGET
 * Illustration that explains filter concept
 */

function UserFilterPictureWidget() {

  return (
    <div className="rel">

      {/*** HORIZONTAL FILTERS ***/}

      <div className="abs medgrey user-filter-picture" 
        style={{ 
          width : 46,
          top : 6,
          left : 6
        }}
      />

      <div className="abs medgrey user-filter-picture" 
        style={{ 
          width : 40,
          top : 6,
          left : 74
        }}
      />

      <div className="abs medgrey user-filter-picture" 
        style={{ 
          width : 26,
          top : 6,
          left : 138
        }}
      />

      <div className="abs medgrey user-filter-picture" 
        style={{ 
          width : 66,
          top : 6,
          left : 188
        }}
      />

      {/*** VERTICAL FILTERS ***/}

      <div className="abs medgrey user-filter-picture" 
        style={{ 
          width : 115,
          top : 52,
          left : 52
        }}
      />

      <div className="abs orange user-filter-picture" 
        style={{ 
          width : 75,
          top : 81,
          left : 52
        }}
      />
      
      <div className="abs medgrey user-filter-picture" 
        style={{ 
          width : 66,
          top : 110,
          left : 52
        }}
      />

      <div className="abs medgrey user-filter-picture" 
        style={{ 
          width : 90,
          top : 138,
          left : 52
        }}
      />
      
      <img alt="filters" src={require("@/assets/filters.png")}
        width="380"
      />

    </div>
  )
  
}

export default UserFilterPictureWidget