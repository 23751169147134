/** 
 * PROJECT.REDUCERS
 */

import { Project, ProjectAlerts, ProjectAxisRule, ProjectMessageOptions, ProjectQrcodeOptions, ProjectState, PROJECT_ACTIVATE, PROJECT_EDIT, PROJECT_EDIT_ALERTS, PROJECT_EDIT_AXIS_RULE, PROJECT_EDIT_MESSAGE_OPTIONS, PROJECT_EDIT_QRCODE_OPTIONS, PROJECT_GET, PROJECT_INIT, PROJECT_REMOVE, PROJECT_STATUS } from "./project.types"
import { STATUS_LOADED } from "./_status.types"

let active: Project
let activeAxisRule: ProjectAxisRule | undefined
let alerts: ProjectAlerts
let messageOptions: ProjectMessageOptions
let qrcodeOptions: ProjectQrcodeOptions

const initialState: ProjectState = {
  active : new Project(),
  list : [],
  status : STATUS_LOADED
}

export default function projectReducer(
  state = initialState,
  action: any
): ProjectState {
  switch (action.type) {

    case PROJECT_ACTIVATE:
      return Object.assign({}, state, {
        active : new Project(action.payload.project)
      })

    case PROJECT_EDIT:
      active = Object.assign(state.active, { [action.payload.key] : action.payload.value })
      return Object.assign({}, state, { active })

    case PROJECT_EDIT_ALERTS:
      alerts = new ProjectAlerts(state.active.alerts)
      alerts[action.payload.key] = action.payload.value
      active = Object.assign(state.active, { alerts })
      return Object.assign({}, state, { active })

    //Edit axis rule for project
    //if no rule found create a new one
    //if no population are selected remove rule
    //else edit rule
    case PROJECT_EDIT_AXIS_RULE:
      active = new Project(state.active)
      activeAxisRule = active.segmentationByAxesRules.find(x => x.axisId === action.payload.axisId)

      if (!activeAxisRule){
        active.segmentationByAxesRules.push({ axisId : action.payload.axisId, populations : action.payload.populations })
      } else if (!action.payload.populations.length){
        active.segmentationByAxesRules = active.segmentationByAxesRules.filter(x => x.axisId !== action.payload.axisId)
      } else{
        active.segmentationByAxesRules = active.segmentationByAxesRules.filter(x => x.axisId === action.payload.axisId ? x.populations = action.payload.populations : x)
      }
    
      return Object.assign({}, state, { active })

    case PROJECT_EDIT_MESSAGE_OPTIONS:
      messageOptions = new ProjectMessageOptions(state.active.messageOptions)
      messageOptions[action.payload.key] = action.payload.value
      active = Object.assign(state.active, { messageOptions })
      return Object.assign({}, state, { active })
  
    case PROJECT_EDIT_QRCODE_OPTIONS:
      qrcodeOptions = new ProjectQrcodeOptions(state.active.qrcodeOptions)
      qrcodeOptions[action.payload.key] = action.payload.value
      active = Object.assign(state.active, { qrcodeOptions })
      return Object.assign({}, state, { active })

    case PROJECT_GET:
      return Object.assign({}, state, {
        list : action.payload.projects.map((x:any) => new Project(x))
      })

    case PROJECT_INIT:
      return Object.assign({}, state, {
        active : new Project(state.active),
        list : state.list.map((x:any) => new Project(x)),
      })
  
    case PROJECT_REMOVE:
      return Object.assign({}, state, { 
        list : state.list.filter(x => x.id !== action.payload.id)
      })

    //Update status
    case PROJECT_STATUS:
      return {
        ...state,
        status : action.payload.status
      }

    default:
      return state

  }

}
