/** 
 * HOME-WELCOME.MODAL
 * Introduction message after creating an account
 * Link to template or create first project
 */
import Modal from "@/components/modal"
import { connect } from "react-redux"
import { withTranslation, WithTranslation } from "react-i18next"
import { AccountOnboardingAction, AccountState } from "@/redux/account.types"
import Space from "@/components/space";
import { faClipboardList, faPaperPlane, faTimes, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import CardButton from "@/components/card-button";
import ProjectTestOnboardingModal from "./project-test-onboarding.modal";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { store } from "@/index";
import { accountUpdateOptions } from "@/redux/account.actions";
import { Session } from "@/redux/_session.types";
import { sessionEditAccountOptions } from "@/redux/_session.actions";

interface StateProps extends WithTranslation{
  _session : Session
  account : AccountState
}

interface OwnProps{
  onClose : Function
}

type Props = StateProps & OwnProps

interface WelcomeItem{
  id : AccountOnboardingAction
  title : string
  icon : IconDefinition
  isCta? : boolean
}

const MODAL_CREATE_PROJECT = "MODAL_CREATE_PROJECT"

function HomeWelcomeModal(props:Props) {
  const { t } = props
  const navigate: NavigateFunction = useNavigate()

  //Card end items
  const [welcomeItems] = useState<WelcomeItem[]>([
    {
      id : "project",
      title : t("home_welcome_project"),
      icon : faPaperPlane,
      isCta : true
    },
    {
      id : "templates",
      title : t("home_welcome_templates"),
      icon : faClipboardList
    },
    {
      id : "neither",
      title : t("home_welcome_neither"),
      icon : faTimes
    }
  ])

  //Modal to display
  const [currentModal, setCurrentModal] = useState<string | null>(null)

  //Select card
  function selectCard(cardId:AccountOnboardingAction){

    //Update option for the first time
    if (!props._session.accountOptions.onboardingAction){
      store.dispatch(sessionEditAccountOptions("onboardingAction", cardId))
      store.dispatch(accountUpdateOptions(Object.assign({}, props._session.accountOptions, { onboardingAction : cardId })))
    }

    //Switch according choice
    switch(cardId){
      case "templates":
        navigate("/survey/templates")
        return props.onClose()
      case "project":
        return setCurrentModal(MODAL_CREATE_PROJECT)
      case "neither":
        return props.onClose()
      default:
        return null
    }

  }

  return (
    <div className="home-welcome-modal">

      <Modal disableClickOutside>

        { currentModal === MODAL_CREATE_PROJECT &&
        <ProjectTestOnboardingModal onClose={() => setCurrentModal(null)} onNext={() => setCurrentModal(null)}/>
        }

        <div style={{ 
            textAlign : "center",
            marginTop : 32
          }}>
          <h1>
            {t("home_welcome_title")}
          </h1>
          <p>
            {t("home_welcome_subtitle")}
          </p>
        </div>

        <div className="flex rel">

          <div className="abs"
            style={{ 
              left : -6,
              top : -104
            }}>
            <img src={require("@/assets/assistant/owl_female.png")}
              alt={"owl assistant"}
              width={120}
              style={{ objectFit: "contain" }}
            />
          </div>


          <Space/>

          { welcomeItems.map(card =>
          <CardButton
            isCta={ card.isCta}
            key={card.id}
            icon={card.icon}
            title={card.title}
            onClick={() => selectCard(card.id)}>
          </CardButton>
          )}

          <Space/>

        </div>

      </Modal>

    </div>
  )

}

const mapStateToProps = state => ({
  _session : state._session,
  account : state.account
})

export default connect(mapStateToProps)(withTranslation()(HomeWelcomeModal))