/**
 * PROJECT-TEST.MODAL
 * Send a test to list of users
 */
import { useCallback, useEffect, useState } from "react";
import Modal from "@/components/modal";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { WithTranslation, withTranslation } from 'react-i18next';
import { store } from "@/index";
import { userCopy, userFetch, userFetchList, userGet, userRemove } from "@/redux/user.actions";
import { connect } from "react-redux";
import { Session } from "@/redux/_session.types";
import { ProjectState } from "@/redux/project.types";
import PageLoader from "@/components/page-loader";
import { STATUS_LOADED, STATUS_LOADING } from "@/redux/_status.types";
import { User, UserState } from "@/redux/user.types";
import Chip from "@/components/chip";
import Link from "@/components/link";
import SearchUser from "@/components/search-user";
import { projectEdit } from "@/redux/project.actions";
import { toast } from "react-toastify";
import AdmEditUserModal from "./adm-edit-user.modal";
import Dropdown from "@/components/dropdown";
import { EmailState } from "@/redux/email.types";
import { emailActivate } from "@/redux/email.actions";
  
interface StateProps extends WithTranslation{
  _session : Session
  email : EmailState
  project : ProjectState
  user : UserState
}

interface OwnProps{
  onClose : Function
  onNext : Function
}

type Props = StateProps & OwnProps

const MODAL_ADD_USER = "MODAL_ADD_USER"

function ProjectTestModal(props:Props) {
  const { t } = props

  //Display dialog box
  const [currentModal, setCurrentModal] = useState<string | null>(null)

  //Loading in process
  const [isLoading, setIsLoading] = useState(true)

  //Search user to add to the testers list
  const [isSearchActive, setIsSearchActive] = useState(false)

  //Load list of testers
  //If no user is found search for admins
  const loadUsersForTest = useCallback(async () => {

    //Fetch list
    const users:any = await store.dispatch(userFetchList(props.project.active.usersForTest.join(",")))

    if (users.error){
      store.dispatch(userGet([]))
    }else if (!users.length){
      const adminsResponse:any = await store.dispatch(userFetch("", null, null, true, "id,email,firstname,lastname"))
      const admins:User[] = adminsResponse.error ? [] : adminsResponse.filter((x: User) => x.role === "ADMIN")
      store.dispatch(userGet(admins))
    }else{
      store.dispatch(userGet(users))
    }

    setIsLoading(false)

  }, [props.project.active.usersForTest])

  //Load users at start
  useEffect(() => {
    loadUsersForTest()
  }, [loadUsersForTest])

  //Edit user list
  function editUsers(){
    store.dispatch(projectEdit("usersForTest", props.user.list.map(x => x.id)))
  }

  //Launch
  function launch(){
    if (props.user.list.length > 0){
      editUsers()
      props.onNext()
    }else if (props.user.list.length > props._session.accountOptions.ceilsTesters){
      toast(t("project_test_error_participants", { count : props._session.accountOptions.ceilsTesters }), { type : "error" })
    }else{
      toast(t("project_test_empty"), { type : "error" })
    }
  }

  //Close modal
  function close(){
    editUsers()
    props.onClose()
  }

  return (
    <Modal isCloseButtonVisible
      onNext={launch}
      onClose={() => close()}
      title={t("project_test")}>

      { currentModal === MODAL_ADD_USER &&
      <AdmEditUserModal onClose={() => setCurrentModal(null)}
        onNext={() => setCurrentModal(null)}
      />
      }

      <PageLoader status={isLoading ? STATUS_LOADING : STATUS_LOADED}>
        
        <div className="flex">

          <div className="flex2">

            { props.project.active.diffusionMode.email &&
            <Dropdown value={props.email.active.name}
              displayField="name"
              active={props.email.active.id}
              list={props.email.list}
              title={t("sending_email_survey_test")}
              onSelect={(e) => store.dispatch(emailActivate(e))}
              error={props.email.list.length === 0 ? true : false}
            />
            }

            <div className="height-40"/>

            <p>
              {t("project_test_users")}
            </p>
            
            <div className="flex flex-wrap">
              {props.user.list.map(user =>
              <Chip key={user.id}
                color={props._session.accountColors.active}
                isWithMargin
                onDelete={() => store.dispatch(userRemove(user.id))}>
                {user.username}
              </Chip>  
              )
              }
            </div>

            <Link icon={faPlus}
              onClick={() => setIsSearchActive(true)}>
              {t("project_test_add")}
            </Link>

          </div>

          <div className="width-60"/>

          <div style={{ width : '200px' }}>

            <div className="abs"
              style={{
                marginTop : '-98px',
                width : '240px'
              }}>
              { isSearchActive &&
              <SearchUser activeUsers={props.user.list}
                onClose={() => setIsSearchActive(false)}
                onSelect={(user) => store.dispatch(userCopy(user))}
                onAdd={() => setCurrentModal(MODAL_ADD_USER)}
              />
              }
            </div>

            <img src={require("@/assets/project-status/test.png")}
              alt="check" width="100%"
            />

          </div>

        </div>

        <div className="height-20"/>

      </PageLoader>

    </Modal>

  )
  
}

const mapStateToProps = state => ({
  _session : state._session,
  email : state.email,
  project : state.project,
  user : state.user
})

export default connect(mapStateToProps)(withTranslation()(ProjectTestModal))