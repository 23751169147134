/**
 * UPLOAD.HELP
 * Help user to format a file in order to import users
 */
import HelpModal from "@/components/modal-help"
import { WithTranslation, withTranslation } from 'react-i18next';

interface OwnProps{
  onClose: Function
}

type Props = OwnProps & WithTranslation

function UploadHelp(props: Props) {
  const { t } = props

  return (
    <HelpModal
      onClose={props.onClose}
      title={t("adm_upload_title")}>
    
      <p><b>{t("upload_help_title_1")}</b></p>
      <p>{t("upload_help_text_1")}</p>

      <p><b>{t("upload_help_title_2")}</b></p>
      <p>{t("upload_help_text_2")}</p>
      <p>{t("upload_help_text_3")}</p>
      
    </HelpModal>
  );
}

export default withTranslation()(UploadHelp);