/**
 * GET-BILLINGS
 * Get the array of the history of transactions
 * Mix of subscriptions and packs of invitation
 */

import { store } from "@/index"
import { sortBy } from "lodash"
import { Pack } from "@/redux/pack.types"
import { Subscription } from "@/redux/subscription.types"
import { t } from "@/translate/t"

const getBillings: Function = (): Subscription[] => {
  const billings: Subscription[] = []
    
  store.getState().subscription.list.forEach((subscription: Subscription) => {
    billings.push(new Subscription(subscription))
  })

  store.getState().pack.list.forEach((pack: Pack) => {
    billings.push(new Subscription({
      id: pack.id,
      date: pack.date,
      dateStart: pack.date,
      dateEnd: null,
      text: t("pack_invitations", { count : pack.count }),
      price: pack.price,
      paid: true,
      bill: false
    }))
  })

  return sortBy(billings, 'dateStart').reverse()

}

export default getBillings