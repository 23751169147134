/**
 * GET-ARCHIVE-PROPOSITION
 * Match proposition from template with archive database 
 */

import { store } from "@/index"
import { sum } from "lodash"
import { Proposition } from "@/redux/proposition.types"
import { Question } from "@/redux/question.types"
import { Topic } from "@/redux/topic.types"

const getArchiveProposition = (topic:Topic, question:Question, proposition:Proposition, archiveQuestions, archivePropositions) => {
  proposition.countAnswered = null

  const archiveProposition =  archivePropositions.find(x => 
    x.aid === (store.getState().survey.active.randomData ? proposition.id : proposition.aid) &&
    x.fkQuestion === (store.getState().survey.active.randomData ? question.id : question.aid) &&
    x.fkTopic === (store.getState().survey.active.randomData ? topic.id : topic.aid)
  )

  if (archiveProposition){
    proposition.count = archiveProposition.count

    if (question.primaryId){
      const primaryQuestion = topic.Questions.find(x => x.id === question.primaryId)
      if (
        primaryQuestion && 
        primaryQuestion.type === "scale"
      )
        {
        const primaryArchiveQuestion = archiveQuestions.find(x => x.aid === primaryQuestion.aid)

        if (primaryArchiveQuestion){

          if (question.QuestionsTopic.triggerValue === '>'){
            proposition.countAnswered = sum(primaryQuestion.repartition.slice(Math.ceil(primaryQuestion.responseCount / 2), primaryQuestion.repartition.length - 1))
          }else if(question.QuestionsTopic.triggerValue === '<'){
            proposition.countAnswered = sum(primaryQuestion.repartition.slice(0, Math.ceil(primaryQuestion.responseCount / 2)))
          }

        }

      }
    }

  }else{
    proposition.count = 0
  }

  return proposition

}

export default getArchiveProposition