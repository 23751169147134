/** 
 * CHIP
 * Round chip with text inside
 */
import { IconDefinition } from "@fortawesome/fontawesome-common-types"
import { faInfoCircle, faTimes, faUser } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

interface OwnProps{
  color? : string | null //Color of the chip
  count? : number //Display a count (with user icon)
  countWarning? : boolean //Is the count value in red
  children? : any //Elements inside the chip
  draggable? : boolean //Is draggable
  icon? : IconDefinition //Display icon before text
  isOutlineColored? : boolean //Show color only for outline
  isWithMargin? : boolean //Is there a margin
  onClick? : Function //Function on click
  onDelete? : Function | null //Function on delete (times icon if defined)
  onInfo? : Function | null //Function on info (info icon if defined)
}

function Chip(props:OwnProps) {

  //Action on click
  function click(e){
    e.stopPropagation()
    if (props.onClick){
      props.onClick(e)
    }
  }

  //Clic on info
  function clickInfo(e){
    e.stopPropagation()
    if (props.onInfo){
      props.onInfo(e)
    }
  }

  //Clic on delete
  function deleteChip(e){
    e.stopPropagation()
    if (props.onDelete){
      props.onDelete(e)
    }
  }

  //Classname
  function getClassName(){
    let className = "chip"
    if (props.isWithMargin) className = className + " chip-margin"
    if (props.onClick) className = className + " chip-editable"
    return className
  }

  //Style depends of color
  function getStyle(){
    if (props.color){
      return {
        backgroundColor : !props.isOutlineColored ? props.color : "",
        borderColor : props.color,
        color : props.isOutlineColored ? props.color : "white"
      }
    }else{
      return {}
    }
  }

  return (
    <div>
      <div className={getClassName()}
        onClick={click}
        style={getStyle()}>

        { props.icon &&
        <span style={{marginRight: (props.children || props.onDelete || props.count || props.onInfo) ? 6 : 0}}>
          <FontAwesomeIcon icon={props.icon}/>
        </span>
        }

        { props.children &&
        <span>
          {props.children}
        </span>
        }

        { props.onDelete &&
        <span
          className="chip-delete"
          onClick={deleteChip}
        >
          <FontAwesomeIcon icon={faTimes}/>
        </span>
        }
        
        {( typeof props.count === "number" ) &&
        <span className={props.countWarning && !props.color ? "red-t chip-user" : "chip-user"}>
          <FontAwesomeIcon icon={faUser}/>
          {props.count}
        </span>
        }

        { props.onInfo &&
        <span className="chip-info _hover"
          onClick={clickInfo}>
          <FontAwesomeIcon icon={faInfoCircle}/>
        </span>
        }

      </div>
    </div>
  )

}

export default Chip