/** 
 * HEATMAP-PARAMS.MODAL
 * Edit heatmap options
 */

import Checkbox from "@/components/checkbox"
import Dropdown from "@/components/dropdown"
import ModalHelp from "@/components/modal-help"
import { store } from "@/index"
import { withTranslation, WithTranslation } from "react-i18next"
import { connect } from "react-redux"
import { accountUpdate } from "@/redux/account.actions"
import { sessionEditAccountOptions } from "@/redux/_session.actions"
import { Session } from "@/redux/_session.types"
import initFilters from "@/utils/init-filters.utils"
import getFilterName from "@/utils/get-filter-name.utils"

interface StateProps extends WithTranslation{
  _session : Session
}

interface OwnProps{
  onClose : Function
  resultsTypes : any[]
}

type Props = StateProps & OwnProps

function HeatmapParamsModal(props:Props) {
  const { t } = props

  //On close
  //Save changes to the database
  function close(){

    if (props._session.authLevel > 0){
      store.dispatch(accountUpdate({
        id : props._session.accountId,
        options : props._session.accountOptions
      }))
    }
    
    props.onClose()
    
  }

  //Edit value
  function edit(key, value){
    store.dispatch(sessionEditAccountOptions(key, value))
  }

  //SPecific case of filter
  function editFilter(e){
    edit("heatmapFilterName", e.default ? e.id : e.name)
  }

  return (
    <ModalHelp title={t("utils_params")}
      onClose={close}>

      <Dropdown active={props._session.accountOptions.heatmapFilterName}
        displayField="name"
        list={initFilters()}
        onSelect={(e) => { editFilter(e) }}
        title={t("dashboard_heatmap_filter_name")}
        value={props._session.accountOptions.heatmapFilterName ? getFilterName(props._session.accountOptions.heatmapFilterName) : null}
      />

      <Dropdown active={props._session.accountOptions.heatmapResultsType}
        displayField="name"
        list={props.resultsTypes}
        onSelect={(e) => edit("heatmapResultsType", e.id)}
        title={t("dashboard_heatmap_results_type")}
        value={props._session.accountOptions.heatmapResultsType ? t(props._session.accountOptions.heatmapResultsType) : null}
      />

      <div className="height-20"/>

      <Checkbox text={t("dashboard_heatmap_no_score")}
        active={props._session.accountOptions.heatmapDisplayNoScore}
        onClick={(e) => edit("heatmapDisplayNoScore", e.value)}
      />

      <Checkbox text={t("dashboard_heatmap_participation")}
        active={props._session.accountOptions.heatmapDisplayParticipation}
        onClick={(e) => edit("heatmapDisplayParticipation", e.value)}
      />
      
      <Checkbox text={t("dashboard_heatmap_company")}
        active={props._session.accountOptions.heatmapDisplayCompany}
        onClick={(e) => edit("heatmapDisplayCompany", e.value)}
      />

    </ModalHelp>

  )

}


const mapStateToProps = state => ({
  _session : state._session
})

export default connect(mapStateToProps)(withTranslation()(HeatmapParamsModal))