/** 
 * REGROUPING.REDUCERS
 * Edit regrouping state
 */

import { Regrouping, RegroupingState, REGROUPING_ACTIVATE, REGROUPING_ADD, REGROUPING_COPY, REGROUPING_EDIT, REGROUPING_EDIT_LESSON, REGROUPING_GET, REGROUPING_INIT, REGROUPING_REMOVE, REGROUPING_STATUS } from "./regrouping.types";
import { STATUS_LOADED } from "./_status.types"
import { v4 as uuid } from "uuid";
import { Attribute } from "./attribute.types";
import { t } from "@/translate/t";


let active:Regrouping

const initialState: RegroupingState = {
  active : new Regrouping(),
  list : [],
  status : STATUS_LOADED
}

export default function regroupingReducer(
  state = initialState,
  action: any
): RegroupingState {
  switch (action.type) {

    //activate regrouping
    case REGROUPING_ACTIVATE:
      return Object.assign({}, state, {
        active : new Regrouping(action.payload.regrouping)
      })

    
    //Add new regrouping
    case REGROUPING_ADD:
      active = new Regrouping()
      active.id = uuid()
      active.name = t("regrouping_new")
      active.filterName = action.payload.filterName
      return Object.assign({}, state, { active })

    //Add existing regrouping to list
    case REGROUPING_COPY:
      active = new Regrouping(action.payload.regrouping)
      return Object.assign({}, state, {
        active,
        list : state.list.concat([active])
      })

    //editing regrouping
    case REGROUPING_EDIT:
      active = Object.assign(state.active, { [action.payload.key] : action.payload.value })
      return Object.assign({}, state, {
        active,
        list : state.list.map(x => x.id === active.id ? active : x)
      })

    //Update one attribute of user
    case REGROUPING_EDIT_LESSON:
      active = new Regrouping(state.active)

      active.Attributes = active.Attributes.map((x:Attribute)=>x.id === action.payload.lesson.id? action.payload.lesson : x)

      return Object.assign({}, state, {
        active,
        list : state.list.map(x => x.id === active.id ? active : x)
      })
    

    //Save list of regrouping in the store
    case REGROUPING_GET:
      return {
        ...state,
        list : action.payload.regroupings.map((x) => new Regrouping(x))
      }

    //Init store
    case REGROUPING_INIT:
      return Object.assign({}, state, {
        active : new Regrouping(state.active),
        list : state.list.map((x:any) => new Regrouping(x)),
        status : state.status
      })

    //Remove a regrouping
    case REGROUPING_REMOVE:
      return {
        ...state,
        list  : state.list.filter(x => x.id !== action.payload.id )
      }

    //Set status
    case REGROUPING_STATUS:
      return {
        ...state,
        status : action.payload.status
      }

    default:
      return state

  }

}