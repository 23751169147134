/** 
 * ADD-BOOKMARK.MODAL
 * Add a new bookmark for current survey
 */
import Modal from "@/components/modal"
import { connect } from "react-redux"
import { withTranslation, WithTranslation } from "react-i18next"
import TextInput from "@/components/text-input"
import { Bookmark, BookmarkOptions, BookmarkState } from "@/redux/bookmark.types";
import { SurveyState } from "@/redux/survey.types";
import { useState } from "react";
import { FilterState } from "@/redux/filter.types";
import { store } from "@/index";
import { bookmarkAdd, bookmarkInsert, bookmarkStatus } from "@/redux/bookmark.actions";
import { STATUS_SAVED, STATUS_SAVE_ERROR, STATUS_SAVING } from "@/redux/_status.types";
import { v4 as uuid } from "uuid";
import { surveyEdit, surveyUpdateBookmark } from "@/redux/survey.actions";
import { getHeatmap, initBookmarkName, getTopic } from "@/utils/get-export-names.utils"
import { Session } from "@/redux/_session.types";

interface StateProps extends WithTranslation{
  _session : Session
  bookmark : BookmarkState
  filter : FilterState
  survey : SurveyState
}

interface OwnProps{
  onClose : Function
}

type Props = StateProps & OwnProps

function AddBookmarkModal(props:Props) {
  const { t } = props

  const [bookmarkName, setBookmarkName] = useState(initBookmarkName(t))
  const [bookmarkOptions] = useState(new BookmarkOptions({
    dashboardFilters : props.filter.dashboard,
    dashboardFiltersCompare : window.location.pathname === "/dashboard/compare" ? props.filter.dashboardCompare : null,
    page : window.location.pathname,
    urlParams : getUrlParams()
  }))

  //Get params for compare
  function getCompareSurveyId(){
    let surveyId:string | null = null

    if (window.location.pathname === "/dashboard/compare"){
      const survey = document.getElementsByClassName("dashboard-compare-survey")
      if (survey.length){
        surveyId = survey[0].id
      }

    }

    return surveyId

  }

  //Get anchor for Question page
  function getUrlParams(){
    let urlParams = ""
    const topicId = getTopic().id
    const heatmap = getHeatmap(t)
    const surveyId = getCompareSurveyId()

    if (topicId.length){
      urlParams = "topic_id=" + topicId
    }

    else if (heatmap.filterName.length){
      urlParams = "filter_name=" + heatmap.filterName + "&results_type=" + heatmap.resultsType
    }

    else if (surveyId){
      urlParams = "survey_id=" + surveyId
    }

    return urlParams

  }

  //Save new bookmark to server
  async function next(){
    const bookmarkId = uuid()
    const bookmarkOrders = props.survey.active.bookmarksOrder.concat([bookmarkId])

    //Create new bookmark
    const bookmark = new Bookmark({
      id : bookmarkId,
      name : bookmarkName,
      options : bookmarkOptions,
      UserId : props._session.userId
    })

    //Do save
    store.dispatch(bookmarkStatus(STATUS_SAVING))
    const response:any = await store.dispatch(bookmarkInsert(bookmark, props.survey.active.id, props.survey.active.isSurveyGroup))
    if (response.error){
      store.dispatch(bookmarkStatus(STATUS_SAVE_ERROR))
    }else{
      store.dispatch(bookmarkAdd(bookmark))
      store.dispatch(surveyEdit("bookmarksOrder", bookmarkOrders))
      store.dispatch(surveyUpdateBookmark(props.survey.active.id, bookmarkOrders))
      store.dispatch(bookmarkStatus(STATUS_SAVED))
      props.onClose()
    }

  }

  return (
    <Modal isCloseButtonVisible
      status={props.bookmark.status}
      onClose={() => props.onClose()}
      onNext={next}
      title={t("utils_save")}>
      
      <TextInput autoFocus
        isFullWidth
        onChange={(e) => setBookmarkName(e.value)}
        title={t("bookmark_name")}
        value={bookmarkName}
      />

      <div className="height-20"/>

    </Modal>
  )

}

const mapStateToProps = state => ({
  _session : state._session,
  bookmark : state.bookmark,
  filter : state.filter,
  survey : state.survey
})

export default connect(mapStateToProps)(withTranslation()(AddBookmarkModal))