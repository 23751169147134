/**
 * ADM-IMPORT.ROUTE
 * Add user one by one
 */
import NavFocus from "@/nav/nav.focus"
import { WithTranslation, withTranslation } from 'react-i18next';
import PageHeader from "@/components/page-header";
import Card from "@/components/card"; 
import AdmImportManualWidget from "@/widgets/adm-import-manual.widget";
import { NavigateFunction, useNavigate } from "react-router-dom";

type Props = WithTranslation

function AdmImportManualRoute(props:Props){
  const { t } = props
  const navigate: NavigateFunction = useNavigate()
  return(
    <NavFocus>
      <PageHeader title={t("user_import_manual")}
        subtitle={t("user_import_manual_help")}/>
        <Card>
          <AdmImportManualWidget onNext={() => navigate("/adm/users")}/>
        </Card>
    </NavFocus>
  )
}

export default withTranslation()(AdmImportManualRoute)