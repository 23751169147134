/**
 * EXPORT-ACTION-PLAN.UTILS
 * export action plan to xlsx
 */


import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { t } from "@/translate/t";
import { ActionPlan, ActionPlanTask } from "@/redux/action-plan.types";
import { store } from "..";
import { formatDate } from "./format-date.utils";

export function exportActionPlan(actionPlan: ActionPlan){

  const fileName: string = t("tasks_new_admin", {name: actionPlan.UserResponsible ? actionPlan.UserResponsible.username : t("user")})
  
  let cells: string[][] = [
    ["🎯", t("tasks_priority"), actionPlan.priority.length > 0 ? actionPlan.priority : t("utils_undefined")],
    ["🏅", t("tasks_expected_outcome"), actionPlan.expectedOutcome.length > 0 ? actionPlan.expectedOutcome : t("utils_undefined")],
    ["👋", t("tasks_contact"), ...(actionPlan.UserContact ? [actionPlan.UserContact.username, actionPlan.UserContact.email!] : [t("utils_undefined")])],
    ["▶️", t("tasks_date_start"), actionPlan.dateStart ? formatDate(actionPlan.dateStart, false, false) : t("utils_undefined")],
    ["⏹️", t("tasks_date_end"), actionPlan.dateEnd ? formatDate(actionPlan.dateEnd, false, false) : t("utils_undefined")]
  ]

  const tasks: string[][] = actionPlan.tasks.map((task: ActionPlanTask) => [
    task.title,
    task.text,
    task.getTopic(store.getState().topic.list).label,
    t("tasks_list_status_" + task.status)
  ])

  if(tasks.length > 0){
    cells = [
      ...cells,
      [],
      ["📝", `${t("tasks_status_todo")} (${tasks.length})`],
      ["", t("tasks_list_title"), t("tasks_list_text"), t("topic"), t("project_status")],
      ...tasks.map((item: string[], index: number) => [index + 1 + "", ...item])
    ]
  }

  const workbook: XLSX.WorkBook = XLSX.utils.book_new()

  const worksheet: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(cells)

  const colWidths: any = [
    { wch: 10 },
    { wch: 50 },
    { wch: 50 },
    { wch: 50 },
    { wch: 50 }
  ]
  worksheet['!cols'] = colWidths

  // Add worksheet to workbook
  XLSX.utils.book_append_sheet(workbook, worksheet, fileName);

  // Generate a binary Excel file and save it
  const document: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  saveAs(new Blob([document]), fileName.toLowerCase().replaceAll(/[^a-zA-Z0-9]/g, "_") + '.xlsx');

}