/** 
 * CARD-BUTTON
 * Big square button
 */
import { faEllipsisV, faLock, IconDefinition } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { withTranslation, WithTranslation } from 'react-i18next';
import { useState } from "react"
import { connect } from "react-redux"
import ListButton from "./list-button"
import Space from "./space"
import { Session } from "@/redux/_session.types";
import ActionsMenu from "./actions-menu";

interface StateProps extends WithTranslation{
  _session : Session
}

interface OwnProps{
  actions? : any[] //Container différents actions (for context menu)
  children? : any //Content inside the card
  icon? : IconDefinition //Icon to display (if no image)
  iconColor? : string //Color of the icon
  image? : string | null //Url of the picture
  isActive? : boolean //Selected (take active color)
  isCta? : boolean //Call to action
  isSmall? : boolean //Small version of the button
  isLock? : boolean //Display text in grey and add lock icon
  onAction? : Function //Function trigger on click on action of context menu
  onClick : Function //Function trigger on click
  title : string //Title
  subtitle? : string //Subtitle
}

type Props = StateProps & OwnProps

const MIN_LENGTH_TEXT = 44 //Limit of char for text to display image or icon

function CardButton(props:Props) {
  const { t } = props

  //Open context menu of actions
  const [openActions, setOpenActions] = useState(false)

  //Clic on action
  function clickAction(actionId){
    setOpenActions(false)
    if (props.onAction){
      props.onAction(actionId)
    }
  }

  //Get style of icon
  function getIconStyle(){
    let color

    if (props.isCta){
      color = "#20CA7E"
    }

    else if (props.isActive){
      color = props._session.accountColors.active
    }

    return {
      color
    }

  }

  //Style of bouton
  //Call to action set background color
  //If selected change border
  function getStyle(){
    let borderColor
    let color
    let width
    let height

    if (props.isCta){
      borderColor = props._session.accountColors.cta
    }

    if (props.isActive){
      borderColor = props._session.accountColors.active
    }

    if (props.isSmall){
      width = 120
      height = 120
    }
    
    return {
      borderColor,
      color,
      width,
      height
    }

  }

  return (
    <div>

      <div className="rel">

        { openActions &&
        <ActionsMenu actions={props.actions ? props.actions : []}
          onSelect={clickAction}
          onClose={() => setOpenActions(false)}
        />
        }
        
      </div>

      <div className="card-button flex flex-dcol"
        style={getStyle()}
        onClick={(e) => props.onClick(e)}>

        { (props.actions && !props.isLock) &&
        <div className="card-button-actions grey-t">
          <ListButton icon={faEllipsisV}
            onClick={() => setOpenActions(true)}
            text={t("utils_options")}
          />
        </div>
        }

        <Space/>

        { (props.image && props.title?.length < MIN_LENGTH_TEXT) &&
        <img src={props.image} alt={props.title} 
          style={{
            marginBottom : props.title === "" ? "0px" : "",
            filter : props.isLock ? "grayscale(1)" : "",
            opacity : props.isLock ? 0.5 : 1
          }}>
        </img>
        }

        { (!props.image && props.icon) &&
        <div className="medgrey-t card-button-icon"
          style={getIconStyle()}>

          { props.title?.length < MIN_LENGTH_TEXT &&
          <FontAwesomeIcon icon={props.icon} color={props.iconColor!}/>
          }

        </div>
        }

        <Space/>
        
        <div className={props.isLock ? "grey-t" : ""}>

          { props.isLock &&
          <FontAwesomeIcon style={{ marginRight : '6px' }} icon={faLock}/>
          }

          { (props.isActive || props.isCta)
          ?
          <b>{props.title}</b>
          :
          props.title
          }
          
        </div>

        { props.title !== "" && <Space/> }

        { props.subtitle &&
        <div className="card-button-subtitle card-button-footer"
          style={{
            backgroundColor : props.isActive ? props._session.accountColors.active : ( props.isCta ? props._session.accountColors.cta : "")
          }}>
          {props.subtitle}
        </div>
        }

      </div>

    </div>
  )

}

const mapStateToProps = state => ({
  _session : state._session
})

export default connect(mapStateToProps)(withTranslation()(CardButton))