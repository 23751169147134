import { orderBy } from "lodash";
import { Reminder, ReminderState, REMINDER_ADD, REMINDER_GET, REMINDER_INIT, REMINDER_REMOVE, REMINDER_REPLACE } from "./reminder.types";

let active:Reminder
let list:Reminder[]

const initialState: ReminderState = {
  list : []
}

export default function reminderReducer(
  state = initialState,
  action: any
): ReminderState {
  switch (action.type) {

    //Get active invitation model
    case REMINDER_ADD:
      active = new Reminder(action.payload.reminder)
      return Object.assign({}, state, {
        list : orderBy([...state.list, active ], ["date"], ["asc"])
      })

    //Save reminder
    case REMINDER_GET:
      list = action.payload.reminders.map((x) => new Reminder(x))
      return {
        ...state,
        list,
      }
      
    //Init store
    case REMINDER_INIT:
      return Object.assign({}, state, {
        list : state.list.map((x:any) => new Reminder(x))
      })

    //Remove invitation model
    case REMINDER_REMOVE:
      return {
        ...state,
        list  : state.list.filter(i => { return i.id !== action.payload.id })
      }

    //Replace existing item
    case REMINDER_REPLACE:
      active = new Reminder(action.payload.reminder)
      list = state.list.map(x => x.id === active.id ? active : x)
      return Object.assign({}, state, {
        list
      })

    default:
      return state

  }
  
}