/** 
 * STEP-CARD
 * Card in wizard
 */

import ListItem from "./list-item";
import Card from "./card";
import { faCheck, faLock, faTimesCircle, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import ListIcon from "./list-icon";
import Space from "./space";
import { FadeIn } from "@/utils/animations.utils";
import { Project, ProjectStepStatus, StepAlert } from "@/redux/project.types";
import { withTranslation, WithTranslation } from "react-i18next";
import { useEffect, useState } from "react";

interface OwnProps extends WithTranslation{
  children : React.ReactNode //Display core of the sted
  icon : IconDefinition //Icon of the card
  isConfirmed : boolean //Step is confirmed
  isVisited : boolean //Step is visited (user has already open it)
  isOpen? : boolean //Is the card open
  onOpen : Function //Trigger function on open
  title : string //Title of the card
  showWarnings : boolean //Display or hide warnings
  subtitle? : React.ReactNode //Text to display at the end
  alert : StepAlert
  isLocked? : boolean
  tooltip? : string
}

function StepCard(props:OwnProps) {
  const { t } = props

  const [status, setStatus] = useState<ProjectStepStatus>(props.isLocked ? "locked" : "initial")

  useEffect(() => {
    if(!props.isLocked){
      setStatus(new Project().getStepStatus(
        props.alert.errors.length,
        props.alert.warnings.length,
        props.showWarnings,
        props.isConfirmed,
        props.isVisited
      ))
    }
    else{
      setStatus("locked")
    }
  }, [
    props.isLocked,
    props.isVisited,
    props.isConfirmed,
    props.showWarnings,
    props.alert.errors.length,
    props.alert.warnings.length
  ])

  //Alternative display if card is done or is locked
  function getHeaderClassname(){
    let className = "rel setup-card-header"
    if(status === "locked"){
      className += " _hover setup-card-header-locked"
    }else{
      className += " _hover_big"
      if (!props.isVisited && !props.isConfirmed){
        className += " grey-t"
      }else if(status === "error"){
        className += " setup-card-header-error"
      }else if(status === "warning"){
        className += " setup-card-header-warning"
      }else if (status === "confirmed"){
        className += " setup-card-header-confirmed"
      }else{
        className += " setup-card-header-visited"
      }
    }

    return className


  }

  function getHeaderIcon(){
    switch(status){
      case "locked":
        return faLock
      case "confirmed":
        return faCheck
      case "error":
        return faTimesCircle
      case "warning":
        return faCheck
      default:
        return props.icon
    }
  }

  function getWarnings(): boolean{
    return props.alert.warnings.length > 0 && props.showWarnings
  }
  
  return (
    <Card isWithoutPadding>

      <div className={getHeaderClassname()}
        data-tip={props.tooltip ? t(props.tooltip) : undefined}>

        <div className="setup-card-header-color"/>

        <ListItem onClick={props.onOpen}>

          <ListIcon fallbackIcon={getHeaderIcon()}/>

          {props.title}

          { props.isConfirmed &&
          <div className="flex flex1" style={{ fontSize : "14px", margin : "auto 20px" }}>

            { props.alert.errors.length > 0 &&
            <div className="red-t">
              {props.alert.errors.length + " " + t("utils_error", { s: props.alert.errors.length > 1 ? "s" : ""})}
            </div>
            }

            {( props.alert.errors.length > 0 && getWarnings()) &&
            <div className="grey-t">
              {", "}
            </div>
            }

            { getWarnings() &&
            <div className="medgrey-t">
              {props.alert.warnings.length + " " + t("utils_warning", { s: props.alert.warnings.length > 1 ? "s" : ""})}
            </div>
            }

            <div className="width-20" />
            
            <Space/>

            <div className="flex setup-card-header-subtitle" >
              {props.subtitle}
            </div>

          </div>
          }

        </ListItem>
      </div>

      <div className={"rel setup-card-content " + (props.isOpen ? " setup-card-content-expanded" : "")}>

        { props.isLocked &&

        <div className="abs"
          style={{
            zIndex : 9,
            backgroundColor: "#ffffff61",
            width: "100%",
            height: "100%"
          }}
        />
        }

        { props.isOpen &&
        <FadeIn>
          { props.children }
        </FadeIn>
        }
      </div>

    </Card>
  )

}

export default (withTranslation()(StepCard))