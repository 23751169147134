/**
 * BACK-TO-SUPERVISOR
 * Go back to supervisor interface
 */

import axios from 'axios';
import { store } from '@/index';
import cookie from 'react-cookies';
import { accountQuit } from '@/redux/account.actions';
import { supervisorStatus } from '@/redux/supervisor.actions';
import { sessionGet } from '@/redux/_session.actions';
import { Session } from '@/redux/_session.types';
import { STATUS_LOADED, STATUS_LOADING, STATUS_LOAD_ERROR } from '@/redux/_status.types';

const backToSupervisor = async () => {

  store.dispatch(supervisorStatus(STATUS_LOADING))
  const response:any = await store.dispatch(accountQuit(true))

  if (response.error){
    store.dispatch(supervisorStatus(STATUS_LOAD_ERROR))
  }else{

    setTimeout(() => {

      store.dispatch(supervisorStatus(STATUS_LOADED))

      //Save token
      cookie.save('idtreeToken', response.token, { path: '/' })
      axios.defaults.headers.common['idtreeToken'] = response.idtreeToken

      //Set superadmin session
      const _session = new Session()
      _session.interfaceType = "SUPERVISOR"
      _session.authLevel = 2
      _session.email = response.supervisor.email
      _session.language = response.supervisor.language
      _session.supervisorId = response.supervisor.id
      _session.supervisorLevel = response.supervisor.level
      _session.userFirstname = response.supervisor.firstname
      _session.userLastname = response.supervisor.lastname
      _session.connectedAsSupervisor = true

      //Update store redux
      store.dispatch(sessionGet(_session))

      //Go to account page
      setTimeout(() => {
        store.dispatch(supervisorStatus(STATUS_LOADED))
        window.location.pathname = '/supervisor/accounts'
      }, 500)

    }, 200)

  }

}

export default backToSupervisor