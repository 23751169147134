/** 
 * QUESTION-FULLSCREEN.HELP
 * Info about carte in big screen
 */
import HelpModal from "@/components/modal-help"
import { withTranslation, WithTranslation } from "react-i18next"

interface OwnProps extends WithTranslation{
  onClose : Function
}

function QuestionFullScreenHelp(props:OwnProps) {
  const { t } = props

  return (
    <HelpModal onClose={props.onClose}
      title={t("question_fullscreen")}>
      {t("question_fullscreen_help")}
    </HelpModal>
  )
}

export default withTranslation()(QuestionFullScreenHelp)