/** 
 * LOGIN-SSO
 * Login to application with SSO provider
 */
import LoginModal from '@/modals/login.modal'

function LoginSsoRedirectionRoute(){
  return (
    <LoginModal authLevel={2} sso={true}/>
  )
}

export default LoginSsoRedirectionRoute