/** 
 * EDITOR-OPTIONS.WIDGET
 * Customize editor options
 */

import { connect } from "react-redux"
import { withTranslation, WithTranslation } from "react-i18next";
import { Session } from "@/redux/_session.types";
import Card from "@/components/card";
import Checkbox from "@/components/checkbox";
import { store } from "@/index";
import { sessionEditUserOptions } from "@/redux/_session.actions";
import { userUpdateOptions } from "@/redux/user.actions";

interface StateProps extends WithTranslation{
  _session : Session
}

function EditorOptionsWidget(props:StateProps) {
  const { t } = props

  //Edit user options when checkbox is edited
  function editCheckbox(checkboxId: "hideWizardEditor" | "activateQuestions"){
    
    store.dispatch(sessionEditUserOptions(checkboxId, !props._session.userOptions[checkboxId]))

    setTimeout(() => {
      if (props._session.interfaceType !== "SUPERVISOR"){
        store.dispatch(userUpdateOptions(props._session.userId, props._session.userOptions))
      }
    }, 200);
    
  }

  return (
    <Card  title={t("utils_editor_preferences")}>

      <Checkbox
        active={!props._session.userOptions.hideWizardEditor}
        text={t("nlp_wizard_display")}
        onClick={() => editCheckbox("hideWizardEditor")}
      />

      <Checkbox
        active={!props._session.userOptions.activateQuestions}
        text={t("utils_draft_default")}
        onClick={() => editCheckbox("activateQuestions")}
      />

    </Card>
  )
}

const mapStateToProps = state => ({
  _session: state._session,
})

export default connect(mapStateToProps)(withTranslation()(EditorOptionsWidget))