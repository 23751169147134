/** 
 * FORMATION-HOME
 * Home for LMS (learning management system)
 */
import { Component } from "react";

import { connect } from "react-redux";
import { Session } from "@/redux/_session.types";
import NavApp from "@/nav/nav.app";
import UpgradeWidget from "@/widgets/upgrade.widget";

interface Props{
  _session : Session
}

class LearningHome extends Component<Props>{

  render () {
    return(
      <NavApp>
        { this.props._session.modules.learning
        ?
        <iframe className="learning-iframe"
          title="learning"
          style={{
            margin : '-20px -40px',
            width : 'calc(100% + 80px)'
          }}
          src="https://coaching.id-tree.com/#!/_login/coaching/1dbad5e4-93d4-4d85-871a-21dc9a17a0ad/null/">
        </iframe>
        :
        <UpgradeWidget feature="learning"/>
        }
      </NavApp>
    )
  }
}

const mapStateToProps = state => ({
  _session : state._session
})

export default connect(mapStateToProps)(LearningHome)