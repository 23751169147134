/**
 * EMAIL.TYPES
 * 
 */

import { Project, ProjectMessageOptions } from "./project.types"
import emailTemplates from "@/json/email-templates.json"
import { v4 as uuid } from "uuid";
import i18n from "@/translate/i18n";
import { t } from "@/translate/t";

//Content of the email
//For each attribute there is translations possible (ex { "fr" : "xxx", "en" : "yyy" })
export class EmailContent{
  cta:any = { [i18n.language] : t("email_cta") }
  title:any = { [i18n.language] : t("email_title") }
  body:any = {}
  info:any = { [i18n.language] : t("email_info") }
  footer:any = { [i18n.language] : t("email_footer") }
  subject:any = {}

  constructor(item: Partial<EmailContent> = {}){
    if (!item) item = new EmailContent()
    Object.assign(this, item) 
  }

}

/**
 * ACCOUNT
 */
export class Email{
  id:string = ""
  content:EmailContent = new EmailContent()
  name:string = ""
  sender:string = ""
  isChecked: boolean = false
  Project:Project = new Project()

  constructor(item: Partial<Email> = {}){
    if (!item) item = new Email()
    item.content = item.content ? new EmailContent(item.content) : new EmailContent()
    item.Project = item.Project ? new Project(item.Project) : new Project()
    Object.assign(this, item)
  }

  get texts():string[]{
    return [
      this.content.subject,
      this.content.title,
      this.content.body,
      this.content.footer
    ]
  }

  getEmptyFields(lang :string){
    return this.texts.filter((x:any)=> !x[lang] || x[lang].length === 0)
  }

  //Get list of template for a project
  getList(senderName: string, projectName: string, templateName: string):Email[]{

    const emails:Email[] = []
    for (let i = 0; i < 5; i++) {
      emails.push(this.getFromTemplate(i, senderName, projectName, templateName))
    }

    return emails
  }

  //Get email from template by index
  getFromTemplate(index:number, senderName:string, projectName:string, templateName: string):Email{
    const emailTemplate = emailTemplates[index] ? emailTemplates[index] : emailTemplates[0]
    emailTemplate["title"] = emailTemplate.subject
    const content = this.replaceKeyWords(new EmailContent(emailTemplate), senderName, templateName.length > 0 ? templateName : projectName)
    return new Email({
      id : uuid(),
      name : t("email_template_" + index),
      sender : senderName,
      content
    })
  }

  //Get template from specific message
  getFromMessage(name:string, message:ProjectMessageOptions, senderName:string):Email{
    const content = new EmailContent(emailTemplates[0])
    content.body = message.content
    content.subject = message.title
    content.title = message.title
    return new Email({
      id : uuid(),
      name,
      sender : senderName,
      content
    })
  }

  replaceKeyWords(emailContent:EmailContent, senderName:string, projectName:string):EmailContent{
    const stringContent: string = JSON.stringify(emailContent).replace(new RegExp("\\$sender", 'g'), senderName).replace(new RegExp("\\$project", 'g'), projectName)
    return new EmailContent(JSON.parse(stringContent))
  }

}

export interface EmailState{
  active : Email
  list : Email[]
}

export const EMAIL_ACTIVATE : string = "EMAIL_ACTIVATE"
export const EMAIL_ADD : string = "EMAIL_ADD"
export const EMAIL_EDIT : string = "EMAIL_EDIT"
export const EMAIL_GET : string = "EMAIL_GET"
export const EMAIL_INIT : string = "EMAIL_INIT"
export const EMAIL_REMOVE : string = "EMAIL_REMOVE"