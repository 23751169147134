/**
 * INVITATION.ACTIONS 
 */

import { Invitation, INVITATION_ACTIVATE, INVITATION_REMOVE, INVITATION_GET, INVITATION_GET_COUNT, INVITATION_GET_COUNT_SEARCH, INVITATION_INIT, INVITATION_STATUS, INVITATION_IS_GENERATING, INVITATION_EDIT_FROM_USER } from "./invitation.types"
import { User } from "./user.types"
import { STATUS_LOADED, STATUS_LOAD_ERROR, STATUS_SEARCHING } from "./_status.types"


//REDUX


export const invitationActivate = (invitation: Invitation) => ({
  type: INVITATION_ACTIVATE,
  payload: {
    invitation
  }
})

export const invitationEditFromUser = (user: User) => ({
  type: INVITATION_EDIT_FROM_USER,
  payload: {
    user
  }
})

export const invitationGet = (invitations: Invitation[]) => ({
  type: INVITATION_GET,
  payload: {
    invitations
  }
})

export const invitationGetCount = (count: number) => ({
  type: INVITATION_GET_COUNT,
  payload: {
    count
  }
})

export const invitationGetCountSearch = (countSearch: number) => ({
  type: INVITATION_GET_COUNT_SEARCH,
  payload: {
    countSearch
  }
})

export const invitationInit = () => ({
  type: INVITATION_INIT
})

export const invitationIsGenerating = (isQrcodeGenerating: boolean) => ({
  type: INVITATION_IS_GENERATING,
  payload: {
    isQrcodeGenerating
  }
})

export const invitationRemove = (aid: number) => ({
  type: INVITATION_REMOVE,
  payload: {
    aid
  }
})

export const invitationStatus = (status: string) => ({
  type: INVITATION_STATUS,
  payload: {
    status
  }
})


// API


export const invitationDestroy = (aid: number) => ({
  type: 'API',
  payload: {
    method: "DELETE",
    url: "/invitation/" + aid
  }
})

export const invitationFetch = (surveyId: string, search: string, limit: number, offset: number) => ({
  type: 'API',
  payload: {
    method: "GET",
    url: "/invitations",
    data: {
      surveyId,
      search,
      limit,
      offset
    }
  }
})

export const invitationFetchByEmail = (email:string) => ({
  type: 'API',
  payload: {
    method: "GET",
    url: "/invitation/email",
    data: {
      email
    }
  }
})

export const invitationFetchCount = (surveyId: string, search: string) => ({
  type: 'API',
  payload: {
    method: "GET",
    url: "/invitations/count",
    data: {
      surveyId,
      search
    }
  }
})

export const invitationFetchCountWithoutEmail = (surveyId: string) => ({
  type: 'API',
  payload: {
    method: "GET",
    url: "/invitations/count/emailless",
    data: {
      surveyId
    }
  }
})

export const invitationFetchLast = () => ({
  type: 'API',
  payload: {
    method: "GET",
    url: "/invitation/last"
  }
})

export const invitationAddNew = (surveyId: string, usersIds: string[]) => ({
  type: 'API',
  payload: {
    method: "POST",
    url: "/invitation/add-new",
    data: {
      surveyId,
      usersIds
    }
  }
})

export const invitationReset = (aid: number) => ({
  type: 'API',
  payload: {
    method: "POST",
    url: "/invitation/reset",
    data: {
      aid
    }
  }
})

export const invitationSendEmail = (projectId: string, invitationAid: number, emailId: string, skin: number | null) => ({
  type: 'API',
  payload: {
    method: "POST",
    url: "/invitation/send-email",
    data: {
      projectId,
      invitationAid,
      emailId,
      skin
    }
  }
})


/*** REDUX THUNK ***/

export const invitationFetchAndGet: any = (search, limit, offset) => async (dispatch, getState) => {

  //Set loading and prepare mode to send to the backend
  dispatch(invitationStatus(STATUS_SEARCHING))

  //Fetch data from server
  const response: any = await dispatch(invitationFetch(getState().survey.active.id, search, limit, offset))
  const responseCount: any = await dispatch(invitationFetchCount(getState().survey.active.id, search))

  //If error display status
  if (response.error || responseCount.error) {
    dispatch(invitationStatus(STATUS_LOAD_ERROR))
  }
  //Else edit store
  else {
    dispatch(invitationStatus(STATUS_LOADED))
    dispatch(invitationGet(response))
    dispatch(invitationGetCountSearch(responseCount.count))
    if (!search.length) dispatch(invitationGetCount(responseCount.count))
  }

}