/** 
 * DELETE-AXIS.MODAL
 * Delete an axis
 */
import { connect } from "react-redux"
import { withTranslation, WithTranslation } from "react-i18next"
import { store } from "@/index"
import { STATUS_SAVED, STATUS_SAVE_ERROR, STATUS_SAVING } from "@/redux/_status.types"
import { Session } from "@/redux/_session.types";
import ModalConfirm from "@/components/modal-confirm";
import { axisDestroy, axisDestroy_AsSupervisor, axisRemove, axisStatus } from "@/redux/axis.actions";
import { topicRemoveAxes } from "@/redux/topic.actions";
import { AxisState } from "@/redux/axis.types";
import { TemplateState } from "@/redux/template.types";
import DuplicateTemplateAskModal from "./duplicate-template-ask.modal";

interface StateProps extends WithTranslation{
  _session : Session
  axis : AxisState,
  template : TemplateState,
}

interface OwnProps{
  onClose : Function
}

type Props = StateProps & OwnProps

function DeleteAxisModal(props:Props) {
  const { t } = props

  //Delete confirm
  async function removeAxisConfirm(){
    const destroyFunction = props._session.interfaceType === "SUPERVISOR" ? axisDestroy_AsSupervisor : axisDestroy
    store.dispatch(axisStatus(STATUS_SAVING))
    const response:any = await store.dispatch(destroyFunction(props.axis.active.id))
    if (response.error){
      store.dispatch(axisStatus(STATUS_SAVE_ERROR))
    }else{
      store.dispatch(axisStatus(STATUS_SAVED))
      store.dispatch(axisRemove(props.axis.active.id))
      store.dispatch(topicRemoveAxes(props.axis.active.id))
      props.onClose()
    }
  }

  return (
    (props.template.active.fromSupervisor && props._session.interfaceType !== "SUPERVISOR")
    ?
    <DuplicateTemplateAskModal onClose={props.onClose}/>
    :
    <ModalConfirm onNo={props.onClose}
      onYes={removeAxisConfirm}
      text={t("axis_ask_delete", {
        axis : props.axis.active.name[props.template.active.language]
      })}
      status={props.axis.status}
      textBold={t("utils_next_ask")}
    />
  )

}

const mapStateToProps = state => ({
  _session : state._session,
  axis : state.axis,
  template : state.template
})

export default connect(mapStateToProps)(withTranslation()(DeleteAxisModal))