/** USER.REDUCERS
 * Modifier le state pour les données des utilisateurs
 */

import { v4 as uuid } from "uuid";
import { User, UserState, USER_ACTIVATE, USER_ADD, USER_COPY, USER_EDIT, USER_EDIT_ATTRIBUTE, USER_GET, USER_GET_COUNT, USER_GET_COUNT_SEARCH, USER_GET_EXCLUDED, USER_INIT, USER_REMOVE, USER_STATUS } from "./user.types";
import { STATUS_LOADED } from "./_status.types";

let active:User

const initialState: UserState = {
  active : new User(),
  count : 0,
  countSearch : 0,
  currentSearch : "",
  currentOffset : 0,
  list : [],
  listExcluded : [],
  status : STATUS_LOADED,
}

export default function userReducer(
  state = initialState,
  action: any
): UserState {
  switch (action.type) {

    //Set active user
    case USER_ACTIVATE:
      return Object.assign({}, state, {
        active : new User(action.payload.user)
      })

    //Add a new user
    //Update counts
    case USER_ADD:
      active = new User(action.payload.user)
      active.id = uuid()
      return Object.assign({}, state, {
        active,
        count : state.count + 1,
        countSearch : state.countSearch + 1,
        list : [active, ...state.list]
      })

    //Add existing user to list (ex copy from search to add to admin list)
    case USER_COPY:
      active = new User(action.payload.user)
      return Object.assign({}, state, {
        active,
        list : [active, ...state.list]
      })
      
    //Remove a user
    //Update counts
    case USER_REMOVE:
      return {
        ...state,
        count : state.count - 1,
        countSearch : state.countSearch - 1,
        list  : state.list.filter(x => x.id !== action.payload.id )
      }

    //Edit user
    case USER_EDIT:
      active = Object.assign(state.active, { [action.payload.key] : action.payload.value })
      return Object.assign({}, state, {
        active,
        list : state.list.map(x => x.id === active.id ? active : x)
      })

    //Update one attribute of user
    case USER_EDIT_ATTRIBUTE:
      active = new User(state.active)
      /*attribute = new Attribute(action.payload.attribute)

      //Remove the current attribute for the filter
      active.Attributes = active.Attributes.filter(x => x.FilterId !== attribute.FilterId)

      //Init relationship and concat with attribute array
      attribute.AttributesUser.UserId = active.id
      attribute.AttributesUser.AttributeId = attribute.id
      attribute.AttributesUser.user = true
      active.Attributes = active.Attributes.concat([attribute])*/

      return Object.assign({}, state, {
        active,
        list : state.list.map(x => x.id === active.id ? active : x)
      })
  
    //Get list of users
    case USER_GET:
      return Object.assign({}, state, {
        list : action.payload.users.map((x:User) => new User(x))
      })

    //Get list of users excluded
    case USER_GET_EXCLUDED:
      return Object.assign({}, state, {
        listExcluded : action.payload.users.map((x:User) => new User(x))
      })

    //Get count of user (total)
    case USER_GET_COUNT:
      return Object.assign({}, state, {
        count : action.payload.count
      })

    //Get count of user (with search)
    case USER_GET_COUNT_SEARCH:
      return Object.assign({}, state, {
        countSearch : action.payload.countSearch
      })

    //Init store on app load
    case USER_INIT:
      return Object.assign({}, state, {
        active : new User(state.active),
        currentOffset : initialState.currentOffset,
        currentSearch : initialState.currentSearch,
        list : state.list.map((x:any) => new User(x)),
        listExcluded :  state.listExcluded ? state.listExcluded.map((x:any) => new User(x)) : [],
        status : initialState.status
      })

    //Update loader status
    case USER_STATUS:
      return {
        ...state,
        status : action.payload.status
      }

    default:
      return state

  }

}