/**
 * GET-REPARTITION-COLOR
 * Get color for repartition from a scale
 */

import { AccountOptions } from "@/redux/account.types"

const getRepartitionColor = (note:number, max:number, rps:boolean, displaySettings:AccountOptions, displayAllColors:boolean) => {
  let color
  const score = note / max

  if (displaySettings.dashboardNightmareModeDisplay){

    if (score >= displaySettings.dashboardNightmareModeMax / 100){
      color = displaySettings.dashboardNightmareColor3
    }else if(score < displaySettings.dashboardNightmareModeMin / 100){
      color = displaySettings.dashboardNightmareColor1
    }else{
      color = displaySettings.dashboardNightmareColor2
    }

  }else if(displayAllColors){

    if (score >= 1) color = "#1CB06E"
    else if(score >= 0.9) color = "#1CB06E"
    else if(score >= 0.8) color = "#20CA7E"
    else if(score >= 0.7) color = "#51e898"
    else if(score >= 0.6) color = "#99efc2"
    else if(score >= 0.5) color = "#d5d4d4"
    else if(score >= 0.4) color = "#cdc6c6"
    else if(score >= 0.3) color = "#f1a8a3"
    else if(score >= 0.2) color = "#f3857d"
    else if(score >= 0.1) color = "#f05146"
    else color = "#dd4237"

  }else{
    
    if (score >= 1) color = "#51e898"
    else if(score >= 0.9) color = "#51e898"
    else if(score >= 0.8) color = "#51e898"
    else if(score >= 0.7) color = "#99efc2"
    else if(score >= 0.6) color = "#99efc2"
    else if(score >= 0.5) color = "#d5d4d4"
    else if(score >= 0.4) color = "#d5d4d4"
    else if(score >= 0.3) color = "#f1a8a3"
    else if(score >= 0.2) color = "#f1a8a3"
    else if(score >= 0.1) color = "#f05146"
    else color = "#f05146"

    if (rps){
      if (score >= 0.5) color = "#99efc2"
    }

  }

  return color

}

export default getRepartitionColor