/** 
 * LOADING.MODAL
 * Display loading
 */

import Modal from "@/components/modal"
import { withTranslation, WithTranslation } from "react-i18next"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import Space from "@/components/space";

interface OwnProps{
  children? : any
} 

type Props = OwnProps & WithTranslation

function LoadingModal(props: Props) {
  const { t } = props

  return (
    <Modal>

      <div className="height-20"/>

      <div className="flex grey-t"
        style={{ fontSize : '32px' }}>
        <Space/>
        <FontAwesomeIcon icon={faCircleNotch} spin/>
        <Space/>
      </div>

      <div className="height-20"/>

      <div className="flex grey-t">
        <Space/>
        {t("utils_loading")}
        <Space/>
      </div>

      {props.children}

    </Modal>
  )

}

export default withTranslation()(LoadingModal)