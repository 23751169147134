/**
 * SUPERVISOR.SUBSCRIPTIONS
 * Manage current subscriptions
 */

import { WithTranslation, withTranslation } from "react-i18next"
import PageHeader from "@/components/page-header"
import NavSupervisor from "@/nav/nav.supervisor"
import SubscriptionsListWidget from "@/widgets/subscriptions-list.widget"
import { useState } from "react"
import Tabs from "@/components/tabs"

//Tabs
const TAB_ENTERPRISES = "TAB_ENTERPRISES"
const TAB_CONSULTANTS = "TAB_CONSULTANTS"

function SupervisorSubscriptionsRoute(props: WithTranslation) {
  const { t } = props

  const [currentTab, setCurrentTab] = useState<string>(TAB_ENTERPRISES)

  return (
    <NavSupervisor>
      <PageHeader title={t("subscriptions")}></PageHeader>

      <Tabs
        active={currentTab}
        onClick={(tab) => setCurrentTab(tab)}
        tabs={{
          TAB_ENTERPRISES: "Entreprises",
          TAB_CONSULTANTS: "Consultants"
        }}
      />

      {currentTab === TAB_ENTERPRISES && (
        <div className="flex">
          <SubscriptionsListWidget title="Fin de projet" />
          <div className="width-20" />
          <SubscriptionsListWidget title="Fin de contrat" isEndSubscription />
        </div>
      )}

      {currentTab === TAB_CONSULTANTS && <div></div>}
    </NavSupervisor>
  )
}

export default withTranslation()(SupervisorSubscriptionsRoute)
