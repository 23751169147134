/** 
 * LIST-DROPDOWN
 * Dropdown in list (small version of dropdown)
 */
import { useState } from "react"
import { connect } from "react-redux"
import Style from 'style-it';
import { WithTranslation, withTranslation } from 'react-i18next'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import Space from "./space"
import { Session } from "@/redux/_session.types";
import Button from "./button"
import ContextMenu from "./context-menu"
import ListItem from "./list-item";
import ListButton from "./list-button";

interface StateProps extends WithTranslation{
  _session : Session
}

interface OwnProps{
  active? : any | null //Active id
  color? : string //Couleur of texte
  isBold? : boolean //Text in bold
  isCaretAlwaysVisible? : boolean //Show dropdown icon
  isNullAllowed? : boolean //Display "not defined" in the list
  onAdd? : Function //Function on add
  onClick? : Function //Function on click
  onEdit? : Function //Function on edit
  onSelect : Function //Function on select
  positionBottom? : boolean //Display on top
  positionRight? : boolean //Display on right side
  maxHeight? : number //Height max (overflow)
  value : any //Text to display
  values : any[] //List of values to display
}

type Props = StateProps & OwnProps

function ListDropdown(props:Props) {
  const { t } = props

  //Style : color on hover
  const style = ".list-dropdown-text:hover{ color: " + props._session.accountColors.cta + "; }"

  //Show context menu
  const [isOpen, setIsOpen] = useState(false)

  function add(){
    if (props.onAdd){
      props.onAdd()
    }
  }

  function click(e){
    e.stopPropagation()
    setIsOpen(true)
    if (props.onClick){
      props.onClick()
    }
  }

  function edit(e, item){
    e.stopPropagation()
    if (props.onEdit){
      props.onEdit(item)
    }
  }

  function select(e, value){
    e.stopPropagation()
    props.onSelect(value)
    setIsOpen(false)
  }

  return (
    <div className="list-dropdown flex1">

      <Style>{style}</Style>
      
      <div className={props.isCaretAlwaysVisible ? "list-dropdown-text list-dropdown-text-visible" : "list-dropdown-text"}
        style={{ 
          color : props.color,
          fontWeight : props.isBold ? "bold" : "initial"
        }}
        onClick={click}>

        { props.value?.length 
        ?
        props.value
        :
        <i>
          {t("utils_undefined")}
        </i>
        }

        <FontAwesomeIcon icon={faCaretDown}/>

      </div>

      { isOpen &&
      <ContextMenu onClose={() => setIsOpen(false)}
        maxHeight={props.maxHeight}
        positionRight={props.positionRight}
        positionBottom={props.positionBottom}>

        { props.isNullAllowed &&
        <ListItem isEditable
          isSmall
          isActive={props.active === null}
          onClick={(e) => select(e, { id : null })}>
          {t("utils_undefined")}
        </ListItem>
        }

        <div style={{ 
          maxHeight : 360, 
          overflowY : "auto"
        }}>

          { props.values.map(value =>
          <ListItem key={value.id}
            isEditable
            isSmall
            isActive={props.active === value.id}
            onClick={(e) => select(e, value)}>
            {t(value.name)}
            <Space/>


            { (props.onEdit && value.id) &&
            <ListButton icon={faPencilAlt}
              onClick={(e) => edit(e, value)}
            />
            }

          </ListItem>
          )
          }

        </div>

        { props.onAdd &&
        <div className="flex dropdown-add">
          <Space/>
          <Button className="primary"
            onClick={add}>
            {t("utils_add")}
          </Button>
        </div>
        }

      </ContextMenu>
      }

    </div>
  )

}

const mapStateToProps = state => ({
  _session : state._session
})

export default connect(mapStateToProps)(withTranslation()(ListDropdown))