/**
 * ADM-ADD-ROLE-BUTTON.WIDGET
 * Button to add a new role
 */

import { useState } from "react"
import { WithTranslation, withTranslation } from 'react-i18next';
import Button from "@/components/button";
import { User } from "@/redux/user.types";
import SearchUser from "@/components/search-user";
import UpgradeModal from "@/modals/upgrade.modal";
import AdmEditUserModal from "@/modals/adm-edit-user.modal";
import AdmSelectRoleModal from "@/modals/adm-select-role.modal";

type Props = WithTranslation

//Modals
const MODAL_ADD_USER = 'MODAL_ADD_USER' //Add new user if this one does not exist
const MODAL_SELECT_ROLE = "MODAL_SELECT_ROLE" //Set role for new user
const MODAL_UPGRADE_ROLES = 'MODAL_UPGRADE_ROLES' //Upgrade in order to use feature

function AdmAddRoleWidget(props: Props){
  const { t } = props

  //Modal to display
  const [currentModal, setCurrentModal] = useState<string | null>(null)

  //User name to display
  const [currentUser, setCurrentUser] = useState(new User())

  //Display search user menu
  const [isSearchUserActive, setIsSearchUserActive] = useState(false)

  //Select specific user
  function selectUser(user:User){
    setCurrentUser(user)
    setCurrentModal(user.role ? null : MODAL_SELECT_ROLE)
  }

  return(
    <div className="rel">
      
      { currentModal === MODAL_ADD_USER &&
      <AdmEditUserModal onClose={() => setCurrentModal(null)}
        onNext={(user) => selectUser(user)}
      />
      }

      { currentModal === MODAL_SELECT_ROLE &&
      <AdmSelectRoleModal currentUser={currentUser} onClose={() => setCurrentModal(null)}/>
      }
      
      { currentModal === MODAL_UPGRADE_ROLES &&
      <UpgradeModal feature="roles" isAppLevel
        onClose={() => setCurrentModal(null)}/>
      }

      <Button className="primary"
        onClick={() => setIsSearchUserActive(true)}>
        {t("roles_add")}
      </Button>

      { /** context menu rechercher des utilisateurs */
      isSearchUserActive &&
      <SearchUser onAdd={() => setCurrentModal(MODAL_ADD_USER)}
        onClose={() => setIsSearchUserActive(false)}
        onSelect={(user:User) => selectUser(user)}
      />
      }

    </div>
  )

}

export default withTranslation()(AdmAddRoleWidget)