/**
 * DASHBOARD-HISTORY
 * Evolution in the time
 */

import NavDashboard from "@/nav/nav.dashboard";
import { connect } from "react-redux";
import { Session } from "@/redux/_session.types";
import UpgradeWidget from "@/widgets/upgrade.widget";
import DashboardHistoryWidget from "@/widgets/dashboard-history.widget";

interface StateProps{
  _session: Session
}

type Props = StateProps

function DashboardHistoryRoute(props: Props){

  return(
    <NavDashboard title="dashboard_history">

      { props._session.accountLevel === "free"
      ?
      <UpgradeWidget feature="history" isAppLevel/>
      :
      <DashboardHistoryWidget/>
      }

    </NavDashboard>
  )
}

const mapStateToProps = state => ({
  _session : state._session
})

export default connect(mapStateToProps)(DashboardHistoryRoute)