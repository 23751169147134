/** LIST-CONTAINER
 * Container de liste (avec actions contextuelles)
 */
import { useState } from "react"
import { WithTranslation, withTranslation } from "react-i18next"
import { STATUS_LOADED } from "@/redux/_status.types"
import SearchBar from "./search-bar"
import Button from "./button"
import Space from "./space"
import ListButton from "@/components/list-button";
import { connect } from "react-redux"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBackward, faCaretLeft, faCaretRight, faCog, faForward } from "@fortawesome/free-solid-svg-icons"
import { Session } from "@/redux/_session.types"

interface StateProps extends WithTranslation{
  _session : Session
}

interface OwnProps extends WithTranslation{
  children? : any //Elements à l'intérieur
  countLimit? : number //Nombre max de lignes par pages
  countSearch? : number //Nombre de lignes recherchées
  isFooterActive? : boolean //Afficher le footer
  onAdd? : Function //Fonction au clic sur ajouter
  onAddPrimary? : boolean //Cta for add button
  onAddTitle? : string //Texte du bouton ajouter
  onOption? : Function //Clic sur option button
  onSearch? : Function //Barre de recherche
  onSearchStart? : Function //Fonction au démarrage de la recherche
  onSetPage? : Function //Fonction quand je change de page
  placeholder? : string //Texte à faire afficher si pas de valeurs
  status? : string //Statut à faire afficher (loading, saving...)
  title? : string //Titre pour la liste
}
 
type Props = StateProps & OwnProps

function ListContainer(props:Props) {
  const { t } = props

  //Définir nombre de pages
  //Calculer le nombre de page en fonction de la limite et du total recherché
  const pages = (props.countLimit && props.countSearch) ?
    Math.ceil(props.countSearch / props.countLimit) : 1

  //Page actuelle
  const [currentPage, setCurrentPage] = useState(1)

  //Clic sur le bouton ajouter
  function add(){
    if (props.onAdd){
      props.onAdd()
    }
  }

  //Changer de page
  function changePage(index){
    setCurrentPage(index + 1)
    if (props.onSetPage && props.countLimit){
      props.onSetPage(index * props.countLimit)
    }
  }

  function clickOption(){
    if (props.onOption){
      props.onOption()
    }
  }

  function search(value: string){
    if(props.onSearch){
      changePage(0)
      props.onSearch(value)
    }
  }

  return (
    <div className="list-container">

      { /** header de list-container */}
      <div className="flex list-container-header">

        { props.title &&
        <div className="flex">
          <span style={{ margin : "auto" }}>
            <b>
              {props.title}
            </b>
          </span>
          <div className="width-40"/>
        </div>
        }

        { /** 
         * barre de recherche 
         * Si pas de barre de rechercher alors afficher loader simple
         * */
        props.onSearch
        &&
        <SearchBar onSearch={search} 
          onSearchStart={props.onSearchStart}
          status={props.status ? props.status : STATUS_LOADED} >
        </SearchBar>
        }
        
        <Space/>

        { props.onAdd &&
        <Button className={props.onAddPrimary ? "primary" : "secondary"}
          onClick={add}>
          { props.onAddTitle ? props.onAddTitle : t("utils_add") }
        </Button>
        }

        { props.onOption &&
        <div style={{ marginTop : '16px' }}>
          <ListButton icon={faCog}
            text={t("utils_edit_display")}
            onClick={clickOption}
          />
        </div>
        }
        
      </div>

      { /** afficher texte si pas de valeur */
      (
        props.countSearch === 0 && 
        props.placeholder
      ) &&
      <div className="list-container-placeholder grey-t">
        {props.placeholder}
      </div>
      }
      
      { props.children }

      { /** footer visible si au moins le compte est spécifié */
      props.isFooterActive &&
      <div className="flex list-container-pages">

        { pages > 1 &&
        <div className="flex flex1">
          <Space/>

          { currentPage > 2 &&
          <FontAwesomeIcon 
            onClick={() => changePage(0)} 
            icon={faBackward}
          />
          }
              
          { currentPage > 1 &&
          <FontAwesomeIcon icon={faCaretLeft} 
            onClick={() => changePage(currentPage - 2)}
            style={{ fontSize : '18px' }}
          />
          }

        </div>
        }

        { pages > 1 ? [...Array(pages)].map((e, i) => 
        <div key={i}>
          { ((i > currentPage - 4 && i < currentPage + 2) || (i === pages - 1)) &&
            <div className="list-container-page" onClick={() => changePage(i)}
              style={currentPage === i + 1 ? { fontWeight : 600, color : props._session.accountColors.active } : {}}>
              {i + 1}
            </div>
          }
        </div>
        ) : null
        }

        <div className="flex flex1">

          { pages > 1 &&
          <div className="flex">

            { currentPage < pages &&
            <FontAwesomeIcon onClick={() => changePage(currentPage)}
              icon={faCaretRight} style={{ fontSize : '18px' }}
            />
            }

            { currentPage < pages - 1 &&
            <FontAwesomeIcon onClick={() => changePage(pages - 1)} 
              icon={faForward}
            />
            }

          </div>

          }

          <Space/>

          <div className="grey-t">
            {t("utils_total", { value : props.countSearch })}
          </div>

        </div>


      </div>
      }

    </div>
    
  )
  
}

const mapStateToProps = state => ({
  _session : state._session
})

export default connect(mapStateToProps)(withTranslation()(ListContainer))