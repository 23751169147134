/** 
 * ACCOUNT.REDUCERS
 * Update account
 */

import { Account, AccountState, ACCOUNT_ACTIVATE, ACCOUNT_ADD, ACCOUNT_EDIT, ACCOUNT_GET, ACCOUNT_GET_COUNT, ACCOUNT_GET_COUNT_SEARCH, ACCOUNT_INIT, ACCOUNT_ORDER, ACCOUNT_REMOVE, ACCOUNT_STATUS } from "./account.types";
import { STATUS_LOADED } from "./_status.types";
import { v4 as uuid } from "uuid";
import { Supervisor } from "./supervisor.types";
import { t } from "@/translate/t";

let active:Account

const initialState: AccountState = {
  active : new Account(),
  ascOrDesc : "asc",
  count : 0,
  countSearch : 0,
  list : [],
  order : "name",
  status : STATUS_LOADED
}

export default function accountReducer(
  state = initialState,
  action: any
): AccountState {
  switch (action.type) {

    //Add new account
    case ACCOUNT_ADD:
      active = new Account()
      active.id = uuid()
      active.name = t("account_new")
      active.SupervisorId = action.payload.supervisorId
      active.Supervisor = new Supervisor({
        id : action.payload.supervisorId,
        firstname : action.payload.firstname,
        lastname : action.payload.lastname
      })
      return Object.assign({}, state, { active })

    //Edit active account
    case ACCOUNT_EDIT:
      active = Object.assign(state.active, { [action.payload.key] : action.payload.value })
      return Object.assign({}, state, { 
        active ,
        list : state.list.map(x => x.id === active.id ? active : x)
      })

    //Set as active account
    case ACCOUNT_ACTIVATE:
      return Object.assign({}, state, {
        active : new Account(action.payload.account)
      })

    //Save liste des comptes in reducer
    case ACCOUNT_GET:
      return Object.assign({}, state, {
        list : action.payload.accounts.map((x:any) => new Account(x))
      })

    //Save count total of account
    case ACCOUNT_GET_COUNT:
      return Object.assign({}, state, {
        count : action.payload.count
      })

    //Save count of account searched
    case ACCOUNT_GET_COUNT_SEARCH:
      return Object.assign({}, state, {
        countSearch : action.payload.countSearch
      })

    //Init store
    case ACCOUNT_INIT:
      return Object.assign({}, state, {
        active : new Account(state.active),
        list : state.list.map((x:any) => new Account(x)),
        status : initialState.status
      })

    //Save count of account searched
    case ACCOUNT_ORDER:
      return Object.assign({}, state, {
        ascOrDesc : action.payload.ascOrDesc,
        order : action.payload.order
      })

    //Remove an account
    //Update count
    case ACCOUNT_REMOVE:
      return {
        ...state,
        count : state.count - 1,
        countSearch : state.countSearch - 1,
        list  : state.list.filter(x => x.id !== action.payload.id )
      }

    //Update status
    case ACCOUNT_STATUS:
      return Object.assign({}, state, {
        status : action.payload.status
      })

    default:
      return state

  }

}