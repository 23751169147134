/** 
 * PROJECT-INTRODUCTION.MODAL
 * Describe all steps for a project
 */

import { faCaretDown, faCaretRight, faCaretUp } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import CardMagic from "@/components/card-magic"
import Modal from "@/components/modal"
import Space from "@/components/space"
import { withTranslation, WithTranslation } from "react-i18next"
import { ProjectStatus, PROJECT_STATUS_LIST } from "@/redux/project.types"

interface OwnProps{
  onClose : Function
  status? : ProjectStatus
}

type Props = OwnProps & WithTranslation

function ProjectIntroductionModal(props:Props) {
  const { t } = props

  return (
    <Modal isLarge
      isCloseButtonVisible
      onClose={() => props.onClose()}>

      <div style={{ textAlign : "center" }}>
        <h2>
          {t("project_introduction_title")}
        </h2>
        <p>
          {t("project_introduction_text")}
        </p>
      </div>

      <div className="height-20"/>

      <div className="flex">

        <Space />

        <div>

          <div className="flex">
            { PROJECT_STATUS_LIST.filter((status:ProjectStatus)=> status !== "test").map((status: ProjectStatus, i: number) =>
            <div key={status}
              className="flex">

              <CardMagic key={status}
                isActive={ status === props.status }
                title={t("project_status_" + status)}
                image={require("@/assets/project-status/" + status + ".png")}
                description={t("project_status_" + status + "_description")}
              />

              { i < 2 &&
              <div className="width-40 flex">
                <Space/>
                <FontAwesomeIcon icon={faCaretRight}
                  className="medgrey-t"
                  style={{ 
                    fontSize: 40,
                    marginTop:"-5px"
                  }}
                />
                <Space/>
              </div>
              }

            </div>
            )}
          </div>

          <div style={{width:"140px"}}>

            <div className="flex">

              <Space />

              <div>

              <FontAwesomeIcon icon={faCaretDown}
                  className="medgrey-t"
                  style={{ 
                    fontSize: 40,
                  }}
                />

                <FontAwesomeIcon icon={faCaretUp}
                  className="medgrey-t"
                  style={{ 
                    fontSize: 40,
                  }}
                />

              </div>

              <Space />

            </div>

            <CardMagic
              isActive={ props.status === "test" }
              title={t("project_status_test")}
              image={require("@/assets/project-status/test.png")}
              description={t("project_status_test_description")}
            />

          </div>

        </div>

        <Space/>

      </div>

      <div className="height-20"/>

    </Modal>
  )

}

export default withTranslation()(ProjectIntroductionModal)