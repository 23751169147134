/** 
 * IMPORT-UPLOAD
 * Interface for uploading users
 */
import { useEffect, useState } from "react"
import { saveAs } from "file-saver"
import { withTranslation, WithTranslation } from "react-i18next"
import Button from "@/components/button"
import { USER_FIELDS } from "@/redux/user.types"
import { toast } from 'react-toastify';
import * as XLSX from 'xlsx'
import { store } from "@/index"
import { STATUS_LOADED, STATUS_LOADING } from "@/redux/_status.types"
import Space from "@/components/space"
import UploadButtonWidget from "./upload-button.widget"
import { filterFetchCheck, filterGetImportResponse, filterStatus } from "@/redux/filter.actions"
import { sessionEditAccountOptions } from "@/redux/_session.actions"
import UploadHelp from "@/help/upload.help"
import { faCloudDownloadAlt, faInfoCircle } from "@fortawesome/free-solid-svg-icons"
import { validateEmail } from "@/utils/validate-email.utils"
import Modal from "@/components/modal"
import { v4 as uuid } from "uuid";

interface OwnProps{
  onNext : Function
}

type Props = OwnProps & WithTranslation

const MODAL_HELP = "MODAL_HELP"
const MODAL_EMAIL_WARNING = "MODAL_EMAIL_WARNING"

function AdmImportFileWidget(props:Props) {
  const { t } = props

  //Current modal to display
  const [currentModal, setCurrentModal] = useState<string | null>(null)

  //Emails invalids detection
  const [invalidEmails, setInvalidEmails] = useState<string[]>([])

  //Init loader
  useEffect(() => {
    store.dispatch(filterStatus(STATUS_LOADED))
  }, [])

  //Send data to server to check data
  async function sendFile(data:any){

    //Check file on server side
    store.dispatch(filterStatus(STATUS_LOADING))
    const response:any = await store.dispatch(filterFetchCheck(data))
    store.dispatch(filterStatus(STATUS_LOADED))

    if(response.error){
      toast(t(response.error), { type : 'error' })
    }

    else{

      let invalidEmailList = []

      //Detect email and check if is valid
      //If not display warning
      const emailColumn = response.columnsImport.find(x => x.filterId === "email")
      if (emailColumn){
        invalidEmailList = emailColumn.items.filter(x => !validateEmail(x))
        setInvalidEmails(invalidEmailList)
      }
      
      //Update account informations
      //If there are fields already mapped edit account options
      USER_FIELDS.forEach((item:any) => {
        if (response.accountOptions[item.id + "ImportName"]){
          store.dispatch(sessionEditAccountOptions(item.id + "ImportName", response.accountOptions[item.id + "ImportName"]))
        }else{
          store.dispatch(sessionEditAccountOptions(item.id + "ImportName", null))
        }
      })

      //Save response of filter check in reducer
      store.dispatch(filterGetImportResponse(response))
      
      //Warning message
      if (invalidEmailList.length){
        setCurrentModal(invalidEmailList.length > 0 ? MODAL_EMAIL_WARNING : null)
      }else{
        props.onNext()
      }

    }

  }

  //Generate file to download
  async function download(){
    
    //Search user list
    const users = require("@/assets/users.json")

    //Generate random code
    const id: string = uuid().substring(0, 6)

    //Init file
    const title = t("import_download_list_name")
    const data: string[] = Object.keys(users[0])

    const excelWs: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet([data.map((x: string) => t(x))])
    excelWs['!cols'] = data.map((x: any, i: number) => ({ wch: i === 0 ? 50 : getColumnWidth(x) }))

    // Add worksheet to workbook
    const workbook: any = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, excelWs, title);

    //Add lines
    users.forEach((user: any) => {
      const keys: any = Object.keys(user)
      const excelArray: string[] = new Array(9)
      keys.forEach((x: any, i: number) => {
        if (i === 0){
          if (user.Statut === "ouvrier"){
            excelArray[i] = ""
          }else{
            excelArray[i] = (user[keys[i + 1]] + "." + user[keys[i + 2]] + "@" + id + ".fr").toLowerCase()
          }
        }else{
          excelArray[i] = user[x]
        }
      })
      XLSX.utils.sheet_add_aoa(excelWs, [excelArray], { origin: -1 })
    })

    //Save file
    const wbout: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    saveAs(new Blob([wbout]), title + '.xlsx');

    //Get max value lenght for a column in order to set column width
    //Min width is 10
    function getColumnWidth(column){
      const list = users.map(x => x[column].length)
      const width =  Math.max(...list) * 1.5
      return width < 10 ? 10 : width
    }

  }

  //Proceed to upload file
  function upload(event:any){
    const reader = new FileReader()
    const file = event[0]
    let fileReader:any | null

    if (file){

      const name = file.name

      //Get extension of the uploaded file
      //setExtension(name.substring(name.lastIndexOf(".")))
      const extension : string = name.substring(name.lastIndexOf("."))


      //If CSV read file as text
      if(extension === ".csv"){
        reader.onloadend = (event) => {
          fileReader = event.target!.result
          sendFile(fileReader)
        }

        reader.readAsText(file, 'UTF-8')
      }

      //If excel use XLSX libray
      else if(extension === ".xls" || extension === ".xlsx"){
        reader.onloadend = (event) => {
          fileReader = event.target!.result
          const wb = XLSX.read(fileReader, { type:'binary' })
          const wsname = wb.SheetNames[0]
          const ws = wb.Sheets[wsname]
          const data = XLSX.utils.sheet_to_csv(ws, { FS : ";" })
          sendFile(data)
        }

        reader.readAsBinaryString(file)
      }

      else{
        toast(t("file_not_recognized"), { type : 'error' })
      }
    }
  }

  return (
    <div className="flex" style={{ padding : '16px 32px' }}>

      { currentModal === MODAL_EMAIL_WARNING &&
      <Modal onClose={() => setCurrentModal(null)}
        onNext={() => props.onNext()}
        isCloseButtonVisible>

        <p>
          <b>
          {t("import_invalid_emails_1")}
          </b>
        </p>

        <ul>
          { invalidEmails.map((email, i) =>
          <li key={i}>
            {email}
          </li>
          )
          }
        </ul>

      </Modal>
      }

      { currentModal === MODAL_HELP &&
      <UploadHelp onClose={() => setCurrentModal(null)}/>
      }

      <div className="flex flex-dcol"
        style={{ 
          paddingRight : '40px',
          width : '400px' 
        }}>
        
        <Space/>

        <p>{t("adm_upload_text")}</p>
        <p><b>{t("adm_upload_accepted_files")}</b></p>

        <Space/>

        <div className="flex">
          <Button isWithBorder
            onClick={download}
            icon={faCloudDownloadAlt}>
            {t("adm_upload_btn_download")}
          </Button>
          <Button icon={faInfoCircle} onClick={() => setCurrentModal(MODAL_HELP)}>
            {t("utils_find_more")}
          </Button>
        </div>

        <Space/>

      </div>

      <UploadButtonWidget
        acceptedFiles=".csv,.xls,.xlsx"
        onUpload = {(data) => upload(data)}
      />

    </div>

  )
}

export default withTranslation()(AdmImportFileWidget)