/**
 * PAGE.REDUCERS
 * Edit page state
 */

import {
  Page,
  PageState,
  PAGE_ACTIVATE,
  PAGE_ADD,
  PAGE_EDIT,
  PAGE_INIT,
  PAGE_GET,
  PAGE_REMOVE,
  PAGE_STATUS,
  PAGE_REPLACE,
  PAGE_CHANGE
} from "./page.types"
import { STATUS_LOADED } from "./_status.types"
import { v4 as uuid } from "uuid"

let active: Page
let pageIndex: number = 0

const initialState: PageState = {
  active: new Page(),
  list: [],
  status: STATUS_LOADED
}

export default function pageReducer(state = initialState, action: any): PageState {
  switch (action.type) {
    //activate page
    case PAGE_ACTIVATE:
      return Object.assign({}, state, {
        active: new Page(action.payload.page)
      })

    //Add new template
    //Add page at specific index
    case PAGE_ADD:
      active = new Page(action.payload.page)
      if (!action.payload.page.id) {
        active.id = uuid()
      }
      return Object.assign({}, state, {
        active,
        list: state.list
          .slice(0, action.payload.index + 1)
          .concat([active])
          .concat(state.list.slice(action.payload.index + 1))
      })

    //Change page (find next or previous)
    case PAGE_CHANGE:
      pageIndex = state.list.findIndex((x) => state.active.id === x.id)
      active = state.list[pageIndex + (action.payload.goNext ? 1 : -1)]
      return Object.assign({}, state, {
        active: active ? active : state.active
      })

    //editing page
    case PAGE_EDIT:
      active = Object.assign(state.active, {
        [action.payload.key]: action.payload.value,
        status: state.active.status === "saved" ? "edited" : state.active.status
      })
      return Object.assign({}, state, {
        active,
        list: state.list.map((x) => (x.id === active.id ? active : x))
      })

    //Init store
    case PAGE_INIT:
      return Object.assign({}, state, {
        active: new Page(state.active),
        status: state.status
      })

    //Save list of learning in the store
    //Update count for each page
    case PAGE_GET:
      /*list: state.list.map(
        (a) => new Page({ ...a, count: action.payload.allPages.filter((b) => b.id === a.id).length })
      )*/
      return {
        ...state,
        list: action.payload.pages.map((x) => new Page(x))
      }

    //Remove a page
    case PAGE_REMOVE:
      return Object.assign({}, state, {
        list: state.list.filter((x) => x.id !== action.payload.id)
      })

    //Replace existing item
    case PAGE_REPLACE:
      return Object.assign({}, state, {
        active: new Page(action.payload.page),
        list: state.list.map((x: Page) => (x.id === action.payload.page.id ? action.payload.page : x))
      })

    //Set status
    case PAGE_STATUS:
      return {
        ...state,
        status: action.payload.status
      }

    default:
      return state
  }
}
