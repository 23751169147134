/**
 * ACCOUNT-OPTIONS.MODAL
 * Edit account options
 */

import Link from "@/components/link"
import Modal from "@/components/modal"
import ModalConfirm from "@/components/modal-confirm"
import Space from "@/components/space"
import { store } from "@/index"
import { useState } from "react"
import { withTranslation, WithTranslation } from "react-i18next"
import { connect } from "react-redux"
import { Session } from "@/redux/_session.types"
import { filterUpdateArchive_AsSupervisor } from "@/redux/filter.actions"
import { surveyUpdateRpsTags, surveyUpdateTemplate } from "@/redux/survey.actions"
import { SurveyState } from "@/redux/survey.types"
import AccountOptionsWidget from "@/widgets/account-options.widget"

interface StateProps {
  _session: Session
  survey: SurveyState
}

interface OwnProps {
  onClose: Function
}

type Props = StateProps & OwnProps & WithTranslation

const UPDATE_FILTERS_MODAL = "UPDATE_FILTERS_MODAL"
const UPDATE_FILTER_LOADING_MODAL = "UPDATE_FILTER_LOADING_MODAL"
const UPDATE_RPS_TAGS = "UPDATE_RPS_TAGS"
const UPDATE_TEMPLATE = "UPDATE_TEMPLATE"

function AccountOptionsModal(props: Props) {
  const { t } = props

  const [currentModal, setCurrentModal] = useState<null | string>(null)

  function updateFilters() {
    setCurrentModal(UPDATE_FILTER_LOADING_MODAL)
    store.dispatch(filterUpdateArchive_AsSupervisor(props.survey.active.id))
  }

  function updateRpsTags() {
    setCurrentModal(UPDATE_FILTER_LOADING_MODAL)
    store.dispatch(surveyUpdateRpsTags(props.survey.active.id))
  }

  function updateTemplate() {
    setCurrentModal(UPDATE_FILTER_LOADING_MODAL)
    store.dispatch(surveyUpdateTemplate(props.survey.active.id))
  }

  return (
    <Modal
      isCloseButtonVisible
      disableClickOutside
      title={t("account_settings_preferences_display")}
      onClose={() => props.onClose()}>
      {currentModal === UPDATE_FILTER_LOADING_MODAL && (
        <Modal onClose={() => setCurrentModal(null)} isCloseButtonVisible>
          {"L'opération est en cours elle peut prendre quelques minutes."}
        </Modal>
      )}

      {currentModal === UPDATE_FILTERS_MODAL && (
        <ModalConfirm
          onNo={() => setCurrentModal(null)}
          onYes={() => updateFilters()}
          text="Les filtres personnalisés vont être mis à jour à partir du dernier fichier des collaborateurs importé."
          textBold={t("utils_next_ask")}
        />
      )}

      {currentModal === UPDATE_RPS_TAGS && (
        <ModalConfirm
          onNo={() => setCurrentModal(null)}
          onYes={() => updateRpsTags()}
          text="Les tags RPS vont être mis à jour pour l'enquête"
          textBold={t("utils_next_ask")}
        />
      )}

      {currentModal === UPDATE_TEMPLATE && (
        <ModalConfirm
          onNo={() => setCurrentModal(null)}
          onYes={() => updateTemplate()}
          text="Le questionnaire va être mis à jour pour l'enquête"
          textBold={t("utils_next_ask")}
        />
      )}

      <div style={{ marginLeft: "-30px" }}>
        <AccountOptionsWidget isSmall />

        {props._session.connectedAsSupervisor && (
          <div>
            <div className="flex">
              <Space />
              <Link onClick={() => setCurrentModal(UPDATE_FILTERS_MODAL)}>
                Mettre à jour les filtres personnalisés (Gestionnaire IDTree uniquement)
              </Link>
              <Space />
            </div>
            <div className="flex">
              <Space />
              <Link onClick={() => setCurrentModal(UPDATE_RPS_TAGS)}>
                Mettre à jour les tags RPS (Gestionnaire IDTree uniquement)
              </Link>
              <Space />
            </div>
            <div className="flex">
              <Space />
              <Link onClick={() => setCurrentModal(UPDATE_TEMPLATE)}>
                Mettre à jour le questionnaire (Gestionnaire IDTree uniquement)
              </Link>
              <Space />
            </div>
          </div>
        )}

        <div className="height-100" />
      </div>
    </Modal>
  )
}

const mapStateToProps = (state) => ({
  _session: state._session,
  survey: state.survey
})

export default connect(mapStateToProps)(withTranslation()(AccountOptionsModal))
