/** 
 * BILLINGS-WIDGET
 * bills list for an account
 */

import { connect } from "react-redux"
import { withTranslation, WithTranslation } from "react-i18next"
import { faCheck, faFileInvoice, faTimes } from "@fortawesome/free-solid-svg-icons"
import ListItem from "@/components/list-item"
import Space from "@/components/space"
import { Session } from "@/redux/_session.types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Subscription } from "@/redux/subscription.types"
import ListFields from "@/components/list-fields"
import ListCell from "@/components/list-cell"
import env from "@/env"
import ReactTooltip from 'react-tooltip'


interface StateProps extends WithTranslation{
  _session : Session
}

interface OwnProps{
  billings : Subscription[]
  showPaymentStatus? : boolean
  title? : string
}

type Props = StateProps & OwnProps


function BillingsWidget(props: Props){
  const { t } = props

  return(
    <div>

      {props.title &&
      <h3>
        {props.title}
      </h3>
      }

      <ListFields>
        <ListCell width={120}>
          {t("utils_date")}
        </ListCell>
        <ListCell width={120}>
          {t("utils_description")}
        </ListCell>
        <Space/>
        <ListCell width={120}>
          {t("utils_price")}
        </ListCell>
        {props.showPaymentStatus &&
        <ListCell width={50}>
          {t("utils_paid")}
        </ListCell>
        }
        <ListCell width={50}>
          {t("bill")}
        </ListCell>
      </ListFields>

      { props.billings.map((item: Subscription) =>
      <ListItem key={item.id}>
        <ListCell width={120}>
          {item.dateStartLabel}
        </ListCell >
        <ListCell width={120}>
          {item.text}
        </ListCell>
        <Space/>
        <ListCell width={120}>
          {item.price + "€"}
        </ListCell>
        {props.showPaymentStatus &&
        <ListCell width={50}>
          <div>
            {item.paid ?
            <FontAwesomeIcon icon={faCheck} />
            :
            <FontAwesomeIcon icon={faTimes} />
            }
          </div>
        </ListCell>
        }
        <ListCell width={50}>
          <div>
            {item.bill ?
            <FontAwesomeIcon
              icon={faFileInvoice}
              className="_hover"
              onClick={() => window.open(`${env.REACT_APP_URL_SPACE}/Bills/${props._session.accountId}/${item.id}.pdf?${new Date().getTime()}`, "_blank")}
              data-tip={t("bill_download")}
            />
            :
            <FontAwesomeIcon icon={faFileInvoice} color="#e9e9e9" data-tip={t("bill_not_available")} />
            }
          </div>
        </ListCell>
      </ListItem>
      )}

      <ReactTooltip />

    </div>
  )
}

const mapStateToProps = state => ({
  _session : state._session
})

export default connect(mapStateToProps)(withTranslation()(BillingsWidget))