/**
 * MODAL-CONFIRM
 * Open dialog (YES/NO)
 */
import { WithTranslation, withTranslation } from "react-i18next"
import Space from "./space"
import Button from "./button"
import { STATUS_SAVING } from "@/redux/_status.types"

interface OwnProps{
  status? : string
  onNo : Function
  onYes : Function
  text? : string
  textBold : string
}

type Props = OwnProps & WithTranslation

function ModalConfirm(props: Props) {
  const { t } = props
  
  return (
    <div className="modal-container flex">

      <div className="modal modal-confirm">

        { props.text &&
        <p>{ props.text }</p>
        }

        { props.textBold &&
        <p>
          <b>{ props.textBold }</b>
        </p>
        }

        <div className="height-20"/>

        <div className="flex">
          <Space/>

          <Button onClick={() => props.onNo()}>
            {t("utils_no")}
          </Button>

          <Button className="primary"
            isLoading={props.status === STATUS_SAVING}
            onClick={() => props.onYes()}>
            {t("utils_yes")}
          </Button>

        </div>

      </div>

    </div>
  )
}

export default withTranslation()(ModalConfirm)