/**
 * AUTH.ACTIONS
 * Actions for authentication 
 */

export const _authEmail = id  => ({
  type : 'API',
  payload : {
    method : "POST",
    url : "/_auth/email",
    data : {
      id
    }
  }
})

export const _authPassword = email  => ({
  type : 'API',
  payload : {
    method : "POST",
    url : "/_auth/password",
    data : {
      email
    }
  }
})

export const _authReporting = () => ({
  type : 'ARCHIVE',
  payload : {
    url : "/auth"
  }
})

export const _authSsoChallenge = (org: string) => ({
  type : 'API',
  payload : {
    method : "POST",
    url : "/_auth/sso/challenge",
    data : {
      org
    }
  }
})

export const _authSsoAuthenticate = (code:string) => ({
  type : 'API',
  payload : {
    method : "POST",
    url : "/_auth/sso/authenticate",
    data : {
      code
    }
  }
})

export const _authSupervisor = (email, code) => ({
  type : 'API',
  payload : {
    method : "POST",
    url : "/_auth/supervisor",
    data : {
      email,
      code
    }
  }
})