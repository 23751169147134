/***
 * PROJECT-FILTERS.WIDGET
 * Check if filters are ok
 */

import { connect } from "react-redux";
import ConfirmButtons from "@/components/confirm-buttons";
import DashboardHeaderWidget from "./dashboard-header.widget";
import DashboardOverviewWidget from "./dashboard-overview.widget";
import { ProjectState } from "@/redux/project.types";
import { withTranslation, WithTranslation } from "react-i18next";
import ListItem from "@/components/list-item";
import ProjectStepErrorsWidget from "./project-step-errors.widget";
import Checkbox from "@/components/checkbox";
import Space from "@/components/space";
import { Filter, FilterState } from "@/redux/filter.types";
import { useState } from "react";
import { store } from "@/index";
import { filterActivateAndEdit } from "@/redux/filter.actions";
import Link from "@/components/link";

interface StateProps extends WithTranslation{
  filter : FilterState
  project : ProjectState
}

interface OwnProps{
  onEdit : Function
  onNext: Function
}

type Props = StateProps & OwnProps

function ProjectFiltersWidget(props:Props){
  const { t } = props

  const filters: Filter[] = props.filter.list.filter((x: Filter) => !x.isTemplateFilter)
  const templateFilters: Filter[] = props.filter.list.filter((x: Filter) => x.isTemplateFilter)

  const [isChecked, setIsChecked] = useState(filters.every((x: Filter) => x.isChecked))

  function check(){
    setIsChecked(!isChecked)
    filters.filter((x: Filter) => !x.isChecked).forEach((filter: Filter) => {
      store.dispatch(filterActivateAndEdit(filter, "isChecked", true))
    })
  }

  return (
    <div>

      <ListItem>
        {t("project_filters_help")}
      </ListItem>

      <div style={{ margin : "0px 32px" }}>

        <div style={{ zIndex : 10 }}
          className="rel">
          <DashboardHeaderWidget isPreview/>
        </div>
        
        <div className="rel">

          <div className="abs"
            style={{
              zIndex : 9,
              backgroundColor: "#ffffff61",
              width: "100%",
              height: "100%"
            }}/>

          <DashboardOverviewWidget isPreview/>

        </div>

      </div>

      <ListItem>
        <div>

          <p>
            {t("project_filters_help_1")}
          </p>

          <p className="red-t">
            <b>
              {t("project_filters_help_1a")}
            </b>
          </p>

          <div className="flex">
            <span className="grey-t">
              {t("project_filters_reset")}
            </span>
            &nbsp;
            <Link isWithoutMargin
              onClick={() => props.onEdit()}>
              {t("user_import_manual_btn_back")}
            </Link>
          </div>

          <div className="height-20"/>

          { filters.length > 0 &&
          <div className="flex">
            <Checkbox active={isChecked}
              onClick={check}
            />
            <div className="width-10"/>
            <div>
              { filters.length === 0 
              ? 
              <b>{t("project_filters_confirm_empty")}</b>
              : 
              <div>
                <b>
                  {" " + t("project_filters_confirm", {
                    count : filters.length,
                    s : filters.length > 1 ? "s" : ""
                  })}
                </b>

                <span>
                  &nbsp;
                  ({filters.map((x: Filter) => x.name).join(", ")})
                </span>
              </div>
              }
            </div>


            <Space/>

          </div>
          }

          {templateFilters.length > 0 &&
          <div>
            <div className="height-20"/>
              <b>
                {t("template_filters" + (templateFilters.length > 1 ? "_multiple" : "_unique"), { count : templateFilters.length })}
              </b>
              <span>
              &nbsp;
              ({templateFilters.map((x: Filter) => x.name).join(", ")})
            </span>
            </div>
          }

        </div>
      </ListItem>
      
      <ProjectStepErrorsWidget alert={props.project.active.alerts.filters} step="filters"/>

      { props.onNext &&
      <ConfirmButtons onCancel={() => props.onNext(false, false)}
        onConfirm={() => props.onNext(true, true)}
        onConfirmLater={() => props.onNext(true, false)}
      />
      }

    </div>
  )
}

const mapStateToProps = state => ({
  filter : state.filter,
  project : state.project
})

export default connect(mapStateToProps)(withTranslation()(ProjectFiltersWidget))