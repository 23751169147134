/** 
 * DASHBOARD-PARTICIPATION-PROGRESS.WIDGET
 * View survey participation progress
 */

import { withTranslation, WithTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { countBy, orderBy } from "lodash";
import { DateType, SurveyInvitation } from "./dashboard-participation.widget";
import Plot from "react-plotly.js";
import PageLoader from "@/components/page-loader";
import { STATUS_LOADING } from "@/redux/_status.types";
import { Session } from "@/redux/_session.types";
import { connect } from "react-redux";

interface StateProps{
  _session : Session
}

interface OwnProps{
  invitations: SurveyInvitation[],
  selectedDateType: DateType
}

type Props = StateProps & OwnProps & WithTranslation


function DashboardParticipationProgressWidget(props:Props) {
  const { t } = props

  const [isLoading, setIsLoading] = useState<boolean>(true)

  const [data, setData] = useState<Plotly.Data[]>([])

  useEffect(() => {

    function sumItems(array: number[]): number[]{
      let sum: number = 0
      return array.map((x: number) => sum = (sum || 0) + x)
    }

    function formateData(){

      setIsLoading(true)

      const selectedDateType: DateType = props.selectedDateType
      const filteredInvitations: SurveyInvitation[] = props.invitations.filter((x: SurveyInvitation) => x[selectedDateType])

      const items: object = countBy(orderBy(filteredInvitations, selectedDateType).map((x: SurveyInvitation)=>(new Intl.DateTimeFormat(undefined, {}).format(new Date(x[selectedDateType]!)))))

      setData([
        {
          x: Object.keys(items),
          y: sumItems(Object.values(items)).map((x: number) => (x / props.invitations.length * 100).toFixed(0)),
          name: "% " + t("participation_cumulated"),
          type: "scatter",
          line: {
            color: props._session.accountColors.brandPrimary,
            shape: "spline"
          }
        }, {
          x: Object.keys(items),
          y: Object.values(items).map((x: number) => (x / props.invitations.length * 100).toFixed(0)),
          name: "% " + t("participation_daily"),
          type: "bar",
          marker: {
            color: "#88888888",
            line: {
              color: "#888888",
              width: 1.5
            }
          }
        }
      ])

      setIsLoading(false)
    }

    if(props.invitations.length > 0){
      formateData()
    }
    else{
      setIsLoading(false)
    }

  }, [
    t,
    props.invitations,
    props.selectedDateType,
    props._session.accountColors.brandSecondary,
    props._session.accountColors.brandPrimary
  ])


  return (
    <PageLoader status={isLoading ? STATUS_LOADING : null}>
      {props.invitations.length > 0 ?
      <Plot
        data={data}
        layout={{
          height : 350,
          //width: 650,
          font: {
            size: 10,
            color: "#111C2B"
          },
          legend: {
            x: 1,
            xanchor: 'right',
            y: 0.5
          },
          margin: {
            t: 40,
            b: 80,
            l: 50,
            r: 50
          }
        }}

        config={{
          displayModeBar: false
        }}
      />
      :
      <div>{t("disparity_level_0")}</div>
      }
    </PageLoader>
  )

}

const mapStateToProps = state => ({
  _session : state._session
})

export default connect(mapStateToProps)(withTranslation()(DashboardParticipationProgressWidget))