/** 
 * BOOKMARK.ACTIONS
 */

import { Bookmark, BOOKMARK_ACTIVATE, BOOKMARK_ADD, BOOKMARK_GET, BOOKMARK_INIT, BOOKMARK_REMOVE, BOOKMARK_SELECT, BOOKMARK_STATUS, BOOKMARK_SWAP } from "./bookmark.types"
import { surveyEdit, surveyUpdateBookmark } from "./survey.actions"


//REDUX

export const bookmarkActivate = (bookmark:Bookmark) => ({
  type : BOOKMARK_ACTIVATE,
  payload : {
    bookmark
  }
})

export const bookmarkAdd = (bookmark:Bookmark) => ({
  type : BOOKMARK_ADD,
  payload : {
    bookmark
  }
})

export const bookmarkGet = (bookmarks:Bookmark[]) => ({
  type : BOOKMARK_GET,
  payload : {
    bookmarks
  }
})

export const bookmarkInit = () => ({
  type : BOOKMARK_INIT
})

export const bookmarkRemove = (id:string) => ({
  type : BOOKMARK_REMOVE,
  payload : {
    id
  }
})

export const bookmarkSelect = (bookmarkId:string) => ({
  type : BOOKMARK_SELECT,
  payload : {
    bookmarkId
  }
})

export const bookmarkStatus = (status:string) => ({
  type : BOOKMARK_STATUS,
  payload : {
    status
  }
})

export const bookmarkSwap = (sourceId, destinationId) => ({
  type : BOOKMARK_SWAP,
  payload : {
    sourceId,
    destinationId
  }
})


//API

export const bookmarkInsert = (bookmark:Bookmark, surveyId:string, isSurveyGroup:boolean) => ({
  type : 'API',
  payload : {
    method : "POST",
    url : "/bookmark/insert",
    data : {
      bookmark,
      surveyId,
      isSurveyGroup
    }
  }
})

export const bookmarkDestroy = (id:string) => ({
  type : 'API',
  payload : {
    method : "DELETE",
    url : "/bookmark/" + id
  }
})

export const bookmarkFetch = (surveyId:string, userId:string) => ({
  type : 'API',
  payload : {
    method : "GET",
    url : "/bookmarks",
    data : {
      surveyId,
      userId
    }
  }
})


//THUNK


export const bookmarkSwapAndUpdate:any = (sourceId, destinationId) => async (dispatch, getState) => {
  dispatch(bookmarkSwap(sourceId, destinationId))
  const bookmarkIds = getState().bookmark.list.map(x => x.id)
  dispatch(surveyEdit("bookmarksOrder", bookmarkIds))
  dispatch(surveyUpdateBookmark(getState().survey.active.id, bookmarkIds))
}
