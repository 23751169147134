import { memo } from 'react';
import { bounceIn, fadeIn, fadeOut, flipInY, slideInLeft, slideInRight, slideOutRight, rotateInDownLeft, zoomIn, slideInUp, slideOutDown } from 'react-animations'
import styled, { Keyframes, keyframes } from 'styled-components'

const bounceInAnimation: Keyframes = keyframes`${bounceIn}`
const fadeInAnimation: Keyframes = keyframes`${fadeIn}`
const fadeOutAnimation: Keyframes = keyframes`${fadeOut}`
const flipInYAnimation: Keyframes = keyframes`${flipInY}`
const slideInLeftAnimation: Keyframes = keyframes`${slideInLeft}`
const slideInRightAnimation: Keyframes = keyframes`${slideInRight}`
const slideInTopAnimation: Keyframes = keyframes`${slideInUp}`
const slideOutRightAnimation: Keyframes = keyframes`${slideOutRight}`
const slideOutDownAnimation: Keyframes = keyframes`${slideOutDown}`
const rotateInDownLeftAnimation: Keyframes = keyframes`${rotateInDownLeft}`
const zoomInAnimation: Keyframes = keyframes`${zoomIn}`

export const BounceIn: any = memo(styled.div`animation: 3s ${bounceInAnimation}`)
export const FadeIn: any = memo(styled.div`animation: 1s ${fadeInAnimation}`)
export const FadeOut: any = memo(styled.div`animation: 2s ${fadeOutAnimation}`)
export const FlipInY: any = memo(styled.div`animation: 1s ${flipInYAnimation}`)
export const SlideInLeft: any = memo(styled.div`animation: 1s ${slideInLeftAnimation}`)
export const SlideInRight: any = memo(styled.div`animation: 1s ${slideInRightAnimation}`)
export const SlideInTop: any = memo(styled.div`animation: 1s ${slideInTopAnimation}`)
export const SlideOutRight: any = memo(styled.div`animation: 1s ${slideOutRightAnimation}`)
export const SlideOutDown: any = memo(styled.div`animation: 1s ${slideOutDownAnimation}`)
export const RotateInDownLeft: any = memo(styled.div`animation: 1s ${rotateInDownLeftAnimation}`)
export const ZoomIn: any = memo(styled.div`animation: 1s ${zoomInAnimation}`)
export const SlideIn: any = memo(styled.div`animation: 1s ${(a: any) => (a.isLeft ? slideInLeftAnimation : slideInRightAnimation)}`)