/** 
 * TASKS-DESCRIPTION.WIDGET
 * Get all info about the action plan when active
 */

import { withTranslation, WithTranslation } from "react-i18next"
import { connect } from "react-redux"
import { ActionPlanState } from "@/redux/action-plan.types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"
import { useState } from "react"
import Modal from "@/components/modal"

interface StateProps extends WithTranslation{
  actionPlan : ActionPlanState
}

const MODAL_CONTACT: string = "MODAL_CONTACT"

function TasksDescriptionWidget(props:StateProps) {
  const { t } = props

  //Modal
  const [currentModal, setCurrentModal] = useState<null | string>(null)

  return (
    <div>

      { currentModal === MODAL_CONTACT &&
      <Modal isCloseButtonVisible
        onClose={() => setCurrentModal(null)}
        title={t("tasks_contact")}>
        <p>
          {props.actionPlan.active.UserContact?.username}
        </p>
        <p>
          <b>{t("user_email")}</b> : {props.actionPlan.active.UserContact?.email}
        </p>
      </Modal>
      }
      
      <div className="flex" 
        style={{ marginBottom : 22 }}>

        <div className="flex1 grey-t">

          { props.actionPlan.active.priority &&
          <div style={{ marginBottom : 6 }}>
            🎯 <b>{t("tasks_priority")}</b> : {props.actionPlan.active.priority}
          </div>
          }
          
          { props.actionPlan.active.expectedOutcome &&
          <div style={{ marginBottom : 6 }}>
            🏅 <b>{t("tasks_expected_outcome")}</b> : {props.actionPlan.active.expectedOutcome}
          </div>
          }

          { props.actionPlan.active.UserContact &&
          <div style={{ marginBottom : 6 }}>
            👋 <b>{t("tasks_contact")}</b> 
            &nbsp;
            : 
            &nbsp;
            <span className="_hover" onClick={() => setCurrentModal(MODAL_CONTACT)}>
              {props.actionPlan.active.UserContact?.username}
            </span> 
         </div>
          }

        </div>

        <div className="flex"
          style={{ height: "fit-content" }}>

          { props.actionPlan.active.dateStart &&
          <div className="tasks-date">
            <div className="tasks-date-title grey-t">{t("tasks_date_start_short")}</div>
            <div className="tasks-date-month">
              {new Date(props.actionPlan.active.dateStart).toLocaleString('default', { day : "2-digit", month : "long" })}
            </div>
          </div>
          }

          { (props.actionPlan.active.dateEnd && props.actionPlan.active.dateStart) &&
          <FontAwesomeIcon icon={faArrowRight}
            className="flex flex-auto grey-t"
            style={{ marginLeft : 20, fontSize : 18 }}
          />
          }

          { props.actionPlan.active.dateEnd &&
          <div className="tasks-date">
            <div className="tasks-date-title grey-t">{t("tasks_date_end_short")}</div>
            <div className="tasks-date-month">
              {new Date(props.actionPlan.active.dateEnd).toLocaleString('default', { day : "2-digit", month : "long" })}
            </div>
          </div>
          }

        </div>

      </div>
    </div>
  )

}

const mapStateToProps = state => ({
  actionPlan : state.actionPlan
})

export default connect(mapStateToProps)(withTranslation()(TasksDescriptionWidget))