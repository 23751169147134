/**
 * FORMAT-DATE.UTILS
 * Replace occurence for email
 */

import i18next from "i18next"
import { DEFAULT_FILTERS } from "@/redux/filter.types"

const getFilterName = (filterName: string) => {

  if (filterName === "company") {
    return i18next.t("user_company_alt")
  } else if (DEFAULT_FILTERS.map((x: any) => x.id).includes(filterName)) {
    return i18next.t("user_" + filterName + "_alt")
  } else {
    return filterName
  }

}

export default getFilterName

