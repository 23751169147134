/** 
 * LIST-ITEM
 * Item of list
 */

import { connect } from "react-redux"
import { Session } from "@/redux/_session.types"


 interface StateProps{
  _session : Session
}

interface OwnProps{
  children : any //Content inside
  bgColor? : string | null //Display specific bg color
  isActive? : boolean //Is element active
  isEditable? : boolean //Is element editable
  isSmall? : boolean //Display list in small
  onClick? : Function //Action onClick
  itemId?: string
}

type Props = StateProps & OwnProps

function ListItem(props:Props) {

  //Action onClick
  function click(e){
    if (props.onClick){
      props.onClick(e)
    }
  }

  //ClassName
  function getClassName(){
    let className = "flex flex1 list-item"
    if (!props.isSmall) className = className + " list-item-large"
    if (props.isEditable) className = className + " list-item-editable"
    return className
  }

  //Style
  function getStyle(){
    let backgroundColor
    let borderColor
    let color

    if (props.bgColor){
      backgroundColor = props.bgColor
      borderColor = props.bgColor
    }

    if (props.isActive){
      color = props._session.accountColors.active
    }


    return {
      backgroundColor,
      borderColor,
      color
    }

  }

  return (
    <div id={props.itemId}
      className={getClassName()}
      style={getStyle()}
      onClick={click}>
      {props.children}
    </div>
  )

}

const mapStateToProps = state => ({
  _session : state._session
})

export default connect(mapStateToProps)(ListItem)