/** 
 * NAV-TAB
 * Tab to navigate beetween pages of the dashboard
 */
import { IconDefinition } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useState } from "react"
import { connect } from "react-redux"
import { Session } from "@/redux/_session.types"
import CSS from 'csstype';

interface StateProps{
  _session : Session
}

interface OwnProps{
  id : string
  icon : IconDefinition
  isActive : boolean
  label : string
  onClick : Function
}

type Props = StateProps & OwnProps

type RoundedBorderDirection = "left" | "right"

function NavTab(props:Props) {

  const [isLabelVisible, setIsLabelVisible] = useState(props.isActive)

  function getContainerClassName(){
    let className = "rel grey-t dashboard-tab-container"

    if (isLabelVisible){
      className += " dashboard-tab-open"
    }

    if (props.isActive){
      className += " lightwhite"
    }

    return className

  }

  function getContainerStyle(){
    const style:CSS.Properties = {}

    if (props.isActive){
      style.marginBottom = "-4px"
      style.color = props._session.accountColors.active
    }

    if (isLabelVisible){
      let width = props.label.length * 8 + 74
      if (width < 150) width = 150
      style.width = width + "px"
    }


    return style

  }

  function getRoundedBorder(direction:RoundedBorderDirection){
    const style:CSS.Properties = {}
    const styleBack:CSS.Properties = {}
    const styleFade:CSS.Properties = {}

    if (direction === "left"){
      style.left = "-8px"
      styleBack.left = "-6px"
      styleFade.left = "-108px"
    }else{
      style.right = "-9px"
      style.transform = "rotate(90deg)"
      styleBack.right = "-6px"
      styleFade.right = "-108px"
      styleFade.transform = "rotate(180deg)"
    }

    return (
      <div>
        <div style={styleBack} className="dashboard-rounded-border-back abs lightwhite"/>
        <div style={style} className="dashboard-rounded-border abs medgrey-b"/>
        <div style={styleFade} className="dashboard-rounded-border-fade abs"/>
      </div>
    )

  }

  function getStyle(){
    const style:CSS.Properties = {}

    if (props.isActive){
      style.border = "1px solid"
      style.borderBottom = "none"
    }

    return style

  }

  return (
    <div id={props.id.replaceAll("/", "_")}
      className={getContainerClassName()}
      onClick={() => props.onClick()}
      onMouseEnter={() => setIsLabelVisible(true)}
      onMouseLeave={() => setIsLabelVisible(props.isActive ? true : false)}
      style={getContainerStyle()}>
      <div className="dashboard-tab medgrey-b _hover flex rel"
        style={getStyle()}>

        <FontAwesomeIcon icon={props.icon}/>

        { isLabelVisible &&
        <div className="dashboard-tab-text">
          {props.label}
        </div>
        }

        { //Vertical separator for inactive tab
        !props.isActive &&
        <div className="dashboard-tab-separator abs medgrey-b"/>
        }

      </div>

      { // Rounded border for active tab (like google chrome tab)
      props.isActive &&
      getRoundedBorder("left")
      }

      { // Idem for other side
      props.isActive &&
      getRoundedBorder("right")
      }

    </div>
  )

}

const mapStateToProps = state => ({
  _session : state._session
})

export default connect(mapStateToProps)(NavTab)