/**
 * 
 * DASHBOARD-SUMMARIZE
 * summarize survey messages with chatGPT
 * 
 */
import { useState } from "react";
import ConsentOpenaiWidget from "./consent-openai.widget";
import GptDisplayerWidget from "./gpt-displayer.widget";
import { Question } from "@/redux/question.types";
import { Session } from "@/redux/_session.types";
import { connect } from "react-redux";

interface StateProps{
  _session : Session
}

interface OwnProps{
  comments: string[]
  currentQuestion : Question
}
 
type Props = OwnProps & StateProps

function DashboardSummarizeWidget(props: Props){

  const [comments] = useState(props.currentQuestion.isPromptCustom && props.currentQuestion.prompt ? [] : props.comments)
  const [isPromptCustom] = useState(props.currentQuestion.isPromptCustom)
  const [questionLabel] = useState(props.currentQuestion.label)
  const [prompt] = useState(props.currentQuestion.getPromptFormatted(comments, props._session.userInitialLanguage))

  return(
    <ConsentOpenaiWidget>

      <GptDisplayerWidget
        destination={isPromptCustom ? "ask" : "summarize"}
        params={{
          comments,
          questionLabel,
          prompt
        }}
        language={props._session.language}
      />
        
    </ConsentOpenaiWidget>
  )
}

const mapStateToProps = state => ({
  _session: state._session
})

export default connect(mapStateToProps)(DashboardSummarizeWidget)