/** 
 * MESSAGE.REDUCERS
 * Edit message
 */

import { Message, MessageState, MESSAGE_GET, MESSAGE_GET_COUNT, MESSAGE_STATUS } from "./message.types";
import { STATUS_LOADED } from "./_status.types";

const initialState: MessageState = {
  count : 0,
  list : [],
  status : STATUS_LOADED
}

export default function messageReducer(
  state = initialState,
  action: any
): MessageState {
  switch (action.type) {

    //Save list of messages in the store
    case MESSAGE_GET:
      return {
        ...state,
        count : action.payload.messages.length,
        list : action.payload.messages.map((x) => new Message(x))
      }

    //Save count
    case MESSAGE_GET_COUNT:
      return {
        ...state,
        count : action.payload.count
      }

    //Set status
    case MESSAGE_STATUS:
      return {
        ...state,
        status : action.payload.status
      }

    default:
      return state

  }

}