/**
 * GET-ARCHIVE-TOPIC
 * Match topic from template with archive database 
 */

import { store } from "@/index"
import { sum } from "lodash"
import { Topic } from "@/redux/topic.types"

const getArchiveTopic = (topic:Topic, archiveTopics) => {
  if (archiveTopics.error){
    return new Topic()
  }else{
    
    const archiveTopic =  archiveTopics.find(x => x.aid === (store.getState().survey.active.randomData ? topic.id : topic.aid))
    const withScoreQuestions = topic.Questions.filter(x => x.withScore).length
    const withScoreOnly = withScoreQuestions === topic.Questions.length
  
    if (archiveTopic){
  
      //Repartition if not answered questions
      const repartition:any[] = archiveTopic.repartition ? archiveTopic.repartition : []
  
      topic.note = archiveTopic.note
      topic.usersCount = archiveTopic.usersCount
      topic.hide = archiveTopic.note === undefined ? true : archiveTopic.hide
      topic.withScore = withScoreQuestions > 0
      topic.repartition = repartition
      topic.noAnswers = archiveTopic.noAnswers
  
      if (repartition.length){
        topic.noAnswersRatio = (archiveTopic.noAnswers / (sum(repartition) + archiveTopic.noAnswers)) * 100
        topic.answersRatio = (sum(archiveTopic.repartition) / (sum(repartition) + archiveTopic.noAnswers)) * 100
      }
  
    }else{
      topic.hide = withScoreOnly ? true : false
      topic.withScore = withScoreOnly
    }
  
    return topic

  }
}

export default getArchiveTopic