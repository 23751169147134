/** 
 * PROJECT-DOWNLOAD-QRCODE.MODAL
 * Download all qrcode for a project
 */
import Modal from "@/components/modal"
import { withTranslation, WithTranslation } from "react-i18next"
import { connect } from "react-redux"
import { ProjectState } from "@/redux/project.types";

interface StateProps extends WithTranslation{
  project : ProjectState
}

interface OwnProps{
  onClose : Function
}

type Props = StateProps & OwnProps

function ProjectDownloadQrcodesModal(props:Props) {
  const { t } = props

  return (
    <Modal onClose={() => props.onClose()}>
      {t("GENERATE QRCODE")}
    </Modal>
  )

}

const mapStateToProps = state => ({
  project : state.project
})

export default connect(mapStateToProps)(withTranslation()(ProjectDownloadQrcodesModal))