/** 
 * HOME-RESOURCES.WIDGET
 * Link to resources
 */

import { withTranslation, WithTranslation } from "react-i18next"
import Card from "@/components/card";
import Space from "@/components/space";
import CardButton from "@/components/card-button";
import { faBook } from "@fortawesome/free-solid-svg-icons";
import i18n from "@/translate/i18n";

type Props = WithTranslation

function HomeResourcesWidget(props:Props) {
  const { t } = props

  function clickResources(){
    if (i18n.language === "fr"){
      window.open("https://www.id-tree.com/aide/", "_blank")
    }else{
      window.open("https://www.id-tree.com/en-blog/", "_blank")
    }
  }

  return (
    <Card>
      <div className="flex flex-dcol home-widget">
        <Space/>
        <CardButton icon={faBook}
          title={t("resources_link")}
          onClick={clickResources}
        />
        <Space/>
      </div>
    </Card>
  )

}

export default withTranslation()(HomeResourcesWidget)