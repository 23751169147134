/**
 * QUESTION
 */

import { QUESTION_REMOVE, QUESTION_ACTIVATE, QUESTION_GET, QUESTION_STATUS, QUESTION_INIT, QUESTION_GET_COUNT, QUESTION_GET_COUNT_SEARCH, QUESTION_EDIT } from "./question.types";
import { STATUS_SAVED, STATUS_SAVE_ERROR, STATUS_SAVING } from "./_status.types";


//REDUX

export const questionActivate = question => ({
  type : QUESTION_ACTIVATE,
  payload : {
    question
  }
})

export const questionEdit = (key:string, value:any) => ({
  type : QUESTION_EDIT,
  payload : {
    key,
    value
  }
})

export const questionGet = questions => ({
  type : QUESTION_GET,
  payload : {
    questions
  }
})

export const questionGetCount = (count:number) => ({
  type : QUESTION_GET_COUNT,
  payload : {
    count
  }
})

export const questionGetCountSearch = (countSearch:number) => ({
  type : QUESTION_GET_COUNT_SEARCH,
  payload : {
    countSearch
  }
})

export const questionInit = () => ({
  type : QUESTION_INIT
})

export const questionRemove = id => ({
  type : QUESTION_REMOVE,
  payload : {
    id
  }
})

export const questionStatus = status => ({
  type : QUESTION_STATUS,
  payload : {
    status
  }
})


//API

export const questionDestroy = id => ({
  type : 'API',
  payload : {
    method : "DELETE",
    url : "/question/" + id
  }
})

export const questionDestroy_AsSupervisor = id => ({
  type : 'API',
  payload : {
    method : "DELETE",
    url : "/supervisor/question/" + id
  }
})

export const questionFetch = (search: string, limit: number | null, offset: number | null, language: string, withSupervisor: boolean) => ({
  type : 'API',
  payload : {
    method : "GET",
    url : "/questions",
    data : {
      search,
      limit, 
      offset,
      language,
      withSupervisor
    }
  }
})

export const questionFetch_AsSupervisor = (search: string, limit: number | null, offset: number | null, language: string, withSupervisor: boolean) => ({
  type : 'API',
  payload : {
    method : "GET",
    url : "/supervisor/questions",
    data : {
      search,
      limit, 
      offset,
      language,
      withSupervisor
    }
  }
})

export const questionFetchCount = (search: string, language: string, withSupervisor: boolean) => ({
  type : 'API',
  payload : {
    method : "GET",
    url : "/questions/count",
    data : {
      search,
      language,
      withSupervisor
    }
  }
})

export const questionFetchCount_AsSupervisor = (search: string, language: string, withSupervisor: boolean) => ({
  type : 'API',
  payload : {
    method : "GET",
    url : "/supervisor/questions/count",
    data : {
      search,
      language,
      withSupervisor
    }
  }
})

export const questionFetchOne = (id: string) => ({
  type : 'API',
  payload : {
    method : "GET",
    url : "/question",
    data : {
      id
    }
  }
})

export const questionUpdateAfterLaunch = (surveyId:string, questionAid:string, key:string, value:any) => ({
  type : 'API',
  payload : {
    method : "POST",
    url : "/question/after-launch",
    data : {
      surveyId,
      questionAid,
      key,
      value
    }
  }
})

export const questionUpdatePrompt = (questionAid, isPromptCustom:boolean, prompt:string) => ({
  type : 'API',
  payload : {
    method : "POST",
    url : "/question/prompt",
    data : {
      questionAid,
      isPromptCustom,
      prompt
    }
  }
})


//THUNK

export const questionDestroyAndRemove:any = (question) => async (dispatch, getState) => {
  dispatch(questionActivate(question))
  dispatch(questionStatus(STATUS_SAVING))
  
  const updateFunction = getState()._session.interfaceType === "SUPERVISOR" ? questionDestroy_AsSupervisor : questionDestroy
  const response:any = await dispatch(updateFunction(question.id))
  dispatch(questionStatus(response.error ? STATUS_SAVE_ERROR : STATUS_SAVED))

  if (!response.error){
    dispatch(questionRemove(question.id))
  }

}