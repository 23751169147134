/** 
 * SWITCH-LANGUAGE
 * Display current language : click on this to switch language
 */
import ContextMenu from "./context-menu";
import ListItem from "./list-item";
import { useState } from "react";
import { WithTranslation, withTranslation } from "react-i18next"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactTooltip from 'react-tooltip'
import { faCaretRight } from "@fortawesome/free-solid-svg-icons"
import Space from "./space";
import getEmoji from "@/utils/get-emoji.utils";

interface OwnProps extends WithTranslation{
  text : any
  isWhiteMode? : boolean
  language : string
  languageAlt? : string | null
  languages  : string[]
  onSelect : Function
}

function SwitchLanguage(props:OwnProps) {
  const { t } = props

  //Context menu with list of language
  const [showLanguages, setShowLanguages] = useState(false)

  //If manu language (more than 2) open context menu
  //Else switch with the other language
  function selectLanguage(){
    if (props.languages.length > 2){
      setShowLanguages(true)
    }else{
      selectLanguageEnd(props.languageAlt)
    }
  }

  //On select language
  function selectLanguageEnd(lg){
    setShowLanguages(false)
    props.onSelect(lg)
  }

  return (
    props.languageAlt
    ?
    <div className="switch-language rel">

      { /** context menu to select language */
      showLanguages &&
      <ContextMenu onClose={() => setShowLanguages(false)}>
        { props.languages.map(lg =>
        <ListItem key={lg}
          isSmall
          isEditable
          isActive={lg === props.language}
          onClick={() => selectLanguageEnd(lg)}>
          {lg.toUpperCase()}
        </ListItem>
        )
        }
      </ContextMenu>
      }

      <div className="grey-t flex"
        style={{
          color : props.isWhiteMode ? "white" : ""
        }}>
        
        <span onClick={selectLanguage}
          style={{ minWidth : '54px' }}>
          {getEmoji(props.language)}
          &nbsp;
          <b>{props.language.toUpperCase()}</b>
        </span>

        <Space/>

        <span onClick={selectLanguage} data-tip={props.text[props.languageAlt]}>
          <b>{getEmoji(props.languageAlt)}</b>
          &nbsp;
          <FontAwesomeIcon icon={faCaretRight}/>
          &nbsp;
          {props.text[props.languageAlt] ? props.text[props.languageAlt] : t("utils_undefined")}
        </span>
        {props.text[props.languageAlt]?.length > 50 &&
        <ReactTooltip/>
        }

      </div>

    </div>
    :
    null
  )

}

export default withTranslation()(SwitchLanguage)